<template>
  <b-container fluid class="light-silver-bg">
    <div class="p_loading" v-if="isLoading">
      <div class="p_loader"></div>
    </div>
    <!-- Tutor Profile Overview -->
    <ProfileTeacherAccount :teacher="teacherData"/>
    <!-- Navigiation -->
    <div class="shadow-sm sticky-top" style="top: 0;">
      <b-container fluid class="bg-white">
        <b-row class="text-white max-width-1200 mx-auto">
          <b-nav pills v-b-scrollspy="170" class="w-100 rounded-0 " style="padding: 0 15px;">
            <b-nav-item href="#tutor-bio" @click="scrollIntoView">About</b-nav-item>
            <b-nav-item href="#tutor-specialization" @click="scrollIntoView">Specialization</b-nav-item>
            <b-nav-item href="#tutor-schedule" @click="scrollIntoView">Schedule</b-nav-item>
            <b-nav-item href="#tutor-reviews" @click="scrollIntoView">Reviews</b-nav-item>
          </b-nav>
        </b-row>
      </b-container>
    </div>
    <!-- Tutor Details -->
    <b-row class="max-width-1200 mx-auto py-5">
      <b-col sm="12" lg="9">
        <!-- Tutor About -->
        <ProfileTeacherAbout :teacherData="teacherData"/>
        <!-- Tutor Specialization -->
        <ProfileTeacherSpecialization :teacherData="teacherData"/>
        <!-- Tutor Schedule -->
        <ProfileTeacherAvailability :teacherData="teacherData"/>
        <!-- Tutor Reviews  -->
        <ProfileTeacherRatingsAndReviews :teacherData="teacherData"/>
      </b-col>
      <b-col sm="12" lg="3">
        <!-- Tutor button -->
        <ProfileTeacherRate :teacherData="teacherData"/>
        <!-- End Tutor button -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mixins from '../mixins'
import ProfileTeacherAccount from '../components/profile/teacher/ProfileTeacherAccount.vue'
import ProfileTeacherAbout from '../components/profile/teacher/ProfileTeacherAbout.vue'
import ProfileTeacherSpecialization from '../components/profile/teacher/ProfileTeacherSpecialization.vue'
import ProfileTeacherAvailability from '../components/profile/teacher/ProfileTeacherAvailability.vue'
import ProfileTeacherRatingsAndReviews from '../components/profile/teacher/ProfileTeacherRatingsAndReviews.vue'
import ProfileTeacherRate from '../components/profile/teacher/ProfileTeacherRate'

export default {
  components: {
    ProfileTeacherAccount,
    ProfileTeacherAbout,
    ProfileTeacherSpecialization,
    ProfileTeacherAvailability,
    ProfileTeacherRatingsAndReviews,
    ProfileTeacherRate
  },
  name: "TeacherProfile",
  metaInfo: {
    title: "Chalkboard - Tutor Profile",
  },
  data: () => ({
    mainProps: {
      blank: true,
      blankColor: "#777",
      width: 96,
      height: 96,
      class: "m1",
    },
    teacherData: null,
    isLoading: true
  }),
  computed: {},
  beforeMount() {
    if (this.$store.state.userData.role == 'teacher') {
      this.ShowModal('You are not allowed to access this page.', () => { window.location.href = window.location.origin })
    }
    else
      this.loadTeacherData();
  },
  mounted() {
    document.getElementById("navbar-fp-main").style.position = "relative";
    document.getElementById("navbar-fp-main").classList.remove("sticky-top-main");
  },
  methods: {
    loadTeacherData() {
      this.getTeacherData(window.localStorage.getItem("tutorId"))
        .then(response => {
          const _data = response.data.data;
          this.teacherData = _data;
        })
        .finally(() => { this.isLoading = false });
    }
  },
  mixins: [mixins]
};
</script>

<style>
body {
  scroll-behavior: smooth;
}
.availabilitytab .nav-tabs .nav-link {
  margin-right: 10px;
  color: var(--dark-silver);
  background: var(--light-silver);
}

.availabilitytab .nav-item:last-child .nav-link {
  margin-right: unset;
}

.availabilitytab .nav-tabs .nav-link.active {
  background: var(--light-blue);
  color: #fff;
}
label.btn.btn-secondary {
  border: none;
  color: var(--dark-silver);
  background: var(--light-silver);
}

.morning label.btn.btn-secondary.active,
.morning .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--orange);
}
.afternoon label.btn.btn-secondary.active,
.afternoon .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--teal);
}
.evening label.btn.btn-secondary.active,
.evening .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--dark-blue);
}
.selectall-btn.btn-secondary,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):active,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):focus,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):hover {
  color: var(--light-blue);
  background: white;
  border: 1px solid var(--light-blue);
}
.clearall-btn.btn-secondary,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):active,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):focus,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):hover {
  color: var(--red);
  background: white;
  border: 1px solid var(--red);
}
.text-overflow-ellipsis {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p_loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.p_loader {
  width: 8em;
  height: 8em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>

<style scoped>
.b-rating.form-control,
.form-control:focus {
  background: transparent;
  outline: none;
  box-shadow: none;
}
.cb-tutor-profile-background-image {
  background-image: url("~@/assets/global/tutor-profile-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 45vh;
}
.container-fluid {
  padding-left: unset;
  padding-right: unset;
}
.nav-item > a {
  font-weight: 600;
  color: var(--dark-grey);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-weight: 600;
  color: #fff;
  background-color: var(--orange);
}
@media screen and (max-width: 768px) {
  .cb-tutor-profile-background-image {
    height: 100vh;
  }
}
</style>
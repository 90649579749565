<template>
<div class="statistics">
            <div class="container stats">
                <div class="stat-header">
                    <h6>Trusted by thousands of parents and learners globally</h6>
                </div>
                <div class="stat-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 stat-body-sec">
                            <h3>{{formatCounter(completed_hrs)}}</h3>
                            <hr>
                            <p>completed hours of online tutoring</p>
                        </div>
                        <div class="col-lg-3 col-md-6 stat-body-sec">
                            <h3>4.9</h3>
                            <hr>
                            <p>out of 5 average rating from parents</p>
                        </div>
                        <div class="col-lg-3 col-md-6 stat-body-sec">
                            <h3>15</h3>
                            <hr>
                            <p>countries with learners enjoying personalized learning from Chalkboard</p>
                        </div>
                        <div class="col-lg-3 col-md-6 stat-body-sec">
                            <h3>10%</h3>
                            <hr>
                            <p>of over 2,000 applicants passed our thorough vetting process</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
  export default {
    name: 'Statistics',
    data() {
      return {
          completed_hrs: 0,
      }
    },
    computed: {},
    mounted() {
      this.getSessionStatistics()

    },
    methods: {
      getSessionStatistics(){ 
          const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/statistic/get-session-statistics";

          this.axios.get(ApiURL).then((data) => {
 
            this.completed_hrs = data.data.data[0].completed_sessions;
 
          });       
      },
      formatCounter(num_val){
            let result = 0
            if (typeof(num_val) !== 'undefined') {
                let val = (num_val/1).toFixed(0)//.replace('.', ',')
                result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            return result
        }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
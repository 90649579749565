<template>
  <div>
    <!-- breadcrumbs -->
    <div class="d-lg-flex  align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold ">
        View Session History
      </div>
      <div>
        <!-- Time zone -->
        <div class="d-flex">
          <div class="d-flex align-items-center font-size-sm mr-3 grey font-weight-normal">
            <div v-b-tooltip.hover title="Date and time displayed are based on your selected time zone">
              <font-awesome-icon class="font-size-xs mr-1 " :icon="['fas', 'info-circle']" />
            </div>
            Time zone:
          </div>
          <div class="font-weight-semibold font-size-sm d-flex align-items-center">
            <span class=" mr-2">{{ userData.timezone }}</span>
            <b-link to="/preferences#timezone" v-b-tooltip.hover title="Change time zone in Preference settings.">
              <font-awesome-icon class="font-size-xs mr-1 light-blue" :icon="['fas', 'pen']" />
            </b-link>
          </div>
        </div>
      </div>
    </div>

    <b-card no-body class="p-4 shadow-sm mb-4" style="border:none;">

      <b-table class="default-table-css font-size-sm" :items="reviews" :fields="fields" responsive :busy="isBusy" show-empty empty-text="No history of ratings and reviews yet.">

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>  please wait while data is loading...</strong>
          </div>
        </template>
        <!-- Learner Name -->
        <template #cell(learner_name)="data">
          <div class="d-flex align-items-center">
            <b-avatar size="1.6rem" class="mr-1" :src="data.item.learner_image"></b-avatar>
            <span  > {{data.item.learner_name}}
            </span>
          </div>
        </template>

        <!-- Tutor Name -->
        <template #cell(tutor_name)="data">
          <div class="d-flex align-items-center">
            <b-avatar size="1.6rem" class="mr-1" :src="data.item.image"></b-avatar>
            <span class="font-weight-medium custom-link"  @click="getTeacherDetails(data.item.tutor_id)"> {{data.item.tutor_name}}
            </span>
          </div>
        </template>


        <!-- Review Buttons -->
        
        <template #cell(review)="data">
          <b-button size="sm" @click="onCreateReview(data.item)" class="table-write-review-btn " v-if="(data.item.rating < 1 || data.item.rating == null || data.item.rating == '') && data.item.isSevenDays == 0">Write a review</b-button>
          <!-- Edit Star Rating -->
          <div class="d-flex align-items-center">
            <b-form-rating class="font-size-xs px-2" v-if="data.item.rating > 0" :value="data.item.rating" icon-empty="star-fill" variant="warning" no-border inline readonly></b-form-rating>
            <div>
              <button v-if="data.item.rating > 0 && data.item.isSevenDays == 0" v-b-tooltip.hover title="Edit review" @click="editRatings(data.item)" :disabled="data.item.isEdited == 1" style="background:transparent;border:none; outline: none;">
                <font-awesome-icon class="font-size-md silver-bg" style="height:25px;width:25px;border-radius: 50%;padding: .35rem;" :icon="['fas', 'pen']" />
              </button>
            </div>
            <!-- View Rating -->
            <div v-if="false">
              <button v-b-tooltip.hover title="View review" style="background:transparent;border:none; outline: none;">
                <font-awesome-icon class="font-size-md silver-bg" style="height:25px;width:25px;border-radius: 50%;padding: .35rem;vertical-align: middle;" :icon="['fas', 'eye']" />
              </button>
            </div>
          </div>
          <!-- no review -->
          <span v-if="data.item.isSevenDays == 1 && data.item.rating == null" v-b-tooltip.hover title="A session may only be rated and reviewed within seven days from session date" class="dark-silver table-no-review-text">
            No Review
          </span>

        </template>
        
        <!-- Action buttons -->
        <template #cell(action)="data">
          <b-button class="table-reschedule-rebook-btn" size="sm" @click="onRebook(data.item)">
            <font-awesome-icon class=" mr-1" :icon="['fas', 'calendar-alt']" />Book Again
          </b-button>

          <b-button size="sm" style="margin-left: 5px;" @click="onScribook(data.item)">
            <font-awesome-icon class=" mr-1" :icon="['fas', 'book-reader']" />
          </b-button>
          
          <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="reset-padding trans-section">
            <template #button-content>
              <b-button class="table-view-details-btn mx-1" size="sm" title="View Details" v-b-tooltip.hover v-b-modal.view-details-modal @click="selectedData = data.item.data">
                <font-awesome-icon class="" :icon="['fas', 'external-link-alt']" />
              </b-button>
            </template>
            <b-dropdown-item>
              <div class="relative-div font-size-xs">
                Booking Id: <span class="ml-2 font-weight-semibold">
                  <!-- Booking Id -->
                  {{ data.item.booking_id }}
                </span>
                <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0; right: -1.5rem!important;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="relative-div font-size-xs">
                Transaction Id: <span class="ml-2 font-weight-semibold">
                  <!-- Transaction Id -->
                  {{ data.item.transaction_id }}
                </span>
                <b-button @click="onCopy(data.item.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0; right: -1.5rem!important;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>

              <div class="relative-div font-size-xs">
                Session Type: <span class="ml-2 font-weight-semibold">
                  <!-- Session -->
                  {{ data.item.session_type }}
                </span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination v-if="reviews.length > 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" class="p-3"></b-pagination>

      <RatingsAndReviews v-if="isRatingsAndReviews" :modalToShow="'RatingsAndReviews'" :ratingsAndReviewsData="selectedData" :mode="mode" :request="'session-history'" @onUpdateRequestSuccess="onRequestUpdate"></RatingsAndReviews>

      <div class="tutor-profile-modal" v-if="showModal">

        <!-- Modal content -->
        <div id="tutorProfileModal" class="tutor-profile-modal-content light-silver-bg p-0 w-75">
          <span class="tutor-profile-close" title="Close" @click="closeModal">&times;</span>
          <div>
            <ProfileTeacherAccount :teacherData="teacherData" />
            <b-row class="mx-lg-5 py-5">
              <b-col sm="12" lg="9">
                <ProfileTeacherAbout :teacherData="teacherData" />
                <ProfileTeacherSpecialization :teacherData="teacherData" />
                <ProfileTeacherAvailability :teacherData="teacherData" />
                <ProfileTeacherRatingsAndReviews :teacherData="teacherData" />
              </b-col>
              <b-col sm="12" lg="3">
                <ProfileTeacherRate :teacherData="teacherData" />
              </b-col>
            </b-row>
          </div>

        </div>
      </div>
    </b-card>

    <ScribookCreationModal v-if="isShowScribookCreationModal" :scribookData="scribookData" :isAllowedFromSessionHistory="true" @onScribookCreationModalClose="onScribookCreationModalClose"/>

  </div>

</template>
<script>
import mixins from "../../../mixins";
import RatingsAndReviews from "../../review/RatingsAndReviews.vue";
import ProfileTeacherAccount from "../../profile/teacher/ProfileTeacherAccount.vue";
import ProfileTeacherAbout from "../../profile/teacher/ProfileTeacherAbout.vue";
import ProfileTeacherSpecialization from "../../profile/teacher/ProfileTeacherSpecialization.vue";
import ProfileTeacherAvailability from "../../profile/teacher/ProfileTeacherAvailability.vue";
import ProfileTeacherRatingsAndReviews from "../../profile/teacher/ProfileTeacherRatingsAndReviews.vue";
import ProfileTeacherRate from "../../profile/teacher/ProfileTeacherRate";
import ScribookCreationModal from '../../scribook/ScribookCreationModal.vue'
import { mapState } from 'vuex';
export default {
  components: {
    RatingsAndReviews,
    ProfileTeacherAccount,
    ProfileTeacherAbout,
    ProfileTeacherSpecialization,
    ProfileTeacherAvailability,
    ProfileTeacherRatingsAndReviews,
    ProfileTeacherRate,
    ScribookCreationModal
  },
  name: "SessionHistoryParent",
  data() {
    return {
      fields: [
        { key: "date", sortable: true },
        { key: "grade_level", label: "Level/Category" },
        { key: "subject", label: "Subject/Lesson" },
        "learner_name",
        "tutor_name",
        "status",
        { key: "review", label: "" },
        "action",
      ],
      isBusy: false,
      reviews: [],
      isRatingsAndReviews: false,
      selectedData: [],
      teacherId: null,
      mode: "update",
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      showModal: false,
      teacherData: null,
      isLoading: false,
      timezone: null,
      guestTZ: null,
      regionTzName: null,
      isShowScribookCreationModal: false,
      scribookData: []
    };
  },
  computed: {
    ...mapState(['userData']),
  },
  mounted() {
    const vm = this;
    vm.timezone = vm.guestTz;
    vm.$watch(
      "$store.state.userData",
      (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
        vm.regionTzName =
          data.timezone == null
            ? vm.getTzRegion(vm.guestTz)
            : data.region_timezone;
      },
      { immediate: true }
    );
    vm.getRatingsAndReviews();
  },
  methods: {
    onScribookCreationModalClose() {
      this.isShowScribookCreationModal = false
    },
    onScribook(data) {
      this.scribookData = data
      this.isShowScribookCreationModal = true
    },
    closeModal() {
      this.showModal = false;
    },
    onCreateReview(data) {
      this.mode = "create";
      this.selectedData = {
        booking_id: data.booking_id,
        image: data.image,
        tutor_name: data.tutor_name,
      };
      this.isRatingsAndReviews = true;
    },
    editRatings(data) {
      this.mode = "update";
      this.isRatingsAndReviews = true;
      this.selectedData = data;
    },
    onRequestUpdate(value) {
      if (value) {
        this.isRatingsAndReviews = false;
        this.getRatingsAndReviews();
      }
    },
    getTeacherDetails(teacherId) {
      this.showModal = true;
      this.isLoading = true;
      this.teacherId = teacherId;
      this.getTeacherData(this.teacherId)
        .then((data) => {
          const _data = data.data.data;
          this.teacherData = _data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getRatingsAndReviews() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        `/api/web/review/review-list-parent-learner?count=${this.perPage}&page=${this.currentPage}`;
      const options = {
        headers: this.getGetRequestHeaders(),
      };
      this.isBusy = true;
      await this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;
          this.totalRows = _data.total;
          this.reviews = _data.data.map((item) => {
            return {
              tutor_id: item.tutor_id,
              review_id: item.id,
              booking_id: item.booking_id,
              transaction_id: item.transaction_id,
              date: this.moment.utc(item.start_date).tz(this.guestTZ).format("dddd, LL"),
              time: `${this.moment.tz(item.start_date, 'UTC').tz(this.guestTZ).format('hh:mm A')} - ${this.moment(this.moment.tz(item.start_date, 'UTC').tz(this.guestTZ)).add(1, 'hours').format("hh:mm A")} (${this.regionTzName})`,
              subject: item.subject,
              tutor_name: item.firstname + " " + item.lastname,
              status: item.status,
              rating: item.rating,
              feedback: item.review,
              recommendation: item.recommendation,
              remarks: item.remarks,
              image: item.image,
              learner_name:
                item.learner_firstname + " " + item.learner_lastname,
              grade_level: item.grade_level,
              isEdited: item.isEdited,
              isSevenDays: item.isSevenDays,
              session_type: item.session_type,
              child_id: item.child_id,
              note: item.note,
              learner_image: item.learner_image
            };
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.isBusy = false;
        });
    },
    onCopy(text) {
      this.$emit('copyToClipboard', text)
    },
    onRebook(data) {
      if (this.userData.role === 'parent') {
        data.account_type = 'parent'
      }
      localStorage.setItem("tutorId", data.tutor_id);
      localStorage.setItem("rebookData", JSON.stringify(data));
      this.$router.push("/booking");
    }
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getRatingsAndReviews().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link {
  font-size: 2rem;
  font-weight: 700;
}
.nav.nav-tabs {
  background: var(--silver);
}
.b-rating.form-control:focus {
  box-shadow: none;
}
</style>
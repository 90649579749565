<template>
  <div
    class="app-modal"
    :class="visible ? 'app-modal--open' : 'app-modal--close'"
    :id="id"
    ref="appModal"
    tabindex="0"
    @keyup="onKeyup"
    @mouseup="onMouseup"
  >
    <div
      class="app-modal__main"
      :style="{
        minWidth: minWidth,
        minHeight: minHeight,
        width: width,
        height: height,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      }"
      ref="appModalMain"
    >
      <component
        v-bind="contentComponentBinds"
        v-on="contentComponentEvents"
        :is="contentComponent"
        @close-modal="closeAppModal(id)"
      ></component>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModal",

  props: {
    id: {
      type: String,
      required: true
    },
    minWidth: {
      type: [Number, String],
      default: "350px"
    },
    minHeight: {
      type: [Number, String],
      default: "150px"
    },
    width: {
      type: [Number, String],
      width: "500px"
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    maxWidth: {
      type: [Number, String],
      default: "600px"
    },
    maxHeight: {
      type: [Number, String]
    },
    visible: {
      type: Boolean,
      required: true
    },
    contentComponent: {
      type: Object,
      required: true
    },
    contentComponentBinds: {
      type: Object
    },
    contentComponentEvents: {
      type: Object
    },
    clickToClose: {
      type: Boolean,
      default: false
    }
    /**
     * TODO - add capabilities like draggable and resizable
     */
  },

  methods: {
    onMouseup(e) {
      if (!this.$refs.appModalMain.contains(e.target) && !this.clickToClose) {
        this.closeAppModal();
      }
    },

    onKeyup(e) {
      if (e.code === "Escape" && !this.clickToClose) {
        this.closeAppModal();
      }
    }
  }
};
</script>

<template>
  <div>
    <select
      v-bind="$attrs"
      :class="childClass"
      @input="$emit('input', $event.target.value)"
    >
      <option value="">-- select --</option>
      <option v-for="(item, index) in $attrs.options" :key="index" :value="item.code">{{item.name}}</option>
    </select>
  </div>
</template>

<script>
  export default {
    name: 'FormSelect',
    props: ['childClass'],
  }
</script>

<style scoped>
</style>
<template>
  <div>
    <!-- Toast for admitting learner -->
    <b-toast id="participant-admit" class="vs-toast" static no-auto-hide no-close-button>
      <span> {{ this.participantUser }} is waiting... <b-button class="admit-btn" @click="allowUserToJoin">Admit</b-button></span>
      <br>
    </b-toast>

    <b-toast id="participant-waiting" class="vs-toast" static no-auto-hide no-close-button>
      <span class="text-center">Please wait, Tutor {{ this.participantUser }}. will let you in soon.</span>
      <span class="d-block text-center">{{ this.subjectLevel }}</span>
      <div class="participant-waiting-loader">
      </div>
    </b-toast>

    <!-- Show Toast to Tutors when Learners are late or unavailable -->
    <b-toast id="participant-share-screen-msg" class="vs-toast" static no-close-button>
      <span> Participants can now see your screen. <button @click="$bvToast.hide(this)" type="button" aria-label="Close" class="close text-white ml-2"><svg viewBox="0 0 16 16" width="1.2em" height="1.2em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi">
            <g>
              <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
            </g>
          </svg></button></span>
      <br>
    </b-toast>

    <!-- Show Toast to Tutors when Learners are late or unavailable -->
    <b-toast id="participant-late-learner" class="vs-toast" static no-auto-hide no-close-button>
      <span> Learner {{ this.participantUser }} is late or unavailable. <b-button class="admit-btn" href="https://tawk.to/chat/6061c66df7ce18270934ff86/1f1uuu83b" target="_blank" @click="$bvToast.hide(this)">Contact Support</b-button> </span>
      <br>
    </b-toast>

    <!-- Show Toast to Tutors when they late for 15 minutes -->
    <b-toast id="participant-late-tutor" toast-class="vs-toast warning" no-auto-hide no-close-button title="Notice">
      <span style="font-size:14px;">You have been late for more than 15 minutes. Your learner or your learner's parent may request for minute credits or they may choose to cancel this session.</span>
      <br>
      <b-button class="admit-btn ml-0 mt-1" @click="$bvToast.hide('participant-late-tutor')">Understood</b-button>
    </b-toast>

    <!-- Show Toast to Participants when they have slow internet connection  -->
    <b-toast id="participant-unstable-network" toast-class="vs-toast warning" no-auto-hide title="Unstable network">
      <span style="font-size:14px;">Your connection is unstable.</span>
    </b-toast>
  </div>
</template>

<script>
export default {
  name: "SessionToastMessages",
};
</script>
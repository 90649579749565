<template>
  <div>
    <VuePhoneNumberInput
      v-bind="$attrs"
      no-example
      size="sm"
      class="form-pn-input"
      @input="$emit('input', $event)"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
  export default {
    mounted() {
      const pn_input = document.querySelectorAll('.form-pn-input')
      pn_input.forEach(input => {
        input.addEventListener('keydown', (e) => {
          const keyCode = e.keyCode || e.which
          if (keyCode === 13) e.preventDefault()
        })
      })
    }
  }
</script>

<style scoped>
  * >>> .country-selector__list__item__calling-code {
    font-size: 11px !important;
    margin-left: -5px;
  }
  * >>> .dots-text {
    font-size: 12px;
  }
  * >>> .country-selector__label, * >>> .input-tel.sm .input-tel__label[data-v-e59be3b4] {
    display: none;
  }
  * >>> .country-selector.sm .country-selector__country-flag[data-v-46e105de] {
    top: 12px;
  }
  * >>> .country-selector.sm.has-value .country-selector__input[data-v-46e105de] {
    padding-top: 0;
  }
  * >>> .country-selector__toggle[data-v-46e105de] {
    top:calc(50% - 12px);
  }
  * >>> .input-tel.sm.has-value .input-tel__input[data-v-e59be3b4] {
    padding-top: 0;
  }
  * >>> .input-tel__input {
    cursor: initial!important;
  }
</style>
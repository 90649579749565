<template>
  <div>
    <div id="tutor-specialization" class="bg-white py-4 px-4 mb-4 rounded shadow-sm">
      <div class="d-flex flex-row align-items-center pb-2" style="border-bottom: 2px solid var(--silver)">
        <font-awesome-icon class="mr-1" icon="user-circle" />
        <h6 class="m-0">Specialization</h6>
      </div>

      <div class="py-4">
        <ul class="list-group">
          <li v-for="item in teacherSubjects" :key="item.level" class="list-group-item d-flex align-items-center">
            <div class="mr-2"><strong> {{ item.gradelevel }} - </strong></div>
            <div><span v-for="(sItem, sIndex) in item.subjects.split(',')" :key="sIndex" class="badge px-2 font-weight-normal gray mr-2" style="background: #D7F9FF;">{{ sItem }}</span></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['teacherData'],
    name: 'ProfileTeacherSpecialization',
    data: () => ({
      items: []
    }),
    computed: {
      teacherSubjects() {
        let vm = this,
            specializationRecord = [];
        if (vm.items != "") {
          const levels = this.items.subject.reduce(function(record, x){
            if(record[x["level"]]){
                 record[x["level"]][0].subjects = record[x["level"]][0].subjects.concat("," + x["name"]);
            }
            else{
              (record[x["level"]] = record[x["level"]] || []).push({
                  level: x.level,
                  subjects: x.name,
                  gradelevel: x.grade_level_name
              });
            }
            return record;
          }, {});
          for(let index in levels){
            let record = levels[index][0];
            specializationRecord.push({
              level: record.level,
              subjects: record.subjects,
              gradelevel: record.gradelevel
            });
          }
        }
        return specializationRecord;
      }
    },
    mounted() {},
    methods: {},
    watch: {
      teacherData(val) {
        this.items = val
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
    <div>
        <b-tab v-for="(subject, index) in data.subjects" :key="index" :title="subject" lazy @click="onSubjectTabClick(subject)">
            <ScribookSubjectTabContent :data="data.subjectCards" :displayView="displayView" @selectedItem="onSelectedItem" @showFileStorageSidebar="onShowFileStorageSidebar"/>
        </b-tab>
    </div>
</template>

<script>

import ScribookSubjectTabContent from '../scribook/ScribookSubjectTabContent.vue'

export default {
    props: {
        data: Object,
        displayView: String
    },
    name: 'ScribookSubjectTab',
    components: { ScribookSubjectTabContent },
    data: () => ({
    }),
    methods: {
        onSelectedItem(payload) {
            this.$emit('selectedItem', payload)
        },
        onShowFileStorageSidebar() {
            this.$emit('showFileStorageSidebar')
        },
        onSubjectTabClick(subject) {
            this.$emit('emitSelectedSubjectTab', subject)
        }
    }
}
</script>

<style scoped>
</style>

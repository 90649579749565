<template>
    <b-modal id="session-scribook" hide-header hide-footer @hide="onModalClose()">
        <Scribook :isViewScribook="true" :scribookData="scribookData"/>
    </b-modal>
</template>

<script>
import Scribook from '../scribook/Scribook.vue'
export default {
    props: {
        scribookData: Object
    },
    components: {
        Scribook
    },
    mounted() {
        this.$bvModal.show('session-scribook')
    },
    methods: {
        onModalClose() {
            this.$emit('scribookViewModalClose')
        }
    }
}
</script>

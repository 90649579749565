<template>
  <div>
    <section class="section py-5">
      <div class="container px-5">
        <h1
          class="text-center font-weight-bold dark-grey mb-4"
        >
          Service Etiquette
        </h1>
        <ul style="list-style: disc outside; margin: auto; width: 80%">
          <li>
            I understand that my role as a tutor is to assist tutees in
            developing learner independence by helping them develop the skills
            necessary to achieve their most effective and efficient learning.
          </li>
          <li>I undertake never to do a tutee’s work for him or her.</li>
          <li>
            I understand that my relationship to the student is professional
            rather than personal, and I will avoid establishing inappropriate
            relationships with tutees.
          </li>
          <li>
            I will respect every tutee’s uniqueness and personal dignity at all
            times by accepting him or her without judgment.
          </li>
          <li>
            I will respect, and will work to improve my sensitivity to, tutees’
            cultural, individual and role differences, including those based on
            age, gender, sexual orientation, race, ethnicity, culture, national
            origin, religion, disability, language and socioeconomic status.
          </li>
          <li>
            I recognize the existence of individual learning styles and will
            remain flexible in my approach to student learning.
          </li>
          <li>
            Recognizing that I may not be able to meet all of the needs of
            tutees, I will be willing to seek assistance from more qualified
            employees and/or to direct tutees to appropriate resources when
            tutees’ needs exceed the boundaries of my competence and expertise.
          </li>
          <li>
            I will fulfill all my commitments to tutees - including those
            relating to punctuality and attendance - out of courtesy and to be a
            good example for my tutees.
          </li>
          <li>
            I will establish a safe environment where students feel comfortable
            to ask questions and admit weaknesses.
          </li>
          <li>
            I will not tutor any form of tests, including take-home tests/essays
          </li>
          <li>I will keep all information about tutees confidential.</li>
          <li>
            When on duty as a tutor, I will conduct myself in a professional
            manner.
          </li>
          <li>
            I will act professionally about my colleagues by never commenting
            negatively on other tutors, whether on his/her teaching style and
            strategies.
          </li>
          <li>
            I will keep current in both my subject area(s) and learning
            methodologies.
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "service_etiquette",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>
<template>
  <section class="pt-5">
    <div class="container pt-5">
      <h5 class="dark-blue text-center">What parents are saying about Chalkboard</h5>
    </div>

    <div class="cb-parent-testimonial-container">
      <VueSlickCarousel v-bind="settings">
        <div class="cb-parent-testimonial-content one">
          <div class="testi-bg one d-flex align-items-center px-sm-3">
            <blockquote>We like the fact that <span>Chalkboard is learner-centric</span>. There is no specific curriculum per age or per grade. <span>Their program is individualized and it is tailored to the kid's learning style</span>. Chalkboard supports a relationship-based learning program, where the <span>kids and their tutors are really well-connected</span>. We sensed the love, joy and enthusiasm of our children whenever we asked them about their lessons and their tutors.
            </blockquote>
          </div>
          <div class="d-flex flex-column flex-lg-row align-items-center justify-content-end mt-lg-n5 px-sm-3">
            <div class="cb-parent-testimonial-name">
              <p>Warly and Melanie Remegio</p>
              <p>Chalkboard parents</p>
              <p>PNA New York President and Critical Care Nurse</p>
            </div>
            <figure class="cb-parent-testimonial-img r">
              <img :src="require('@/assets/imgs/testimonials/parent-testi-1.png')" alt="">
            </figure>
          </div>
        </div>

        <div class="cb-parent-testimonial-content two">
          <div class="testi-bg two d-flex align-items-center px-sm-3">
            <blockquote><span>Chalkboard has become my partner as a mom </span>– this platform was able to help me in more ways than one! Not only did it help me during the online official classes where my kids needed a specific subject teacher to support them, it was also where my kids continued to be productive during the summer. <span>Chalkboard is indeed a go-to placefor me and my kids! I definitely recommend Chalkboard especially to working moms like me</span>.
            </blockquote>
          </div>
          <div class="d-flex flex-column-reverse flex-lg-row align-items-center justify-content-start mt-lg-n5 px-sm-3">
            <figure class="cb-parent-testimonial-img">
              <img :src="require('@/assets/imgs/testimonials/parent-testi-2.png')" alt="">
            </figure>
            <div class="cb-parent-testimonial-name">
              <p>Chichi Valdepenas</p>
              <p>Chalkboard parent</p>
              <p>TV Host / News Anchor</p>
            </div>
          </div>
        </div>

      </VueSlickCarousel>
    </div>

    <div class="container text-center" style="padding: 10em 0;">
      <h5 class="dark-blue text-center mb-5 pb-5">Affiliations & Partners</h5>
      <div class="partners-row first">
        <a href="https://www.deped.gov.ph/" target="_blank">
          <b-img :src="require('@/assets/imgs/testimonials/DepEd-Logo.png')" fluid alt="DepEd Partner"></b-img>
        </a>
        <a href="https://www.facebook.com/APSO2012/" target="_blank">
          <b-img :src="require('@/assets/imgs/testimonials/APSO-Logo.png')" fluid alt="APSO Partner"></b-img>
        </a>
        <a href="https://www.iste.org/" target="_blank">
          <b-img :src="require('@/assets/imgs/testimonials/ISTE.png')" fluid alt="ISTE Partner"></b-img>
        </a>
        <a href="https://www.afta1989.org/" target="_blank">
          <b-img :src="require('@/assets/imgs/testimonials/AFTA.png')" fluid alt="AFTA Partner"></b-img>
        </a>
        <a href="https://stem.org/" target="_blank">
          <b-img :src="require('@/assets/imgs/testimonials/STEM.png')" fluid alt="STEM Partner"></b-img>
        </a>
      </div>
      <div class="partners-row">
      <a href="https://www.csteachers.org/" target="_blank">
        <b-img :src="require('@/assets/imgs/testimonials/CSTA.png')" fluid alt="CSTA Partner"></b-img>
      </a>
      <a href="https://iacademy.edu.ph/" target="_blank">
        <b-img :src="require('@/assets/imgs/testimonials/iAcademy.png')" fluid alt="iAcademy Partner"></b-img>
      </a>
      <a href="#" target="_blank">
        <b-img :src="require('@/assets/imgs/testimonials/MissionInspire.png')" fluid alt="MissionInspire Partner"></b-img>
      </a>
      </div>
    </div>
  </section>

</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "CustomerFeedback",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        initialSlide: 1,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 11000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
.partners-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
  padding-bottom: 2em;
}

@media screen and (max-width: 600px) {
  .partners-row {
  flex-direction: column;
}
}
</style>
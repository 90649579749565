<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col>
        <b-card no-body>
          <b-card-header class="p-1 light-blue-bg">

          </b-card-header>

          <b-card-body class="text-center">
            <div class="light-blue font-size-lg"><strong>Profile Overview</strong></div>
            <div class="d-flex flex-column align-items-center justify-content-center py-3">
              <font-awesome-icon class="light-blue font-size-xl mb-2" :icon="['fas', 'wallet']" />
              <span class="light-blue font-size-md ">₱ {{ walletBalance }}</span>
              <span class="light-blue font-size-sm">Wallet Balance</span>

            </div>
            <hr>
            <b-row>
              <b-col>
                <div>
                  <div class="light-blue">{{ students }}</div>
                  <span>Students</span>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="light-blue">{{ tutoringHours }}</div>
                  <span>Tutoring Hours</span>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="light-blue">{{ ratings }}</div>
                  <span>Ratings</span>
                </div>
              </b-col>
            </b-row>

          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <TeacherTodaySessions />
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-card-header class="p-1 light-blue-bg"></b-card-header>
      <b-card-body>
        <b-tabs fill class="teachersessiontabs bg-white font-size-sm">
            <b-tab v-on:click.prevent="loadPendingData" title="Pending Sessions" active>
              <TeacherPendingSessions :isDataTriggerLoad="isPendingDataTriggerLoad" @triggeredWatchSessions="triggeredWatchSessions" v-if="isLoadPendingData"/>
            </b-tab>
            <b-tab v-on:click.prevent="loadUpcomingData" title="Upcoming Sessions">
              <TeacherUpcomingSessions :isDataTriggerLoad="isUpcomingDataTriggerLoad" @triggeredWatchSessions="triggeredWatchSessions" v-if="isLoadUpcomingData"/>
            </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>

  </b-container>
</template>

<script>
import mixins from "../../../mixins";
import TeacherTodaySessions from '../../schedule/teacher/ScheduleTeacherTodaySessionsOld.vue';
import TeacherPendingSessions from '../../schedule/teacher/ScheduleTeacherPendingSessionsOld.vue';
import TeacherUpcomingSessions from '../../schedule/teacher/ScheduleTeacherUpcomingSessionsOld.vue';

export default {
  components: { 
    TeacherTodaySessions, 
    TeacherPendingSessions, 
    TeacherUpcomingSessions
  },
  name: "DashboardTeacher",
  data: () => ({
    walletBalance: "---",
    students: "---",
    tutoringHours: "---",
    ratings: "---",
    isLoadPendingData: true,
    isPendingDataTriggerLoad: false,
    isLoadUpcomingData: false,
    isUpcomingDataTriggerLoad: false
  }),
  computed: {},
  mounted() {
    this.loadWalletBalance();
    this.loadCounters();
  },
  methods: {
    loadWalletBalance() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/wallet/current-balance";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios.get(ApiURL, options).then((data) => {
        const _data = data.data;
        this.walletBalance = _data.data.total || 0;
      });
    },
    loadCounters() {
      const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/user/fetch-user',
            options = {
              headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${this.UserToken}`,
              }
            }
      this.axios.get(ApiURL, options).then(response => {
        const _data = response.data.data
        this.getTeacherCounter(_data.user_id).then(c_response => {
          const _cdata = c_response.data;
          this.students = _cdata.data.students;
          this.tutoringHours = _cdata.data.tutoring_hours;
          this.ratings = _cdata.data.rating;
        })
      })
    },
    loadPendingData() {
      this.isLoadPendingData = true;
      this.isPendingDataTriggerLoad = true;
    },
    loadUpcomingData() {
      this.isLoadUpcomingData = true
      this.isUpcomingDataTriggerLoad = true
    },
    triggeredWatchSessions() {
      this.isPendingDataTriggerLoad = false
      this.isUpcomingDataTriggerLoad = false
    }
  },
  mixins: [mixins],
};
</script>

<style scoped>
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background: var(--orange);
  outline: none;
  border: none;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>
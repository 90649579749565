<template>
  <div>
    <b-row>
      <b-col>
        <div class="text-center text-primary my-2" v-if="isLoading" style="font-size: 0.8rem;opacity: 0.5;">
          <b-spinner class="align-middle"></b-spinner>
          <strong> please wait while data is loading...</strong>
        </div>
      </b-col>

      <transition-group tag="div" class="transition-slider is-overflowed" name="fade">
        <b-col sm="12" lg="4" class="mb-2 card-body" v-for="item in todaySessions.data" :key="`${item.booking_id} ${item.start_date}`">
          <b-card style="border-top: 0.8em solid var(--orange); border-top-left-radius: 10px; border-top-right-radius: 10px;">
            <div class="py-2">
              <!-- Tutor image, name, session type -->
              <div class="d-flex align-items-center border-bottom pb-4">
                <b-avatar size="3em" class="mr-2" :src="item.image"></b-avatar>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="font-weight-semibold">Tutor {{ item.name }}</div>
                  <b-button class="bg-white p-0" :class="{ 'chat-red-notif': isUnreadMessages }">
                    <font-awesome-icon class="light-blue"  :icon="['far', 'comment-dots']" @click="onNotifClick(item)" />
                  </b-button>
                </div>
              </div>

            </div>
            <!-- Level/Cat and Subj/Lesson Container -->
            <div class="pt-2 pb-3">
              <!-- Grade Level / Category -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="font-size-sm font-weight-medium">Level/Category:</div>
                <div class="font-size-sm grey">{{ item.grade_level }}</div>
              </div>

              <!-- Subject / Lesson -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="font-size-sm font-weight-medium">Subject/Lesson:</div>
                <div class="font-size-sm grey">{{ item.subject }}</div>
              </div>
            </div>

            <!-- Date and Time Container -->
            <div class="pt-2 pb-4">
              <!-- Date -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="font-size-sm font-weight-medium">Date:</div>
                <div class="font-size-sm grey">{{ moment.utc(item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
              </div>
              <!-- Time -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="font-size-sm font-weight-medium">Time:</div>
                <div class="font-size-sm grey">{{ `${moment.tz(item.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
              </div>
            </div>

            <!-- Launch Session Button -->
            <b-button class="launch-session-btn w-100 d-flex align-items-center justify-content-center" :disabled="isAfterDate(item.start_date)" v-on:click.prevent="onLaunchSession(item)" v-if="item.room === null || item.user_join === 0">
              <font-awesome-icon class="font-size-xs mr-2" :icon="['fas', 'rocket']" />
              Launch Session
            </b-button>

            <!-- Continue Session Button -->
            <b-button class="continue-session-btn w-100 d-flex align-items-center justify-content-center" v-if="item.room !== null && item.user_join === 1" v-on:click.prevent="onLaunchSession(item)">
              <font-awesome-icon class="font-size-xs mr-2 " :icon="['fas', 'play']" />
              {{`Resume Session (${getTimeDifference(item.end_date) } remaining)`}}
            </b-button>
          </b-card>
        </b-col>
      </transition-group>
    </b-row>

    <transition name="fade">
      <b-row v-if="!isLoading && noSessionsToday">
        <b-col>No sessions today.</b-col>
      </b-row>
    </transition>

    <transition name="fade">
      <b-pagination v-if="todaySessions.data.length > 0" v-model="currentPage" :total-rows="todaySessions.total" :per-page="perPage" align="left" class="pt-4" size="sm"></b-pagination>
    </transition>

  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../mixins";
export default {
  props: ["isLoadTriggered"],
  name: "LearnerTodaySessions",
  data: () => ({
    isLoading: false,
    noSessionsToday: false,
    perPage: 3,
    currentPage: 1,
  }),
  computed: {
    ...mapState(["userData", "todaySessions", "chatThreads"]),
    isUnreadMessages() {
      return this.$store.getters.newNotificationMessage;
    },
  },
  beforeMount() {
    if (this.isLoadTriggered) this.$emit("triggeredWatchSessions");
    this.loadSessions();
  },
  methods: {
    loadSessions() {
      const vm = this;
      vm.isLoading = true;
      vm.$store.state.todaySessions.data = [];
      vm.$store
        .dispatch("getTodaySessions", {
          params: { count: vm.perPage, page: vm.currentPage },
          role: "user",
        })
        .then((response) => {
          vm.noSessionsToday =
            response.data.data.data.length === 0 ? true : false;
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    isAfterDate(startDate) {
      return (
        this.moment
          .utc(startDate)
          .tz(this.userData.timezone)
          .subtract(10, "minutes") > this.moment.tz(this.userData.timezone)
      );
    },
    onLaunchSession(data) {
      window.localStorage.setItem("bookingId", data.booking_id);
      window.localStorage.setItem("chatData", JSON.stringify(data));
      let routeData = this.$router.resolve({ name: "video-session" });
      window.open(routeData.href, "_blank");
      setTimeout(() => {
        this.loadSessions();
      }, 5000);
    },
    getTimeDifference(end_date) {
      const now = this.moment().tz(this.userData.timezone);
      const then = this.moment.utc(end_date).tz(this.userData.timezone);
      var ms = this.moment(then, "DD/MM/YYYY HH:mm:ss").diff(
        this.moment(now, "DD/MM/YYYY HH:mm:ss")
      );
      var d = this.moment.duration(ms);
      return d.minutes() + ":" + d.seconds();
    },
    onNotifClick(data) {
      // Start Mobile
      if ( this.isMobile() || window.orientation !== undefined || window.innerWidth < 798) {
        this.$router.push("/messages")
      } else {

        this.$store.dispatch("loadChatThreads", { favorites_only: "", search_keyword: this.searchMessages || "" }).then(response => {

          const obj = response.data.data.filter(x => x.chat_participants.filter(c => c.user_id === data.tutor_id).length > 0 && x.chat_participants.filter(c => c.user_id === data.user_id).length > 0).map(x => {
            return {
              'thread_id': x.id,
              'created_at': x.created_at,
              'data': x.chat_participants.filter(x => { return x.user_id !== this.userData.user_id})[0],
              'is_read': x.chat_participants.filter(x => { return x.user_id === this.userData.user_id})[0]?.last_read !== null ? true : false,
              'sortH': this.moment(x.messages[0]?.created_at || x.created_at).format('MM-DD-YYYY HH:mm:ss')
            }
          })

          const SelectedChatBubbleBox = document.querySelectorAll(`[data-id="thread_box_${obj[0].thread_id}"]`)
          const ActiveChatBubbleBox = document.querySelectorAll('[data-id*="thread_box_"]').length
          if (SelectedChatBubbleBox.length === 0 && ActiveChatBubbleBox <= 2) {
            if (ActiveChatBubbleBox === 2) document.querySelectorAll('[data-id*="thread_box_"]')[0].remove()
            this.$store.state.selectedInboxChatThread = obj[0];
          }
          
          const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${obj[0].thread_id}']`)
          if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()

        })
        
      }
      // End Mobile 
    },
  },
  watch: {
    currentPage() {
      this.loadSessions();
    },
    isLoadTriggered(val) {
      if (val) this.loadSessions();
      this.$emit("triggeredWatchSessions");
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.chat-red-notif {
  position: relative;
}
.chat-red-notif::after {
  content: "";
  position: absolute;
  right: 0px;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  top: 4px;
  background: #f95656;
}
@media screen and (max-width: 576px) {
  .is-overflowed {
    overflow: auto;
    width: 100%;
  }
  .card-body {
    padding: 0.5rem;
    width: 100%;
  }
  .card-body .card {
    width: 15rem;
  }
}
</style>
<template>
  <div v-if="isShowBanner && !isRestrictedPage && isAllowedPage" class="promotional-banner">
    <div class="promotional-banner-wrapper">
      <div class="promotional-banner-content">
        <div class="text-white pb-1"><span class="font-weight-semibold">Get your <span class="orange font-weight-semibold">FREE</span>* class today.</span> Apply code <span class="orange font-weight-semibold">CBFREE</span> upon checkout. <b-link to="/signup"><span class="cb-voucher-code">SIGN UP NOW</span></b-link><b-link class="cb-voucher-tc" href="https://chalkboard.com.ph/pages/free-trial-terms-and-conditions/" target="_blank"><small class="text-white font-italic"> *Terms and conditions apply</small></b-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins";
export default {
  name: "PromoBanner",
  data: () => ({
    restrictedRoutePage: ["sign_up", "login"],
    isAllowedOnlyToRoutes: ['home_page', 'search']
  }),
  computed: {
    ...mapState(["userData", "voucherPromoData"]),
    isShowBanner() {
      return this.voucherPromoData.length > 0;
    },
    getLatestBanner: function () {
      return this.voucherPromoData;
    },
    isRestrictedPage() {
      return this.restrictedRoutePage.includes(this.$route.name);
    },
    isAllowedPage() {
      return this.isAllowedOnlyToRoutes.includes(this.$route.name);
    }
  },
  mounted() {
    this.$store.dispatch("getVoucherPromo");
  },
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../../assets/css/promotional-banner.css");
</style>
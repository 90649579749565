<template>
  <div>
    <b-form class="py-3">
      <b-card-body>
        <div v-if="setIsInvalid && timeZones == ''" style="color: #dc3545; font-size: 13px;">* Please select your preferred time zone.</div>
        <b-row class="py-3">
          <b-col lg="5">
            <b-form-group label-cols-lg="4" content-cols-sm label="Time zone:" label-for="time-zone" description="Please note that changing time zone affects the displayed date and time on schedules.">
              <b-form-select v-bind:class="{ 'is-invalid': setIsInvalid && timeZones == '' }" id="time-zone" v-model="timeZones">
                <b-form-select-option value="">-- select --</b-form-select-option>
                <b-form-select-option v-for="(item, index) in timeZonesOptions" :key="index" :value="item.timezone">{{ `${item.name} (${moment.tz(item.timezone).format('Z')})` }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        </b-card-body>
        <b-card-footer class="bg-white">
          <b-button variant="primary" @click="onUpdateTimeZone" class="d-block ml-auto mt-4" style="font-weight: 500;" :disabled="timeZones == ''">
            <b-spinner small v-if="isLoading"></b-spinner>
            <span v-if="isLoading"> updating time zone...</span>
            <span v-if="!isLoading">Update Time zone</span>
          </b-button>
        </b-card-footer>
      </b-form>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    props: ['isInvalid', 'isTabDisabled'],
    data: () => ({
      isLoading: false,
      timeZones: null,
      setIsInvalid: false
    }),
    beforeMount() {
      this.$store.dispatch('getRegionTimeZoneList')
    },
    mounted() {
      const vm = this
      vm.$watch((_) => [_.$store.state.userData.timezone, _.$store.state.regionTimeZones], (values) => { vm.timeZones = values[0] != null ? values[0] : '' }, { immediate: true })
      vm.setIsInvalid = vm.isInvalid
      if (vm.isInvalid) {
        document.getElementById("navbar-logo").removeAttribute("href")
      }
    },
    computed: {
      timeZonesOptions() {
        return this.$store.state.regionTimeZones
      },
      disabledTeacherRole () {
        return this.$store.state.userData.role == 'teacher'
      }
    },
    methods: {
      onUpdateTimeZone() {

        if (this.timeZones == '') {
          this.ShowModal('Please select time zone.')
          return
        }

        this.isLoading = true
        
        this.updateTimeZone(this.timeZones).then(response => {
          if (response.status == 200) {
            this.ShowModal("Time zone has been updated.", () => {
              this.$store.dispatch("getUserData", this.UserToken)
              this.$store.dispatch("getTimeSlots", this.timeZones)
              window.location.href = '/preferences#timezone'
            })
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.isLoading = false
        });

      }
    },
    mixins: [mixins]
  }
</script>

<style lang="scss" scoped>

</style>
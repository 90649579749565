<template>
    <div>
        <div class="text-center mt-4">
            <b-form-rating
                class="font-size-xl"
                v-model="newRating"
                icon-empty="star-fill"
                variant="warning" no-border inline
                :readonly="isReadOnlyRating"
            ></b-form-rating>
        </div>
        <div class="text-center mb-4 font-size-md">
            <p class="mt-2 font-weight-bold" v-if="rating == null"> Rate {{ tutorName }}</p>
            <p class="mt-2 font-weight-bold"> {{ ratingOptions[rating] }} </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tutorName', 'rating', 'isReadOnlyRating'],
    name: 'RatingSection',
    data: () => ({
        newRating: null,
        ratingOptions: [
            "",
            "The session was unsatisfactory",
            "The session could be improved",
            "The session was okay",
            "The session was very good",
            "The session was excellent",
        ]
    }),
    mounted() {
        this.newRating = this.rating
    },
    methods: {
    },
    watch: {
        newRating : function(val){
            this.$emit('ratingClicked', val)
        }
    }
}
</script>
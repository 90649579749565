import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import scribook from "./modules/scribook.js";
import appModal from "./modules/appModal.js";

Vue.use(Vuex);

var pjson = require("../../package.json");
const version = pjson.version || 0;
var mixins = require("../mixins");

export const store = new Vuex.Store({
  modules: {
    scribook,
    appModal: appModal
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: () => ({
    userIsAuthorized: localStorage.getItem("userToken") !== null,
    teacherData: [],
    userData: [],
    subjects: [],
    reviewRecommendations: [],
    timeSlots: [],
    userToken: "",
    packageVersion: version,
    highestRate: 1200,
    regionTimeZones: null,
    todaySessions: [],
    upcomingSessions: [],
    acceptedSessions: [],
    pendingSessions: [],
    declinedSessions: [],
    expiredSessions: [],
    isInitFbSdk: false,
    voucherPromoData: [],
    educationalApproachData: [],
    howDidYouHearAboutUsData: [],
    hobbiesAndInterestsData: [],
    bankAccountData: [],
    academicDegreeData: [],
    bankAccountTypeData: [],
    chatThreads: [],
    chatSelectedThread: null,
    chatMessages: null,
    unReadMessages: null,
    chatOpenSelectedThreads: [],
    selectedInboxChatThread: [],
    selectedBubbleChatHead: [],
    newNotificationMessage: false
  }),
  actions: {
    async getTeacherDetails({ commit }, teacherID) {
      const teacherData = new FormData();
      teacherData.append("id", teacherID);
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/tutor-details`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.post(ApiURL, teacherData, options).then((response) => {
          resolve(response);
          commit("SET_TEACHER_DETAILS", response.data.data);
        });
      });
    },
    async getUserData({ commit }, userToken) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/user/fetch-user`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${userToken}`
        }
      };
      return await new Promise((resolve, reject) => {
        axios
          .get(ApiURL, options)
          .then((response) => {
            resolve(response);
            commit("SET_USER_DATA", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getAllSubjects({ commit }) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            "/api/web/maintenance/getsubjectlist"
        )
        .then((data) => {
          const result = data.data;
          commit("SET_ALL_SUBJECTS", result);
        });
    },
    getReviewRecommendations({ commit }) {
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            "/api/web/maintenance/review-recommendation-list"
        )
        .then((data) => {
          const _data = data.data.data;
          commit("SET_REVIEW_RECOMMENDATIONS", _data);
        });
    },
    async getTimeSlots({ commit }, timezone) {
      const formData = new FormData();
      formData.append("userTz", timezone);

      const paramData = new URLSearchParams(formData);

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/teacher-schedule-template?${paramData}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: localStorage.getItem("userToken")
            ? `Bearer ${localStorage.getItem("userToken")}`
            : ""
        }
      };

      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_TIMESLOTS", response.data.data);
        });
      });
    },
    getTutorHighestRate({ commit }) {
      const searchData = new FormData();
      searchData.append("keyword", "");
      searchData.append("sort_by", "highest");

      const paramData = new URLSearchParams(searchData);

      const searchApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/user/teacher-list?" +
        paramData;
      const searchOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`
        }
      };

      axios.get(searchApiURL, searchOptions).then((response) => {
        const _data = response.data.data;
        commit("SET_TUTOR_HIGHEST_RATE", _data.data[0].rate);
      });
    },
    async getRegionTimeZoneList({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/user/region-timezone-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_REGION_TIMEZONE", response.data.data);
        });
      });
    },
    async getTodaySessions({ commit }, payload) {
      const ApiURL = `${
        process.env.VUE_APP_BACKEND_URL
      }/api/web/booking/today-session-${
        payload.role
      }?${mixins.default.methods.urlSearchParams(payload.params)}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_TODAY_SESSIONS", response.data.data);
        });
      });
    },
    async getUpcomingSessions({ commit }, payload) {
      const ApiURL = `${
        process.env.VUE_APP_BACKEND_URL
      }/api/web/booking/upcoming-session-${
        payload.role
      }?${mixins.default.methods.urlSearchParams(payload.params)}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_UPCOMING_SESSIONS", response.data.data);
        });
      });
    },
    async getAcceptedSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/${
        payload.scope
      }?${mixins.default.methods.urlSearchParams(payload.params)}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_ACCEPTED_SESSIONS", response.data.data);
        });
      });
    },
    async getPendingSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/${
        payload.scope
      }?${mixins.default.methods.urlSearchParams(payload.params)}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_PENDING_SESSIONS", response.data.data);
        });
      });
    },
    async getDeclinedSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/${payload.scope}?is_accepted=2&count=${payload.perPage}&page=${payload.currentPage}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_DECLINED_SESSIONS", response.data.data);
        });
      });
    },
    async getTutorDeclinedSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/declined-session-tutor?count=${payload.perPage}&page=${payload.currentPage}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_DECLINED_SESSIONS", response.data.data);
        });
      });
    },
    async getExpiredSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/${payload.scope}?is_accepted=3&count=${payload.perPage}&page=${payload.currentPage}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_EXPIRED_SESSIONS", response.data.data);
        });
      });
    },
    async getTutorExpiredSessions({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/expired-session-tutor?count=${payload.perPage}&page=${payload.currentPage}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_EXPIRED_SESSIONS", response.data.data);
        });
      });
    },
    async cancelPendingRequest({ commit }, payload) {
      const formData = new FormData();
      formData.append("transaction_id", payload.transaction_id);
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/cancel-request`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("CANCEL_PENDING_REQUEST");
        });
      });
    },
    async checkScheduleAvailability({ commit }, payload) {
      const formData = new FormData();
      formData.append("tutor_id", payload.tutor_id);
      formData.append("child_id", payload.child_id);
      payload.start_dates.forEach((startDate, index) => {
        formData.append(`schedules[${index}][start_date]`, startDate);
      });
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/check-teacher-schedule`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("CHECK_SCHEDULE_AVAILABILITY");
        });
      });
    },
    async getNotAvailableSchedules({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/teacher-bookings`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      const params = {
        tutor_id: payload.tutor_id,
        days_covered: payload.days_covered,
        child_id: payload.child_id
      };
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("GET_NOT_AVAILABLE_SCHEDULES");
        });
      });
    },
    async getParentChildren({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/user/child-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_PARENT_CHILDREN");
        });
      });
    },
    async getVoucherPromo({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/voucher/available-promos`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*"
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_VOUCHER_PROMO", response.data.data);
        });
      });
    },
    async getEducationalApproach({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/get-educational-approach-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        },
        mode: "cors"
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_EDUCATIONAL_APPROACHES", response.data.data);
        });
      });
    },
    async getHowDidYouHearAboutUs({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/referral-source-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_HOW_DID_YOU_KNOW_ABOUT_US", response.data.data);
        });
      });
    },
    async getHobbiesAndInterests({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/hobbies-interests-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_HOBBIES_AND_INTERESTS", response.data.data);
        });
      });
    },
    async getBankAccounts({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/bank-account-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_BANK_ACCOUNT", response.data.data);
        });
      });
    },
    async getAcademicDegree({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/academic-degree-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_ACADEMIC_DEGREES", response.data.data);
        });
      });
    },
    async getBankAccountTypes({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/maintenance/bank-account-type-list`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_BANK_ACCOUNT_TYPES", response.data.data);
        });
      });
    },
    async storeTutorRegistrationFormDispatch({ dispatch }) {
      dispatch("getAcademicDegree");
      dispatch("getBankAccounts");
      dispatch("getBankAccountTypes");
    },
    async loadChatThreads({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/load-chat-threads?search_keyword=${payload.search_keyword}&favorites_only=${payload.favorites_only}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_CHAT_THREADS", response.data.data);
        });
      });
    },
    async loadChatMessages({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/load-chat-messages?thread_id=${payload.thread_id}&search_keyword=${payload.search_keyword}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_CHAT_MESSAGES", response.data.data);
        });
      });
    },
    async toggleReadMessage({ commit, dispatch }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/chat-toggle-read`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      const formData = new FormData();
      formData.append("thread_id", payload.thread_id);
      formData.append("is_read", payload.is_read);
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("SET_TOGGLE_READ_MESSAGE", response.data.data);
          dispatch("loadUnreadMessages");
        });
      });
    },
    async loadUnreadMessages({ commit }) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/load-unread-messages`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("SET_NEW_NOTIFICATION_MESSAGE", response.data.data);
        });
      });
    },
    async setChatOpenSelectedThreads({ commit }, data) {
      commit("SET_CHAT_OPEN_SELECTED_THREADS", data);
    },
    async setUserChatStatus({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/user-chat-status`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      const formData = new FormData();
      formData.append("status", payload.status);
      formData.append("user_id", payload.user_id);
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("SET_USER_CHAT_STATUS", response.data.data);
        });
      });
    },
    async toggleMuteParticipantThread({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/chat-toggle-mute`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("SET_TOGGLE_MUTE_PARTICIPANT_THREAD", response.data.data);
        });
      });
    },
    async markChatAsFavorites({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/${
        payload.is_favorite
          ? "remove-chat-from-favorites"
          : "add-chat-to-favorites"
      }`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      return await new Promise((resolve) => {
        axios.post(ApiURL, formData, options).then((response) => {
          resolve(response);
          commit("ADD_CHAT_TO_FAVORITES", response.data.data);
        });
      });
    },
    async loadChatAttachments({ commit }, payload) {
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/load-chat-attachments?thread_id=${payload.thread_id}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`
        }
      };
      return await new Promise((resolve) => {
        axios.get(ApiURL, options).then((response) => {
          resolve(response);
          commit("GET_CHAT_ATTACHMENTS");
        });
      });
    }
  },
  mutations: {
    SET_TEACHER_DETAILS(state, teacher) {
      state.teacherData = teacher;
    },
    SET_USER_DATA(state, user) {
      state.userData = user;
    },
    SET_ALL_SUBJECTS(state, subjects) {
      state.subjects = subjects;
    },
    SET_REVIEW_RECOMMENDATIONS(state, recommendations) {
      state.reviewRecommendations = recommendations;
    },
    SET_TIMESLOTS(state, timeSlots) {
      state.timeSlots = timeSlots;
    },
    SET_TUTOR_HIGHEST_RATE(state, highestRate) {
      state.highestRate = highestRate;
    },
    SET_REGION_TIMEZONE(state, regionTimeZones) {
      state.regionTimeZones = regionTimeZones;
    },
    SET_TODAY_SESSIONS(state, sessions) {
      state.todaySessions = sessions;
    },
    SET_UPCOMING_SESSIONS(state, sessions) {
      state.upcomingSessions = sessions;
    },
    SET_ACCEPTED_SESSIONS(state, sessions) {
      state.acceptedSessions = sessions;
    },
    SET_PENDING_SESSIONS(state, sessions) {
      state.pendingSessions = sessions;
    },
    SET_DECLINED_SESSIONS(state, sessions) {
      state.declinedSessions = sessions;
    },
    SET_EXPIRED_SESSIONS(state, sessions) {
      state.expiredSessions = sessions;
    },
    CANCEL_PENDING_REQUEST() {},
    CHECK_SCHEDULE_AVAILABILITY() {},
    GET_NOT_AVAILABLE_SCHEDULES() {},
    GET_PARENT_CHILDREN() {},
    GET_VOUCHER_PROMO(state, promos) {
      state.voucherPromoData = promos;
    },
    GET_EDUCATIONAL_APPROACHES(state, approaches) {
      state.educationalApproachData = approaches;
    },
    GET_HOW_DID_YOU_KNOW_ABOUT_US(state, response) {
      state.howDidYouHearAboutUsData = response;
    },
    GET_HOBBIES_AND_INTERESTS(state, response) {
      state.hobbiesAndInterestsData = response;
    },
    GET_BANK_ACCOUNT(state, response) {
      state.bankAccountData = response;
    },
    GET_ACADEMIC_DEGREES(state, response) {
      state.academicDegreeData = response;
    },
    GET_BANK_ACCOUNT_TYPES(state, response) {
      state.bankAccountTypeData = response;
    },
    GET_CHAT_THREADS(state, response) {
      state.chatThreads = response;
    },
    GET_CHAT_MESSAGES(state, response) {
      state.chatMessages = response;
    },
    SET_TOGGLE_READ_MESSAGE() {},
    GET_UNREAD_MESSAGES(state, response) {
      state.unReadMessages = response;
    },
    SET_CHAT_OPEN_SELECTED_THREADS(state, thread) {
      if (state.chatOpenSelectedThreads === null) {
        state.chatOpenSelectedThreads = [];
      }
      const result = state.chatOpenSelectedThreads.find(
        (x) => x.thread_id === thread.thread_id
      );

      if (result === undefined) {
        if (state.chatOpenSelectedThreads.length === 2)
          state.chatOpenSelectedThreads.splice(-1);
        state.chatOpenSelectedThreads.push(thread);
      }
    },
    SET_USER_CHAT_STATUS() {},
    SET_TOGGLE_MUTE_PARTICIPANT_THREAD() {},
    ADD_CHAT_TO_FAVORITES() {},
    SET_NEW_NOTIFICATION_MESSAGE(state, response) {
      state.newNotificationMessage = response.length > 0;
    },
    GET_CHAT_ATTACHMENTS() {}
  },
  getters: {
    teacherData: (state) => state.teacherData,
    userData: (state) => state.userData,
    subjects: (state) => state.subjects,
    reviewRecommendations: (state) => state.reviewRecommendations,
    timeSlots: (state) => state.timeSlots,
    appVersion: (state) => {
      return state.packageVersion;
    },
    highestRate: (state) => state.highestRate,
    countryData: (state) => state.countryData,
    educationalApproachData: (state) => state.educationalApproachData,
    hearAboutUsData: (state) => state.hearAboutUsData,
    howDidYouHearAboutUsData: (state) => state.howDidYouHearAboutUsData,
    hobbiesAndInterestsData: (state) => state.hobbiesAndInterestsData,
    bankAccountData: (state) => state.bankAccountData,
    academicDegreeData: (state) => state.academicDegreeData,
    bankAccountTypeData: (state) => state.bankAccountTypeData,
    chatThreads: (state) => state.chatThreads,
    chatSelectedThread: (state) => state.chatSelectedThread,
    chatMessages: (state) => state.chatMessages,
    chatBoxSelected: (state) => state.chatBoxSelected,
    unReadMessages: (state) => state.unReadMessages,
    chatOpenSelectedThreads: (state) => state.chatOpenSelectedThreads,
    newNotificationMessage: (state) => state.newNotificationMessage
  }
});

<template>
  <div class="signup">
    <div class="signup__img-container">
      <img class="signup__img" src="~@/assets/global/login-signup-img.png" alt="Sign Up Left Pane Image" />
    </div>

    <div class="signup__form">
      <validation-observer ref="signupValidationObserver" v-slot="{ handleSubmit }">
        <form class="cb-signup-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-link to="/home" class="signup__logo">
            <img :src="require('@/assets/global/cb_logo.png')" alt="Chalkboard Logo" />
          </b-link>

          <div class="signup-slides" ref="signupSlides">
            <!-- Step 1 -->
            <div class="signup-slides__slide">
              <div class="cb-signup-text">
                <h6 class="cb-signup-text__heading pb-3">
                  What are you signing up as?
                </h6>
              </div>
              <div @click="nextSlide()">
                <BoxRadio v-for="roleOption in roleOptions" :key="roleOption.id" name="form-role" :id="roleOption.id" :title="roleOption.title" :subtitle="roleOption.subtitle" :checked-value="roleOption.checkedValue" :variant="roleOption.variant" :class="roleOption.class" v-model="form.role">
                  <template v-slot:svg>
                    <component :is="roleOption.svg" />
                  </template>
                </BoxRadio>
              </div>
              <p class="text-center mt-auto pt-5 mb-0 grey">
                Already have an account?
                <b-link to="/login" class="cb-signup-form__link">
                  Login here
                </b-link>
              </p>
            </div>
            <!-- end of Step 1 -->

            <!-- Step 2 -->
            <div class="signup-slides__slide">
              <div class="cb-signup-text">
                <h6 class="cb-signup-text__heading">Sign up</h6>
                <p class="cb-signup-text__subheading">
                  Create your Chalkboard account as:
                </p>
              </div>
              <BoxRadio :id="selectedRole.id + 'step-2'" :title="selectedRole.title" :subtitle="selectedRole.subtitle" :checked-value="selectedRole.checkedValue" :value="form.role" :variant="selectedRole.variant" @click="prevSlide()">
                <template v-slot:svg>
                  <component :is="selectedRole.svg" />
                </template>
              </BoxRadio>

              <!-- Email Field -->
              <b-form-group class="pb-2">
                <label for="email" class="cb-signup-form__label">Email</label>
                <ValidationProvider name="email" rules="required|email" v-slot="validationContext">
                  <b-form-input class="cb-signup-form__input" id="email" ref="email" v-model="form.email" type="email" required placeholder="Email Address" :state="getValidationState(validationContext)"></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <!-- Password Field -->
              <b-form-group class="">
                <ValidationProvider name="password" rules="required|atleast_one_number|atleast_one_capital_letter|atleast_one_special_character|max_eight_characters" v-slot="validationContext">
                  <label for="password" class="cb-signup-form__label">
                    Password
                  </label>
                  <b-input-group>
                    <b-form-input class="cb-signup-form__input" id="password" v-model="form.password" ref="password" :type="passwordInputType" required placeholder="Password (min of 8 characters)" :state="getValidationState(validationContext)"></b-form-input>
                    <b-input-group-append>
                      <b-button class="cb-signup-form__eye" @click="togglePassword">
                        <b-icon v-if="!showPassword" icon="eye-fill"></b-icon>
                        <b-icon v-if="showPassword" icon="eye-slash-fill"></b-icon>
                      </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </ValidationProvider>
              </b-form-group>

              <!-- Referral Code Field -->
              <b-form-group class="pb-2">
                <label for="referral_code" class="cb-signup-form__label">
                  Referral Code
                </label>
                <ValidationProvider name="referral_code" rules="" v-slot="validationContext">
                  <b-form-input class="cb-signup-form__input" id="referral_code" ref="referral_code" placeholder="Enter referral code" v-model="form.referral_code" :state="getValidationState(validationContext)"></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <!-- Marketing Checkbox -->
              <b-form-group>
                <label
                  class="cb-signup-form__checkbox"
                  for="marketing_opt_in"
                >
                  <input
                    class="cb-signup-form__checkbox-input"
                    type="checkbox"
                    id="marketing_opt_in"
                    refs="marketing_opt_in"
                    v-model="form.marketing_opt_in"
                    checked="checked"
                  />
                  <span class="cb-signup-form__checkbox-mark"></span>
                  Yes, I want to receive personalized recommendations about
                  Chalkboard’s services and promotional offers. You can opt out
                  anytime.
                </label>
              </b-form-group>

              <!-- Create Account Button -->
              <button type="submit" class="cb-signup-form__submit" :disabled="isSubmitDisabled">
                <b-spinner small v-if="isLoading"></b-spinner>
                <span v-if="isLoading">creating account...</span>
                <span v-if="!isLoading">Create my account</span>
              </button>

              <!-- Policy -->
              <p class="cb-signup-form__policy">
                By creating an account, you agree to Chalkboard's
                <a class="cb-signup-form__policy-link" href="/user-terms-and-conditions">
                  User Terms Privacy Policy and Terms and Conditions
                </a>
                .
              </p>

              <!-- Or Signup With -->
              <div class="cb-signup-form__divider">or signup with</div>

              <!-- Social Media Signup -->
              <div class="cb-signup-form__socmed-btns">
                <b-button @click="onFacebookSignIn(form.role)" :disabled="!$store.state.isInitFbSdk" variant="default">
                  <FacebookIcon />
                </b-button>
                <b-button @click="onGoogleLoginClick" :disabled="!isInit"  variant="default">
                  <GoogleIcon />
                </b-button>
              </div>

              <p class="text-center mt-auto pt-5 mb-0 grey">
                Already have an account?
                <b-link to="/login" class="cb-signup-form__link">
                  Login here
                </b-link>
              </p>
            </div>
            <!-- end of Step 2 -->

            <!-- Step 3 -->
            <div class="signup-slides__slide">
              <div class="cb-signup-text">
                <h6 class="cb-signup-text__heading">Check your email</h6>
                <p class="cb-signup-text__subheading">
                  To complete the registration of your account
                </p>
              </div>

              <MailboxIcon class="w-100" />

              <p class="dark-grey py-5">
                We’ve sent an email verification to your email address
                <span class="cb-signup-form__link">{{ form.email }}</span>
                Make sure to check your spam folder too.
              </p>

              <p class="signup__message-nm grey mb-0">
                Didn’t get the the email? Click
                <a class="cb-signup-form__link" @click="onReSendEmailVerificationClick">
                  resend verification email
                </a>
              </p>
            </div>
            <!-- end of Step 3 -->
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import mixins from "../../../mixins";
import google from "../../../mixins/social/google.js";
import facebook from "../../../mixins/social/facebook.js";
import BoxRadio from "./BoxRadio.vue";
import LearnerIcon from "../../../assets/imgs/signup/learner.svg?inline";
import ParentIcon from "../../../assets/imgs/signup/parent.svg?inline";
import TutorIcon from "../../../assets/imgs/signup/tutor.svg?inline";
import FacebookIcon from "../../../assets/imgs/signup/facebook.svg?inline";
import GoogleIcon from "../../../assets/imgs/signup/google.svg?inline";
import MailboxIcon from "../../../assets/imgs/signup/mailbox.svg?inline";

export default {
  name: "SignUp",

  metaInfo: {
    title: "Signup | Chalkboard",
  },

  components: {
    BoxRadio,
    LearnerIcon,
    ParentIcon,
    TutorIcon,
    FacebookIcon,
    GoogleIcon,
    MailboxIcon,
  },

  computed: {
    selectedRole() {
      return this.roleOptions[this.form.role] || {};
    },

    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },

    isSubmitDisabled() {
      return false;
    }
  },

  data: () => ({
    roleOptions: {
      student: {
        id: "student-role",
        title: "I'm an adult Learner",
        subtitle: "looking to improve and move up in the professional setting.",
        checkedValue: "student",
        svg: "LearnerIcon",
      },
      parent: {
        id: "parent-role",
        title: "I'm a Parent",
        subtitle:
          "looking for tutors to accelerate the learning manifold of my children.",
        checkedValue: "parent",
        svg: "ParentIcon",
      },
      teacher: {
        id: "teacher-role",
        title: "I'm applying as a Tutor",
        subtitle:
          "to provide help and improve a learner’s academic performance.",
        checkedValue: "teacher",
        svg: "TutorIcon",
        variant: "light-blue",
        class: "mt-5",
      },
    },
    showPassword: false,
    form: {
      role: "",
      email: "",
      password: "",
      referral_code: "",
      marketing_opt_in: false,
      account_type: "CBACNT"
    },
    isLoading: false,
    currentSlideIndex: 0
  }),

  methods: {
    onSubmit() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/authenticate/signup";

      this.isLoading = true;
      this.axios
        .post(ApiURL, this.form)
        .then(({ data }) => {
          if (data.status == 423) {
            const message = `<div>${data.message}</div>`;
            this.ShowModal(message)
          } else if (data.status == 422) {
            this.$refs.signupValidationObserver.setErrors(data.message);
          } else {
            const payload = {
              eventName: "Lead",
              data: {
                content_name: this.form.role,
              },
            };
            this.metaPixelTrack(payload);
            this.nextSlide();
          }
        })
        .finally(() => (this.isLoading = false));
    },

    resetForm() {
      this.$refs.email.blur();
      this.$refs.password.blur();
      this.$refs.referral_code.blur();

      this.form.role = "";
      this.form.email = "";
      this.form.password = "";
      this.form.referral_code = "";
      this.form.marketing_opt_in = false;
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    nextSlide() {
      this.currentSlideIndex--;
      this.$refs.signupSlides.style.setProperty(
        "--signup-slide-index",
        this.currentSlideIndex
      );
    },

    prevSlide() {
      this.currentSlideIndex++;
      this.$refs.signupSlides.style.setProperty(
        "--signup-slide-index",
        this.currentSlideIndex
      );
    },

    onReSendEmailVerificationClick() {
      const ApiURL =
          process.env.VUE_APP_BACKEND_URL +
          "/api/web/authenticate/sendValidationEmail",
        options = {
          headers: {
            Accept: "application/json, text/plain, */*"
          }
        },
        form = { email: this.form.email };
      this.axios.post(ApiURL, form, options).then(() => {
        const message = "Email Verification sent!";
        this.$bvToast.toast(message, {
          toaster: "b-toaster-top-right",
          bodyClass: "p-3",
          variant: "success"
        });
      });
    }
  },

  mixins: [mixins, google, facebook],
};
</script>

<template>
  <div class="voucher-dialog">
    <div class="voucher-dialog__header">
      <div class="voucher-dialog__img-wrapper-activated">
        <voucheractivated />
      </div>
      <div class="voucher-dialog__title">Voucher has been activated</div>
    </div>
    <div class="voucher-dialog__body">
      You have successfully activated a voucher. This is now added to your
      voucher list.
    </div>
    <div class="voucher-dialog__footer">
      <b-link
        class="voucher-dialog__footer-link"
        @click="showUserVouchersModal"
      >
        View My Vouchers
      </b-link>
      <span class="voucher-dialog__footer-redirect">
        This will close automatically in
        <strong>{{ count }}</strong>
        ..
      </span>
    </div>
  </div>
</template>

<script>
import voucheractivated from "../../assets/imgs/voucher-activated.svg?inline";
import UserVouchers from "./UserVouchers.vue";

export default {
  name: "VoucherActivated",

  components: {
    voucheractivated
  },

  data() {
    return {
      count: 5,
      countEnabled: true
    };
  },

  methods: {
    countDown() {
      setTimeout(() => {
        this.count--;
        if (this.count > 0 && this.countEnabled) {
          this.countDown();
        }
        if (this.count == 0) {
          this.closeAppModal();
        }
      }, 1000);
    },

    showUserVouchersModal() {
      this.closeAppModal();
      this.openAppModal(
        UserVouchers,
        { showAction: false },
        { id: "user-vouchers-modal" }
      );
    }
  },

  mounted() {
    if (this.count > 0) {
      this.countDown();
    }
  },

  beforeDestroy() {
    console.log("before destroy stop count");
    this.countEnabled = false;
  }
};
</script>

<template>
  <div>
    <UserVouchersItem
      v-for="(voucher, index) in voucher"
      :key="'voucher' + index"
      :voucher="voucher"
      :show-action="showAction"
    />
  </div>
</template>

<script>
import UserVouchersItem from "./UserVouchersItem.vue";

export default {
  name: "UserVouchersList",

  components: {
    UserVouchersItem
  },

  props: {
    voucher: {
      type: Array,
      default() {
        return [];
      }
    },
    showAction: {
      type: Boolean,
      default: true
    }
  }
};
</script>

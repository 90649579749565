<template>
  <div>
    <!-- Tutor Profile Overview -->
    <div class="cb-tutor-profile-background-image">
      <b-row class="h-100 max-width-1200 mx-auto">
        <b-col sm="12" lg="4">
          <div class="h-100 p-3 d-lg-flex flex-lg-column justify-content-lg-around align-items-lg-center" style="flex-wrap: wrap; background-color: rgba(0, 0, 0, 0.5);overflow: hidden;position: relative;">
            <div class="tutor-ribbon font-size-sm" v-if="!computedTeacher.session_count">New Tutor!</div>
            <div class="text-center">
              <b-img-lazy v-bind="mainProps" rounded="circle" :src="items.image || require('@/assets/placeholder/profile-placeholder.png')" alt="photo" style="
                object-fit: cover;
                height: 150px;
                width: 150px;
                background: white;
                padding: 0.1rem;
              " fluid></b-img-lazy>
              <!-- Star Rating container -->
              <div class="text-center">
                <template v-if="isRatingLoading">
                  <b-spinner
                    type="grow"
                    small
                    style="color: #E9E9E9;"
                  ></b-spinner>
                  <span style="font-size: 11px; color: #E9E9E9;">
                    loading ratings and reviews ...
                  </span>
                </template>
                <template v-else>
                  <template v-if="this.reviewCount">
                    <div>
                      <b-icon
                        icon="star-fill"
                        class="font-size-md orange mr-1"
                      ></b-icon>
                      <span class="font-size-lg font-weight-bold text-white">
                        {{ this.averageRating || 0 }}
                      </span>
                    </div>
                    <a
                      href="#tutor-reviews"
                      @click="scrollIntoView"
                      class="font-size-sm text-white"
                    >
                      {{ this.reviewCount || 0 }} review{{
                        this.reviewCount || 0 > 1 ? "s" : ""
                      }}
                    </a>
                  </template>
                </template>
              </div>
            </div>
            <div class="p-2 w-100">

              <!-- Tutor Education -->
              <div class="text-white pt-3 pb-3 font-size-sm">
                <b-container>
                  <b-row>
                    <b-col cols="1"><font-awesome-icon class="mr-2" icon="graduation-cap" style="height:20px;width:16px;" /></b-col>
                    <b-col><span style="word-break: break-word;font-size: calc(var(--fs-one) - 2px)">{{ items.education || 'N/A' }}</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1"><font-awesome-icon class="mr-2" icon="school" style="height:16px;width:16px;"/></b-col>
                    <b-col><span style="word-break: break-word;font-size: calc(var(--fs-one) - 2px)">{{ items.college_university || 'N/A' }}</span></b-col>
                  </b-row>
                </b-container>
              </div>

              <!-- Tutor Learners and Session -->
              <div v-if="computedTeacher.learner_count || computedTeacher.session_count" class="text-white font-size-sm">
                <b-container>
                  <b-row>
                    <b-col cols="1">
                      <font-awesome-icon class="mr-2" icon="user-alt" />
                    </b-col>
                    <b-col>
                      <strong>{{ computedTeacher.learner_count }}</strong>
                      Student{{
                        computedTeacher.learner_count > 1 ? "s" : ""
                      }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="1">
                      <font-awesome-icon class="mr-2" icon="clock" />
                    </b-col>
                    <b-col>
                      <strong>{{ computedTeacher.session_count }}</strong>
                      Session{{
                        computedTeacher.session_count > 1 ? "s" : ""
                      }}
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" lg="8" class="d-flex align-items-center">
          <div class="p-3">
            <h3 class="font-size-xl">
              {{ items.firstname }} {{ items.lastname }}
            </h3>
            <p class="fs-24">{{ items.tagline }}</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import common_mixins from '../../../mixins'
  import review_mixins from "../../../mixins/review"

  export default {
    name: 'ProfileTeacherAccount',

    props: {
      teacher: Object
    },

    data: () => ({
      averageRating: 0,
      reviewCount: 0,
      isRatingLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 96,
        height: 96,
        class: "m1",
      },
      items: []
    }),

    computed: {
      computedTeacher() {
        return this.teacher || {}
      }
    },

    methods: {
      getReviewSummary() {
        this.getTeacherReviewSummary(this.items.user_id).then(response => {
          const _data = response.data;
          this.averageRating = _data.data[0].average_rating || 0;
          this.reviewCount = _data.data[0].rating_count || 0;
        }).finally(() => { this.isRatingLoading = false })
      },
    },
    watch: {
      teacher(val) {
        this.items = val
        this.getReviewSummary()
      }
    },
    mixins: [common_mixins, review_mixins]
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cb-tutor-profile-background-image {
  background-image: url("~@/assets/global/tutor-profile-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.tutor-ribbon {
  font-weight: 600;
  font-size: var(--fs-one) - 4px;
  color: white;
  inset: 0 auto auto 0;
  background: var(--teal);
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
  box-shadow: 0 0 0 999px var(--teal);
  clip-path: inset(0 -100%);
  position: absolute;
      top: 15px;
    left: 15px;
}
</style>
<template>

  <div class="main">

    <div class="video-session-container">
      <div class="video-session-body">
        <header class="video-session-header">
          <b-img class="mr-3 cb-logo" :src="require('@/assets/global/cb_logo.png')"></b-img>
          <div class="d-flex w-100 flex-column">
            <!-- Session Info -->
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="dark-silver">
                <small class="mr-1">{{ this.startTime || "" }} - {{ this.endTime|| "" }} </small>
                <b-badge pill class="grey-bg">
                  <font-awesome-icon class="mr-1" :icon="['fas', 'users']" /> {{ this.connectedUser}}
                  <!-- No of participants connected -->
                </b-badge>
                <!----- Show For Tutor Only ------>
                <div class="ml-2 vs-timer-container d-inline-block">
                  <div class="vs-xd">
                    <input type="checkbox" id="vs-clock" hidden>
                    <label for="vs-clock" style="margin:0;">
                      <small v-b-tooltip.hover.right title="Click to show/hide timer">
                        <font-awesome-icon class="mr-1" style="font-size:var(--fs-xs);" :icon="['fas', 'hourglass-end']" />
                      </small>
                    </label>
                    <small id="session-timer" class="vs-timer">{{ this.stopWatch }}</small>
                  </div>
                </div>
              </div>
              <div class="dark-silver"><small>ID: {{ this.bookingId }}</small></div>
            </div>

            <div class="d-flex align-items-center justify-content-between w-100">
              <!-- Session Booking ID -->
              <div class=" text-white">
                <div class="text-white">{{ this.subjectLevel || ""}}</div>
              </div>
              <div class="d-flex align-items-center">
                <div v-b-modal.audio-video-refresh-modal v-b-tooltip title="Audio and Video Settings" class="d-inline-flex">
                  <font-awesome-icon v-b-modal.modal-1 :icon="['fas', 'cog']" class="mr-2" style="color:#f6f6f6;" />
                </div>
              </div>
              <b-modal id="audio-video-refresh-modal" size="md" no-close-on-backdrop hide-header centered>
                <small class="my-4">Changing your audio and video settings will temporarily disconnect you from your session.</small>
                <template #modal-footer="{ cancel }">

                  <!-- Emulate built in modal footer ok and cancel button actions -->

                  <b-button size="sm" style="background:none;color:var(--grey);" @click="cancel()">
                    Cancel
                  </b-button>
                  <b-button size="sm" class="px-3" style="background:var(--light-blue);color:var(--white);border-radius: 5em;" @click="onClickProceed">
                    Proceed
                  </b-button>

                </template>
              </b-modal>
            </div>

          </div>

        </header>

        <!-- Video Frame Container -->
        <div class="video-session-frame-container">
          <!-- Toast for admitting learner -->
          <b-toast id="participant-admit" class="vs-toast" static no-auto-hide no-close-button>
            <span> {{ this.participantUser }} is waiting... <b-button class="admit-btn" @click="allowUserToJoin">Admit</b-button></span>
            <br>
          </b-toast>

          <b-toast id="participant-waiting" class="vs-toast" static no-auto-hide no-close-button>
            <span class="text-center">Please wait, Tutor {{ this.participantUser }}. will let you in soon.</span>
            <span class="d-block text-center">{{ this.subjectLevel }}</span>
            <div class="participant-waiting-loader">
            </div>
          </b-toast>

          <!-- Show Toast to Tutors when Learners are late or unavailable -->
          <b-toast id="participant-share-screen-msg" class="vs-toast" static no-close-button>
            <span> Participants can now see your screen. <button @click="$bvToast.hide(this)" type="button" aria-label="Close" class="close text-white ml-2"><svg viewBox="0 0 16 16" width="1.2em" height="1.2em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x b-icon bi">
                  <g>
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                  </g>
                </svg></button></span>
            <br>
          </b-toast>

          <!-- Show Toast to Tutors when Learners are late or unavailable -->
          <b-toast id="participant-late-learner" class="vs-toast" static no-auto-hide no-close-button>
            <span> Learner {{ this.participantUser }} is late or unavailable. <b-button class="admit-btn" href="https://tawk.to/chat/6061c66df7ce18270934ff86/1f1uuu83b" target="_blank" @click="$bvToast.hide(this)">Contact Support</b-button> </span>
            <br>
          </b-toast>

          <!-- Show Toast to Tutors when they late for 15 minutes -->
          <b-toast id="participant-late-tutor" toast-class="vs-toast warning" no-auto-hide no-close-button title="Notice">
            <span style="font-size:14px;">You have been late for more than 15 minutes. Your learner or your learner's parent may request for minute credits or they may choose to cancel this session.</span>
            <br>
            <b-button class="admit-btn ml-0 mt-1" @click="$bvToast.hide('participant-late-tutor')">Understood</b-button>
          </b-toast>

          <!-- Show Toast to Participants when they have slow internet connection  -->
          <b-toast id="participant-unstable-network" toast-class="vs-toast warning" no-auto-hide title="Unstable network">
            <span style="font-size:14px;">Your connection is unstable.</span>
          </b-toast>

          <div id="main-view-container"></div>
          <!-- Default State -->
          <div id="vs-defaultscreen" class="vs-defaultscreen">

            <!-- Participant Video -->
            <div class="participant-video-frame" id="participant-video" v-show="this.participantCanJoin">
              <div class="vs-frame-info">
                <div :class="{ 'vs-frame-muted': this.participantMic && !this.participantMic.isEnabled, 'd-none': this.participantMic && this.participantMic.isEnabled }">
                  <font-awesome-icon :icon="['fas', 'microphone']" />
                </div>
                <div id="participant-user" class="vs-frame-name">{{ this.participantUser }}</div>
                <!-- Show only when participant is disconnected -->
                <div class="vs-frame-name" v-if="this.isParticipantLeave">
                  Left the room
                </div>
              </div>

            </div>
            <!-- Local User Video -->
            <div class="local-video-frame" id="local-video">
              <div class="vs-frame-info">
                <div :class="{ 'vs-frame-muted': !isMicEnabled, 'd-none': isMicEnabled }">
                  <font-awesome-icon :icon="['fas', 'microphone']" />
                </div>
                <div id="local-name" class="vs-frame-name">{{ this.localUser }}
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="video-session-controls-container">
          <div class="video-session-controls ">

            <b-button href="https://tawk.to/chat/6061c66df7ce18270934ff86/1f1uuu83b" target="_blank" class="video-session-controls-btn mb-2" style="visibility: hidden;">
              <div class="vs-icon">
                <font-awesome-icon :icon="['fas', 'headset']" />
              </div>
              <span>Contact Support</span>
            </b-button>

            <div>
              <!-- Mic Button -->

              <b-button class="video-session-controls-btn mb-2">
                <!-- vs-inactive class to toggle buttons -->
                <div v-bind:class="onMicOnOff" id="mic-button" @click="muteUnmuteHandler">
                  <font-awesome-icon :icon="['fas', 'microphone']" />
                </div>
                <!-- change text base on button state -->

                <span>{{ this.isMicEnabled ? 'Mute' : 'Unmute' }}</span>

              </b-button>
              <!-- Camera Button -->

              <b-button class="video-session-controls-btn mb-2">
                <!-- vs-inactive class to toggle buttons -->
                <div v-bind:class="onCamOnOff" id="cam-button" @click="camOnOffHandler">
                  <font-awesome-icon :icon="['fas', 'video']" />
                </div>
                <!-- change text base on button state -->
                <span>{{ this.isCamEnabled ? 'Stop' : 'Start' }} Video</span>
              </b-button>

              <!-- Screen Share Button -->

              <b-button class="video-session-controls-btn mb-2" id="screen-share-button" @click="onShareScreenHandler">
                <div class="vs-icon">
                  <font-awesome-icon :icon="['fas', 'share-square']" />
                </div>
                <span>Share Screen</span>
              </b-button>

              <!-- Chat Button -->

              <b-button class="video-session-controls-btn mb-2" v-b-toggle.chat-sidebar @click="onChatClick">
                <div class="vs-icon">
                  <font-awesome-icon :icon="['fas', 'comment-dots']" />
                </div>
                <span>Chat</span>
              </b-button>

              <!-- Wifi Signal -->
              <b-button id="network-strength-signal" class="video-session-controls-btn mb-2">
                <div class="vs-icon">
                  <div id="wifi" class="wifi"></div>
                </div>
                <span>Network Quality</span>
              </b-button>
              <b-tooltip target="network-strength-signal" title="Network Quality" triggers="hover">
                {{ this.networkQuality }}
              </b-tooltip>

            </div>

            <!-- Leave Button -->
            <b-button class="video-session-controls-btn leave mb-2" id="leave-room-button" v-b-modal.leave-room-button-modal>
              <div class="vs-icon">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
              </div>
              <span>Leave</span>
            </b-button>

            <b-modal id="leave-room-button-modal" size="md" no-close-on-backdrop no-close-on-esc hide-header centered>
              <h6 class="text-center red font-weight-bold p-4">Oops!</h6>
              <p class="text-center font-weight-medium dark-grey">You are about to leave an active session. <br>Are you sure?</p>
              <template #modal-footer="{ cancel }">
                <div class="text-center m-auto">
                  <!-- Emulate built in modal footer ok and cancel button actions -->
                  <b-button class=" d-block mx-auto" style="font-size: var(--fs-one);font-weight: 500;padding: 0.4em 1em;background:var(--red);border-radius:15em;color:var(--white);margin-bottom: 1em;" @click="onLeaveRoom">
                    Yes, I'll leave
                  </b-button>
                  <b-button size="sm" style="background:none;color:var(--grey);text-decoration: underline;" class="pb-4" @click="cancel()">
                    No, I'll continue the session
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>
        </div>
        <b-sidebar id="chat-sidebar" right shadow backdrop header-class="chat-header-custom" bg-variant="white" title="Chat">

          <div class="vs-chat-sidebar">
            <div class="position-relative">
              <div class="chat-bubble-box-container">
                <div data-id="chat_thread_box" class="chat-bubble-box" v-if="selectedChatThread !== null">
                  <div class="chat-bubble-box-body ">
                    <b-media>
                      <div class="text-center py-5">
                        <b-avatar size="5em" :src="selectedChatThread.data.image"></b-avatar>
                        <div class="pt-3 dark-silver">
                          You are now connected with
                          <div>{{ this.getRoleName(selectedChatThread.data.role) }} {{ `${selectedChatThread.data.firstname} ${getFChar(selectedChatThread.data.lastname)}.` }} Say Hello!</div>
                        </div>
                      </div>
                      <ChatBubbleMessage :dataSource="selectedChatThread" :data="messages" :last_seen="last_seen" :userData="userData" :isMessageLoading="isMessageLoading" />
                    </b-media>
                  </div>
                  <div class="text-cb-color" style="text-align: left; margin-left: 10px;" v-if="isSendingFileAttachment">
                    <b-spinner class="align-middle" small></b-spinner>
                    <strong><small> sending file attachment...</small></strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="d-flex py-2 px-2" style="background:var(--white);border-top: 1px solid rgba(0, 0, 0, 0.125);" v-if="selectedChatThread">
              <div class="position-relative w-100">
                <div id="emoji-mart-list" class="row emoji-selector-list" style="display: none">
                  <picker set="emojione" @select="showEmoji" title="Chalkboard Emoji" />
                </div>
                <b-textarea v-model="chatMessage" @focus="onChatMessageFocus" :disabled="isSendingFileAttachment || selectedChatThread.is_active === 0" @keydown.enter.exact.prevent @keyup.enter="sendMessage" class="chat-bubble-box-type-message" placeholder="Type a message.." no-resize></b-textarea>
                <span class="chat-bubble-box-type-message-emoji" v-b-tooltip.hover title="Emoji">
                  <font-awesome-icon class="" :icon="['fas', 'smile']" id="show-emoji-icon" @click="onEmojiClick" />
                </span>
              </div>
              <div class="d-flex align-items-end">
                <div class="chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || selectedChatThread.is_active === 0 }" v-b-tooltip.hover title="Add attachment">
                  <font-awesome-icon class="" v-if="selectedChatThread.is_active === 0" :icon="['fas', 'file']" />
                  <font-awesome-icon class="" v-else :icon="['fas', 'file']" @click="onUploadAttachment" />
                  <b-form-file style="display: none" id="chatFileUpload" v-model="attachFiles" @change="onFileUploadChange($event, selectedChatThread.thread_id)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".jpg, .jpeg, .png, .docx, .doc, .ppt, .xls, .xlsx, .pdf, .zip, .rar"></b-form-file>
                </div>
                <div class="chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || selectedChatThread.is_active === 0 }" v-b-tooltip.hover title="Send message" @click="sendMessage">
                  <font-awesome-icon class="ml-n1" :icon="['fas', 'paper-plane']" />
                </div>
              </div>
            </div>
          </template>
        </b-sidebar>
      </div>

      <!-- Start Display Sharing -->
      <div id="video-session-participants-container" class="video-session-participants-container">
        <!-- Participant Video -->
        <div class="participant-video-frame vs-participant" id="participant-video-side" v-show="this.participantCanJoin">
          <div class="vs-frame-info">
            <div :class="{ 'vs-frame-muted': this.participantMic && !this.participantMic.isEnabled, 'd-none': this.participantMic && this.participantMic.isEnabled }">
              <font-awesome-icon :icon="['fas', 'microphone']" />
            </div>
            <div id="participant-user" class="vs-frame-name">{{ this.participantUser }}</div>
            <!-- Show only when participant is disconnected -->
            <div class="vs-frame-name" v-if="this.isParticipantLeave">
              Left the room
            </div>
          </div>
        </div>

        <!-- Local User Video -->
        <div class="local-video-frame vs-participant" id="local-video-side">
          <div class="vs-frame-info">
            <div :class="{ 'vs-frame-muted': !isMicEnabled, 'd-none': isMicEnabled }">
              <font-awesome-icon :icon="['fas', 'microphone']" />
            </div>
            <div id="local-name" class="vs-frame-name">{{ this.localUser }}
            </div>
          </div>
        </div>
      </div>
      <!-- End Display Sharing -->
    </div>

    <b-alert v-model="showTop" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="info" dismissible>
      <b-icon icon="info-circle-fill" scale="2" variant="info" class="mr-2"></b-icon> {{ topAlertMessage }}
    </b-alert>

    <b-toast id="my-toast" style="max-width: 150px!important;" variant="primary" no-auto-hide no-close-button>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Remaining Session Time</strong>
          <small class="text-muted mr-2"></small>
        </div>
      </template>
      {{ stopWatch }}
    </b-toast>

    <!-- MODAL POPUP SYSTEM CHECKING -->
    <b-modal id="vs-settings-modal" title="Settings" body-class="vs-settings-modal-body" header-class="vs-settings-modal-header" size="lg" hide-footer no-close-on-backdrop hide-header no-close-on-esc>
      <SessionPreview @join-session="onJoinSession" />
    </b-modal>

    <!-- START MODAL POPUP SESSION COMPLETED -->
    <b-modal id="complete-room-button-modal" size="md" no-close-on-backdrop no-close-on-esc hide-header centered>
      <h6 class="text-center dark-grey font-weight-bold pt-4">Great work today!</h6>
      <b-img class="d-block mb-4 mx-auto img-fluid w-25" :src="require('@/assets/imgs/completed-session.png')"></b-img>
      <!-- If Parent/Learner Role -->
      <p v-if="!isTutor" class="text-center font-weight-medium dark-grey px-4 mb-1">Your session has ended. Would you like to leave a review for your tutor?</p>

      <!-- If Tutor Role -->
      <p  v-if="isTutor" class="text-center font-weight-medium dark-grey px-4 mb-1">Your session has ended. Please create your lesson logs for your learner through our Scribook.</p>

      <template #modal-footer="{ }">
        <div class="text-center m-auto">

          <!-- If Parent/Learner Role  -->
          <div v-if="!isTutor">
            <!-- Open Rating and Reviews Modal -->
            <b-button class=" d-block mx-auto" style="font-size: var(--fs-one);font-weight: 600;padding: 0.4em 1em;background:var(--light-blue);border-radius:15em;color:var(--white);margin-bottom: 1em;" @click="onShowReviewAndScribookModal">
              Review tutor
            </b-button>
            <!--  Close virtual classroom -->
            <b-button size="sm" style="background:none;color:var(--grey);text-decoration: underline;" class="pb-4" @click="onLeaveRoom">
              No, thanks
            </b-button>
          </div>

          <!-- If Tutor Role -->
          <div v-if="isTutor">
            <!-- Open Scribook Modal -->
            <b-button class=" d-block mx-auto" style="font-size: var(--fs-one);font-weight: 600;padding: 0.4em 1em;background:var(--orange);border-radius:15em;color:var(--white);margin-bottom: 1em;" @click="onShowReviewAndScribookModal">
              Create Scribook
            </b-button>
            <!--  Close virtual classroom -->
            <b-button size="sm" style="background:none;color:var(--grey);text-decoration: underline;" class="pb-4" @click="onLeaveRoom">
              Skip for now
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- END MODAL POPUP SESSION COMPLETED -->

    <RatingsAndReviews v-if="isRatingsAndReviews" :modalToShow="'RatingsAndReviews'" :mode="'create'" :request="'video-session'" :ratingsAndReviewsData="selectedData"></RatingsAndReviews>
    <Scribook v-if="isShowScribook" :scribookData="scribookData" :isAllowedFromSessionHistory="true" @onScribookCreationModalClose="onScribookCreationModalClose" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins";
import { Picker } from "emoji-mart-vue";
import RatingsAndReviews from "../review/RatingsAndReviews.vue";
import { connect, LocalVideoTrack, LocalAudioTrack, createLocalTracks } from "twilio-video";
import ChatBubbleMessage from "../chat/ChatBubbleMessage.vue";
import chatMixins from "../../mixins/chat";
import SessionPreview from "./SessionPreview.vue";
import Scribook from "../scribook/ScribookCreationModal.vue";

export default {
  name: "SessionVideo",
  components: {
    RatingsAndReviews,
    Picker,
    ChatBubbleMessage,
    SessionPreview,
    Scribook,
  },
  mixins: [mixins, chatMixins],
  data: () => ({
    isRatingsAndReviews: false,
    bookingId: null,
    startTime: null,
    countDownStart: null,
    countDownEnd: null,
    endTime: null,
    showTop: false,
    localVideo: null,
    participantVideo: null,
    topAlertMessage: "",
    showTimer: "",
    stopWatch: "00:00",
    localName: "",
    localUser: "",
    participantUser: "",
    myToken: "",
    roomName: "",
    mainviewcontainer: null,
    tempTrack: "",
    room: "",
    shareScreen: "",
    micbtn: "",
    cambtn: "",
    drawbtn: "",
    leaveRoom: "",
    screenTrack: null,
    isMicEnabled: true,
    isCamEnabled: true,
    isParticipantMicEnabled: true,
    tutorName: "",
    tutorId: "",
    isTutor: false,
    selectedData: [],
    imageUrl: "",
    guestTZ: null,
    mySid: "",
    checkRoomErrorState: false,
    selectedChatThread: null,
    chatMessage: "",
    last_seen: null,
    messages: null,
    isMessageLoading: false,
    attachFiles: [],
    attachFileName: "",
    attachOrigFileName: "",
    isSendingFileAttachment: false,
    selected: true,
    connectedUser: 1,
    participantMic: null,
    subjectLevel: null,
    audioInputDevices: [],
    audioOutputDevices: [],
    videoCameraDevices: [],
    videoDevices: [],
    participantCanJoin: false,
    isPartcinantConnected: false,
    scribookData: {},
    isShowScribook: false,
    networkQuality: "0 mbps",
    isParticipantLeave: false,
    isLocalDisconnected: false,
    isParticipantSharedScreen: false
  }),
  beforeMount() {
    let vm = this;
    if (!window.localStorage.getItem("bookingId")) {
      window.location.href = "/";
    }
    window.addEventListener("beforeunload", vm.onBeforePageUnload);
  },
  mounted() {
    let vm = this;
    vm.$bvModal.show("participant-late-learner");
    vm.$bvModal.show("vs-settings-modal");
    vm.bookingId = window.localStorage.getItem("bookingId");
    vm.drawbtn = document.getElementById("whiteboard-button");
    navigator.mediaDevices
      .enumerateDevices()
      .then(vm.availableDevices)
      .catch(vm.handleError);
    vm.$watch("$store.state.userData", (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
      },
      { immediate: true }
    );
    window.addEventListener("mouseup", function (event) {
      let emoji = document.getElementById("emoji-mart-list");
      if (emoji) {
        if (
          event.target.parentNode != emoji &&
          !document.getElementById("emoji-mart-list")?.contains(event.target)
        ) {
          emoji.style.display = "none";
        }
      }
    });
    this.loadTawkTo()
  },
  computed: {
    ...mapState(["userData", "chatThreads"]),
    onCamOnOff() {
      let vm = this;
      return !vm.isCamEnabled ? "vs-icon vs-inactive" : "vs-icon vs-active";
    },
    onMicOnOff() {
      let vm = this;
      return !vm.isMicEnabled ? "vs-icon vs-inactive" : "vs-icon vs-active";
    },
  },
  destroyed() {
    let vm = this;
    clearInterval(vm.wifiStrength);
    vm.room.disconnect();
    window.close();
  },
  methods: {
    loadTawkTo() {
      var Tawk_API = Tawk_API || {};
      let s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/6061c66df7ce18270934ff86/1f1uuu83b";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);

      const tawkWidget = setInterval(() => {
        if (document.querySelector('.widget-visible')) {
          const iframesInt = setInterval(() => {
            const iframes = Array.prototype.slice.call(document.querySelector('.widget-visible').querySelectorAll('iframe'), 0)
            if (iframes.length) {
              clearInterval(iframesInt)
              iframes.forEach(x => {
                const tawkContainer = setInterval(() => {
                  if (x.contentDocument.body.querySelector('.tawk-min-container')) {
                    clearInterval(tawkContainer)
                    x.contentDocument.body.querySelector('.tawk-text-truncate').innerHTML = 'Contact Support'
                  }
                })
              })
            }
          })
          clearInterval(tawkWidget)
        }
      })
    },
    onBeforePageUnload() {
      let vm = this;
      clearInterval(vm.wifiStrength);
      vm.room.disconnect();
    },
    onShowReviewAndScribookModal() {
      let vm = this;
      if (vm.isTutor) {
        vm.isShowScribook = true;
      } else {
        vm.selectedData = {
          booking_id: vm.bookingId,
          image: vm.imageUrl,
          tutor_name: vm.tutorName,
        };
        vm.isRatingsAndReviews = true;
      }
      vm.isLocalDisconnected = false;
      vm.$bvModal.hide("complete-room-button-modal");
      vm.room.disconnect();
      window.localStorage.removeItem("bookingId");
    },
    onScribookCreationModalClose() {
      this.$router.push("/schedule/session-history");
    },
    startVideo() {
      let vm = this;
      const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/video/check-room";
      const options = {
        params: {
          booking_id: this.bookingId,
        },
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };
      this.axios
        .get(ApiURL, options)
        .then((response) => {
          const _data = response.data;
          if (_data.status != 200) {
            vm.ShowModal(_data.data.message);
          } else {
            if (_data.data.token != null) {
              vm.countDownStart = vm.moment.utc(_data.data.starttime).tz(this.guestTZ);
              vm.countDownEnd = vm.moment.utc(_data.data.endtime).tz(this.guestTZ);
              vm.startTime = vm.countDownStart.format("hh:mm A");
              vm.endTime = vm.countDownEnd.format("hh:mm A");
              vm.tutorName = _data.data.tutor_name;
              vm.imageUrl = _data.data.image || "";
              vm.isTutor = _data.data.role === "teacher" ? true : false;
              vm.localUser = _data.data.role === "teacher" ? _data.data.tutor_name : _data.data.learner_name;
              vm.localRole = _data.data.role === "teacher" ? _data.data.role : "User";
              vm.localUserId = _data.data.role === "teacher" ? _data.data.tutor_id : _data.data.user_id;
              vm.participantUser = _data.data.role === "teacher" ? _data.data.learner_name : _data.data.tutor_name;
              vm.participantRole = _data.data.role === "teacher" ? _data.data.role : "User";
              vm.participantUserId = _data.data.role === "teacher" ? _data.data.tutor_id : _data.data.user_id;
              vm.myToken = _data.data.token;
              vm.roomName = _data.data.room;
              vm.subjectLevel = _data.data.subject_level;
              vm.participantCanJoin = _data.data.can_join;
              vm.scribookData = {
                scribook: { scribook_id: 0, scribook_allow_edit: 0 },
                bookings: {
                  booking_id: vm.bookingId,
                  subject: vm.subjectLevel.split("-")[0].trim(),
                  grade_level: vm.subjectLevel.split("-")[1].trim(),
                  start_date: _data.data.starttime,
                },
                learner: {
                  name: _data.data.learner_name || "",
                  image: _data.data.leaner_picture || "",
                },
              };
              vm.onStartTimer();
              if(_data.data.is_tutor_late && vm.localRole == "teacher"){
                 vm.$bvToast.show("participant-late-tutor");
              }
              if (vm.localRole == "teacher" || vm.participantCanJoin)
                vm.addLocalVideo();
              else {
                vm.$bvToast.show("participant-waiting");
                connect(vm.myToken);
                vm.onCheckUserAllowToJoin();
              }
            } else {
              this.showTop = true;
              this.topAlertMessage = _data.data.message;
            }
          }
        })
        .catch(() => {
          this.checkRoomErrorState = true;
        });
    },
    onStartTimer() {
      let vm = this,
        sessionTimer = document.getElementById("session-timer"),
        currentDateTime,
        classRoomScheduleTime,
        bufferedTime,
        timer,
        seconds,
        minutes;

      setInterval(() => {
        currentDateTime = this.moment.tz(this.guestTZ);
        classRoomScheduleTime = this.moment(this.countDownStart);
        if (currentDateTime >= classRoomScheduleTime) {
          bufferedTime = this.moment(this.countDownEnd);
          timer = bufferedTime - currentDateTime;
          seconds = Math.floor((timer / 1000) % 60);
          minutes = Math.floor((timer / (1000 * 60)) % 60);
          if (minutes == 0 && seconds == 0) {
            vm.$bvToast.hide("my-toast");
            vm.$bvModal.show("complete-room-button-modal");
          }
          if (minutes < 10 && !sessionTimer.classList.contains("blink-timer")) {
            sessionTimer.classList.add("blink-timer");
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          vm.stopWatch = minutes + ":" + seconds;
        }
      }, 1000);
    },
    addLocalVideo() {
      let vm = this,
        trackElement,
        localVideoTrack;

      createLocalTracks({
        audio: { deviceId: vm.audioInputSelected },
        video: { deviceId: vm.videoCameraSelected },
      })
      .then((localTracks) => {
        localVideoTrack = localTracks.find((track) => track.kind === "video");
        vm.localVideo = vm.localVideo == null ? document.getElementById("local-video") : vm.localVideo;
        trackElement = localVideoTrack.attach();
        trackElement.setAttribute("class", "vs-local");
        vm.setVideoElementStyle(trackElement);
        vm.localVideo.append(trackElement);
        if (vm.isCamEnabled) {
          localVideoTrack.disable();
        }
        return connect(
          vm.myToken,
          {
            name: vm.roomName,
            tracks: localTracks,
            audio: { name: "microphone" },
            video: { name: "camera" },
            bandwidthProfile: {
              video: {
                mode: "collaboration",
                maxSubscriptionBitrate: 2400000,
                dominantSpeakerPriority: "high",
                contentPreferencesMode: "auto",
              },
            },
            preferredVideoCodecs: [{ codec: "VP8" }],
            networkQuality: true,
          },
          (error) => {
            vm.ShowModal(`Unable to connect to Room: ${error.message}`);
          }
        );
      })
      .then((createdRoom) => {
        if (createdRoom) {
          vm.room = createdRoom;
          createdRoom.participants.forEach(vm.participantConnected);
          vm.mySid = createdRoom.localParticipant.sid;
          createdRoom.on("disconnected", vm.localUserDisconnected);
          createdRoom.on("reconnecting", vm.localUserReconnecting);
          createdRoom.on("participantConnected", vm.participantConnected);
          createdRoom.on("participantDisconnected",vm.participantDisconnected);
          //web service request to determine if the participants got disconnected
          createdRoom.on("participantReconnecting",vm.participantReconnecting);
          vm.muteUnmuteHandler();
          vm.camOnOffHandler();
        }
      });
    },
    localUserDisconnected(){
      let vm = this;
      if(vm.isLocalDisconnected){
        alert("You have been disconnected.");
        window.close();
      }
    },
    localUserReconnecting(){
      let vm = this;
      vm.isLocalDisconnected = true;
      console.log("Reconnecting....");
    },
    participantConnected(participant) {
      let vm = this;
      if (vm.mySid !== undefined && (participant !== undefined && vm.mySid !== participant.sid)) {
        if (vm.localRole === "teacher" && !vm.participantCanJoin)
          vm.$bvToast.show("participant-admit");
        vm.isPartcinantConnected = true;
        vm.connectedUser = 2;
        vm.isParticipantLeave = !vm.isPartcinantConnected;
      }
      if (vm.participantCanJoin && vm.isPartcinantConnected) {
        vm.participantVideo = vm.participantVideo == null ? document.getElementById("participant-video") : vm.participantVideo;
        participant.on("trackSubscribed", vm.trackSubscribed);
        participant.on("trackUnsubscribed", vm.trackUnsubscribed);
      }
    },
    participantDisconnected(participant) {
      let vm = this,
        ssContainer = document.getElementById("video-session-participants-container"),
        ssDefaultContainer = document.getElementById("vs-defaultscreen");
      if (participant.state === "connected") 
        vm.participantVideo.remove();
      else if(participant.state === "disconnected" && vm.tempTrack) {
        vm.tempTrack.detach().forEach((element) => {
          element.remove();
          ssContainer.style.display = "";
          ssDefaultContainer.style.display = "";
        });
        vm.participantVideo = null;
        vm.connectedUser = 1;
        vm.isParticipantLeave = true;
      }
    },
    participantReconnecting(participant){
      let vm = this,
        paramData = new FormData();
      
      if (participant.state === "reconnecting"){
          const uri = process.env.VUE_APP_BACKEND_URL + "/api/web/video/videosessionlog";
          const options = {
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `Bearer ${vm.UserToken}`,
            }
          };
          paramData.append("booking_id", vm.bookingId);
          paramData.append("user_id", vm.participantUserId);
          paramData.append("role", vm.participantRole);
          vm.axios.post(uri, paramData, options)
            .catch((error) => {
              vm.$bvToast.toast(error.response.data, {
                title: "System Message",
                variant: "warning",
                solid: true
              });
            });
        }
    },
    trackSubscribed(track) {
      let vm = this,
        trackElement = track.attach(),
        ssContainer = document.getElementById("video-session-participants-container"),
        ssDefaultContainer = document.getElementById("vs-defaultscreen"),
        localVideoSide = document.getElementById("local-video-side"),
        participantVideoSide = document.getElementById("participant-video-side");
        
      vm.mainviewcontainer = vm.mainviewcontainer == null ? document.getElementById("main-view-container") : vm.mainviewcontainer;
      if (track.name == "screen") {
        if (vm.screenTrack != null) {
          vm.onShareStop();
        }
        vm.tempTrack.detach().forEach((element) => {
          element.remove();
          ssContainer.style.display = "flex";
          ssDefaultContainer.style.display = "none";
        });
        localVideoSide.append(vm.localVideo.getElementsByTagName("video")[0]);
        vm.mainviewcontainer.append(trackElement);
        participantVideoSide.append(vm.tempTrack.attach());
        vm.isParticipantSharedScreen = true;
      } 
      else if (track.name == "screenaudio") {
        vm.mainviewcontainer.append(trackElement);
      } 
      else if (track.kind === "audio") {
        vm.participantMic = track;
        track
          .attach()
          .setSinkId(vm.audioOutputSelected)
          .then(() => {
            document.body.appendChild(track.attach());
          });
      } 
      else {
        vm.setVideoElementStyle(trackElement);
        trackElement.setAttribute("class", "vs-participant");
        vm.participantVideo.append(trackElement);
        vm.tempTrack = track;
      }
    },
    trackUnsubscribed(track) {
      let vm = this,
        ssContainer = document.getElementById("video-session-participants-container"),
        ssDefaultContainer = document.getElementById("vs-defaultscreen"),
        localVideoSide = document.getElementById("local-video-side"),
        trackElement;

      track.detach().forEach((element) => {
        element.remove();
      });
      if (track.name == "screen") {
        vm.mainviewcontainer.append(vm.tempTrack.attach());
        vm.tempTrack.detach().forEach((element) => {
          element.remove();
          ssContainer.style.display = "none";
          ssDefaultContainer.style.display = "";
        });
        vm.localVideo.append(localVideoSide.getElementsByTagName("video")[0]);
        trackElement = vm.tempTrack.attach();
        vm.setVideoElementStyle(trackElement);
        vm.participantVideo.append(trackElement);
        vm.isParticipantSharedScreen = false;
      }
    },
    onShareScreenHandler() {
      let vm = this,
        screenAudio,
        trackElement;

      if(!vm.isPartcinantConnected){
         vm.$bvToast.toast("Could not share the screen, "+ vm.participantUser + " not yet connected", {
          title: "System Message",
          variant: "info",
          solid: true,
        });
      }
      else if(vm.isParticipantSharedScreen){
        vm.$bvToast.toast("Could not share the screen, "+ vm.participantUser + " currently shared screen", {
          title: "System Message",
          variant: "info",
          solid: true,
        });
      }
      else if (!vm.screenTrack) {
        try{
          navigator.mediaDevices
          .getDisplayMedia({ audio: true, video: true })
          .then((stream) => {
            const audioSource = stream.getAudioTracks()[0];
            const videoSource = stream.getVideoTracks()[0];
            vm.screenTrack = LocalVideoTrack(videoSource, { name: "screen" });
            vm.room.localParticipant.publishTrack(vm.screenTrack);
            if (audioSource) {
              screenAudio = LocalAudioTrack(audioSource, {
                name: "screenaudio",
              });
              vm.room.localParticipant.publishTrack(screenAudio);
            }
            vm.screenTrack.mediaStreamTrack.onended = vm.onShareStop;
            vm.tempTrack.detach().forEach((element) => {
              element.remove();
            });
            trackElement = vm.tempTrack.attach();
            vm.setVideoElementStyle(trackElement);
            vm.participantVideo.append(trackElement);
          });
        }
        catch(e){
           vm.$bvToast.toast(e, {
            title: "System Message",
            variant: "warning",
            solid: true,
          });
        }
      }
    },
    onShareStop() {
      let vm = this,
        ssContainer = document.getElementById("video-session-participants-container"),
        ssDefaultContainer = document.getElementById("vs-defaultscreen"),
        trackElement;

      vm.room.localParticipant.unpublishTrack(vm.screenTrack);
      vm.screenTrack.stop();
      vm.screenTrack = null;
      vm.tempTrack.detach().forEach((element) => {
        element.remove();
      });
      trackElement = vm.tempTrack.attach();
      vm.setVideoElementStyle(trackElement);
      ssContainer.style.display = "none";
      ssDefaultContainer.style.display = "";
      vm.participantVideo.append(trackElement);
    },
    muteUnmuteHandler() {
      let vm = this;
      if (vm.room.localParticipant) {
        if (vm.isMicEnabled) {
          vm.room.localParticipant.audioTracks.forEach((publication) => {
            if (publication.trackName !== "screenaudio")
              publication.track.disable();
          });
          vm.isMicEnabled = false;
        } else {
          vm.room.localParticipant.audioTracks.forEach((publication) => {
            if (publication.trackName !== "screenaudio")
              publication.track.enable();
          });
          vm.isMicEnabled = true;
        }
      }
    },
    camOnOffHandler() {
      let vm = this;
      if (vm.room.localParticipant) {
        if (vm.isCamEnabled) {
          vm.room.localParticipant.videoTracks.forEach((publication) => {
            if (publication.trackName !== "screen") publication.track.disable();
          });
          vm.isCamEnabled = false;
        } else {
          vm.room.localParticipant.videoTracks.forEach((publication) => {
            if (publication.trackName !== "screen") publication.track.enable();
          });
          vm.isCamEnabled = true;
        }
      }
    },
    onLeaveRoom() {
      const vm = this;
      if(vm.room){
        vm.room.disconnect();
      }      
      window.close();

    },
    showEmoji(emoji) {
      this.chatMessage = this.chatMessage + emoji.native;
    },
    onEmojiClick() {
      const emojis = document.getElementById("emoji-mart-list");
      emojis.style.display = "block";
      emojis.style.position = "absolute";
      emojis.style.top = "-420px";
    },
    onUploadAttachment() {
      document.getElementById("chatFileUpload").click();
    },
    sendMessage() {
      if (this.chatMessage === "") return;
      const attachment =
        this.attachOrigFileName === "" ? null : this.attachOrigFileName;
      if (attachment === null) {
        this.messages.push({
          thread_id: this.selectedChatThread.thread_id,
          user_id: this.userData.user_id,
          message: this.chatMessage,
          created_at: this.moment.tz(this.userData.timezone).utc(),
          attachment_name: null,
          attachment_url: null,
        });
      }
      const param = {
        thread_id: this.selectedChatThread.thread_id,
        message: this.chatMessage,
        server_attachment: this.attachFileName,
        attachment_file: this.attachOrigFileName,
        recipient: this.selectedChatThread.data.user_id,
      };
      const formData = new FormData();
      Object.keys(param).forEach((key) => {
        formData.append(key, param[key]);
      });

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/send-chat`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      this.axios
        .post(ApiURL, formData, options)
        .then((response) => {
          if (attachment !== null) {
            this.messages.push({
              thread_id: this.selectedChatThread.thread_id,
              user_id: this.userData.user_id,
              message: this.chatMessage,
              created_at: this.moment.tz(this.userData.timezone).utc(),
              attachment_name: attachment,
              attachment_url: response.data.data.attachment_url,
            });
          }
        })
        .finally(() => {
          if (attachment !== null) {
            this.chatMessage = "";
            this.last_seen = null;
            this.scrollToBottom();
            this.isSendingFileAttachment = false;
            this.attachFiles = [];
            this.attachFileName = "";
            this.attachOrigFileName = "";
          }
        });
      if (attachment === null) {
        this.chatMessage = "";
        this.last_seen = null;
        this.scrollToBottom();
      }
    },
    onMinimizeChatBox() {
      this.selectedChatThread = null;
    },
    onChatBoxClose() {
      this.selectedChatThread = null;
    },
    onChatClick() {
      this.selectedChatThread = [];
      const chatData = JSON.parse(localStorage.getItem("chatData"));
      const obj = this.chatThreads
        .filter((x) =>
            x.chat_participants.filter((c) =>
                c.user_id === (this.userData.role === "teacher" ? this.userData.user_id : chatData.tutor_id)
            ).length > 0 &&
            x.chat_participants.filter((c) => c.user_id === chatData.user_id)
              .length > 0
        )
        .map((x) => {
          return {
            thread_id: x.id,
            created_at: x.created_at,
            data: x.chat_participants.filter((x) => {
              return x.user_id !== this.userData.user_id;
            })[0],
            is_read:
              x.chat_participants.filter((x) => {
                return x.user_id === this.userData.user_id;
              })[0]?.last_read !== null
                ? true
                : false,
            sortH: this.moment(
              x.messages[0]?.created_at || x.created_at
            ).format("MM-DD-YYYY HH:mm:ss"),
            is_active: x.is_active,
          };
        });
      this.selectedChatThread = obj[0];
      this.last_seen =
        obj[0].data.last_read !== null
          ? this.moment
              .tz(obj[0].data.last_read, "UTC")
              .tz(this.userData.timezone)
              .format("hh:mm A ll")
          : null;
      setTimeout(() => {
        const msgTextarea = document.querySelector(".chat-bubble-box-type-message");
        if (msgTextarea) {
          msgTextarea.addEventListener("keyup", () => {
            msgTextarea.style.height = "45px";
            msgTextarea.style.height = msgTextarea.scrollHeight + "px";
          });
        }
      }, 250);
      this.isMessageLoading = true;
      this.messages = null;
      this.$store
        .dispatch("loadChatMessages", {
          thread_id: obj[0].thread_id,
          search_keyword: "",
        })
        .then((response) => {
          this.messages = response.data.data;
          this.scrollToBottom();
        })
        .finally(() => {
          this.isMessageLoading = false;
        });
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${obj[0].data.user_id}`).stopListening(".Modules\\Message\\Events\\Web\\SendChatMessage");
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${obj[0].data.user_id}`)
        .listen(".Modules\\Message\\Events\\Web\\SendChatMessage", (e) => {
          this.chatNotifSound();
          this.messages.push(e);
          this.scrollToBottom();
        });
      window.Echo.private(`chat-user-status.${obj[0].data.user_id}`)
        .listen(".Modules\\Message\\Events\\Web\\ChatUserStatus",(e) => {
          this.selectedChatThread.data.chat_status = e.status;
        }
      );
      window.Echo.private(`read-thread-message.${obj[0].thread_id}.${obj[0].data.user_id}`)
        .listen(".Modules\\Message\\Events\\Web\\MarkMessageRead", (e) => {
        this.last_seen = this.moment
          .tz(e.last_seen, "UTC")
          .tz(this.userData.timezone)
          .format("hh:mm A ll");
        this.scrollToBottom();
      });
      this.$store.dispatch("setUserChatStatus", {
        user_id: this.userData.user_id,
        status: "online",
      });
    },
    onViewProfile(tutorId) {
      if (this.userData.role !== "teacher") {
        window.localStorage.setItem("tutorId", tutorId);
        let routeData = this.$router.resolve({
          name: "search_teacher_profile",
        });
        window.open(routeData.href, "_blank");
      }
    },
    scrollToBottom() {
      const chatBubbleBoxBody = document.getElementsByClassName('b-sidebar-body')[0];
      if (chatBubbleBoxBody)
        setTimeout(() => {
          chatBubbleBoxBody.scrollTo({
            top: chatBubbleBoxBody.scrollHeight,
            behavior: "smooth",
          });
        }, 100);
    },
    onChatMessageFocus() {
      if (this.last_seen !== null) {
        this.readMessage();
      }
    },
    readMessage() {
      const payload = {
        thread_id: this.selectedChatThread.thread_id,
        is_read: 0,
      };
      this.$store.dispatch("toggleReadMessage", payload);
    },
    onFileUploadChange(e, thread_id) {
      let file = e.dataTransfer || e.target;
      file = file.files[0];
      if (file) {
        const validSize = [
          { type: "file", size: "5000" },
          { type: "photo", size: "5000" },
        ];
        let allowedExt = [
          {
            ext: [
              "txt",
              "docx",
              "doc",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "pdf",
              "zip",
              "rar",
            ],
            type: "file",
          },
          { ext: ["jpg", "jpeg", "png"], type: "photo" },
        ];
        const filename = file.name.toLowerCase();
        const ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        const fileType = allowedExt
          .filter((x) => {
            return x.ext.includes(ext);
          })
          .map((x) => x.type)[0];
        const allowedSize = parseInt(
          validSize
            .filter((x) => {
              return x.type === fileType;
            })
            .map((x) => x.size)[0]
        );
        allowedExt = [].concat
          .apply(
            [],
            allowedExt.map((x) => x.ext)
          )
          .join("|");
        const pattern = new RegExp(`(${allowedExt})$`);
        if (file.size / 1024 > allowedSize) {
          this.ShowModal(
            `The ${fileType} that you are trying to upload is too large.<br /><br /><b>Upload Details:</b><br />
              Name: ${file.name}<br />
              Size: ${this.formatBytes(file.size)}<br />
              Type: ${
                ext === "rar" ? "application/x-rar-compressed" : file.type
              }`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            },
            `<strong>${this.convertByteToMegaByte(
              allowedSize
            )}MB Uploading Limit</strong>`
          );
          return;
        } else if (!pattern.test(filename)) {
          this.ShowModal(
            `The file must be a file of type: ${allowedExt.replaceAll(
              "|",
              ", "
            )}.`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            }
          );
          return;
        }
        const formData = new FormData();
        const params = {
          attachment_file: file,
          thread_id: thread_id,
        };
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/upload-chat-attachment`,
          options = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };
        this.isSendingFileAttachment = true;
        this.axios
          .post(ApiURL, formData, options)
          .then((response) => {
            if (response.status === 200) {
              this.attachFileName = response.data.data.server_attachment;
              this.attachOrigFileName = response.data.data.original_name;
              this.chatMessage = this.attachOrigFileName;
              this.sendMessage();
            } else {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            }
          })
          .catch(() => {
            this.attachFiles = [];
            this.attachFileName = "";
            this.attachOrigFileName = "";
          })
          .finally(() => {});
      }
    },
    onCheckWifiStrength() {
      let vm = this,
        wifi = document.getElementById("wifi"),
        endTime = null,
        startTime = null,
        testConnectionSpeed = {
          imageAddr: "https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg", // will move this in server repository if server can handle the request
          downloadSize: 5245329, // Must match the file above (from your server ideally)
          run: function (mbps_max, cb_gt, cb_lt) {
            testConnectionSpeed.mbps_max = parseFloat(mbps_max)
              ? parseFloat(mbps_max)
              : 0;
            testConnectionSpeed.cb_gt = cb_gt;
            testConnectionSpeed.cb_lt = cb_lt;
            testConnectionSpeed.InitiateSpeedDetection();
          },
          InitiateSpeedDetection: function () {
            window.setTimeout(testConnectionSpeed.MeasureConnectionSpeed, 1);
          },
          result: function () {
            let duration = (endTime - startTime) / 1000;
            let bitsLoaded = testConnectionSpeed.downloadSize * 8;
            let speedBps = (bitsLoaded / duration).toFixed(2);
            let speedKbps = (speedBps / 1024).toFixed(2);
            let speedMbps = (speedKbps / 1024).toFixed(2);
            if ( speedMbps >=(testConnectionSpeed.max_mbps ? testConnectionSpeed.max_mbps : navigator.connection.downlink))
              testConnectionSpeed.cb_gt ? testConnectionSpeed.cb_gt(speedMbps) : false;
            else
              testConnectionSpeed.cb_lt ? testConnectionSpeed.cb_lt(speedMbps) : false;
          },
          MeasureConnectionSpeed: function () {
            let download = new Image();
            download.onload = function () {
              endTime = new Date().getTime();
              testConnectionSpeed.result();
            };
            startTime = new Date().getTime();
            let cacheBuster = "?nnn=" + startTime;
            download.src = testConnectionSpeed.imageAddr + cacheBuster;
          },
        },
        wifiStrength = 100;
      if (navigator.connection.downlink < 1.5) {
        wifi.className = "wifi weak";
        vm.networkQuality = navigator.connection.downlink + " Mbps";
        vm.$bvToast.show("participant-unstable-network");
      } else {
        // start test immediatly, you could also call this on any event or whenever you want
        testConnectionSpeed.run(
          navigator.connection.downlink,
          function (mbps) {
            vm.networkQuality = mbps + " Mbps";
            wifiStrength = (mbps / navigator.connection.downlink) * 100;

            if (wifiStrength >= 75) {
              wifi.className = "wifi";
            } else if (wifiStrength >= 50 && wifiStrength < 75) {
              wifi.className = "wifi medium";
            } else if (wifiStrength > 0 && wifiStrength < 50) {
              wifi.className = "wifi weak";
              vm.$bvToast.show("participant-unstable-network");
            } else if (wifiStrength === 0) {
              wifi.className = "wifi none";
            }
          },
          function (mbps) {
            vm.networkQuality = mbps + " Mbps";
            wifiStrength = (mbps / 100) * 100;

            if (wifiStrength >= 75) {
              wifi.className = "wifi";
            } else if (wifiStrength >= 50 && wifiStrength < 75) {
              wifi.className = "wifi medium";
            } else if (wifiStrength > 0 && wifiStrength < 50) {
              wifi.className = "wifi weak";
              vm.$bvToast.show("participant-unstable-network");
            } else if (wifiStrength === 0) {
              wifi.className = "wifi none";
            }
          }
        );
      }
    },
    setVideoElementStyle(trackElement) {
      trackElement.style.flex = 0.5;
      trackElement.style.width = "40em";
      trackElement.style.maxHeight = "64em";
      trackElement.style.minHeight = "25em";
      trackElement.style.position = "relative";
      trackElement.style.background = "#353535";
      trackElement.style.borderRadius = "20px";
      trackElement.style.transform = "scale(-1, 1)";
    },
    onJoinSession(record) {
      let vm = this;
      vm.audioInputSelected = record.audioInputSelected;
      vm.audioOutputSelected = record.audioOutputSelected;
      vm.videoCameraSelected = record.videoCameraSelected;
      vm.isCamEnabled = !record.isCamEnabled;
      vm.isMicEnabled = !record.isMicEnabled;
      vm.startVideo();
      vm.$bvModal.hide("vs-settings-modal");
      vm.wifiStrength = setInterval(() => {
        vm.onCheckWifiStrength();
      }, 1500);
      vm.muteUnmuteHandler();
      vm.camOnOffHandler();
      vm.onLoadTawkTo();
    },
    allowUserToJoin() {
      let vm = this,
        paramData = new FormData();
      paramData.append("booking_id", vm.bookingId);
      const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/video/allowusertojoin",
        options = {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${this.UserToken}`,
          },
        };
      vm.axios
        .post(ApiURL, paramData, options)
        .then((response) => {
          if (response.status == 200) {
            vm.$bvToast.hide("participant-admit");
            vm.participantCanJoin = true;
            vm.isParticipantLeave = false;
            vm.participantConnected();
          }
        })
        .catch((error) => {
          if(error.response){
            alert(error.response.data);
          }
        });
    },
    onCheckUserAllowToJoin() {
      let vm = this;
      vm.userallowToJoin = setInterval(() => {
        const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/video/checkusercanjoin";
        const options = {
          params: { booking_id: vm.bookingId },
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${vm.UserToken}`,
          },
        };
        vm.axios.get(ApiURL, options).then((response) => {
          if (response.data && response.data.can_join && !vm.participantCanJoin) {
            vm.participantCanJoin = response.data.can_join;
            vm.$bvToast.hide("participant-waiting");
            vm.addLocalVideo();
            clearInterval(vm.userallowToJoin);
          }
        });
      }, 2500);
    },
    onClickProceed() {
      let vm = this;
      vm.$router.go(vm.$router.currentRoute);
    },
    //end methods
  },
};
</script>

<style scoped>
.img-thumbnail {
  background: none;
  border: none;
}

#main-view-container {
  height: 100%;
  background: transparent;
  position: relative;
}
#main-view-container >>> video {
  height: 100%;
  object-fit: fill;
  width: 100%;
}

label {
  cursor: pointer;
}
input:checked ~ .vs-timer {
  width: 67px;
}
.vs-timer {
  width: 0;
  transition: width 500ms linear;
}

.vs-timer-container {
  position: relative;
}
.vs-xd {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.main {
  background-color: var(--dark-grey);
  height: 100vh;
  width: 100%;
}
.video-session-container {
  height: 100%;
  width: 100%;
  display: flex;
}
.video-session-body {
  position: relative;
  width: 100%;
}
.video-session-header {
  background: #353535;
  padding: 0.5em;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.video-session-frame-container {
  width: 100%;
  padding: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em 1em 6.5em;
  position: absolute;
  height: 100%;
}

.vs-defaultscreen {
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.participant-video-frame,
.local-video-frame {
  flex: 1;
  position: relative;
  height: 300px;
  border-radius: 20px;
  background: #353535;
  max-width: 55%;
  width: 100%;
}

.participant-video-frame >>> video,
.local-video-frame >>> video {
  max-height: 25em !important;
  min-height: 15em !important;
  width: 100% !important;
  object-fit: cover;
  height: 300px;
}

.vs-participant {
  flex: 1;
  max-width: 25em;
  border-radius: 20px;
  position: relative;
  background: #353535;
  z-index: 1;
}
.vs-local {
  flex: 1;
  max-width: 25em;
  border-radius: 20px;
  position: relative;
  background: #353535;
  z-index: 1;
}
.vs-frame-info {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.3em;
  pointer-events: none;
  z-index: 100;
}
.vs-frame-muted {
  height: 2em;
  width: 2em;
  text-align: center;
  margin-right: 0.2em;
  position: relative;
}
.vs-frame-muted::before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to right bottom,
    transparent,
    transparent 44%,
    #f44336 46%,
    #f44336 54%,
    transparent 56%,
    transparent
  ) !important;
}
.vs-frame-muted,
.vs-frame-name {
  font-size: var(--fs-xs);
  background: rgba(34, 34, 34, 50%);
  border-radius: 30px;
  color: var(--light-silver);
  margin-bottom: 10px;
  z-index: 100;
  line-height: 2em;
}

.vs-frame-name {
  padding: 0em 0.5em;
  font-weight: 500;
}
/* .vs-screenshare {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
}
.vs-screenshare video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
} */
.video-session-controls-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.video-session-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.video-session-controls-btn {
  background: transparent;
  text-align: center;
  position: relative;
}

.vs-btn-settings {
  position: absolute;
  top: 0.4em;
  right: 0.5em;
  text-align: center;
  background: #353535;
  border-radius: 100px;
  height: 2em;
  width: 2em;
  line-height: 1.7;
  padding: 0;
  font-size: calc(var(--fs-xs) - 4px);
  border: 2px solid var(--dark-grey);
  color: var(--light-silver);
  font-size: calc(var(--fs-xs) - 2px);
  font-weight: 500;
  padding-top: 0 !important;
}

.video-session-controls-btn > .vs-icon {
  background: #353535;
  border-radius: 100px;
  color: var(--light-silver);
  height: 2.5em;
  width: 2.5em;
  line-height: 2.5;
  font-size: 1.2rem;
  margin: auto;
  position: relative;
}

.vs-icon.vs-inactive {
  color: #7d7d7d;
}

.vs-inactive::after {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to right bottom,
    transparent,
    transparent 44%,
    #f44336 49%,
    #f44336 51%,
    transparent 56%,
    transparent
  ) !important;
}

.video-session-controls-btn > span {
  color: var(--dark-silver);
  font-size: calc(var(--fs-xs) - 2px);
  font-weight: 500;
  padding-top: 0.5em;
}

/* .video-session-controls-btn.leave {
  position: absolute;
  right: 1em;
} */

.video-session-controls-btn.leave > .vs-icon {
  background: var(--red);
}

.video-session-controls-btn.leave:hover > .vs-icon {
  background: #ac3434;
}

/* reset button states */
.video-session-controls-btn:focus,
.video-session-controls-btn.active,
.video-session-controls-btn:hover,
.video-session-controls-btn:active {
  background: unset !important;
}

.video-session-controls-btn:hover > .vs-icon {
  background: #2c2c2c;
  color: var(--white);
  transition: all 300ms ease-in-out;
}
/*  main screen of sharing*/
.video-session-participants-container {
  width: 280px; /* set to 250px */
  overflow-y: scroll;
  border-left: 1.5px solid #353535;
  display: none; /* set to flex */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5em;
  gap: 1em;
}

.video-session-participants-container > .vs-participant {
  flex: unset;
  height: 9em;
  width: 100%;
  max-width: 100%;
}

.participant-video-frame.vs-participant >>> video,
.local-video-frame.vs-participant >>> video {
  position: absolute;
  top: 0;
  object-fit: cover;
  min-height: unset !important;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  border-radius: 20px;
}

/* Settings Modal */
.vs-settings-tabs >>> .nav-link {
  color: var(--dark-grey);
}
.vs-settings-tabs >>> .nav-link.active {
  background: var(--light-blue);
  border-radius: 30px;
  font-weight: 700;
}
.vs-settings-tabs >>> .card-header {
  background: none;
  border-right: 1px solid var(--silver);
}
.vs-settings-tabs >>> .custom-select {
  border-radius: 35px;
}

.vs-settings-tabs
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--light-blue) !important;
  border-color: rgb(0 191 224 / 20%);
}

.vs-settings-btn,
.vs-settings-btn:active,
.vs-settings-btn:focus,
.vs-settings-btn.btn-secondary:not(:disabled):not(.disabled):active {
  border-radius: 30px;
  background: transparent;
  color: var(--dark-grey);
  border: 2px solid var(--light-blue);
  font-weight: 500;
}

.vs-settings-btn:hover {
  background: var(--light-blue);
  background-color: var(--light-blue);
  color: var(--white);
  border: 2px solid var(--light-blue);
  transition: all 200ms ease-in-out;
}

#vs-settings-video-preview {
  background: var(--grey);
  border-radius: 10px;
  min-width: 400px;
  width: 100%;
}

header.vs-chat-header {
  background-color: var(--dark-grey) !important;
  background: var(--dark-grey) !important;
  border-bottom: 1px solid var(--dark-silver) !important;
  color: var(--white) !important;
}
#chat-sidebar .b-sidebar-header {
  background: red;
}
#chat-sidebar.bg-dark {
  background: red !important;
}
.vs-chat-sidebar {
  background: var(--white);
}
.chat-bubble-box {
  position: relative;
  bottom: 0;
  height: auto;
}

.chat-bubble-box-avatar {
  position: relative;
}

.chat-bubble-box-avatar-status {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 0.525em;
  height: 0.525em;
}
.chat-bubble-box-dropdown-icon {
  border-top: 0.3em var(--light-blue) solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-top: 1.3em;
  margin-left: 1em;
}

.chat-bubble-box-avatar-status.online {
  background: var(--teal);
}

.chat-bubble-box-avatar-status.offline {
  background: var(--dark-silver);
}

.chat-bubble-box-body {
  padding: 1em;
  height: 100%;
  scrollbar-width: thin;
}

.chat-bubble-box-body::-webkit-scrollbar {
  width: 10px;
}

.chat-bubble-box-body::-webkit-scrollbar-track {
  background: var(--light-silver);
}

.chat-bubble-box-body::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
  border-radius: 10px;
}

.chat-bubble-box-body::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}

.chat-bubble-box-body .media-aside {
  margin-right: 0.625rem;
}

.vs-chat-sidebar >>> .chat-bubble-box-message {
  display: inline-block;
  background: var(--light-silver);
  color: var(--dark-grey);
  font-size: calc(var(--fs-one) - 4px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
}
.vs-chat-sidebar >>> .chat-bubble-box-message-status {
  display: inline-block;
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
}
.vs-chat-sidebar >>> .chat-bubble-box-message-status.me {
  float: right;
}

.vs-chat-sidebar >>> .chat-bubble-box-message.me {
  background: var(--light-blue);
  color: var(--white);
  float: right;
}

.vs-chat-sidebar >>> .chat-bubble-box-message a {
  font-size: calc(var(--fs-one) - 4px);
}

.chat-bubble-box-type-message:focus,
.chat-bubble-box-type-message:active {
  background-color: #353535;
  outline: none;
  box-shadow: none;
  color: var(--dark-grey);
}
.chat-bubble-box-type-message::placeholder {
  color: var(--dark-silver);
}
.form-control.chat-bubble-box-type-message {
  background: #f0f2f5;
  border-radius: 25px;
  color: var(--dark-grey);
  line-height: 1.2;
  overflow-y: unset !important;
  padding-top: 0.82em;
  padding-left: 0.82em;
  padding-right: 2.725em;
  word-break: break-all;
  height: 45px;
  max-height: 75px;
  border: none;
  font-size: calc(var(--fs-one) - 2px);
  scrollbar-width: thin;
}

.form-control:focus.chat-bubble-box-type-message {
  background-color: #f0f2f5;
  outline: none;
  box-shadow: none;
}

.chat-bubble-box-type-message-emoji {
  position: absolute;
  right: 25px;
  bottom: 0;
  font-size: 20px;
  line-height: 2.2;
  color: var(--dark-silver);
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f0f2f5;
  text-align: center;
  line-height: 2.2;
  margin-left: 0.425rem;
  color: var(--dark-silver);
  font-size: 20px;
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons:hover {
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  transition: all ease 500ms;
}

.chat-bubble-box-type-message-buttons.disabled,
.chat-bubble-box-type-message-buttons.disabled:hover {
  cursor: not-allowed;
  color: var(--dark-silver);
}

.chat-bubble-box-tutor-name {
  font-weight: 500;
  color: var(--dark-grey);
  cursor: pointer;
  max-width: 10em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-bubble-box-status {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
}
.chat-bubble-box-control {
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  font-size: calc(var(--fs-one) - 2px);
  font-weight: normal;
  line-height: 1.4;
  cursor: pointer;
}

.chat-bubble-head-container {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.chat-bubble-head {
  border-radius: 50%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  position: relative;
}
.chat-bubble-head-red-notif {
  position: absolute;
  top: 0;
  right: -2px;
  background: #f95656;
  border-radius: 50%;
  width: 0.825em;
  height: 0.825em;
  text-align: center;
}
.chat-bubble-head-close {
  position: absolute;
  top: 0;
  right: -3px;
  display: none;
  background: var(--white);
  border-radius: 50%;
  color: var(--dark-grey);
  width: 1.225em;
  height: 1.225em;
  text-align: center;
  line-height: 1.4;
  font-size: calc(var(--fs-one) - 4px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}
.chat-bubble-head:hover > .chat-bubble-head-close {
  display: block;
}
.text-cb-color {
  color: var(--light-blue) !important;
}

.b-toast.vs-toast {
  max-width: 550px;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15em;
  z-index: 9999;
}

.b-toast.vs-toast >>> .toast {
  background-color: unset !important;
  max-width: 550px;
  border-radius: 15em;
}
.vs-toast >>> .toast-body {
  background-color: #131313 !important;
  border-radius: 15em;
  padding-left: 2em;
  padding-right: 2em;
  color: var(--white);
}

.vs-toast >>> .toast-body > span {
  font-size: 14px;
}

.b-toast >>> .toast.vs-toast.warning {
  background-color: rgba(255, 249, 231, 1) !important;
}

.b-toast >>> .toast.vs-toast.warning .toast-header {
  background-color: rgba(255, 243, 205, 0.85);
  border-bottom-color: rgba(255, 238, 186, 0.85);
  color: #856404;
}

.b-toast >>> .toast.vs-toast.warning .toast-body {
  background: rgba(255, 249, 231, 1) !important;
  background-color: rgba(255, 249, 231, 1) !important;
  color: #856404;
  font-weight: 500;
}

.b-toast-warning .toast.vs-toast {
  background-color:  rgba(255, 249, 231, 1) !important;
}

.vs-toast.warning  >>> .toast-body {
  background:  rgba(255, 249, 231,1) !important;
  background-color:  rgba(255, 249, 231,1) !important;
  border-radius: 15em;
  padding-left: 2em;
  padding-right: 2em;
  color: #856404;
}

.vs-toast.warning  >>> .toast-body > span {
  font-weight: 500;
}

.vs-toast .admit-btn {
  margin-left: 1em;
  background: var(--light-blue);
  border-radius: 15em;
  font-weight: 500;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 14px;
}

/* Participant waiting loader */
.participant-waiting-loader {
  margin: 0 auto;
  border: 4px solid #3e3e3e;
  border-radius: 50%;
  border-top: 4px solid var(--light-blue);
  border-right: 4px solid var(--light-blue);
  border-bottom: 4px solid var(--light-blue);
  width: 20px;
  height: 20px;
  -webkit-animation: waiting-spin 2s linear infinite;
  animation: waiting-spin 2s linear infinite;
}

@-webkit-keyframes waiting-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes waiting-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.vs-settings-modal-header > h5.modal-title {
  font-size: var(--fs-one);
}
.vs-settings-modal-body.modal-body {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .video-session-header,
  .vs-defaultscreen {
    flex-direction: column;
  }
}

@media screen and (min-width: 1280px) {
  .vs-defaultscreen {
    width: 60%;
  }
  .participant-video-frame,
  .participant-video-frame >>> video,
  .local-video-frame,
  .local-video-frame >>> video {
    height: 300px;
  }
}
</style>

<style>
.chat-header-custom {
  background: var(--white);
  flex-direction: row-reverse !important;
}
.chat-header-custom.b-sidebar-header .close {
  margin-right: unset !important;
  margin-left: auto;
}

.b-sidebar-body::-webkit-scrollbar {
  width: 10px;
}

.b-sidebar-body::-webkit-scrollbar-track {
  background: var(--light-silver);
}

.b-sidebar-body::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
  border-radius: 10px;
}

.b-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}
</style>

<style lang="scss">
/* WIFI SIGNAL */
@mixin getWifi($size) {
  $anim: 1.5s linear infinite;
  $border-width: $size/2.35;
  $line-color: #fff;
  $line-color-weak: rgba($line-color, 0.4);
  $trans: 0.3s linear;

  box-sizing: border-box;
  background-color: transparent;
  border: $size/2 solid transparent;
  border-top-color: $line-color;
  border-radius: $size / 2;
  height: $size;
  position: absolute;
  left: 20px;
  top: 28px;
  transition: border-top-color $trans;
  width: $size;

  &:before,
  &:after {
    content: "";
    box-sizing: border-box;
    background-color: transparent;
    border: $border-width/1.2 solid transparent;
    border-top-color: $line-color;
    position: absolute;
    transition: border-top-color $trans;
  }

  &:before {
    border-radius: $size;
    height: $size * 2;
    left: -$size;
    top: -$size;
    width: $size * 2;
  }

  &:after {
    border-radius: $size * 3;
    height: $size * 3;
    left: -$size * 1.5;
    top: -$size * 1.5;
    width: $size * 3;
  }

  &.loading {
    animation: blink-1 $anim;

    &:before {
      animation: blink-2 $anim;
    }

    &:after {
      animation: blink-3 $anim;
    }
  }

  &.none:not(.loading) {
    border-top-color: $line-color-weak;

    &:before,
    &:after {
      border-top-color: $line-color-weak;
    }
  }

  &.weak:not(.loading) {
    &:before,
    &:after {
      border-top-color: $line-color-weak;
    }
  }

  &.medium:not(.loading) {
    &:after {
      border-top-color: $line-color-weak;
    }
  }

  @at-root {
    @keyframes blink-1 {
      0% {
        border-top-color: $line-color-weak;
      }

      50%,
      100% {
        border-top-color: $line-color;
      }
    }

    @keyframes blink-2 {
      0%,
      100% {
        border-top-color: $line-color;
      }

      50% {
        border-top-color: $line-color-weak;
      }
    }

    @keyframes blink-3 {
      0%,
      50% {
        border-top-color: $line-color;
      }

      100% {
        border-top-color: $line-color-weak;
      }
    }
  }
}

.wifi {
  @include getWifi(15px);
  transform: translate(-25%);
}

.blink-timer {
  animation: blinker 2s linear infinite;
}

#leave-room-button-modal .modal-footer,
#complete-room-button-modal .modal-footer {
  border-top: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
    color: firebrick;
    font-weight: bold;
  }
}
</style>

export default { 
  FOR_EMAIL_VERIFICATION  : 'TSSFEV',
  DRAFT                   : 'TSDRFT',
  NEW                     : 'TSNEW',
  FOR_SCREENING           : 'TSSCRN',
  REJECTED                : 'TSRJCT',
  RETURN_APPLICATION      : 'TSRNAN',
  RESUBMIT                : 'TSSBMT',
  ORIENTATION             : 'TSORNT',
  ACTIVE                  : 'TSACTV',
  VERIFIED                : 'TSVRFD',
  DEACTIVATED             : 'TSDCTV'
}
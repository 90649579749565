<template>
  <section class="mcdo">
    <section class="mcdo__hero">
      <b-img :src="require('@/assets/imgs/promotions/mcdo_banner.png')"></b-img>
      <div class="mcdo__email-container">
        <validation-observer
          ref="promoValidationObserver"
          v-slot="{ handleSubmit }"
        >
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="validationContext"
            >
              <b-form-input
                id="email"
                type="email"
                class="mcdo__email-input"
                required
                placeholder="Enter your email address"
                :state="getValidationState(validationContext)"
                v-model="email"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>

            <b-button
              class="mcdo__email-submit"
              type="submit"
              variant="primary"
              :disabled="isLoading"
            >
              Claim Voucher
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </section>
    <section class="mcdo__how">
      <h5 class="">How it works</h5>
      <b-container>
        <b-row class="mcdo__how-cards-wrapper">
          <b-col sm="12" md="12" lg="4">
            <b-card class="mcdo__how-cards">
              <figure>
                <b-img
                  :src="require('@/assets/imgs/promotions/voucher.png')"
                ></b-img>
              </figure>
              <b-card-title>ENTER YOUR EMAIL ADDRESS ABOVE</b-card-title>
              <b-card-text>
                Submit your email address and our system will assign a unique
                McDonald’s voucher code for you to redeem.
              </b-card-text>
            </b-card>
          </b-col>
          <b-col sm="12" md="12" lg="4">
            <b-card class="mcdo__how-cards">
              <figure>
                <b-img
                  :src="require('@/assets/imgs/promotions/inbox-mail.png')"
                ></b-img>
              </figure>
              <b-card-title>
                CHECK YOUR
                <br />
                EMAIL
              </b-card-title>
              <b-card-text>
                Check your email to view the VOUCHER CODE assigned to you.
                Further instructions will be provided.
              </b-card-text>
            </b-card>
          </b-col>
          <b-col sm="12" md="12" lg="4">
            <b-card class="mcdo__how-cards">
              <figure>
                <b-img
                  :src="
                    require('@/assets/imgs/promotions/calendar-schedule.png')
                  "
                ></b-img>
              </figure>
              <b-card-title>FINALIZE YOUR SCHEDULE!</b-card-title>
              <b-card-text>
                After filling in the form in your email, our Customer Support
                team will reach out to help you book your child’s tutoring
                sessions.
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="mcdo__about-campaign">
      <b-container>
        <b-row>
          <b-col sm="12" md="4" lg="4" class="mb-4">
            <b-img
              fluid
              :src="require('@/assets/imgs/promotions/about.png')"
            ></b-img>
          </b-col>
          <b-col sm="12" md="8" lg="8">
            <h5 class="">
              ABOUT CHALKBOARD x MCDONALD’S HAPPY MEAL READER’S BOOK
            </h5>
            <p>
              Chalkboard is one with McDonald’s and The Learning Network in
              providing opportunities for Filipino children to grow and enjoy
              their youth in a safe and fun environment.
            </p>

            <p>
              With the mission to enhance the learning experience of children,
              Chalkboard is offering 1 month of free Small Group Tutoring
              sessions to all McDonald’s Happy Meal Reader’s Book customers. The
              FREE program comes with eight (8) tutoring sessions with an expert
              tutor.
            </p>

            <p>
              Tutoring sessions are booked and held online through Chalkboard’s
              revolutionary tutoring platform – giving the parents and learners
              utmost convenience and comfort.
            </p>

            <p>
              Redemption of voucher is only up to April 30 so sign up today to
              start experience what Chalkboard has to offer you and your child!
            </p>

            <p>
              Got questions? Send us an email at
              <a
                class="orange font-weight-medium"
                href="mailto:support@chalkboard.com.ph"
                target="_blank"
              >
                support@chalkboard.com.ph
              </a>
              with SUBJECT :
              <strong>MCDO HAPPY MEAL</strong>
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="mcdo__faq" fluid>
      <b-container>
        <h5 class="text-center mb-5 pb-5">
          Mechanics &nbsp; + &nbsp; Terms & Conditions
        </h5>

        <div class="accordion">
          <div>
            <div v-b-toggle.collapse-1 class="mcdo__faq-list">
              MECHANICS
            </div>
            <b-collapse id="collapse-1" accordion="accordion">
              <b-card class="px-4">
                <ul>
                  <li>The campaign will run from April 1 - 30, 2023.</li>

                  <li>
                    Only the first 100 voucher redemptions will qualify and
                    receive a campaign voucher. We will accommodate 10 voucher
                    redemptions per day. The redemption limit refreshes the next
                    day, so you may try to redeem your voucher again.
                  </li>
                  <li>
                    Beneficiaries will enjoy 8 hours worth of tutoring sessions
                    on Math or English.
                  </li>
                  <li>Tutoring session may be scheduled until May 31, 2023.</li>
                  <li>
                    Tutoring sessions will be held virtually through
                    Chalkboard’s website.
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </div>

          <div>
            <div v-b-toggle.collapse-2 class="mcdo__faq-list">
              TERMS & CONDITIONS
            </div>
            <b-collapse id="collapse-2" accordion="accordion">
              <b-card class="px-4">
                <ul>
                  <li>1 learner per parent account only.</li>
                  <li>
                    The voucher is non-transferable and non-convertible to cash
                    or to any other form of promo.
                  </li>
                  <li>
                    Voucher is not valid in conjunction with other promos
                    offered by Chalkboard.
                  </li>
                  <li>
                    Tutoring hours in excess of the 8-hour provision will be
                    charged to the parent.
                  </li>
                  <li>
                    Chalkboard reserves the right to refuse or reject
                    participants of the campaign should there be conflicts on
                    the above Terms & Conditions stated.
                  </li>
                  <li>
                    All unredeemed vouchers will be void at the end of the
                    campaign period.
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </div>
        </div>
      </b-container>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import McDoVoucherClaimed from "../../voucher/McDoVoucherClaimed.vue";
import McDoVoucherLimit from "../../voucher/McDoVoucherLimit.vue";

export default {
  name: "McDonald",

  components: {},

  data() {
    return {
      email: "",
      isLoading: false
    };
  },

  created() {
    /**
     * TODO
     * - redirect user if not from ph
     */
  },

  methods: {
    onSubmit() {
      this.isLoading = true;
      const url = process.env.VUE_APP_BACKEND_URL + "/api/voucher/claim";
      const options = {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      };

      axios
        .post(url, { email: this.email, voucher_code: "MCDO23" }, options)
        .then((response) => {
          const { status, errors } = response.data;
          if (response.status == 201) {
            this.showMcDoVoucherClaimed();
          } else if (status == 422) {
            if (
              (errors.email || []).includes(
                "You can no longer claim this voucher for today, please come back tomorrow"
              )
            ) {
              this.openAppModal(
                McDoVoucherLimit,
                {},
                {
                  id: "mcdo-voucher-limit",
                  maxWidth: "450px"
                }
              );
            } else {
              this.$refs.promoValidationObserver.setErrors(errors);
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 429) {
            alert(
              "You have made too many requests within the past minute. Please try again after a minute passes."
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showMcDoVoucherClaimed() {
      this.openAppModal(
        McDoVoucherClaimed,
        {},
        {
          id: "mcdo-voucher-sent-modal",
          maxWidth: "450px"
        }
      );
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
};
</script>

<style lang="scss" scoped>
$mobile: 480px;
$tablet: 769px;
$laptop: 1024px;
$desktop: 1200px;
.mcdo {
  padding-top: 4em;
  &__hero {
    height: 100%;
    background-color: #f6f6f6;
    position: relative;

    img {
      width: 100%;
      padding-top: 2em;
    }

    &-left {
      background: transparent;
      padding: 2.5rem 10rem 4.5rem 5.5rem;
      position: relative;
      top: 150px;
      left: 0;
      width: 100%;
      max-width: 950px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -175px;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: 5px solid var(--white);
        background: #d9291c;
        transform: skewX(335deg);
      }
    }
  }

  &__happy-meal-box {
    position: relative;
    z-index: 1;
    margin-top: -110px;
    margin-bottom: 1rem;
    img {
      max-width: 140px;
    }
  }

  &__heading {
    font-size: var(--fs-three);
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 2rem;
    color: var(--white);
    z-index: 1;
    position: relative;

    span {
      color: #ffc72c;
      font-size: var(--fs-three);
    }
  }

  &__subheading {
    color: var(--white);
    z-index: 1;
    position: relative;

    p {
      font-size: var(--fs-one);
      font-style: italic;
      max-width: 550px;
    }
  }

  &__how {
    padding: 150px 2rem;
    background: var(--light-silver);
    counter-reset: counter;
    h5 {
      color: var(--dark-blue);
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__email-container {
    border-radius: 25px;
    padding: 2em;
    margin: 5em auto 0;
    background: #d9291c;
    width: 50%;

    @media screen and (max-width: 620px) {
      padding: 1em;
      width: 80%;
    }
    form {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-direction: column;
      span {
        width: 100%;
      }
    }
  }

  &__email-input {
    background: #781710;
    border-radius: 50px;
    border: none;
    color: var(--white);
    text-align: center;
    padding: 1.4em;

    &::placeholder {
      color: var(--white);
    }

    + .invalid-feedback {
      color: var(--white);
      position: initial;
      font-size: calc(var(--fs-one) - 2px);
      text-align: center;
      padding-top: 1em;
    }
  }

  &__email-submit {
    background: #ffc72c !important;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.7em;

    width: 100%;
    color: var(--dark-grey) !important;
    border: none !important;
    box-shadow: none !important;
  }

  &__how-cards {
    text-align: center;
    background-color: unset;
    border: unset;
    counter-increment: counter;
    max-width: 300px;
    margin-inline: auto;

    img {
      max-width: 100px;
      margin-bottom: 1rem;
    }

    &::before {
      content: counter(counter);
      position: relative;
      left: -50px;
      bottom: -70px;
      background: #da281c;
      color: var(--orange);
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 10px solid white;
      text-align: center;
      line-height: 1.825;
      font-size: var(--fs-four);
      font-weight: 900;
    }

    .card-body {
      background: rgb(1, 100, 201);
      background: linear-gradient(
        180deg,
        rgba(14, 191, 224, 1) 0%,
        rgba(11, 152, 179, 1) 100%
      );
      padding: 2rem;
      border-radius: 25px;
    }
    .card-title {
      color: var(--dark-blue);
      font-weight: 600;
      font-size: calc(var(--fs-one) + 2px);
      min-height: 45px;
    }
    .card-text {
      color: var(--white);
      font-size: calc(var(--fs-one) - 2px);
      font-weight: 500;
      line-height: 1.4;
      min-height: 115px;
    }
  }

  &__about-campaign {
    padding: 100px 2rem;
    background: #d9291c;

    h5 {
      color: var(--orange);
      margin-bottom: 2rem;
    }

    p {
      color: var(--white);
    }

    img {
      width: 100%;
      max-width: 80%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__faq {
    position: relative;
    background: #e9e9e9;
    padding: 100px 2rem;

    .accordion > *:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &__faq-list {
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.5rem 1.2rem;
    box-shadow: 62px 41px 30px rgba(209, 209, 209, 0.02),
      35px 23px 25px rgba(209, 209, 209, 0.06),
      15px 10px 19px rgba(209, 209, 209, 0.1),
      4px 3px 10px rgba(209, 209, 209, 0.11),
      0px 0px 0px rgba(209, 209, 209, 0.11);

    color: var(--dark-grey);
    font-weight: 500;
    font-size: var(--fs-one);
    position: relative;
    transition: all 0.25s;

    &.not-collapsed {
      background: var(--orange);
      font-weight: 600;

      + .collapse > .card {
        background: var(--orange-30);
        border-radius: 0.5rem;
      }
    }

    &[aria-expanded="true"]::after {
      transform: translateY(-50%) rotate(1turn);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
      right: 20px;
      width: 12px;
      height: 6px;
      background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:gray;" /></svg>');
      background-size: contain;
      background-repeat: no-repeat;
      transition: transform 0.4s;
    }
  }
}
</style>

<template>
  <b-container class="py-5 my-5">
    <div class="forgot-password-wrapper py-5 my-5">
      
      <div class="forgot-password-container pt-5">
        <h6>Reset your Chalkboard<br>account password</h6>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form class="forgot-password-form pt-4" id="forgot-password-form" @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="onReset">
          <b-form-group id="input-group-1" label="Enter your email address" label-for="email" description="Don’t worry, we'll never share your email with anyone else.">
            <ValidationProvider name="email" rules="required|email" v-slot="validationContext">
              <b-form-input id="email" v-model="form.email" type="email" placeholder="email@email.com" :disabled="isSubmitLoading" :state="getValidationState(validationContext)"></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>

          <!-- Buttons -->
          <div class="text-center pt-3">
            <!-- 1st Step -->
            <b-button type="submit" class="btn" :disabled="isSubmitLoading">
              <b-spinner small v-if="isSubmitLoading"></b-spinner>
              <span v-if="isSubmitLoading"> loading...</span>
              <span v-if="!isSubmitLoading">Submit</span>
            </b-button>
            <b-button @click="returnToLogin" class="back btn">Return to login</b-button>
          </div>
        </b-form>
        </validation-observer>
      </div>
    </div>
    <!-- <h3 class="text-center pb-4">Reset Your Password</h3>
    <b-row align-h="center" align-v="center">
      <b-col sm="12" md="5" lg="5">
        <div style="padding: 10px;">
          <b-form id="forgot-password-form" @submit.prevent="onSubmit" @reset.prevent="onReset">
            <b-form-group id="input-group-1" label="Email Address" label-for="email" description="We'll never share your email with anyone else.">
              <b-form-input id="email" v-model="form.email" type="email" placeholder="Enter email address" size="sm"></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" block>Submit</b-button>&nbsp;
          </b-form>
        </div>
      </b-col>
    </b-row> -->
  </b-container>

</template>

<script>
import mixins from "../mixins";
export default {
  name: "ForgotPassword",
  data: () => ({
    form: {
      email: "",
    },
    isSubmitLoading: false
  }),
  methods: {
    returnToLogin() {
      this.$router.push("/login");
    },
    async onSubmit() {
      this.isSubmitLoading = true;
      const formData = new FormData();
      formData.append("email", this.form.email);

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/authenticate/forgot-password`,
        options = {
          headers: {
            Accept: "application/json, multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${this.UserToken}`,
          },
        };

      this.axios
        .post(ApiURL, formData, options)
        .then((data) => {
          const _data = data.data;
          if (_data.status == 200) {
            this.ShowModal(
              "We have successfully reset your password. Please check your e-mail.",
              () => {
                this.$router.push("/login");
              }
            );
          } else {
            this.ShowModal(_data.message);
          }
        })
        .catch((error) => {
          this.ShowModal(error);
        })
        .finally(() => {
          this.isSubmitLoading = false;
        })
    },
    onReset() {
      this.form.email = "";
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>

.forgot-password-container {
  max-width: 400px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2.5em 2em;
  position: relative;
  margin: 0 auto;
}

.forgot-password-form input.form-control {
  border-radius: 1.25rem;
  padding: 0.375rem 1rem;
}
.forgot-password-form button {
  background: var(--light-blue);
  border-radius: 2rem !important;
  border: none;
  padding: 0.5em 2.375em !important;
  font-size: var(--fs-one) !important;
  font-weight: 500;
  outline: none;
}
.forgot-password-form button.back {
  background: none;
  background-color: none;
  color: var(--light-blue);
  font-weight: normal;
}
</style>
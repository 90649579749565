<template>
  <div>
    <HeaderBanner :SubjectData="this.subject_data[subject_index]" />

    <FAQ :SubjectData="this.subject_data[subject_index]" />

    <CourseOutline :SubjectData="this.subject_data[subject_index]" />

  </div>
</template>

<script>
import HeaderBanner from "./marketing/subject/HeaderBanner.vue";
import FAQ from "./marketing/subject/FAQ.vue";
import CourseOutline from "./marketing/subject/CourseOutline.vue";

export default {
  name: "BecomeATutor",
  computed: {},
  mounted() {},
  beforeMount() {
    this.subject_index = this.getSubjectIndex(this.$route.params.subject_name);
  },
  methods: {
    getSubjectIndex(key) {
      let subjidx = -1;

      switch (key) {
        case "scratch_jr":
          subjidx = 0;
          break;
        case "scratch_level_1":
          subjidx = 1;
          break;
        case "scratch_level_2":
          subjidx = 2;
          break;
        case "scratch_level_3":
          subjidx = 3;
          break;
        case "python_level_1":
          subjidx = 4;
          break;
        case "python_level_2":
          subjidx = 5;
          break;
        case "roblox_level_1":
          subjidx = 6;
          break;
        case "roblox_level_2":
          subjidx = 7;
          break;
        case "minecraft_1":
          subjidx = 8;
          break;
        case "minecraft_2":
          subjidx = 9;
          break;
        case "mit_app_inventor":
          subjidx = 10;
          break;
        case "canva":
          subjidx = 11;
          break;
        case "procreate":
          subjidx = 12;
          break;
        case "reading_readiness_1a":
          subjidx = 13;
          break;
        case "reading_readiness_1b":
          subjidx = 14;
          break;
        case "reading_readiness_2a":
          subjidx = 15;
          break;
        case "reading_readiness_2b":
          subjidx = 16;
          break;
        case "elementary_reading_1":
          subjidx = 17;
          break;
        case "elementary_reading_2":
          subjidx = 18;
          break;
        case "intermediate_reading_1":
          subjidx = 19;
          break;
        case "intermediate_reading_2":
          subjidx = 20;
          break;
        case "vlogging_1":
          subjidx = 21;
          break;
        case "vlogging_2":
          subjidx = 22;
          break;
        case "public_speaking_1":
          subjidx = 23;
          break;
        case "public_speaking_2":
          subjidx = 24;
          break;
      }
      return subjidx;
    },
  },
  components: { HeaderBanner, FAQ, CourseOutline },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.subject_index = this.getSubjectIndex(
          this.$route.params.subject_name
        );
      }
    },
  },
  data() {
    return {
      subject_index: 0,
      subject_data: [
        {
          subject_code: "scratch_jr",
          subject_name: "ScratchJr",
          // <div style="position:relative;"><iframe class="c-obj" src="https://scratch.mit.edu/projects/580957060/embed" allowtransparency="true" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen></iframe><div class="iframe-overlay" style="position:absolute;top:0;left:0;right:0;bottom:0;"></div></div>
          subject_projects: [
            ` <img class="card-img-top c-obj" src="assets/imgs/offerings/coding/scratchjr.jpg" alt="">`,
            ` <iframe class="c-obj" src="https://scratch.mit.edu/projects/580957060/embed" allowtransparency="true" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen></iframe>`,
            `                    <iframe class="c-obj" src="https://trinket.io/embed/python3/4f42f0b18f?toggleCode=true&showInstructions=true" width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
`,
          ],
          description: "",
          course_code: "coding",
          course_name: "Coding",
          class_overview:
            "In this course, students will create their own interactive story.  ScratchJr is an introductory programming language that enables young children (ages 5-7) to create their own interactive stories and games. ScratchJr is available as a free app for both iPad and Android tablets.",
          target_learners: "Ages 5-8",
          prerequisite: "None",
          session_count: "6-8 ",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`
          ],
          class_benefits: [
            `To familiarize students with mathematical, literacy, and programming concepts`,
            `To improve problem-solving and communication skills`,
            `To explore arts and storytelling by designing and creating projects`,
          ],
          system_requirements: [
            "It will run on any iPad 2 or later, including all iPad minis, with iOS 7.0 or later installed. Note that newer versions of ScratchJr will only run on iOS 8+. It also runs on any Android tablet, 7-inches or larger, that are running Android 4.2 (Jelly Bean MR1) or higher",
          ],
          installation_guide: [
            `<li><a style="font-size: 16px" href="https://apps.apple.com/us/app/scratchjr/id895485086" target="_blank">Download</a> ScratchJr in the App Store</li>`,
            `<li><a style="font-size: 16px" href="https://play.google.com/store/apps/details?id=org.scratchjr.android&hl=en&gl=US">Download</a> ScratchJr for Tablet</li>`,
          ],
          course_outline: [
            { outline_item: `Welcome to ScratchJr!`, item_breakdown: [] },
            { outline_item: `Animation 1`, item_breakdown: [] },
            { outline_item: `Animation 2`, item_breakdown: [] },
            { outline_item: `Project Making`, item_breakdown: [] },
            { outline_item: `Create Stories`, item_breakdown: [] },
            {
              outline_item: `Using Wait, Set Speed and the Paint Editor`,
              item_breakdown: [],
            },
            { outline_item: `Project Making`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "scratch_level_1",
          subject_name: "Scratch Level 1",
          subject_projects: [
            ` <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjr.jpg')" alt="">`,
            ` <iframe src="https://scratch.mit.edu/projects/580957060/embed" allowtransparency="true" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen></iframe>`,
            `                    <iframe src="https://trinket.io/embed/python3/4f42f0b18f?toggleCode=true&showInstructions=true" width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
`,
          ],
          description: "Animation and Storytelling",
          course_code: "coding",
          course_name: "Coding",
          class_overview:
            "Learn the basics of programming and game development in a fun, playful and interactive way using Scratch. Create your own stories, animation and interactive games in Scratch while developing your creativity, computational thinking and problem-solving skills!",
          target_learners: "Ages 8-12",
          prerequisite: "None",
          session_count: "8-10 ",
           lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`, `Perseverance`, `Creativity`
          ],
          class_benefits: [
            `To introduce the basics of programming using visual programming`,
            `To familiarize students with the concepts of sequencing, loops, and animation`,
            `To explore arts and storytelling by creating projects that include characters, scenes, narratives, music, and design.`,
          ],
          system_requirements: [
            "Windows 10 or later or macOS 10.13 or later",
            `Approximately 400 MB of free hard drive space`,
            `Relatively new web browsers like Chrome or Mobile Chrome 63 or higher, Edge 15 or higher, Firefox 57 or higher, Safari or Mobile Safari 11 or higher.`,
          ],
          installation_guide: [
            `<li><a style="font-size: 16px" href="https://scratch.mit.edu/download" target="_blank">Download the Scratch App here</a> for desktop to create Scratch projects without an internet connection.</li>`,
            `<li>Alternatively, create an account <a style="font-size: 16px" href="https://scratch.mit.edu/join" target="_blank">here in Scratch</a> to use the web version.</li>`,
          ],
          course_outline: [
            { outline_item: `The Scratch Environment`, item_breakdown: [] },
            {
              outline_item: `Sprites, Sounds and Costumes`,
              item_breakdown: [],
            },
            { outline_item: `Animating Sprites`, item_breakdown: [] },
            {
              outline_item: `Programming Concepts`,
              item_breakdown: ["Variables", "Conditional Statements", "Loops"],
            },
            {
              outline_item: `Adding Interactivity`,
              item_breakdown: [
                "Control sprites using the mouse",
                "Control Sprites using the keyboard",
              ],
            },
            {
              outline_item: `Project Making: Digital Story or Mini-Game`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "scratch_level_2",
          subject_name: "Scratch Level 2",
          subject_projects: [
            ` <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjr.jpg')" alt="">`,
            ` <iframe src="https://scratch.mit.edu/projects/580957060/embed" allowtransparency="true" width="100%" height="100%" frameborder="0" scrolling="no" allowfullscreen></iframe>`,
            `                    <iframe src="https://trinket.io/embed/python3/4f42f0b18f?toggleCode=true&showInstructions=true" width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
`,
          ],
          description: "Game Development",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`, `Perseverance`, `Creativity`
          ],
          class_overview:
            "Dive into the world  of game development using Scratch. Learn the fundamentals of making interactive games and recreate some of the most popular computer games ever invented. The only limit is your imagination!",
          target_learners: "Ages 9-13",
          prerequisite: "None",
          session_count: "8-10 ",
          class_benefits: [
            `To learn the concepts of events, parallelism, conditionals, operators, variables, and modularization`,
            `To integrate mathematical concepts that will help develop problem solving skills and computational thinking`,
            `To spark creativity and apply programming concepts by building and designing games`,
          ],
          system_requirements: [
            "Windows 10 or later or macOS 10.13 or later",
            `Approximately 400 MB of free hard drive space`,
            `Relatively new web browsers like Chrome or Mobile Chrome 63 or higher, Edge 15 or higher, Firefox 57 or higher, Safari or Mobile Safari 11 or higher.`,
          ],
          installation_guide: [
            `<li><a style="font-size: 16px" href="https://scratch.mit.edu/download" target="_blank">Download the Scratch App here</a> for desktop to create Scratch projects without an internet connection.</li>`,
            `<li>Alternatively, create an account <a style="font-size: 16px" href="https://scratch.mit.edu/join" target="_blank">here in Scratch</a> to use the web version.</li>`,
          ],
          course_outline: [
            {
              outline_item: `Scratch Core Concepts`,
              item_breakdown: [
                "Animating Sprites",
                "Changing Backdrop",
                "Variables",
                "Loops",
                "Conditions",
              ],
            },
            {
              outline_item: `Game Development Activities`,
              item_breakdown: ["Catching Game", "Maze Game", "Jumping Game"],
            },
            {
              outline_item: `Project Making`,
              item_breakdown: ["Shooting Game", "Flappy Bird Clone"],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "scratch_level_3",
          subject_name: "Scratch Level 3:",
          description: "Game Development 2",
          course_code: "coding",
          course_name: "Coding",
          class_overview:
            "Scratch is a visual programming language used for creating stories, animations, and games. Through this course, the student will be able to learn about fundamental programming concepts through game development which will help enhance creativity, problem solving skills, and computational thinking.",
          target_learners: "Ages 8-16",
          session_count: 8,
          class_benefits: [
            `Program coordination among various sprites`,
            `To integrate higher-level mathematical concepts in program development`,
            `To provide input through keyboard for Scratch programs`,
          ],
          course_outline: [
            { outline_item: `Review and Events`, item_breakdown: [] },
            { outline_item: `Functions`, item_breakdown: [] },
            { outline_item: `Custom Code Development`, item_breakdown: [] },
            { outline_item: `Coding Backgrounds`, item_breakdown: [] },
            { outline_item: `Application 1`, item_breakdown: [] },
            { outline_item: `Application 2`, item_breakdown: [] },
            { outline_item: `Application 3`, item_breakdown: [] },
            { outline_item: `Application 4`, item_breakdown: [] },
          ],
          prepared_by: "Tutor Jezreel E.",
          booking_url: "/search?subjscope=Coding",
        },

        {
          subject_code: "python_level_1",
          subject_name: "Python Level 1",
          description: "Programming with Turtle",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`, `Perseverance`
          ],
          class_overview:
            "Python is a powerful yet beginner-friendly language for kids to learn programming. In this  Python coding class, students will take their first dive into coding with Python. We will transition from block coding, which they learned in Scratch, to text-based coding to animate and draw with Python using the Turtle module.",
          target_learners: "Ages 10-16",
          prerequisite: "None",
          session_count: "8-10 ",
          system_requirements: [
            "Windows 10 or later or macOS 10.13 or later",
            `At least x86 64-bit CPU (Intel / AMD architecture)`,
            `At least 4 GB RAM.`,
            `5 GB free disk space.`,
          ],
          installation_guide: [
            `<li>Open an Internet browser like Google Chrome or Mozilla Firefox and go to <a style="font-size: 16px" href="https://www.python.org/downloads/" target="_blank">Python Website</a></li>`,
            `<li>It will automatically detect your operating system.</li>`,
            `<li>Click the Download Python button.</li>`,
            `<li>Click Run and go through the installation process.</li>`,
          ],
          class_benefits: [
            `To learn about the basics of Python`,
            `To learn mathematical and programming concepts through arts`,
          ],
          course_outline: [
            { outline_item: `Introduction to Python`, item_breakdown: [] },
            {
              outline_item: `Programming Concepts`,
              item_breakdown: [
                "Variables and Numbers",
                "Lists and Tuples",
                "Making Decisions",
                "Loops",
                "Functions",
              ],
            },
            {
              outline_item: `Using the Turtle Module`,
              item_breakdown: ["Creating Canvas", "Moving the Turtle"],
            },
            {
              outline_item: `Project Making using the Turtle Module`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },

        {
          subject_code: "python_level_2",
          subject_name: "Python Level 2",
          description: "Game Development Using Pygame",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`, `Perseverance`
          ],
          class_overview:
            "Create a more sophisticated graphics-based game using the Pygame library of Python. We will cover key concepts in game development and design plus gain understanding about Object Oriented Programming concepts by creating the Space Invader game.",
          target_learners: "Ages 10-16",
          prerequisite: "Python 1",
          session_count: "8-10",
          system_requirements: [
            "Windows 10 or later or macOS 10.13 or later",
            `At least x86 64-bit CPU (Intel / AMD architecture)`,
            `At least 4 GB RAM.`,
            `5 GB free disk space.`,
          ],
          installation_guide: [
            `<li>Open an Internet browser like Google Chrome or Mozilla Firefox and go to <a style="font-size: 16px" href="https://www.python.org/downloads/" target="_blank">Python Website</a></li>`,
            `<li>It will automatically detect your operating system.</li>`,
            `<li>Click the Download Python button.</li>`,
            `<li>Click Run and go through the installation process.</li>`,
          ],
          class_benefits: [
            `To learn about creating Graphical User Interface (GUI) and the concept of Object- Oriented Programming (OOP)`,
            `To spark creativity and apply programming concepts by creating games`,
          ],
          course_outline: [
            {
              outline_item: `Installing Python and Pygame`,
              item_breakdown: [],
            },
            {
              outline_item: `Game Setup`,
              item_breakdown: ["Game Window", "Title", "Background Color"],
            },
            {
              outline_item: `Controlling Movement using the Keyboard`,
              item_breakdown: [],
            },
            { outline_item: `Adding Boundaries`, item_breakdown: [] },
            {
              outline_item: `Creating the Enemy`,
              item_breakdown: [
                "Adding Movement to the Enemy",
                "Adding Multiple Enemies",
              ],
            },
            {
              outline_item: `Creating Bullets for Shooting`,
              item_breakdown: ["Firing Multiple Bullets"],
            },
            { outline_item: `Detect Collision`, item_breakdown: [] },
            { outline_item: `Adding Text and Score`, item_breakdown: [] },
            { outline_item: `Finishing Touches`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "roblox_level_1",
          subject_name: "Roblox Level 1",
          description: "Build your own obby",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`, `Perseverance`, `Creativity`
          ],
          class_overview:
            "Roblox is  one of the most popular gaming platforms of all time. It allows users to design and code their own world and games which can then be played and enjoyed by other players around the globe. Learn to make your own world in this introductory course using Roblox Studio. Learn the basics of modeling and writing scripts while building an obstacle course with moving and disappearing platforms. Publish your game and share it with your friends!",
          target_learners: "Ages 10-16",
          session_count: "8-10",
          prerequisite: "Scratch 1 or Scratch 2",
          system_requirements: [
            "A PC or Mac computer running at least Windows 7 or macOS 10.10 (Roblox Studio cannot run on Linux, Chromebooks, or mobile devices such as smartphones).",
            `At least 1 GB of system memory.`,
            `Internet access. This keeps the software up-to-date and lets you save projects to your Roblox account.`,
          ],
          installation_guide: [
            `<li>Go to the <a style="font-size: 16px" href="https://www.roblox.com/" target="_blank">Roblox website</a> and log into your account</li>`,
            `<li>Upon logging into Roblox, visit any of your games, click Menu icon in the upper-right corner, and select Edit</li>`,
            `<li>A pop-up window will appear informing you that Roblox Studio is installing
</li>`,
            `<li>Once installed, Studio will automatically open</li>`,
          ],
          class_benefits: [
            `To learn about creating Graphical User Interface (GUI) and the concept of Object- Oriented Programming (OOP)`,
            `To spark creativity and apply programming concepts by creating games`,
          ],
          course_outline: [
            {
              outline_item: `Introduction to Roblox Studio`,
              item_breakdown: [
                "The Roblox Studio Environment",
                "Starting a New Project",
                "Baseplate",
                "Creating a Spawn Location",
                "Add and Move a Part",
                "Anchor and Scale Parts",
              ],
            },
            {
              outline_item: `Creating your first Obby`,
              item_breakdown: [
                "Make a Disappearing Platform",
                "Make a Lava Floor",
                "Fading Platforms",
              ],
            },
            { outline_item: `Add Scoring in your Game`, item_breakdown: [] },
            {
              outline_item: `Final Touches and Publishing your Game`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "roblox_level_2",
          subject_name: "Roblox Level 2",
          description: "Create an adventure Map",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`, `Perseverance`, `Creativity`
          ],
          class_overview:
            "Learn to make your own Adventure Maps using Roblox Studio’s terrain tools. Learn the basics of modeling and writing Lua scripts to create an interactive and amazing map! Publish your game and share it with your friends!",
          target_learners: "Ages 10-16",
          prerequisite: "Scratch 1 or Scratch 2",
          session_count: "8-10 ",
          system_requirements: [
            "A PC or Mac computer running at least Windows 7 or macOS 10.10 (Roblox Studio cannot run on Linux, Chromebooks, or mobile devices such as smartphones).",
            `At least 1 GB of system memory.`,
            `Internet access. This keeps the software up-to-date and lets you save projects to your Roblox account.`,
          ],
          installation_guide: [
            `<li>Go to the <a style="font-size: 16px" href="https://www.roblox.com/" target="_blank">Roblox website</a>  and log into your account</li>`,
            `<li>Upon logging into Roblox, visit any of your games, click Menu icon in the upper-right corner, and select Edit</li>`,
            `<li>A pop-up window will appear informing you that Roblox Studio is installing
</li>`,
            `<li>Once installed, Studio will automatically open</li>`,
          ],
          class_benefits: [
            `To learn about creating Graphical User Interface (GUI) and the concept of Object- Oriented Programming (OOP)`,
            `To spark creativity and apply programming concepts by creating games`,
          ],
          course_outline: [
            {
              outline_item: `Introduction to Roblox Studio`,
              item_breakdown: [],
            },
            { outline_item: `Lua Programming Language`, item_breakdown: [] },
            {
              outline_item: `Setting-Up the Terrain and Tools`,
              item_breakdown: [],
            },
            { outline_item: `Add a Lava Shield`, item_breakdown: [] },
            {
              outline_item: `Final Touches and Publishing your Game`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "minecraft_1",
          subject_name: "Minecraft 1:",
          description: "Modding Essentials",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`, `Perseverance`, `Creativity`
          ],
          class_overview:
            "One of the best-selling independently developed and published video games, Minecraft immerses kids in creative thinking as they build and explore worlds of imaginative block structures. In this course, you will learn to build your own houses, plants, animals and mobs and play with them in Minecraft!",
          target_learners: "Ages 9-13",
          session_count: "8-10 ",
          prerequisite: "None",
          system_requirements: [
            "Processor (CPU): Intel Core i3-3210 3.2 GHz / AMD A8-7600 APU 3.1 GHz or better",
            `Memory (RAM): 4 GB`,
            `Graphics (GPU): Intel HD Graphics 4000 / AMD Radeon R5 series with OpenGL 4.4`,
            `Network: Broadband Internet connection for downloading Minecraft files before playing offline.`,
            `Storage: 2 GB HDD`,
            `Windows: Windows 7 and up / macOS: Any 64-bit OS X using 10.9 Maverick or newer`,
          ],
          installation_guide: [
            `<li>Go to <a style="font-size: 16px;" target="_blank"href="https://java-for-minecraft.com/en/">Java website and download Java</a> for your operating system. Java is the main language we are going to use to  create mods in Minecraft.</li>`,
            `<li>Set-up your Text Editor by <a style="font-size: 16px;" target="_blank" href="https://www.eclipse.org/downloads/">Eclipse Software</a>. This is where we are going to type Java code to create mods in Minecraft.</li>`,
            `<li>Lastly, make you sure you have an account in <a style="font-size: 16px;" target="_blank" href="https://www.minecraft.net/">Minecraft</a>
</li>`,
          ],
          class_benefits: [
            `To learn about creating Graphical User Interface (GUI) and the concept of Object- Oriented Programming (OOP)`,
            `To spark creativity and apply programming concepts by creating games`,
          ],
          course_outline: [
            {
              outline_item: `Introduction to Minecraft modding and tools`,
              item_breakdown: [],
            },
            {
              outline_item: `Learning the Java Programming Language`,
              item_breakdown: [],
            },
            {
              outline_item: `Understanding Command Blocks`,
              item_breakdown: [],
            },
            {
              outline_item: `Building  your own Ax and Shield`,
              item_breakdown: [],
            },
            { outline_item: `Adding Mobs`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "minecraft_2",
          subject_name: "Minecraft 2:",
          description: "Create your own Biome",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`, `Perseverance`, `Creativity`
          ],
          class_overview:
            "In this course you will learn the basics of the Java Programming language to create a custom biome in Minecraft. You will add creatures and decorations to make your quests more realistic and fun!",
          target_learners: "Ages 9-13",
          session_count: "8-10 ",
          prerequisite: "None",
          system_requirements: [
            "Processor (CPU): Intel Core i3-3210 3.2 GHz / AMD A8-7600 APU 3.1 GHz or better",
            `Memory (RAM): 4 GB`,
            `Graphics (GPU): Intel HD Graphics 4000 / AMD Radeon R5 series with OpenGL 4.4`,
            `Network: Broadband Internet connection for downloading Minecraft files before playing offline.`,
            `Storage: 2 GB HDD`,
            `Windows: Windows 7 and up / macOS: Any 64-bit OS X using 10.9 Maverick or newer`,
          ],
          installation_guide: [
            `<li>Go to <a style="font-size: 16px;" target="_blank"href="https://java-for-minecraft.com/en/">Java website and download Java</a> for your operating system. Java is the main language we are going to use to  create mods in Minecraft.</li>`,
            `<li>Set-up your Text Editor by <a style="font-size: 16px;" target="_blank" href="https://www.eclipse.org/downloads/">Eclipse Software</a>. This is where we are going to type Java code to create mods in Minecraft.</li>`,
            `<li>Lastly, make you sure you have an account in <a style="font-size: 16px;" target="_blank" href="https://www.minecraft.net/">Minecraft</a>
</li>`,
          ],
          class_benefits: [
            `To learn about creating Graphical User Interface (GUI) and the concept of Object- Oriented Programming (OOP)`,
            `To spark creativity and apply programming concepts by creating games`,
          ],
          course_outline: [
            {
              outline_item: `Introduction to Minecraft modding and tools`,
              item_breakdown: [],
            },
            {
              outline_item: `Checking of required Software`,
              item_breakdown: [],
            },
            {
              outline_item: `Introduction to Java and Eclipse`,
              item_breakdown: [],
            },
            { outline_item: `Sample Biome in Minecraft`, item_breakdown: [] },
            { outline_item: `Biome Creation`, item_breakdown: [] },
            {
              outline_item: `Add blocks, decorations and creatures`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },
        {
          subject_code: "mit_app_inventor",
          subject_name: "MIT App Inventor:",
          description: "Mobile App Development",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
            `Problem Solving`,`Critical Thinking`,`Numerical Literacy`
          ],
          class_overview:
            "The course will give students hands-on experience in developing interesting Android applications. No previous experience in programming is needed, and the course is suitable for students with any level of computing experience. MIT App Inventor will be used in the course. It is a blocks-based programming tool that allows everyone, even novices, to start programming and build fully functional apps for Android devices. Students are encouraged to use their own Android devices for hands-on testing and exploitation.",
          target_learners: "Ages 9-13",
          session_count: "8-10 ",
          prerequisite: "None",
          system_requirements: [
            "Macintosh (with Intel processor): Mac OS X 10.5, 10.6+",
            `Windows: Windows XP, Windows Vista, Windows 7+`,
            `GNU/Linux: Ubuntu 8+, Debian 5+ (Note: GNU/Linux live development is only supported for WiFi connections between computer and Android device.)`,
            `Mozilla Firefox 3.6 or higher (Note: If you are using Firefox with the NoScript extension, you'll need to turn the extension off. See the note on the troubleshooting page.)`,
            `Apple Safari 5.0 or higher`,
            `Google Chrome 4.0 or higher`,
            `App Inventor does not support Microsoft Internet Explorer. Windows users should use Chrome or Firefox`,
          ],

          course_outline: [
            {
              outline_item: `Introduction to App Inventor`,
              item_breakdown: [
                `Learn about the smartphone and its touch interface`,
                `Explore the App Inventor Interface`,
              ],
            },
            {
              outline_item: `Load and run mobile Apps`,
              item_breakdown: [
                `Events-driven Programming`,
                `Understand the event-driven nature of App Inventor programming`,
              ],
            },
            {
              outline_item: `learn to use variables and procedures`,
              item_breakdown: [],
            },
            {
              outline_item: `Graphics and Animation`,
              item_breakdown: [
                `Make your animation come alive on your mobile phone`,
              ],
            },
            {
              outline_item: `Gaming Time`,
              item_breakdown: [
                `Learn to put all the knowledge into developing an interactive game on mobile phone`,
              ],
            },
            {
              outline_item: `Project Making`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },

        {
          subject_code: "canva",
          subject_name: "Canva:",
          description: "Graphic Designing",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
           `Perseverance`, `Creativity`
          ],
          class_overview:
            "Canva is a free-to-use online graphic design tool. Use it to create social media posts, presentations, posters, videos, logos and more",
          target_learners: "Ages 8-16",
          session_count: "8-10 ",
          prerequisite: "None",
          system_requirements: [
            `Desktop and mobile browser (canva.com)`,
            `Google Chrome, version 57 or higher`,
            `Mozilla Firefox, version 52 or higher`,
            `Safari, version 12 or higher`,
            `Apple Safari 5.0 or higher`,
            `Microsoft Edge, version 89 or higher`,
            `Opera, version 44 or higher`,
          ],

          course_outline: [
            {
              outline_item: `Creating an Account`,
              item_breakdown: [],
            },
            {
              outline_item: `Using Available Templates`,
              item_breakdown: [],
            },
            {
              outline_item: `Customizing the Editor`,
              item_breakdown: [],
            },
            {
              outline_item: `Upload and Edit Photos`,
              item_breakdown: [],
            },
            {
              outline_item: `Editing Elements`,
              item_breakdown: [],
            },
            {
              outline_item: `Fonts and Text`,
              item_breakdown: [],
            },
            {
              outline_item: `Saving and Organizing Documents`,
              item_breakdown: [],
            },
            {
              outline_item: `Final Project - Infographic or Poster`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },

        {
          subject_code: "procreate",
          subject_name: "Procreate:",
          description: "Graphic Designing",
          course_code: "coding",
          course_name: "Coding",
          lifelong_skills: [
             `Perseverance`, `Creativity`
          ],
          class_overview:
            "Procreate is a raster graphics editor app for digital painting developed and published by Savage Interactive for iOS and iPadOS. Designed in response to the artistic possibilities of the iPad, it was launched on the App Store in 2011.",
          target_learners: "Ages 8-16",
          session_count: "8-10 ",
          prerequisite: "None",
          system_requirements: [
            `It requires iPadOS 14.4 or newer and is supported on the following devices: 12.9-inch iPad Pro (1st, 2nd, 3rd, 4th, and 5th generation) 11-inch iPad Pro (1st, 2nd, and 3rd generation)`,
          ],

          course_outline: [
            {
              outline_item: `User Interface`,
              item_breakdown: [],
            },
            {
              outline_item: `Using Brushers`,
              item_breakdown: [],
            },
            {
              outline_item: `Understanding Layers`,
              item_breakdown: [],
            },
            {
              outline_item: `Line Art`,
              item_breakdown: [],
            },
            {
              outline_item: `Shapes`,
              item_breakdown: [],
            },
            {
              outline_item: `2D Art 1`,
              item_breakdown: [],
            },
            {
              outline_item: `2d Art 2`,
              item_breakdown: [],
            },
            {
              outline_item: `Effects`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Coding",
        },

        {
          subject_code: "reading_readiness_1a",
          description: `The Sounds I Can Make`,
          subject_name: "Reading Readiness Part 1A",
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course introduces the learners to the 74 phonemic sounds while learning the rules for the soft and hard sounds of /c/ and /g/ on the side.",
          target_learners: "Ages 3-4",
          session_count: "6",
          class_benefits: [
            `Differentiate vowels and consonants, and associate sounds and symbols.`,
            `Recognize soft and hard sounds, and long and short vowel sounds.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Oh say can you say! (Sounds of the letters including long and short vowel sounds)
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: Do you hear what I hear? (Focus on the beginning sound)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Stop, Listen, and Move! (The rules for hard and soft /c/ and /g/.)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 4: Apples and Bananas (Long and short vowel sounds)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 5: Think Tank (Voiced and unvoiced /th/)`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 6: Do you remember?`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "reading_readiness_1b",
          description: `Hello, ABC`,
          subject_name: "Reading Readiness Part 1B",
          prerequisite: `Reading Readiness Part 1A`,
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "Learners will get to know the letter names and their sounds.",
          target_learners: "Ages 3-4",
          session_count: "6",
          class_benefits: [
            `Associate letter sounds to letter names.`,
            `Recognize vowels and consonants.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Letter detectives (Introduce letter name and letter sound)
`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 2: Rock and Roll`, item_breakdown: [] },
            { outline_item: `Lesson 3: Namaste`, item_breakdown: [] },
            { outline_item: `Lesson 4: The mind museum`, item_breakdown: [] },
            { outline_item: `Lesson 5: The Royals`, item_breakdown: [] },
            { outline_item: `Lesson 6: Mix and Match`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },

        {
          subject_code: "reading_readiness_2a",
          description: `Time to Blend!
`,
          subject_name: "Reading Readiness Part 2A",
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course introduces phonetic reading focusing on short vowel sounds using cvc words.",
          target_learners: "Ages 4-5",
          session_count: "6",
          class_benefits: [
            `Recognize the difference on how long and short vowel sounds are produced.`,
            `Read cvc words through sounds.`,
            `Blend multiple sounds and recognize sight words.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Oh, My Vowels! (Short vowel sounds)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: Listening Ears (Reading per letter)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Meet My Friend (Segmenting)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 4: Unscramble The Rumble (recognizing letter placement)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 5: Spin The Wheel (Word family)`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 6: What I Like`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "reading_readiness_2b",
          description: `A for apple and A for acorn`,
          subject_name: "Reading Readiness Part 2B",
          prerequisite: `Reading Readiness Part 2 A`,
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course will help learners produce long vowels and familiarize themselves with the rules of silent letter /e/.",
          target_learners: "Ages 4-5",
          session_count: "6",
          class_benefits: [
            `Differentiate the way long vowel sounds are pronounced vs. short vowel sounds.`,
            `Produce the long vowel sounds with accuracy.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Let’s Chant! (Introduction to different vowel sounds)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: The Story of ai and ay (long a sound and rule of use)`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: The Magic Letter E`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 4: My Oh My! Look At The Sky!
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 5: Row, Row, Row The Boat`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 6: The /ue/ and /ew/ Sounds`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "elementary_reading_1",
          description: " I Can Read BIG Words Too!",
          subject_name: "Elementary Reading 1",
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "In this course, the students will learn about complex and irregular words containing Bossy R, Digraphs, Consonant Blends, Sight Words and Compound Words.",
          target_learners: "Ages 6-7",
          session_count: "6",
          class_benefits: [
            `Read words with phonograms er, ur and ir.`,
            `Produce orally single-syllable words by blending sounds (phonemes), including consonant blends.`,
            `Recognize words with the same ending sounds.`,
            `Recognize and read sight words.`,
            `Recognize compound words.`,
          ],
          course_outline: [
            { outline_item: `Lesson 1: Bossy R`, item_breakdown: [] },
            {
              outline_item: `Lesson 2: Consonant Blends L and R Family`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Digraphs`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 4: Rhyming Words`, item_breakdown: [] },
            { outline_item: `Lesson 5: Sight Words`, item_breakdown: [] },
            { outline_item: `Lesson 6: Compound Words`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "elementary_reading_2",
          description: "Let’s Make a Story",
          subject_name: "Elementary Reading 2",
          prerequisite: `Elementary Reading 1`,
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course is an introduction to the basic parts of speech which will help learners construct simple sentences.",
          target_learners: "Ages 6-7",
          session_count: "6",
          class_benefits: [
            `Recognize common action words in sentences listened to.`,
            `Define what a sentence is and construct simple sentences.`,
            `Recognize and identify common and proper nouns.`,
            `Define the meaning and recognize adjectives.`,
            `Use appropriate prepositions.`,
            `Write simple sentences.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Subjects and Verbs`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: Sentences Need Three Things`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Nouns`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 4: Adjectives`, item_breakdown: [] },
            { outline_item: `Lesson 5: Prepositions`, item_breakdown: [] },
            { outline_item: `Lesson 6: Making Sentences`, item_breakdown: [] },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "intermediate_reading_1",
          description: " Readers and Writers",
          subject_name: "Intermediate Reading Skills 1",
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course aims to introduce learners to the basics of writing a story.",
          target_learners: "Ages 8-9",
          session_count: "6",
          class_benefits: [
            `Identify and differentiate the 3 parts of story-making.`,
            `Identify different kinds of stories.`,
            `Create an outline and identity for characters in the story.`,
            `To create different kinds of story settings`,
            `Prepare a story map to create a connection throughout the story.`,
            `Create a story.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Parts of a Story (Beginning, Middle, End)
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: Kinds of Stories (Fable, Fairy Tale, Myth)
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Character Building
`,
              item_breakdown: [],
            },
            { outline_item: `Lesson 4: Story Settings`, item_breakdown: [] },
            {
              outline_item: `Lesson 5: Conflict and Solution Building`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 6: Story Making
`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },
        {
          subject_code: "intermediate_reading_2",
          description: "Let’s Dig Deeper",
          subject_name: "Intermediate Reading Skills 2",
          prerequisite: `Intermediate Reading Skills 1`,
          course_code: "reading",
          course_name: "Reading",
          class_overview:
            "This course is intended for learners who wish to develop their reading skills and to improve their level of comprehension.",
          target_learners: "Ages 8-9",
          session_count: "6",
          class_benefits: [
            `Recognize the main idea in a given story and identify supporting details.`,
            `Differentiate and practice skimming and scanning.`,
            `Use techniques to recognize context clues.`,
            `Identify cause and effect and suggest possible outcomes of the story.`,
            `Identify events and arrange them in proper order to create a logical flow of the story.`,
            `Distinguish facts from opinions.`,
          ],
          course_outline: [
            {
              outline_item: `Lesson 1: Main Ideas and Supporting Details
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 2: Skimming and Scanning

`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 3: Context Clues

`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 4: Cause and Effect and Predicting Outcomes
`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 5: Sequencing of Events`,
              item_breakdown: [],
            },
            {
              outline_item: `Lesson 6: Fact or Opinion
`,
              item_breakdown: [],
            },
          ],
          prepared_by: "",
          booking_url: "/search?subjscope=Reading",
        },

        {
          subject_code: "vlogging_1",
          subject_name: "Vlogging",
          description: "Rookie",
          course_code: "other_lessons",
          course_name: "Other Lessons",
          class_overview:
            "In this class, the student will learn the basics of vlogging and how to create a video of themselves talking about a particular topic.",
          target_learners: "Ages 13 and up",
          session_count: 6,
          class_benefits: [
            `To familiarize with how YouTube works`,
            `To practice speaking skills`,
            `To improve video capturing and editing skills.`,
          ],
          course_outline: [
            {
              outline_item: `Preparing`,
              item_breakdown: [`Right Mindset`, `Branding and Niche`],
            },
            {
              outline_item: `Setting Up`,
              item_breakdown: [
                `Creating your Youtube channel`,
                `Creating your channel art`,
              ],
            },
            {
              outline_item: `Building`,
              item_breakdown: [
                `Content Planning`,
                `Basic Videography and Editing`,
              ],
            },
          ],
          prepared_by: "Ronald Angelo L.",
          booking_url: "/search?subjscope=Vlogging",
        },

        {
          subject_code: "vlogging_2",
          subject_name: "Vlogging",
          description: "Rising Star",
          course_code: "other_lessons",
          course_name: "Other Lessons",
          class_overview:
            "In this class, the student will learn how to livestream videos using various applications and software. The student will also learn how creating videos can generate income.",
          target_learners: "Ages 13 and up",
          session_count: 6,
          class_benefits: [
            `To familiarize with YouTube Studio`,
            `To practice speaking and social skills`,
          ],
          course_outline: [
            {
              outline_item: `Live Streaming Basics`,
              item_breakdown: [
                `YouTube Studio`,
                `Software and Online Tools Setup`,
                `Aligning with your niche`,
              ],
            },
            {
              outline_item: `Monetization`,
              item_breakdown: [
                `YouTube Partner Program`,
                `AdSense`,
                `Income generation outside Youtube`,
              ],
            },
          ],
          prepared_by: `Ronald Angelo L.`,
          booking_url: "/search?subjscope=Vlogging",
        },
        {
          subject_code: "public_speaking_1",
          subject_name: "Public Speaking",
          description: "Speech Builder",
          course_code: "other_lessons",
          course_name: "Other Lessons",
          class_overview:
            "In this class, the student will develop good oral communication skills and fluency, build confidence, vocabulary, and interpersonal skills.",
          target_learners: "Ages 9 and up",
          session_count: 20,
          class_benefits: [
            `To be a better communicator and master the art of speaking in public.`,
          ],
          course_outline: [
            { outline_item: `Active Listening`, item_breakdown: [] },
            { outline_item: `Talking About Yourself`, item_breakdown: [] },
            {
              outline_item: `Describing People and Places`,
              item_breakdown: [],
            },
            { outline_item: `Narrating Events`, item_breakdown: [] },
            { outline_item: `Expressing Your Opinions`, item_breakdown: [] },
          ],
          prepared_by: `Ronald G.`,
          booking_url:
            "/search?subjscope=English&lvlscope=Preschool,Grade 10,Grade 11,Grade 12",
        },

        {
          subject_code: "public_speaking_2",
          subject_name: "Public Speaking",
          description: "Eloquence and Confidence",
          course_code: "other_lessons",
          course_name: "Other Lessons",
          class_overview:
            "In this class, the student will develop eloquence and confidence during a speech. The student will also learn how to organize and present ideas and practice verbal and non-verbal communication skills.",
          target_learners: "Ages 9 and up",
          session_count: 20,
          class_benefits: [
            `To be  a better communicator and master the art of speaking in public.`,
          ],
          course_outline: [
            {
              outline_item: `Planning, Organizing and Writing a Speech`,
              item_breakdown: [],
            },
            {
              outline_item: `Delivering an Informative Speech`,
              item_breakdown: [],
            },
            {
              outline_item: `Delivering Extemporaneous and Impromptu Speeches`,
              item_breakdown: [],
            },
            {
              outline_item: `Speeches for Various Occasions`,
              item_breakdown: [],
            },
            { outline_item: `Platform Behavior`, item_breakdown: [] },
          ],
          prepared_by: `Ronald G.`,
          booking_url:
            "/search?subjscope=English&lvlscope=Preschool,Grade 10,Grade 11,Grade 12",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/subject.css");
</style>
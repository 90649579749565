<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-1 orange-bg">

      </b-card-header>

      <b-card-body class="text-center">
        <div class="orange font-size-lg"><strong>Today's Sessions</strong></div>

        <hr>
        <div v-if="items.length == 0">No sessions today.</div>
        <!-- Learner Profile Image -->
        <div v-for="(item, index) in items" :key="index">
          <b-avatar :src="item.image" size="4rem"></b-avatar>

          <div class="py-3">
            <!-- Learner Name -->
            <div class="d-flex align-items-center justify-content-between">
              <div class="grey">Learner Name</div>
              <div><strong>{{ item.name }}</strong></div>
            </div>
            <!-- Time -->
            <div class="d-flex align-items-center justify-content-between">
              <div>Time</div>
              <div><strong>{{ `${moment.tz(item.start_date, 'UTC').tz(guestTZ).format('hh:mm A')} (${regionTzName})` }}</strong></div>
            </div>
            <!-- Grade Level -->
            <div class="d-flex align-items-center justify-content-between">
              <div>Grade Level</div>
              <div><strong>{{ item.grade_level }}</strong></div>
            </div>
            <!-- Subject -->
            <div class="d-flex align-items-center justify-content-between">
              <div>Subject</div>
              <div><strong>{{ item.subject }}</strong></div>
            </div>
          </div>

          <b-button @click="onLaunchSession(item.booking_id)" :disabled="isAfterDate(item.start_date)" class="orange-bg white w-100">Launch Session</b-button>
        </div>

      </b-card-body>
      <b-pagination v-if="items.length > 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        class="p-3"
        size="sm"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import mixins from '../../../mixins';
export default {
  name: "ScheduleTeacherTodaySessions",
  data: () => ({
    items: [],
    currentPage: 1,
    perPage: 1,
    totalRows: 0,
    guestTZ: null,
    regionTzName: null
  }),
  computed: {},
  mounted() {
    const vm = this
    vm.$watch('$store.state.userData', (data) => {
      vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone
      vm.regionTzName = data.timezone == null ? vm.getTzRegion(vm.guestTz) : data.region_timezone
      vm.$store.dispatch('getTimeSlots', vm.guestTZ)
    }, { immediate: true })
    vm.fetchTodaySessions()
  },
  methods: {
    async fetchTodaySessions () {
      const ApiURL = process.env.VUE_APP_BACKEND_URL + `/api/web/booking/today-session-tutor?count=${this.perPage}&page=${this.currentPage}`;
      const options = {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': `Bearer ${this.UserToken}`
        }
      }
      await this.axios.get(ApiURL, options).then(data => {
        const _data = data.data;
        this.items = _data.data.data;
        this.totalRows = _data.data.total
      })
    },
    onLaunchSession(bookingId) {
      window.localStorage.setItem("bookingId", bookingId)
      let routeData = this.$router.resolve({ name: 'video-session' });
      window.open(routeData.href, '_blank');
    },
    isAfterDate(startDate) {
      return this.moment.utc(startDate).tz(this.guestTZ).subtract(10, 'minutes') > this.moment.tz(this.guestTZ)
    }
  },
  mixins: [mixins],
  watch: {
    currentPage: {
      handler: function () {
        this.fetchTodaySessions().catch((error) => {
          console.error(error);
        });
      },
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.orange-bg.btn-secondary:hover, .orange-bg.btn-secondary:focus, .orange-bg.btn-secondary.focus, .orange-bg.btn-secondary:not(:disabled):not(.disabled):active, .orange-bg.btn-secondary:not(:disabled):not(.disabled).active, .show > .orange-bg.btn-secondary.dropdown-toggle, .orange-bg.btn-secondary:not(:disabled):not(.disabled):active:focus, .orange-bg.btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .orange-bg.btn-secondary.dropdown-toggle:focus{
  outline: none;
  border: none;
  background: var(--orange);
}
</style>
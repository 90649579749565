<template>
    <div>
        <section class="section py-5">
 <div class="container px-5">
 <h1 class="is-size-1 has-text-centered has-text-weight-bold dark-grey mb-6">Tutor Terms & Conditions</h1>
 <div class="content">
 TheChalkboard Inc., operating as Chalkboard (&quot;Chalkboard&quot;, &quot;our&quot;, &quot;us&quot; or &quot;we&quot;) provides an
 online platform known as CHALKBOARD that connects tutors with individuals seeking tutoring
 services (each a “User”) and other services related thereto (collectively, the &quot;Services&quot;) through
 our website, accessible at www.chalkboard.com.ph (the &quot;Site&quot;). The Services include the ability for
 Users and Tutors (each as defined below) to interact via the Chalkboard platform&#39;s interface or
 other methods as may be made available by Chalkboard from time to time.
 <h3 class="is-size-5 my-4 dark-grey">
 Definitions
 </h3>
 <strong>“Bundle discount”</strong> means an optional volume discount calculated by Chalkboard and a Tutor
 chooses to provide for its service<br><br>
 <strong>&quot;Chalkboard Content&quot;</strong> means Content that Chalkboard makes available through the Site or
 Services, including any Content licensed from a third party, but excluding User Content.<br><br>
 <strong>&quot;Collective Content&quot;</strong> means, collectively, Chalkboard Content and User Content.<br><br>
 <strong>&quot;Tutor&quot;</strong> means a qualified and authorized person that renders tutoring or educational services
 provider accessible via the Services.<br><br>
 <strong>&quot;Tutorial Services&quot;</strong> mean any tutoring or educational services a User may receive from a tutor
 related to a User&#39;s Chalkboard account or the Services.<br><br>
 <strong>&quot;User&quot;</strong> means a person who seeks, enrolls, avails and/or schedules Tutorial Services for a student
 or for his or herself (e.g. a tutor, student, parent or guardian).<br><br>
 <strong>&quot;User Content&quot;</strong> means Content that a tutor or User posts, uploads, publishes, submits or transmits
 to be made available through the Site or Services in connection with the Tutorial Services or
 otherwise, including, but not limited to, Questions and related answers and other learning
 materials posted by Users and/or Tutors via the Services.<br><br>
 As a Tutor, your use of the Services is governed by the policies, terms and conditions herein.<br><br>
 THE SITE AND SERVICES COMPRISE AN ONLINE PLATFORM WHICH FACILITATES THE
 CONNECTION BETWEEN USERS AND TUTORS FEATURED ON THE SITE AND VIA THE
 SERVICES. YOU UNDERSTAND AND AGREE THAT CHALKBOARD IS NOT A PARTY TO ANY
 AGREEMENTS ENTERED INTO BETWEEN USERS AND TUTORS. AS MORE FULLY DETAILED
 BELOW AND TO THE FULLEST PERMITTED BY APPLICABLE LAW, CHALKBOARD DISCLAIMS
 ALL LIABILITY RELATED TO THE TUTORIAL SERVICES OR THE CONDUCT OF USERS, TUTORS

 <br><br>
 OR OTHER USERS OF THE SITE OR SERVICES NOT EXPRESSLY AND ALLOWED IN WRITING.
 ACCORDINGLY, ANY MEETINGS (VIRTUAL OR OTHERWISE) BETWEEN USERS, TUTORS OR
 ANY OTHER THIRD PARTIES AND ANY PURCHASES OF TUTORIAL SERVICES PROVIDED BY
 TUTORS ARE DONE AT THE PARTIES&#39; SOLE RISK.<br><br>
 Certain areas of the Site (and your access to or use of certain Services or Collective Content) may
 have different terms and conditions posted or may require you to agree to and accept additional
 terms and conditions. If there is a conflict between these Tutor Terms &amp; Conditions and terms and
 conditions posted for a specific area of the Site, Services or Collective Content, the terms and
 conditions posted for the specific area of the Site, Services, or Collective Content will take
 precedence with respect to your use of or access to that area of the Site, Services or Collective
 Content, as applicable.<br><br>
 YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE OR SERVICES,
 OR YOUR CHALKBOARD ACCOUNT, OR BY POSTING ANY CONTENT ON THE SITE OR
 THROUGH THE SERVICES IN CONNECTION WITH YOUR TUTORIAL SERVICES OR
 OTHERWISE, YOU ARE INDICATING AND ACKNOWLEDGING THAT YOU HAVE READ, AND
 THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY, THESE TUTOR TERMS &amp;
 CONDITIONS, WHETHER OR NOT YOU HAVE REGISTERED VIA THE SITE. IF YOU DO NOT
 AGREE TO THESE TUTOR TERMS &amp; CONDITIONS, THEN YOU HAVE NO RIGHT TO ACCESS
 OR USE THE SITE, SERVICES OR COLLECTIVE CONTENT. <br><br>If you accept or agree to these Tutor
 Terms &amp; Conditions on behalf of a company or other legal entity, you represent and warrant that
 you have the authority to bind that company or other legal entity to these Tutor Terms &amp;
 Conditions and, in such event, &quot;you&quot; and &quot;your&quot; will refer and apply to that company or other legal
 entity.
 <ol type="1">

 <li class="my-4 is-size-3 dark-grey">
 How the Site and Services Work
 </li>
 The Site and Services can be used to facilitate the provision of Tutorial Services via our
 online platform and its video and text chat and document collaboration interface. As
 stated above, Chalkboard makes available a platform with related technology for Users to
 connect with Tutors online and conduct the Tutorial Services via our online interface as
 requested by Users. Chalkboard is not a provider of tutoring services. Chalkboard&#39;s role is
 solely to facilitate the availability of the Site and Services. Similarly, Chalkboard is not a
 contracting agent or representative of any Tutor or User. Tutors are independent
 contractors and are not employees or agents of Chalkboard. <br><br>
 You are an independent contractor and nothing in this Tutor Terms &amp; Conditions will be
 construed as establishing an employment or agency relationship between you and
 Chalkboard. You have no authority to bind Chalkboard by contract or otherwise. You will
 determine, in your sole discretion, the manner and means by which you provide Tutorial
 Services in connection with this Tutor Terms &amp; Conditions or as related to the Services or
 Site, subject to the requirement that you will at all times comply with applicable law and

 3
 existing rules and regulations. As an independent contractor, you will not be entitled to
 any compensation and/or benefits paid or made available by Chalkboard to its employees,
 including, without limitation, any vacation, sick leave or similar pay or benefit, or to
 participate in any plans, arrangements or distributions made by Chalkboard pertaining to
 any bonus, stock option, profit sharing, insurance or similar benefits.
 <li class="my-4 is-size-3 dark-grey">
 Account Sign-Up and Utilization
 </li>
 You must sign-up an account on the Chalkboard platform.<br><br>
 When you set-up your account on the Chalkboard platform, you must:

 <ol type="a">
 <li>
 18 years old and older
 </li>
 <li>
 Provide accurate, updated and complete information
 </li>
 <li>
 Consent Chalkboard to verify your account information and make inquiries
 when necessary.
 </li>
 </ol>

 You are responsible for the confidentiality of your username and password. You
 understand not to disclose any of your account information to any third party.<br><br>
 You are also responsible for all your account activities, whether or not you authorized it.<br><br>
 You will also notify Chalkboard should you encounter or have known of any unauthorized<br><br>
 access of your account within twenty-four hours from the occurrence thereof.<br><br>
 You understand and agree that you will not involve, permit, authorize, or accommodate
 other persons to participate in facilitating tutoring services using Chalkboard platform
 aside from you and your assigned client.

 <li class="my-4 is-size-3 dark-grey">
 Privacy
 </li>
 You agree that you have reviewed and acknowledged <a class="light-blue" href="./privacy-policy">Privacy Policy</a>, which governs the
 collection, processing, and storage of your “personal information” as defined in
 Chalkboard Privacy Policy and/or the Data Privacy Act of 2012.
 <li class="my-4 is-size-3 dark-grey">
 Interchange with Users and Delivery of Services
 </li>
 You are solely responsible for your interchange with the users of the Chalkboard platform.
 You understand to exercise all reasonable and necessary precautions in all interchanges
 with the users of the Chalkboard platform since Chalkboard does not screen potential
 clients of the platform.<br><br>


 You will not send messages or communications that are rude or abusive or in any other
 way disrespectful, or unfavorable and illegal manner as prescribed in the <a class="light-blue" href="./service-etiquette">Service Etiquette
 and Penalties policies</a>. Chalkboard reserves the right to evaluate your compliance with the
 <a class="light-blue" href="./service-etiquette">Service Etiquette
 and Penalties policies</a>.<br><br>
 You are solely responsible for all the instructional content, communications, assistance,
 and direction that you provide as part of the service which must be in accordance with the
 acceptable and recognized educational and teaching standards.<br><br>
 You will not broadcast nor record any tutorial sessions made in the Chalkboard platform
 without the written consent of the Chalkboard, concerned User and Clients.
 <li class="my-4 is-size-3 dark-grey">
 Payment
 </li>
 Fees Payable in respect of your provision of the Services:

 <ol type="a">
 <li>
 Is paid on a per hour basis in accordance with the number of hours or
 partial hours of Tutorial Services you provide at the hourly rates you
 established.
 </li>
 <li>
 Is inclusive of any and all applicable taxes for which you may be responsible.
 </li>
 <li>
 Is subject to Chalkboard’s service fee
 </li>
 <li>
 Is subject to any Bundle Discount that you agree to provide
 </li>

 <li>
 Is subject to any penalty costs that may be incurred as a result of; being late,
 unfinished or incomplete sessions, or a <strong>“no show”</strong> for any previously
 scheduled tutor session as prescribed in the Service Etiquette and
 Penalties policies.
 </li>
 <li>
 Is based on the agreed cut-off period (10th and 25th of the month)
 </li>
 </ol>
 In order to receive payment of Fees from Chalkboard, Tutor will be required to provide an
 account to Chalkboard. Fees Payable will be remitted to Tutor around the 15 th and 30 th of
 the month via your nominated account using Chalkboard’s third-party payment processor
 net of any tax, fees and penalty costs incurred. Tutor is solely responsible for ensuring that
 he/she will be able to receive and withdraw money from his/her nominated account. <br><br>
 Please note that if a Tutor Payment sent by Chalkboard to you in accordance with these
 Tutor Terms &amp; Conditions is later invalidated or reversed for any reason (e.g. a User
 requests a refund), you will be liable to Chalkboard for the full amount of such Tutor
 Payment that Chalkboard sent to you and any fees related thereto. This means that you
 will be responsible for reimbursing Chalkboard in the event of a chargeback, reversal of
 payment or other claim by a User in this regard. We may void or withhold payments for: (1)
 breach of these Tutor Terms &amp; Conditions, (2) suspected fraudulent activity (including,
 without limitation, purporting to complete more tutoring minutes than is possible within a
 given period of time), (3) failure to timely provide tax information necessary for
 withholding, or (4) if the User requests a refund or submits a chargeback request.

 <li class="my-4 is-size-3 dark-grey">
 Taxes
 </li>
 Tutor shall pay all the necessary and required taxes incurred while performing services
 under this Agreement —including all applicable income taxes in the Philippines.<br><br>
 If the Chalkboard has to pay any such tax then the Tutor agrees to pay back or reimburse
 the Chalkboard in full, any money that the Chalkboard has to pay. Tutor also agrees to pay
 back or reimburse the Chalkboard for any fine or other punishment imposed on the
 Chalkboard because the appropriate taxes/fees were not paid by the Tutor.<br><br>
 Tutor agrees to allow Chalkboard to withhold taxes when deemed appropriate pursuant to
 tax laws.

 <li class="my-4 is-size-3 dark-grey">
 License/Permission Granted by Tutor
 </li>
 Chalkboard may, in our sole discretion, permit users of the Services in writing to post,
 upload, publish, submit or transmit Content. By making available any Content on or
 through the Site or Services, you hereby grant the following license to Chalkboard, which
 Chalkboard may exercise solely in connection with the Services, including promotion of
 the Services, directly or directly by intermediaries: a worldwide, irrevocable, perpetual,
 non-exclusive, transferable, royalty-free license, with the right to sublicense to other users
 of the Services (including Users and other Tutors, as applicable), to use, view, copy, adapt,
 modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit,
 stream, broadcast and otherwise exploit academically such Content. Chalkboard does not
 claim any ownership rights in any Content provided by users of the Services or Site and
 nothing in these Tutor Terms &amp; Conditions will be deemed to restrict any rights that you
 may have to use and exploit any of your Content.<br><br>
 Chalkboard may record live lessons that you provide to students in connection with your
 provision of Tutorial Services with or without your consent. You hereby grant to
 Chalkboard an irrevocable, worldwide, royalty-free, exclusive license to use any such
 recorded live lessons for all business purposes, including for marketing purposes, and you
 hereby grant Chalkboard permission to use your voice, image, name, and likeness to the
 extent that the same are encompassed or embodied within such recorded live lessons.

 You acknowledge and agree that you are solely responsible for all Content that you make
 available through the Site or Services. Accordingly, you represent and warrant that: (i) you
 either are the sole and exclusive owner of all Content that you make available through the
 Site or Services or you have all rights, licenses, consents and releases that are necessary to
 grant to Chalkboard the rights in and to such Content, as contemplated under these Tutor
 Terms &amp; Conditions; and (ii) the Content, your posting, uploading, publication, submission
 or transmittal of it, or Chalkboard&#39;s use of the Content (or any portion thereof) on, through
 or by means of the Site or Services will not infringe, misappropriate or violate a third
 party&#39;s patent, copyright, trademark, trade secret, moral rights or other intellectual
 property rights, or rights of publicity or privacy, or result in the violation of any applicable
 law or regulation.
 <li class="my-4 is-size-3 dark-grey">
 General Prohibition and Requirements
 </li>
 You agree and commit not to do any of the following:<br><br>
 Post, upload, publish, submit, or transmit any Content that: (i) infringes, misappropriates or
 violates a third party&#39;s patent, copyright, trademark, trade secret, moral rights or other
 intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any
 conduct that would violate, any applicable law or regulation or would give rise to civil
 liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene,
 pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred,
 harassment, or harm against any individual or group; (vi) is violent or threatening or
 promotes violence or actions that are threatening to any person or entity; or (vii) promotes
 illegal or harmful activities or substances; (viii) other analogous and similar acts.<br><br>
 You also agree not to:
 <ol type="a">
 <li>
 Conduct yourself in an unprofessional manner, including, without limitation, using
 profanity or other objectionable language with users.
 </li>
 <li>
 Violate the <a class="light-blue" href="./service-etiquette">Service Etiquette</a>, including, without limitation, helping a student to cheat,
 completing graded assignments for a student, etc. Engage in conduct that could
 artificially impact your reviews on the Site or Service, including, without limitation,
 unnecessarily splitting tutor lessons/sessions to get multiple reviews for what should
 have been a single lesson/session, incentivizing users to provide positive reviews,
 intimidating users associated with their review, etc.
 </li>
 <li>
 Engage in any form of plagiarism.
 </li>
 <li>
 Engage in any conduct with the intent or result to trick the Service or Site to garner
 additional payments.
 </li>
 <li>
 Engage in any fraudulent activity in connection with the Service or Site, including,
 without limitation, accepting payment for lessons/sessions that did not occur, allowing
 multiple lessons/sessions to occur simultaneously, etc.
 </li>
 <li>
 Share any Accounts or accounts with Third-Party payment services with anyone
 </li>
 <li>
 Attempt to become a Tutor on the Service after being deactivated or terminated.
 </li>
 <li>
 Use, display, mirror or frame the Site, or any individual element within the Site or
 Services, Chalkboard&#39;s name, any Chalkboard trademark, logo or other proprietary
 information, or the layout and design of any page or form contained on a page, without
 Chalkboard&#39;s express written consent;
 </li>
 <li>
 Access, tamper with, or use non-public areas of the Site, Chalkboard&#39;s computer
 systems, or the technical delivery systems of Chalkboard&#39;s providers;
 </li>
 <li>
 Attempt to probe, scan, or test the vulnerability of any Chalkboard system or network
 or breach any security or authentication measures; Avoid, bypass, remove, deactivate,
 impair, descramble or otherwise circumvent any technological measure implemented
 by Chalkboard or any of Chalkboard&#39;s providers or any other third party (including
 another user) to protect the Site, Services or Collective Content;
 </li>
 <li>
 Attempt to access or search the Site, Services or Collective Content or download
 Collective Content from the Site or Services through the use of any engine, software,
 tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools
 or the like) other than the software and/or search agents provided by Chalkboard or
 other generally available third party web browsers;
 </li>
 <li>
 Send any unsolicited or unauthorized advertising, promotional materials, email, junk
 mail, spam, chain letters or other form of solicitation;
 </li>
 <li>
 Use any meta tags or other hidden text or metadata utilizing a Chalkboard trademark,
 logo URL or product name without Chalkboard&#39;s express written consent;
 </li>
 <li>
 Use the Site, Services or Collective Content for any commercial purpose or the benefit
 of any third party or in any manner not permitted by these Tutor Terms &amp; Conditions
 or any other policies applicable to use of the Site, Services or Collective Content;
 </li>
 <li>
 Forge any TCP/IP packet header or any part of the header information in any email or
 newsgroup posting, or in any way use the Site, Services or Collective Content to send
 altered, deceptive or false source-identifying information;
 </li>
 <li>
 Attempt to decipher, decompile, disassemble or reverse engineer any of the software
 used to provide the Site, Services or Collective Content;
 </li>
 <li>
 Interfere with, or attempt to interfere with, the access of any user, host or network,
 </li>
 <li>
 including, without limitation, sending a virus, overloading, flooding, spamming, or mail-
 bombing the Site or Services;
 </li>
 <li>
 Collect or store any personally identifiable information from the Site or Services from
 other users of the Site or Services without their express permission;
 </li>
 <li>
 Impersonate or misrepresent your affiliation with any person or entity;
 Provide to Chalkboard or use as your profile image any fake image or images of
 celebrities, cartoons, or avatars;
 </li>
 <li>
 Violate any applicable law or regulation; or
 </li>
 <li>
 Encourage or enable any other individual to do any of the foregoing.
 </li>
 <li>
 Other similar and related acts which are prejudicial and damaging to the interest of
 Chalkboard.
 </li>
 </ol>
 In addition to the above, you agree that you:
 <ol>
 <li>
 Will comply with all applicable local, national and foreign laws, treatises and
 regulations in connection with your provision of Tutorial Services.
 </li>
 <li>
 Are solely responsible for the content of the communications, assistance and direction
 that you provide as part of the Tutorial Services.
 </li>
 <li>
 Are solely responsible for all equipment necessary to access and use the Site and
 Services and to provide Tutorial Services. If we determine that there have been, or
 receive reports of, repeated technical issues with your provision of tutoring services,
 we may suspend, cancel or terminate your Account.
 </li>
 <li>
 Will not record or otherwise store any Tutorial Service sessions or Content that you
 access or provide through the Site or Services (except as expressly permitted by
 Chalkboard in writing)
 </li>
 </ol>
 Chalkboard will have the right to investigate and prosecute violations of any of the above
 to the fullest extent permitted by applicable law. Chalkboard may involve and cooperate
 with law enforcement authorities or third parties in investigating and prosecuting the
 users who violate these Tutor Terms &amp; Conditions or any other policies applicable to use
 of the Services. Chalkboard acts as a technology service that facilitates the provision of
 Tutorial Services between Tutors and Users. You acknowledge that Chalkboard has no
 obligation to monitor your access to or use of the Site, Services or Collective Content or to
 review or edit any Collective Content, but has the right to do so for the purpose of
 operating the Site and Services, to ensure your compliance with these Tutor Terms &amp;
 Conditions or any other policies applicable to use of the Services, or to comply with
 applicable law or the order or requirement of a court, administrative agency or other
 governmental body. Chalkboard reserves the right, at any time and without prior notice, to
 (i) remove content from the Service at any time for violations of these Tutor Terms &amp;
 Conditions or other policies applicable to use of the Service; (ii) give a Tutor the
 opportunity to amend content it believes to be in violation of these Tutor Terms &amp;
 Conditions or other policies applicable to use of the Service.; and (iii) take appropriate
 action against a Tutor. Nonetheless, you agree that, to the fullest extent permitted by
 applicable law, Chalkboard is not responsible for any User Content or any Tutor&#39;s failure to
 comply with these Tutor Terms &amp; Conditions or other policies applicable to use of the
 Service. Without limiting the terms of the &quot;Limitation of Liability&quot; section below and to the
 fullest extent permitted by applicable law, Chalkboard is not responsible for the use or
 exchange of any information, files or goods between Tutors and Users.

 <li class="my-4 is-size-3 dark-grey">
 Termination and Account Cancellation
 </li>
 Chalkboard reserves the right to suspend, disable, cancel or terminate your Account and
 revoke your access to and use of the Site, Services and Collective Content at any time, with
 or without cause, including, without limitation, if you breach these Tutor Terms &amp;
 Conditions. In the event Chalkboard terminates your Account due to your breach of these
 Tutor Terms &amp; Conditions or violation of any law, you will remain liable for all amounts due
 hereunder and consequences of your unauthorized and illegal activities. You may cancel
 your Account at any time through the platform or by sending an email to
 <a class="light-blue" href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a>.

 <br><br>
 If your account is suspended and you apply to be reinstituted as a Tutor on the Service,
 Chalkboard is under no obligation to honor such request but may do so in its sole
 discretion and in accordance with all Chalkboard policies, procedures, or requirements for
 reinstitution applicable at that time.
 <li class="my-4 is-size-3 dark-grey">
 Limitation of Liability 
 </li>
 YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
 LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE,
 SERVICES AND COLLECTIVE CONTENT REMAINS WITH YOU. TO THE MAXIMUM
 EXTENT PERMITTED BY LAW, NEITHER CHALKBOARD NOR ANY OTHER PERSON OR
 ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE, SERVICES
 OR COLLECTIVE CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
 EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF
 DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
 SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR
 ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS
 ARISING OUT OF OR IN CONNECTION WITH THESE TUTOR TERMS AND
 CONDITIONS OR FROM THE USE OF OR INABILITY TO USE THE SITE, SERVICES OR
 COLLECTIVE CONTENT, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR
 MEETINGS WITH TUTORS, USERS OR OTHER USERS OF THE SITE OR SERVICES OR
 OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF
 YOUR USE OF THE SITE OR SERVICES WHETHER BASED ON WARRANTY, CONTRACT,
 TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL
 THEORY, AND WHETHER OR NOT CHALKBOARD HAS BEEN INFORMED OF THE
 POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS
 FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.<br><br>
 IN NO EVENT WILL CHALKBOARD&#39;S AGGREGATE LIABILITY ARISING OUT OF GROSS
 NEGLIGENCE OR IN CONNECTION WITH THESE TUTOR TERMS AND CONDITIONS
 OR FROM THE USE OF OR INABILITY TO USE THE SITE, SERVICES OR COLLECTIVE
 CONTENT EXCEED THE TUTOR PAYMENT AMOUNTS CHALKBOARD HAS PAID TO
 YOU IN CONNECTION WITH YOUR PROVISION OF TUTORIAL SERVICES VIA THE
 SITE AND SERVICES OR FIVE THOUSAND PESOS (PHP5,000) (WHICHEVER IS
 GREATER). THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
 ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN CHALKBOARD AND YOU.<br><br>
 THE PRIOR LIMITATION ON DAMAGES IS NOT INTENDED TO LIMIT CHALKBOARD’S
 OBLIGATION TO PAY PREVAILING PARTY COSTS OR FEES IF RECOVERABLE
 PURSUANT TO APPLICABLE LAW. THE LIMITATIONS SET FORTH IN THIS SECTION
 WILL NOT LIMIT OR EXCLUDE CHALKBOARD’S LIABILITY FOR SERIOUS PERSONAL
 INJURY OR PROPERTY DAMAGE CAUSED BY CHALKBOARD, OR FOR
 CHALKBOARD’S GROSS NEGLIGENCE, FRAUD OR INTENTIONAL, WILLFUL,
 MALICIOUS OR RECKLESS MISCONDUCT. OTHERWISE, CHALKBOARD SHALL NOT
 BE LIABLE FOR ANY FAULT OR DAMAGE FOR ANY CAUSES BEYOND ITS CONTROL.<br><br>
 THE PARTIES ALSO AGREED THAT CHALKBOARD SHALL NOT, IN WHATEVER MANNER, BE HELD LIABLE FOR ANY
 AND ALL KINDS OF ACTS OR OMISSIONS BY A TUTOR, USER AND/OR ANYONE WHO HAS ACCESS TO
 CHALKBOARD’S ONLINE PLATFORM THAT CAUSES ANY FORM OF DAMAGE OR LIABILITY TO ANY PERSON. IT IS
 <br><br>
 FURTHER AGREED THAT ANY DAMAGE, LIABILITY OR CLAIM IN RELATION TO THE SAID ACTS/OMISSIONS SHALL BE
 THE SOLE RESPONSIBILITY OF THE PERSON/S WHO CAUSES THE SAME. THE RESPONSIBLE PERSON/S ALSO
 UNDERTAKES TO COMPENSATE CHALKBOARD FOR ANY DAMAGE OR LOSS THAT IT MAY SUSTAIN AS A RESULT OF
 THE SAME ACTS/OMISSIONS.
 <li class="my-4 is-size-3 dark-grey">
 Notices 
 </li>
 Any notices or other communications permitted or required hereunder, including those
 regarding modifications to these Tutor Terms and Conditions, will be in writing and given:
 (i) by Chalkboard via email (in each case to the email address that you provide); or (ii) by
 posting to the Site. For notices made by e-mail, the date of receipt will be deemed the date
 on which such notice is transmitted. It is only the email that you utilized in accessing the
 Chalkboard shall be recognized as your official email to which any notice will be sent.
 <li class="my-4 is-size-3 dark-grey">
 Execution in Good Faith &amp; Resolution of Disputes
 </li>
 The Parties are fully aware that this agreement is made in good faith and based on their
 trust and confidence in each other. They also agree to resolve any disputes that may arise
 from this agreement by way of reasonable discussion and amicable settlement. However, if
 a dispute remains unresolved for thirty days from the time it was presented in writing to
 the other party, the aggrieved party shall resort to arbitration in accordance with the
 Republic Act 9285 otherwise known as the Alternative Dispute Resolution Act of 2004
 (Alternative Dispute Resolution Act of 2004). Venue of arbitration shall be in Subic Bay
 Freeport Zone to the exclusion of other venues. The dispute shall be presented to a sole
 arbitrator jointly chosen by the parties whose decision shall be final and binding unto the
 parties.
 </ol><br><br>
 As Tutor, I have read, understand, and agree to abide by the foregoing terms and conditions of the
 Chalkboard including any subsequent amendment or revision thereof. Should I commit any
 violation, I understand and agree that my access shall be revoked, and Chalkboard may file any
 legal action against me.
 <table class="table my-6 table is-bordered is-striped is-hoverable is-fullwidth">
 <thead>
 <tr>
 <th class="has-text-centered orange-bg">SCENARIO</th>
 <th class="has-text-centered orange-bg">PENALTY COST</th>
 </tr>
 </thead>
 <tbody>
 <tr>
 <td>1. When a tutor is <strong>
 late for 11-15 minutes
 without prior notice
 </strong> and the session still
 progresses</td>
 <td>
 75% payout, (25% goes back to parent as a credit,
 net of any third-party processing fees)<br><br>
 <strong>“Tardiness” warning</strong> to tutor. Tutor will be
 <strong>delisted after 5 warnings</strong>.
 </td>
 </tr>

 <tr>
 <td>
 2. When a tutor is late for 16 minutes or
 more without prior notice, the session is
 cancelled and is considered as <strong>“no show”</strong> for
 the tutor
 </td>
 <td>
 0% PAYOUT<br><br>
 ● <strong>“No show”</strong> warning to tutor. Tutor will be delisted after 3 <strong>“No show”</strong> warnings.
 </td>
 </tr>

 <tr>
 <td>
 4. When a tutor cancels or reschedules a
 session, the same day as scheduled,
 regardless of any reason
 </td>
 <td>
 <strong>Less than 4 hours notice</strong>
 <br><br>
 ● <strong>“No show”</strong> warning to tutor. Tutor will
 be delisted after 3 “No show” warnings.
 <br><br>
 <strong>More than 4 hours notice</strong>
 <br><br>
 ● Allowed without penalty up to <strong>5x per
 calendar year</strong>
 <br><br>
 ● <strong>“No show”</strong> warning to tutor on the <strong>6th
 instance and to each succeeding</strong>
 instance. Tutor will be delisted after 3
 “No show warnings”
 </td>
 </tr>

 <tr>
 <td>
 5. When a tutor <strong>cancels all or some</strong> of the
 upcoming sessions <strong>in advance</strong>
 (at least 4 hours before the next scheduled session) regardless of any reason
 </td>
 <td>
 <strong>More than 4 hours notice</strong><br><br>
 ● Allowed without penalty up to <strong>
 3x per
 calendar year
 </strong>. Cancellation/rescheduling session) regardless of any reason
 of all or some of the remaining upcoming
 sessions counts as 1 instance.<br><br>
 ● “No show” warning to tutor on the <strong>
 4th
 instance and to each succeeding
 instance
 </strong>. Tutor will be delisted after 3
 “No show warnings”
 </td>
 </tr>

 <tr>
 <td>
 6. When a <strong>tutor delists himself/herself</strong> from
 the platform <strong>without 3-day notice</strong> and still
 has upcoming session/s
 </td>
 <td>
 Tutor is <strong>blacklisted</strong> on the platform
 </td>
 </tr>

 <tr>
 <td>
 7. Tutor <strong>abandons the learner </strong>while session
 is in progress (as reported by Parent/Learner)
 <br><br>
 − Tutor <strong>did not report</strong> incident to
 Customer Support
 <br><br>
 − Tutor <strong>reported the incident</strong> and
 reason is found valid (i.e. power
 interruption, no internet connection,
 etc.)
 </td>
 <td>
 <div class="my-5"></div>
 <br><br>
 − Counts as 1 <strong>“no show” incident</strong>.
 <br><br>
 − If a session is interrupted, the tutor will be paid
 <strong>according to the number of minutes rendered.</strong>
 </td>
 </tr>
 </tbody>
 </table>

 <h3 class="is-size-5 my-4 dark-grey has-text-centered">
 Service Etiquette
 </h3>
 <ul>
 <li>
 I understand that my role as a tutor is to assist tutees in developing learner independence
 by helping them develop the skills necessary to achieve their most effective and efficient
 learning.
 </li>
 <li>
 I undertake never to do a tutee’s work for him or her.
 </li>
 <li>
 I understand that my relationship to the student is professional rather than personal, and I
 will avoid establishing inappropriate relationships with tutees.
 </li>
 <li>
 I will respect every tutee’s uniqueness and personal dignity at all times by accepting him or
 her without judgment.
 </li>
 <li>
 I will respect, and will work to improve my sensitivity to, tutees’ cultural, individual and
 role differences, including those based on age, gender, sexual orientation, race, ethnicity,
 culture, national origin, religion, disability, language and socioeconomic status.
 </li>
 <li>
 I recognize the existence of individual learning styles and will remain flexible in my
 approach to student learning.
 </li>
 <li>
 Recognizing that I may not be able to meet all of the needs of tutees, I will be willing to seek
 assistance from more qualified employees and/or to direct tutees to appropriate resources
 when tutees’ needs exceed the boundaries of my competence and expertise.
 </li>
 <li>
 I will fulfill all my commitments to tutees - including those relating to punctuality and
 attendance - out of courtesy and to be a good example for my tutees.
 </li>
 <li>
 I will establish a safe environment where students feel comfortable to ask questions and
 admit weaknesses.
 </li>
 <li>
 I will not tutor any form of tests, including take-home tests/essays
 </li>
 <li>
 I will keep all information about tutees confidential.
 </li>
 <li>
 When on duty as a tutor, I will conduct myself in a professional manner
 </li>.
 <li>
 I will act professionally about my colleagues by never commenting negatively on other
 tutors, whether on his/her teaching style and strategies.
 </li>
 <li>
 I will keep current in both my subject area(s) and learning methodologies.
 </li>
 </ul>

 </div>
 </div>
</section>
    </div>
</template>
<script>
export default {
  name: "tutor_tc",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>

</style>
<template>
  <div>
        <b-form class="py-3">
            <b-card-body>
              <b-row>
                <b-col lg="5">
                    <div style="font-weight: 600;">Email & Notifications</div>
                    <div class="font-size-sm">Adjust your email preferences to control whether to
                    receive email notifications.</div>

                    <div class="loading" v-if="isLoading">
                      <div class="loader"></div>
                    </div>

                    <div class="py-3"> 
                     
                  
                      
                        <b-card v-for="(ac_item, ac_index) in alert_categories" :key="ac_index" class="mb-2 light-white-bg ">
                          <div class="d-flex align-items-center justify-content-between">
                            <div>
                              <div style="font-weight: 600;">{{ac_item.category_name}}</div>
                              <div class="font-size-xs">{{ac_item.category_description}}</div>
                            </div>
                            <div>
                              <b-form-checkbox v-model="ac_item.alert_status" @change="onChangeAlertState(ac_item.category_code, ac_item.category_name, ac_item.alert_status)" switch value="1"
      unchecked-value="0">
                                {{ac_item.alert_status == 1 ? 'On' : 'Off'}}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </b-card>
                                         
                   


                  

                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
  </div>
</template>

<script>
import mixins from "../../mixins";

  export default {
    data: () => ({
        isLoading: true,
        alert_categories: []
    }),
    beforeMount() {
    },
    mounted() { 
      this.getCategories();
    },
    computed: { 
    },
    methods: { 
      getCategories(){ 
        const ApiURLOnl =
          process.env.VUE_APP_BACKEND_URL +
          "/api/web/notification/get-user-alert-preferences";

        const options = {
          headers: this.getGetRequestHeaders(),
          mode: "cors",
        };

        this.axios.get(ApiURLOnl, options).then((data) => {
          const _data = data.data.data;
          this.alert_categories = _data;
        })
        .finally(() => { this.isLoading = false });        
      },
      onChangeAlertState(category_code, category_name, flagval){ 
      
        const ApiURL =
          process.env.VUE_APP_BACKEND_URL + "/api/web/notification/set-alert-subscription";

        const options = {
          headers: this.getPostRequestHeaders(),
          mode: "cors",
        };

        let paramData = new FormData();

        paramData.append("alert_category", category_code);
        paramData.append("alert_status", flagval);

        this.axios
          .post(ApiURL, paramData, options)
          .then((data) => {
            const _data = data.data;

            if(_data.status  == 200){
              this.$bvToast.toast( (flagval == 1 ? 'You are now subscribed to ' : '') + ' "' + category_name + '" ' + (flagval == 1 ? 'notifications.' : 'notifications are now disabled.'), {
                title: (flagval == 1 ? "Subscription Activated" : "Subscription Deactivated"),
                variant: (flagval == 1 ? "success" : "warning"),
                solid: true,
                toaster: "b-toaster-top-right"
              });
            }else{
              this.$bvToast.toast( 'There was a problem updating "' + category_name + '".', {
                title: "System Error",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-right"
              });
            }


          })
          .catch((err) => console.error(err));
      }
    },
    mixins: [mixins],
  }
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <section class="cb-subjects-cards-container container py-5">
      <div class="row py-5" v-if="this.CourseData.subject_list.length > 0">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(0, 2)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
              <div class="pb-3">
                <b-badge :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.target_learners}}</b-badge>
                <b-badge v-if="item.session_count > 0 || item.session_count != null" :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.session_count}} sessions</b-badge>
              </div>
              <h6 class="card-title mb-0">{{item.subject_name}}</h6>
              <p class="" style="font-size: calc(var(--fs-one) + 2px);font-weight:500;color: #95908e;">{{item.subject_subtitle}}</p>
              <p class="card-text">{{item.subject_description}}</p>
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link" v-if="item.view_tutors_url">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link cb-learn-more-at " v-if="CourseData.course_code == 'academic_tutoring' && item.learn_more_url">Learn More</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link" v-if="CourseData.course_code != 'academic_tutoring' && item.learn_more_url">Learn More</b-link>
            </div>

          </div>
        </div>
      </div>
      <div class="row py-5" v-if="this.CourseData.subject_list.length >= 3">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(2, 4)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
              <div class="pb-3">
                <b-badge :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.target_learners}}</b-badge>
                <b-badge v-if="item.session_count > 0 || item.session_count != null" :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.session_count}} sessions</b-badge>
              </div>
              <h6 class="card-title mb-0">{{item.subject_name}}</h6>
              <p class="" style="font-size: calc(var(--fs-one) + 2px);font-weight:500;color: #95908e;">{{item.subject_subtitle}}</p>
              <p class="card-text">{{item.subject_description}}</p>
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link" v-if="item.learn_more_url">Learn More</b-link>
            </div>

          </div>
        </div>
      </div>
      <div class="row py-5" v-if="this.CourseData.subject_list.length >= 5">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(4, 6)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
              <div class="pb-3">
                <b-badge :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.target_learners}}</b-badge>
                <b-badge v-if="item.session_count > 0 || item.session_count != null" :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.session_count}} sessions</b-badge>
              </div>
              <h6 class="card-title mb-0">{{item.subject_name}}</h6>
              <p class="" style="font-size: calc(var(--fs-one) + 2px);font-weight:500;color: #95908e;">{{item.subject_subtitle}}</p>
              <p class="card-text">{{item.subject_description}}</p>
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link" v-if="item.learn_more_url">Learn More</b-link>
            </div>

          </div>
        </div>
      </div>
      <div class="row py-5" v-if="this.CourseData.subject_list.length >= 7">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(6,8)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
              <div class="pb-3">
                <b-badge :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.target_learners}}</b-badge>
                <b-badge v-if="item.session_count > 0 || item.session_count != null" :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.session_count}} sessions</b-badge>
              </div>

              <h6 class="card-title mb-0">{{item.subject_name}}</h6>
              <p class="" style="font-size: calc(var(--fs-one) + 2px);font-weight:500;color: #95908e;">{{item.subject_subtitle}}</p>
              <p class="card-text">{{item.subject_description}}</p>
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link">Learn More</b-link>
            </div>

          </div>
        </div>
      </div>

      <div class="row py-5" v-if="this.CourseData.subject_list.length >= 8">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(8,9)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
              <div class="pb-3">
                <b-badge :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.target_learners}}</b-badge>
                <b-badge v-if="item.session_count > 0 || item.session_count != null" :class="`badge-${CourseData.course_code} mr-2`" pill>{{item.session_count}} sessions</b-badge>
              </div>
              <h6 class="card-title mb-0">{{item.subject_name}}</h6>
              <p class="" style="font-size: calc(var(--fs-one) + 2px);font-weight:500;color: #95908e;">{{item.subject_subtitle}}</p>
              <p class="card-text">{{item.subject_description}}</p>
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link">Learn More</b-link>
            </div>

          </div>
        </div>
      </div>

      <!-- <div class="row py-5" v-if="this.CourseData.subject_list.length >= 9">
        <div class="col col-lg-6 col-sm-12" v-for="(item, index) in this.CourseData.subject_list.slice(8, 10)" :key="index">
          <div :class="'card ' + CourseData.course_code + '-card'">
            <img v-if="item.subject_image != ''" class="card-img-top" :src="require('@/assets/imgs/offerings/' + item.subject_image)">
            <div class="card-body">
                <p class="card-text">{{item.target_learners}}</p>
              <h6 class="card-title">{{item.subject_name}}</h6>
              <p class="card-text">{{item.subject_description}}</p>
            
            </div>
            <div class="card-footer">
              <b-link :to="item.view_tutors_url" class="card-link cb-primary-link">View Tutors</b-link>
              <b-link :to="item.learn_more_url" class="card-link cb-learn-more-link">Learn More</b-link>
            </div>

          </div>
        </div>
      </div> -->

    </section>

    <section v-if="CourseData.course_code == 'academic_tutoring'" class="container py-5 my-5">
      <b-row align-h="center" align-v="center">
        <b-col lg="7" md="12" sm="12">
          <div class="mb-5">
            <h6 class="dark-blue">Core Subjects</h6>
            <div class="grey">Learn academic subjects set in the standards of local curriculum (i.e. Math, English, Science, Filipino, Araling Panlipunan, etc).</div>
          </div>
          <div class="mb-5">
            <h6 class="dark-blue">International Curriculum</h6>
            <div class="grey">Get access to tutors who cater to learners taking Cambridge, International Baccalaureate and Common Core Curriculum for countries like the United Kingdom, United States, and more.</div>
          </div>
        </b-col>
        <b-col lg="5" md="12" sm="12">
          <div class="text-center">
            <svg width="351" height="327" viewBox="0 0 351 327" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M188.476 42.4051C220.427 44.359 255.148 47.1219 277.276 66.0337C298.911 84.5229 305.51 116.287 304.834 141.848C304.172 166.849 308.005 230.634 287.722 249.378C265.79 269.645 194.013 271.207 160.882 270.998C127.884 270.79 83.4643 273.053 59.0377 254.944C32.5975 235.343 47.1281 170.844 43.4325 141.848C39.529 111.219 43.9328 79.1202 72.8221 59.3135C99.6553 40.9165 153.529 40.2681 188.476 42.4051Z" fill="#FFBD59" fill-opacity="0.2" />
              <g clip-path="url(#clip0_241_1119)">
                <path d="M208.035 113.857L220.378 67.9308L246.602 74.9892" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M214.207 90.894L238.792 97.5113" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M208.035 113.857L232.62 120.475" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M250.649 125.327L261.229 85.9615" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M250.649 125.327L256.38 104.004C258.571 95.8512 266.951 91.0196 275.098 93.2124C283.245 95.4052 288.073 103.792 285.881 111.945L280.151 133.268" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M315.303 54.7854L216.964 28.3163C211.533 26.8545 205.946 30.0756 204.485 35.5109L178.036 133.925C176.576 139.36 179.794 144.952 185.225 146.413L283.565 172.883C288.996 174.344 294.583 171.123 296.044 165.688L322.493 67.2737C323.953 61.8385 320.735 56.2472 315.303 54.7854Z" stroke="#FFBD59" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <g clip-path="url(#clip1_241_1119)">
                <path d="M48.1072 214.605L78.8783 204.647M73.5516 277.941L88.2718 249.129M101.957 197.178L132.728 187.22M68.468 225.023L58.5176 194.228M127.401 260.515L142.121 231.703M149.157 253.475L120.367 238.743M174.233 263.077C174.921 265.206 173.757 267.483 171.63 268.171L63.9315 303.024C61.8045 303.712 59.5292 302.548 58.8413 300.419L24.0152 192.638C23.3273 190.51 24.4907 188.233 26.6177 187.544L134.317 152.692C136.444 152.003 138.719 153.167 139.407 155.296L174.233 263.077Z" stroke="#FFBD59" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_241_1119">
                  <rect width="162.943" height="163.05" fill="white" transform="matrix(0.965633 0.25991 -0.259541 0.965732 192.752 0.692749)" />
                </clipPath>
                <clipPath id="clip1_241_1119">
                  <rect width="157.304" height="157.401" fill="white" transform="matrix(0.951421 -0.307892 0.307468 0.951558 0.0950928 177.186)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </b-col>
      </b-row>
    </section>
    <section :class="'container-fluid py-5 ' + this.CourseData.course_code + '-bg'">
      <div class="row align-items-center justify-content-center">
        <div class="col-10">
          <div class="text-center text-white">
            <p class="p-3 fs-24">{{this.CourseData.summary}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SubjectList",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  props: ["CourseData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.badge-academic_tutoring {
  background: rgba(0, 48, 96, 15%);
  color: var(--dark-blue);
  text-transform: uppercase;
}
.badge-reading {
  background: rgba(54, 177, 186, 15%);
  color: #36b2ba;
  text-transform: uppercase;
}
.badge-coding {
  background: rgba(253, 210, 133, 15%);
  color: var(--orange);
  text-transform: uppercase;
}
</style>
<template>
  <b-container fluid class="max-width-1200 pt-5">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit()">
        <b-row class="py-5">
          <b-col sm="12" lg="7">
            <div class="p-2">
              <h5 class="pb-3">Session Request</h5>
              <ol class="session-request-steps-list">
                <li
                  ref="learner_details"
                  class="active-session-request-step pb-4"
                >
                  <!-- Learner Details Group -->
                  <div class="font-weight-bold pb-4">Learner Details</div>
                  <b-form-group class="pb-4">
                    <!-- Learner -->
                    <b-form-group label="Learner">
                      <ValidationProvider
                        name="learner"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-select
                          v-model="selectedLearner"
                          :state="getValidationState(validationContext)"
                          :disabled="isSelectedLearnerDisabled"
                        >
                          <b-form-select-option value=""
                            >-- select --</b-form-select-option
                          >
                          <b-form-select-option
                            v-for="(option, index) in learnerOptions"
                            :key="index"
                            :value="option.value"
                            >{{ option.text }}</b-form-select-option
                          >
                        </b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- Grade Level -->
                    <b-form-group label="Level / Category ">
                      <ValidationProvider
                        name="grade level"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-select
                          v-model="selectedGradeLevel"
                          :state="getValidationState(validationContext)"
                          :disabled="isSelectedGradeLevelDisabled"
                        >
                          <b-form-select-option value=""
                            >-- select --</b-form-select-option
                          >
                          <b-form-select-option
                            v-for="(option, index) in gradeLevelOptions"
                            :key="index"
                            :value="option"
                            >{{ option.name }}</b-form-select-option
                          >
                        </b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- Subject -->
                    <b-form-group label="Subject / Lesson">
                      <ValidationProvider
                        name="subject"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-select
                          v-model="selectedSubject"
                          :state="getValidationState(validationContext)"
                          :disabled="isSelectedSubjectDisabled"
                        >
                          <b-form-select-option value=""
                            >-- select --</b-form-select-option
                          >
                          <b-form-select-option
                            v-for="(option, index) in subjectOptions"
                            :key="index"
                            :value="option"
                            >{{ option }}</b-form-select-option
                          >
                        </b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                        <div class="text-cb-color" v-if="isSubjectLoading">
                          <b-spinner class="align-middle" small></b-spinner>
                          <strong><small> loading subjects...</small></strong>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-form-group>
                  <!-- End Learner Details Group -->
                </li>
                <li
                  ref="schedule_details"
                  class="pb-4"
                  :class="{
                    'active-session-request-step':
                      selectedLearner !== '' &&
                      selectedGradeLevel !== '' &&
                      selectedSubject !== '',
                  }"
                >
                  <div class="font-weight-bold pb-4">Schedule Details</div>
                  <!-- Session Type -->
                  <b-form-group label="Session Type" class="pb-4">
                    <p class="mt-n1 font-weight-normal font-size-sm grey">
                      Please select a type of session
                    </p>
                    <b-button-group class="btn-group-container">
                      <b-button
                        class="btn-group-btn-outline"
                        :class="{
                          'btn-group-btn-outline-hover':
                            selectedSessionType === 'hourly',
                        }"
                      >
                        <font-awesome-icon class="" :icon="['far', 'clock']" />
                        Hourly
                      </b-button>
                      <b-button class="btn-group-btn-outline" disabled>
                        <font-awesome-icon
                          class=""
                          :icon="['fas', 'layer-group']"
                        />
                        Bundle
                      </b-button>
                    </b-button-group>
                  </b-form-group>
                  <!-- Set Schedule -->
                  <b-form-group class="pb-4">
                    <div class="font-weight-bold">Set Schedule</div>
                    <p class="mt-n1 font-weight-normal font-size-sm grey">
                      You can book 1 up to 20 hours
                    </p>
                    <div class="rounded-top grey-bg p-2">
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <font-awesome-icon
                          class="font-size-xs mr-1 text-white"
                          :icon="['fas', 'info-circle']"
                        />
                        <label
                          for="time-zone"
                          class="mb-0 mr-2 text-white font-weight-normal font-size-sm"
                          v-b-tooltip.hover
                          title="Please note that changing time zone affects the displayed date and time on schedules."
                        >
                          Time zone:
                        </label>
                        <b-form-select
                          class="w-50"
                          size="sm"
                          v-model="selectedTimeZone"
                          :disabled="isSelectedTzDisabled"
                        >
                          <b-form-select-option value="" disabled
                            >-- select --</b-form-select-option
                          >
                          <b-form-select-option
                            v-for="(item, index) in timeZoneOptions"
                            :key="index"
                            :value="item.timezone"
                            >{{
                              `${item.name} (${getTzOffSet(
                                calendar,
                                item.timezone
                              )})`
                            }}</b-form-select-option
                          >
                        </b-form-select>
                      </div>
                    </div>
                    <b-calendar
                      block
                      hide-header
                      locale="en-US"
                      ref="calendar"
                      id="calendar"
                      v-model="calendar"
                      class="cb-calendar cb-booking-calendar border rounded p-2"
                      :style="
                        calendarError != ''
                          ? 'border: 1px solid #dc3545!important'
                          : ''
                      "
                      :min="minDate"
                      :max="maxDate"
                      @selected="onSelectDay"
                    >
                    </b-calendar>
                    <div
                      v-if="calendarError != ''"
                      style="display: block !important"
                      class="invalid-feedback"
                    >
                      {{ calendarError }}
                    </div>
                  </b-form-group>
                  <!-- Schedule Summary -->
                  <div class="pb-2"><strong>Schedule Summary</strong></div>
                  <b-card no-body>
                    <b-card-header
                      class="rounded-top grey-bg p-3 font-weight-semibold"
                    >
                      <div class="mb-0 font-size-sm text-white ml-2">
                        You have added {{ totalSelectedTimeSlots }}
                        {{ `Session${totalSelectedTimeSlots > 1 ? "s" : ""}` }}
                      </div>
                    </b-card-header>
                    <b-card-body class="schedule-summary-container p-0">
                      <ul class="list-unstyled p-3">
                        <li
                          v-for="(item, index) in schedules"
                          :key="index"
                          class="grey font-weight-semibold font-size-sm pb-4"
                        >
                          <div class="mb-2">
                            {{ moment(item.date_schedule).format("dddd, LL") }}
                          </div>
                          <ul class="list-unstyled">
                            <li
                              v-for="(item2, index2) in item.items"
                              :key="index2"
                              class="shadow-sm p-2 mb-2 rounded"
                            >
                              <div
                                class="font-size-sm d-flex align-items-center justify-content-between"
                              >
                                <div class="d-flex">
                                  <div class="font-size-xs list-counter mr-2">
                                    {{ index2 + 1 }}
                                  </div>
                                  <div class="light-blue">
                                    {{
                                      formatDateTimeSchedule(
                                        item.date_schedule,
                                        item2
                                      )
                                    }}
                                  </div>
                                </div>
                                <button
                                  @click="onScheduleSummaryRemove(item, item2)"
                                  type="button"
                                  class="light-blue close"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </b-card-body>
                    <b-card-footer class="text-center silver-bg">
                      <small class="grey font-size-xs">
                        <font-awesome-icon
                          class=" mr-1"
                          :icon="['fas', 'info-circle']"
                        />Time zone displayed is based on your account's time
                        zone settings
                      </small>
                    </b-card-footer>
                  </b-card>
                </li>
                <li
                  class="pb-4"
                  :class="{
                    'active-session-request-step':
                      selectedLearner !== '' &&
                      selectedGradeLevel !== '' &&
                      selectedSubject !== '' &&
                      schedules.length > 0,
                  }"
                >
                  <!-- Learner Details Group -->
                  <div class="font-weight-bold pb-4">
                    Additional Information
                  </div>
                  <b-form-group label="Notes to Tutor">
                    <ValidationProvider
                      name="notes to tutor"
                      rules="required"
                      v-slot="validationContext"
                    >
                      <b-form-textarea
                        id="textarea"
                        v-model="notesToTutor"
                        placeholder="Send topics you want to discuss with your tutor"
                        rows="8"
                        max-rows="6"
                        :state="getValidationState(validationContext)"
                      ></b-form-textarea>
                      <b-form-invalid-feedback id="input-2-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group label="Attach Files">
                    <b-form-file
                      v-model="attachFiles"
                      @change="onFileChange"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .jpeg, .png, .docx, .doc, .ppt, .xls, .xlsx, .pdf, .zip, .rar"
                    >
                    </b-form-file>
                  </b-form-group>
                </li>
              </ol>
            </div>
          </b-col>
          <b-col sm="12" lg="5">
            <div
              v-if="!isTeacherDataLoaded"
              class="sticky-top shadow d-flex flex-column align-items-center justify-content-between p-4"
            >
              <!-- Skeletons -->
              <div class="py-2 w-100">
                <b-row
                  align-v="center"
                  no-gutters
                  class="text-sm-center text-md-left"
                >
                  <b-col sm="12" lg="3" md="2">
                    <b-skeleton type="avatar" size="5rem"></b-skeleton>
                  </b-col>
                  <b-col>
                    <div class="font-size-lg font-weight-bold">
                      <b-skeleton width="20%"></b-skeleton>
                    </div>
                    <p class="font-size-xs mb-1 font-italic">
                      <b-skeleton width="80%"></b-skeleton>
                    </p>
                    <div
                      class="d-flex align-items-center justify-content-sm-center justify-content-md-start"
                    >
                      <b-skeleton width="60%"></b-skeleton>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <hr />
              <div class="py-2 w-100">
                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <b-skeleton width="100%"></b-skeleton>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <b-skeleton width="100%"></b-skeleton>
                </div>
              </div>
              <hr />
              <div class="py-2 w-100">
                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <b-skeleton width="100%"></b-skeleton>
                </div>
              </div>
              <div class="w-100">
                <b-skeleton type="button" width="100%"></b-skeleton>
              </div>
            </div>

            <div
              v-if="isTeacherDataLoaded"
              class="sticky-top shadow d-flex flex-column align-items-center justify-content-between p-4"
            >
              <!-- Tutor Name and Pic -->
              <div class="py-2  w-100">
                <b-row
                  align-v="center"
                  no-gutters
                  class="text-sm-center text-md-left"
                >
                  <b-col sm="12" lg="3" md="2">
                    <b-avatar
                      v-bind="mainProps"
                      :src="teacherData.image"
                      alt="teacher image"
                      size="5rem"
                      class="mr-1"
                    ></b-avatar>
                  </b-col>
                  <b-col>
                    <div class="font-size-lg font-weight-bold">
                      {{ `${teacherData.firstname} ${teacherData.lastname}` }}
                    </div>
                    <!-- Tutor tagline -->
                    <p
                      class="font-size-xs mb-1 font-italic"
                      style="word-break: break-word; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;"
                    >
                      {{ teacherData.tagline }}
                    </p>
                    <div
                      class="d-flex align-items-center justify-content-sm-center justify-content-md-start"
                    >
                      <b-form-rating
                        v-model="averageRating"
                        icon-empty="star-fill"
                        variant="warning"
                        class="font-size-xs p-0 mr-2"
                        no-border
                        inline
                        readonly
                      ></b-form-rating>
                      <p class="font-size-xs mb-0 font-weight-bold mr-1 ">
                        {{ averageRating }}
                      </p>
                      <p class="font-size-xs mb-0 text-underline">
                        (
                        {{
                          `${reviewCount} Review${reviewCount > 1 ? "s" : ""}`
                        }}
                        )
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <hr />

              <!-- Tutor Rate and Session -->
              <div class="py-2 w-100">
                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <div class="grey">Rate per hour</div>
                  <div class="font-weight-bold">
                    <template v-if="showConvertedAmount">
                      {{ convertedTeacherRate }}
                    </template>
                    <template v-else>
                      {{ `₱ ${formatPrice(teacherData.rate)}` }}
                    </template>
                  </div>
                </div>

                <div
                  v-if="showConvertedAmount"
                  class="d-flex dark-silver font-weight-medium"
                >
                  <div class="ml-auto">
                    {{ `₱ ${formatPrice(teacherData.rate)}` }}
                  </div>
                </div>

                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100 pt-2"
                >
                  <div class="grey">Number of Sessions</div>
                  <div class="font-weight-bold">
                    {{ totalSelectedTimeSlots }}
                  </div>
                </div>
              </div>

              <hr />

              <!-- Subtotal -->
              <div class="py-2 w-100">
                <div
                  class="d-flex flex-row align-items-center justify-content-between w-100"
                >
                  <div class="grey">Subtotal</div>
                  <div class="font-weight-bold">
                    <template v-if="showConvertedAmount">
                      {{ convertedSubTotal }}
                    </template>
                    <template v-else>
                      {{
                        `₱ ${formatPrice(
                          totalSelectedTimeSlots * teacherData.rate
                        )}`
                      }}
                    </template>
                  </div>
                </div>
                <div v-if="showConvertedAmount" class="d-flex">
                  <div class="ml-auto dark-silver font-weight-medium">
                    {{
                      `₱ ${formatPrice(
                        totalSelectedTimeSlots * teacherData.rate
                      )}`
                    }}
                  </div>
                </div>
              </div>

              <template v-if="showConvertedAmount">
                <hr />
                <div
                  class="d-flex justify-content-between dark-silver w-100 py-2"
                >
                  <div style="font-size:14px;color:#bbb;">
                    Amount to be reflected on your billing statement shall be
                    its PHP currency equivalent which is 1
                    {{ currencyLocalization.currencyCode }} =
                    {{ parseFloat(currencyLocalization.rateTo).toFixed(2) }} PHP
                  </div>
                </div>
              </template>

              <!-- Request Session Button -->
              <div class="w-100">
                <button
                  @click="validateObserver"
                  class="w-100 primary-btn-outline my-3"
                  :class="{ 'c-not-allowed': isCheckingFileUpload }"
                  :disabled="
                    isLoading ||
                      isCheckSchedAvailLoading ||
                      isCheckingFileUpload
                  "
                >
                  <b-spinner
                    small
                    v-if="isLoading || isCheckSchedAvailLoading"
                  ></b-spinner>
                  <span v-if="isCheckSchedAvailLoading">
                    checking schedule availability...</span
                  >
                  <span v-else-if="isLoading"> sending request...</span>
                  <span v-else-if="!isLoading">Send Request</span>
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>

      <!-- Calendar Modal -->
      <b-modal
        id="calendar-modal"
        size="xl"
        title="Set Schedule"
        hide-footer
        body-class="p-0"
      >
        <template #modal-header="{ close }">
          <div class="w-100 d-flex align-items-center">
            <div>
              <div class="font-weight-semibold">Set Schedule</div>
              <small>You can book up to 20 sessions on a single request.</small>
            </div>
            <b-button type="button" class="close" size="sm" @click="close()">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </div>
        </template>
        <BookingCalendarModal />
      </b-modal>

      <!-- Review Session Request Modal -->
      <b-button v-show="false" v-b-modal.session-request-modal
        >Launch demo modal</b-button
      >

      <b-modal id="session-request-modal" hide-footer>
        <template #modal-header="{ close }">
          <div class="w-100 d-flex align-items-center">
            <div>
              <div class="font-weight-semibold">Review Session Request</div>
            </div>
            <b-button
              type="button"
              class="close font-size-xs btn-reset"
              size="sm"
              @click="close()"
            >
              <u>Edit</u>
            </b-button>
          </div>
        </template>
        Tutor Name and Pic
        <div class="py-2  w-100">
          <b-row
            align-v="center"
            no-gutters
            class="text-sm-center text-md-left"
          >
            <b-col sm="12" lg="3" md="2">
              <b-avatar
                v-bind="mainProps"
                alt="teacher image"
                size="5rem"
                class="mr-1"
              ></b-avatar>
            </b-col>
            <b-col>
              <div class="font-size-lg font-weight-bold">{{ tutorName }}</div>
              Tutor tagline
              <p class="font-size-xs mb-1 font-italic">
                This is a free online calculator which counts the number one
              </p>
              <div
                class="d-flex align-items-center justify-content-sm-center justify-content-md-start"
              >
                <b-form-rating
                  icon-empty="star-fill"
                  variant="warning"
                  class="font-size-xs p-0 mr-2"
                  no-border
                  inline
                  readonly
                ></b-form-rating>
                <p class="font-size-xs mb-0 font-weight-bold mr-1 ">5.0</p>
                <p class="font-size-xs mb-0 text-underline">( 23 Reviews )</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <hr />

        Tutor Rate and Session
        <div class="py-2 w-100">
          <div
            class="d-flex flex-row align-items-center justify-content-between w-100 pb-1"
          >
            <div>Learner Name</div>
            <div class="font-weight-bold">
              {Learner name}
            </div>
          </div>
          <div
            class="d-flex flex-row align-items-center justify-content-between w-100 pb-1"
          >
            <div>Level/Category</div>
            <div class="font-weight-bold">
              { Level/Category }
            </div>
          </div>
          <div
            class="d-flex flex-row align-items-center justify-content-between w-100 pb-1"
          >
            <div>Subject/Lesson</div>
            <div class="font-weight-bold">
              { Subject/Lesson }
            </div>
          </div>
        </div>
        <hr />

        Selected Session Review Session Request Modal
        <div class="py-2 w-100">
          <div
            class="d-flex flex-row align-items-center justify-content-between w-100 pb-4"
          >
            <div>Selected Session</div>
            <div class="font-weight-bold">
              { Number of sessions }
            </div>
          </div>
          <div class="schedule-summary-accordion-content-container bg-white ">
            <ul class="list-unstyled p-3">
              <li class="grey font-weight-semibold font-size-sm pb-4">
                <div class="mb-2">Monday, March 29, 2021</div>
                <ul class="list-unstyled">
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          1
                        </div>
                        <div class="light-blue">
                          Wednesday, December 29, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          2
                        </div>
                        <div class="light-blue">
                          Friday, December 30, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="grey font-weight-semibold font-size-sm pb-4">
                <div class="mb-2">Monday, March 29, 2021</div>
                <ul class="list-unstyled">
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          1
                        </div>
                        <div class="light-blue">
                          Wednesday, December 29, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          2
                        </div>
                        <div class="light-blue">
                          Friday, December 30, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="grey font-weight-semibold font-size-sm pb-4">
                <div class="mb-2">Monday, March 29, 2021</div>
                <ul class="list-unstyled">
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          1
                        </div>
                        <div class="light-blue">
                          Wednesday, December 29, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          2
                        </div>
                        <div class="light-blue">
                          Friday, December 30, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="grey font-weight-semibold font-size-sm pb-4">
                <div class="mb-2">Monday, March 29, 2021</div>
                <ul class="list-unstyled">
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          1
                        </div>
                        <div class="light-blue">
                          Wednesday, December 29, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                  <li class="shadow-sm p-2 mb-2 rounded">
                    <div
                      class="font-size-sm d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex  align-items-center">
                        <div class="font-size-xs list-counter mr-2">
                          2
                        </div>
                        <div class="light-blue">
                          Friday, December 30, 2021 at 7:00 AM - 8:00 AM
                          (Manila)
                        </div>
                      </div>
                      <button
                        type="button"
                        class="light-blue close"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        Request Session Button
        <div class="w-100">
          <button type="submit" class="w-100 primary-btn my-4">
            <b-spinner small v-if="isLoading"></b-spinner>
            <span v-if="isLoading"> sending request...</span>
            <span v-if="!isLoading">Confirm Request</span>
          </button>
        </div>
      </b-modal>
    </validation-observer>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins";
import review_mixins from "../../mixins/review";
import { getters, methods, watch } from "../../store/booking";
import BookingCalendarModal from "../booking/BookingCalendarModal.vue";
export default {
  name: "Booking",
  metaInfo: {
    title: "Chalkboard - Session Request Page",
  },
  components: { BookingCalendarModal },
  data: () => ({
    mainProps: {
      blank: true,
      blankColor: "#777",
      width: 75,
      height: 75,
      class: "m1",
    },
    learnerOptions: [],
    isSelectedLearnerDisabled: true,
    selectedGradeLevel: "",
    gradeLevelOptions: [],
    isSelectedGradeLevelDisabled: true,
    selectedSubject: "",
    subjectOptions: [],
    isSelectedSubjectDisabled: true,
    isSubjectLoading: false,
    selectedSessionType: "hourly",
    isSelectedTzDisabled: true,
    calendarError: "",
    teacherData: [],
    isTeacherDataLoaded: false,
    selectedScheduleSummary: [],
    notesToTutor: "",
    attachFiles: [],
    attachFileName: "",
    tutorName: "",
    tutorRate: "",
    isLoading: false,
    rebookData: [],
    averageRating: 0,
    reviewCount: 0,
    isCheckSchedAvailLoading: false,
    isCheckingFileUpload: false,
    originalFileName: "",
  }),
  beforeMount() {
    if (this.userData.role === "teacher")
      this.ShowModal("You are not allowed to access this page.", () => {
        this.$router.push("/");
      });
  },
  computed: {
    ...mapState(["userData"]),
    ...getters,

    totalSelectedTimeSlots() {
      const result = this.schedules.reduce((acc, curr) => {
        return acc + curr.items.length;
      }, 0);
      return result;
    },

    convertedTeacherRate() {
      return this.showConvertedAmount
        ? this.convertAmount(this.teacherData.rate).result
        : null;
    },

    convertedSubTotal() {
      return this.showConvertedAmount
        ? this.convertAmount(
            this.totalSelectedTimeSlots * this.teacherData.rate
          ).result
        : null;
    },
  },
  mounted() {
    const vm = this;

    if (this.$route.query.tchid != undefined) {
      localStorage.setItem("tutorId", this.$route.query.tchid);
    }

    if (localStorage.getItem("tutorId")) {
      vm.xAvailability = [];
      vm.schedules = [];
      vm.availableSchedule = [];
      vm.setRebookData();
      vm.tutorId = localStorage.getItem("tutorId");
      vm.loadUserData();
      vm.selectedTimeZone = vm.userData.timezone;
      vm.$store.dispatch("getRegionTimeZoneList").then((response) => {
        vm.timeZoneOptions = response.data.data;
        vm.isSelectedTzDisabled = false;
      });
      vm.minDate = vm.moment.tz(vm.selectedTimeZone).format();
      vm.maxDate = vm.moment.tz(
        "2024-09-15 00:00:00",
        "YYYY-MM-DD HH:mm:ss",
        "Asia/Manila"
      ).toDate();
      vm.$store
        .dispatch("getTeacherDetails", vm.tutorId)
        .then((response) => {
          vm.teacherData = response.data.data;
        })
        .finally(() => {
          vm.isTeacherDataLoaded = true;
        });
      vm.$watch(
        (_) => [_.selectedLearner, _.selectedGradeLevel, _.selectedSubject],
        () => {
          const yOffset =
            (document.getElementById("navbar-fp-main").offsetHeight + 15) * -1;
          var y = 0;
          if (
            vm.selectedLearner !== "" &&
            vm.selectedGradeLevel !== "" &&
            vm.selectedSubject !== ""
          ) {
            y =
              this.$refs["schedule_details"].getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
          } else {
            y =
              this.$refs["learner_details"].getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
          }
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      );
    }
    document.getElementById("navbar-fp-main").style.position = "relative";
    this.getTeacherReviewSummary(vm.tutorId)
      .then((response) => {
        const _data = response.data.data;
        this.averageRating = _data[0].average_rating || 0;
        this.reviewCount = _data[0].rating_count || 0;
      })
      .finally(() => {
        this.isRatingLoading = false;
      });

    document
      .querySelectorAll(
        'button[title="Previous month"], button[title="Current month"], button[title="Next month"]'
      )
      .forEach((btn) => {
        btn.addEventListener("click", function() {
          setTimeout(() => {
            vm.setActiveDateFromTz(vm.selectedTimeZone);
          }, 100);
        });
      });

    vm.setActiveDateFromTz(vm.selectedTimeZone);
  },
  methods: {
    ...methods,
    onCheckScheduleAvailability() {
      const vm = this;

      let scheduleArray = [];
      this.schedules.forEach((item) => {
        item.items.forEach((time) => {
          scheduleArray.push({
            start_date: `${item.date_schedule} ${time.split("-")[0]}`,
            end_date: `${item.date_schedule} ${time.split("-")[1]}`,
          });
        });
      });

      scheduleArray = scheduleArray.map((x) => {
        return this.moment
          .tz(`${x.start_date}`, this.selectedTimeZone)
          .tz("UTC")
          .format("YYYY-MM-DD HH:mm:ss");
      });

      const payload = {
        tutor_id: vm.tutorId,
        start_dates: scheduleArray,
        child_id: this.userData.role === "parent" ? vm.selectedLearner : 0,
      };

      vm.isCheckSchedAvailLoading = true;
      this.$store
        .dispatch("checkScheduleAvailability", payload)
        .then((response) => {
          const _data = response.data.data;
          if (
            response.data.status === 200 &&
            _data.filter((x) => x.schedule_status === "Not Available").length >
              0
          ) {
            var conflictSchedule = "";
            _data
              .filter((x) => x.schedule_status === "Not Available")
              .forEach((item) => {
                conflictSchedule += `<div style="font-weight: 600; font-size: 14px;">- ${this.moment
                  .utc(item.start_date)
                  .tz(this.selectedTimeZone)
                  .format("dddd, LL  hh:mm A")} (${
                  vm.userData.region_timezone
                })</div>`;

                const result = vm.schedules.find((x) => {
                  return (
                    x.date_schedule ===
                    this.moment
                      .utc(item.start_date)
                      .tz(this.selectedTimeZone)
                      .format("YYYY-MM-DD")
                  );
                });
                if (result !== undefined) {
                  const resultIndex = result.items.indexOf(
                    result.items.find((x) => {
                      return (
                        x ===
                        `${this.moment(item.start_date).format(
                          "HH:mm:ss"
                        )}-${this.moment(item.end_date).format("HH:mm:ss")}`
                      );
                    })
                  );
                  result.items.splice(resultIndex, 1);
                  if (result.items.length === 0) {
                    const index = vm.schedules.indexOf(result);
                    vm.schedules.splice(index, 1);
                  }
                }

                const day_part = vm.getDayPart(
                  vm.moment
                    .utc(item.start_date)
                    .tz(vm.selectedTimeZone)
                    .format("HH:mm:ss")
                );
                const date_value = `${vm
                  .moment(item.start_date)
                  .format("HH:mm:ss")}-${vm
                  .moment(item.end_date)
                  .format("HH:mm:ss")}`;
                const availResult = vm.xAvailability.find((x) => {
                  return (
                    x.date_schedule ===
                    this.moment
                      .utc(item.start_date)
                      .tz(this.selectedTimeZone)
                      .format("YYYY-MM-DD")
                  );
                });

                if (availResult) {
                  const resultItem = availResult.items[day_part].find((x) => {
                    return x === date_value;
                  });
                  if (resultItem) {
                    const resultIndex = availResult.items[day_part].indexOf(
                      resultItem
                    );
                    availResult.items[day_part].splice(resultIndex, 1);
                  }
                }

                const as_result = vm.availableSchedule.find((x) => {
                  return (
                    x.date_schedule ===
                      this.moment
                        .utc(item.start_date)
                        .tz(this.selectedTimeZone)
                        .format("YYYY-MM-DD") && x.time === date_value
                  );
                });
                if (as_result !== undefined) {
                  vm.notAvailableSchedule.push(as_result);
                }
              });
            this.ShowModal(
              `The following schedule${
                _data.length > 1 ? "s are" : " is"
              } not available.<br /><br />${conflictSchedule}<br /><div style="font-size: 11px;">*Note: <i>The selection of conflicts in the schedule will be automatically removed.</i></div>`,
              () => {
                document.getElementById("calendar").focus();
              }
            );
          } else {
            vm.onSendRequest();
          }
        })
        .finally(() => {
          vm.isCheckSchedAvailLoading = false;
        });
    },
    onSendRequest() {
      const vm = this;

      if (vm.schedules.length === 0) {
        vm.ShowModal("Please select schedule.", () => {
          document.getElementById("calendar").focus();
        });
        return;
      }

      const payload = {
        eventName: "AddToCart",
        data: {
          subject: this.selectedSubject,
          category: this.selectedGradeLevel.name,
          content_name: this.selectedGradeLevel.name.concat(
            " - ",
            this.selectedSubject
          ),
          value: this.totalSelectedTimeSlots * this.teacherData.rate,
          currency: "PHP",
        },
      };
      this.metaPixelTrack(payload);

      const params = {
        tutor_id: this.tutorId,
        session_type: this.selectedSessionType,
        grade_level: this.selectedGradeLevel.name,
        subject: this.selectedSubject,
        note: this.notesToTutor,
      };
      const bookingData = new FormData();
      Object.keys(params).forEach((key) => {
        bookingData.append(key, params[key]);
      });
      if (vm.userData.role === "parent") {
        bookingData.append("child_id", this.selectedLearner);
      }
      if (vm.attachFileName != "") {
        bookingData.append("file", this.attachFileName);
        bookingData.append("original_file", this.originalFileName);
      }
      const scheduleArray = [];
      this.schedules.forEach((item) => {
        item.items.forEach((time) => {
          scheduleArray.push({
            start_date: `${item.date_schedule} ${time.split("-")[0]}`,
            end_date: `${item.date_schedule} ${time.split("-")[1]}`,
          });
        });
      });
      scheduleArray.forEach((item, index) => {
        const utc_start_date = this.moment
          .tz(item.start_date, this.selectedTimeZone)
          .tz("UTC");
        const utc_end_date = this.moment(utc_start_date).add(1, "hours");
        bookingData.append(
          `schedules[${index}][start_date]`,
          utc_start_date.format("YYYY-MM-DD HH:mm:ss")
        );
        bookingData.append(
          `schedules[${index}][end_date]`,
          utc_end_date.format("YYYY-MM-DD HH:mm:ss")
        );
      });
      const bookingApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/request-booking`;
      const bookingOptions = {
        headers: {
          Accept: "application/json, multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };
      vm.isLoading = true;
      vm.axios
        .post(bookingApiURL, bookingData, bookingOptions)
        .then((response) => {
          const data = response.data;
          if (data.status === 422) {
            const conflictSchedule = [];
            data.data.forEach((x) => {
              const convertedDate = this.moment
                .tz(x?.start_date || x, "UTC")
                .tz(this.selectedTimeZone);
              conflictSchedule.push(
                `<div style="font-weight: 600; font-size: 14px;">- ${convertedDate.format(
                  "dddd, LL  hh:mm A"
                )} (${this.userData.region_timezone})</div>`
              );
            });
            const sameTimeDiffTutorErrorMsg =
              "Unable to proceed with this request due to schedule conflict with an existing booking.";
            const message =
              data.message === sameTimeDiffTutorErrorMsg
                ? `<p>${data.message}</p>`
                : "<p>Something went wrong.</p>" +
                  "<p>Possible causes are the following.</p>" +
                  "<p>1. Invalid date.</p>" +
                  "<p>2. Schedule unavailable.</p>";
            vm.ShowModal(`${message} ${conflictSchedule}`);
          } else {
            const { autoAccepted, transactionId } = data.message;
            let message;
            if (autoAccepted) {
              message = `<div>
                Booking request completed. Proceeding to payment.
              </div>`;
            } else {
              message = `<div>
                Your session request has been sent. You will receive an email 
                and SMS notification once your tutor accepts the request.
              </div>
              <br />
              <div>
                Please allow your tutor up to 24 hours to review the request. 
                If you need immediate assistance, please chat with us or email 
                <a href="mailto:support@chalkboard.com.ph">
                  support@chalkboard.com.ph
                </a>
              </div>`;
            }

            this.ShowModal(message, () => {
              if (autoAccepted) {
                vm.$router.push("/booking-payment/" + transactionId);
              } else {
                vm.$router.push("/schedule/requests#pending");
              }
            });
          }
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          if (this.schedules.length > 0) {
            this.onCheckScheduleAvailability();
          } else {
            this.onSendRequest();
          }
        }
      });
    },
    loadUserData() {
      const vm = this;
      switch (vm.userData.role) {
        case "student": {
          const learner = vm.userData.firstname;
          vm.learnerOptions = [];
          vm.learnerOptions.push({ text: learner, value: learner });
          vm.selectedLearner = learner;
          vm.isSelectedLearnerDisabled = false;
          break;
        }
        case "parent": {
          const childApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/children`;
          const childOptions = {
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };
          vm.axios.get(childApiURL, childOptions).then((data) => {
            const _data = data.data.data.data;
            this.learnerOptions = [];
            _data.forEach((children) => {
              this.learnerOptions.push({
                value: children.id,
                text: `${children.firstname} ${children.lastname}`,
              });
              vm.isSelectedLearnerDisabled = false;
            });
          });
        }
      }
      if (this.rebookData.note !== undefined) {
        this.notesToTutor = this.rebookData.note;
      }
      if (
        this.rebookData.account_type !== undefined &&
        this.rebookData.account_type === "parent"
      ) {
        this.selectedLearner = this.rebookData.child_id;
      }
      vm.loadGradeLevels();
    },
    async loadGradeLevels() {
      const vm = this;
      const gradeLevelsApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/grade-level?tutor_id=${vm.tutorId}`;
      const gradeLevelsOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${vm.UserToken}`,
        },
      };
      vm.axios
        .get(gradeLevelsApiURL, gradeLevelsOptions)
        .then((data) => {
          vm.gradeLevelOptions = data.data.data.data;
          if (this.rebookData.grade_level !== undefined) {
            const result = this.gradeLevelOptions.find((item) => {
              return item.name === this.rebookData.grade_level;
            });
            if (result !== undefined) {
              this.selectedGradeLevel = result;
            } else {
              if (this.$route.name === "booking") {
                this.ShowModal(
                  `Tutor ${this.teacherData.firstname ||
                    ""} no longer specializes in '${
                    this.rebookData.grade_level
                  }' level/category.`,
                  () => {
                    this.selectedGradeLevel = "";
                  }
                );
              }
            }
          }
        })
        .finally(() => {
          vm.isSelectedGradeLevelDisabled = false;
        });
    },
    async loadSubjects() {
      const vm = this;
      const subjectApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/subjects?tutor_id=${vm.tutorId}&level=${vm.selectedGradeLevel.grade_level_id}`;
      const subjectOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${vm.UserToken}`,
        },
      };
      vm.isSubjectLoading = true;
      vm.isSelectedSubjectDisabled = true;
      await vm.axios
        .get(subjectApiURL, subjectOptions)
        .then((data) => {
          const _data = data.data.data.data;
          vm.subjectOptions = [];
          vm.subjectOptions = _data.map((item) => item.name);
          vm.selectedSubject = "";
          if (this.rebookData.subject !== undefined) {
            if (this.selectedGradeLevel.name === this.rebookData.grade_level) {
              const result = this.subjectOptions.find((item) => {
                return item === this.rebookData.subject;
              });
              if (result !== undefined) {
                this.selectedSubject = this.rebookData.subject;
              } else {
                if (this.$route.name === "booking") {
                  this.ShowModal(
                    `Tutor ${this.teacherData.firstname ||
                      ""} no longer specializes in '${
                      this.rebookData.subject
                    }' subject/lesson.`,
                    () => {
                      this.selectedSubject = "";
                    }
                  );
                }
              }
            }
          }
        })
        .finally(() => {
          vm.isSelectedSubjectDisabled = false;
          vm.isSubjectLoading = false;
          if (vm.$refs["observer"] !== undefined) {
            vm.$refs["observer"].refs.subject.reset();
          }
        });
    },
    onSelectDay() {
      const vm = this;
      const payload = { schedule: vm.teacherData.schedule };
      vm.onCalendarSelect(payload);
      vm.$bvModal.show("calendar-modal");
      vm.setActiveDateFromTz(vm.selectedTimeZone);
    },
    onScheduleSummaryRemove(item, removeItem) {
      const vm = this;
      const scheduleItem = vm.schedules[vm.schedules.indexOf(item)].items;
      const index = scheduleItem.indexOf(removeItem);
      scheduleItem.splice(index, 1);
      if (scheduleItem.length === 0) {
        vm.schedules.splice(vm.schedules.indexOf(item), 1);
      }
      const availabilityItem = vm.xAvailability.filter(
        (x) => x.date_schedule === item.date_schedule
      )[0];
      const availItemArray = [
        availabilityItem.items.Morning,
        availabilityItem.items.Afternoon,
        availabilityItem.items.Evening,
      ];
      availItemArray.forEach((x) => {
        if (x.filter((item) => item === removeItem).length > 0) {
          const index = x.indexOf(removeItem);
          x.splice(index, 1);
        }
      });
    },
    onFileChange(e) {
      let file = e.dataTransfer || e.target;
      file = file.files[0];

      if (file) {
        const validSize = [
          { type: "file", size: "5000" },
          { type: "photo", size: "5000" },
        ];

        const file_ext = file.name.substring(
          file.name.lastIndexOf(".") + 1,
          file.name.length
        );

        let allowedExt = [
          {
            ext: [
              "txt",
              "docx",
              "doc",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "pdf",
              "zip",
              "rar",
            ],
            type: "file",
          },
          { ext: ["jpg", "jpeg", "png"], type: "photo" },
        ];

        const filename = file.name.toLowerCase();
        const ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        const fileType = allowedExt
          .filter((x) => {
            return x.ext.includes(ext);
          })
          .map((x) => x.type)[0];
        const allowedSize = parseInt(
          validSize
            .filter((x) => {
              return x.type === fileType;
            })
            .map((x) => x.size)[0]
        );

        allowedExt = [].concat
          .apply(
            [],
            allowedExt.map((x) => x.ext)
          )
          .join("|");

        const pattern = new RegExp(`(${allowedExt})$`);

        if (file.size / 1024 > allowedSize) {
          this.ShowModal(
            `The ${fileType} that you are trying to upload is too large.<br /><br /><b>Upload Details:</b><br />
              Name: ${file.name}<br />
              Size: ${this.formatBytes(file.size)}<br />
              Type: ${
                file_ext === "rar" ? "application/x-rar-compressed" : file.type
              }`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.originalFileName = "";
            },
            `<strong>${this.convertByteToMegaByte(
              allowedSize
            )}MB Uploading Limit</strong>`
          );
          return;
        } else if (!pattern.test(filename)) {
          this.ShowModal(
            `The file must be a file of type: ${allowedExt.replaceAll(
              "|",
              ", "
            )}.`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.originalFileName = "";
            }
          );
          return;
        }

        const formData = new FormData();
        const params = {
          file: file,
          original_name: file.name,
          type: fileType,
          document_type: "attached_file",
          tutor_id: this.tutorId,
        };

        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/temp-upload`,
          options = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };

        this.isCheckingFileUpload = true;
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 422) {
              this.ShowModal("File not supported.");
              this.attachFiles = [];
              this.attachFileName = "";
              this.originalFileName = "";
            } else {
              this.attachFileName = _data.data.server_name;
              this.originalFileName = file.name;
            }
          })
          .catch(() => {
            this.attachFiles = [];
            this.attachFileName = "";
            this.originalFileName = "";
          })
          .finally(() => {
            this.isCheckingFileUpload = false;
          });
      }
    },
    setRebookData() {
      if (localStorage.getItem("rebookData")) {
        const _data = JSON.parse(localStorage.getItem("rebookData"));
        this.rebookData = _data;
      }
    },
  },
  watch: {
    ...watch,
    selectedGradeLevel: {
      handler: function() {
        const vm = this;
        if (vm.selectedGradeLevel == "") {
          vm.selectedSubject = "";
          vm.subjectOptions = [];
          vm.isSelectedSubjectDisabled = true;
        } else {
          vm.loadSubjects().catch((error) => {
            vm.ShowModal(error);
          });
        }
      },
    },
  },
  mixins: [mixins, review_mixins],
};
</script>

<style scoped>
/* Custom Button Group */
.btn-group-btn-outline {
  background: white;
  border: 0.5px solid var(--light-blue) !important;
  border-radius: var(--border-radius-5);
  color: var(--light-blue);
  font-weight: 600;
  outline: none;
  transition: all 300ms ease-in-out;
}
.btn-group-btn-outline-hover {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
  color: white;
}
.btn-group-btn-outline:active,
.btn-group-btn-outline:focus {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
}
.btn-group-btn-outline:hover {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
}
.btn-secondary.disabled.btn-group-btn-outline.disabled,
.btn-secondary.disabled.btn-group-btn-outline:disabled {
  /* background: white !important; */
  border: 0.5px solid var(--silver) !important;
  border-radius: var(--border-radius-5);
  color: var(--silver);
  font-weight: 600;
  outline: none;
  transition: all 300ms ease-in-out;
}

/* Session request steps */

ol.session-request-steps-list {
  list-style-type: none;
  counter-reset: session-request-steps;
}
ol.session-request-steps-list > li {
  counter-increment: session-request-steps;
  position: relative;
}
ol.session-request-steps-list > li::after {
  height: 100%;
  content: "";
  position: absolute;
  top: 30px;
  left: -26px;
  border-left: 2px solid var(--light-silver);
}

ol.session-request-steps-list > li::before {
  text-align: center;
  content: counter(session-request-steps);
  background: var(--dark-silver);
  color: #fff;
  position: absolute;
  left: -40px;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
}
ol.session-request-steps-list > li.active-session-request-step::before {
  background: var(--light-blue);
}

/* Session Summary CSS */
.schedule-summary-container {
  min-height: 450px;
  max-height: 550px;
  overflow-y: auto;
}

/* Session Summary list Counter */
.list-counter {
  background: var(--light-blue);
  color: #fff;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75;
}

/* Calendar Modal CSS */
.time-slot-btns .btn {
  background: white;
  color: var(--dark-grey);
  border: 0.5px solid var(--dark-grey);
  font-size: 0.6em;
  font-weight: 500;
  padding: 0.5em 0.8em;
  margin-bottom: 0.5em;
}
.time-slot-btns .btn.disabled {
  border: 0.5px solid var(--silver);
}
.time-slot-btns .btn.active {
  background-color: #d9f5fa !important;
  color: var(--light-blue) !important;
  font-weight: 600;
}
.time-slot-btns .btn:hover {
  border: 1px solid var(--light-blue) !important;
  color: var(--light-blue) !important;
}
.add-schedule-btn {
  background: var(--light-blue);
  color: white;
  font-weight: 600;
  width: 80%;
  transition: background 200ms ease-in-out;
  margin: auto;
}
.add-schedule-btn:hover {
  background: var(--hover-light-blue);
  transition: background 200ms ease-in-out;
}
.schedule-summary-accordion-content-container {
  min-height: 350px;
  max-height: 450px;
  overflow-y: auto;
}

.view-schedule-summary-btn {
  position: relative;
  margin: 0.5em 1.5em;
  font-weight: 600;
}

.view-schedule-summary-btn:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}

.view-schedule-summary-btn:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 300ms ease-in-out;
}
.view-schedule-summary-btn[aria-expanded="true"]:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0.5turn);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 300ms ease-in-out;
}
.proceed-btn {
  background: white;
  color: var(--dark-blue);
  font-weight: 600;
}
.proceed-btn:active,
.proceed-btn:focus,
.proceed-btn:hover {
  background: white;
  color: var(--dark-blue);
  font-weight: 600;
}
/*Custom Override */

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  font-size: 0.575rem;
}
.text-cb-color {
  color: var(--light-blue) !important;
}
.sticky-top {
  top: 100px;
}
.c-not-allowed {
  cursor: not-allowed;
}
</style>

<template>
 <div class="vet-header">
            <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-lg-5 col-md-10 col-sm-12">
                        <h3>How we vet</h3>
                        <p class="">Chalkboard is a provider of outstanding online tutors. All of our tutors were thoroughly screened and evaluated by academic professionals to ensure that we give only the best quality of service. Chalkboard tutors are always available to guide you through learning at your preferred style and pace!</p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
  export default {
    name: 'HeaderBanner',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
export default {
    methods: {
        googleTagInit() {
            try{
                if(String(process.env.VUE_APP_GOOGLE_TAG_ID).match("^GTM-[a-zA-Z0-9]+$")){
                    return new Promise(() => {
                        (function(w, d, s, l, i){
                        w[l]= w[l] || [];
                        w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                        var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l :'';
                        j.async = true;
                        j.src = 'https://www.googletagmanager.com/gtm.js?id='+ i + dl;
                        f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', process.env.VUE_APP_GOOGLE_TAG_ID);
                    })
                }else{
                    console.log("[Initialization Error]: GTM was not initialized. Invalid GTM ID.")
                }
            }catch(error){
                console.log("GTM Initialization Error:", error);
            }
        }
    }
}
<template>
  <div class="tutor-header">
            <div class="container h-100">
                <div class="become-a-tutor h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-lg-6 col-sm-12 ">
                            <h3>Tutor your way</h3> 
                            <p>Set your own schedule, set your own rate.<br/>
                                Help learners reach their potential.</p>
                            <a href="/signup" class="cb-primary-link">Apply AS TUTOR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
  export default {
    name: 'HeaderBanner',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
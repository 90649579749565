export default {
  methods: {
    showDynamicModal(message, variant = "primary", title = "System Message") {
      const ce = this.$createElement;

      const titleNodes = ce("div", {
        domProps: { innerHTML: title }
      });

      let messageNodes = null;

      if (Array.isArray(message)) {
        messageNodes = message.map(m =>
          ce("div", { domProps: { innerHTML: m } })
        );
        messageNodes = ce("div", {}, messageNodes);
      } else {
        messageNodes = ce("div", {
          domProps: { innerHTML: message }
        });
      }
      this.$bvModal.msgBoxOk([messageNodes], {
        title: [titleNodes],
        buttonSize: "sm",
        headerTextVariant: variant,
        okVariant: variant,
        headerClass: "pt-2 pb-2 pl-3 pr-3 p-2",
        titleClass: variant == "primary" ? "light-blue" : variant,
        footerClass: "pt-2 pb-2 pl-3 pr-3",
        bodyClass: "pt-2 pb-2 pl-3 pr-3"
      });
    }
  }
};

<template>
  <div :class="computedClass">
    <input
      class="box-radio__btn"
      type="radio"
      :name="name"
      :id="id + appendToId"
      :value="checkedValue"
      @click.stop="$emit('click', $event)"
      v-model="computedValue"
    />
    <label class="box-radio__label" :for="id + appendToId">
      <div class="box-radio__svg">
        <slot name="svg"></slot>
      </div>
      <div class="box-radio__content">
        <div>
          {{ title }}
        </div>
        <p>
          {{ subtitle }}
        </p>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "BoxRadio",

  computed: {
    computedClass() {
      return ["box-radio", this.variant ? "box-radio--" + this.variant : ""];
    },

    isChecked() {
      return this.checkedValue == this.value ? "checked" : null;
    },

    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },

  props: {
    checkedValue: {
      type: [String, Number, Boolean],
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    appendToId: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: "orange",
      validator: value => ["orange", "light-blue"].includes(value)
    }
  }
};
</script>

<style lang="scss" scoped>
.box-radio {
  $block: &;
  margin: 1em 0;

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 4em 1em 1em;
    border-radius: 15px;
    // box-shadow: 0px 0px 1px 1px var(--silver);
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &::after {
      content: "";
      position: absolute;
      right: 2em;
      transform: translateY(-50%);
      display: inline-block;
      border-right: 4px solid var(--grey);
      border-bottom: 4px solid var(--grey);
      width: 10px;
      border-radius: 2px;
      height: 10px;
      transform: rotate(-45deg);
      transition: all 200ms ease-in-out;
    }
  }

  &__svg {
    margin-right: 1em;
  }

  &__content {
    > div {
      font-size: var(--fs-one);
      font-weight: normal;
      color: var(--grey);
    }

    > p {
      font-size: calc(var(--fs-one) - 2px);
      font-weight: normal;
      color: var(--dark-silver);
      margin: 0;
      padding: 0;
    }
  }

  &__btn {
    display: none;
  }

  &--orange {
    #{$block}__label {
      &:hover {
        box-shadow: 0px 0px 1px 1px var(--orange);

        &::after {
          border-right: 4px solid var(--orange);
          border-bottom: 4px solid var(--orange);
        }
      }
    }

    #{$block}__btn:checked + #{$block}__label {
      box-shadow: 0px 0px 1px 1px var(--orange);
    }
  }

  &--light-blue {
    #{$block}__label {
      &:hover {
        box-shadow: 0px 0px 1px 1px var(--light-blue);

        &::after {
          border-right: 4px solid var(--light-blue);
          border-bottom: 4px solid var(--light-blue);
        }
      }
    }

    #{$block}__btn:checked + #{$block}__label {
      box-shadow: 0px 0px 1px 1px var(--light-blue);
    }
  }
}
</style>

<template>
    <div>
        <div class="text-center my-5">
            <b-button class="dark-silver-outline-button mx-1" style="min-width: 250px;" @click="onNextTime">Maybe next time</b-button>
            <b-button class="blue-button font-weight-bold mx-1" style="min-width: 250px;" @click="onSubmit" :disabled="rating == null">Submit</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReviewButtonSection',
    props: ['rating'],
    data: () => ({

    }),
    methods: {
        onSubmit() {
            this.$emit('onSubmit')
        },
        onNextTime() {
            this.$emit('onNextTime')
        }
    }
}
</script>
<template>
  <div class="voucher-dialog">
    <div class="voucher-dialog__header">
      <div class="voucher-dialog__close">
        <b-button size="sm" @click="closeAppModal()"><xmark /></b-button>
      </div>
      <div class="voucher-dialog__img-wrapper-sent">
        <mcdovoucherlimit />
      </div>
      <div class="voucher-dialog__title">
        REGISTER TOMORROW!
      </div>
    </div>
    <div class="voucher-dialog__body">
      We’ve reached our maximum daily sign up limit for today. Visit us again
      tomorrow so you can sign up and claim your free voucher!
    </div>
  </div>
</template>

<script>
import mcdovoucherlimit from "../../assets/imgs/mcdo-voucher-limit.svg?inline";

export default {
  name: "McDoVoucherLimit",

  components: {
    mcdovoucherlimit
  }
};
</script>

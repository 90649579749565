<template>
  <div>
    <b-table striped hover sort-icon-left show-empty empty-text="No pending sessions."
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="isBusy"
      responsive="sm"
    >

     <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>

      <template #cell(name)="data">
        <a class="link-class" @click="showModal(data.item)">{{ data.value }}</a>
      </template>

      <template #cell(total)="data">
        ₱ {{ data.value }}
      </template>
      <template #cell(action)="data">
        <b-button variant="danger" size="sm" class="mr-1" @click="tryRejectBooking(data.item.transaction_id)">Reject</b-button>
        <b-button variant="primary" size="sm" @click="tryAcceptBooking(data.item.transaction_id)">Accept</b-button>
      </template>
      <template #cell(expiry)="data">
        {{ formatExpiry(data.value) }}
      </template>
      <template #cell(message)="data">
        <b-button
          variant="primary"
          size="sm"
          @click="showMessage(data.item)"
        >Message</b-button>
      </template>
    </b-table>
    <b-pagination v-if="items.length > 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="right"
       class="p-3"
    ></b-pagination>

    <b-modal v-model="modalShow" size="xl" title="Booking Schedule" centered scrollable>
      <b-table style="margin-top: -16px;" striped hover show-empty empty-text="no record found." :items="modalItems" responsive="sm"></b-table>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="mt-3 mr-1" variant="info" v-b-modal.modal-multi-2>View Message</b-button>
          <b-button class="mt-3 mr-1" variant="warning" @click="onDownload">Download</b-button>
        </div>
      </template>
      <b-modal id="modal-multi-2" :title="`${selectedLearnerName}'s Message`" hide-footer centered>{{ note }}</b-modal>
    </b-modal>

    <b-modal v-model="msgModalShow" :title="`${selectedLearnerName}'s Message`" centered ok-only>
      {{ noteMessage }}
    </b-modal>
    
  </div>
</template>

<script>

  import commonmixins from '../../../mixins'
  // import bookingmixins from '../../../mixins/booking'


  export default {
    name: 'ScheduleTeacherPendingSessions',
    props: ['isDataTriggerLoad'],
    data() {
      return {
        isBusy: false,
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        perPage: 10,
        currentPage: 1,
        fields: [{ key: 'name', label: 'Learner Name' }, 'grade_level', 'subject', 'session_type', { key: 'total', label: 'Total Price', sortable: true }, 'action', 'expiry', 'message'],
        items: [],
        UserToken: window.localStorage.getItem('userToken'),
        selectedTutorName: '',
        modalShow: false,
        modalItems: [],
        note: '',
        selectedLearnerName: '',
        selectedData: [],
        msgModalShow: false,
        noteMessage: '',
        guestTz: null,
        regionTzName: null
      }
    },
    computed: {},
    beforeMount() {
      if (this.isDataTriggerLoad) {
        this.$emit('triggeredWatchSessions')
      }
    },
    mounted() {
      const vm = this
      vm.$watch('$store.state.userData', (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone
        vm.regionTzName = data.timezone == null ? vm.getTzRegion(vm.guestTz) : data.region_timezone
        vm.$store.dispatch('getTimeSlots', vm.guestTZ)
      }, { immediate: true })
      vm.fetchPendingSessions()
    },
    methods: {
      showModal(data) {
        const transId = data.transaction_id
        const result = this.items.find(x => x.transaction_id == transId)
        this.modalItems = result.bookings.map(item => {
          return {
            transaction_id: item.transaction_id,
            learner_name: data.name,
            session_type: item.session_type,
            grade_level: item.grade_level,
            subject: item.subject,
            request_date: this.moment.utc(item.created_at).tz(this.guestTZ).format('dddd, LL'),
            session_date: this.moment.utc(item.start_date).tz(this.guestTZ).format('dddd, LL'),
            session_time: `${this.moment.tz(item.start_date, 'UTC').tz(this.guestTZ).format('hh:mm A')} (${this.regionTzName})`
          }
        })
        this.modalShow = true;
        this.note = data.note;
        this.selectedLearnerName = data.name;
        this.selectedData = data;
      },
      async fetchPendingSessions() {

        this.isBusy = true;

        const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/tutor-request-list?is_accepted=0&count=' + this.perPage + '&page=' + this.currentPage
        const options = {
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${this.UserToken}`
          }
        }
        
        await this.axios.get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;
          this.items = _data.data;
          this.totalRows = _data.total;
        }).catch((error) => {
          console.log(error.response);
        }).finally(() => {
          this.isBusy = false;
        })

      },
      onDownload() {

        if (this.selectedData.download_file == null) {
          this.ShowModal("No file to download.")
          return
        }

        const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/booking/download-file",
              formData = new FormData(),
              param = { 
                role: this.selectedData.account_type,
                user_id: this.selectedData.user_id,
                file: this.selectedData.download_file 
              }

        Object.keys(param).forEach(key => formData.append(key, param[key]))
        
        this.onDownloadFile(ApiURL, formData, this.selectedData.original_file)

      },
      showMessage(data) {
        this.msgModalShow = true;
        this.noteMessage = data.note;
        this.selectedLearnerName = data.name
      },
      tryAcceptBooking(tran_id){
          
            const paramData = new FormData()
            paramData.append('is_accepted', 0) 
            paramData.append('transaction_id', tran_id) 
  
            const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/tutor-accept'
            const options = {
              headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${this.UserToken}`
              }
            }
  
            this.axios.post(ApiURL, paramData, options).then(data => {
                this.fetchPendingSessions();
                
                if (data.status == 422) {
                    this.ShowModal(data.message, null, 'Booking Cancelation Error'); 
                }
                else{
                  let message = '<div>Thank you for accepting the request. You will receive an email and SMS notification once the sessions are paid.</div>'
                  message += '<br /><div>You can still review this request under the Accepted Requests tab.</div>'
                  this.ShowModal(message, null, "Booking Request")
                }
 
            })  
      },
      tryRejectBooking(tran_id){
           this.showConfirmModal('Are you sure you want to reject this booking?', () => {
                const paramData = new FormData()
                paramData.append('is_accepted', 0)
                paramData.append('transaction_id', tran_id) 
      
                const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/tutor-reject'
                const options = {
                  headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': `Bearer ${this.UserToken}`
                  }
                }
      
                this.axios.post(ApiURL, paramData, options).then(data => {
                    this.fetchPendingSessions();
                    if (data.status == 422) {
                        this.ShowModal(data.message, null, 'Booking Rejection Error'); 
                    }
                    else{   
                        this.ShowModal("You have rejected the selected booking!", null, 'Booking Reject Request');
                    }
     
                })            
    
            })           

      }      
    },
    watch: {
      currentPage: {
        handler: function() {
          this.fetchPendingSessions().catch(error => {
            console.error(error)
          })
        }
      },
      isDataTriggerLoad(val) {
        if (val) {
          this.fetchPendingSessions()
        }
        this.$emit('triggeredWatchSessions')
      }
    },
    mixins: [commonmixins]
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link-class {
  color: #007bff; 
  text-decoration: none; 
  background-color: transparent; 
  cursor: pointer;
}
</style>
<template>
  <div class="review-modal">
    <b-container>
      <b-form>
        <!-- Modal content -->
        <div class="review-modal-content">
          <!-- Modal header -->
          <header class="text-center light-blue-bg" style="border-radius: 10px 10px 0 0;">
            <div>
              <b-avatar :src="imageUrl" style="margin-top:-4rem;" size="8rem"></b-avatar>
            </div>
            <div class="py-4 font-weight-bold text-white font-size-md">
              {{ headerTitle }}
            </div>
          </header>
          
          <RatingSection :rating="rating" @ratingClicked="setNewRatingValue" :tutorName="tutorName" v-if="ratingAndReviewValue && !isShowReport" :isReadOnlyRating="false"/>
          <ReviewSection :feedbackValue.sync="feedback" v-if="(ratingAndReviewValue && rating != null) && !isShowReport"/>
          <ReportSection :rating="rating" v-if="isShowReport"/>
          <RecommendationSection @changed="onChangeRecommendation" v-if="(ratingAndReviewValue && rating != null) && rating <= 3 && !isShowReport"/>
          <ReviewButtonSection :rating="rating"  @onSubmit="onSubmit" @onNextTime="onNextTime" v-if="ratingAndReviewValue && !isShowReport"/>
          <FeedbackSection :rating="rating" :tutorName="tutorName" :isRedirected="isShowFeedback" v-if="isShowFeedback"/>

        </div>
       
      </b-form>
      
    </b-container>
  </div>
</template>

<script>
import RatingSection from '../review/RatingSection.vue'
import ReviewSection from '../review/ReviewSection.vue'
import RecommendationSection from '../review/RecommendationSection.vue'
import ReviewButtonSection from '../review/ReviewButtonSection.vue'
import FeedbackSection from '../review/FeedbackSection.vue'
import ReportSection from './ReportSection.vue'
export default {
  components: {
    RatingSection,
    ReviewSection,
    RecommendationSection,
    ReviewButtonSection,
    FeedbackSection,
    ReportSection
  },
  name: "ReviewSession",
  props: ['isRatingAndReview', 'isShowReport'],
  data: () => ({
    headerTitle: '',
    tutorName: 'Angela C.',
    ratingOptions: [
      "",
      "The session was unsatisfactory",
      "The session could be improved",
      "The session was okay",
      "The session was very good",
      "The session was excellent",
    ],
    rating: 3,
    feedback: '',
    recommendation: '',
    imageUrl: '',
    isShowFeedback: false,
    ratingAndReviewValue: false
  }),
  mounted() {
    if (this.isRatingAndReview) {
      this.headerTitle = `How was experience your experience with Tutor ${this.tutorName}`
    } else if (this.isShowReport) {
      this.headerTitle = `Rated by `
    } else if (this.isShowFeedback) {
      this.headerTitle = `Thank you for your feedback`
    }
    this.ratingAndReviewValue = this.isRatingAndReview
    this.imageUrl = 'https://bulma.io/images/placeholders/128x128.png'
  },
  computed: {
    feedbackCharactersRemaining : {
      get() {
        const newLimit = this.characterLimit - this.feedback.length
        return ((newLimit) < 0) ? 0 : newLimit;
      },
      set(value) {
        return value
      }
    }
  },
  methods: {
    onChangeRecommendation(value) {
      this.recommendation = value
    },
    onSubmit() {
      this.isShowFeedback = true
      this.headerTitle = 'Thank you for your feedback'
      this.ratingAndReviewValue = !this.isShowFeedback
    },
    onNextTime() {
      window.location.href = '/dashboard'
    },
    setNewRatingValue(value) {
      this.rating = value
    }
  }
}
</script>


<style scope>
.b-rating-star-empty .text-warning {
  color: var(--dark-silver) !important;
}

.review-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.review-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}
</style>
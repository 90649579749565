<template>
  <div>
    <div class="screening-process">
      <div class="container">
        <div class="process">
          <div class="process-header">
            <h4>Our Tutor Screening Process</h4>
            <p>All of our tutors went through the following steps before they were accepted as one of our instructors.</p>
          </div>
          <div class="process-body">
            <div class="steps">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-3 col-sm-12 head">
                  <h1>1</h1>
                </div>
                <div class="col-lg-10 col-md-9 col-sm-12 body">
                  <h6>Screening of Application</h6>
                  <p>Chalkboard has a 10% acceptance rate for applicant tutors. We have exceptionally high requirements for vetting to ensure that only the most adept, proficient, and skillful tutors are accepted.</p>
                </div>
              </div>
            </div>
            <div class="steps">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-3 col-sm-12 head">
                  <h1>2</h1>
                </div>
                <div class="col-lg-10 col-md-9 col-sm-12 body">
                  <h6>Interview</h6>
                  <p>Our tutors went through one-on-one interviews. The screening team consists of members from the academe, college professors, and educational institution managers.</p>
                </div>
              </div>
            </div>
            <div class="steps">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-3 col-sm-12 head">
                  <h1>3</h1>
                </div>
                <div class="col-lg-10 col-md-9 col-sm-12 body">
                  <h6>10-minute live teaching demonstration</h6>
                  <p>During the interview, tutors were asked to prepare for a 10-minute live teaching demonstration wherein they were evaluated for their teaching style, mastery, classroom etiquette, behavior, and engagement.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="expert-tutors">
      <div class="container-fluid">
        <div class="tutors">
          <h5 class="heading">Meet one of our expert tutors</h5>
          <p>We want you to be comfortable with your tutors and your sessions online.</p>
          <h5 class="sub-heading">Take a look at Tutor Danica's lesson and decide for yourself</h5>
          <div class="tutor-video">
            <video class="video" controls>
              <source  :src="require('@/assets/imgs/how-we-vet/how-we-vet.mp4')"  type="video/mp4">
              <source src="" type="video/ogg">
              Your browser does not support HTML video.
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="vet-experience">
      <div class="container-fluid">
        <div class="experience">
          <h5>Connect with Our Tutors</h5>
          <p class="pb-4 px-5">Your best choices are with us. As our tutors were carefully screened and evaluated, we assure parents and learners that all of our tutors are high caliber.</p>
          <b-link to="/search" class="cb-book-now-link">Book Now</b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreeningProcess",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
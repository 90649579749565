export default {
    data: () => ({
        UserToken: window.localStorage.getItem("userToken")
    }),    
    methods: {
        async getDragonpayChannels(channel_type, channel_status){
            const paramData = new FormData()
            paramData.append('channel_type', channel_type) 
            paramData.append('channel_status', channel_status) 
  
            const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/maintenance/get-dragonpay-channels'
            const options = {
              headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${this.UserToken}`
              }
            }
  
            await this.axios.post(ApiURL, paramData, options).then(() => {})

        },
        

    }

}
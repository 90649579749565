export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        openAppModal(
          contentComponent,
          contentComponentBinds,
          modalSettings,
          contentComponentEvents
        ) {
          this.$store.dispatch("openAppModal", {
            contentComponent,
            contentComponentBinds,
            modalSettings,
            contentComponentEvents
          });
        },

        closeAppModal(id) {
          this.$store.dispatch("closeAppModal", id);
        }
      }
    });
  }
};

<template>
  <div>
    <!-- {{this.$options.name}} works! -->
  </div>
</template>

<script>
  import mixins from "../../../mixins";

  export default {
    name: 'EmailVerificationRedirect',
    data() {
      return {}
    },
    computed: {},
    mounted(){
      
      this.verifyEmail();

    },    
    methods: {
      verifyEmail(){
        let emailToken = this.$route.params.token;
          const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/authenticate/verifyEmail";
          const options = {
            headers: {
                'Accept': 'application/json, text/plain, */*'
            }
          }      

          const multiData = new FormData()
          multiData.append('id',emailToken)


          this.axios.post(ApiURL, multiData, options) 
              .then(data => { 
                  this.ShowModal(`Your email ${data.data.data.email} has been verified. You will be redirected automatically.`, () => {
                    this.$router.push('/login')
                  })                  
              })
              .catch(err => console.error(err))
      },  

    },
    
    mixins:[mixins]
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
    <div>
        
    <b-form-group :label="item.question" v-for="(item, index) in questionnaire" :key="index" label-for="remarks" size="sm" class="pb-4">
        <ValidationProvider :name="item.name" :rules="generateRules(item.rules)" v-slot="validationContext">
            <b-form-textarea
                v-model="item['answer']" 
                :placeholder="item.placeholder" 
                @input="onRemainingCharInput(index)"
                :state="getValidationState(validationContext)" 
                rows="4" 
                size="sm" 
                no-resize
                :maxlength="maxLength"
            ></b-form-textarea>
            <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            <b-form-text id="password-help-block">
                {{ getRemainingChar(item) }} <span>{{`character${ getRemainingChar(item) > 1 ? 's': ''} remaining`}}</span>
            </b-form-text>
        </ValidationProvider>
    </b-form-group>

    </div>
</template>

<script>
import mixins from '../../mixins'
export default {
    props: {
        questionnaire: Array,
        maxLength: Number
    },
    data: () => ({}),
    methods: {
        getRemainingChar(item) {
            return this.maxLength - item['answer'].length
        },
        onRemainingCharInput(index) {
            this.questionnaire[index].characterLimit = this.maxLength - this.questionnaire[index].answer.length
        }
    },
    mixins: [mixins]
}
</script>
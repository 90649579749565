<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form id="review-section" @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-container>
            <div class="text-center mt-4">
                <b-form-rating
                    class="font-size-xl"
                    v-model="rating"
                    icon-empty="star-fill"
                    variant="warning" no-border inline
                    @change="onRatingClick"
                ></b-form-rating>
            </div>
            <div class="text-center mb-4 font-size-md">
                <p class="mt-2 font-weight-bold" v-if="rating == null"> Rate {{ tutorName }}</p>
                <p class="mt-2 font-weight-bold font-size-lg pb-3"> {{ ratingOptions[rating] }} </p>
            </div>
            <div class="px-5" v-if="rating <= 3 && rating != null">
                <ValidationProvider name="recommendation" rules="required" v-slot="validationContext">
                    <b-form-group label="What could have been done better? *" label-for="feedback" size="sm">
                        <b-form-select
                            id="recommendation"
                            v-model="recommendation"
                            size="sm"
                            :state="getValidationState(validationContext)"
                            @change="onRecommendationChange"
                        >
                            <b-form-select-option :value="''">-- please select an option --</b-form-select-option>
                            <b-form-select-option v-for="(rec, index) in recommendationOptions" :key="index" :value="rec.code">{{ rec.recommendation }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <!-- <div class="py-2" v-if="recommendation == 'OTH'">
                    <b-form-group label="Others:" label-for="remarks" size="sm">
                        <b-form-textarea
                            id="remarks" 
                            v-model="remarks" 
                            placeholder="Please state other feedback here." 
                            rows="8" 
                            size="sm" 
                            no-resize aria-describedby="password-help-block"
                        ></b-form-textarea>
                        <b-form-invalid-feedback>
                            Type at least 20 characters.
                        </b-form-invalid-feedback>
                        <b-form-text id="password-help-block" style="float:right;">
                            {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                        </b-form-text>
                    </b-form-group>
                </div> -->
            </div>
            <div class="px-5" v-if="rating != null">
                <div class="py-2">
                    <b-form-group label="Tell us more about it:" label-for="feedback" size="sm">
                    <b-form-textarea
                        id="feedback" 
                        v-model="feedback" 
                        :state="feedbackState"
                        rows="8" size="sm"
                        v-on:keyup="onKeyUp"
                        placeholder="Please write your review to your tutor in this section. Tell us what happened but please leave out offensive words. We appreciate your honest and constructive feedback." 
                        no-resize aria-describedby="password-help-block">
                    </b-form-textarea>
                    <b-form-invalid-feedback :state="feedbackState">
                        Type at least 20 characters
                    </b-form-invalid-feedback>
                    <b-form-text id="password-help-block" style="float:right;">
                        {{ feedbackCharactersRemaining }} <span v-if="feedbackCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                    </b-form-text>
                    </b-form-group>
                </div>
            </div>
            <div class="text-center my-5">
                <b-button class="dark-silver-outline-button mx-1" style="min-width: 250px;" @click="onNextTime">{{ firstButtonLabel }}</b-button>
                <b-button type="submit" class="blue-button font-weight-bold mx-1" style="min-width: 250px;" :disabled="rating == null">{{ secondButtonLabel }}</b-button>
            </div>
        </b-container>
        </b-form>
        </validation-observer>
    </div>
</template>

<script>
import mixins from '../../mixins'
export default {
    props: ['ratingsAndReviewsData', 'request', 'mode'],
    name: 'ReviewSection',
    data: () => ({
        rating: null,
        feedback: '',
        ratingOptions: [
            "",
            "The session was unsatisfactory",
            "The session could be improved",
            "The session was okay",
            "The session was very good",
            "The session was excellent",
        ],
        tutorName: '',
        recommendation: '',
        remarks: '',
        characterLimit: 500,
        feedbackState: null,
        firstButtonLabel: 'Maybe next time',
        secondButtonLabel: 'Submit'
    }),
    mounted() {
        if (this.ratingsAndReviewsData) {
            const item = this.ratingsAndReviewsData
            this.tutorName = item.tutor_name
            this.rating = item.rating
            this.feedback = (typeof(item.feedback) !== 'undefined' && item.feedback !== null) ? item.feedback : ''
            this.recommendation = (typeof(item.recommendation) !== 'undefined' && item.recommendation !== null) ? item.recommendation : ''
            this.remarks = (typeof(item.remarks) !== 'undefined' && item.remarks !== null) ? item.remarks : ''
        }
        if (this.request == 'session-history' && this.mode == 'update') {
            this.firstButtonLabel = 'Cancel'
            this.secondButtonLabel = 'Update Review'
        }
        this.$nextTick(() => {
            document.getElementById('feedback').setAttribute("maxlength", this.characterLimit)
        })
    },
    computed: {
        recommendationOptions () {
            return this.$store.state.reviewRecommendations
        },
        feedbackCharactersRemaining : {
            get() {
                const newLimit = this.characterLimit - this.feedback.length
                return ((newLimit) < 0) ? 0 : newLimit;
            },
            set(value) {
                return value
            }
        },
        othersCharactersRemaining : {
            get() {
                const newLimit = this.characterLimit - this.remarks.length
                return ((newLimit) < 0) ? 0 : newLimit;
            },
            set(value) {
                return value
            }
        }
    },
    methods: {
        onKeyUp() {
            if (!this.feedbackState && this.feedback.length >= 20) {
                this.feedbackState = null
            }
        },
        onNextTime() {
            this.$emit('onNextTime')
        },
        onSubmit () {
            if (this.rating <= 3 && this.recommendation === 'OTH' && this.feedback.length < 20) {
                this.feedbackState = false
                return
            }
            const payload = {
                rating: this.rating,
                tutorName: this.tutorName,
                isRedirected: true,
                review: this.feedback,
                recommendation: this.recommendation,
                remarks: this.remarks
            }
            this.$emit('onSubmit', payload)
        },
        onRatingClick() {
            if (this.rating != null) {
                this.$nextTick(() => {
                    document.getElementById('feedback').setAttribute("maxlength", this.characterLimit)
                })
            }
        },
        onRecommendationChange(value) {
            if( value != 'OTH') {
                this.feedbackState = null
            }
        }
    },
    watch: {
        feedback : function(val){
            this.$emit('update:feedbackValue', val)
        },
        rating: function (val) {
            if (val > 3) {
                this.feedbackState = null
            }
        }
    },
    mixins: [mixins]
}
</script>
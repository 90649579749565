<template>
  <div style="padding: 10px;">
    <b-row>
      <b-col sm="12" lg="7">
        <b-card no-body>
          <b-card-header class="p-1 light-blue-bg">

          </b-card-header>

          <b-card-body>
            <!-- Row to add children -->
            <b-row no-gutters align-v="center">
              <b-col lg="2" md="2" sm="12" v-for="(item, index) in childrens" :key="index" style="cursor: pointer; text-align: center;" @click="onChildrenClick(index)">
                <div class="p-3" v-bind:class="{ activeClass: (selectedChildIndex == index) ? isChildActive: ''}">
                  <b-img-lazy :id="`img-child${index}`" v-bind="avatarProps" rounded="circle" :src="item.filepath" alt=""></b-img-lazy>
                  <div class="pt-1 font-size-sm"><b>{{ item.firstname }}</b></div>
                </div>
              </b-col>
              <b-col lg="2" md="2" sm="12">
                <div id="btn-add-child" @click="onAddChild" class="p-1" v-if="enableEditButton">
                  <div style="border: 3px dotted  var(--light-blue);border-radius: var(--border-radius-5);cursor:pointer;" class="d-flex flex-column align-items-center bg-white p-3 light-blue">
                    <b-icon-plus font-scale="2"></b-icon-plus>
                    <small>Add Child</small>
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr>

            <div class="py-2">
              <!-- Profile Image -->
              <div class="text-center">
                <b-img-lazy id="main-img-child" v-bind="mainProps" rounded="circle" :src="imageUrl" alt="No Image"></b-img-lazy>
                <div class="py-2 text-center"><strong>{{ form.firstname }}</strong></div>
              </div>

              <!-- Show when adding new child -->
              <div v-if="isNewChild" class="d-flex align-items-center justify-content-center mt-1 mb-4">
                <div cols="0.5" v-for="(item, index) in avatars" :key="index" @click="onAvatarClick(item.filepath, item.file)">
                  <div>
                    <b-img-lazy :id="`img-child-avatar${index}`" v-bind="avatarProps" rounded="circle" :src="item.filepath" alt="No Image"></b-img-lazy>
                  </div>
                </div>
              </div>

              <validation-observer ref="observer" v-slot="{ handleSubmit }">

                <b-form id="parent-children-form" @submit.prevent="handleSubmit(onSubmit)">
                  <b-row>
                    <b-col>
                      <b-form-group id="input-group-1" label="First Name" label-for="firstname">
                        <ValidationProvider name="first name" rules="required" v-slot="validationContext">
                          <b-form-input id="firstname" v-model="form.firstname" type="text" required placeholder="First Name" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col>

                      <b-form-group id="input-group-1" label="Last Name" label-for="firstname">
                        <ValidationProvider name="last name" rules="required" v-slot="validationContext">
                          <b-form-input id="lastname" v-model="form.lastname" type="text" required placeholder="Last Name" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="4">
                      <b-form-group id="input-group-1" label="Nickname" label-for="firstname">
                        <ValidationProvider name="nickname" rules="required" v-slot="validationContext">
                          <b-form-input required id="nickname" v-model="form.nickname" type="text" placeholder="Nickname" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="4">
                      <b-form-group id="input-group-1" label="Pronouns" label-for="pronouns">
                        <ValidationProvider name="pronouns" v-slot="validationContext">
                          <b-form-input id="pronouns" v-model="form.pronouns" type="text" placeholder="E.g. he/she/they etc." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="4">
                      <b-form-group id="input-group-2" label="Birthday" label-for="birthday">
                        <ValidationProvider name="birthday" rules="required|date_not_today" v-slot="validationContext">
                          <b-form-input id="birthday" v-model="form.birthday" type="date" required size="sm" name="birthday" placeholder="Birthday" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col  sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Grade Level" label-for="gradeLevel">
                        <ValidationProvider name="grade level" rules="required" v-slot="validationContext">
                          <b-form-select v-model="selectedGradeLevel" id="gradeLevel" :options="gradeLevelOptions" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled"></b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col  sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Hobbies and Interests" label-for="hobbies_and_interests">
                        <ValidationProvider name="hobbies and interests" v-slot="validationContext">
                          <b-form-select v-model="form.hobbies_and_interests" id="hobbies_and_interests" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                            <b-form-select-option value="" disabled>-- select hobbies and interests --</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in hobbiesAndInterestsOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Learner Email" label-for="email">
                        <ValidationProvider name="email" v-slot="validationContext">
                          <b-form-input id="email" v-model="form.email" type="text" size="sm" name="email" placeholder="Email" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Phone Number" label-for="phone_number">
                        <ValidationProvider name="phone number" :rules="`validatePhoneNumber:${JSON.stringify(eventPNIData)}`" v-slot="validationContext">
                          <PhoneNumberInput
                            size="sm"
                            v-model="form.phone_number"
                            :disabled="formDisabled"
                            @update="onPNIUpdate($event)"
                            :default-country-code="`${form.country_code}`"
                            :error="validationContext.errors[0] ? true : false"
                          />
                          <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group id="input-group-1" label="Any other information you would like the tutor to know to help support your child?" label-for="remarks">
                        <ValidationProvider name="remarks" v-slot="validationContext">
                          <b-form-textarea id="remarks" v-model="form.remarks" placeholder="" size="sm" rows="3" max-rows="6" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-textarea>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="d-flex justify-content-end">

                    <b-button id="btn-enable" type="button" variant="primary" v-if="enableEditButton" :disabled="childrens.length == 0" v-on:click.prevent="enableEditButton = false; formDisabled = false;">
                      <span>Edit Profile</span>
                    </b-button>

                    <b-button id="btn-cancel" type="button" variant="primary" v-if="!enableEditButton" :disabled="isLoading" v-on:click.prevent="onCancel" class="mr-1">
                      <span>Cancel</span>
                    </b-button>

                    <b-button id="btn-submit" type="submit" variant="primary" v-if="!enableEditButton" :disabled="isLoading">
                      <b-spinner small v-if="isLoading"></b-spinner>
                      <span v-if="isLoading"> saving data...</span>
                      <span v-if="!isLoading">Save</span>
                    </b-button>
                  </div>
                </b-form>

              </validation-observer>
            </div>

          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <!-- Today Session -->
        <ParentTodaySessions :layout="1" />
        <!-- <b-card no-body>
          <b-card-header class="p-1 orange-bg">

          </b-card-header>
          <b-card-body class="text-center">
            <div class="orange font-size-lg"><strong>Today's Session</strong></div>

            <hr>
            <b-row>
              <b-col> -->
        <!-- Learner Profile Image -->
        <!-- <b-avatar src="https://placekitten.com/300/300" size="4rem"></b-avatar>

                <div class="py-3 font-size-sm"> -->
        <!-- Tutor Name -->
        <!-- <div class="d-flex align-items-center justify-content-between">
                    <div class="grey">Tutor Name</div>
                    <div><strong>Tutor Dog</strong></div>
                  </div> -->
        <!-- Time -->
        <!-- <div class="d-flex align-items-center justify-content-between">
                    <div>Time</div>
                    <div><strong>1:00AM</strong></div>
                  </div> -->
        <!-- Subject -->
        <!-- <div class="d-flex align-items-center justify-content-between">
                    <div>Subject</div>
                    <div><strong>Math</strong></div>
                  </div>
                </div>

                <b-button class="orange-bg white w-100">Launch Session</b-button>
              </b-col>

            </b-row>

          </b-card-body>
        </b-card> -->

        <!-- Upcoming Session -->
        <b-card no-body class="mt-3">
          <b-card-header class="p-1 teal-bg">

          </b-card-header>
          <b-card-body class="text-center">
            <div class="teal font-size-lg"><strong>Upcoming Sessions</strong></div>

            <hr>
            <div v-if="ucsItems.length == 0">No upcoming class yet.</div>
            <b-row v-for="(item, index) in ucsItems" :key="index">
              <b-col>
                <div class="py-3">
                  <!-- Tutor Name -->
                  <div class="d-flex align-items-center justify-content-between font-size-sm">
                    <div class="grey">Tutor Name</div>
                    <div><strong>{{ item.tutor_name }}</strong></div>
                  </div>
                  <!-- Time -->
                  <div class="d-flex align-items-center justify-content-between font-size-sm">
                    <div class="grey">Schedule</div>
                    <div><strong>{{ `${moment.utc(item.start_date).tz(guestTZ).format('LLLL')} (${regionTzName})` }}</strong></div>
                  </div>
                  <!-- Subject -->
                  <div class="d-flex align-items-center justify-content-between font-size-sm">
                    <div>Subject</div>
                    <div><strong>{{ item.subject }}</strong></div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-pagination v-if="ucsItems.length > 0" v-model="ucsCurrentPage" :total-rows="ucsTotalRows" :per-page="ucsPerPage" align="center" class="p-3"></b-pagination>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import mixins from "../mixins";
import { mapState } from "vuex";
import ParentTodaySessions from "../components/schedule/parent/ScheduleParentTodaySessionsOld.vue";
import PhoneNumberInput from "../utility/forms/PhoneNumberInput.vue";

export default {
  components: { ParentTodaySessions, PhoneNumberInput },
  name: "ParentChildren",
  metaInfo: {
    title: "Chalkboard - Dashboard My Children",
  },
  data: () => ({
    mainProps: {
      blank: true,
      blankColor: "#777",
      width: 128,
      height: 128,
      class: "m1",
    },
    imageUrl: require("@/assets/placeholder/profile-placeholder.png"),
    avatarProps: {
      blank: true,
      blankColor: "#777",
      width: 48,
      height: 48,
      class: "m1",
    },
    imageFileName: "",
    avatars: [],
    isNewChild: false,
    childrens: [],
    form: {
      childId: null,
      firstname: "",
      lastname: "",
      nickname: "",
      birthday: "",
      pronouns: "",
      hobbies_and_interests: "",
      email: "",
      phone_number: "",
      remarks: "",
      country_code: "",
      formattedPhoneNumber: "",
      area_code: ""
    },
    gradeLevelOptions: [
      { value: "", text: "-- select grade level --", disabled: true },
      { value: "13", text: "Preschool" },
      { value: "1", text: "Grade 1" },
      { value: "2", text: "Grade 2" },
      { value: "3", text: "Grade 3" },
      { value: "4", text: "Grade 4" },
      { value: "5", text: "Grade 5" },
      { value: "6", text: "Grade 6" },
      { value: "7", text: "Grade 7" },
      { value: "8", text: "Grade 8" },
      { value: "9", text: "Grade 9" },
      { value: "10", text: "Grade 10" },
      { value: "11", text: "Grade 11" },
      { value: "12", text: "Grade 12" },
      { value: "14", text: "Tech" },
      { value: "15", text: "Music" },
      { value: "16", text: "Languages" },
      { value: "17", text: "Art" },
      { value: "18", text: "Others" },
    ],
    selectedGradeLevel: [],
    isLoading: false,
    imageHLink: require("@/assets/placeholder/profile-placeholder.png"),
    enableEditButton: true,
    formDisabled: true,
    ucsTotalRows: 0,
    ucsPerPage: 3,
    ucsCurrentPage: 1,
    ucsItems: [],
    selectedChildIndex: 0,
    isChildActive: true,
    guestTZ: null,
    regionTzName: null,
    eventPNIData: {}
  }),
  computed: {
    ...mapState(["hobbiesAndInterestsData"]),
    hobbiesAndInterestsOptions() {
      return this.hobbiesAndInterestsData;
    },
  },
  beforeMount() {
    this.$store.dispatch("getHobbiesAndInterests");
  },
  mounted() {
    const vm = this;
    vm.$watch(
      "$store.state.userData",
      (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
        vm.regionTzName =
          data.timezone == null
            ? vm.getTzRegion(vm.guestTz)
            : data.region_timezone;
      },
      { immediate: true }
    );
    vm.loadAvatars();
    vm.loadChildren();
  },
  methods: {
    onPNIUpdate(e) {
      this.form.country_code = e.countryCode;
      this.form.formattedPhoneNumber = e.formattedNumber
      this.form.area_code = e.countryCallingCode
      this.eventPNIData = e
    },
    onSubmit() {
      if (this.imageFileName == "") {
        this.ShowModal("Please select an avatar.");
        return;
      }

      const formData = new FormData();

      const params = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        nickname: this.form.nickname,
        birthday: this.form.birthday,
        level: this.selectedGradeLevel,
        avatar: this.imageFileName,
        pronouns: this.form.pronouns || "",
        hobbies_and_interests: this.form.hobbies_and_interests || "",
        email: this.form.email || "",
        phone_number: this.form.formattedPhoneNumber || "",
        remarks: this.form.remarks || "",
        country_code: this.form.country_code || "",
        area_code: this.form.area_code || ''
      };

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      if (!this.isNewChild) {
        formData.append("child_id", this.form.childId);
      }

      this.isLoading = true
      const ApiURL = this.isNewChild
        ? process.env.VUE_APP_BACKEND_URL + "/api/web/user/add-child"
        : process.env.VUE_APP_BACKEND_URL + "/api/web/user/update-child";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
        mode: "cors",
      };

      this.axios
        .post(ApiURL, formData, options)
        .then((data) => {
          const _data = data.data;
          if (_data.status == 422) {
            this.ShowModal("Operation Failure.");
            console.log(_data.errors);
          } else {
            this.ShowModal(
              "Child successfully " +
                (this.isNewChild ? "added" : "updated") +
                "."
            );
            this.loadChildren();
          }
        })
        .catch((error) =>
          console.log("fn method hook => onSubmit(): " + error.response)
        ).finally(() => {
          this.isLoading = false 
        })

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onAddChild() {
      if (this.childrens.length >= 5) {
        this.ShowModal("You are only allowed to register up to 5 children.");
        return;
      }
      this.isNewChild = true;
      this.form.childId = null;
      this.form.firstname = "";
      this.form.lastname = "";
      this.form.nickname = "";
      this.form.birthday = "";
      this.selectedGradeLevel = "";
      this.imageFileName = "";
      this.formDisabled = false;
      this.enableEditButton = false;
      this.imageUrl = require("@/assets/placeholder/profile-placeholder.png");
      this.form.pronouns = "";
      this.form.email = "";
      this.form.phone_number = "";
      this.form.remarks = "";
      this.form.hobbies_and_interests = "";
      this.form.country_code = "";
      this.form.area_code = "";

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onChildrenClick(index) {
      this.selectedChildIndex = index;
      const result = this.childrens[index];
      this.imageUrl = result.filepath;

      this.form.childId = result.id;
      this.form.firstname = result.firstname;
      this.form.lastname = result.lastname;
      this.form.nickname = result.nickname;
      this.form.birthday = result.birthday;

      this.selectedGradeLevel = result.level;
      this.imageFileName = result.avatar;

      this.form.pronouns = result.pronouns;
      this.form.email = result.email;
      this.form.phone_number = result.phone_number;
      this.form.remarks = result.remarks;
      this.form.hobbies_and_interests = result.hobbies_and_interests;
      this.form.country_code = result.country_code;
      this.form.area_code = result.area_code

      document.getElementById("birthday").type = "date";

      this.isNewChild = false;
      this.fetchUpcomingSessions();
    },
    onAvatarClick(filepath, filename) {
      this.imageUrl = filepath;
      this.imageFileName = filename;
    },
    loadChildren() {
      const childApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/child-list";
      const childOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(childApiURL, childOptions)
        .then((data) => {
          const _data = data.data.data.data;
          this.childrens = _data;
          this.onChildrenClick(this.selectedChildIndex);
        })
        .catch((error) =>
          console.log("fn method hook => loadChildren(): " + error.response)
        );

      this.enableEditButton = true;
      this.formDisabled = true;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    loadAvatars() {
      const avatarApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/avatar-list";
      const avatarOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(avatarApiURL, avatarOptions)
        .then((data) => {
          const _data = data.data.data;
          this.avatars = _data;
        })
        .catch((error) => {
          console.log("fn method hook => loadAvatars(): " + error.response);
        });
    },
    fetchUpcomingSessions() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/booking/upcoming-session-user?child_id=" +
        this.form.childId +
        "&count=" +
        this.ucsPerPage +
        "&page=" +
        this.ucsCurrentPage;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;
          this.ucsItems = _data.data;
          this.ucsTotalRows = _data.total;
        })
        .catch((error) => {
          console.log(
            "fn method hook => fetchUpcomingSessions(): " + error.response
          );
        });
    },
    onCancel() {
      this.imageUrl = this.imagePlaceholder;
      this.isNewChild = false;
      this.enableEditButton = true;
      this.formDisabled = true;
      this.loadChildren();
    },
  },
  watch: {
    ucsCurrentPage: {
      handler: function () {
        this.fetchUpcomingSessions().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.activeClass {
  background: #e1f9fe;
  border-radius: 5px;
}
#btn-enable.btn.btn-primary.disabled,
#btn-enable.btn.btn-primary:disabled {
  background-color: var(--dark-silver);
  border-color: var(--dark-silver);
}
</style>
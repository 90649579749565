import Vue from 'vue';

export const state = Vue.observable({
  selectedTimeZone: '',
  timeZoneOptions: [],
  calendar: '',
  minDate: null,
  maxDate: null,
  xAvailability: [],
  availableSchedule: [],
  schedules: [],
  scheduleSummary: [],
  timeSlots: [],
  notAvailableSchedule: [],
  maxBookingDays: 180,
  dateFormat: 'YYYY-MM-DD HH:mm:ss',
  tutorId: null,
  isTimeSlotsLoading: false,
  selectedLearner: '',
  xTimeSlots: []
})

export const getters = {
  calendar: {
    get() { return state.calendar },
    set(val) { state.calendar = val }
  },
  minDate: {
    get() { return state.minDate },
    set(val) { state.minDate = val }
  },
  maxDate: {
    get() { return state.maxDate },
    set(val) { state.maxDate = val }
  },
  selectedTimeZone: {
    get() { return state.selectedTimeZone },
    set(val) { state.selectedTimeZone = val }
  },
  timeZoneOptions: {
    get() { return state.timeZoneOptions },
    set(val) { state.timeZoneOptions = val }
  },
  xAvailability: {
    get() { return state.xAvailability },
    set(val) { state.xAvailability = val }
  },
  availableSchedule: {
    get() { return state.availableSchedule },
    set(val) { state.availableSchedule = val }
  },
  schedules: {
    get() { return state.schedules },
    set(val) { state.schedules = val }
  },
  scheduleSummary: {
    get() { return state.scheduleSummary },
    set(val) { state.scheduleSummary = val }
  },
  timeSlots: {
    get() { return state.timeSlots },
    set(val) { state.timeSlots = val }
  },
  notAvailableSchedule: {
    get() { return state.notAvailableSchedule },
    set(val) { state.notAvailableSchedule = val }
  },
  maxBookingDays: {
    get() { return state.maxBookingDays },
    set(val) { state.maxBookingDays = val }
  },
  tutorId: {
    get() { return state.tutorId },
    set(val) { state.tutorId = val }
  },
  isTimeSlotsLoading: {
    get() { return state.isTimeSlotsLoading },
    set(val) { state.isTimeSlotsLoading = val }
  },
  selectedLearner: {
    get() { return state.selectedLearner },
    set(val) { state.selectedLearner = val }
  },
  xTimeSlots: {
    get() { return state.xTimeSlots },
    set(val) { state.xTimeSlots = val }
  },
}

export const methods = {
  async _getTimeSlots() {
    const ts = [];
    for (var i = 0; i < 24; i++) {
      i = i < 10 ? `0${i}` : i
      const time = `${i}:00:00`
      ts.push({
        start_time: time,
        day_part: this.getDayPart(time)
      })
    }
    const xSelectedDate = state.calendar
    const ts_response = []
    ts.forEach(x => {
      const dt = this.moment(`${xSelectedDate} ${x.start_time}`)
      const utc_dt = this.moment.tz(`${xSelectedDate} ${x.start_time}`, state.selectedTimeZone).tz('UTC')
      ts_response.push({
        value: `${x.start_time}-${this.moment(dt).add(1, 'hours').format('HH:mm:ss')}`,
        day_part: x.day_part,
        text: `${this.moment(dt).format('hh:mm A')} - ${this.moment(dt).add(1, 'hours').format('hh:mm A')}`,
        utc_time: `${this.moment(utc_dt).format('HH:mm:ss')}-${this.moment(utc_dt).add(1, 'hours').format('HH:mm:ss')}`
      })
    })
    const response = await this.$store.dispatch('getTimeSlots')
    return ts_response.filter(t => { return response.data.data.find(_ => _.start_time === t.utc_time.split('-')[0]) })
  },
  async loadTimeSlots() {
    const vm = this
    state.isTimeSlotsLoading = true;
    const timeSlots = await vm.$store.dispatch("getTimeSlots", state.selectedTimeZone)
    const _data = timeSlots.data.data.map(x => {
      const convertedDate = this.moment(this.convertTz(this.moment(state.calendar).format('YYYY-MM-DD'), x.start_time, 'UTC', this.selectedTimeZone))
      return {
        start_time: convertedDate.format('HH:mm:ss'),
        end_time: this.moment(convertedDate).add(1, 'hours').format('HH:mm:ss'),
        text: `${convertedDate.format("hh:mm A")} - ${this.moment(convertedDate).add(1, 'hours').format("hh:mm A")}`,
        value: `${x.start_time}-${x.end_time}`,
        day_part: vm.getDayPart(convertedDate.format("HH:mm:ss")),
        sortH: parseInt(convertedDate.format("H")),
      }
    }).sort((a, b) => a.sortH - b.sortH);

    state.timeSlots = _data;
    state.isTimeSlotsLoading = false;
  },
  async onCalendarSelect(payload) {

    let schedule = payload.schedule;
    const selectedDate = state.calendar;
    const DayOfWeek = this.moment(selectedDate).format('dddd')

    if (schedule) {

      const availRes = state.xAvailability.filter(x => x.date_schedule === selectedDate)

      if (availRes.length === 0) {
        state.xAvailability.push({
          date_schedule: selectedDate,
          day: DayOfWeek,
          items: { Morning: [], Afternoon: [], Evening: [] }
        })
      }

      schedule = schedule.map(x => {
        const xSelectedDate = this.moment(`${state.calendar} ${x.start_time}`).day(x.day)
        const utc_dt = this.moment.tz(`${xSelectedDate.format('YYYY-MM-DD')} ${x.start_time}`, 'UTC').tz(state.selectedTimeZone)
        return {
          date_schedule: selectedDate,
          day: utc_dt.format('dddd'),
          time: `${utc_dt.format('HH:mm:ss')}-${this.moment(utc_dt).add(1, 'hours').format('HH:mm:ss')}`,
          day_part: this.getDayPart(utc_dt.format('HH:mm:ss')),
          start_time: utc_dt.format('HH:mm:ss')
        }
      })

      schedule = schedule.filter(x => {
        const currDateTime = this.moment.tz(state.selectedTimeZone)
        let selDateTime = this.moment.tz(`${selectedDate} ${x.start_time}`, state.selectedTimeZone)
        return x.day === DayOfWeek && selDateTime.isAfter(currDateTime.format(state.dateFormat))
      })

      state.isTimeSlotsLoading = true
      state.xTimeSlots = await this._getTimeSlots()
      state.notAvailableSchedule = await this.removeNotAvailableSchedule()
      state.notAvailableSchedule.forEach(item => {
        const result = schedule.find(x => {
                          return x.date_schedule === item.date_schedule &&
                                x.day === item.day &&
                                x.day_part === item.day_part &&
                                x.time === item.time
                        })
        if (result !== undefined) {
          const index = schedule.indexOf(result)
          schedule.splice(index, 1)
        }
      })
      state.isTimeSlotsLoading = false
      
      state.availableSchedule = schedule

    }
  },
  formatDateTimeSchedule(date, time) {
    const tzName = state.timeZoneOptions.find(x => x.timezone === state.selectedTimeZone).name || vm.getTzRegion(state.selectedTimeZone)
    const vm = this
    const Date = date
    const Time = time.split('-')
    const dt = this.moment(`${Date} ${this.moment(`${Date} ${Time[0]}`).tz(state.selectedTimeZone).format('HH:mm:ss')}`).format('dddd, LL')
    const time1 = vm.moment.tz(`${Date} ${Time[0]}`, state.selectedTimeZone).format('h:mm A')
    const time2 = vm.moment.tz(`${Date} ${Time[1]}`, state.selectedTimeZone).format('h:mm A')
    return `${dt} at ${time1} - ${time2} (${tzName})`
  },
  async removeNotAvailableSchedule () {
    const response = await this.$store.dispatch('getNotAvailableSchedules', { tutor_id: state.tutorId, days_covered: state.maxBookingDays, child_id: state.selectedLearner })
    const _data = response.data.data
    return _data.map(item => {
      const utc_dt = this.moment.tz(item.start_date, 'UTC').tz(this.selectedTimeZone)
      return {
        date_schedule: utc_dt.format('YYYY-MM-DD'),
        day: utc_dt.format('dddd'),
        day_part: this.getDayPart(utc_dt.format('HH:mm:ss')),
        time: `${utc_dt.format('HH:mm:ss')}-${this.moment(utc_dt).add(1, 'hours').format('HH:mm:ss')}`
      }
    })
  }
}

export const watch = {
  selectedTimeZone: {
    handler: async function (newVal, oldVal) {

      const vm = this

      if (oldVal === "") return false
      
      vm.minDate = vm.moment.tz(vm.selectedTimeZone).format()
      // vm.maxDate = vm.moment().add(vm.maxBookingDays, "days").tz(vm.selectedTimeZone).format()
      vm.maxDate = vm.moment.tz(
        "2024-09-15 00:00:00",
        "YYYY-MM-DD HH:mm:ss",
        "Asia/Manila"
      ).toDate();

      const tzFrom = oldVal
      const tzTo = newVal

      const newSchedules = []

      vm.schedules.forEach((x, index) => {
        const newConvertedItems = []
        x.items.forEach(item => {
          const dt = this.moment.tz(`${x.date_schedule} ${item.split('-')[0]}`, tzFrom).tz(tzTo)
          newConvertedItems.push({
            date: dt.format('YYYY-MM-DD'),
            time: dt.format('HH:mm:ss'),
            day: this.moment(dt).format('dddd')
          })
        })
        if (newConvertedItems.length > 0) {
          vm.schedules[index].date_schedule = newConvertedItems[0].date
          vm.schedules[index].day = newConvertedItems[0].day
          vm.schedules[index].sortD = this.moment(newConvertedItems[0].date)
        }
        const groupedDateSchedule = newConvertedItems.map(item => item.date).filter((value, index, self) => self.indexOf(value) === index)
        groupedDateSchedule.forEach(item => {
          const result = newConvertedItems.filter(x => x.date === item)
          const items = result.map(r => `${r.time}-${this.moment(`${r.date} ${r.time}`).add(1, 'hours').format('HH:mm:ss')}`)
          const ns_result = newSchedules.find(n => n.date_schedule === item)
          if (ns_result !== undefined) {
            const ns_index = newSchedules.indexOf(ns_result)
            newSchedules[ns_index].items.push(...items)
          } else {
            newSchedules.push({
              date_schedule: item,
              items: items,
              day: this.moment(item).format('dddd'),
              sortD: this.moment(item)
            })
          }
        })
      })
      
      vm.schedules = newSchedules

      const newAvailabilities = []

      vm.xAvailability.forEach((x, index) => {
        const newConvertedItems = [];
        [].concat.apply([], [x.items.Morning, x.items.Afternoon, x.items.Evening]).forEach((item) => {
          const dt = this.moment.tz(`${x.date_schedule} ${item.split('-')[0]}`, tzFrom).tz(tzTo)
          newConvertedItems.push({
            date: dt.format('YYYY-MM-DD'),
            day: this.moment(dt).format('dddd'),
            day_part: this.getDayPart(dt.format('HH:mm:ss')),
            time: `${dt.format('HH:mm:ss')}-${this.moment(dt).add(1, 'hours').format('HH:mm:ss')}`
          })
        })
        if (newConvertedItems.length > 0) {
          vm.xAvailability[index].date_schedule = newConvertedItems[0].date
          vm.xAvailability[index].day = newConvertedItems[0].day
        }

        const groupedDateSchedule = newConvertedItems.map(item => item.date).filter((value, index, self) => self.indexOf(value) === index)
        groupedDateSchedule.forEach(item => {
          const result = newConvertedItems.filter(x => x.date === item)
          const items = {
            Morning: [],
            Afternoon: [],
            Evening: []
          }
          const DayParts = ['Morning', 'Afternoon', 'Evening']
          DayParts.forEach((daypart) => {
            if (result.find(_ => _.day_part === daypart) !== undefined) {
              items[daypart].push(...result.map(x => x.time))
            }
          })
          const ns_result = newAvailabilities.find(n => n.date_schedule === item)
          if (ns_result !== undefined) {
            const ns_index = newAvailabilities.indexOf(ns_result)
            DayParts.forEach((daypart) => {
              newAvailabilities[ns_index].items[daypart].push(...items[daypart])
            })
          } else {
            newAvailabilities.push({
              date_schedule: item,
              day: this.moment(item).format('dddd'),
              items: {
                Morning: items['Morning'],
                Afternoon: items['Afternoon'],
                Evening: items['Evening']
              }
            })
          }
        })
      })

      vm.xAvailability = newAvailabilities

      const payload = {
        schedule: vm.teacherData.schedule
      }

      vm.onCalendarSelect(payload)

      setTimeout(() => {
        this.setActiveDateFromTz(newVal)
      }, 500);
      
    }
  }
}
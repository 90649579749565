<template>
  <div>
    <b-table class="default-table-css font-size-sm" :fields="fields" :busy="isLoading" :items="items" empty-text="No accepted booking from tutors yet." show-empty responsive>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> please wait while data is loading...</strong>
        </div>
      </template>

      <template #cell(LevelCategory)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.LevelCategory }}</div>
        </div>
      </template>

      <template #cell(SubjectLesson)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.SubjectLesson }}</div>
        </div>
      </template>

      <template #cell(Learner)="data">
        <div class="d-flex align-items-center">
          <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
          <div>{{ data.item.Learner }}</div>
        </div>
      </template>

      <template #cell(Tutor)="data">
        <div class="d-flex align-items-center">
          <b-avatar size="2em" class="mr-1" :src="data.item.tutor_image"></b-avatar>
          <div>{{ data.item.Tutor }}</div>
        </div>
      </template>

      <template #cell(Amount)="data">
        <div class="d-flex align-items-center">
          <div>
            <template v-if="!isUserFromPh">
              {{ convertAmount(data.item.Amount).result }}
            </template>
            <template v-else>
              {{ `₱ ${formatPrice(data.item.Amount)}` }}
            </template>
          </div>
          <div><b-icon icon="info-circle-fill" v-b-tooltip.hover="{title: 'Processing fee will be added upon payment', placement: 'bottom'}" scale="1" variant="info"></b-icon></div>
        </div>
      </template>

      <template #cell(Expiry)="data">
        <div class="d-flex align-items-center">
          <div>{{ formatExpiry(data.item.Expiry) }}</div>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button class="table-pay-now-btn" size="sm" :disabled="data.item.lock_payment == 1" v-b-tooltip.hover :title="data.item.lock_payment == 1 ? 'Payments are allowed up until 90 minutes prior to the start of the session.' : ''" @click="paySessionRequest(data.item.transaction_id)">
          <font-awesome-icon class=" mr-1" :icon="['fas', 'coins']" />Pay Now
        </b-button>
        <b-button class="table-view-details-btn mx-1" size="sm" title="View Details" v-b-tooltip.hover v-b-modal.view-details-modal @click="selectedData = data.item.data">
          <font-awesome-icon class="" :icon="['fas', 'external-link-alt']" />
        </b-button>
      </template>
    </b-table>

    <b-pagination v-if="acceptedSessions.data.length > 0" v-model="currentPage" :total-rows="acceptedSessions.total" :per-page="perPage" align="right" class="pt-4" size="sm"></b-pagination>

    <b-modal id="view-details-modal" size="xl" title="Set Schedule" hide-footer body-class="p-0" scrollable>
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center">
          <div>
            <div class="font-weight-semibold">Booking schedule with Tutor {{ selectedData.tutor_name }}</div>
          </div>
          <b-button type="button" class="close" size="sm" @click="close()">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>

      </template>

      <div class="p-3 ">

       <b-row class="pb-4">
          <b-col lg="5" sm="12">
            <!-- Transaction ID -->
            <div class="font-size-sm d-flex align-items-center ">
              <div class="relative-div px-2">
                Transaction ID: <span class="ml-2 font-weight-semibold"> {{ selectedData.transaction_id }}
                </span>
                <b-button @click="onCopy(selectedData.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>

            </div>
          </b-col>
          <b-col lg="7" sm="12">
            <!-- Request Date & Time -->
            <div class="font-size-sm">
              Request Date and Time:<span class="font-weight-semibold ml-2">{{ moment.utc(selectedData.created_at).tz(userData.timezone).format('dddd, LL') }}</span>
              <!-- Time --> <span class="ml-1 font-weight-semibold">{{ `${moment.utc(selectedData.created_at).tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</span>
            </div>
          </b-col>
        </b-row>

        <!-- Table Content -->
        <b-table class="default-table-css font-size-sm" :fields="modalFields" :items="selectedData.bookings" empty-text="No details found." show-empty responsive>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> please wait while data is loading...</strong>
            </div>
          </template>

          <template #cell(BookingID)="data">
            <div class="d-flex align-items-center relative-div mr-2">
                {{ data.item.booking_id }}
              <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-1 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
              </b-button>
            </div>
          </template>

          <template #cell(LevelCategory)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.grade_level }}</div>
            </div>
          </template>

          <template #cell(SubjectLesson)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.subject }}</div>
            </div>
          </template>

          <template #cell(Learner)="data">
            <div class="d-flex align-items-center">
              <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
              <div>{{ data.item.learner_name }}</div>
            </div>
          </template>

          <template #cell(Date)="data">
            <div class="d-flex align-items-center">
              <div>{{ moment.utc(data.item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
            </div>
          </template>

          <template #cell(Time)="data">
            <div class="d-flex align-items-center">
              <div>{{ `${moment.tz(data.item.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
            </div>
          </template>

        </b-table>

      </div>

      <!-- Custom Modal Footer -->
      <div class="light-white-bg w-100 p-3">
        <div class="font-weight-semibold pb-2">Notes:</div>
        <p class="font-weight-normal font-size-sm pb-4">{{ selectedData.note }}</p>

        <!-- Modal Buttons -->
        <div class="d-flex align-items-center justify-content-between pb-3">
          <div></div>
          <div>
            <b-button class="table-close-back-btn mr-2" @click="$bvModal.hide('view-details-modal')">Back</b-button>
            <b-button class="table-pay-now-btn" size="sm" :disabled="selectedData.lock_payment == 1" @click="paySessionRequest(selectedData.transaction_id)">
              <font-awesome-icon class=" mr-1" :icon="['fas', 'coins']" />Pay Now
            </b-button>
          </div>
        </div>

      </div>

    </b-modal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../mixins";
import paymentMixins from "../../../mixins/collection/";
export default {
  props: ["isLoadTriggered"],
  name: "LearnerAcceptedSessions",
  data: () => ({
    isLoading: false,
    perPage: 10,
    currentPage: 1,
    noSessionsToday: "",
    fields: [
      { key: "LevelCategory", label: "Level/Category" },
      { key: "SubjectLesson", label: "Subject/Lesson" },
      { key: "Learner", sortable: true },
      { key: "Tutor", sortable: true },
      "Amount",
      { key: "Expiry", sortable: true },
      "Action",
    ],
    selectedData: [],
    modalFields: [
      "BookingID",
      { key: "LevelCategory", label: "Level/Category" },
      { key: "SubjectLesson", label: "Subject/Lesson" },
      "Learner",
      "Date",
      "Time",
    ],
    items: [],
    showTop: false,
    listPolling: null
  }),
  computed: {
    ...mapState(["userData", "acceptedSessions"]),
  },
  beforeMount() {
    if (this.isLoadTriggered) this.$emit("triggeredWatchSessions");
    this.loadSessions()
    this.listPolling = setInterval(() => { this.startLockPaymentUpdate() }, 5000)
  },
  beforeDestroy(){
    clearInterval(this.listPolling)
  },  
  methods: {
    loadSessions() {
      const vm = this;
      vm.isLoading = true;
      vm.$store.state.acceptedSessions.data = [];
      vm.$store.dispatch("getAcceptedSessions", { params: { count: vm.perPage, page: vm.currentPage, is_accepted: 1 }, scope: "learner-parent-request" })
        .then((response) => {
          vm.noSessionsToday = response.data.data.data.length === 0 ? true : false;
          vm.items = response.data.data.data.map((item) => {
            return {
              LevelCategory: item.grade_level,
              SubjectLesson: item.subject,
              Learner: item.learner_name,
              Tutor: item.tutor_name,
              SessionType: item.session_type,
              Amount: item.total,
              Expiry: item.expiry,
              learner_image: item.learner_image,
              tutor_image: item.tutor_image,
              transaction_id: item.transaction_id,
              lock_payment: item.lock_payment,
              data: item,
            };
          });
          vm.fields.filter(x => x['sortable'] !== undefined).map(x => x.sortable = response.data.data.data.length > 1 ? true : false)
        })
        .finally(() => { vm.isLoading = false; });
    },
    onCopy(text) {
      this.$emit('copyToClipboard', text)
    },
    async startLockPaymentUpdate() {
      const vm = this, response = await vm.$store.dispatch('getAcceptedSessions', { params: { is_accepted: 1, view: "calendar" }, scope: 'learner-parent-request' })
      const _data = response.data.data.data
      if (_data.length > 0) {
        _data.forEach(x => {
          const xLockPayment = x.lock_payment, xTransId = x.transaction_id
          const result = vm.items.find(_ => _.transaction_id === xTransId)
          if (result !== undefined) {
            const _lockPayment = result.lock_payment, _index = vm.items.indexOf(result)
            if (xLockPayment !== _lockPayment) {
              vm.items[_index].lock_payment = xLockPayment
            }
          }
        });
      }
    },
  },
  watch: {
    currentPage() {
      this.loadSessions();
    },
    isLoadTriggered(val) {
      if (val) this.loadSessions();
      this.$emit("triggeredWatchSessions");
    },
  },
  mixins: [mixins, paymentMixins],
};
</script>

<style scoped>
</style>
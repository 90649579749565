<template>
  <div>
    <div class="text-center mt-4">
      <b-form-rating class="font-size-xl" v-model="rating" icon-empty="star-fill" variant="warning" no-border inline readonly></b-form-rating>
    </div>
    <div class="text-center mb-4 font-size-md">
      <p class="mt-2 font-weight-bold" v-if="rating == null"> Rate {{ tutorName }}</p>
      <p class="mt-2 font-weight-bold"> {{ ratingOptions[rating] }} </p>
    </div>
    <div class="px-5" v-if="rating <= 3 && rating != null">
      <b-form-group label="What could have been done better? *" label-for="feedback" size="sm">
        <b-form-select id="recommendation" v-model="recommendation" size="sm" :disabled="true">
          <b-form-select-option :value="''">-- please select an option --</b-form-select-option>
          <b-form-select-option v-for="(rec, index) in recommendationOptions" :key="index" :value="rec.code">{{ rec.recommendation }}</b-form-select-option>
        </b-form-select>
        <!-- <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
      </b-form-group>
    </div>
    <div class="px-5">
      <div class="py-2">
        <div class="d-flex align-items-center justify-content-between">
          <label>Tell us more about it:</label>
          <span class="font-size-xs gray" v-if="false" style="cursor: pointer;" @click="onGetHelp" v-b-tooltip.hover title="Click here to get help from Admins">
            <font-awesome-icon class="font-size-xs mr-1" :icon="['fas', 'exclamation-circle']" />Get Help
          </span>

        </div>
        <b-form-group label-for="feedback" size="sm">
          <b-form-textarea id="feedback" readonly v-model="feedback" rows="8" placeholder="please provide feedback" size="sm" no-resize aria-describedby="password-help-block"></b-form-textarea>
          <b-form-invalid-feedback :state="true">
            Type at least 20 characters
          </b-form-invalid-feedback>
          <b-form-text id="password-help-block" style="float:right;">
            {{ feedbackCharactersRemaining }} <span v-if="feedbackCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
          </b-form-text>
        </b-form-group>
      </div>
    </div>
    <div class="text-center my-5">
      <b-button class="blue-button font-weight-bold mx-1" @click="onOkClick" style="min-width: 250px;">Ok</b-button>
    </div>

  </div>
</template>

<script>
import mixins from "../../mixins";
export default {
  name: "ReportSection",
  props: ["payload"],
  data: () => ({
    rating: null,
    tutorName: "",
    recommendation: "",
    feedback: "",
    characterLimit: 500,
    ratingOptions: [
      "",
      "The session was unsatisfactory",
      "The session could be improved",
      "The session was okay",
      "The session was very good",
      "The session was excellent",
    ],
  }),
  computed: {
    recommendationOptions() {
      return this.$store.state.reviewRecommendations;
    },
    feedbackCharactersRemaining: {
      get() {
        const newLimit = this.characterLimit - this.feedback.length;
        return newLimit < 0 ? 0 : newLimit;
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    this.rating = this.payload.rating;
    this.tutorName = this.payload.tutor_name;
    this.recommendation = this.payload.recommendation || "";
    this.feedback = this.payload.feedback || "";
    if (this.feedback == "") {
      document
        .getElementById("feedback")
        .setAttribute(
          "placeholder",
          this.feedback == ""
            ? "no feedback provided"
            : "please provide feedback"
        );
    }
    document
      .getElementById("feedback")
      .setAttribute("maxlength", this.characterLimit);
  },
  methods: {
    onOkClick() {
      this.$emit("onOkClick");
    },
    onGetHelp() {
      this.$emit("onGetHelp");
    },
  },
  mixins: [mixins],
};
</script>
import Vue from "vue";
import { createFocusTrap } from "focus-trap";

Vue.directive("focus-trap", {
  inserted: function(el) {
    const focusTrap = createFocusTrap(el, {});
    focusTrap.activate();
    el.__focusTrap__ = focusTrap;
  },

  componentUpdated: function(el) {
    if (el.offsetParent !== null) {
      const focusTrap = el.__focusTrap__;
      if (focusTrap) {
        if (focusTrap.active) {
          return;
        }
        focusTrap.activate();
      } else {
        const newFocusTrap = createFocusTrap(el, {
          clickOutsideDeactivates: true,
          escapeDeactivates: false
        });
        newFocusTrap.activate();
        el.__focusTrap__ = newFocusTrap;
      }
    } else {
      const focusTrap = el.__focusTrap__;
      if (focusTrap && focusTrap.active) {
        focusTrap.deactivate();
      }
      delete el.__focusTrap__;
    }
  },

  unbind: function(el) {
    const focusTrap = el.__focusTrap__;
    if (focusTrap) {
      if (focusTrap.active) {
        focusTrap.deactivate();
      }
      delete el.__focusTrap__;
    }
  }
});

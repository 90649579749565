<template>
  <div>
    <HeaderBanner :CourseData="this.course_data[course_index]"/>
    
    <SubjectList :CourseData="this.course_data[course_index]"/>

    <Summary :CourseData="this.course_data[course_index]"/>



  </div>
</template>

<script>
import HeaderBanner from "./marketing/course/HeaderBanner.vue";
import SubjectList from "./marketing/course/SubjectList.vue";
import Summary from "./marketing/course/Summary.vue";

  export default {
    name: 'BecomeATutor',
    beforeMount() {
     this.course_index = this.getCourseIndex(this.$route.params.course_name);
      
    },
    data() {
      return {
            course_index: 0,
            course_code: '',
            course_data: [  
                        {
                          course_code: 'academic_tutoring',
                          course_name: 'Academic Tutoring',
                          course_image: 'Header - Academic Tutoring.jpg',
                          summary: 'Academic tutoring promotes individualized learning and helps learners improve their performance, retention and personal growth.',
                          subject_list: [
                                          {
                                            subject_image: '',
                                            subject_name: '1-on-1 Tutoring', 
                                            subject_description: 'One on one tutoring can be beneficial for learners who wish to improve in their academic standing and need additional guidance in completing their coursework.  By working with our vetted tutors, learners will have access to individualized instruction that will help them improve their academic performance.',
                                            view_tutors_url: '/search',
                                            learn_more_url: ''
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Small Group Tutoring', 
                                            subject_description: 'Small group tutoring can be beneficial for students who wish to improve their skills and comprehension on a specific subject, but don’t have the resources to do one-on-one tutoring.  Small group tutoring allows the learner to collaborate with fellow learners who are facing similar struggles and may be able to work on them together.',
                                            view_tutors_url: '',
                                            learn_more_url: '/group-tutoring'
                                          }
                                        ], 
                          course_description: 'Chalkboard’s 1-on-1 academic tutoring and small group classes offer personalized learning experience tailored for every learner’s specific needs.',
                          testimonial: {
                            featured_image: 'Academic Tutoring Testimonial.jpg',
                            quote: `Tutor Israel has helped my son with his modules (all subjects) over the course of the past three months. During that time, Tutor Israel has been patient and flexible in meeting my son's learning needs. Today my son is both caught up on his modules and retaining the material. Thanks so much Tutor Israel and look forward to our next session!`,
                            reviewer_name: `Lita, Chalkboard parent`,
                            reviewer_location: `Olongapo City, Philippines`,
                          },
                          post_scripts: {
                            header: 'Learn with Chalkboard',
                            body: 'Jumpstart your child’s academic success and forge ahead their learning process by getting assistance from our brilliant tutors.',
                            book_now_url: `/search`
                          }
                        }
                      , {
                          course_code: 'coding',
                          course_name: 'Coding',
                          course_image: 'Header - Coding.jpg',
                          summary: 'Coding is not just about learning a programming language because it also helps with creativity, problem solving, presentation skills, and resilience.',
                          subject_list: [
                                          {
                                            subject_image: 'coding/scratchjr.jpg',
                                            subject_name: 'ScratchJr', 
                                            subject_subtitle: 'Animation, Stories and Minigames',
                                            target_learners: "Ages 5-8",
                                            prerequisite: "None",
                                            session_count: "6-8 ",
                                            subject_description: 'Introductory visual programming',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/scratch_jr'
                                          },
                                          {
                                            subject_image: 'coding/scratchlevel1.jpg',
                                            subject_name: 'Scratch Level 1', 
                                            subject_subtitle: 'Animation And Storytelling',
                                            target_learners: "Ages 8-12",
                                            prerequisite: "None",
                                            session_count: "8-10 ",
                                            subject_description: 'Introduction to the basics of programming using visuals',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/scratch_level_1'
                                          },
                                          {
                                            subject_image: 'coding/scratchlevel2.jpg',
                                            subject_name: 'Scratch Level 2', 
                                            subject_subtitle: 'Game Development',
                                            target_learners: "Ages 9-13",
                                            prerequisite: "None",
                                            session_count: "8-10 ",
                                            subject_description: 'Applications of programming concepts by building and designing games',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/scratch_level_2'
                                          },
                                          {
                                            subject_image: 'coding/pythonlevel1.jpg',
                                            subject_name: 'Python Level 1', 
                                            subject_subtitle: 'Programming With Turtle',
                                            target_learners: "Ages 10-16",
                                            prerequisite: "None",
                                            session_count: "8-10 ",
                                            subject_description: 'Learn the basics of Python',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/python_level_1'
                                          },
                                          {
                                            subject_image: 'coding/pythonlevel2.jpg',
                                            subject_name: 'Python Level 2',
                                             subject_subtitle: 'Game Development Using PyGame',
                                            target_learners: "Ages 10-16",
                                            prerequisite: "Python 1",
                                            session_count: "8-10", 
                                            subject_description: 'Learn about creating Graphical User Interface (GUI) and the concept of Object-Oriented Programming (OOP)',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/python_level_2'
                                          },
                                          {
                                            subject_image: 'coding/robloxlevel1.jpg',
                                            subject_name: 'Roblox Level 1',
                                             subject_subtitle: 'Build Your Own Obby',
                                            target_learners: "Ages 10-16",
                                            session_count: "8-10 ",
                                            prerequisite: "Scratch 1 or Scratch 2", 
                                            subject_description: 'Learn to make your own world in this introductory course using Roblox Studio',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/roblox_level_1'
                                          },
                                          {
                                            subject_image: 'coding/robloxlevel2.jpg',
                                            subject_name: 'Roblox Level 2',
                                            subject_subtitle: 'Create An Adventure Map',
                                            target_learners: "Ages 10-16",
                                            prerequisite: "Scratch 1 or Scratch 2",
                                            session_count: "8-10 ", 
                                            subject_description: 'Learn to make your own Adventure Maps using Roblox Studio’s terrain tools',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/roblox_level_2'
                                          },
                                          {
                                            subject_image: 'coding/minecraft1.jpg',
                                            subject_name: 'Minecraft Level 1',
                                             subject_subtitle: 'Modding Essentials',
                                            target_learners: "Ages 9-13",
                                            session_count: "8-10 ",
                                            prerequisite: "None", 
                                            subject_description: 'Learn to build your own houses, plants, animals and mobs and play with them in Minecraft',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/minecraft_1'
                                          },
                                          {
                                            subject_image: 'coding/minecraft2.jpg',
                                            subject_name: 'Minecraft Level 2',
                                             subject_subtitle: 'Create Your Own Biome',
                                            target_learners: "Ages 9-13",
                                            session_count: "8-10 ",
                                            prerequisite: "None", 
                                            subject_description: 'Learn the basics of the Java Programming language to create a custom biome in Minecraft. ',
                                            view_tutors_url: '/search?subjscope=Coding',
                                            learn_more_url: '/subject/minecraft_2'
                                          },
                                        ], 
                          course_description: 'Learn Coding from our expert coding instructors to open up many opportunities and provide competitive advantages in your studies and career.',
                          testimonial: {
                            featured_image: 'Coding Testimonial.jpg',
                            quote: "I love how tutor Kristine is so patient with both my boys. They enjoy learning coding and have a great time after the lesson. I also liked the fact that every time they finished their session tutor Kristine gives the feedback. It keeps me updated and informed. Thank you for making the boys have fun while learning coding.",
                            reviewer_name: `Cecily, Chalkboard parent`,
                            reviewer_location: `New Jersey`,
                          },
                          post_scripts: {
                            header: 'Learn How To Code',
                            body: 'Offer your child a bright future with coding’s numerous benefits and possibilities. Allow our coding tutors to lead your child towards success.',
                            book_now_url: `/search`
                          }
                        }
                      , {
                          course_code: 'reading',
                          course_name: 'Reading',
                          course_image: 'Header - Reading.jpg',
                          summary: 'Reading improves brain connectivity, increases vocabulary and comprehension, and promotes critical and analytical thinking skills. An emphasis on reading helps develop higher levels of focus and concentration.',
                          subject_list: [
                                          {
                                            subject_image: 'reading/reading_readiness_1a.jpg',
                                            subject_subtitle: 'The Sounds I Can Make',
                                            target_learners: "Ages 3-4",
          session_count: "6", 
                                            subject_name: 'READING READINESS PART 1A', 
                                            subject_description: ' Learn  phonemic sounds through games, activities and stories.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/reading_readiness_1a'
                                          },
                                          {
                                            subject_image: 'reading/reading_readiness_1b.jpg',
                                            subject_subtitle: 'Hello, ABC', 
                                            subject_name: 'READING READINESS PART 1B',
                                            target_learners: "Ages 3-4",
          session_count: "6",
                                            subject_description: 'Getting to know letter names and sounds.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/reading_readiness_1b'
                                          },
                                          {
                                            subject_image: 'reading/reading_readiness_2a.jpg',
                                            subject_subtitle: 'Time to Blend!', 
                                            subject_name: 'READING READINESS PART 2A',
                                            target_learners: "Ages 4-5",
          session_count: "6",
                                            subject_description: 'Start phonetic reading focusing on short vowel sounds and cvc.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/reading_readiness_2a'
                                          },
                                          {
                                            subject_image: 'reading/reading_readiness_2b.jpg',
                                            subject_subtitle: 'A for apple and A for acorn', 
                                            subject_name: 'READING READINESS PART 2B',
                                            target_learners: "Ages 4-5",
          session_count: "6",
                                            subject_description: 'Learn about long vowel sounds.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/reading_readiness_2b'
                                          },
                                          {
                                            subject_image: 'reading/elem_reading_1.jpg',
                                            subject_subtitle: 'I Can Read BIG Words Too!', 
                                            subject_name: 'ELEMENTARY READING 1',
                                            target_learners: "Ages 6-7",
          session_count: "6",
                                            subject_description: ' Learn about complex and irregular words.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/elementary_reading_1'
                                          },
                                          {
                                            subject_image: 'reading/elem_reading_2.jpg',
                                            subject_subtitle: ' Let’s Make a Story', 
                                            subject_name: 'ELEMENTARY READING 2',
                                            target_learners: "Ages 6-7",
          session_count: "6",
                                            subject_description: 'Learn the basic parts of speech to make simple sentences.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/elementary_reading_2'
                                          },
                                          {
                                            subject_image: 'reading/inter_reading_1.jpg',
                                            subject_subtitle: ' Readers and Writers', 
                                            subject_name: 'INTERMEDIATE READING SKILLS 1',
                                            target_learners: "Ages 8-9",
          session_count: "6",
                                            subject_description: 'The basics of story writing.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/intermediate_reading_1'
                                          },
                                          {
                                            subject_image: 'reading/inter_reading_2.jpg',
                                            subject_subtitle: ' Let’s Dig Deeper', 
                                            subject_name: 'INTERMEDIATE READING SKILLS 2',
                                            target_learners: "Ages 8-9",
          session_count: "6",
                                            subject_description: 'Introduction to higher reading comprehension skills.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/intermediate_reading_2'
                                          }
                                        ], 
                          course_description: 'Advanced reading skills are beneficial to our young learners! Learn from our reading enthusiasts here in Chalkboard.',
                          testimonial: {
                            featured_image: 'Reading Testimonial.jpg',
                            quote: `Teacher Regina is very effective. She keeps my son engaged throughout the session. I liked that she incorporated play in teaching as this is where my son really got the concept on how to read short words. Thank you, Teacher Regina! Keep it up!`,
                            reviewer_name: `Haydee, Chalkboard Parent`,
                            reviewer_location: `Mandaluyong City, Philippines`,
                          },
                          post_scripts: {
                            header: 'Learn How To Read',
                            body: 'Support your child’s cognitive development and improve their imagination and creativity through reading. Start their reading journey with our specialists.',
                            book_now_url: '/search?subjscope=Reading'
                          }
                        }
                      , {
                          course_code: 'music_arts_languages',
                          course_name: 'Music, Arts, and Languages',
                          summary: 'Music can enhance brain function in children, while art encourages self-expression and creativity. Learning languages, especially at a young age, improves memory function.',
                          course_image: 'Header - MAL.png',
                          subject_list: [
                                          {
                                            subject_image: '',
                                            subject_name: 'Music', 
                                            subject_description: 'Improve your overall mental development and have fun while learning music from our talented music instructors.',
                                            view_tutors_url: '/search?search_result=Music',
                                            learn_more_url: ''
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Arts', 
                                            subject_description: 'Embrace your natural creativity and enhance your skills by learning from our gifted art instructors.',
                                            view_tutors_url: '/search?search_result=Art',
                                            learn_more_url: ''
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Languages', 
                                            subject_description: 'Boost your memory function and your communication abilities through learning different languages from our eloquent language instructors.',
                                            view_tutors_url: '/search?search_result=Language',
                                            learn_more_url: ''
                                          }
                                        ], 
                          course_description: 'Nurture your love for music, arts, and interest in language to improve creativity, self-expression, and proficiency.',
                          testimonial: {
                            featured_image: 'MAL Testimonial.jpg',
                            quote: `My kid has no previous background on playing the piano but now she already knows how to read notes and play pieces through the patience and help of Teacher Ellen. I am really impressed by how she is teaching my child even though it is only done online. She is a kind, patient and encouraging teacher. She inspires her students to do better through positive encouragement and support. Thank you, Teacher Ellen!`,
                            reviewer_name: `Glaiza, Chalkboard parent`,
                            reviewer_location: `Las Piñas City, Philippines`,
                          },
                          post_scripts: {
                            header: 'Spark Creativity',
                            body: 'Encourage your children to embrace and foster their natural talents and skills.',
                            book_now_url: `/search?subjscope=Music,Art,Languages`
                          }
                        }
                      , {
                          course_code: 'other_lessons',
                          course_name: 'Other Lessons',
                          course_image: 'Header - Other Lessons.jpg',
                          summary: 'Chess develops perspective and planning skills. Public Speaking promotes self-confidence. Entrepreneurship is ideal for developing grit and problem-solving. Vlogging allows learners to share ideas freely and creatively.',
                          subject_list: [
                                          {
                                            subject_image: '',
                                            subject_name: 'Chess', 
                                            subject_description: 'Boost planning skills, increase self-awareness, and improve memory by learning to play and master chess.',
                                            view_tutors_url: '/search?subjscope=Chess',
                                            learn_more_url: ''
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Vlogging: Rookie', 
                                            subject_description: 'Showcase your skills and reach a wider audience by learning how to vlog from our skilled instructors.',
                                            view_tutors_url: '/search?subjscope=Vlogging',
                                            learn_more_url: '/subject/vlogging_1'
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Vlogging: Rising StaR', 
                                            subject_description: 'Showcase your skills and reach a wider audience by learning how to vlog from our skilled instructors.',
                                            view_tutors_url: '/search?subjscope=Vlogging',
                                            learn_more_url: '/subject/vlogging_2'
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Public Speaking: SPEECH BUILDER', 
                                            subject_description: 'Build personal development and boost your confidence by mastering public speaking with our proficient instructors.',
                                            view_tutors_url: '/search?subjscope=Reading&lvlscope=Preschool,Grade 10,Grade 11,Grade 12',
                                            learn_more_url: '/subject/public_speaking_1'
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Public Speaking: - Eloquence and Confidence', 
                                            subject_description: 'Build personal development and boost your confidence by mastering public speaking with our proficient instructors.',
                                            view_tutors_url: '/search?subjscope=Reading',
                                            learn_more_url: '/subject/public_speaking_2'
                                          },
                                          {
                                            subject_image: '',
                                            subject_name: 'Entrepreneurship', 
                                            subject_description: 'Learn the underlying principles of starting a business and set yourself up for success by learning entrepreneurship.',
                                            view_tutors_url: '/search?subjscope=Entrepreneurship',
                                            learn_more_url: ''
                                          }
                                        ], 
                          course_description: 'Learn new skills that can boost academic performance and discover a hobby that can build passion and enrich personal growth significantly.',
                          testimonial: {
                            featured_image: 'Other Lessons Testimonial.jpg',
                            quote: `My sons took Chess lessons with teacher Jabriel and I am so grateful that I chose him to be their instructor. They got very much hooked into it and are always looking forward to the next games and strategies that he will share. He is very patient and established a perfect balance on how he teaches and how he makes sure his students enjoy the sessions. Teacher Jabriel is a rockstar!`,
                            reviewer_name: `Michelle, Chalkboard parent`,
                            reviewer_location: `Los Angeles, California`,
                          },
                          post_scripts: {
                            header: 'Discover More',
                            body: 'Start your children with lessons that can benefit their well-being and can launch them into acquiring great achievements.',
                            book_now_url: `/search?lvlscope=Others`
                          }
                        }

                      
        
        ]        
      }
    },
    computed: {},
    mounted() {},
    methods: { getCourseIndex(key){
              switch (key) {
                case "academic_tutoring":
                  return 0;
                case "coding":
                  return 1;
                case "reading":
                  return 2;
                case "music_arts_languages":
                  return 3;
                case "other_lessons":
                  return 4;                  
                default: return -1;
                  
              }
            }
    },
    components: {HeaderBanner, SubjectList, Summary},
    watch: {

      $route (to, from){
          if(to != from){
            this.course_index = this.getCourseIndex(this.$route.params.course_name);
             
          }
          
      }    
      
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/course.css");
</style>
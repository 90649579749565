<template>
  <div>
  <div class="application">
            <div class="container">
                <div class="applying">
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="sticky-links sticky-top" style="top: 100px;">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <a href="#tutor-application-process" class="nav-link active">Application Process</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#tutor-application-requirements" class="nav-link">Application Requirements</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://chalkboard.tawk.help/category/tutors%E2%80%99-guide" class="nav-link">Tutor FAQ’s</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="app">
                                <div class="process">
                                    <h5 id="tutor-application-process" class="head">Application Process</h5>
                                    <p>Here are steps on how to apply and become a Chalkboard tutor:</p>
                                    <ol>
                                        <li>Sign up as a Tutor. You have the option to sign up as a Professional or a Peer tutor.</li>
                                        <li>Create your profile. Give information about your academic background, specialization, preferred schedule, and share relevant information that can help market tutor's expertise.</li>
                                        <li>Submit your requirements.</li>
                                        <li>Pass your interview and teaching demo. Prepare a 10-minute presentation to showcase your teaching skills and strategies.</li>
                                        <li>Attend the Tutor Onboarding.</li>
                                    </ol>
                                </div>
                                <div class="requirements">
                                    <h5 id="tutor-application-requirements" class="head">Application Requirements</h5>
                                    <div class="pro">
                                        <h5 class="sub-head">For Professional Tutors</h5>
                                        <p>(A professional tutor is anyone who has completed at least an undergraduate degree)</p>
                                        <ul>
                                            <li>Resume or CV</li>
                                            <li>Any government issued ID (SSS, UMID, BIR, GSIS, LTO, Postal ID)</li>
                                            <li>Transcript of Records, Diploma, Board Rating</li>
                                            <li>Certificate of Employment (if applicable)</li>
                                            <li>Training Certificates (if available)</li>
                                        </ul>
                                    </div>
                                    <div class="peer">
                                        <h5 class="sub-head">For Peer Tutors</h5>
                                        <p>(A peer tutor is a student currently enrolled in a college, university or equivalent, and have previous tutoring experience)</p>
                                        <ul>
                                            <li>Resume or Curriculum vitae</li>
                                            <li>School ID or any government issued ID (SSS, UMID, BIR, GSIS, LTO, Postal ID)</li>
                                            <li>Dean's Recommendation or General Weighted Average Certificate</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

           <div class="apply-now">
            <div class="container">
                <div class="apply">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-6 col-sm-12">
                            <h5>Become a Tutor at Chalkboard</h5>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 align-self-end">
                            <div class="apply-button">
                                 <b-link to="/signup" class="btn cb-primary-link px-5">Apply Now</b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
  export default {
    name: 'Requirements',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
  <div>
    <HeaderBanner/>

    <Benefits/>

    <Requirements/>

  </div>
</template>

<script>
import HeaderBanner from "./marketing/become-a-tutor/HeaderBanner.vue";
import Benefits from "./marketing/become-a-tutor/Benefits.vue";
import Requirements from "./marketing/become-a-tutor/Requirements.vue";

  export default {
    name: 'BecomeATutor',
    metaInfo: {
    title: "Become a Tutor - Apply as a tutor | Chalkboard",
    htmlAttrs: { lang: "en-US" },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Share your expertise and become one of our most in-demand tutors.",
      },
      {
        name: "keywords",
        content:
          "online learning, online tutor/online tutorial, online learning platform, online math tutor, english tutors, online tutoring services, online coding tutor, python coding tutor, programming tutor, online marketplace, tutoring services, ukulele tutor, distance learning, virtual learning",
      },
    ],
  },
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    components: {HeaderBanner, Benefits, Requirements}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/become-a-tutor.css");
</style>
let xenditScript = null;
let isXenditReady = false;

function loadXendit() {
  return new Promise((resolve, reject) => {
    if (isXenditReady) {
      resolve(window.Xendit);
    } else if (xenditScript !== null) {
      xenditScript.addEventListener("load", () => {
        isXenditReady = true;
        resolve(window.Xendit);
      });
    } else {
      xenditScript = document.createElement("script");
      xenditScript.setAttribute("src", "https://js.xendit.co/v1/xendit.min.js");
      xenditScript.addEventListener("load", () => {
        isXenditReady = true;
        resolve(window.Xendit);
      });
      xenditScript.addEventListener("error", () => {
        reject(new Error("Failed to load Xendit SDK"));
      });
      document.head.appendChild(xenditScript);
    }
  });
}

export { xenditScript, loadXendit };

<template>
  <main>
    <HeaderBanner />

    <StatsSummary />

    <PopularLessons />

    <LessonsFrom />

    <TeacherList />

    <CustomerFeedback />

    <TrustedPartners />

    <EndSection />

    <PromoPopup v-if="1==0"/>

  </main>
</template>

<!-- Fontawesome -->  

<script src="https://kit.fontawesome.com/e06763c731.js" crossorigin="anonymous"></script>

<script>
import HeaderBanner from "./marketing/front-page/HeaderBanner.vue";
import StatsSummary from "./marketing/front-page/StatsSummary.vue";
import PopularLessons from "./marketing/front-page/PopularLessons.vue";
import LessonsFrom from "./marketing/front-page/LessonsFrom.vue";
import TeacherList from "./marketing/front-page/TeacherList.vue";
import CustomerFeedback from "./marketing/front-page/CustomerFeedback.vue";
import TrustedPartners from "./marketing/front-page/TrustedPartners.vue";
import EndSection from "./marketing/front-page/EndSection.vue";
import PromoPopup from "../components/promo/PromoPopup.vue";
export default {
  components: {
    HeaderBanner,
    StatsSummary,
    PopularLessons,
    LessonsFrom,
    TeacherList,
    CustomerFeedback,
    TrustedPartners,
    EndSection,
    PromoPopup
  },
  name: "Index",
  metaInfo: {
    title: "Chalkboard – Where Learners and Tutors Meet | Chalkboard",
    htmlAttrs: { lang: "en-US" },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Leading online tutor marketplace in Southeast Asia. Find expert online tutors for children including K-12 subjects, coding, music, and more!",
      },
      {
        name: "keywords",
        content:
          "online learning, online tutor/online tutorial, online learning platform, online math tutor, english tutors, online tutoring services, online coding tutor, python coding tutor, programming tutor, online marketplace, tutoring services, ukulele tutor, distance learning, virtual learning",
      },
    ],
  },
  data: () => ({
    search: "",
  }),
  created() {},
  computed: {},
  mounted() {},
  methods: {
    onSubmit() {
      this.$router.push("/search?search_result=" + this.search + "&lvlcat=");
    }
  },
};
</script>
<style scoped>
@import url("../assets/css/home.css");
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0.5;
  z-index: 999;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -5.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
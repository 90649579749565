<template>
  <div></div>
</template>

<script>
export default {
  name: "UserVouchersItem",

  props: {
    voucher: {
      type: Object,
      default() {
        return {};
      }
    },
    showAction: {
      type: Boolean,
      default: true
    }
  }
};
</script>

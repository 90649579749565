<template>
  <b-overlay class="pt-4 px-2 px-sm-2 px-lg-4" :show="isUpdating" rounded="sm">
    <div>
      <span class="fw-bold fs-nm">Booking Requests Preferences</span>
      <p class="fs-nm">
        Set which users you'll automatically accept booking requests from.
      </p>
    </div>

    <b-overlay :show="isLoadingSelectableUsers" rounded="sm">
      <b-card class="mb-4">
        <b-row>
          <b-col lg="4">
            <span class="fs-sm fw-bold">Bookings</span>
            <p class="fs-sm mb-0">
              Auto accept bookings from parents or learners
            </p>
          </b-col>

          <b-col>
            <div :class="autoAcceptBookingsContainerClass">
              <b-form-radio
                v-for="codeType in codeTypes"
                :key="codeType.value"
                class="fs-sm"
                size="sm"
                name="auto-accept-bookings"
                :value="codeType.value"
                v-model="form.data.auto_accept_bookings"
                @change="onAutoAcceptBookingsChange"
                :disabled="!isTutorActive"
              >
                <span class="fs-sm">{{ codeType.label }}</span>
              </b-form-radio>
            </div>

            <template v-if="shouldShowSelectableUsers">
              <v-select
                multiple
                :options="form.selectableUsers"
                label="full_name"
                :selectable="isOptionSelectable"
                :value="selectedUsers"
                placeholder="You are not auto accepting booking requests from anyone at the moment. D:"
                @option:selecting="onSelectableUsersSelecting"
                @option:deselecting="onSelectableUsersDeselecting"
              />
            </template>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-card>
      <b-row>
        <b-col lg="4">
          <span class="fs-sm fw-bold">First-Time Bookings</span>
          <p class="fs-sm mb-0">
            Auto accept the first-time booking of parents or learners
          </p>
        </b-col>

        <b-col class="d-flex align-items-center align-content-center">
          <b-form-checkbox
            v-model="form.data.auto_accept_first_time_booking"
            class="fs-sm"
            switch
            :value="1"
            :unchecked-value="0"
            size="sm"
            :disabled="isAutoAcceptDisabled || !isTutorActive"
          >
            <span class="fs-sm">
              {{ form.data.auto_accept_first_time_booking == 1 ? "On" : "Off" }}
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>

    <hr />

    <div class="d-flex">
      <b-button
        class="ml-auto"
        variant="primary"
        size="sm"
        :disabled="!isTutorActive"
        @click="update()"
      >
        Apply Changes
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "TutorBookingRequests",

  components: {
    vSelect
  },

  computed: {
    isAutoAcceptDisabled() {
      return ["ATACAL", "ATACNN"].includes(this.form.data.auto_accept_bookings);
    },

    shouldShowSelectableUsers() {
      return (
        this.form.data.auto_accept_bookings == "SLCTDO" &&
        !this.isLoadingSelectableUsers
      );
    },

    autoAcceptBookingsContainerClass() {
      return this.shouldShowSelectableUsers ? "mb-2" : "";
    },

    isAnyUserSelected() {
      return (this.form.selectableUsers || []).some(
        (selectableUser) => selectableUser.auto_accept
      );
    },

    selectedUsers() {
      return (this.form.selectableUsers || []).filter(
        (selectableUser) => selectableUser.auto_accept
      );
    }
  },

  data() {
    return {
      path:
        process.env.VUE_APP_BACKEND_URL +
        "/api/tutor-booking-requests-preferences",
      options: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken")
        }
      },
      form: {
        data: {},
        errors: {},
        selectableUsers: []
      },
      codeTypes: [],
      isLoadingSelectableUsers: false,
      isUpdating: false,
      isTutorActive: false
    };
  },

  created() {
    this.get();
  },

  methods: {
    get() {
      this.axios.get(this.path, this.options).then(({ status, data }) => {
        if (status == 200) {
          this.form.data = data.record;
          this.codeTypes = data.ATACPB;
          this.isTutorActive = data.isTutorActive;
          if (data.record.auto_accept_bookings == "SLCTDO") {
            this.form.selectableUsers = data.selectableUsers;
          }
          this.$emit("show-tab");
        }
      });
    },

    update() {
      this.isUpdating = true;

      const data = this.form.data;
      if (data.auto_accept_bookings == "SLCTDO") {
        data["selectedUserIds"] = this.form.selectableUsers
          .filter((user) => user.auto_accept)
          .reduce((result, user) => {
            result.push(user.id);
            return result;
          }, []);
      }

      this.axios
        .put(this.path, data, this.options)
        .then((response) => {
          if (response.status == 200) {
            const vNodesMsg = this.$createElement(
              "p",
              { class: ["fs-sm mb-0"] },
              ["Successfully Updated!"]
            );

            this.$bvToast.toast(vNodesMsg, {
              title: "Booking Requests",
              variant: "success",
              toaster: "b-toaster-top-right",
              headerClass: "fs-sm",
              bodyClass: "fs-sm"
            });
          }
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },

    getSelectableTutors() {
      this.isLoadingSelectableUsers = true;
      this.axios
        .get(this.path + "/get-selectable-tutors", this.options)
        .then((response) => {
          if (response.status == 200) {
            this.form.selectableUsers = response.data;
          }
        })
        .finally(() => {
          this.isLoadingSelectableUsers = false;
        });
    },

    onAutoAcceptBookingsChange(value) {
      if (value == "ATACAL") {
        this.form.data.auto_accept_first_time_booking = 1;
      }
      if (value == "ATACNN") {
        this.form.data.auto_accept_first_time_booking = 0;
      }
      if (value == "SLCTDO") {
        this.getSelectableTutors();
        this.form.data.auto_accept_first_time_booking = 1;
      }
    },

    onSelectableUsersSelecting(value) {
      value.auto_accept = true;
    },

    onSelectableUsersDeselecting(value) {
      value.auto_accept = false;
    },

    isOptionSelectable(option) {
      return !option.auto_accept;
    }
  }
};
</script>

<style scoped>
.fw-bold {
  font-weight: bold !important;
}
.fs-sm {
  font-size: 0.8rem !important;
  line-height: 1.2rem !important;
}
.fs-nm {
  font-size: 1rem !important;
}
</style>

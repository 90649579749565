<template>
  <div>

    <!-- Peer/Pro -->
    <div v-if="!isApplicationReceived && !isApplicationRejected">
      <div v-if="!isPeerSelected && !isPeerNotNull" class="bg-white">
        <b-container>
          <div class="cb-tr-container p-5 text-center mt-4`">
            <div class="font-size-xl font-weight-bold">What type of tutor are you?</div>

            <div class="d-lg-flex align-items-center justify-content-center p-3 my-4">

              <b-button @click="onPeerTutorSelect(0)" size="lg" class="light-blue-border block w-100 light-blue d-flex align-items-center flex-column p-4 m-2">
                <font-awesome-icon class="font-size-xl m-3" :icon="['fas', 'user-tie']" />
                <span>I'm a Professional Tutor</span>
              </b-button>

              <b-button @click="onPeerTutorSelect(1)" size="lg" class="light-blue-border block w-100 light-blue d-flex align-items-center flex-column p-4 m-2">

                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200.223 200.223" style="enable-background:new 0 0 200.223 200.223;" xml:space="preserve" class="font-size-xl m-3 svg-inline--fa fa-user-tie fa-w-14">
                  <path fill="currentColor" d="M51.453,141.914h97.319v27.805H51.453V141.914z M178.008,21.672l-11.074,45.627c4.292,9.313,6.714,19.658,6.733,30.559
    l0.146,84.213c0.008,4.788-1.849,9.294-5.228,12.686c-3.381,3.392-7.88,5.265-12.668,5.273l-111.4,0.193c-0.003,0-0.028,0-0.031,0
    c-9.867,0-17.91-8.029-17.928-17.896l-0.145-84.213c-0.019-10.928,2.335-21.492,6.805-31.117L22.216,21.672
    c-0.572-2.358,0.516-4.807,2.65-5.963l18.401-9.969c1.459-0.791,3.202-0.858,4.718-0.185c1.516,0.674,2.634,2.013,3.026,3.626
    l6.899,28.424c3.285-2.302,6.74-4.311,10.325-6.034v-3.766C68.234,12.473,80.707,0,96.039,0h8.146
    c15.332,0,27.805,12.473,27.805,27.805v3.891c3.599,1.746,7.043,3.766,10.289,6.05l6.933-28.565
    c0.392-1.613,1.509-2.952,3.026-3.626c1.516-0.674,3.258-0.606,4.718,0.185l18.401,9.969
    C177.492,16.866,178.58,19.314,178.008,21.672z M78.947,27.432c6.717-2.004,13.758-3.061,20.965-3.073h0.131
    c7.383,0,14.51,1.11,21.239,3.149c-0.161-9.297-7.762-16.813-17.096-16.813h-8.146C86.73,10.694,79.148,18.17,78.947,27.432z
    M159.466,136.567c0-2.953-2.394-5.347-5.347-5.347H46.106c-2.953,0-5.347,2.394-5.347,5.347v38.5c0,2.953,2.394,5.347,5.347,5.347
    h108.013c2.953,0,5.347-2.394,5.347-5.347V136.567z M159.466,100.566c0-32.728-26.626-59.354-59.354-59.354	s-59.354,26.626-59.354,59.354c0,2.953,2.394,5.347,5.347,5.347s5.347-2.394,5.347-5.347c0-26.831,21.828-48.659,48.659-48.659
    s48.659,21.828,48.659,48.659c0,2.953,2.394,5.347,5.347,5.347S159.466,103.519,159.466,100.566z" />
                </svg>
                I'm a Peer Tutor
              </b-button>

            </div>
            <p>What's the difference between <strong>Peer Tutors</strong> and <strong>Professional Tutors?</strong><br>
              A peer tutor is a student currently enrolled in a college, university or equivalent, and have previous tutoring experience. A professional tutor has completed at least an undergraduate degree.</p>
          </div>
        </b-container>
      </div>

      <validation-observer v-if="isPeerSelected || isPeerNotNull" ref="observer" v-slot="{ handleSubmit }">
        <b-form id="registration-step2-form" @submit.stop.prevent="handleSubmit(onSubmit)">

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-3">
              <!-- If Peer Tutor -->
              <div v-if="isPeerTutor == 1" class="font-size-lg  text-center mb-2">You are registering as a <strong v-b-tooltip.hover.bottom title="A peer tutor is a student currently enrolled in a college, university or equivalent, and have previous tutoring experience">Peer Tutor</strong><a class="light-blue font-size-xs pl-1" style="vertical-align: middle; cursor: pointer;" @click="onChangeTutorType">(change)</a></div>
              <!-- If Professional Tutor -->
              <div v-if="isPeerTutor == 0" class="font-size-lg  text-center mb-2">You are registering as a <strong v-b-tooltip.hover.bottom title="A professional tutor has completed at least an undergraduate degree.">Professional Tutor</strong><a class="light-blue font-size-xs pl-1" style="vertical-align: middle; cursor: pointer;" @click="onChangeTutorType">(change)</a></div>

            </b-card-header>
            <b-card-body class="py-5">

              <b-row align-v="center" align-h="center">
                <b-col lg="8" sm="12">

                  <b-alert show class="light-blue " style="background:#F6FDFE;">
                    <div class="pb-2 font-size-sm">
                      <strong>Guidelines for Uploading files</strong>
                    </div>
                    <ul class="font-size-xs">
                      <li>For easy identification, follow this sample file naming convention
                        <br>LastName_FirstName- Document (Ex: Cruz_Angie-Resume.docx)
                      </li>
                      <li>Accepted file types: .doc, .docx, .jpg, .jpeg, .png</li>
                      <li>Each file cannot exceed {{ convertByteToMegaByte(validDocumentFileSize) }}MB</li>

                      <li>Large files may take some time to upload</li>
                    </ul>
                  </b-alert>
                  <div class="p-2">
                    <div class="mb-2"><strong>Resume *</strong></div>
                    <ValidationProvider name="resume" rules="required" v-slot="validationContext">
                      <b-form-file id="resume" placeholder="Upload resume file here" required v-model="form.resume" accept=".docx,.pdf,.jpeg,.jpg,.png" size="md" :state="getValidationState(validationContext)" @change="onResumeFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                    </ValidationProvider>
                  </div>

                  <!-- If Peer Tutor -->
                  <div class="p-2">
                    <div class="mb-2"><strong>Government ID / School ID *</strong></div>

                    <ValidationProvider name="government id / school id" rules="required" v-slot="validationContext">
                      <b-form-file id="govtId" placeholder="Upload government or school id file here" required v-model="form.government_id" accept=".docx,.pdf,.jpeg,.jpg,.png" size="md" :state="getValidationState(validationContext)" @change="onGovernmentIDFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>

                    </ValidationProvider>
                  </div>

                  <!-- If Professional Tutor -->
                  <div class="p-2" v-if="isPeerTutor == 0">
                    <div class="mb-2"><strong>TOR or Diploma *</strong></div>
                    <ValidationProvider name="TOR or Diploma" rules="required" v-slot="validationContext">
                      <b-form-file id="torDiploma" placeholder="Upload TOR or Diploma file here" required v-model="form.tor_diploma" accept=".docx,.pdf,.jpeg,.jpg,.png" size="md" :state="getValidationState(validationContext)" @change="onTORDiplomaFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                    </ValidationProvider>
                  </div>
                  
                  <!-- If Peer Tutor -->
                  <div class="p-2" v-if="isPeerTutor == 1">
                    <div class="mb-2"><strong>Dean’s Recom/GWA Cert *</strong></div>

                    <ValidationProvider name="Dean's Recom/GWA Cert" rules="required" v-slot="validationContext">
                      <b-form-file id="gwaCert" placeholder="Upload  dean's recom/gwa cert here" required v-model="form.gwa_cert" accept=".docx,.pdf,.jpeg,.jpg,.png" size="md" :state="getValidationState(validationContext)" @change="onGWACertFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                    </ValidationProvider>
                  </div>

                  <div class="py-4 text-center"><strong>Optional Files</strong></div>

                  <!-- If Professional Tutor -->
                  <div class="p-2" v-if="isPeerTutor == 0">
                    <div class="mb-2"><strong>COE</strong></div>
                    <ValidationProvider name="certificate of employment" rules="size:25000" v-slot="validationContext">
                      <b-form-file placeholder="Upload coe here" v-model="form.coe" size="md" accept=".docx,.pdf,.jpeg,.jpg,.png" :state="getValidationState(validationContext)" @change="onCOEFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                    </ValidationProvider>
                  </div>

                  <!-- If Peer Tutor -->
                  <div class="p-2">
                    <div class=""><strong>Certifications</strong></div>
                    <div class="font-size-sm mb-2" v-if="isPeerTutor == 0">(SPED, Singapore Math, Kumon Math, National Certificate, TOEFL/IELTS)</div>
                    <ValidationProvider name="certifications" v-slot="validationContext">
                      <b-form-file placeholder="Upload certificates here" v-model="form.certificate" size="md" accept=".docx,.pdf,.jpeg,.jpg,.png" :state="getValidationState(validationContext)" @change="onCertificateFileChange"></b-form-file>
                      <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                    </ValidationProvider>
                  </div>

                </b-col>
              </b-row>

            </b-card-body>

          </b-card>
          <p class="font-size-sm font-weight-normal text-center mb-3 mt-4">By clicking on the Next button, you certify that all the information entered is true and <br>
            correct to the best of your knowledge and that you understand that submitting false and<br>
            malicious documents and photos may disqualify you from being a tutor on the platform.</p>
          <b-button @click="validateObserver" :disabled="isLoading || isValidatingFileUpload" class="blue-button btn-block font-weight-bold my-3 w-25 mx-auto">
            <b-spinner small v-if="isLoading || isValidatingFileUpload"></b-spinner>
            <span v-if="isLoading"> loading...</span>
            <span v-else-if="isValidatingFileUpload"> validating file upload...</span>
            <span v-if="!isLoading && !isValidatingFileUpload">NEXT</span>
          </b-button>

        </b-form>

      </validation-observer>
    </div>
    <div v-else-if="isApplicationReceived">
      <!-- Show after clicking NEXT -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-3">
            <div class="font-size-lg  text-center mb-2"><strong>Your application has been received</strong></div>

          </b-card-header>
          <b-card-body class="py-5">

            <img class="d-block mx-auto mb-3" src="~@/assets/global/email.gif">
            <div class="text-center px-4">Thank you for your interest in becoming a tutor at Chalkboard. <br>Our Talent Acquisition Team will evaluate your submitted documents and will contact you for your interview and teaching demonstration schedule.<br> For questions, please email us at <span class="light-blue">talent@chalkboard.com.ph</span></div>

          </b-card-body>

        </b-card>
    </div>
    <div v-else>
      <!-- Show after clicking NEXT -->
      <b-card no-body class="mb-1" v-if="isApplicationRejected">
        <b-card-header header-tag="header" class="p-3">
          <div class="font-size-lg  text-center mb-2"><strong>Your application has been rejected</strong></div>

        </b-card-header>
        <b-card-body class="py-5 px-5">
          <p class="font-size-md font-weight-bold">Dear {{ reApplyFirstName }}, </p>
          <p>
            Thank you for considering Chalkboard. After carefully reviewing your
            qualifications, we were unable to approve your offering at this time.
            Common reasons include the inability to demonstrate qualifications for
            stated subject during interview and/or inadequate credentials or
            experience.
          </p>

          <p>We encourage you to review your qualifications and credentials. You
            may consider improving this metric through additional certifications or
            degrees.</p>

          <p>We encourage you to review your teaching demo. Was it a clear
            representation of your tutoring skills? If not, please consider preparing
            another demo tailored to a specific subject matter that you will be
            prepared to excel in and resubmit to our Talent Acquisition Team.</p>

          <p>If you have questions regarding your specific application and/or if you
            would like to schedule a second teaching demo, please email
            <a href="mailto: talent@chalkboard.com.ph" class="light-blue"> talent@chalkboard.com.ph</a>
          </p>

          <b-button v-if="false" @click="onReApply" class="blue-button btn-block font-weight-bold my-5 w-25 mx-auto">
            <b-spinner mdall v-if="isLoading"></b-spinner>
            <span v-if="!isLoading">REAPPLY</span>
          </b-button>
        </b-card-body>

      </b-card>
    </div>

  </div>
</template>

<script>
import mixins from "../../../../../mixins";
import multistepMixin from "../../../../../mixins/multistep-mixin";
import TSTAT from '../../../../../mixins/tutor-status'

export default {
  props: ['userItems'],
  name: "RegistrationTeacherStep2",
  data: () => ({
    isValidatingFileUpload: false,
    isPeerNotNull: false
  }),
  created() {},
  computed: {
    isApplicationReceived() {
      return [TSTAT.RESUBMIT, TSTAT.NEW, TSTAT.FOR_SCREENING, TSTAT.ORIENTATION].includes(this.userItems.status)
    },
    isApplicationRejected() {
      return [TSTAT.REJECTED].includes(this.userItems.status)
    },
    reApplyFirstName() {
      return this.userItems.firstname
    },
  },
  mounted() {
    this.isPeerNotNull = this.$store.state.userData.is_peer !== null
    const isAppReceived = this.isApplicationReceived
    const isAppRejected = this.isApplicationRejected
    document.getElementById("tutor_registration_container").style.display = (isAppReceived || isAppRejected || (this.isPeerSelected || this.isPeerNotNull)) ? "block" : "none";
  },
  methods: {
    onReApply() {
      this.isLoading = true
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/return-application`;
      const options = {
          headers: {
              "Accept": "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${this.UserToken}`,
          }
      }

      this.axios.post(ApiURL, new FormData(), options).then(() => {
        this.$store.dispatch('getUserData', window.localStorage.getItem("userToken")).then(() => {
          window.location.reload()
        }).finally(() => { this.isLoading = false })
      })
    },
    onChangeTutorType () {
      this.isPeerSelected = false
      this.isPeerNotNull = false
      document.getElementById("tutor_registration_container").style.display = "none";
      this.updatePeerStatus()
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          this.onSubmit();
        }
      });
    },
    onSubmit() {

      this.isPeerTutor = this.$store.state.userData.is_peer !== null ? this.$store.state.userData.is_peer : this.isPeerTutor;
      
      const filesData = new FormData();

      filesData.append("resume_file", this.resumeFileName);
      filesData.append("govt_id_file", this.governmentIDFileName);
      filesData.append((this.isPeerTutor) ? "gwa_file" : "tor_file", (this.isPeerTutor) ? this.gwaCertFileName : this.TORDiplomaFileName);
      filesData.append("is_peer", this.isPeerTutor);

      if (this.coeFileName != "") {
        filesData.append("coe_file", this.coeFileName);
      }

      if (this.certificateFileName != "") {
        filesData.append("certificate_file", this.certificateFileName);
      }

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/document`,
            options = {
              headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${this.UserToken}`,
              },
            };

      this.isLoading = true;

      this.axios
        .post(ApiURL, filesData, options)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.isLoading = false;
        });

    },
    onResumeFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.resume = null
                            this.resumeFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.resume = null
            this.resumeFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "resume");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL =  `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.resumeFileName = _data.data.server_name;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.resume = null
                this.resumeFileName = ""
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }

    },
    onGovernmentIDFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.government_id = null
                            this.governmentIDFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.government_id = null
            this.governmentIDFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "govt_id");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.governmentIDFileName = _data.data.server_name
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.government_id = null
                this.governmentIDFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }
        
    },
    onGWACertFileChange (e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.gwa_cert = null
                            this.gwaCertFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.gwa_cert = null
            this.gwaCertFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "gwp");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.gwaCertFileName = _data.data.server_name
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.gwa_cert = null
                this.gwaCertFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }

    },
    onTORDiplomaFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.tor_diploma = null
                            this.TORDiplomaFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.tor_diploma = null
            this.TORDiplomaFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "tor");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.TORDiplomaFileName = _data.data.server_name
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.tor_diploma = null
                this.TORDiplomaFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }

    },
    onCertificateFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.certificate = null
                            this.certificateFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.certificate = null
            this.certificateFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "cert");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };
              
        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.certificateFileName = _data.data.server_name;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.certificate = null
                this.certificateFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }
      
    },
    onCOEFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.coe = null
                            this.coeFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.coe = null
            this.coeFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "coe");
        formData.append("type", (file.type.indexOf("image") !== - 1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.coeFileName = _data.data.server_name;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.coe = null
                this.coeFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }

    },
  },
  mixins: [mixins, multistepMixin],
};
</script>

<style>
@media screen and (max-width: 39em) {
  #progressbar strong {
    font-size: .825rem;
  }
  .cb-tr-container {
    padding: .5em !important;
  }
  #registration-step2-form .custom-file-label  {
    font-size: .825rem;
  }
  #registration-step2-form .custom-file-label span.form-file-text {
    visibility: hidden;
  }
  #registration-step2-form .custom-file-label span.form-file-text::before {
    content:'Upload file here'; 
  visibility: visible;
  }
   #registration-step2-form .blue-button{
     padding-left: .7em;
   }
}
</style>
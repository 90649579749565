<template>
  <div>
    <!-- <section class="container-fluid light-blue-bg">
      <div class="cb-as-seen-container py-5">
        <h5 class="text-center">Trusted by Our Partners</h5>
        <div class="row align-items-center justify-content-center pt-4">
          <div class="col-2">
            <figure class="figure p-2">
              <img class="figure-img cnn" :src="require('@/assets/imgs/home/devcon-white.png')" alt="">
            </figure>
          </div>
          <div class="col-2">
            <figure class="figure p-2">
              <img class="figure-img rappler" :src="require('@/assets/imgs/home/piche.png')" alt="">
            </figure>
          </div>
          <div class="col-2">
            <figure class="figure p-2">
              <img class="figure-img rappler" :src="require('@/assets/imgs/home/devcon.png')" alt="">
            </figure>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "TrustedPartners",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
<template>
  <div>
    <b-container fluid class="cb-learner-hero px-lg-5 py-lg-4">
      <div class="py-4 cb-learner-hero-overlay text-white text-right">
        <h1 class="text-white font-size-xl">
          Everyday,<br />everyone is a learner
        </h1>
        <p class="mb-lg-5 font-size-lg text-white">Making learning a fun adventure,<br> empowering young minds for a better future.</p>
        <a class="cb-cta" href="./signup">Get Started</a>
      </div>
    </b-container>

    <section class="section text-center py-5">
      <div class="container py-4">
        <h3 class="">Learning Made Fun and Easy</h3>
        <p class="">Search and learn all in one place.</p>
      </div>
      <b-container>
        <!-- First Row -->
        <b-row align-v="center">
          <b-col>
            <img width="350" height="350" src="../assets/global/video-tool.png" alt="Chalkboard Video Tool" />
          </b-col>
          <b-col>
            <h4 class=" mb-4 text-left">Video Tool</h4>
            <p class="text-left">
              Chalkboard is integrated with a top quality and easy-to-use video
              application.
            </p>
          </b-col>
        </b-row>
        <!-- Second Row -->
        <b-row align-v="center">
          <b-col>
            <h4 class=" mb-4 text-right">Whiteboard</h4>
            <p class="text-right">
              In every Chalkboard lesson, a whiteboard is always available as an
              interactive tool to ensure a fun and engaging learning experience.
            </p>
          </b-col>
          <b-col>
            <img width="350" height="350" src="../assets/global/whiteboard.png" alt="Chalkboard Whiteboard" />
          </b-col>
        </b-row>
        <!-- Third Row -->
        <b-row align-v="center">
          <b-col>
            <img width="350" height="350" src="../assets/global/scribook.png" alt="Chalkboard Scribook" />
          </b-col>
          <b-col>
            <h4 class=" mb-4 text-left">Scribook</h4>
            <p class="text-left">
              Keep track of your past lessons using Chalkboard’s very own online
              notebook.
            </p>
          </b-col>
        </b-row>
        <!-- Fourth Row -->
        <b-row align-v="center">
          <b-col>
            <h4 class=" mb-4 text-right">Badges</h4>
            <p class="text-right">
              Celebrate big or small wins. Collect badges as you learn.
            </p>
          </b-col>
          <b-col>
            <img width="350" height="350" src="../assets/global/badges.png" alt="Chalkboard Badges" />
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Video -->
    <section class="section text-center light-silver-bg py-5">
      <div class="container py-4">
        <h3 class=" mb-4">Meet one of our expert tutors</h3>
        <p class="mb-4">
          We want you to be comfortable with your tutors and your sessions
          online.<br />
          Take a look at Tutor Danica's lesson and decide for yourself.
        </p>

        <div class="py-4 cb-video-container">
          <video width="800" poster="../assets/global/cb_logo.png" controls>
            <source src="../assets/global/chalkboard-teaching-demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>

    <!-- Tutors Section -->
    <section class="section orange-bg text-center py-4">
      <div class="container py-4">
        <h3 class="text-white">Find Your Next Tutor</h3>
        <p class="content text-white pb-5">Expert tutors all in one place</p>
      </div>

    </section>
    <b-container class="cb-tutor-carousel">
      <VueSlickCarousel v-bind="settings">
        <div class="text-center">
          <img class=" rounded-circle w-50 mx-auto" src="../assets/global/anabel_s.jpg" alt="" />
          <div class="font-size-md my-2 font-weight-bold">
            Anabel S.
          </div>
          <div class="font-size-sm">
            Quezon National High School<br />
            Grades 1 to 6, Mathematics
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/carlita_g.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Carlita G.
          </div>
          <div class="font-size-sm">
            iACADEMY<br />
            All Grade Levels, All subjects
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/rahny_p.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Rahny P. </div>
          <div class="font-size-sm">
            La Consolacion College<br />
            Grades 7 to 12, Science
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/ronnie_l.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Ronie L.
          </div>
          <div class="font-size-sm">
            Kaong National High School<br />
            Grades 1 to 10, TLE &amp; Filipino
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/geraldine_c.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Geraldine C.
          </div>
          <div class="font-size-sm">
            Special Education Center for the Gifted<br />
            Grades 4 to 5, English
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/john_christopher_l.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            John Christopher L.
          </div>
          <div class="font-size-sm">
            University of the Philippines, Diliman<br />
            Grades 7 to 12, Filipino &amp; English
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/joseph_t.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Joseph T.
          </div>
          <div class="font-size-sm">
            Angeles University Foundation<br />
            Grades 7 to 12, Mathematics
          </div>
        </div>

        <div class="text-center">
          <img class="rounded-circle w-50 mx-auto" src="../assets/global/linart_s.jpg" alt="" />

          <div class="font-size-md my-2 font-weight-bold">
            Linart S.
          </div>
          <div class="font-size-sm">
            University of the Philippines, Diliman<br />
            Grades 7 to 12, Mathematics &amp; Science
          </div>
        </div>

      </VueSlickCarousel>
      <div class="my-4 py-4 text-center">
        <router-link to="/search?search_result=&lvlcat=" class="rounded px-4 py-3 text-white font-weight-bold orange-bg">Book Now</router-link>
      </div>

    </b-container>

  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Learners",
  metaInfo: {
    title: "Chalkboard - For Learners",
  },
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        autoplay: true,
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.font-size-xl {
  font-size: 3.5rem;
  line-height: 1;
}
.cb-learner-hero {
  min-height: 780px;
  background: url(../assets/global/Learners_Banner_2000x777.png);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cb-learner-hero-overlay {
  width: 100%;
}

.cb-video-container video {
  max-width: 100%;
  height: auto;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.cb-tutor-carousel {
  max-width: 1480px;
  margin-top: -5rem;
}
.cb-cta {
  background: white;
  color: var(--light-blue);
}
@media screen and (max-width:1224px) {
  h1 {
    font-size: 2rem;
  }
  .font-size-md {
    font-size: 1rem;
  }
  .cb-learner-hero {
  min-height: 350px;
  background: url(../assets/global/Learners_Banner_1024x398.png);
    background-size: cover;
  background-repeat: no-repeat; 
  }
  
}

@media screen and (max-width:768px) {
  .cb-learner-hero {
    display: block;
  background:  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/global/Learners_Banner_768x299.png);
  background-size: cover;
  background-repeat: no-repeat; 
  }
  .cb-cta {
    padding: .5rem 1rem;
  }
  
}
</style>
<template>
  <div>
    <section class="cb-subjects-header">
      <b-container fluid class="px-0">
        <b-row align-h="center" align-v="center" no-gutters>

          <div class="container cb-subjects-header-contents">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <b-link to="/offerings">Home</b-link>
                      </li>
                      <li class="breadcrumb-item active">
                        <b-link :to="'/course/' + this.CourseData.course_code">{{this.CourseData.course_name}}</b-link>
                      </li>
                    </ol>
                  </nav>
                  <h3 class="dark-blue">
                    {{this.CourseData.course_name}} </h3>
                  <p class="py-1 pr-5">{{this.CourseData.course_description}}</p>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-6 col-sm-0 ">

          </div>
          <div class="col-lg-6 col-sm-12">
            <img style="object-fit: cover; 
                        height: 100%;
                        width: 100%;" :src="require('@/assets/imgs/courses/' + this.CourseData.course_image)" alt="">

          </div>

        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeaderBanner",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  updated() {},
  props: ["CourseData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
  <div>
    <b-container fluid class="cb-tutor-hero px-5 py-5">
      <div class="py-5 cb-tutor-hero-overlay ">
        <h1 class="font-size-xl text-white font-weight-bold">
          Tutor Your Way
        </h1>
        <p class="font-size-lg text-white  mb-5">
         Set your own schedule, set your own rate. <br>Help learners reach their potential.
        </p>
        <a class="font-weight-bold cb-cta" href="./signup">Apply As Tutor</a>
      </div>
    </b-container>

    <b-container class="py-5 my-5">
      <b-row>
        <b-col>
          <figure class="w-25">
            <img
              class="w-100"
              src="../assets/global/build_profile.png"
              alt=""
            />
          </figure>
          <h4 class="">Build Your Profile</h4>
          <p class="is-size-5">
            Build your reputation by showcasing all your expertise, competency
            advantages, teaching certifications, affiliations, and proof of
            education.
          </p>
        </b-col>
        <b-col>
          <figure class="w-25">
            <img
              class="w-100"
              src="../assets/global/market_expertise.png"
              alt=""
            />
          </figure>
          <h4 class="">Market Your Expertise</h4>
          <p class="is-size-5">
            We provide you with a platform to market your expertise to a
            community of learners.
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <figure class="w-25">
            <img
              class="w-100"
              src="../assets/global/manage_schedule.png"
              alt=""
            />
          </figure>
          <h4 class="">Manage Your Schedule</h4>
          <p class="is-size-5">
            Tutor base on your own schedule and work anytime you want.
          </p></b-col
        >
        <b-col>
          <figure class="w-25">
            <img class="w-100" src="../assets/global/own_rate.png" alt="" />
          </figure>
          <h4 class="">Set Your Own Rate</h4>
          <p class="is-size-5">
            You decide how much is your hourly rate based on your qualifications
            and experiences. Then we will handle billings and payouts.
          </p></b-col
        >
      </b-row>
    </b-container>

    <!-- Tutor Steps -->
    <b-container>
      <h3 class=" mb-5">Becoming a Tutor <br />Is as easy as..</h3>
      <b-row>
        <b-col
          ><ul class="list-unstyled cb-tutor-steps-sticky">
            <li class="is-flex">
              <span class="cb-tutor-step-box-1"></span
              ><a class="tutor-signup-step silver" href="#tutor-signup-step"
                >Sign Up</a
              >
            </li>
            <li class="is-flex">
              <span class="cb-tutor-step-box-2"></span
              ><a class="tutor-screen-step silver" href="#tutor-screen-step"
                >Screening</a
              >
            </li>
            <li class="is-flex">
              <span class="cb-tutor-step-box-3"></span
              ><a class="tutor-verify-step silver" href="#tutor-verify-step"
                >Verification</a
              >
            </li>
            <li class="is-flex">
              <span class="cb-tutor-step-box-4"></span
              ><a class="tutor-earn-step silver" href="#tutor-earn-step"
                >Earn</a
              >
            </li>
          </ul></b-col
        >
        <b-col>
          <div
            id="tutor-signup-step"
            class="mb-6"
            data-aos-offset="250"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div class="">
              <h4 class="mb-4">Sign Up</h4>
              <p class="is-size-5 pr-6">
                A quick and easy start-up process to start building your
                profile. You can easily add information that matters to you.
              </p>
            </div>
            <div class="mb-4">
              <img src="assets/images/tutors/Sign Up.png" alt="" />
            </div>
          </div>

          <!-- Second Step -->
          <div
            id="tutor-screen-step"
            class="mb-6"
            data-aos-offset="350"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div class="">
              <h4 class="mb-4">Get Screened</h4>
              <p class="is-size-5 pr-6">
                Once you have submitted your profile, one of our Talent
                Acquisition officers will contact you for an interview and
                teaching demonstration.
              </p>
            </div>
            <div class="mb-4">
              <img src="assets/images/tutors/Get Screened.png" alt="" />
            </div>
          </div>

          <!-- Third Step -->
          <div
            id="tutor-verify-step"
            class="mb-6"
            data-aos-offset="250"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div class="">
              <h4 class="mb-4">Get Verified</h4>
              <p class="is-size-5 pr-6">
                For security purposes, help us verify your profile by uploading
                your verification documents after signing up.
              </p>
            </div>
            <div class="mb-4">
              <img src="assets/images/tutors/Get Verified.png" alt="" />
            </div>
          </div>

          <!-- Fourth Step -->
          <div
            id="tutor-earn-step"
            class="mb-6"
            data-aos-offset="350"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div class="">
              <h4 class="mb-4">Earn</h4>
              <p class="is-size-5 pr-6">
                Get online, accept bookings and start earning on your pace and
                comfort.
              </p>
            </div>
            <div class="mb-4">
              <img src="assets/images/tutors/Earn.png" alt="" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Featured Blogs Tutor
    <section class="section">
      <div class="container px-6 py-6">
        <h3 class="has-text-centered is-size-1 light-blue mb-6">
          Featured Blogs
        </h3>
        <div
          style="border-bottom: 1px solid silver"
          class="columns reverse-columns is-vcentered py-4 mb-6"
        >
          <div class="column has-text-left">
            <h4 class="dark-grey mb-4 has-text-centered-touch">
              How Online Tutorials Work
            </h4>
            <p class="is-size-5 has-text-centered-touch mb-4">
              Online learning is becoming the latest, safest, and popular type
              of distance learning. The process has redesigned the concept of
              education, and nowadays...
            </p>
            <a href="./blog/tutors/how-online-tutorials-work">
              <p
                class="is-size-5 has-text-centered-touch font-weight-bold light-blue"
              >
                Read More
              </p>
            </a>
          </div>
          <div class="column has-text-centered">
            <img
              width="400"
              height="400"
              src="../assets/global/first_blog.png"
              alt="How Online Tutorials Work"
            />
          </div>
        </div>

        <div class="columns reverse-columns is-vcentered py-4 mb-6">
          <div class="column has-text-left">
            <h4 class="dark-grey mb-4 has-text-centered-touch">
              Tips On How To Make <br />Your Online Class Engaging
            </h4>
            <p class="is-size-5 has-text-centered-touch mb-4">
              You may be teaching online for some time and are now contemplating
              how to make your class engaging. Covid-19 has ushered the
              interactive, rehabilitative, and..
            </p>
            <a
              href="./blog/tutors/tips-on-how-to-make-your-online-class-engaging"
            >
              <p
                class="is-size-5 has-text-centered-touch font-weight-bold light-blue"
              >
                Read More
              </p>
            </a>
          </div>
          <div class="column has-text-centered">
            <img
              width="400"
              height="400"
              src="../assets/global/second_blog.png"
              alt="Tips On How To Make Your Online Class Engaging"
            />
          </div>
        </div>
      </div>
    </section> -->

    <!-- Become A tutor at Chalkboard -->
    <b-container fluid class="teal-bg">
      <b-container class="py-5">
        <b-row align-v="center">
          <b-col>
            <h3 class="is-size-1 text-white">
              Becoma a Tutor<br />
              at Chalkboard
            </h3>
          </b-col>
          <b-col>
            <a class="cb-cta-fullwidth" href="./signup">Apply Now</a>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Teachers",
   metaInfo: {
    title: "Chalkboard - Become a Tutor",
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.font-size-xl {
  font-size: 3.5rem;
  line-height: 1;
}
.cb-tutor-hero {
  min-height: 780px;
  background: url(../assets/global/Tutor_Banner_2000x777.png);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cb-tutor-hero-overlay {
  width: 100%;
}

.cb-tutor-steps-sticky {
  position: sticky;
  top: 50px;
}

.cb-tutor-steps-sticky li {
  align-items: center;
}

.cb-tutor-step-box-1,
.cb-tutor-step-box-2,
.cb-tutor-step-box-3,
.cb-tutor-step-box-4 {
  height: 30px;
  width: 30px;
  background-color: var(--silver);
  border: none;
  display: inline-block;
  margin-right: 0.5rem;
  transition: all 0.5s;
}

.tutor-signup-step.active-step,
.tutor-screen-step.active-step,
.tutor-verify-step.active-step,
.tutor-earn-step.active-step {
  color: var(--light-blue);
  font-weight: 700;
}

.cb-tutor-step-box-1.active-step,
.cb-tutor-step-box-2.active-step,
.cb-tutor-step-box-3.active-step,
.cb-tutor-step-box-4.active-step {
  background-color: var(--light-blue);
}

#tutor-signup-step,
#tutor-screen-step,
#tutor-verify-step,
#tutor-earn-step {
  display: flex;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tutor-signup-step,
.tutor-screen-step,
.tutor-verify-step,
.tutor-earn-step {
  font-size: 1.7rem !important;
}


@media screen and (max-width: 1224px) {
  h1 {
    font-size: 2rem;
  }
  .font-size-md {
    font-size: 1rem;
  }
  .cb-tutor-hero {
    min-height: 398px;
    background: url(../assets/global/Tutor_Banner_1024x398.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.1rem;
  }
  .font-size-md {
    font-size: 0.8rem;
  }
  .cb-tutor-hero {
    background: url(../assets/global/Tutor_Banner_768x299.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  #tutor-signup-step,
  #tutor-screen-step,
  #tutor-verify-step,
  #tutor-earn-step {
    flex-direction: column-reverse;
    text-align: center;
  }
  #tutor-signup-step .pr-6,
  #tutor-screen-step .pr-6,
  #tutor-verify-step .pr-6,
  #tutor-earn-step .pr-6 {
    padding-right: 0rem !important;
  }
}

</style>
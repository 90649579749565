<template>
  <div style="overflow-x: initial:overflow-y: initial;">
    <div id="navbar-fp-main">
      <div v-if="isNavBarVisible && !isUserDashboard">
        <PromoBanner />
      </div>
      <b-navbar
        toggleable="lg"
        class="px-5em"
        v-if="isNavBarVisible && !isUserDashboard"
      >
        <b-navbar-brand id="navbar-logo" :to="!isRegistration ? '#' : '/home'">
          <b-img
            class="mb-1 mt-n1 img-fluid cb-logo"
            :src="require('@/assets/global/cb_logo.png')"
          ></b-img>
        </b-navbar-brand>

        <b-navbar-toggle
          target="nav-collapse"
          v-if="!isUserDashboard"
        ></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="cb-main-nav  d-flex align-items-center">
            <!-- Why Chalkboard Link -->
            <div
              class="dropdown show"
              v-if="isRegistration && !isUserDashboard"
            >
              <b-link to="/why-chalkboard/" class="nav-link" role="button">
                Why Chalkboard
              </b-link>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <b-link
                  href="/why-chalkboard/#why-choose-us"
                  class="dropdown-item"
                >
                  Why Choose Us
                </b-link>
                <b-link
                  href="/why-chalkboard/#testimonials"
                  class="dropdown-item"
                >
                  Testimonials
                </b-link>
                <a
                  class="dropdown-item"
                  href="/why-chalkboard/#what-sets-us-apart"
                >
                  What Sets Us Apart
                </a>
                <!-- How We Vet Link -->
                <b-link
                  to="/how-we-vet"
                  v-if="isRegistration && !isUserDashboard"
                  class="dropdown-item"
                >
                  How We Vet
                </b-link>
              </div>
            </div>
            <!-- Offerings -->
            <div
              class="dropdown show"
              v-if="isRegistration && !isUserDashboard"
            >
              <b-link to="/offerings" class="nav-link" role="button">
                Offerings
              </b-link>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <div class="at-dropdown">
                  <b-link
                    class="dropdown-item chevron"
                    to="/course/academic_tutoring"
                  >
                    Academic Tutoring
                  </b-link>
                  <div class="at-dropdown-content">
                    <b-link class="dropdown-item" to="/search">
                      1-on-1 Tutoring
                    </b-link>
                    <b-link class="dropdown-item" to="/group-tutoring">
                      Small Group Classes
                    </b-link>
                  </div>
                </div>
                <div class="at-dropdown">
                  <b-link class="dropdown-item chevron" to="/">Reading</b-link>
                  <div class="at-dropdown-content">
                    <b-link
                      class="dropdown-item"
                      to="/search?subjscope=Reading"
                    >
                      1-on-1 Reading Classes
                    </b-link>
                    <b-link class="dropdown-item" to="/group-tutoring">
                      Small Group Reading Classes
                    </b-link>
                  </div>
                </div>
                <b-link class="dropdown-item" to="/course/music_arts_languages">
                  Music, Arts and Languages
                </b-link>
                <b-link class="dropdown-item" to="/course/other_lessons">
                  Other Lessons
                </b-link>
                <div class="at-dropdown">
                  <b-link class="dropdown-item chevron" to="/">
                    Summer Programs
                  </b-link>
                  <div class="at-dropdown-content">
                    <b-link class="dropdown-item" to="/course/reading">
                      Reading Programs
                    </b-link>
                    <b-link class="dropdown-item" to="/learn-coding">
                      Coding Programs
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Coding Link -->
            <b-nav-item to="/learn-coding">Coding</b-nav-item>
            <b-nav-item
              class="partner-with-us-link"
              href="https://aral.chalkboard.com.ph/"
              target="_blank"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <BImg
                  class="partner-with-us-aral-logo"
                  :src="require('../assets/imgs/aral-logo.png')"
                />
                Partner with Us
              </div>
            </b-nav-item>
            <!-- Become a Tutor Link
            <b-nav-item to="/become-a-tutor" v-if="isRegistration && !isUserDashboard">Become a Tutor</b-nav-item> -->
            <!-- Offerings -->
            <div
              class="dropdown show"
              v-if="isRegistration && !isUserDashboard"
            >
              <b-link to="/contact-chalkboard" class="nav-link" role="button">
                More
              </b-link>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <b-link to="/contact-chalkboard" class="dropdown-item">
                  Contact Us
                </b-link>

                <a class="dropdown-item" href="https://chalkboard.tawk.help/">
                  Help Center
                </a>
                <a class="dropdown-item" v-b-modal.modal-1>How it works</a>

                <b-modal
                  id="modal-1"
                  hide-footer
                  hide-header
                  centered
                  size="lg"
                >
                  <div class="video-container">
                    <iframe
                      src="https://www.youtube.com/embed/Pc2oWqY_wAc"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </b-modal>
              </div>
            </div>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="cb-main-nav ml-auto">
            <b-nav-item to="/login" v-if="!this.$store.state.userIsAuthorized">
              Login
            </b-nav-item>
            <b-nav-item
              to="/signup"
              class="cb-signup-link"
              v-if="!this.$store.state.userIsAuthorized"
            >
              Sign up
            </b-nav-item>

            <!-- Menu Avatar dropdown -->
            <b-dropdown
              size="sm"
              variant="link"
              class="cb-main-avatar-dropdown avatar-dropdown d-block text-center"
              toggle-class="text-decoration-none"
              no-caret
              right
              text="Right align"
              v-if="this.$store.state.userIsAuthorized"
            >
              <template #button-content>
                <div class="d-flex align-items-center ">
                  <b-avatar
                    class="mr-2"
                    size="2em"
                    :src="currentUserData.image"
                  ></b-avatar>
                  <div class="dark-grey">
                    Hi,
                    <span class="light-blue font-weight-bold">
                      {{ currentUserData.firstname }}
                    </span>
                  </div>
                  <font-awesome-icon
                    class="light-blue mb-1 ml-2 font-size-xs"
                    :icon="['fas', 'sort-down']"
                  />
                </div>
              </template>
              <b-dropdown-header id="dropdown-header-label">
                <div class="font-size-sm">{{ currentUserDataName }}</div>
                <div class="font-size-sm">{{ currentUserData.email }}</div>
              </b-dropdown-header>
              <b-dropdown-item
                to="/dashboard"
                v-if="
                  this.$store.state.userIsAuthorized &&
                    !isUserDashboard &&
                    isRegistration
                "
              >
                <font-awesome-icon
                  class="mr-2 font-size-sm"
                  :icon="['fas', 'columns']"
                />
                Dashboard
              </b-dropdown-item>
              <b-dropdown-item to="/profile" v-if="isUserDashboard">
                <font-awesome-icon
                  class="mr-2 font-size-sm"
                  :icon="['far', 'user-circle']"
                />
                Profile
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <!-- <b-dropdown-item href="https://chalkboard.tawk.help/" v-if="isUserDashboard || !isUserDashboard || isRegistration">
              <font-awesome-icon class="mr-2 font-size-sm" :icon="['far', 'question-circle']" />Get Help
            </b-dropdown-item> -->

              <b-dropdown-item
                href="#"
                @click="logout"
                v-if="this.$store.state.userIsAuthorized"
              >
                <font-awesome-icon
                  class="mr-2 font-size-sm"
                  :icon="['fas', 'power-off']"
                />
                Logout
              </b-dropdown-item>
            </b-dropdown>
            <!-- Menu Avatar dropdown -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <!-- Dashboard Menu - Only show on Dashboard -->
      <b-navbar
        id="navbar-db-main"
        v-if="isUserDashboard"
        class="align-items-center justify-content-between bg-white"
        toggleable="lg"
      >
        <b-navbar-brand
          id="navbar-logo"
          class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"
          @click="$router.push(!isRegistration ? '#' : '/home')"
        >
          <b-img
            class="mb-1 img-fluid cb-logo"
            :src="require('@/assets/global/cb_logo.png')"
          ></b-img>
        </b-navbar-brand>
        <b-button
          class="d-md-none d-lg-none d-xl-none light-blue-bg "
          v-b-toggle.cb-mobile-menu
          v-if="isUserDashboard"
        >
          ☰
        </b-button>
        <b-sidebar id="cb-mobile-menu" no-header shadow backdrop>
          <div class="px-2 py-2">
            <b-navbar-nav class="nav ">
              <b-img
                class="mb-1 img-fluid cb-logo"
                :src="require('@/assets/global/cb_logo.png')"
              ></b-img>
              <b-nav-item disabled>
                <small>
                  {{
                    `${moment
                      .tz(currentUserData.timezone)
                      .format("ddd, LL")} (${currentUserData.region_timezone})`
                  }}
                </small>
              </b-nav-item>

              <b-nav-item
                to="/dashboard"
                :active="isCurrentRoute('/dashboard')"
                :hidden="!isUserTeacher"
              >
                <font-awesome-icon class="mr-1" icon="home" />
                Dashboard
              </b-nav-item>
              <b-nav-item
                id="nav-profile"
                to="/profile"
                :active="isCurrentRoute('/profile')"
              >
                <font-awesome-icon
                  class="mr-1"
                  :icon="['far', 'user-circle']"
                />
                Profile
              </b-nav-item>
              <b-nav-item
                to="/inbox"
                v-if="1 == 0"
                :active="isCurrentRoute('/inbox')"
              >
                <font-awesome-icon class="mr-1" :icon="['far', 'envelope']" />
                Inbox
              </b-nav-item>
              <b-nav-item
                v-b-toggle.schedule-nav
                :active="isCurrentRoute('/schedule')"
              >
                <font-awesome-icon
                  class="mr-1"
                  :icon="['far', 'calendar-alt']"
                />
                Schedule
              </b-nav-item>
              <!-- Schedule sub navs -->
              <b-collapse
                :visible="isCurrentRoute('/schedule')"
                id="schedule-nav"
                class="mt-2 px-3"
              >
                <b-nav-item
                  to="/schedule/calendar"
                  :active="isCurrentRoute('/schedule/calendar')"
                >
                  Calendar
                </b-nav-item>
                <b-nav-item
                  to="/schedule/sessions"
                  :active="isCurrentRoute('/schedule/sessions')"
                >
                  Your Sessions
                </b-nav-item>
                <b-nav-item
                  to="/schedule/requests"
                  :active="isCurrentRoute('/schedule/requests')"
                >
                  Requests
                </b-nav-item>
                <b-nav-item
                  to="/schedule/session-history"
                  :active="isCurrentRoute('/schedule/session-history')"
                >
                  Session History
                </b-nav-item>
              </b-collapse>
              <b-nav-item
                v-if="isUserParent"
                to="/parent-children"
                :active="isCurrentRoute('/parent-children')"
              >
                <font-awesome-icon class="mr-1" icon="book-reader" />
                My Children
              </b-nav-item>
              <b-nav-item
                v-if="!isUserTeacher"
                to="/search?search_result=&lvlcat="
                :active="isCurrentRoute('/search')"
              >
                <font-awesome-icon class="mr-1" icon="search" />
                Search
              </b-nav-item>
              <!-- <b-nav-item to="/history" :active="isCurrentRoute('/history')">
              <font-awesome-icon class="mr-1" :icon="['far', 'clock']" />History
            </b-nav-item> -->
              <b-nav-item
                to="/preferences"
                :active="isCurrentRoute('/preferences')"
              >
                <font-awesome-icon class="mr-1" icon="cog" />
                Preference
              </b-nav-item>

              <div class="py-4">
                <b-nav-item v-b-modal.bring-a-learner v-if="1 == 0">
                  <font-awesome-icon class="mr-1" icon="share" />Bring A Learner
                </b-nav-item>

                <b-modal
                  id="bring-a-learner"
                  hide-footer
                  header-class="p-0"
                  centered
                  size="lg"
                >
                  <template #modal-header>
                    <!-- Header -->
                    <div class="bring-modal-bg text-center p-4 w-100">
                      <div>
                        <font-awesome-icon
                          class="text-white font-size-md"
                          icon="gift"
                        />
                        <font-awesome-icon
                          class="text-white font-size-xl"
                          icon="gift"
                        />
                        <font-awesome-icon
                          class="text-white font-size-lg"
                          icon="gift"
                        />
                      </div>

                      <div class="py-4 text-center">
                        <!-- Mechanics -->
                        <div class="pb-2">
                          <div
                            class="text-white font-weight-bold font-size-xl pb-2"
                          >
                            BRING A LEARNER, GET P100
                          </div>
                          <p class="text-white  font-size-sm">
                            Hey {{ userData.firstname }}, got friends or family
                            who needs expert tutors? Invite them to sign up with
                            Chalkboard and we'll give you P100 voucher ( or P100
                            credit if you're a tutor ) when they book at least
                            one (1) session using your unique code on their
                            first transaction.
                          </p>
                        </div>

                        <!-- Unique Code -->
                        <span class="text-white pb-1">
                          <small>Your unique code:</small>
                        </span>
                        <div
                          class="bg-white d-flex align-items-center justify-content-center p-3 shadow-sm mx-auto"
                          style="border-radius: 45px; max-width: 500px"
                        >
                          <div
                            class="text-center font-size-lg light-blue font-weight-bold"
                          >
                            {{ userData.referralcode }}
                          </div>
                          <b-button
                            class="p-0 m-0 bg-white"
                            @click="copyReferralCode"
                          >
                            <font-awesome-icon
                              class="font-size-sm mx-2 light-blue"
                              :icon="['far', 'clone']"
                            />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="text-center p-4">
                    <p class=" font-size-sm">
                      If they sign up and book three (3) sessions
                      <br />
                      on their first transaction, we'll give them P100 voucher
                      too!
                    </p>

                    <!-- Other ways to signup -->
                    <span><small>Other ways to invite:</small></span>
                    <b-row class="pt-2" no-gutters>
                      <b-col sm="12" lg="7">
                        <b-input-group size="sm">
                          <template #append>
                            <b-button
                              class=" font-weight-bold "
                              style="background:#406488;padding: 0.25rem 0.5rem;"
                              @click="sendReferalCode"
                            >
                              <font-awesome-icon
                                class="font-size-sm mr-1"
                                :icon="['fas', 'envelope']"
                              />
                              Invite by Email
                            </b-button>
                          </template>
                          <b-form-input
                            v-model="referralemail"
                            placeholder="Enter email address"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                      <b-col sm="12" lg="5">
                        <b-button
                          size="sm"
                          class="font-weight-bold"
                          style="background:#40A0A0;padding: 0.30rem 0.8rem;"
                          @click="shareLink"
                        >
                          <font-awesome-icon
                            class="font-size-sm mr-1"
                            :icon="['fas', 'link']"
                          />
                          Copy Shareable Link
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-alert
                    v-model="showTop"
                    class="position-fixed fixed-top m-0 rounded-0"
                    style="z-index: 2000;"
                    variant="info"
                    dismissible
                  >
                    <b-icon
                      icon="info-circle-fill"
                      scale="2"
                      variant="info"
                      class="mr-2"
                    ></b-icon>
                    {{ topAlertMessage }}
                  </b-alert>
                </b-modal>
              </div>
            </b-navbar-nav>
          </div>
        </b-sidebar>

        <!-- Chat -->
        <div class="d-flex">
          <b-dropdown
            size=""
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
            class="chat-nav-message-button"
            v-if="$route.name !== 'messages'"
            :class="{ 'chat-red-notif': isUnreadMessages }"
          >
            <template #button-content>
              <font-awesome-icon
                class="light-blue fs-24 "
                :icon="['far', 'comment-dots']"
                @click="onNotifClick"
              />
            </template>
            <div class="text-center font-weight-medium fs-24">Messages</div>
            <b-dropdown-divider></b-dropdown-divider>
            <div class="chat-nav-message-list-wrapper">
              <ChatNotifNavMessage :currentUserData="currentUserData" />
            </div>
            <b-dropdown-divider></b-dropdown-divider>

            <div class="text-center">
              <b-link @click="onSeeAllMessages" class=" light-blue">
                See all Chat
              </b-link>
            </div>
          </b-dropdown>

          <!-- Menu Avatar dropdown -->
          <b-dropdown
            size="sm"
            variant="link"
            class="avatar-dropdown d-block text-center"
            toggle-class="text-decoration-none"
            no-caret
            right
            text="Right align"
            v-if="this.$store.state.userIsAuthorized"
          >
            <template #button-content>
              <div class="d-flex align-items-center ">
                <b-avatar
                  class="mr-2"
                  size="2em"
                  :src="currentUserData.image"
                ></b-avatar>
                <div class="dark-grey">
                  Hi,
                  <span class="light-blue font-weight-semibold">
                    {{ currentUserData.firstname }}
                  </span>
                </div>
                <font-awesome-icon
                  class="light-blue mb-1 ml-2 font-size-xs"
                  :icon="['fas', 'sort-down']"
                />
              </div>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              <div class="font-size-sm">{{ currentUserDataName }}</div>
              <div class="font-size-sm">{{ currentUserData.email }}</div>
            </b-dropdown-header>

            <b-dropdown-item to="/profile" v-if="isUserDashboard">
              <font-awesome-icon
                class="mr-2 font-size-sm"
                :icon="['far', 'user-circle']"
              />
              Profile
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              href="#"
              @click="goToTawkTo"
              v-if="isUserDashboard || !isUserDashboard || isRegistration"
            >
              <font-awesome-icon
                class="mr-2 font-size-sm"
                :icon="['far', 'question-circle']"
              />
              Get Help
            </b-dropdown-item>

            <b-dropdown-item
              href="#"
              @click="logout"
              v-if="this.$store.state.userIsAuthorized"
            >
              <font-awesome-icon
                class="mr-2 font-size-sm"
                :icon="['fas', 'power-off']"
              />
              Logout
            </b-dropdown-item>
          </b-dropdown>
          <!-- Menu Avatar dropdown -->
        </div>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import { mapState } from "vuex";
import facebook from "../mixins/social/facebook.js";
import ChatNotifNavMessage from "../components/chat/ChatNotifNavMessage.vue";

//Promo
import PromoBanner from "../components/promo/PromoBanner.vue";
import TSTAT from "../mixins/tutor-status";

import UserVouchers from "./voucher/UserVouchers.vue";

export default {
  name: "NavigationMain",
  data() {
    return {
      RouteNotRequireNav: [
        "sign_up",
        "login",
        "video-session",
        "preview-session-room",
      ],
      DashboardRoutes: [
        "dashboard",
        "profile",
        "inbox",
        "schedule",
        "calendar",
        "sessions",
        "requests",
        "session-history",
        // "history",
        "preferences",
        "parent-children",
        "messages",
        "scribook_learner",
        "scribook",
        // "search",
        // "booking",
      ],
      currentUserData: [],
      referralemail: "",
      showTop: false,
      topAlertMessage: "",
    };
  },
  components: { ChatNotifNavMessage, PromoBanner },
  computed: {
    ...mapState(["userData"]),
    isNavBarVisible() {
      return this.RouteNotRequireNav.find((e) => e === this.$route.name)
        ? false
        : true;
    },
    isUserDashboard() {
      return this.DashboardRoutes.find((e) => e === this.$route.name)
        ? true
        : false;
    },
    currentRouteName() {
      return this.$route.name;
    },
    isUserParent() {
      return this.$store.state.userData.role == "parent" ? true : false;
    },
    isUserLearner() {
      return this.$store.state.userData.role == "learner" ? true : false;
    },
    isUserTeacher() {
      return this.$store.state.userData.role == "teacher" ? true : false;
    },
    isRegistration() {
      return this.$route.name == "student_registration" ||
        this.$route.name == "parent_registration" ||
        this.$route.name == "teacher_registration"
        ? false
        : true;
    },
    currentUserDataName() {
      let uname = `${this.currentUserData.firstname || ""} ${this.getFChar(
        this.currentUserData.lastname || ""
      )}`;
      if (uname.trim() === "") uname = "";
      else uname = `${uname}.`;
      return uname;
    },
    isUnreadMessages() {
      return this.$store.getters.newNotificationMessage;
    },
  },
  methods: {
    onNotifClick() {
      this.$store.dispatch("loadChatThreads", {
        favorites_only: "",
        search_keyword: "",
      });
      if (
        this.isMobile() ||
        window.orientation !== undefined ||
        window.innerWidth < 798
      ) {
        this.$router.push("/messages");
      }
    },
    onSeeAllMessages() {
      this.$router.push({ name: "messages" }).catch(() => {});
      const chatNotifNav = document.querySelector(
        ".dropdown-menu.dropdown-menu-right"
      );
      if (chatNotifNav) {
        chatNotifNav.classList.toggle("show");
      }
      const btnToggle = document.getElementById("__BVID__37__BV_toggle_");
      if (btnToggle) {
        btnToggle.setAttribute("aria-expanded", false);
      }
      const btnRedNotif = document.getElementById("__BVID__37");
      if (btnRedNotif) {
        btnRedNotif.classList.toggle("show");
      }
    },
    logout() {
      if (this.userData.length > 0) {
        this.$store
          .dispatch("setUserChatStatus", {
            user_id: this.userData.user_id,
            status: "offline",
          })
          .then(() => {
            this.onSiteLogout();
          });
      } else {
        this.onSiteLogout();
      }
    },
    sendReferalCode() {},
    shareLink() {},
    goToTawkTo() {
      const user = this.$store.state.userData;
      const isTeacher =
        user.role === "teacher" &&
        [TSTAT.ACTIVE, TSTAT.VERIFIED].includes(user.status);
      const isLearnerParent =
        (user.role === "student" || user.role === "parent") &&
        user.status === "CSACTV";
      if (
        (isTeacher || isLearnerParent) &&
        (user.timezone === null || user.timezone === undefined) &&
        window.localStorage.getItem("userToken") != null
      ) {
        this.$router.push("/preferences?setTimezone").catch(() => {});
      } else window.location = "https://chalkboard.tawk.help/";
    },
    onSiteLogout() {
      if (this.socialAccountType().includes(this.userData.account_type))
        this.$gAuth.signOut();
      this.siteLogOut();
      this.onFacebookLogout();
    },

    showUserVouchersModal() {
      this.openAppModal(
        UserVouchers,
        {
          "show-action": false,
        },
        {
          id: "user-vouchers-modal",
          maxWidth: "550px",
        }
      );
    },
  },
  watch: {
    "$store.state.userData": function() {
      this.currentUserData = this.$store.state.userData;
      this.currentUserData.lastname = this.getFChar(
        this.currentUserData.lastname
      ).toUpperCase();
    },
  },
  mixins: [mixins, facebook],
};
</script>
<style scoped>
@import url("../assets/css/main-nav.css");
.at-dropdown {
  position: relative;
}
.at-dropdown-content {
  position: absolute;
  padding: 1em 0;
  display: none;
  top: -1em;
  left: 100%;
  z-index: 11;
  border: none;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 10px 25px 0 rgb(0 0 0 / 10%);
  width: 100%;
  min-width: calc(16.5em + 1.3vw);
}
.at-dropdown:hover .at-dropdown-content {
  display: block;
}
.chevron::after {
  content: "";
  position: absolute;
  top: 48%;
  transform: translateY(-48%) rotate(270deg);
  right: 20px;
  width: 10px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:grey;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.4s;
}

.cb-main-nav .nav-link.router-link-exact-active.router-link-active {
  color: var(--light-blue) !important;
}

.chat-nav-message-button >>> .btn.dropdown-toggle.btn-link {
  color: var(--light-blue);
}

.chat-nav-message-button >>> .btn:focus.dropdown-toggle.btn-link,
.chat-nav-message-button >>> .btn.focus.dropdown-toggle.btn-link {
  box-shadow: none;
}

.chat-red-notif {
  position: relative;
}
.chat-red-notif::after {
  content: "";
  position: absolute;
  right: 10px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 8px;
  background: #f95656;
}
.see-all-chat-link {
  color: var(--light-blue);
}

.chat-nav-name {
  color: var(--grey);
  font-size: var(--fs-one);
}
.chat-nav-name.chat-unread {
  color: var(--dark-grey);
  font-weight: 500;
}
.chat-nav-message-list-wrapper {
  padding: 0.525em 0;
  max-height: 280px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.chat-nav-message-list-wrapper::-webkit-scrollbar {
  width: 10px;
}

.chat-nav-message-list-wrapper::-webkit-scrollbar-track {
  background: var(--light-silver);
}

.chat-nav-message-list-wrapper::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
  border-radius: 10px;
}

.chat-nav-message-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}

.chat-nav-message-list-wrapper
  >>> [role="presentation"]
  [type="button"].dropdown-item.active,
.chat-nav-message-list-wrapper
  >>> [role="presentation"]
  [type="button"].dropdown-item:active {
  background-color: var(--light-silver) !important;
  outline: none;
}
.chat-nav-message-list {
  display: flex;
  align-items: center;
  padding: 0.525em 0;
}
.chat-nav-message {
  color: #9b9b9b;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-nav-message.chat-unread {
  color: var(--light-blue);
}

.chat-nav-time {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-grey);
}
.chat-nav-time.chat-unread {
  font-weight: 500;
}
.chat-dot-unread {
  display: inline-block;
  background: var(--light-blue);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}
.see-all-chat-link >>> button.dropdown-item {
  color: var(--light-blue) !important;
}

.partner-with-us-link:hover .partner-with-us-aral-logo {
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-name: partner-with-us-aral-logo-bounce;
}

/* 
  taken from css-animate's bounce keyframes, all credit belongs to the creator(s) of the animate.css package
*/
@keyframes partner-with-us-aral-logo-bounce {
  0%,
  5%,
  37%,
  85% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25%,
  27% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.1);
    transform: translate3d(0, -15px, 0) scaleY(1.1);
  }
  55% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -7px, 0) scaleY(1.05);
    transform: translate3d(0, -7px, 0) scaleY(1.05);
  }
  65% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }
  75% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}
</style>

<template>
  <div>
    <b-alert v-model="showTop" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" fade variant="info">Copied!</b-alert>
    <!-- breadcrumbs -->
    <div class="d-lg-flex  align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold ">
        View Requests
      </div>
      <div>
        <!-- Time zone -->
        <div class="d-flex">
          <div class="d-flex align-items-center font-size-sm mr-3 grey font-weight-normal">
            <div v-b-tooltip.hover title="Date and time displayed are based on your selected time zone">
              <font-awesome-icon class="font-size-xs mr-1 " :icon="['fas', 'info-circle']" />
            </div>
            Time zone:
          </div>
          <div class="font-weight-semibold font-size-sm d-flex align-items-center">
            <span class=" mr-2">{{ userData.timezone }}</span>
            <b-link to="/preferences#timezone" v-b-tooltip.hover title="Change time zone in Preference settings.">
              <font-awesome-icon class="font-size-xs mr-1 light-blue" :icon="['fas', 'pen']" />
            </b-link>
          </div>
        </div>
      </div>
    </div>

    <b-card no-body class="p-4 shadow-sm mb-4" style="border:none;">

      <b-tabs v-model="tabIndex" class="custom-tab-container" v-if="userData.role !== 'teacher'">

        <b-tab title="Accepted Requests" v-on:click.prevent="setActiveTab" lazy>
          <LearnerAcceptedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" @copyToClipboard="copyToClipboard" />
          <ParentAcceptedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Pending Requests" v-on:click.prevent="setActiveTab" lazy>
          <LearnerPendingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" @copyToClipboard="copyToClipboard"/>
          <ParentPendingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Declined Requests" v-on:click.prevent="setActiveTab" lazy>
          <LearnerDeclinedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" @copyToClipboard="copyToClipboard"/>
          <ParentDeclinedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Expired Requests" v-on:click.prevent="setActiveTab" lazy>
          <LearnerExpiredSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" @copyToClipboard="copyToClipboard"/>
          <ParentExpiredSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" @copyToClipboard="copyToClipboard"/>
        </b-tab>

      </b-tabs>

      <b-tabs v-model="tabIndex" class="custom-tab-container" v-if="userData.role === 'teacher'">
        <b-tab title="Pending Requests" v-on:click.prevent="setActiveTab" lazy>
          <TeacherPendingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Accepted Requests" v-on:click.prevent="setActiveTab" lazy>
          <TeacherAcceptedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Declined Requests" v-on:click.prevent="setActiveTab" lazy>
          <TeacherDeclinedSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <b-tab title="Expired Requests" v-on:click.prevent="setActiveTab" lazy>
          <TeacherExpiredSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" @copyToClipboard="copyToClipboard"/>
        </b-tab>

      </b-tabs>

    </b-card>

  </div>
</template>

<script>
import LearnerAcceptedSessions from "../schedule/learner/ScheduleLearnerAcceptedSessions.vue";
import ParentAcceptedSessions from "../schedule/parent/ScheduleParentAcceptedSessions.vue";
import TeacherAcceptedSessions from "../schedule/teacher/ScheduleTeacherAcceptedSessions.vue";
import LearnerPendingSessions from "../schedule/learner/ScheduleLearnerPendingSessions.vue";
import ParentPendingSessions from "../schedule/parent/ScheduleParentPendingSessions.vue";
import TeacherPendingSessions from "../schedule/teacher/ScheduleTeacherPendingSessions.vue";
import LearnerDeclinedSessions from "../schedule/learner/ScheduleLearnerDeclinedSessions.vue";
import ParentDeclinedSessions from "../schedule/parent/ScheduleParentDeclinedSessions.vue";
import TeacherDeclinedSessions from "../schedule/teacher/ScheduleTeacherDeclinedSessions.vue";
import LearnerExpiredSessions from "../schedule/learner/ScheduleLearnerExpiredSessions.vue";
import ParentExpiredSessions from "../schedule/parent/ScheduleParentExpiredSessions.vue";
import TeacherExpiredSessions from "../schedule/teacher/ScheduleTeacherExpiredSessions.vue";
import { mapState } from "vuex";
export default {
  name: "Requests",
  metaInfo: {
    title: "Chalkboard - Dashboard Requests",
  },
  components: {
    LearnerAcceptedSessions,
    ParentAcceptedSessions,
    TeacherAcceptedSessions,
    LearnerPendingSessions,
    ParentPendingSessions,
    TeacherPendingSessions,
    LearnerDeclinedSessions,
    ParentDeclinedSessions,
    TeacherDeclinedSessions,
    LearnerExpiredSessions,
    ParentExpiredSessions,
    TeacherExpiredSessions,
  },
  data: () => ({
    isLoadTriggered: false,
    tabIndex: 0,
    tabs: ["accepted", "pending", "declined", "expired"],
    selectedModalData: [],
    showTop: false
  }),
  computed: {
    ...mapState(["userData"]),
  },
  mounted() {
    if (this.userData.role === "teacher") {
      this.tabs = ["pending", "accepted", "declined", "expired"];
    }
    this.$nextTick(() => {
      this.tabIndex = this.tabs.findIndex(
        (tab) => tab === this.$route.hash.replaceAll("#", "")
      );
    });
    this.$store.dispatch('getTimeSlots', this.userData.timezone)
  },
  methods: {
    setActiveTab() {
      this.isLoadTriggered = true;
    },
    triggeredWatchSessions() {
      this.isLoadTriggered = false;
    },
    currentUserRoleIs(role) {
      return this.userData.role === role;
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      this.showTop = true;
      setTimeout(() => {
        this.showTop = false;
      }, 1000);
    }
  },
};
</script>

<style scoped>
</style>
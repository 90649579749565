<style scoped>
.legends {
  display: flex;
}
.legend-marker {
  font-size: 0.6em;
  margin-right: 35px;
  position: relative;
}
.legend-marker::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: -15px;
}
.legend-marker:nth-child(1)::before {
  background: var(--teal);
}
.legend-marker:nth-child(2)::before {
  background: var(--orange);
}

.legend-marker:nth-child(3)::before {
  background: var(--dark-blue);
}

.legend-marker:nth-child(4)::before {
  background: var(--light-blue);
}

/* Calendar Today's Session (POPOVER) */
.today-session-popover-container {
  border-left: 5px solid var(--orange);
}
.today-session-popover-btn {
  background: var(--orange);
  font-weight: 600;
  padding-left: 1em;
  padding-right: 1em;
}
.today-session-popover-btn:hover,
.today-session-popover-btn:active,
.today-session-popover-btn:focus {
  background: #fca828;
  background-color: #fca828;
  transition: background 300ms ease-in-out;
}

/* Calendar Upcoming Session (POPOVER) */
.upcoming-session-popover-container {
  border-left: 5px solid var(--teal);
}
.upcoming-session-popover-btn {
  background: none;
  border: 0.5px solid var(--teal);
  color: var(--teal);
  font-weight: 600;
  padding-left: 1em;
  padding-right: 1em;
  outline: none;
}
.upcoming-session-popover-btn:hover,
.upcoming-session-popover-btn:active,
.upcoming-session-popover-btn:focus {
  background: var(--teal);
  background-color: var(--teal);
  border-color: var(--teal);
  transition: background 300ms ease-in-out;
}

/* Calendar Pending Request (POPOVER) */
.pending-request-popover-container {
  border-left: 5px solid var(--dark-blue);
}
.pending-request-popover-btn {
  background: none;
  border: 0.5px solid var(--dark-blue);
  color: var(--dark-blue);
  font-weight: 600;
  padding-left: 1em;
  padding-right: 1em;
  outline: none;
}
.pending-request-popover-btn:hover,
.pending-request-popover-btn:active,
.pending-request-popover-btn:focus {
  background: var(--dark-blue);
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  transition: background 300ms ease-in-out;
}

/* Calendar Accepted Request (POPOVER) */
.accepted-request-popover-container {
  border-left: 5px solid var(--light-blue);
}
.accepted-request-popover-btn {
  background: none;
  border: 0.5px solid var(--light-blue);
  color: var(--light-blue);
  font-weight: 600;
  padding-left: 1em;
  padding-right: 1em;
  outline: none;
}
.accepted-request-popover-btn:hover,
.accepted-request-popover-btn:active,
.accepted-request-popover-btn:focus {
  background: var(--light-blue);
  background-color: var(--light-blue);
  border-color: var(--light-blue);
  transition: background 300ms ease-in-out;
}
</style>
<template>
  <div>
    <!-- breadcrumbs -->
    <div class="d-flex align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold">
        View Calendar
      </div>
      <!-- Time zone -->
      <div class="d-flex">
        <div class="d-flex align-items-center font-size-sm mr-3 grey font-weight-normal">
          <div v-b-tooltip.hover title="Date and time displayed are based on your selected time zone">
            <font-awesome-icon class="font-size-xs mr-1 " :icon="['fas', 'info-circle']" />
          </div>
          Time zone:
        </div>
        <div class="font-weight-semibold font-size-sm d-flex align-items-center">
          <span class=" mr-2">{{userData.timezone}}</span>
          <b-link to="/preferences#timezone" v-b-tooltip.hover title="Change time zone in Preference settings.">
            <font-awesome-icon class="font-size-xs mr-1 light-blue" :icon="['fas', 'pen']" />
          </b-link>
        </div>
      </div>
    </div>

    <!-- Legends Container -->
    <div class="shadow-sm bg-white rounded mb-4">
      <div class="font-weight-semibold d-flex align-items-center justify-content-between p-4">
        <div class="legends ml-2 ">
          <div class="legend-marker teal">
            Upcoming Sessions
          </div>
          <div class="legend-marker orange">
            Today's Sessions
          </div>
          <div class="legend-marker dark-blue">
            Pending Requests
          </div>
          <div class="legend-marker light-blue">
            Accepted Requests
          </div>
        </div>

      </div>

    </div>

    <!-- Today's Session Dataclick(POPOVER) -->
    <div ref="today_request_container" v-show="false">
      <b-card header="Today's Session" v-if="showPopOver" class="today-session-popover-container" header-class=" bg-white" no-body style="border-top: none; border-right: none; border-bottom: none;">

        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="orange"><b>Today's session</b></div>
            <button type="button" class="close font-size-sm" aria-label="Close">
              <span aria-hidden="true">&#10005;</span>
            </button>
          </div>
        </template>

        <div class="border-bottom p-3">
          <!-- Tutor image, name, session type -->
          <div class="d-flex align-items-center mb-3">
            <b-avatar size="2em" class="mr-2" :src="selectedData.image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{selectedData.name}}</div>
              <div class="font-size-xs">{{`${selectedData.session_type} Session`}}</div>
            </div>
          </div>

          <!-- Learner image, name, grade -->
          <div class="d-flex align-items-center ">
            <b-avatar size="2em" class="mr-2" :src="userData.role === 'parent' ? selectedData.child_image : userData.image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{`${ userData.role === 'parent' ? selectedData.child_name : `${userData.firstname} ${this.getFChar(userData.lastname)}.`}`}}</div>
              <div class="font-size-xs">{{selectedData.grade_level}}</div>
            </div>
          </div>
        </div>

        <div class="border-bottom p-3">
          <!-- Subject -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Subject:</div>
            <div class="font-size-sm font-weight-semibold">{{selectedData.subject}}</div>
          </div>
          <!-- Time -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Time:</div>
            <div class="font-size-sm font-weight-semibold">{{`${moment.tz(selectedData.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})`}}</div>
          </div>
        </div>
        <!-- Button -->
        <div class="text-center p-4">
          <b-button size="sm" class="today-session-popover-btn" :data-booking-id="selectedData.booking_id" :disabled="selectedData.isAfterDate">
            <font-awesome-icon class="mr-1 " :icon="['fas', 'rocket']" />
            Launch Session
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Upcoming Session Dataclick(POPOVER) -->
    <div ref="upcoming_request_container" v-show="false">
      <b-card header="Upcoming Session" v-if="showPopOver" class="upcoming-session-popover-container" header-class=" bg-white" no-body style="border-top: none; border-right: none; border-bottom: none;">

        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="teal"><b>Upcoming session</b></div>
            <button type="button" class="close font-size-sm" aria-label="Close">
              <span aria-hidden="true">&#10005;</span>
            </button>
          </div>
        </template>

        <div class="border-bottom p-3">
          <!-- Tutor image, name, session type -->
          <div class="d-flex align-items-center mb-3">
            <b-avatar size="2em" class="mr-2" :src="userData.role === 'teacher' ? userData.image : selectedData.image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{ userData.role === 'teacher' ? `${userData.firstname} ${this.getFChar(userData.lastname)}.` : selectedData.tutor_name}}</div>
              <div class="font-size-xs">{{`${selectedData.session_type} Session`}}</div>
            </div>
          </div>

          <!-- Learner image, name, grade -->
          <div class="d-flex align-items-center ">
            <b-avatar size="2em" class="mr-2" :src="userData.role === 'teacher' ? selectedData.image : userData.role === 'parent' ? selectedData.learner_image : userData.image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{ userData.role === 'teacher' ? selectedData.learner_name : userData.role === 'parent' ? selectedData.name : `${userData.firstname} ${this.getFChar(userData.lastname)}.` }}</div>
              <div class="font-size-xs">{{selectedData.grade_level}}</div>
            </div>
          </div>
        </div>

        <div class="border-bottom p-3">
          <!-- Subject -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Subject:</div>
            <div class="font-size-sm font-weight-semibold"> {{selectedData.subject}}</div>
          </div>
          <!-- Time -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Time:</div>
            <div class="font-size-sm font-weight-semibold">{{`${moment.tz(selectedData.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})`}}</div>
          </div>
          <!-- Date -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Date:</div>
            <div class="font-size-sm font-weight-semibold">{{moment.utc(selectedData.start_date).tz(userData.timezone).format('LL')}}</div>
          </div>
        </div>
        <!-- Button -->
        <!-- <div class="text-center p-4">
          <b-button size="sm" class="button-reschedule upcoming-session-popover-btn">
            <font-awesome-icon class="mr-1 " :icon="['fas', 'calendar-alt']" />
            Reschedule
          </b-button>
        </div> -->
      </b-card>
    </div>

    <!-- Pending Request Dataclick(POPOVER) -->
    <div ref="pending_request_container" v-show="false">
      <b-card header="Pending Request" v-if="showPopOver" class="pending-request-popover-container" header-class=" bg-white" no-body style="border-top: none; border-right: none; border-bottom: none;">

        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="dark-blue"><b>Pending Request</b></div>
            <button type="button" class="close font-size-sm" aria-label="Close">
              <span aria-hidden="true">&#10005;</span>
            </button>
          </div>
        </template>

        <div class="border-bottom p-3">
          <!-- Tutor image, name, session type -->
          <div class="d-flex align-items-center mb-3">
            <b-avatar size="2em" class="mr-2" :src="selectedData.tutor_image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{selectedData.tutor_name}}</div>
              <div class="font-size-xs">{{`${selectedData.session_type} Session`}}</div>
            </div>
          </div>
          <!-- Learner image, name, grade -->
          <div class="d-flex align-items-center ">
            <b-avatar size="2em" class="mr-2" :src="selectedData.learner_image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{ userData.role === 'teacher' ? selectedData.name : selectedData.learner_name}}</div>
              <div class="font-size-xs">{{selectedData.grade_level}}</div>
            </div>
          </div>
        </div>

        <div class="border-bottom p-3">
          <!-- Subject -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Subject:</div>
            <div class="font-size-sm font-weight-semibold">{{selectedData.subject}}</div>
          </div>
          <!-- Time -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Time:</div>
            <div class="font-size-sm font-weight-semibold">{{`${moment.tz(selectedData.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})`}}</div>
          </div>
          <!-- Date -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Date:</div>
            <div class="font-size-sm font-weight-semibold">{{moment.utc(selectedData.start_date).tz(userData.timezone).format('LL')}}</div>
          </div>
        </div>
        <!-- Button -->
        <div class="text-center p-4">
          <b-button size="sm" class="pending-request-popover-btn" to="/schedule/requests#pending">
            <font-awesome-icon class="mr-1" :icon="['far', 'eye']" />
            View Request
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- accepted Request Dataclick(POPOVER) -->
    <div ref="accepted_request_container" v-show="false">
      <b-card header="Accepted Request" v-if="showPopOver" class="accepted-request-popover-container" header-class=" bg-white" no-body style="border-top: none; border-right: none; border-bottom: none;">

        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="light-blue"><b>Accepted Request</b></div>
            <button type="button" class="close font-size-sm" aria-label="Close">
              <span aria-hidden="true">&#10005;</span>
            </button>
          </div>
        </template>

        <div class="border-bottom p-3">
          <!-- Tutor image, name, session type -->
          <div class="d-flex align-items-center mb-3">
            <b-avatar size="2em" class="mr-2" :src="selectedData.tutor_image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{ selectedData.tutor_name }}</div>
              <div class="font-size-xs">{{`${selectedData.session_type} Session`}}</div>
            </div>
          </div>

          <!-- Learner image, name, grade -->
          <div class="d-flex align-items-center ">
            <b-avatar size="2em" class="mr-2" :src="selectedData.learner_image"></b-avatar>
            <div>
              <div class="font-size-sm font-weight-semibold">{{selectedData.learner_name}}</div>
              <div class="font-size-xs">{{selectedData.grade_level}}</div>
            </div>
          </div>
        </div>

        <div class="border-bottom p-3">
          <!-- Subject -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Subject:</div>
            <div class="font-size-sm font-weight-semibold">{{selectedData.subject}}</div>
          </div>
          <!-- Time -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Time:</div>
            <div class="font-size-sm font-weight-semibold">{{`${moment.tz(selectedData.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})`}}</div>
          </div>
          <!-- Date -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-size-sm">Date:</div>
            <div class="font-size-sm font-weight-semibold">{{moment.utc(selectedData.start_date).tz(userData.timezone).format('LL')}}</div>
          </div>
        </div>
        <!-- Button -->
        <div class="text-center p-4">
          <b-button size="sm" class="accepted-request-popover-btn " to="/schedule/requests#accepted">
            <font-awesome-icon class="mr-1" :icon="['far', 'eye']" />
            View Request
          </b-button>
        </div>
      </b-card>
    </div>

    <b-card no-body class="p-4 shadow-sm" style="border:none;">
      <div class="dashboard-calendar">
        <div class="loading" v-if="isSessionsLoading">
          <div class="text-center text-primary my-2" style="top: 50%; margin-top: -5.05em; left: 50%; margin-left: -4em; position: fixed; font-size: 0.9rem;">
            <b-spinner class="align-middle"></b-spinner>
            <strong> please wait while data is loading...</strong>
          </div>
        </div>
        <FullCalendar :options="config">
        </FullCalendar>
      </div>
    </b-card>

  </div>
</template>

<script>
import { mapState } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Popover } from "bootstrap";
import mixins from "../../mixins";
export default {
  components: { FullCalendar },
  name: "calendar",
   metaInfo: {
    title: "Chalkboard - Dashboard Calendar",
  },
  data: () => ({
    events: [],
    selectedData: [],
    showPopOver: false,
    isPopOverLoading: false,
    isSessionsLoading: false,
    appName: process.env.VUE_APP_BACKEND_URL,
    viewRangeBefore: 180,
    viewRangeAfter: 180,
  }),
  mounted() {
    const vm = this;
    vm.loadTodaySessions();
    vm.loadUpcomingSessions();
    vm.loadPendingSessions();
    vm.loadAcceptedSessions();
  },
  computed: {
    ...mapState(["userData"]),
    config() {
      return {
        ...this.calendarOptions,
        ...this.eventHandlers,
      };
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: `${
            this.userData.role === "teacher" ? "" : "dayGridMonth,dayGridWeek"
          }`,
        },
        initialView:
          this.userData.role === "teacher" ? "dayGridWeek" : "dayGridMonth",
        events: this.events,
        eventOrder: "sort",
        dayMaxEvents: 3,
        validRange: {
          start: this.moment
            .tz(this.userData.timezone)
            .subtract(this.viewRangeBefore, "days")
            .format("YYYY-MM-DD"),
          end: this.moment
            .tz(this.userData.timezone)
            .add(this.viewRangeAfter, "days")
            .format("YYYY-MM-DD"),
        },
        now: this.moment.tz(this.userData.timezone).format('YYYY-MM-DD')
      };
    },
    eventHandlers() {
      return {
        eventDidMount: this.onEventDidMount,
        eventContent: this.onEventContent,
      };
    },
  },
  methods: {
    isAfterDate(startDate) {
      return this.moment.utc(startDate).tz(this.userData.timezone).subtract(10, 'minutes') > this.moment.tz(this.userData.timezone);
    },
    onEventContent(eventInfo) {
      return {
        html: `<div class="fc-${eventInfo.event.extendedProps.status.toLowerCase()} fc-event-custom-class">${
          eventInfo.event.extendedProps.customHTML
        }</div>`,
      };
    },
    onEventDidMount(args) {
      const vm = this;
      args.el.style.cursor = "pointer";
      const options = {
        animation: true,
        trigger: "hover",
        title: "",
        content: function () {
          return vm.$refs[
            `${args.event.extendedProps.status}_request_container`
          ].innerHTML;
        },
        html: true,
        delay: 0,
        sanitize: false,
        placement: "auto",
      };
      const myPopover = new Popover(args.el, options);
      args.el.addEventListener("mouseenter", function () {
        vm.selectedData = args.event.extendedProps.data;
        vm.$nextTick(() => {
          myPopover.show();
          document.querySelectorAll(".popover").forEach((popover) => {
            popover
              .querySelector(".close")
              .addEventListener("click", function () {
                myPopover.hide();
              });
              popover.querySelector('.today-session-popover-btn').addEventListener('click', function (e) {
                const bookingId = e.target.getAttribute('data-booking-id')
                window.localStorage.setItem("bookingId", bookingId)
                window.open('/video-session', '_blank');
              })
          });
          this.parentNode.querySelectorAll(".popover").forEach((popover) => {
            popover.addEventListener("mouseleave", function () {
              myPopover.hide();
            });
          });
        });
      });
      args.el.addEventListener("mouseleave", function () {
        var some_function = function () {
          setTimeout(function () {
            if (!document.querySelectorAll(".popover:hover").length) {
              myPopover.hide();
            } else {
              some_function();
            }
          }, 50);
        };
        some_function();
      });
    },
    loadTodaySessions() {
      const vm = this;
      vm.isSessionsLoading = true;
      vm.$store
        .dispatch("getTodaySessions", {
          params: { view: "calendar" },
          role: vm.userData.role === "teacher" ? "tutor" : "user",
        })
        .then((response) => {
          if (response.data.data.data) {
            response.data.data.data.forEach((item) => {
              item.isAfterDate = vm.isAfterDate(item.start_date);
              const name = vm.userData.role === 'parent' ? item.child_name : item.name
              vm.events.push({
                id: item.booking_id,
                start: vm.moment
                  .utc(item.start_date)
                  .tz(vm.userData.timezone)
                  .format("YYYY-MM-DD"),
                end: vm.moment.utc(item.end_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                title: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} ${name}`,
                status: "today",
                customHTML: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} <b>${name}</b>`,
                sort: parseInt(vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('H')),
                data: item,
              });
            });
          }
        })
        .finally(() => {
          this.showPopOver = true;
        });
    },
    loadUpcomingSessions() {
      const vm = this;
      vm.$store
        .dispatch("getUpcomingSessions", {
          params: { view: "calendar" },
          role: vm.userData.role === "teacher" ? "tutor" : "user",
        })
        .then((response) => {
          if (response.data.data.data) {
            response.data.data.data.forEach((item) => {
              const name =
                vm.userData.role === "teacher"
                  ? item.learner_name
                  : vm.userData.role === 'parent' ? item.name : item.tutor_name;
              vm.events.push({
                id: item.booking_id,
                start: vm.moment.utc(item.start_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                end: vm.moment.utc(item.end_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                title: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} ${name}`,
                status: "upcoming",
                customHTML: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} <b>${name}</b>`,
                sort: parseInt(vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('H')),
                data: item,
              });
            });
          }
        })
        .finally(() => {
          this.showPopOver = true;
        });
    },
    loadPendingSessions() {
      const vm = this;
      vm.$store
        .dispatch("getPendingSessions", {
          params: { is_accepted: 0, view: "calendar" },
          scope:
            vm.userData.role === "teacher"
              ? "tutor-request-list"
              : "learner-parent-request",
        })
        .then((response) => {
          if (response.data.data.data) {
            response.data.data.data.forEach((data) => {
              data.bookings.forEach((item) => {
                const name =
                  vm.userData.role === "teacher" ? item.name : vm.userData.role === 'parent' ? item.learner_name : data.tutor_name;
                item.tutor_name =
                  vm.userData.role === "teacher"
                    ? `${vm.userData.firstname} ${vm.getFChar(vm.userData.lastname)}.`
                    : data.tutor_name;
                item.tutor_image =
                  vm.userData.role === "teacher"
                    ? vm.userData.image
                    : data.tutor_image;
                vm.events.push({
                  id: item.booking_id,
                  start: vm.moment .utc(item.start_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                  end: vm.moment.utc(item.end_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                  title: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} ${name}`,
                  status: "pending",
                  customHTML: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} <b>${name}</b>`,
                  sort: parseInt(vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('H')),
                  data: item,
                });
              });
            });
          }
        })
        .finally(() => {
          this.showPopOver = true;
        });
    },
    loadAcceptedSessions() {
      const vm = this;
      vm.$store
        .dispatch("getAcceptedSessions", {
          params: { is_accepted: 1, view: "calendar" },
          scope:
            vm.userData.role === "teacher"
              ? "accepted-session-tutor"
              : "learner-parent-request",
        })
        .then((response) => {
          if (response.data.data.data) {
            const res = response.data.data.data
            res.forEach((data) => {
              data.bookings.forEach((item) => {
                const name =
                  vm.userData.role === "teacher"
                    ? item.learner_name
                    : vm.userData.role === 'parent' ? item.learner_name : data.tutor_name;
                item.tutor_name =
                  vm.userData.role === "teacher"
                    ? `${vm.userData.firstname} ${vm.getFChar(vm.userData.lastname)}.`
                    : data.tutor_name;
                item.tutor_image =
                  vm.userData.role === "teacher"
                    ? vm.userData.image
                    : data.tutor_image;
                vm.events.push({
                  id: item.booking_id,
                  start: vm.moment.utc(item.start_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                  end: vm.moment.utc(item.end_date).tz(vm.userData.timezone).format("YYYY-MM-DD"),
                  title: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} ${name}`,
                  status: "accepted",
                  customHTML: `${vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('hh:mm A')} <b>${name}</b>`,
                  sort: parseInt(vm.moment.tz(item.start_date, 'UTC').tz(vm.userData.timezone).format('H')),
                  data: item,
                });
              });
            });
          }
        })
        .finally(() => {
          this.showPopOver = true;
          vm.isSessionsLoading = false;
        });
    }
  },
  mixins: [mixins],
};
</script>

<style>
.popover-body {
  padding: 0 !important;
  width: 100%;
  min-width: 270px;
  max-width: 500px;
}
.fc-h-event {
  background-color: transparent;
}
.fc-h-event .fc-event-main {
  color: black;
}
.fc-event-custom-class {
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
  background: var(--light-white);
  padding: 5px 5px 5px 8px;
  border-radius: var(--border-radius-3);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.fc-event-custom-class > b {
  white-space: normal;
  display: block;
}
.fc-accepted.fc-event-custom-class {
  border-left: 5px solid var(--light-blue);
  background: linear-gradient(
    to right,
    rgb(0 191 224 / 5%) 50%,
    var(--light-white) 50%
  );
  background-size: 200% 100%;
  background-position: right;
  transition: all 0.35s ease-out;
}
.fc-pending.fc-event-custom-class {
  border-left: 5px solid var(--dark-blue);
  background: linear-gradient(
    to right,
    rgb(0 48 96 / 5%) 50%,
    var(--light-white) 50%
  );
  background-size: 200% 100%;
  background-position: right;
  transition: all 0.35s ease-out;
}
.fc-upcoming.fc-event-custom-class {
  border-left: 5px solid var(--teal);
  background: linear-gradient(
    to right,
    rgb(0 128 128 / 5%) 50%,
    var(--light-white) 50%
  );
  background-size: 200% 100%;
  background-position: right;
  transition: all 0.35s ease-out;
}
.fc-today.fc-event-custom-class {
  border-left: 5px solid var(--orange);
  background: linear-gradient(
    to right,
    rgb(255 189 89 / 5%) 50%,
    var(--light-white) 50%
  );
  background-size: 200% 100%;
  background-position: right;
  transition: all 0.35s ease-out;
}
.fc-accepted.fc-event-custom-class:hover,
.fc-pending.fc-event-custom-class:hover,
.fc-upcoming.fc-event-custom-class:hover,
.fc-today.fc-event-custom-class:hover {
  background-position: left;
}
</style>
<template>
  <main>
    <section class="cb-first-section">
      <div class="cb-first-section-content">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-lg-5">
              <h3>
                Offerings
              </h3>
              <p class="py-1 pr-3">Chalkboard offers on demand 1-on-1 educational programs that give learners an edge inside and outside of their class.</p>

            </div>
          </div>
        </div>
      </div>
      <img class="header-img" :src="require('@/assets/imgs/offerings/Header - Offerings.png')" alt="">

    </section>

    <section class="container">
      <div class="cb-offerings-lessons-container">
        <div><b-link to="/course/academic_tutoring">Academic Tutoring</b-link></div>
        <div><b-link to="/course/coding">Coding</b-link></div>
        <div><b-link to="/course/reading">Reading</b-link></div>
        <div><b-link to="/course/music_arts_languages">Music, Arts and Languages</b-link></div>
        <div><b-link to="/course/other_lessons">Other Lessons</b-link></div>

      </div>
    </section>

    <section class="container-fluid py-5 dark-blue-bg">
      <div class="container">
        <h6 class="text-white text-center pt-4 pb-5">Get the help that you need
          for your child with these steps.</h6>
        <div class="row  justify-content-around pb-5">
          <div class="col-sm-12 col-lg-3">
            <div class="cb-steps-container">
              <figure class="cb-steps-icon">
                <img class="img-fluid" :src="require('@/assets/imgs/offerings/Find.png')" alt="">
              </figure>
              <div class="cb-steps-desc">
                <div>Find</div>
                <p>You can easily search for tutors based on expertise, schedule, and rates.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="cb-steps-container">
              <figure class="cb-steps-icon">
                <img class="img-fluid" :src="require('@/assets/imgs/offerings/Match.png')" alt="">
              </figure>
              <div class="cb-steps-desc">
                <div>Match</div>
                <p>You then choose from the search results and send a session request to their selected tutors.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="cb-steps-container">
              <figure class="cb-steps-icon">
                <img class="img-fluid" :src="require('@/assets/imgs/offerings/Book.png')" alt="">
              </figure>
              <div class="cb-steps-desc">
                <div>Book</div>
                <p>Once the Tutor accepts the session request, you can pay via Chalkboard’s secure payment platform.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3">
            <div class="cb-steps-container">
              <figure class="cb-steps-icon">
                <img class="img-fluid" :src="require('@/assets/imgs/offerings/Learn.png')" alt="">
              </figure>
              <div class="cb-steps-desc">
                <div>Learn</div>
                <p>You can start learning using our digital tools, all integrated in our platform.</p>
              </div>
            </div>
          </div>

        </div>

        <div class="text-center pt-3 pb-5">
          <b-link to="/search" class="cb-book-now-link px-5">Book Now</b-link>

        </div>
      </div>
    </section>

    <section class="container py-5">
      <div class="row align-items-center justify-content-between">
        <div class="col-sm-12 col-lg-5">
          <figure class="figure p-2">
            <img class="figure-img img-fluid cb-offering-testimonial-img" :src="require('@/assets/imgs/testimonials/offering-testi-1.jpg')" alt="">
          </figure>

        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="cb-offering-testimonial-content">
            <figure class="cb-offering-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
            </figure>
            <blockquote> My child can get easily distracted but Teacher Wileth demonstrates great patience. Her teaching ability in reading has helped my child tremendously, and I'm hoping that this will prepare her for the next level. Thank you!</blockquote>
            <div class="cb-offering-testimonial-name">
              <p>Lionell</p>
              <p class="font-weight-bold">Chalkboard parent</p>
              <p class="font-italic">Albuquerque, New Mexico</p>
              <b-form-rating inline readonly value="5"></b-form-rating>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="light-silver-bg">
      <section class="container py-5">
        <div class="row align-items-center justify-content-between cb-second-offering-testimonial">
          <div class="col-sm-12  col-lg-6">
            <div class="cb-offering-testimonial-content">
              <figure class="cb-offering-testimonial-quotes">
                <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
              </figure>
              <blockquote> My nephew enjoys his class with Tutor John Christopher. He makes the class fun and engaging. Per my nephew, the tutor shows mastery with piano. He liked him the first time they met and after the 1st session he was able to play a song. My nephew is happy and is always looking forward to his class with Tutor John Christopher. One of the recommended tutors on the platform. Thank you tutor!</blockquote>
              <div class="cb-offering-testimonial-name">
                <p>Janelle</p>
                <p class="font-weight-bold">Chalkboard parent</p>
                <p class="font-italic">Quezon City, Philippines</p>
                <b-form-rating inline readonly value="5"></b-form-rating>

              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-5">
            <figure class="figure p-2 text-center" style="margin-top: 4rem;">
              <img class="figure-img img-fluid cb-offering-testimonial-img" :src="require('@/assets/imgs/testimonials/offering-testi-2.jpg')" alt="" style="width:50%;">
            </figure>

          </div>
        </div>
      </section>
    </div>

    <section class="container py-5">
      <div class="row align-items-center justify-content-between">
        <div class="col-sm-12 col-lg-5">
          <figure class="figure p-2">
            <img class="figure-img img-fluid cb-offering-testimonial-img" :src="require('@/assets/imgs/testimonials/offering-testi-3.jpg')" alt="">
          </figure>

        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="cb-offering-testimonial-content">
            <figure class="cb-offering-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
            </figure>
            <blockquote>My daughter liked Teacher Nhey right away! She had good things to say about her and she was very patient with her. When I signed up at Chalkboard, I wanted to see first if it will provide my daughter the support she needs to learn and like Math. I liked how she listened as I explained my daughter's needs and my expectations. She showed examples of what she can do to teach my daughter and provided clear feedback at the end of the session. I look forward to working with her this school year!</blockquote>
            <div class="cb-offering-testimonial-name">
              <p>Sol</p>
              <p class="font-weight-bold">Chalkboard parent</p>
              <p class="font-italic">Norfolk County, Massachusetts</p>
              <b-form-rating inline readonly value="5"></b-form-rating>

            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="light-blue-5-bg">
      <section class="container py-5">
        <div class="py-5">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-12 col-lg-9">
              <div class="text-center py-5">
                <h5 class="mb-4 dark-blue" style="font-weight: 900;">Explore Chalkboard</h5>
                <p class="fs-24 mb-5"> For each of our offerings, you can choose from our top-level tutors that are fit for your child's learning style and personality.
                </p>

                <b-link to="/search" class="cb-book-now-link px-5">Book Now</b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<!-- Fontawesome -->  

<script src="https://kit.fontawesome.com/e06763c731.js" crossorigin="anonymous"></script>

<script>
export default {
  name: "Offerings",
  metaInfo: {
    title: "Offerings - Online learning programs | Chalkboard",
    htmlAttrs: { lang: "en-US" },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Choose from our different learner-focused tutoring programs. Learn K-12 subjects, coding, music, and more!",
      },
      {
        name: "keywords",
        content:
          "online learning, online tutor/online tutorial, online learning platform, online math tutor, english tutors, online tutoring services, online coding tutor, python coding tutor, programming tutor, online marketplace, tutoring services, ukulele tutor, distance learning, virtual learning",
      },
    ],
  },
  mounted () {
    const payload = {
      'eventName': 'ViewContent',
      'data': {
      'content_name': "offerings_page"
      }
    };
    this.metaPixelTrack(payload);
  }
};
</script>
<style>
@import url("../assets/css/offerings.css");
</style>
import axios from 'axios'

const namespaced = false

const state = {
    scribooks: null
}

const getters = {

}

const mutations = {
    'SET_SCRIBOOK': (state, response) => state.scribooks = response,
    'NO_COMMIT': () => {}
}

const actions = {
    createScribook({commit}, payload) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/create-scribook`
        const options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
           axios.post(ApiUrl, formData, options).then(response => {
               resolve(response)
               commit('NO_COMMIT')
           })
        })
    },
    getScribook({commit}, payload) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/load-scribooks`
        const options = {
            params: payload,
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('SET_SCRIBOOK', response.data.data)
            })
        })
    },
    getScribookDetails({commit}, booking_id) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/get-scribook-details?scribook_id=${booking_id}`
        const options = {
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('SET_SCRIBOOK', response.data.data)
            })
        })
    },
    updateScribookAssessment({commit}, payload) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/update-assessment-response`
        const options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        const formData = new FormData();
        formData.append('scribook_id', payload.scribook_id)
        payload.assessment.forEach((x, index) => {
            formData.append(`assessment[${index}][question_item]`, x.question_item)
            formData.append(`assessment[${index}][answer]`, x.answer)
        })
        return new Promise((resolve) => {
            axios.post(ApiUrl, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    updateScribookOverallAssessment({commit}, payload) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/update-overall-assessment`
        const options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiUrl, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    getAssessment({commit}, scribook_id) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/load-assessment?scribook_id=${scribook_id}`
        const options = {
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    uploadTempScribookFile({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/upload-scribook-file`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    uploadScribookFile({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/${payload.upload_key}-scribook-file`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload.data).forEach((key) => { formData.append(key, payload.data[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    canSkipScribook({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/skip-scribook`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    setScribookVisibility({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/set-scribook-visibility`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    getScribookFiles({commit}, payload) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/load-scribook-files`
        const options = {
            params: payload,
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    deleteScribookFile({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/delete-scribook-file`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    getAssessmentOptions({commit}, scribook_id) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/load-assessment-options?scribook_id=${scribook_id}`
        const options = {
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    validateScribookFile({commit}, scribook_id) {
        const ApiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/validate-scribook-file?scribook_id=${scribook_id}`
        const options = {
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`
            }
        }
        return new Promise((resolve) => {
            axios.get(ApiUrl, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    completeScribook({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/complete-scribook`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    uploadScribookImage({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/${payload.upload_key}-scribook-image`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
        };
        const formData = new FormData();
        Object.keys(payload.data).forEach((key) => { formData.append(key, payload.data[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    },
    downloadScribookPrintOut({commit}, payload) {
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/scribook/download-scribook-printout`,
        options = {
            headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            },
            responseType: 'blob'
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => { formData.append(key, payload[key]); });
        return new Promise((resolve) => {
            axios.post(ApiURL, formData, options).then(response => {
                resolve(response)
                commit('NO_COMMIT')
            })
        })
    }
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
export default {
  data: () => ({
    accountType: 'FBACNT',
    fbIsInit: false
  }),
  mounted () {},
  methods: {
    onFacebookLoginClick () {
      const vm = this
      window.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          if (response.authResponse)
            window.FB.api('/me', {locale: 'en_US', fields: 'email'}, function(data) {
              if ((data.email || '') === '') {
                vm.ShowModal('Chalkboard cannot access your Facebook email address.', null, 'Facebook Sign Up')
              } else {
                vm.onFacebookLogin(data.email)
              }
            });
        } else {
          window.FB.login(response => {
            if (response.authResponse)
              window.FB.api('/me', {locale: 'en_US', fields: 'email'}, function(data) {
                if ((data.email || '') === '') {
                  vm.ShowModal('Chalkboard cannot access your Facebook email address.', null, 'Facebook Sign Up')
                } else {
                  vm.onFacebookLogin(data.email)
                }
              });
          }, { scope: 'user_birthday' })
        }
      });
    },
    async onFacebookLogin (email) {
      const loginFormData = new FormData();
      loginFormData.append("email", email);
      const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/authenticate/login-social-media',
            options = {
              headers: {
                "Accept": "application/json, text/plain, */*",
              }
            }
      const login_response = await this.axios.post(ApiURL, loginFormData, options)
      if (login_response.data.status === 200) {
        const userToken = login_response.data.data.api_token
        window.localStorage.setItem("userToken", userToken);
        this.$store.state.userToken = userToken
        this.UserToken = userToken;
        this.$store.state.userIsAuthorized = true;
        this.$store.dispatch("getUserData", userToken).then(() => {
          this.autoRedirectIfLogged()
        });
      } else if (login_response.data.status === 422) {
        this.ShowModal(login_response.data.message, null, 'Facebook Log In')
        this.onFacebookLogout()
      }
    },
    async onFacebookSignIn (role) {
      const vm = this
      try {
        if (!role) {
          this.ShowModal('Please select user role.')
          return
        }
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            vm.getFacebookUserDetails(role)
          } else {
            window.FB.login(response => {
              if (response.authResponse) {
                vm.getFacebookUserDetails(role)
              }
            }, { scope: 'public_profile, email, user_birthday' })
          }
        })
      } catch (error) { return null }
    },
    async getFacebookUserDetails (role) {
      const vm = this
      window.FB.api('/me', {locale: 'en_US', fields: 'first_name,last_name,email,picture,birthday'}, function (data) {

        const signUpParams = {
          birthday: vm.moment(`${data.birthday}`).format('YYYY-MM-DD'),
          firstname: data.first_name,
          lastname: data.last_name,
          email: data.email,
          role: role,
          account_type: vm.accountType,
        }
        
        if ((data.email || '') === '') {
          vm.ShowModal('Chalkboard cannot access your Facebook email address.', null, 'Facebook Sign Up')
          return
        }

        const formData = new FormData();
        Object.keys(signUpParams).forEach(key => { formData.append(key, signUpParams[key]) })

        vm.axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/web/authenticate/signup-social-media`, formData).then(response => {

          if (response.data.status === 200) {
            vm.ShowModal('Thank you for your registration.', async () => {
              vm.onFacebookLogin(signUpParams.email)
            })
          } else {
            if (response.data.status === 422) {
              vm.ShowModal(response.data.message, null, 'Facebook Sign Up')
              vm.onFacebookLogout()
            }
          }

        })
        
      })

    },
    onFacebookLogout() {
      window.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          window.FB.logout(function() {
            window.FB.Auth.setAuthResponse(null, 'unknown');
          });
        }
      })
    }
  }
}
<template>
  <div>
    <!-- breadcrumbs -->
    <div class="d-lg-flex  align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold ">
        View Session History
      </div>
      <div>
        <!-- Time zone -->
        <div class="d-flex">
          <div class="d-flex align-items-center font-size-sm mr-3 grey font-weight-normal">
            <div v-b-tooltip.hover title="Date and time displayed are based on your selected time zone">
              <font-awesome-icon class="font-size-xs mr-1 " :icon="['fas', 'info-circle']" />
            </div>
            Time zone:
          </div>
          <div class="font-weight-semibold font-size-sm d-flex align-items-center">
            <span class=" mr-2">{{ userData.timezone }}</span>
            <b-link to="/preferences#timezone" v-b-tooltip.hover title="Change time zone in Preference settings.">
              <font-awesome-icon class="font-size-xs mr-1 light-blue" :icon="['fas', 'pen']" />
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <b-card no-body class="p-4 shadow-sm mb-4" style="border:none;">
      <b-table class="default-table-css font-size-sm" :items="reviews" :fields="fields" responsive :busy="isBusy" show-empty empty-text="No history of ratings and reviews yet.">

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> please wait while data is loading...</strong>
          </div>
        </template>

        <!-- Reviewed By -->
        <template #cell(reviewed_by)="data">
          <div class="d-flex align-items-center pb-1" v-b-tooltip.hover title="Parent" v-if="data.item.reviewer_data[0].parent_name !== null" style="cursor: default;">
            <b-avatar size="1.7rem" class="mr-1" :src="data.item.reviewer_data[0].parent_image" ></b-avatar>
            <div>
              <div class="mb-n1 dark-silver" style="font-size: 12px;font-weight: 500;"> Parent </div>
              <span> {{ data.item.reviewer_data[0].parent_name }} </span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-avatar size="1.7rem" class="mr-1" :src="data.item.reviewer_data[0].learner_image"></b-avatar>
            <div>
              <div class="mb-n1 dark-silver" style="font-size: 12px;font-weight: 500;"> Learner </div>
              <span> {{ data.item.reviewer_data[0].learner_name }} </span>
            </div>
          </div>

        </template>

        <!-- review buttons -->
        <template #cell(review)="data">
          <div>
            <span v-if="data.item.rating == null && data.item.isSevenDays == 0" v-b-tooltip.hover title="Session has not been rated yet" class="orange px-3 py-1 d-inline-flex text-center" style="background: #FFF3E2;">No Review Yet</span>

            <!-- Edit Star Rating -->
            <div class="d-flex align-items-center ">

              <b-form-rating v-if="data.item.rating > 0" :value="data.item.rating" class="font-size-xs px-2" icon-empty="star-fill" variant="warning" no-border inline readonly>
              </b-form-rating>

              <div v-if="data.item.rating > 0">
                <!-- View Rating -->
                <button v-b-tooltip.hover @click="viewRatings(data.item)" title="View review" style="background:transparent;border:none; outline: none;">
                  <font-awesome-icon class="font-size-md silver-bg" style="height:25px;width:25px;border-radius: 50%;padding: .35rem;vertical-align: middle;" :icon="['fas', 'eye']" />
                </button>
              </div>
            </div>

            <!-- No review -->
            <span v-if="data.item.isSevenDays == 1 && data.item.rating == null" v-b-tooltip.hover title="A session may only be rated and reviewed within seven days from session date" class="dark-silver  table-no-review-text">No Review</span>
          </div>
        </template>

        <template #cell(action)="data">
          <!-- Add/Edit Scribook -->

          <!-- Change tooltip to 'Update Scribook (Draft)' if entry is not yet submitted -->
          <!-- Change tooltip to 'Edit Scribook for this session' if scribook entry has been submitted -->
          
          <div class="position-relative">
            <b-button v-if="canCreateScribook(data.item)" class="table-scribook-btn " size="sm" :title="setScribookButtonTitle(data.item)" v-b-tooltip.hover @click="onCreateScribookEntry(data.item)">
              <font-awesome-icon class="mr-1" :icon="['fas', 'book']" />
              Scribook
            </b-button>
            
            <!-- View Scribook -->
            <b-button v-if="canViewScribook(data.item)" class="table-scribook-btn view" size="sm" title="View Scribook" v-b-tooltip.hover @click="onViewScribookEntry(data.item)">
              <font-awesome-icon class="mr-1" :icon="['fas', 'book']" />
              Scribook
            </b-button>

            <!-- Only show when Scribook entry is required -->
            <div v-if="data.item.scribook_required || data.item.scribook_required == 1" class="position-absolute" style="top:50%; right:-1.1em;transform:translateY(-50%);" title="Scribook entry is required" v-b-tooltip.hover>
              <font-awesome-icon class=" red" style="font-size: 12px;" :icon="['fas', 'exclamation-triangle']" />
            </div>
          </div>

          <!-- Disabled Scribook -->
          <div v-if="!canCreateScribook(data.item) && data.item.scribook_id === null" title="You can no longer create a Scribook entry 48 hours after the session is completed" v-b-tooltip.hover>
            <b-button class="table-scribook-btn disabled" disabled size="sm" @click="onViewScribook(data.item)">
              <font-awesome-icon class="mr-1" :icon="['fas', 'book']" />
              Scribook
            </b-button>
          </div>
          
          <!-- Edit Scribook -->
          <!-- Show only when Scribook is editable -->
          <!-- <b-button class="table-view-details-btn" size="sm" title="Edit Scribook Entry" v-b-tooltip.hover v-if="1==0">
            <font-awesome-icon class="" :icon="['fas', 'pen']" />
          </b-button> -->
        </template>
        <template #cell(more_action)="data">
          <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="reset-padding trans-section">
            <template #button-content>
              <b-button class="table-view-details-btn" size="sm" title="View Details" v-b-tooltip.hover v-b-modal.view-details-modal @click="selectedData = data.item.data">
                <font-awesome-icon class="" :icon="['fas', 'ellipsis-v']" />
              </b-button>
            </template>
            <b-dropdown-item>
              <div class="relative-div font-size-xs">
                Booking Id: <span class="ml-2 font-weight-semibold">
                  <!-- Booking Id -->
                  {{ data.item.booking_id }}
                </span>
                <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0; right: -1.5rem!important;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="relative-div font-size-xs">
                Transaction Id: <span class="ml-2 font-weight-semibold">
                  <!-- Transaction Id -->
                  {{ data.item.transaction_id }}
                </span>
                <b-button @click="onCopy(data.item.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0; right: -1.5rem!important;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>
            </b-dropdown-item>
            <b-dropdown-item>

              <div class="relative-div font-size-xs">
                Session Type: <span class="ml-2 font-weight-semibold">
                  <!-- Session -->
                  {{ data.item.session_type }}
                </span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination v-if="reviews.length > 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" class="p-3"></b-pagination>
    </b-card>
    <RatingsAndReviews v-if="isRatingsAndReviews" :modalToShow="'Report'" :ratingsAndReviewsData="selectedData" @onReportOkClicked="onReportOkClicked"></RatingsAndReviews>
    <ScribookCreationModal v-if="isShowScribookCreationModal" :scribookData="scribookData" :isAllowedFromSessionHistory="true" @onScribookCreationModalClose="onScribookCreationModalClose"/>
    <ScribookSidebarQuestionnaire :data="selectedScribook" :scribookData="scribookData" :isViewScribook="true" :isViewScribookSidebar="true" @reloadScribook="onReloadScribook" v-if="isShowQuestionnaireModal" @onModalClose="onModalClose"/>
  </div>

</template>

<script>
import mixins from "../../../mixins";
import RatingsAndReviews from "../../review/RatingsAndReviews.vue";
import ScribookCreationModal from '../../scribook/ScribookCreationModal.vue'
import ScribookSidebarQuestionnaire from '../../scribook/ScribookSidebarQuestionnaire.vue'
import { mapState } from 'vuex';
export default {
  components: {
    RatingsAndReviews,
    ScribookCreationModal,
    ScribookSidebarQuestionnaire
  },
  name: "SessionHistoryTeacher",
  data() {
    return {
      fields: [
        { key: "date", sortable: true },
        { key: "grade_level", label: "Level/Category" },
        { key: "subject", label: "Subject/Lesson" },
        "reviewed_by",
        "status",
        { key: "review", label: "" },
        { key: "action", label: "Action", class: "text-center" },
        { key: "more_action", label: "", class: "pl-4" },
      ],
      reviews: [],
      isBusy: false,
      isRatingsAndReviews: false,
      selectedData: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      guestTZ: null,
      regionTzName: null,
      isShowScribookCreationModal: false,
      scribookData: [],
      isShowQuestionnaireModal: false,
      selectedScribook: {}
    };
  },
  computed: {
    ...mapState(["userData"])
  },
  mounted() {
    const vm = this;
    vm.$watch(
      "$store.state.userData",
      (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
        vm.regionTzName =
          data.timezone == null
            ? vm.getTzRegion(vm.guestTz)
            : data.region_timezone;
      },
      { immediate: true }
    );
    this.getRatingsAndReviews();
  },
  methods: {
    onScribookViewModalClose() {
      this.isShowScribookViewModal = false;
    },
    onScribookCreationModalClose() {
      this.isShowScribookCreationModal = false;
      this.getRatingsAndReviews();
    },
    isScribookAllowCreate(data) {
      return Boolean(data.scribook_allow_create) && data.scribook_id === null
    },
    isScribookAllowEdit(data) {
      return Boolean(data.scribook_allow_edit) && data.scribook_id !== null && data.scribook_status === 'CMPLTE'
    },
    onCreateScribookEntry (data) {
      this.setScribookData(data)
      if (this.isScribookAllowCreate(data) || (!this.isScribookAllowCreate(data) && !this.isScribookAllowEdit(data))) {
        this.isShowScribookCreationModal = true
      } else if (!this.isScribookAllowCreate(data) && this.isScribookAllowEdit(data)) {
        this.onViewScribookEntry(data)
      }
    },
    async onViewScribookEntry(data) {
      this.setScribookData(data)
      this.onReloadScribook(data)
    },
    setScribookData(data) {
      this.scribookData = {
        scribook: {
          scribook_id: data.scribook_id,
          scribook_allow_edit: data.scribook_allow_edit,
          scribook_status: data.scribook_status
        },
        bookings: {
          booking_id: data.booking_id,
          subject: data.subject,
          grade_level: data.grade_level,
          start_date: data.start_date,
        },
        learner: {
          name: data.reviewer_data[0].learner_name,
          firstname: data.reviewer_data[0].learner_name.split(" ")[data.reviewer_data[0].learner_name.split(" ").length - 2],
          image: data.image || data.learner_image || data.reviewer_data[0]?.learner_image,
        },
        users: {
          id: data.user_id,
          role: data.role
        }
      }
    },
    setScribookButtonTitle(data) {
      let title = ''
      if (this.isScribookAllowCreate(data)) {
        title = 'Add Scribook for this session'
      } else if (!this.isScribookAllowCreate(data) && !this.isScribookAllowEdit(data)) {
        title = 'Update Scribook (Draft)'
      } else if (!this.isScribookAllowCreate(data) && this.isScribookAllowEdit(data)) {
        title = 'Edit Scribook for this session'
      }
      return title;
    },
    canCreateScribook(data) {
      const isAllowCreate = Boolean(data.scribook_allow_create),
            isAllowEdit = Boolean(data.scribook_allow_edit)
      return (isAllowCreate || (!isAllowCreate && isAllowEdit))
    },
    canViewScribook(data) {
      const isAllowCreate = Boolean(data.scribook_allow_create),
            isAllowEdit = Boolean(data.scribook_allow_edit)
      return (!isAllowCreate && !isAllowEdit && data.scribook_id !== null) 
    },
    onReportOkClicked() {
      this.isRatingsAndReviews = false;
    },
    viewRatings(data) {
      this.selectedData = data;
      this.isRatingsAndReviews = true;
    },
    async getRatingsAndReviews() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        `/api/web/review/review-list-teacher?count=${this.perPage}&page=${this.currentPage}`;
      const options = {
        headers: this.getGetRequestHeaders(),
      };
      this.isBusy = true;
      await this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;
          this.totalRows = _data.total;
          this.reviews = _data.data.map((item) => {
            return {
              tutor_id: item.tutor_id,
              review_id: item.id,
              booking_id: item.booking_id,
              transaction_id: item.transaction_id,
              date: this.moment
                .utc(item.start_date)
                .tz(this.guestTZ)
                .format("dddd, LL"),
              time: `${this.moment
                .tz(item.start_date, "UTC")
                .tz(this.guestTZ)
                .format("hh:mm A")} - ${this.moment(
                this.moment.tz(item.start_date, "UTC").tz(this.guestTZ)
              )
                .add(1, "hours")
                .format("hh:mm A")} (${this.regionTzName})`,
              subject: item.subject,
              tutor_name: item.firstname + " " + item.lastname,
              status: item.status,
              rating: item.rating,
              feedback: item.review,
              recommendation: item.recommendation,
              remarks: item.remarks,
              image: item.image,
              reviewer_data: item.reviewer_data,
              grade_level: item.grade_level,
              isEdited: item.isEdited,
              isSevenDays: item.isSevenDays,
              session_type: item.session_type,
              learner_name: `${item.learner_firstname} ${item.learner_lastname}`,
              learner_firstname: item.learner_firstname,
              learner_lastname: item.learner_lastname,
              learner_image: item.image,
              start_date: item.start_date,
              scribook_id: item.scribook_id,
              scribook_allow_edit: item.scribook_allow_edit,
              scribook_allow_create: item.scribook_allow_create,
              role: item.role,
              user_id: item.user_id,
              scribook_status: item.scribook_status,
              scribook_required: item.scribook_required
            };
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.isBusy = false;
        });
    },
    onCopy(text) {
      this.$emit("copyToClipboard", text);
    },
    onModalClose() {
      this.isShowQuestionnaireModal = false;
    },
    async isScribookRequired(bookingId) {
      let isRequired = false
      const response = await this.$store.dispatch("canSkipScribook", { booking_id: bookingId });
      isRequired = response.data.status === 422 ? true : false;
      return isRequired
    },
    async onReloadScribook(data) {
      const response = await this.$store.dispatch('getScribookDetails', data.scribook_id)
      if (response.data.status === 200) {
        const item = response.data.data.data[0]
        this.selectedScribook = {
          scribook_id: item.scribook_id,
          title: item.subject,
          learner: {
            name: item.learner_name,
            firstname: item.learner_firstname,
            lastname: item.learner_lastname,
            image: item.learner_image
          },
          tutor: {
            id: item.tutor_id,
            name: item.teacher_name,
            firstname: item.tutor_firstname,
            lastname: item.tutor_lastname,
            image: item.teacher_image
          },
          parent: {
            child_id: item.child_id,
          },
          bookings: {
            booking_id: item.booking_id,
            note: item.note,
            account_type: item.account_type,
            grade_level: item.grade_level,
            subject: item.subject,
            child_id: item.child_id,
            start_date: item.start_date
          },
          createdAt: item.created_at,
          topic: item.topic,
          entryType: item.scribook_type,
          subjectCardPills: [
            { title: item.subject, value: "", isOpGrade: false },
            {
              title: item.assessment_title,
              value: item.overall_assessment,
              isOpGrade: true,
            },
          ],
          questionnaire: item.questionnaire.map((q) => {
            return {
              answer: q.answer,
              question: q.question,
            };
          }),
          attachments: item.attachments,
          overall_assessment: item.overall_assessment,
          overall_assessment_title: item.assessment_title,
          image_file_name: item.image_file_name,
          image_server_name: item.image_server_name ? `${process.env.VUE_APP_BACKEND_URL}/${item.image_server_name}` : null
        }
        this.isShowQuestionnaireModal = true
      }
    }
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getRatingsAndReviews().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link {
  font-size: 2rem;
  font-weight: 700;
}
.nav.nav-tabs {
  background: var(--silver);
}
.b-rating.form-control:focus {
  box-shadow: none;
}
</style>
<template>
  <div class="chat-bubble-head-container">
    <ul class="list-unstyled" ref="bch-container">
      <li><b-spinner small></b-spinner></li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import mixins from '../../mixins';
  import ChatHead from './ChatHead.vue'
  import Vue from 'vue'
  export default {
    data: () => ({
      items: [],
      maxChatBubbleHead: 3
    }),
    computed: {
      ...mapState(['userData', 'chatThreads'])
    },
    components: {},
    beforeMount() {},
    mounted() {
      
      this.$watch('$store.state.chatThreads', (val) => {
        this.items = val.map(x => {
          return {
            'thread_id': x.id,
            'created_at': x.created_at,
            'data': x.chat_participants.filter(x => { return x.user_id !== this.userData.user_id})[0],
            'is_read': x.chat_participants.filter(x => { return x.user_id === this.userData.user_id})[0]?.last_read !== null ? true : false,
            'sortH': this.moment(x.messages[0]?.created_at || x.created_at).format('MM-DD-YYYY HH:mm:ss'),
            'is_active': x.is_active
          }
        }).sort((a, b) => new Date(b.sortH) - new Date(a.sortH))
        this.items.forEach(x => {
          this.onListenIncomingMessage(x.thread_id, x.data.user_id)
        })
      })

      const watchUserData = this.$watch('userData', () => {
        this.$store.dispatch('loadChatThreads', { favorites_only: '', search_keyword: '' }).then(response => {
          const obj = response.data.data.map(x => {
            return {
              'thread_id': x.id,
              'created_at': x.created_at,
              'data': x.chat_participants.filter(x => { return x.user_id !== this.userData.user_id})[0],
              'is_read': x.chat_participants.filter(x => { return x.user_id === this.userData.user_id})[0]?.last_read !== null ? true : false,
              'sortH': this.moment(x.messages[0]?.created_at || x.created_at).format('MM-DD-YYYY HH:mm:ss'),
              'is_active': x.is_active
            }
          }).sort((a, b) => new Date(b.sortH) - new Date(a.sortH)).slice(0, this.maxChatBubbleHead)
          this.$refs['bch-container'].innerHTML = ''
          obj.forEach((x) => {
            var ComponentClass = Vue.extend(ChatHead)
            var instance = new ComponentClass({
                propsData: { data: x, userData: this.userData }
            })
            instance.$mount()
            this.$refs['bch-container'].appendChild(instance.$el)
          })
        }).finally(() => { watchUserData() })
      })

      this.$watch('$store.state.selectedBubbleChatHead', (val) => {
        if (val.length !== 0) {
          const ChatBubbleHeads = document.querySelectorAll('[data-id*="bubble_head_"]')
          if (ChatBubbleHeads && ChatBubbleHeads.length === 3) {
            document.querySelectorAll('[data-id*="bubble_head_"]')[0].remove()
          }
          var ComponentClass = Vue.extend(ChatHead)
          var instance = new ComponentClass({
              propsData: { data: val, userData: this.userData }
          })
          instance.$mount()
          this.$refs['bch-container'].appendChild(instance.$el)
        }
      })

    },
    methods: {
      onListenIncomingMessage(thread_id, user_id) {
        window.Echo.private(`send-chat-message.${this.userData.user_id}.${user_id}`)
          .listen('.Modules\\Message\\Events\\Web\\SendChatMessage', () => {
            const result = this.items.find(x => x.thread_id === thread_id)
            if (result !== 'undefined') {
              const index = this.items.indexOf(result)
              this.items[index].is_read = false
              this.$store.state.newNotificationMessage = true
            }
          })
      }
    },
    mixins: [mixins]
  }
</script>

<style scoped>
.chat-bubble-head-container {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
</style>
<template>
  <div>
    <div class="text-center my-3"></div>
    <FullCalendar ref="fullCalendar" :options="config" />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import mixins from "../mixins";
import { mapState } from "vuex";
export default {
  components: { FullCalendar },
  name: "full-calendar",
  props: ["teacherData", "selectedTz", "meridiem"],
  data: () => ({
    events: [],
    guestTZ: null,
    timeSlots: [],
  }),
  computed: {
    ...mapState(["userData"]),
    config() {
      return {
        ...this.calendarOptions,
      };
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "",
        },
        validRange: {
          // start: firstEventDate.value,
          end: this.moment.utc("2024-09-16 00:00:00", "YYYY-MM-DD HH:mm:ss"),
        },
        initialView: "dayGridWeek",
        eventOrder: "sort",
        events: this.events,
        editable: false,
        selectable: true,
        selectMirror: true,
        weekends: true,
        now: this.moment.tz(this.userData.timezone).format("YYYY-MM-DD"),
      };
    },
  },
  created() {},
  mounted() {
    const vm = this;
    vm.$watch(
      (_) => [_.teacherData, _.selectedTz, _.meridiem],
      () => {
        vm.guestTZ = vm.selectedTz == null ? vm.guestTz : vm.selectedTz;
        vm.guestTZ = vm.guestTZ == "" ? vm.guestTz : vm.guestTZ;
        if (vm.teacherData.user_id != undefined) {
          vm.$watch("$store.state.timeSlots", () => {
            vm.getCalendarItems();
          });
          vm.getCalendarItems();
        }
      },
      { immediate: true }
    );
    document
      .querySelectorAll(".fc-prev-button, .fc-next-button")
      .forEach((button) => {
        button.addEventListener("click", () => {
          vm.getCalendarItems();
        });
      });
  },
  methods: {
    async getCalendarItems() {
      let schedule = this.teacherData.schedule;

      schedule = schedule.map((x) => {
        const xSelectedDate = this.moment(
          this.$refs.fullCalendar.getApi().view.activeStart
        ).day(x.day);
        const UTCDate = this.moment
          .tz(`${xSelectedDate.format("YYYY-MM-DD")} ${x.start_time}`, "UTC")
          .tz(this.guestTZ);
        return {
          day: UTCDate.format("dddd"),
          start_time: UTCDate.format("HH:mm:ss"),
          end_time: this.moment(UTCDate)
            .add(1, "hours")
            .format("HH:mm:ss"),
          utc_start_time: x.start_time,
        };
      });

      this.events = [];
      if (this.$refs.fullCalendar != undefined) {
        let calendarApi = this.$refs.fullCalendar.getApi();
        const activeStart = calendarApi.view.activeStart;
        const activeEnd = calendarApi.view.activeEnd;
        const currentMoment = this.moment(activeStart);
        const endMoment = this.moment(activeEnd);
        while (
          currentMoment.isBefore(endMoment, "day") &&
          currentMoment.isBefore(
            this.moment.tz(
              "2024-09-16 00:00:00",
              "YYYY-MM-DD HH:mm:ss",
              "Asia/Manila"
            )
          )
        ) {
          try {
            schedule
              .filter((item) => item.day == currentMoment.format("dddd"))
              .forEach((element) => {
                let currentDate = this.moment(
                  `${currentMoment.format("YYYY-MM-DD")} ${element.start_time}`
                );

                if (
                  this.$store.state.timeSlots.filter((x) => {
                    return x.start_time === element.utc_start_time;
                  }).length === 0
                ) {
                  return;
                }

                const events = {
                  date: currentDate.format("YYYY-MM-DD"),
                  title: currentDate.format("hh:mm A"),
                  sort: parseInt(currentDate.format("H")),
                };

                if (
                  this.meridiem !== null &&
                  currentDate.format("A") === this.meridiem
                ) {
                  this.events.push(events);
                }
              });
          } catch {
            //
          }
          currentMoment.add(1, "days");
        }
      }
    },
  },
  mixins: [mixins],
};
</script>

<style>
/* Tooltip container */
.custom-tooltip {
  position: relative;
  border-bottom: 1px dotted black;
}

.custom-tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 999;
  bottom: 27px;
  left: 50%;
  margin-left: -60px;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
}

.fc-prev-button.fc-button,
.fc-next-button.fc-button {
  margin: 5px;
  border: none;
}
.fc .fc-button-primary {
  background: var(--light-blue);
  box-shadow: none;
}
.fc .fc-button-primary:hover {
  background: var(--light-blue) !important;
}

.fc .fc-button-primary:active,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:focus {
  background-color: var(--light-blue);
  box-shadow: none;
  border: none;
}
.fc-event-title-container {
  text-align: center;
}
.fc-col-header-cell-cushion {
  text-align: center;
  color: var(--dark-grey);
}
.fc .fc-daygrid-event {
  margin-top: 15px;
}
.fc-h-event {
  background: var(--light-blue);
  border: none;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 5px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 5px;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 5px;
}
</style>

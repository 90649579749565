<template>
  <div>
    <LearnerProfile v-if="isUserLearner" />
    <ParentProfile v-if="isUserParent" />
    <TeacherProfile v-if="isUserTeacher" />
  </div>
</template>

<script>
import mixins from "../../mixins";
import LearnerProfile from "../../components/profile/learner/ProfileLearner.vue";
import ParentProfile from "../../components/profile/parent/ProfileParent.vue";
import TeacherProfile from "../../components/profile/teacher/ProfileTeacher.vue";

export default {
  name: "Profile",

  metaInfo: {
    title: "Chalkboard - Dashboard Profile"
  },

  components: {
    LearnerProfile,
    ParentProfile,
    TeacherProfile
  },

  data() {
    return {
      form: {
        birthday: "",
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        street: "",
        brgy: "",
        city: "",
        province: "",
        zip_code: ""
      },
      ProfileName: "",
      UserToken: window.localStorage.getItem("userToken")
    };
  },

  created() {
    this.loadUserData();
  },

  computed: {
    isUserParent() {
      return this.$store.state.userData.role == "parent" ? true : false;
    },
    isUserLearner() {
      return this.$store.state.userData.role == "student" ? true : false;
    },
    isUserTeacher() {
      return this.$store.state.userData.role == "teacher" ? true : false;
    }
  },

  mounted() {
    this.$store.dispatch(
      "getUserData",
      window.localStorage.getItem("userToken")
    );
  },

  methods: {
    onSubmit() {},

    onReset() {
      this.form = {
        birthday: "",
        email: "",
        firstname: "",
        lastname: "",
        mobile_no: "",
        street: "",
        brgy: "",
        city: "",
        province: "",
        zip_code: ""
      };
    },

    loadUserData() {
      fetch(process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`
        }
      })
        .then((res) => res.json())
        .then((user) => {
          const _data = user.data;

          this.form = {
            birthday: _data.birthday,
            email: _data.email,
            firstname: _data.firstname,
            lastname: _data.lastname,
            mobile_no: _data.mobile_no,
            street: _data.street,
            brgy: _data.brgy,
            city: _data.city,
            province: _data.province,
            zip_code: _data.zip_code
          };

          this.ProfileName = this.form.firstname + " " + this.form.lastname;
        })
        .catch(() => {});
    }
  },

  mixins: [mixins]
};
</script>

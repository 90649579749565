<template>
  <div>
    <b-card no-body class="p-4">
     <div class="font-size-md font-weight-bold">  Account Preference</div>

      <b-tabs class="preference pt-4" v-model="tabIndex">
        <b-tab title=" Change Password" :disabled="isTabDisabled" v-if="!isSocialLogin">
          <ChangePassword v-if="!isSocialLogin"/>
        </b-tab>
        <b-tab title="Time zone">
          <TimeZone :isInvalid="isInvalid" v-if="isTzShow"/>
        </b-tab>
        <b-tab title="Notifications" :disabled="isTabDisabled" >
          <NotificationSubscription/>
        </b-tab>
        <b-tab
          v-if="this.$store.state.userData.role == 'teacher'"
          title="Booking Requests"
          :disabled="isTabDisabled"
          title-item-class="d-none"
          button-id="tutorBookingRequestsButton"
        >
          <TutorBookingRequests @show-tab="onTutorBookingRequestsShowTab"/>
        </b-tab>
      </b-tabs>

    </b-card>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '../../mixins';
import TimeZone from './TimeZone.vue';
import TutorBookingRequests from "./TutorBookingRequests.vue";
import ChangePassword from "./PreferencesAccountChangePassword.vue";
import NotificationSubscription from './NotificationSubscription.vue';

export default {
  components: {
    ChangePassword,
    TimeZone,
    NotificationSubscription,
    TutorBookingRequests
  },
  name: "Preferences",
  metaInfo: {
    title: "Chalkboard - Dashboard Preferences",
  },
  data: () => ({
    tabIndex: 0,
    isInvalid: false,
    isTabDisabled: false,
    isTzShow: false,
    tabs: ['changePassword', 'timezone']
  }),
  computed: {
    ...mapState(['userData']),
    isSocialLogin() {
      return this.socialAccountType().includes(this.userData.account_type)
    }
  },
  mounted() {
    this.checkUserTz()
    this.$nextTick(() => {
      this.tabIndex = this.tabs.findIndex(
        (tab) => tab === this.$route.hash.replaceAll("#", "")
      );
    });
  },
  methods: {
    checkUserTz() {
      const vm = this
      const urlParams = new URLSearchParams(window.location.search).has('setTimezone')
      vm.$nextTick(() => {
        vm.tabIndex = (urlParams) ? 1 : 0
        vm.isInvalid = (urlParams) ? true : false
        vm.isTabDisabled = (urlParams) ? true : false
        vm.isTzShow = true
      })
      vm.tabIndex = 1
      vm.isInvalid = true
      vm.isTabDisabled = true
    },

    onTutorBookingRequestsShowTab() {
      document
        .getElementById("tutorBookingRequestsButton")
        .parentNode.classList.toggle("d-none");
    }
  },
  mixins: [mixins]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.light-white-bg {
  background-color: var(--light-white);
}
</style>
<style scoped>
.preference >>> .nav-item > a.nav-link {
  color: var(--dark-silver);
  padding: 0 0 0.3rem 0;
  margin-right: 1.2rem;
}
.preference >>> .nav-item > a.nav-link:hover {
  border-color: #ffffff #ffffff #ffffff;
}
.preference >>> a.nav-link.active {
  color: var(--dark-grey);
  font-weight: 600;
  border: none;
  border-bottom: 4px solid var(--light-blue);
  
}

.preference >>> a.nav-link.active:hover {
  border-bottom: 4px solid var(--light-blue);
}
</style>
<template>
  <div class="chat-notif-nav-messages">
    <div class="text-cb-color" style="text-align: center; width: 22em; height: 5em;" v-if="isLoadingThread">
      <b-spinner class="align-middle" small></b-spinner>
      <strong><small> loading messages...</small></strong>
    </div>
    <div class="chat-nav-message-no-chat-messages" v-if="!isLoadingThread && items.length === 0">
      <font-awesome-icon class="grey" :icon="['far', 'comments']" />
      <div>No messages yet</div>
      <span>Don't worry, a notification will appear here when someone sends you a message</span>
    </div>
    <b-dropdown-item-button class="btn-chat-notif-nav-message" v-for="(item, index) in items" :key="index" @click="onClick(item)">
      <div class="chat-nav-message-list">
        <b-avatar class="mr-2" size="3em" :src="item.data.image"></b-avatar>

        <div class="d-flex align-items-center">
          <div>
            <div class="chat-nav-name" :class="{ 'chat-unread': item.is_read === false || false }">{{ `${item.data.firstname} ${getFChar(item.data.lastname)}.` }}</div>
            <div class="chat-nav-message" :class="{ 'chat-unread': item.is_read === false || false }">{{ item.last_message }}</div>
          </div>

          <div class="text-center">
            <div class="chat-nav-time" :class="{ 'chat-unread': item.is_read === false || false }">{{ moment.utc(item.last_active_message).tz(userData.timezone).fromNow() }}</div>
            <span class="chat-dot-unread" v-if="item.is_read === false || false"></span>
          </div>
        </div>

      </div>
    </b-dropdown-item-button>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import mixins from '../../mixins';
  import chatMixins from '../../mixins/chat'
  export default {
    props: ['currentUserData'],
    data: () => ({
      items: [],
      isLoadingThread: true
    }),
    computed: {
      ...mapState(['userData', 'chatThreads']),
    },
    beforeMount() {
      this.$store.dispatch('loadChatThreads', { favorites_only: '', search_keyword: '' })
      this.$store.dispatch('loadUnreadMessages')
    },
    mounted() {

      this.$watch('$store.state.chatThreads', (response) => {
        this.items = response.map(x => {
          return {
            'thread_id': x.id,
            'created_at': x.created_at,
            'data': x.chat_participants.filter(x => { return x.user_id !== this.userData.user_id})[0],
            'is_read': x.chat_participants.filter(x => { return x.user_id === this.userData.user_id})[0]?.last_read !== null ? true : false,
            'last_message': x.messages[0]?.message || "You are now connected with",
            'last_active_message': x.messages[0]?.created_at || x.created_at,
            'sortH': this.moment(x.messages[0]?.created_at || x.created_at).format('MM-DD-YYYY HH:mm:ss'),
            'is_active': x.is_active
          }
        }).sort((a, b) => new Date(b.sortH) - new Date(a.sortH))
        this.isLoadingThread = false
        this.items.forEach(x => {
          this.onListenIncomingMessage(x.thread_id, x.data.user_id)
        })
      })

      window.Echo.private(`chat-notifications.${this.userData.user_id}`)
        .listen('.Modules\\Message\\Events\\Web\\CreateChatThread', (e) => {
          if (e.thread_id !== 0) {
            this.chatNotifSound()
            e.data = { image: null, firstname: '', lastname: '' }
            this.items.unshift(e)
            this.$store.dispatch('loadUnreadMessages')
          }
        })

    },
    methods: {
      onListenIncomingMessage(thread_id, user_id) {
        window.Echo.private(`send-chat-message.${this.userData.user_id}.${user_id}`)
          .listen('.Modules\\Message\\Events\\Web\\SendChatMessage', (response) => {
            const result = this.items.find(x => x.thread_id === thread_id)
            if (result !== 'undefined') {
              const index = this.items.indexOf(result)
              this.items[index].last_active_message = response.created_at
              this.items[index].last_message = response.message
              this.items[index].is_read = false
              this.$store.state.newNotificationMessage = true
            }
          })
      },
      onClick(data) {
        const payload = {
          'thread_id': data.thread_id,
          'is_read': 0
        }
        this.$store.dispatch('toggleReadMessage', payload)
        this.items.find(x => x.thread_id === parseInt(data.thread_id)).is_read = 1
        const SelectedChatBubbleBox = document.querySelectorAll(`[data-id="thread_box_${data.thread_id}"]`)
        const ActiveChatBubbleBox = document.querySelectorAll('[data-id*="thread_box_"]').length
        if (SelectedChatBubbleBox.length === 0 && ActiveChatBubbleBox <= 2) {
          if (ActiveChatBubbleBox === 2) document.querySelectorAll('[data-id*="thread_box_"]')[0].remove()
          this.$store.state.selectedInboxChatThread = data;
        }
        const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${data.thread_id}']`)
        if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()
      }
    },
    mixins: [mixins, chatMixins]
  }
</script>

<style scoped>
.chat-red-notif {
  position: relative;
}
.chat-red-notif::after {
  content: '';
  position: absolute;
  right: 10px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 8px;
  background: #F95656;
}
.chat-nav-name {
  color: var(--grey);
  font-size: var(--fs-one);
}
.chat-nav-name.chat-unread {
  color: var(--dark-grey);
  font-weight: 500;
}
.chat-nav-message-list-wrapper {
  padding: 0.525em 0;
  max-height: 280px;
  overflow-y: scroll;
}
.chat-nav-message-list {
  display: flex;
  align-items: center;
  padding: 0.525em 0;
}
.chat-nav-message {
  color: #9b9b9b;
  min-width: 250px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-nav-message.chat-unread {
  color: var(--light-blue);
}

.chat-nav-time {
  font-size: calc(var(--fs-one) - 4px);
}
.chat-nav-time.chat-unread {
  font-weight: 500;
}
.chat-dot-unread {
  display: inline-block;
  background: var(--light-blue);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}
.chat-nav-message-no-chat-messages {
  padding: 3em;
  text-align: center;
  width: 22em;
  color: var(--grey);
}
.chat-nav-message-no-chat-messages > [data-icon="comments"] {
  font-size: var(--fs-two-six);
}
.chat-nav-message-no-chat-messages > div {
  font-size: var(--fs-one);
}
.chat-nav-message-no-chat-messages > span {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
}
.text-cb-color {
  color: var(--light-blue) !important;
}
</style>
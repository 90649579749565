export default {
    data: () => ({
        UserToken: window.localStorage.getItem("userToken")
    }),    
    methods: {
        cancelBooking(tran_id){

             this.showConfirmModal('Are you sure you want to cancel this booking?', () => {
                const paramData = new FormData()
                paramData.append('transaction_id', tran_id) 
      
                const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/cancel-request'
                const options = {
                  headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': `Bearer ${this.UserToken}`
                  }
                }
      
                this.axios.post(ApiURL, paramData, options).then(data => {
                
                    if (data.status == 422) {
                        this.ShowModal(data.message, null, 'Booking Cancelation Error'); 
                    }
                    else{   
                        this.ShowModal("Booking is cancelled.", null, 'Booking Cancelation Request'); 
                        // this.$router.push('/booking-payment');//{ path: , params: { txnId: tran_id } }
                    }
     
                })            
    
            }, 'Booking Cancelation')            
            

        },
        rejectBooking(tran_id){ 

            this.showConfirmModal('Are you sure you want to reject this booking?', () => {
                const paramData = new FormData()
                paramData.append('is_accepted', 0)
                paramData.append('transaction_id', tran_id) 
      
                const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/tutor-reject'
                const options = {
                  headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': `Bearer ${this.UserToken}`
                  }
                }
      
                this.axios.post(ApiURL, paramData, options).then(data => {
                
                    if (data.status == 422) {
                        this.ShowModal(data.message, null, 'Booking Rejection Error'); 
                    }
                    else{   
                        this.ShowModal("You have rejected the selected booking!", null, 'Booking Reject Request'); 
                        // this.$router.push('/booking-payment');//{ path: , params: { txnId: tran_id } }
                    }
     
                })            
    
            })    
            
        },
        acceptBooking(tran_id){
                
            const paramData = new FormData()
            paramData.append('is_accepted', 0) 
            paramData.append('transaction_id', tran_id) 
  
            const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/tutor-accept'
            const options = {
              headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${this.UserToken}`
              }
            }
  
            this.axios.post(ApiURL, paramData, options).then(data => {
            
                if (data.status == 422) {
                    this.ShowModal(data.message, null, 'Booking Cancelation Error'); 
                }
                else{
                    let message = '<div>Thank you for accepting the request. You will receive an email and SMS notification once the sessions are paid.</div>'
                    message += '<br /><div>You can still review this request under the Accepted Requests tab.</div>'
                    this.ShowModal(message, null, "Booking Request")
                }
 
            })  
        },
    }
}
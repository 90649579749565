<template>
  <div>
    <div class="course-detail">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-sm-12">
            <div class="what-class">
              <h6>What is this class?</h6>
              <p>{{this.SubjectData.class_overview}}</p>
            </div>
            <div v-if="this.SubjectData.class_benefits != null " class="what-class" :class="{ 'pb-5':this.SubjectData.course_code == 'reading'}">
              <h6>What will you learn?</h6>
              <ul class="ml-4" v-for="(item, index) in this.SubjectData.class_benefits" :key="index">
                <li>{{item}}</li>
                <!-- <li>To improve problem-solving and communication skills</li>
                                <li>To explore arts and storytelling by designing and creating projects</li> -->
              </ul>
            </div>
            <div v-if="this.SubjectData.system_requirements != null " class="what-class">
              <h6>System Requirements</h6>
              <ul class="ml-4" v-for="(item, index) in this.SubjectData.system_requirements" :key="index">
                <li>{{item}}</li>
                <!-- <li>To improve problem-solving and communication skills</li>
                                <li>To explore arts and storytelling by designing and creating projects</li> -->
              </ul>
            </div>
            <div v-if="this.SubjectData.installation_guide != null " class="what-class">
              <h6>Installation Guide</h6>
              <ul class="ml-4" v-for="(item, index) in this.SubjectData.installation_guide" :key="index" v-html="item">
                <li>{{item}}</li>
                <!-- <li>To improve problem-solving and communication skills</li>
                                <li>To explore arts and storytelling by designing and creating projects</li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div :class="'learner-sessions ' + this.SubjectData.course_code + '-card'">
              <div class="learners">
                <p><strong>Learners: </strong></p>
                <p>{{this.SubjectData.target_learners }} years</p>
              </div>
              <div v-if="this.SubjectData.prerequisite != null " class="learners">
                <p><strong>Prerequisite:</strong></p>
                <p>{{this.SubjectData.prerequisite}}</p>
              </div>
              <div class="sessions pb-2">
                <p><strong>Number of sessions:</strong></p>
                <p>{{this.SubjectData.session_count}} sessions</p>
              </div>
              <div v-if="this.SubjectData.lifelong_skills != null " class="learners">
                <p><strong>Lifelong Skills:</strong></p>
                <div v-for="(item, index) in this.SubjectData.lifelong_skills" :key="index" v-html="item">
                </div>
              </div>
            </div>
            <div class="text-center pb-5">
              <b-link :to="this.SubjectData.booking_url" class="cb-primary-link px-5 w-100 mb-2">BOOK A CLASS
              </b-link>
              <b-link href="https://chalkboardph.simplybook.me/v2/#book/count/1/" class="cb-primary-link outline-primary px-5 w-100" v-if="(this.SubjectData.course_code == 'coding' && this.SubjectData.subject_code != 'scratch_jr' && this.SubjectData.subject_code != 'python_level_1' && this.SubjectData.subject_code != 'python_level_2') || this.SubjectData.course_code == 'reading' ">Join A GROUP CLASS
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="'divider ' + this.SubjectData.course_code + '-bg'"></div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  props: ["SubjectData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.outline-primary,
.outline-primary:hover {
  background: none !important;
  border: 1px solid var(--light-blue);
  color: var(--light-blue) !important;
}
</style>
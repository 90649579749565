<template>
  <div>
    <HeaderBanner/>

    <Statistics/>

    <ScreeningProcess/>
  </div>
</template>

<script>
import HeaderBanner from "./marketing/how-we-vet/HeaderBanner.vue";
import Statistics from "./marketing/how-we-vet/Statistics.vue";
import ScreeningProcess from "./marketing/how-we-vet/ScreeningProcess.vue";


  export default {
    name: 'HowWeVet',
     metaInfo: {
    title: "How we vet - Home of the most exceptional online tutors in the Philippines | Chalkboard",
    htmlAttrs: { lang: "en-US" },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "All of Chalkboard’s online tutors were rigorously assessed and evaluated to guarantee excellent tutoring services.",
      },
      {
        name: "keywords",
        content:
          "online learning, online tutor/online tutorial, online learning platform, online math tutor, english tutors, online tutoring services, online coding tutor, python coding tutor, programming tutor, online marketplace, tutoring services, ukulele tutor, distance learning, virtual learning",
      },
    ],
  },
    data() {
      return {}
    },
    computed: {},
    mounted() {
      
    },
    methods: {},
    components: {HeaderBanner, Statistics, ScreeningProcess}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/how-we-vet.css");

</style>
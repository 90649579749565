<template>
  <div>
    <b-table
      class="default-table-css font-size-sm"
      :fields="fields"
      :items="items"
      :busy="isLoading"
      empty-text="No upcoming class yet."
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> please wait while data is loading...</strong>
        </div>
      </template>

      <template #cell(LevelCategory)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.LevelCategory }}</div>
        </div>
      </template>

      <template #cell(SubjectLesson)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.SubjectLesson }}</div>
        </div>
      </template>

      <template #cell(Learner)="data">
        <div class="d-flex align-items-center" style="min-width: 8em;">
          <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
          <div class="d-flex  flex-column mr-3">
            <div style="min-width: 5em;">{{ data.item.Learner }}</div>
            <small>{{ `(${data.item.learner_age} y.o)` }}</small>
          </div>
           <b-button  class="bg-white p-0" :class="{ 'chat-red-notif': isUnreadMessages }" v-b-tooltip.hover title="Chat"> <font-awesome-icon class="light-blue " :icon="['far', 'comment-dots']" @click="onChatClick(data)" /></b-button>
         
        </div>
      </template>

      <template #cell(Date)="data">
        <div class="d-flex align-items-center">
          <div>{{ moment.utc(data.item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
        </div>
      </template>

      <template #cell(start_date)="data">
        <div class="d-flex align-items-center">
          <div>{{ `${moment.utc(data.item.start_date).tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
        </div>
      </template>

      <template #cell(Action)="data">
         <!-- View Scribook -->
        <b-button class="table-scribook-btn" size="sm" title="View Scribook" v-b-tooltip.hover @click="onViewScribook(data)" >
          <font-awesome-icon class="mr-1" :icon="['fas', 'book']" />
          Scribook
        </b-button>
        <!-- Scribook Modal -->
        <b-modal id="session-scribook" :ref="`session-scribook-${data.item.data.booking_id}`" hide-header hide-footer >
          <Scribook :scribookData="scribookData" :isViewScribook="true"/>
        </b-modal>
        
      </template>

      <template #cell(more_action)="data">
        <b-button class="table-view-details-btn mx-1" size="sm" title="View Details" v-b-tooltip.hover v-b-modal.view-details-modal @click="selectedData = data.item.data">
          <font-awesome-icon class="" :icon="['fas', 'ellipsis-v']" />
        </b-button>
      </template>
    </b-table>

    <b-pagination
      v-if="upcomingSessions.data.length > 0"
      v-model="currentPage"
      :total-rows="upcomingSessions.total"
      :per-page="perPage"
      align="right"
      class="pt-4"
      size="sm"
    ></b-pagination>

    <b-modal id="view-details-modal" size="xl" title="Set Schedule" hide-footer body-class="p-0" scrollable>
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center">
          <div>
            <div class="font-weight-semibold">Booking schedule with Learner {{ selectedData.learner_name }}</div>
          </div>
          <b-button type="button" class="close" size="sm" @click="close()">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>

      </template>
      
      <div class="p-3 ">

       <b-row class="pb-4">
          <b-col lg="5" sm="12">
            <!-- Transaction ID -->
            <div class="font-size-sm d-flex align-items-center ">
              <div class="relative-div px-2">
                Transaction ID: <span class="ml-2 font-weight-semibold"> {{ selectedData.transaction_id }}
                </span>
                <b-button @click="onCopy(selectedData.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>

            </div>
          </b-col>
          <b-col lg="7" sm="12">
            <!-- Request Date & Time -->
            <div class="font-size-sm">
              Request Date and Time:<span class="font-weight-semibold ml-2">{{ moment.utc(selectedData.created_at).tz(userData.timezone).format('dddd, LL') }}</span>
              <!-- Time --> <span class="ml-1 font-weight-semibold">{{ `${moment.utc(selectedData.created_at).tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</span>
            </div>
          </b-col>
        </b-row>

        <!-- Table Content -->
        <b-table class="default-table-css font-size-sm" :fields="modalFields" :items="selectedDataBookings" empty-text="No details found." show-empty responsive>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> please wait while data is loading...</strong>
            </div>
          </template>

          <template #cell(BookingID)="data">
            <div class="d-flex align-items-center relative-div mr-2">
                {{ data.item.booking_id }}
              <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-1 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
              </b-button>
            </div>
          </template>

          <template #cell(LevelCategory)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.grade_level }}</div>
            </div>
          </template>

          <template #cell(SubjectLesson)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.subject }}</div>
            </div>
          </template>

          <template #cell(Learner)="data">
            <div class="d-flex align-items-center">
              <b-avatar size="2em" class="mr-1" :src="data.item.image"></b-avatar>
              <div>{{ data.item.learner_name }}</div>
            </div>
          </template>

          <template #cell(Date)="data">
            <div class="d-flex align-items-center">
              <div>{{ moment.utc(data.item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
            </div>
          </template>

          <template #cell(Time)="data">
            <div class="d-flex align-items-center">
              <div>{{ `${moment.tz(data.item.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
            </div>
          </template>

        </b-table>

      </div>

      <!-- Custom Modal Footer -->
      <div class="light-white-bg w-100 p-3">
        <div class="font-weight-semibold pb-2">Notes:</div>
        <p class="font-weight-normal font-size-sm pb-4">{{ selectedData.note }}</p>

        <!-- Modal Buttons -->
        <div class="d-flex align-items-center justify-content-between pb-3">
          <div>
            <b-button class="table-download-btn" size="sm" @click="onDownload({ file: selectedData.download_file,
                                   role: selectedData.account_type,
                                user_id: selectedData.user_id }, selectedData.original_file)">
              <font-awesome-icon class=" mr-1" :icon="['fas', 'download']" />Download Attachment
            </b-button>
          </div>
          <div>
            <b-button class="table-close-back-btn mr-2" @click="$bvModal.hide('view-details-modal')">Back</b-button>
          </div>
        </div>

      </div>

    </b-modal>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import mixins from '../../../mixins'
  import Scribook from "../../scribook/Scribook.vue";

  export default {
    props: ['isLoadTriggered'],
    name: 'TeacherUpcomingSessions',
    components: { Scribook },
    data: () => ({
      isLoading: false,
      perPage: 3,
      currentPage: 1,
      noSessionsToday: '',
      fields: [
        { key: "LevelCategory", label: "Level/Category" },
        { key: "SubjectLesson", label: "Subject/Lesson" },
        "Learner",
        { label: "Date", key: "Date", sortable: true },
        { label: "Time", key: "start_date", sortable: true },
        { label: "Action", key: "Action", class: 'text-center'},
         { key: "more_action", label: "" },
      ],
      selectedData: [],
      modalFields: [
        "BookingID",
        { key: "LevelCategory", label: "Level/Category" },
        { key: "SubjectLesson", label: "Subject/Lesson" },
        "Learner",
        "Date",
        "Time",
      ],
      items: [],
      isShowScribookCreationModal: false,
      scribookData: []
    }),
    computed: {
      ...mapState(['userData', 'upcomingSessions', 'chatThreads']),
      selectedDataBookings() {
        const result = []
        result.push(this.selectedData)
        return result
      },
      isUnreadMessages() {
      return this.$store.getters.newNotificationMessage;
      }

    },
    beforeMount() {
      if (this.isLoadTriggered) this.$emit('triggeredWatchSessions')
      this.loadSessions()
    },
    methods: {
      loadSessions() {
        const vm = this
        vm.isLoading = true
        vm.$store.state.upcomingSessions.data = []
        vm.$store.dispatch('getUpcomingSessions', { params: { count: vm.perPage, page: vm.currentPage }, role: 'tutor' }).then(response => {
          vm.noSessionsToday = response.data.data.data.length === 0 ? true : false
          vm.items = response.data.data.data.map(item => {
            return {
              LevelCategory: item.grade_level,
              SubjectLesson: item.subject,
              Learner: item.learner_name,
              start_date: item.start_date,
              learner_age: item.learner_age,
              learner_image: item.image,
              data: item
            }
          })
          vm.fields.filter(x => x['sortable'] !== undefined).map(x => x.sortable = response.data.data.data.length > 1 ? true : false)
        }).finally(() => { vm.isLoading = false })
      },
      onCopy(text) {
        this.$emit('copyToClipboard', text)
      },
      onDownload(params, original_file) {
        if (params.file == null) {
          this.ShowModal("No file to download.");
          return;
        }
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/download-file`,
        formData = new FormData();
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        this.onDownloadFile(ApiURL, formData, original_file);
      },
      onChatClick(data) {
        const obj = this.chatThreads.filter(x => x.chat_participants.filter(c => c.user_id === ((this.userData.role === 'teacher') ? this.userData.user_id : data.item.data.tutor_id)).length > 0 && x.chat_participants.filter(c => c.user_id === data.item.data.user_id).length > 0).map(x => {
          return {
            'thread_id': x.id,
            'created_at': x.created_at,
            'data': x.chat_participants.filter(x => { return x.user_id !== this.userData.user_id})[0],
            'is_read': x.chat_participants.filter(x => { return x.user_id === this.userData.user_id})[0]?.last_read !== null ? true : false,
            'sortH': this.moment(x.messages[0]?.created_at || x.created_at).format('MM-DD-YYYY HH:mm:ss')
          }
        })
        const SelectedChatBubbleBox = document.querySelectorAll(`[data-id="thread_box_${obj[0].thread_id}"]`)
        const ActiveChatBubbleBox = document.querySelectorAll('[data-id*="thread_box_"]').length
        if (SelectedChatBubbleBox.length === 0 && ActiveChatBubbleBox <= 2) {
          if (ActiveChatBubbleBox === 2) document.querySelectorAll('[data-id*="thread_box_"]')[0].remove()
          this.$store.state.selectedInboxChatThread = obj[0];
        }
        const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${obj[0].thread_id}']`)
        if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()
      },
      onScribookCreationModalClose() {
        this.isShowScribookCreationModal = false
      },
      onViewScribook(data) {
        const _data = data.item.data
        this.$refs[`session-scribook-${data.item.data.booking_id}`].show();
        this.scribookData = {
          scribook: {},
          bookings: {
            booking_id: _data.booking_id,
            subject: _data.subject,
            grade_level: _data.grade_level,
            start_date: _data.start_date
          },
          learner: {
            name: _data.learner_name,
            firstname: _data.learner_name.split(' ')[_data.learner_name.split(' ').length - 2],
            image: _data.image,
            child_id: _data.child_id
          },
          users: {
            id: _data.user_id,
            role: _data.account_type
          }
        }
        this.isShowScribookCreationModal = true
      }
    },
    watch: {
      currentPage() { this.loadSessions() },
      isLoadTriggered(val) {
        if(val) this.loadSessions()
        this.$emit('triggeredWatchSessions')
      }
    },
    mixins: [mixins]
  }
</script>

<style scoped>
.chat-red-notif {
  position: relative;
}
.chat-red-notif::after {
  content: "";
  position: absolute;
  right: 0px;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  top: 4px;
  background: #f95656;
}

</style>
<template>
  <div>
    <input
      v-bind="$attrs"
      :class="childClass"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
  export default {
    name: 'FormInput',
    props: ['childClass'],
  }
</script>

<style scoped>
</style>
<template>
  <div>
    <div class="p_loading" v-if="isLoading">
      <div class="p_loader"></div>
      <div class="p_message">{{ this.isLoadingMessage }}</div>
    </div>

    <div v-if="this.testControls">
      <b-card>
        <div class="font-size-xmd"><strong>QA Controls</strong></div>
        <b-form-checkbox class="font-size-sm" v-model="qaTestChannels">
          Enable testing of actual channels.
        </b-form-checkbox>
      </b-card>
    </div>

    <b-container fluid class="light-silver-bg py-5">
      <b-container>
        <b-row align-h="center">
          <!-- left col -->
          <b-col sm="12" lg="6">
            <div>
              <div class="font-size-lg"><strong>Booking Details</strong></div>
              <hr />
              <div class="mb-5">
                <!-- Session Type -->
                <div class="d-flex justify-content-between">
                  <div>Session Type</div>
                  <div>
                    <strong>{{ this.sessionType }}</strong>
                  </div>
                </div>
                <!-- Hours -->
                <div class="d-flex justify-content-between">
                  <div>Hours</div>
                  <div>
                    <strong>{{ this.totalHours }} hour(s)</strong>
                  </div>
                </div>
                <!-- Payors Name -->
                <div class="d-flex justify-content-between">
                  <div>Payor's Name</div>
                  <div>
                    <strong>{{ this.payorName }}</strong>
                  </div>
                </div>
                <!-- Tutor Name -->
                <div class="mt-4 d-flex justify-content-between">
                  <div>Tutor Name</div>
                  <div>
                    <strong>{{ this.teacherName }}</strong>
                  </div>
                </div>
                <!-- Grade Level -->
                <div class="d-flex justify-content-between">
                  <div>Grade Level</div>
                  <div>
                    <strong>{{ this.gradeLevel }}</strong>
                  </div>
                </div>
                <!-- Subject -->
                <div class="d-flex justify-content-between">
                  <div>Subject</div>
                  <div>
                    <strong>{{ this.subjectName }}</strong>
                  </div>
                </div>
              </div>

              <!-- Payment Methods -->
              <div v-if="showPaymentChannels">
                <div class="font-size-lg"><strong>Payment Method</strong></div>
                <div class="accordion payment-methods">
                  <!-- Main Channels -->
                  <b-form-radio-group
                    v-b-toggle
                    class="payment-center-btn"
                    @change="
                      onChangeSchedule(
                        item.value,
                        item.text,
                        item.open_from,
                        item.open_to
                      )
                    "
                    v-model="paymentMethod"
                    v-for="(item, index) in primarychannels"
                    :key="item.id"
                    :item="item"
                    :index="index"
                    name="radio-btn-stacked"
                    buttons
                    stacked
                  >
                    <!-- Xendit Card Payment -->
                    <b-form-radio
                      v-if="item.value == 'XNDTCP'"
                      class="payment-method"
                      :value="item.value"
                      accordion="accordion"
                      v-b-tooltip.hover="{
                        customClass: 'text-center-important'
                      }"
                      title="Pay using your VISA/Mastercard card"
                      @change="
                        $root.$emit('bv::toggle::collapse', 'card-payment')
                      "
                    >
                      <b-img
                        :src="item.icon"
                        class="payment-method__img"
                      ></b-img>
                    </b-form-radio>
                    <div
                      v-if="item.value == 'XNDTCP'"
                      class="payment-center-text"
                    >
                      {{ item.text }}
                    </div>
                    <!-- end of Xendit Card Payment -->

                    <!-- Paypal -->
                    <b-form-radio
                      v-if="item.value == 'PYPL'"
                      class="payment-method"
                      :value="item.value"
                      accordion="accordion"
                      v-b-tooltip.hover="{
                        customClass: 'text-center-important'
                      }"
                      title="Pay using PayPal account or with a Bank Account or Credit Card"
                    >
                      <b-img
                        :src="item.icon"
                        class="payment-method__img"
                      ></b-img>
                    </b-form-radio>
                    <div
                      v-if="item.value == 'PYPL'"
                      class="payment-center-text"
                    >
                      {{ item.text }}
                    </div>
                    <!-- end of Paypal -->

                    <!-- GCash -->
                    <b-form-radio
                      v-if="item.value == 'GCSH'"
                      class="payment-method"
                      :value="item.value"
                      accordion="accordion"
                    >
                      <b-img
                        :src="item.icon"
                        class="payment-method__img"
                      ></b-img>
                    </b-form-radio>
                    <div
                      v-if="item.value == 'GCSH'"
                      class="payment-center-text"
                    >
                      {{ item.text }}
                    </div>
                    <!-- end of GCash -->
                  </b-form-radio-group>

                  <div
                    v-b-toggle.e-wallet
                    @click="channelGroupSelect"
                    class="payment-center-btn btn-group-toggle btn-group-vertical bv-no-focus-ring"
                  >
                    <label for="" class="payment-method btn btn-secondary">
                      <b-img
                        src="https://developers.thetutormasters.com/img/payment-logos/e-wallet.png"
                        class="payment-method__img"
                      ></b-img>
                    </label>
                    <div class="payment-center-text">e-Wallet</div>
                  </div>

                  <div
                    v-b-toggle.online-banking
                    @click="channelGroupSelect"
                    class="payment-center-btn btn-group-toggle btn-group-vertical bv-no-focus-ring"
                  >
                    <label for="" class="payment-method btn btn-secondary">
                      <b-img
                        src="https://developers.thetutormasters.com/img/payment-logos/onlinebanking.png"
                        class="payment-method__img"
                      ></b-img>
                    </label>
                    <div class="payment-center-text">Online Banking</div>
                  </div>
                </div>
              </div>

              <b-collapse
                id="e-wallet"
                accordion="accordion"
                v-model="eWalletCollapse"
              >
                <b-card
                  no-body
                  style="background: var(--light-white); border: none"
                  class="p-3"
                >
                  <div class="font-weight-medium pt-2 pb-4 grey">
                    e-Wallet Payment Options
                  </div>
                  <div class="payment-methods">
                    <b-form-radio-group
                      class="payment-center-btn"
                      @change="
                        onChangeSchedule(
                          item.value,
                          item.text,
                          item.open_from,
                          item.open_to
                        )
                      "
                      v-model="paymentMethod"
                      v-for="(item, index) in pcewchannels"
                      :key="item.id"
                      :item="item"
                      :index="index"
                      name="radio-btn-stacked"
                      buttons
                      stacked
                    >
                      <b-form-radio :value="item.value">
                        <b-img
                          :src="item.icon"
                          class="payment-method__img"
                        ></b-img>
                      </b-form-radio>
                      <div class="payment-center-text">{{ item.text }}</div>
                    </b-form-radio-group>
                  </div>
                </b-card>
              </b-collapse>

              <b-collapse
                id="online-banking"
                accordion="accordion"
                v-model="onlineBankingCollapse"
              >
                <b-card
                  no-body
                  style="background: var(--light-white); border: none"
                  class="p-3"
                >
                  <div class="font-weight-medium pt-2 pb-4 grey">
                    Online Banking Options
                  </div>
                  <div class="payment-methods">
                    <b-form-radio-group
                      class="payment-center-btn"
                      v-model="paymentMethod"
                      v-for="(item, index) in onlinechannels"
                      @change="
                        onChangeSchedule(
                          item.value,
                          item.text,
                          item.open_from,
                          item.open_to
                        )
                      "
                      :item="item"
                      :index="index"
                      :key="item.id"
                      name="radio-btn-stacked"
                      buttons
                      stacked
                    >
                      <b-form-radio :value="item.value">
                        <b-img
                          :src="item.icon"
                          class="payment-method__img"
                        ></b-img>
                      </b-form-radio>
                      <div class="payment-center-text">{{ item.text }}</div>
                    </b-form-radio-group>
                  </div>
                </b-card>
              </b-collapse>

              <b-collapse
                id="card-payment"
                accordion="accordion"
                v-model="cartPaymentCollapse"
              >
                <b-card
                  no-body
                  style="background: var(--light-white); border: none"
                  class="p-4 card-payment-card"
                >
                  <div class="mb-3">
                    <label><small>Card Number</small></label>
                    <CardInput
                      :disabled="isLoading"
                      :state="isCardNumberValid"
                      @input="onCardNumberInput"
                      v-model="cardNumber"
                    />
                  </div>

                  <div class="date-code mb-4">
                    <span>
                      <label><small>Expiration Date</small></label>
                      <div class="expiration-date">
                        <b-form-select
                          class="month"
                          :options="monthOptions"
                          :disabled="isLoading"
                          :state="isExpiryValid"
                          @input="onExpiryInput"
                          v-model="cardExpMonth"
                        ></b-form-select>
                        <b-form-select
                          class="year"
                          :options="yearOptions"
                          :disabled="isLoading"
                          :state="isExpiryValid"
                          @input="onExpiryInput"
                          v-model="cardExpYear"
                        ></b-form-select>
                      </div>
                    </span>

                    <span>
                      <label style="white-space: nowrap">
                        <small>Security Code</small>
                      </label>
                      <b-form-input
                        type="number"
                        :disabled="isLoading"
                        :state="isSecurityCodeValid"
                        @input="onSecurityCodeInput"
                        v-model="cardCvn"
                      />
                    </span>
                  </div>

                  <div>
                    <em>
                      <small class="text-secondary">
                        *Processing Fee will be updated upon entering card
                        information
                      </small>
                    </em>
                  </div>

                  <div class="invalid-feedback d-block">
                    {{ xenditError }}
                  </div>
                </b-card>
              </b-collapse>

              <!-- Pay Button -->
              <div class="mt-3">
                <b-button
                  class="light-blue-bg w-100 font-weight-bold py-3"
                  @click="processPayment"
                  :disabled="
                    paymentDisabled ||
                    (!paymentMethod && totalAmount > 0) ||
                    (!isCardFeeLoaded && paymentMethod === 'XNDTCP')
                  "
                >
                  CONFIRM AND PAY
                </b-button>
                <div class="font-size-sm my-2">
                  By selecting the button above, I agree to the
                  <span
                    v-b-modal.modal-scrollable
                    class="light-blue font-weight-bold"
                  >
                    Cancellation and Refund Policy
                  </span>
                  and pay the total amount reflected including the services fee.
                </div>
              </div>
            </div>
          </b-col>
          <!-- end of left col -->

          <!-- right col -->
          <b-col sm="12" lg="5">
            <b-card no-body class="shadow-sm">
              <b-card-header class="text-center py-4 bg-white">
                <b-avatar
                  :src="this.teacherProfileImage"
                  size="5rem"
                ></b-avatar>
                <div>
                  <strong>{{ this.teacherName }}</strong>
                </div>
              </b-card-header>

              <b-card-body>
                <!-- Tutor Rate -->
                <div>
                  <div class="d-flex justify-content-between">
                    <div class="grey">Tutoring Fee</div>
                    <div class="font-weight-bold">
                      <template v-if="showConvertedAmount">
                        {{ convertedTutoringFee }}
                      </template>
                      <template v-else>
                        {{ formattedTutoringFee }}
                      </template>
                    </div>
                  </div>
                  <template v-if="showConvertedAmount">
                    <div class="d-flex dark-silver font-weight-medium">
                      <div class="ml-auto">
                        {{ formattedTutoringFee }}
                      </div>
                    </div>
                  </template>
                </div>

                <!-- Fees -->
                <div v-if="paymentMethod" class="my-2">
                  <div
                    class="d-flex justify-content-between"
                    style="color: var(grey)"
                  >
                    <div class="grey">Processing Fee</div>
                  </div>

                  <div
                    class="d-flex justify-content-between"
                    style="color: var(grey)"
                  >
                    <div class="grey">
                      {{ paymentChannelName }}
                    </div>
                    <div class="font-weight-bold">
                      <template v-if="showConvertedAmount">
                        {{ convertedTransferFee }}
                      </template>
                      <template v-else>
                        ₱ {{ formatPrice(transferFee) }}
                      </template>
                    </div>
                  </div>

                  <template v-if="showConvertedAmount">
                    <div class="d-flex dark-silver font-weight-medium">
                      <div class="ml-auto">
                        ₱ {{ formatPrice(transferFee) }}
                      </div>
                    </div>
                  </template>
                </div>

                <!-- Discounts -->
                <div class="pt-2">
                  <div class="d-flex justify-content-between">
                    <div class="grey">Discounts</div>
                    <div v-if="discounts.length == 0">
                      <strong>
                        <template v-if="showConvertedAmount">
                          -{{
                            convertAmount(0, { spaceAfterSign: false }).result
                          }}
                        </template>
                        <template v-else>-₱0.00</template>
                      </strong>
                    </div>
                  </div>

                  <div
                    v-if="showConvertedAmount && discounts.length == 0"
                    class="d-flex text-secondary"
                  >
                    <div class="ml-auto">-₱0.00</div>
                  </div>

                  <div v-for="(discount, index) in discounts" :key="index">
                    <div class="d-flex justify-content-between">
                      <div style="font-weight: 500; color: var(grey)">
                        {{ discountName(discount) }}
                        <span
                          v-if="discount.auto_apply != 1"
                          class="red font-weight-normal"
                          style="font-size: 12px; vertical-align: middle"
                          role="button"
                          :disabled="isLoading"
                          @click="clearVoucher(discount)"
                        >
                          (remove)
                        </span>
                      </div>

                      <div>
                        <div
                          class="dark-grey font-weight-bold"
                          v-if="discount.voucher_subtype != 'REF'"
                        >
                          <template v-if="showConvertedAmount">
                            -{{
                              convertAmount(
                                discountPrice(discount, cbdAmount, baseAmount),
                                { spaceAfterSign: false }
                              ).result
                            }}
                          </template>
                          <template v-else>
                            -₱{{
                              formatPrice(
                                discountPrice(discount, cbdAmount, baseAmount)
                              )
                            }}
                          </template>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        showConvertedAmount && discount.voucher_subtype != 'REF'
                      "
                      class="d-flex dark-silver font-weight-medium"
                    >
                      <div class="ml-auto">
                        -₱{{ discountPrice(discount, cbdAmount, baseAmount) }}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <!-- Total -->
                <div class="d-flex justify-content-between">
                  <div
                    style="
                      font-size: calc(var(--fs-one) + 2px);
                      font-weight: 500;
                      color: var(--dark-grey);
                    "
                  >
                    Total Amount
                  </div>
                  <div
                    style="
                      font-size: calc(var(--fs-one) + 2px);
                      font-weight: bold;
                      color: var(--dark-grey);
                    "
                  >
                    <template v-if="showConvertedAmount">
                      {{ convertedTotalAmount }}
                    </template>
                    <template v-else>₱ {{ formatPrice(totalAmount) }}</template>
                  </div>
                </div>

                <template v-if="showConvertedAmount">
                  <div class="d-flex">
                    <div
                      class="ml-auto dark-silver font-weight-medium"
                      style="font-size: calc(var(--fs-one) + 2px)"
                    >
                      ₱ {{ formatPrice(totalAmount) }}
                    </div>
                  </div>

                  <hr />

                  <div class="d-flex justify-content-between dark-silver w-100">
                    <div style="font-size: 14px; color: #bbb">
                      Amount to be reflected on your billing statement shall be
                      its PHP currency equivalent which is 1
                      {{ currencyLocalization.currencyCode }} =
                      {{ parseFloat(currencyLocalization.rateTo).toFixed(2) }}
                      PHP
                    </div>
                  </div>
                </template>
              </b-card-body>
            </b-card>

            <!-- Coupon Code Area -->
            <b-card no-body class="my-3 shadow-sm">
              <b-card-body>
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-0 mr-3">Have a coupon code?</p>
                  <b-button
                    class="px-0"
                    variant="link"
                    sm
                    @click="showUserVouchersModal"
                    :disabled="isLoading"
                  >
                    Use Voucher
                  </b-button>
                </div>
                <b-input-group size="sm">
                  <b-form-input v-model="voucherCode"></b-form-input>
                  <b-input-group-append v-if="1 == 1">
                    <b-button
                      variant="primary"
                      @click="tryVoucherApply"
                      :disabled="isLoading"
                    >
                      Apply
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="font-size-sm text-success" v-if="1 == 0">
                  You have successfully applied a coupon.
                </span>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- end of right col -->
        </b-row>
      </b-container>
    </b-container>
    <CollectionDocCancelRefundPolicy />

    <div v-if="threedsIframeSrc" v-focus-trap>
      <div class="three-ds-modal" tabindex="0">
        <div class="three-ds-wrapper">
          <span class="three-ds-modal__close" @click="threedsIframeSrc = null">
            close
          </span>
          <iframe
            class="three-ds-iframe"
            :src="threedsIframeSrc"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonmixins from "../../mixins";
import CollectionDocCancelRefundPolicy from "./CollectionDocCancelRefundPolicy";
import UserVouchers from "../voucher/UserVouchers.vue";
import { xenditScript, loadXendit } from "../../config/xendit";
import debounce from "lodash/debounce";
import CardInput from "../form-inputs/CardInput.vue";

export default {
  components: {
    CollectionDocCancelRefundPolicy,
    CardInput
  },

  name: "CollectionBookingPaymentCheckout",

  data() {
    return {
      rates: [{ item_code: "SF", item_name: "Service Fees", item_amount: 100 }],
      transId: 0,
      sessionType: "",
      totalHours: 0,
      payorName: "",
      teacherProfileImage: "",
      teacherName: "",
      gradeLevel: "",
      subjectName: "",
      baseAmount: 0,
      totalAmount: 0,
      cbdAmount: 0, //Charge before discount
      launchDiscount: 0,
      serviceFee: 0,
      totalDiscount: 0,
      voucherCode: "",
      paymentMethod: "",
      paymentChannelName: "",
      learnerName: "",
      rateBreakdown: 0,
      rateTotal: 0,
      transferFee: 0,
      fees: [],
      discounts: [],
      paymentChannel: "",
      openFrom: "",
      openTo: "",
      primarychannels: [],
      pcewchannels: [],
      onlinechannels: [],
      applyVoucherVisible: false,
      clearVoucherVisible: false,
      applyVoucherEnabled: false,
      clearVoucherEnabled: false,
      paymentChannelsPanelVisible: 1,
      guestTZ: null,
      regionTzName: null,
      testControls:
        process.env.VUE_APP_BASE_URL != "https://www.chalkboard.com.ph" ? 1 : 0,
      qaTestChannels: false,
      paymentDisabled: false,
      paymentDisabler: {},
      isLoading: false,
      eWalletCollapse: false,
      onlineBankingCollapse: false,

      cartPaymentCollapse: false,
      xendit: null,
      currentYear: new Date().getFullYear(),
      yearOptions: Array.from({ length: 26 }, (_, i) =>
        (new Date().getFullYear() + i).toString()
      ),
      monthOptions: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ],
      tokenized: false,
      cardNumber: "",
      cardExpMonth: "",
      cardExpYear: "",
      cardCvn: "",
      threedsIframeSrc: null,
      xenditError: "",
      isCardFeeLoaded: false,
      isNational: false,
      isCardNumberValid: null,
      isExpiryValid: null,
      isSecurityCodeValid: null,

      isLoadingMessage: ""
    };
  },

  computed: {
    showPaymentChannels() {
      return this.totalAmount > 0 ? true : false;
    },

    convertedTutoringFee() {
      return this.convertAmount(
        parseFloat(this.rateTotal) + parseFloat(this.serviceFee)
      ).result;
    },

    convertedTransferFee() {
      return this.convertAmount(this.transferFee).result;
    },

    convertedTotalAmount() {
      return this.convertAmount(this.totalAmount).result;
    },

    formattedTutoringFee() {
      return (
        "₱ " +
        this.formatPrice(
          parseFloat(this.rateTotal) + parseFloat(this.serviceFee)
        )
      );
    }
  },

  beforeMount() {
    this.transId = this.$route.params.tran_id;

    const paramData = new FormData();
    paramData.append("transaction_id", this.transId);

    const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/booking/payment";
    const options = {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${this.UserToken}`
      }
    };

    this.axios.post(ApiURL, paramData, options).then((data) => {
      const _data = data.data;

      if (_data.data == "S") {
        this.ShowModal(
          "Payment for this booking is already COMPLETED. If you have any concerns, please contact support.",
          () => {
            this.$router.push("/schedule");
          },
          "Payment Checkout Error"
        );
      } else if (_data.status == 422) {
        this.ShowModal(
          _data.message,
          () => {
            this.$router.push("/schedule");
          },
          "Payment Checkout Error"
        );
      }
    });
  },

  mounted() {
    const vm = this;
    vm.transId = this.$route.params.tran_id;
    if (vm.transId == null || vm.transId == "" || vm.transId == undefined) {
      vm.ShowModal("Invalid transaction request.");
      vm.$router.push("/schedule");
    } else {
      vm.loadBookingDetails();
      vm.loadChannels();
    }
    vm.$watch(
      "$store.state.userData",
      (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
        vm.regionTzName =
          data.timezone == null
            ? vm.getTzRegion(vm.guestTz)
            : data.region_timezone;
      },
      { immediate: true }
    );

    loadXendit().then((xendit) => {
      this.xendit = xendit;
      this.xendit.setPublishableKey(process.env.VUE_APP_XENDIT_API_KEY);
    });

    window.addEventListener("message", this.messageEventHandler);
  },

  methods: {
    onChangeSchedule(proc_id, channel_name, start_time, end_time) {
      this.resetCard();

      let currentDate = this.moment().format("MM-DD-YYYY");
      this.openFrom = this.moment(currentDate + " " + start_time)
        .tz(this.guestTZ)
        .format("LLLL");
      this.openTo = this.moment(currentDate + " " + end_time)
        .tz(this.guestTZ)
        .format("LLLL");

      this.paymentChannelName = channel_name;

      if (proc_id == "PYPL" || proc_id == "GCSH" || proc_id == "XNDTCP") {
        if (this.eWalletCollapse) this.eWalletCollapse = false;
        if (this.onlineBankingCollapse) this.onlineBankingCollapse = false;
        if (this.cartPaymentCollapse) this.cartPaymentCollapse = false;
      }

      this.updateTransferFee();
      this.loadAppliedVouchers();
    },
    loadChannels() {
      this.isLoading = true;
      const ApiURLOnl =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/maintenance/get-dragonpay-channels?channel_type=GCS|PPL|EWL|PYC|INB&channel_status=ACTIVE";

      const options = {
        headers: this.getGetRequestHeaders(),
        mode: "cors"
      };

      this.axios
        .get(ApiURLOnl, options)
        .then((data) => {
          const _data = data.data;

          _data.data.forEach((channel) => {
            let channel_data = {
              value: channel.proc_id,
              text: channel.channel_name,
              icon: channel.icon,
              open_from: channel.open_from,
              open_to: channel.open_to
            };

            if (channel.channel_type == "GCS" || channel.channel_type == "PPL")
              this.primarychannels.push(channel_data);
            else if (
              channel.channel_type == "EWL" ||
              channel.channel_type == "PYC"
            ) {
              this.pcewchannels.push(channel_data);
            } else if (channel.channel_type == "INB") {
              this.onlinechannels.push(channel_data);
            } else if (channel.proc_id == "XNDTCP") {
              // Xendit VISA/Mastercard
              this.primarychannels.push(channel_data);
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadBookingDetails() {
      this.isLoading = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/booking/checkout?transaction_id=" +
        this.transId;

      const options = {
        headers: this.getGetRequestHeaders()
      };

      this.axios
        .get(ApiURL, options)
        .then((data) => {
          let _data = data.data.data;

          this.teacherProfileImage = _data.tutor_image;

          this.sessionType = _data.session_type;
          this.totalHours = _data.hours;
          this.learnerName = _data.name;
          this.payorName = _data.payor_name;
          this.teacherName = _data.tutor_name;
          this.gradeLevel = _data.level;
          this.subjectName = _data.subject;
          this.rateBreakdown = _data.tutor_rate + " x " + _data.hours;
          this.rateTotal = _data.amount;
          this.serviceFee = _data.service_charge;
          this.transferFee = _data.transfer_fee;

          this.totalAmount = parseFloat(_data.total.replace(",", ""));
          this.baseAmount = parseFloat(_data.amount.replace(",", ""));
          this.serviceFee = parseFloat(_data.service_charge.replace(",", ""));
          this.transferFee = parseFloat(_data.transfer_fee.replace(",", ""));

          this.cbdAmount = this.baseAmount + this.serviceFee + this.transferFee;

          this.loadAppliedVouchers();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validatePayment() {
      let validate_msg = "";
      let validation_result = 1;

      let default_time = this.moment(this.moment().format("MM-DD-YYYY 00:00"))
        .tz(this.guestTZ)
        .format("LLLL");

      if (
        this.totalAmount > 0 &&
        !(this.openFrom == default_time && this.openTo == default_time) &&
        !this.moment().isBetween(this.openFrom, this.openTo)
      ) {
        validate_msg =
          "Payment method available during  " +
          this.moment(this.openFrom).format("hh:mm A") +
          " to " +
          this.moment(this.openTo).format("hh:mm A");
      }

      if ((validate_msg ?? "").length > 0) {
        this.ShowModal(validate_msg, null, "Payment Checkout");
        validation_result = -1;
      }

      return validation_result;
    },

    processPayment() {
      let validation_result = this.validatePayment();

      if (validation_result == 1) {
        if (this.totalAmount > 0) {
          if (this.paymentMethod == "XNDTCP") {
            this.createToken();
          } else {
            this.redirectToDragonPay();
          }
        } else if (this.totalAmount == 0) {
          this.transferFee = 0;
          this.launchCBPaymentProcessing();
        }
      }
    },

    resetCard() {
      this.isCardFeeLoaded = false;
      this.isCardNumberValid = null;
      this.isExpiryValid = null;
      this.isSecurityCodeValid = null;
      this.cardNumber = "";
      this.cardExpMonth = "";
      this.cardExpYear = "";
      this.cardCvn = "";
    },

    onCardInput() {
      this.isCardFeeLoaded = false;

      const isCardValid =
        this.isCardNumberValid == null &&
        this.isExpiryValid == null &&
        this.isSecurityCodeValid == null &&
        this.cardNumber != "" &&
        this.cardExpMonth != "" &&
        this.cardExpYear != "" &&
        this.cardCvn != "";

      if (isCardValid) {
        this.simpleDebounce("getCardInfo");
      }
    },

    onCardNumberInput() {
      this.isCardNumberValid = this.cardNumber.length >= 13 ? null : false;
      this.onCardInput();
    },

    onExpiryInput() {
      this.isSecurityCodeValid = null;

      if (this.cardExpMonth && this.cardExpYear) {
        this.isExpiryValid = this.xendit.card.validateExpiry(
          this.cardExpMonth,
          this.cardExpYear
        )
          ? null
          : false;
      }

      this.onCardInput();
    },

    onSecurityCodeInput() {
      this.isSecurityCodeValid = this.xendit.card.validateCvn(this.cardCvn)
        ? null
        : false;
      this.onCardInput();
    },

    simpleDebounce: debounce(function (callback) {
      this[callback]();
    }, 450),

    getCardInfo() {
      const url = process.env.VUE_APP_XENDIT_API_URL + "v2/credit_card_tokens";

      const payload = {
        amount: 0,
        card_data: {
          account_number: this.cardNumber,
          exp_month: this.cardExpMonth,
          exp_year: this.cardExpYear,
          cvn: this.cardCvn
        },
        is_single_use: true,
        should_authenticate: true
      };

      const publicKey = process.env.VUE_APP_XENDIT_API_KEY + ":";
      const base64EncodedKey = btoa(publicKey);

      const config = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Basic " + base64EncodedKey
        }
      };

      this.isLoading = true;
      this.isNational = false;
      this.isLoadingMessage = "Updating fee";
      this.isCardFeeLoaded = false;
      this.xenditError = "";
      this.axios
        .post(url, payload, config)
        .then((response) => {
          const countryCode =
            response.data?.metadata?.country_code || // development
            response.data?.card_info?.country; // production
          this.isNational = countryCode == "PH";

          if (countryCode) {
            return this.updateTransferFee();
          }

          return response;
        })
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == 200 &&
            response.data.data.transfer_fee
          ) {
            this.isCardFeeLoaded = true;
          }
        })
        .catch((error) => {
          const data = error.response.data;
          if (error.response.status == 400) {
            if (data.error_code == "BRAND_NOT_SUPPORTED_ERROR") {
              this.xenditError = data.message;
            } else if (data.error_code == "API_VALIDATION_ERROR") {
              const keyMap = {
                account_number: "Card Number is invalid"
              };
              this.isCardNumberValid = false;
              const firstError = error.response.data.errors[0];
              const key = firstError.context.key;
              this.xenditError = keyMap[key];
            } else {
              alert(
                error.response.data.message || error.response || error.message
              );
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingMessage = "";
        });
    },

    createToken() {
      if (this.xendit === null) {
        alert("Xendit SDK is not ready yet");
        return;
      }

      this.xenditError = "";

      this.xendit.card.createToken(
        {
          amount: this.totalAmount,
          card_number: this.cardNumber,
          card_exp_month: this.cardExpMonth,
          card_exp_year: this.cardExpYear,
          card_cvn: this.cardCvn,
          currency: "PHP"
        },
        (error, response) => {
          if (error) {
            this.xenditError = error.message;
            return;
          }

          if (response.status === "VERIFIED") {
            /**
             * TODO - not really needed for now
             */
          } else if (response.status === "IN_REVIEW") {
            this.threedsIframeSrc = response.payer_authentication_url;
            document.documentElement.style.overflow = "hidden";
            document.body.style.overflow = "hidden";
          } else if (response.status === "FAILED") {
            this.xenditError = response.failure_reason;
          } else {
            /**
             * TODO - logger or leave empty
             * console.log("TO DO: unhandled", error, response);
             */
          }
        }
      );
    },

    redirectToDragonPay() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/booking/connect";

      const options = {
        headers: this.getPostRequestHeaders(),
        mode: "cors"
      };

      let paramData = new FormData();

      paramData.append(
        "countryCode",
        this.currencyLocalization.countryCode || "PH"
      );
      paramData.append(
        "currencyCode",
        this.currencyLocalization.currencyCode || "USD"
      );
      paramData.append("transaction_id", this.transId);
      paramData.append("amount", this.totalAmount);
      paramData.append("ccy", "PHP");
      paramData.append("description", "ChalkboardPayment");
      paramData.append("type", this.paymentMethod);
      paramData.append("qa_testchannels", this.qaTestChannels);
      paramData.append("proc_id", this.paymentMethod);

      this.axios
        .post(ApiURL, paramData, options)
        .then((data) => {
          const _data = data.data.data;

          if (_data.Status == "S") {
            const payload = {
              eventName: "Purchase",
              data: {
                currency: "PHP",
                value: this.totalAmount,
                subject: this.subjectName,
                category: this.gradeLevel,
                content_name: this.gradeLevel.concat(" - ", this.subjectName)
              }
            };
            this.metaPixelTrack(payload);

            window.location.href = _data.Url;
          } else {
            this.ShowModal(_data);
          }
        })
        .catch((err) => console.error(err));
    },
    launchCBPaymentProcessing() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/booking/pay-via-chalkboard";

      const options = {
        headers: this.getPostRequestHeaders(),
        mode: "cors"
      };

      let paramData = new FormData();

      paramData.append("transaction_id", this.transId);

      this.axios
        .post(ApiURL, paramData, options)
        .then((data) => {
          const _data = data.data.data;

          let zeroPaymentConfirmURL =
            process.env.VUE_APP_BASE_URL +
            "/payment-confirmation" +
            "?txnid=" +
            _data.transaction_id +
            "&refno=" +
            _data.refno +
            "&status=" +
            _data.status +
            "&message=" +
            _data.note;

          window.location.href = zeroPaymentConfirmURL;
        })
        .catch((err) => console.error(err));
    },

    loadAppliedVouchers() {
      this.isLoading = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/voucher/load-vouchers?auto_load=1&transaction_id=" +
        this.transId +
        "&procid=" +
        this.paymentMethod;

      const options = {
        headers: this.getGetRequestHeaders(),
        mode: "cors"
      };

      this.axios
        .get(ApiURL, options)
        .then((vouchers) => {
          this.discounts = vouchers.data.data;
          let discount_item = 0;

          this.totalAmount = this.cbdAmount;
          this.totalDiscount = 0;

          vouchers.data.data.forEach((voucher) => {
            if (parseFloat(voucher.quantity) == -1) {
              this.cbdAmount -= this.transferFee;
            }

            discount_item =
              parseFloat(voucher.quantity) == 1
                ? parseFloat(voucher.voucher_amount)
                : parseFloat(voucher.quantity) == -1
                ? this.cbdAmount * -1
                : this.baseAmount * parseFloat(voucher.quantity);

            this.totalDiscount +=
              discount_item +
              (voucher.voucher_subtype == "PRS" && this.totalHours == 1
                ? -1 * this.transferFee
                : 0);

            if (this.cbdAmount + this.totalDiscount <= 0) {
              if (this.paymentMethod == "XNDTCP") {
                this.$root.$emit("bv::toggle::collapse", "card-payment");
              }
              this.paymentMethod = "";
              this.paymentChannelName = "";
              this.transferFee = 0;
            }
          });

          this.totalAmount = this.cbdAmount + this.totalDiscount;
          this.totalAmount = this.totalAmount < 0 ? 0 : this.totalAmount;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    tryVoucherApply() {
      this.isLoading = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/voucher/apply-voucher";

      const options = {
        headers: this.getPostRequestHeaders(),
        mode: "cors"
      };

      const paramData = new FormData();

      paramData.append("voucher_code", this.voucherCode);
      paramData.append("transaction_id", this.transId);

      this.axios
        .post(ApiURL, paramData, options)
        .then((vouchers) => {
          if (vouchers.data.status == 422) {
            this.voucherCode = "";
            this.ShowModal(vouchers.data.message);
          } else {
            const payload = {
              ...(this.voucherCode.toUpperCase() === "CBFREE" && {
                eventName: "ApplyDiscountCBFREE"
              }),
              ...(!(this.voucherCode.toUpperCase() === "CBFREE") && {
                eventName: "ApplyDiscount",
                data: { discount_code: this.voucherCode }
              })
            };

            this.metaPixelTrack(payload, true);

            this.voucherCode = "";
            // this.loadAppliedVouchers();
          }
        })
        .finally(() => {
          this.isLoading = false;
        })
        .then(() => {
          if (this.paymentMethod == "XNDTCP") {
            const isCardValid =
              this.isCardNumberValid == null &&
              this.isExpiryValid == null &&
              this.isSecurityCodeValid == null &&
              this.cardNumber != "" &&
              this.cardExpMonth != "" &&
              this.cardExpYear != "" &&
              this.cardCvn != "";

            if (isCardValid) {
              this.getCardInfo();
            } else {
              this.updateTransferFee();
            }
          } else {
            this.loadAppliedVouchers();
          }
        });
    },

    clearVoucher(voucher) {
      this.isLoading = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/voucher/unload-voucher";
      const options = { headers: this.getPostRequestHeaders(), mode: "cors" };
      let paramData = new FormData();
      paramData.append("voucher_code", voucher.voucher_code);
      paramData.append("transaction_id", this.transId);
      this.axios
        .post(ApiURL, paramData, options)
        .then(() => {
          let message =
            voucher.voucher_subtype != "REF"
              ? "Voucher " + voucher.voucher_code + " was successfully removed."
              : "Referral Voucher was successfully removed.";
          this.ShowModal(message, () => {
            this.loadAppliedVouchers();
          });

          if (this.paymentMethod == "XNDTCP") {
            this.updateTransferFee();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateTransferFee() {
      let charge_amount =
        parseFloat(this.rateTotal) + parseFloat(this.serviceFee);
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/maintenance/get-dragonpay-transfer-fee";

      const options = {
        headers: this.getGetRequestHeaders(),
        mode: "cors",
        params: {
          transaction_id: this.transId,
          proc_id: this.paymentMethod,
          charge_amount: charge_amount,
          isNational: this.isNational ? 1 : 0
        }
      };

      this.isLoading = true;
      this.isLoadingMessage = "Updating fee";
      return this.axios
        .get(ApiURL, options)
        .then((data) => {
          let _data = data.data.data;
          this.transferFee = parseFloat(_data.transfer_fee.replace(",", ""));
          this.cbdAmount = this.baseAmount + this.serviceFee + this.transferFee;
          this.loadAppliedVouchers();
          return data;
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingMessage = "";
        });
    },

    checkPaymentAllowed() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/booking/payment-check-allowed?transaction_id=" +
        this.transId;

      const options = {
        headers: this.getGetRequestHeaders(),
        mode: "cors"
      };

      this.axios.get(ApiURL, options).then((data) => {
        let _data = data.data.data;
        this.paymentDisabled = _data == "F" ? true : false;
      });
    },

    channelGroupSelect() {
      this.paymentMethod = "";
    },

    discountName({ voucher_name, quantity }) {
      return voucher_name + (quantity != 1 ? "(" + quantity * -100 + "%)" : "");
    },

    discountPrice({ quantity, voucher_amount }, cbdAmount, baseAmount) {
      const pQuantity = parseFloat(quantity);
      return (
        (pQuantity == 1
          ? parseFloat(voucher_amount)
          : pQuantity == -1
          ? cbdAmount * -1
          : baseAmount * pQuantity) * -1
      );
    },

    showUserVouchersModal() {
      this.openAppModal(
        UserVouchers,
        {},
        {
          id: "user-vouchers-modal"
        },
        {
          "use-voucher": (voucher) => {
            this.voucherCode = voucher;
            this.tryVoucherApply();
          }
        }
      );
    },

    messageEventHandler(event) {
      if (event.origin == "https://redirect.xendit.co") {
        const data = JSON.parse(event.data);
        this.threedsIframeSrc = null;
        document.documentElement.style.overflow = "";
        document.body.style.overflow = "";
        if (data.status == "VERIFIED") {
          this.tokenized = true;
          this.chargeCard(data);
        }
      }
    },

    chargeCard(data) {
      const url =
        process.env.VUE_APP_BACKEND_URL + "/api/web/booking/charge-card";

      const form = {
        countryCode: this.currencyLocalization.countryCode || "PH",
        currencyCode: this.currencyLocalization.currencyCode || "USD",
        transaction_id: this.transId,
        type: this.paymentMethod,
        proc_id: this.paymentMethod,

        token_id: data.id,
        currency: "PHP",
        amount: this.totalAmount,
        card_cvn: this.cardCvn,

        isNational: this.isNational
      };

      const config = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`
        }
      };

      this.paymentDisabled = true;
      this.isLoading = true;
      this.axios
        .post(url, form, config)
        .then((response) => {
          if (response.data.transaction_id) {
            this.$router.push({
              name: "payment-confirmation",
              query: {
                txnid: response.data.transaction_id,
                status: response.data.status
              }
            });
          } else {
            /**
             * TODO - unhandled / unknown response;
             */
          }
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.xenditError = error.response.data.message;
          } else if (error.response.xendit) {
            alert(error.response.data.message);
          } else {
            alert(error.response.data.message || error.response.data);
          }
        })
        .finally(() => {
          this.tokenized = false;
          this.paymentDisabled = false;
          this.isLoading = false;
        });
    }
  },

  beforeDestroy() {
    if (xenditScript !== null) {
      document.head.removeChild(xenditScript);
    }
    window.removeEventListener("message", this.messageEventHandler);
  },

  watch: {
    paymentMethod: function () {
      if (this.paymentDisabled) {
        this.ShowModal(
          "Payments are allowed up until 90 minutes prior to the start of the session.",
          () => {
            this.$router.push("/schedule");
          },
          "Chalkboard"
        );
      }
    }
  },

  mixins: [commonmixins]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.payment-center-btn .btn.btn-secondary {
  text-align: left;
}
.payment-center-btn .btn-secondary:focus,
.payment-center-btn .btn-secondary.focus,
.payment-center-btn .btn-secondary:not(:disabled):not(.disabled):active,
.payment-center-btn .btn-secondary:not(:disabled):not(.disabled).active,
.show > .payment-center-btn .btn-secondary.dropdown-toggle,
.payment-center-btn .btn-secondary:not(:disabled):not(.disabled):active:focus,
.payment-center-btn .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .payment-center-btn .btn-secondary.dropdown-toggle:focus {
  background: var(--white);
}

.payment-center-btn.block-stacked-buttons .btn-secondary:focus,
.payment-center-btn.block-stacked-buttons .btn-secondary.focus,
.payment-center-btn.block-stacked-buttons
  .btn-secondary:not(:disabled):not(.disabled):active,
.payment-center-btn.block-stacked-buttons
  .btn-secondary:not(:disabled):not(.disabled).active,
.show
  > .payment-center-btn.block-stacked-buttons
  .btn-secondary.dropdown-toggle,
.payment-center-btn.block-stacked-buttons
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
.payment-center-btn.block-stacked-buttons
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show
  > .payment-center-btn.block-stacked-buttons
  .btn-secondary.dropdown-toggle:focus {
  background: white;
}

.card-header {
  background: white;
}
</style>
<style scoped>
.text-center-important.tooltip.b-tooltip >>> .tooltip-inner {
  max-width: 280px;
  text-align: center !important;
}
.payment-center-btn {
  display: inline-block;
  position: relative;
  color: var(--dark-silver);
  /* margin-bottom: 1rem; */
}
.payment-center-btn span {
  font-size: 0.925rem;
  color: var(--dark-grey);
}
.payment-center-btn .active span {
  font-weight: 600;
}
.payment-center-btn .active::after {
  background: var(--light-blue);
  border-radius: 50%;
  color: var(--white);
  content: "✔";
  height: 20px;
  width: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
  line-height: 2;
  font-size: 0.725rem;
}
.payment-center-btn .active {
  border: 1px solid var(--light-blue) !important;
}
.payment-center-btn .btn.btn-secondary {
  text-align: left;
  background: white;
  color: var(--dark-grey);
  border-radius: 5px;
  padding: 0.825em;
}

.payment-center-text {
  text-align: center;
  white-space: nowrap;
  overflow-wrap: break-word;
  max-width: 100px;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 500;
  color: var(--grey);
  margin: 0.325em auto;
}

.payment-center-btn label.btn.btn-secondary {
  border: 1px solid var(--silver);
}

.payment-center-btn.block-stacked-buttons .btn.btn-secondary {
  border: 1px solid white;
}
.payment-center-btn label {
  border: none;
}
.payment-center-btn.btn-group-toggle.btn-group-vertical.bv-no-focus-ring.not-collapsed
  label.btn.btn-secondary {
  border: 1px solid var(--light-blue);
}
/* 
.payment-center-btn 
.fit-cover {
  object-fit: cover;
  height: 20px;
}
.fit-contain {
  object-fit: contain;
  height: 20px;
  width: 50px;
  margin-right: 0.625rem;
} */

.p_loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.p_loader {
  width: 8em;
  height: 8em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
  border-radius: 50%;
}

.p_message {
  color: gray;
}

.text-overflow-ellipsis {
  display: block;
  width: 100%;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 39em) {
  .payment-center-btn label,
  .payment-center-btn span {
    font-size: 0.6rem;
    font-weight: 500;
  }
  .fit-contain {
    width: 25px;
  }
  .fs-sm {
    font-size: 0.825rem;
  }
}

.three-ds-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.three-ds-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 300px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 820px;
  padding-top: 0.25rem;
  padding-bottom: 3rem;
}

.three-ds-modal__close {
  margin-left: auto;
  cursor: pointer;
  transition: all 0.25s;
  padding: 0 0.5rem;
  color: white;
}

.three-ds-modal__close:hover {
  color: gray;
}

.three-ds-iframe {
  background-color: white;
  border-radius: 1rem;
  border: none;
  width: 100%;
  height: 100%;
}

.card-payment-card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-code {
  display: grid;
  grid-template-columns: 8fr 2fr;
  grid-gap: 3rem;
}

.expiration-date {
  width: 80%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-gap: 0.4rem;
}

.payment-methods {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.payment-method {
  display: flex;
  align-items: center;
  min-height: 86px;
}

.payment-method__img {
  width: 100%;
}
</style>

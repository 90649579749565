

    <template>
  <div>
    <svg width="100%" height="67" viewBox="0 0 1440 67" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g clip-path="url(#clip0_601_3465)">
        <path d="M1440 51.5177L1320 48.1677C1200 44.8177 960 38.1176 720 39.2273C480 40.4207 240 49.2145 120 53.7579L1.7572e-06 58.2176L0 78.3176L120 78.3176C240 78.3176 480 78.3176 720 78.3176C960 78.3176 1200 78.3177 1320 78.3177L1440 78.3177L1440 51.5177Z" fill="#f2ffff" />
      </g>
      <defs>
        <clipPath id="clip0_601_3465">
          <rect width="1440" height="67" fill="white" transform="translate(1440 67.0001) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>  
    <section class="container-fluid light-blue-5-bg py-5 position-relative cb-home-end-section">

      <div class="py-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-sm-12 col-lg-10">
            <div class="text-center">
              <h5 class="dark-blue">Learn from our Chalkboard Tutors</h5>
              <p class="fs-24 mb-5"> Experience quality and personalized learning with our expert tutors.
              </p>
              <a class="cb-book-now-link" href="/search">Book Now</a>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
export default {
  name: "EndSection",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
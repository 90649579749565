import Vue from "vue";
import GAuth from 'vue-google-oauth2'

Vue.use(GAuth, { clientId: '466464042446-v29ic8gsvaduhc0ejn7tsgtpkmdprark.apps.googleusercontent.com' })

export default {
  data: () => ({
    accountType: 'GGACNT',
    isInit: false
  }),
  mounted() {
    let checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit
      if (this.isInit) clearInterval(checkGauthLoad)
    }, 1000);
  },
  methods: {
    async onGoogleLoginClick () {
      const googleUser = await this.$gAuth.signIn()
      const email = googleUser.getBasicProfile().getEmail() || ''
      if (email === '') {
        this.ShowModal('Account is not yet registered or linked into a Chalkboard account.', null, 'Google Login')
        return
      }
      this.onGoogleLogIn(email)
    },
    async onGoogleLogIn(email) {
      const loginFormData = new FormData();
      loginFormData.append("email", email);
      const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/authenticate/login-social-media',
            options = {
              headers: {
                "Accept": "application/json, text/plain, */*",
              }
            }
      const login_response = await this.axios.post(ApiURL, loginFormData, options)
      if (login_response.data.status === 200) {
        const userToken = login_response.data.data.api_token
        window.localStorage.setItem("userToken", userToken);
        this.$store.state.userToken = userToken
        this.UserToken = userToken;
        this.$store.state.userIsAuthorized = true;
        this.$store.dispatch("getUserData", userToken).then(() => {
          this.autoRedirectIfLogged()
        });
      } else if (login_response.data.status === 422) {
        this.ShowModal(login_response.data.message, null, 'Google Log In')
        this.$gAuth.signOut()
      }
    },
    async onGoogleSignIn(role) {
      try {
        if (!role) {
          this.ShowModal('Please select user role.')
          return
        }
        const googleUser = await this.$gAuth.signIn()
        const accessToken = googleUser.getAuthResponse().access_token
        this.getGoogleUserDetails(accessToken, role)
      } catch (error) {
        return null;
      }
    },
    async getGoogleUserDetails(accessToken, role) {
      const vm = this
      const params = ['names','emailAddresses','birthdays','genders','photos']
      let response = await vm.axios.get(`https://people.googleapis.com/v1/people/me?personFields=${params.toString()}&access_token=${accessToken}`)
      
      let _date, _birthday, _email, _firstname, _lastname = '';

      response = response.data

      try {
        _date = response.birthdays[0].date;
        const dateM = _date.month < 10 ? `0${_date.month}` : _date.month
        const dateD = _date.day < 10 ? `0${_date.day}` : _date.day
        _birthday = vm.moment(`${_date.year}-${dateM}-${dateD}`).format('YYYY-MM-DD');
      } catch {
        //
      }
      try { _email = response.emailAddresses[0].value || ''; } catch {
        //
      }
      try { _firstname = response.names[0].givenName || ''; } catch {
        //
      }
      try { _lastname = response.names[0].familyName || '' } catch {
        //
      }

      const signUpParams = {
        birthday: _birthday,
        firstname: _firstname,
        lastname: _lastname,
        email: _email,
        role: role,
        account_type: this.accountType,
      }

      if (_date === '') {
        vm.ShowModal('Chalkboard cannot access your Google birthday information.', null, 'Google Sign Up')
        return
      } else if (_email === '') {
        vm.ShowModal('Chalkboard cannot access your Google email address.', null, 'Google Sign Up')
        return
      }

      const formData = new FormData();
      Object.keys(signUpParams).forEach(key => { formData.append(key, signUpParams[key]) })

      const signup_response = await vm.axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/web/authenticate/signup-social-media`, formData)

      if (signup_response.data.status === 200) {
        vm.ShowModal('Thank you for your registration.', async () => {
          this.onGoogleLogIn(signUpParams.email)
        })
      } else {
        if (signup_response.data.status === 422) {
          this.ShowModal(signup_response.data.message, null, 'Google Sign Up')
          this.$gAuth.signOut()
        }
      }
    },
  }
  
}
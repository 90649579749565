
<template>
  <footer v-if="isFooterVisible">
    <b-container class="dark-blue-bg py-4 px-5em" fluid>

      <b-row class="px-2">
        <b-col sm="12" lg="4" class="pb-2">
          <div class="d-flex justify-content-between flex-column h-100">
            <div>
              <b-img class="img-fluid mt-lg-n3 ml-lg-n4 mb-lg-n3" width="380px" :src="require('@/assets/global/cb_logo.png')">
              </b-img>
              <p class="ml-5 text-white">
                Where <span class="orange">Learners</span> and
                <span class="light-blue">Tutors</span> meet
              </p>
              <div class="pb-3">
                <span class=""><a :href="isRegistrationPage ? '#' : 'https://fb.com/thechalkboardinc'" :target="isRegistrationPage ? '' : '_blank'">
                    <div class="cb-footer-social-icons"><img :src="require('@/assets/imgs/social-icons/Facebook White.png')" alt="Chalkboard Facebook"></div>

                  </a></span>
                <span class=""><a :href="isRegistrationPage ? '#' : 'https://twitter.com/chalkboard_inc'" :target="isRegistrationPage ? '' : '_blank'">
                    <div class="cb-footer-social-icons"><img :src="require('@/assets/imgs/social-icons/Twitter White.png')" alt="Chalkboard Twitter"></div>
                  </a></span>
                <span class=""><a :href="isRegistrationPage ? '#' : 'https://www.instagram.com/thechalkboardinc/'" :target="isRegistrationPage ? '' : '_blank'">
                    <div class="cb-footer-social-icons"><img :src="require('@/assets/imgs/social-icons/Instagram White.png')" alt="Chalkboard Instagram"></div>
                  </a></span>

              </div>
            </div>
            <div>
              <img class="cb-footer-payment-logos" :src="require('@/assets/imgs/payment-logos.svg')">

              <div class="text-white">©{{ new Date().getFullYear() }} TheChalkboard Inc. All rights reserved.</div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" lg="4" class="pb-2">
          <div>
            <div class="cb-footer-lead ">Contact</div>
            <ul class="list-unstyled cb-footer-link-ul">
              <b-media tag="li" vertical-align="center">
                <template #aside>
                  <font-awesome-icon class="cb-footer-links-icons" :icon="['fas', 'at']" />
                </template>
                <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'mailto: support@chalkboard.com.ph'">support@chalkboard.com.ph</a>
              </b-media>
              <b-media tag="li" vertical-align="center">
                <template #aside>
                  <font-awesome-icon class="cb-footer-links-icons" :icon="['fab', 'whatsapp']" style="stroke-width: .8em;
    stroke: white;margin-left: 0.1rem;" />
                </template>
                <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://wa.me/17816615480'">+1 781-661-5480 (WhatsApp)</a>
              </b-media>
              <b-media tag="li" vertical-align="center">
                <template #aside>
                  <font-awesome-icon class="cb-footer-links-icons mr-1" :icon="['fas', 'mobile-alt']" style="stroke-width: .8em;
    stroke: white;" />
                </template>
                <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'tel: +63 908-8107090'">+63 908-8107090</a>
              </b-media>
            </ul>

            <ul class="list-unstyled cb-footer-link-ul">
              <b-media tag="li">
                <template #aside>
                  <font-awesome-icon class="cb-footer-links-icons" :icon="['fas', 'map-marker-alt']" />

                </template>
                <p class="cb-footer-links mt-n1 mb-2" :href="isRegistrationPage ? '#' : 'tel: +63 908-8107090'">HQ: Unit 3, 2nd Floor Bldg 607 Sampson Rd.<br />
                  Subic Bay Freeport Zone, Philippines, 2222</p>
              </b-media>

              <b-media tag="li">
                <template #aside>
                  <font-awesome-icon class="cb-footer-links-icons" :icon="['fas', 'map-marker-alt']" />

                </template>
                <p class="cb-footer-links mt-n1 mb-2" :href="isRegistrationPage ? '#' : 'tel: +63 908-8107090'">2901 29F, One Corporate Center, <br />
                  Meralco Ave. cor. Julia Vargas Ave. Ortigas<br />
                  Center, Pasig City, Philippines 1800</p>
              </b-media>
            </ul>
          </div>
        </b-col>

        <b-col sm="12" lg="4">
          <div class="cb-footer-lead ">Relevant Links</div>

          <ul class="list-unstyled cb-footer-link-ul">
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : '/about'">About</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.tawk.help/'">Help Center</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : '/become-a-tutor'">Become a Tutor</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : '/partner-with-us'">Partner with us</a></li>
            
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://careers.chalkboard.com.ph/'">Careers</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : '/contact-chalkboard'">Contact Us</a></li>
            <hr class="w-25">
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/blogs'">Blogs</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/newsroom'">Newsroom</a></li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/privacy-policy'">Privacy Policy</a></li>
            <li>
              <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/user-terms-and-conditions'">User Terms & Conditions</a>
            </li>
            <li>
              <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/tutor-terms-and-conditions'">Tutor Terms & Conditions</a>
            </li>
            <li>
              <a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/online-tutorial-house-rules'">Online Tutorial House Rules</a>
            </li>
            <li><a class="cb-footer-links" :href="isRegistrationPage ? '#' : 'https://chalkboard.com.ph/pages/service-etiquette'">Service Etiquette</a></li>
            <!-- <li><a class="cb-footer-links" href="#">HTML Sitemap</a></li> -->
          </ul>
        </b-col>
      </b-row>

    </b-container>

  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      RouteNotRequireFooter: [
        "dashboard",
        "profile",
        "inbox",
        "schedule",
        "calendar",
        "sessions",
        "requests",
        "session-history",
        "history",
        "preferences",
        "video-session",
        "preview-session-room",
        "parent-children",
        "sign_up",
        "login",
        "my-vouchers",
        "messages",
        "scribook_learner",
        "scribook"
      ],
      RegistrationRoutes: [
        "teacher_registration",
        "student_registration",
        "parent_registration"
      ]
    };
  },
  computed: {
    isFooterVisible() {
      return this.RouteNotRequireFooter.find((e) => e === this.$route.name)
        ? false
        : true;
    },
    isRegistrationPage(){
      
      return this.RegistrationRoutes.find((e) => e === this.$route.name)
        ? true
        : false;
    } 
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("../assets/css/footer.css");
</style>
<template>
    <div style="padding: 20px; padding-top: 35px;">
        <div class="px-5">
            <b-form-group label="Let the admins know what's wrong with this review" label-for="feedback" size="sm">
                <b-form-select
                    id="recommendation"
                    v-model="selected"
                    size="sm"
                >
                    <b-form-select-option :value="''">-- please select an option --</b-form-select-option>
                    <!-- <b-form-select-option v-for="(rec, index) in recommendationOptions" :key="index" :value="rec.code">{{ rec.recommendation }}</b-form-select-option> -->
                </b-form-select>
                <!-- <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
            </b-form-group>
        </div>
        <div class="px-5">
            <div class="py-2">
                <b-form-group label="Other reasons" label-for="feedback" size="sm">
                <b-form-textarea id="other-reason" v-model="otherReason" rows="8" placeholder="Other inappropriate content not listed above" size="sm" no-resize aria-describedby="password-help-block"></b-form-textarea>
                <b-form-invalid-feedback :state="true">
                    Type at least 20 characters
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block" style="float:right;">
                    {{ otherReasonCharactersRemaining }} <span v-if="otherReasonCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                </b-form-text>
                </b-form-group>
            </div>
        </div>
        <div class="text-center my-5">
            <b-button class="dark-silver-outline-button mx-1 my-1" style="min-width: 250px;" @click="onCancel">Cancel</b-button>
            <b-button class="blue-button font-weight-bold mx-1" style="min-width: 250px;">Send to Admin</b-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReportGetHelp',
        data: () => ({
            selected: null,
            otherReason: '',
            characterLimit: 500
        }),
        mounted() {
            document.getElementById('other-reason').setAttribute("maxlength", this.characterLimit)
        },
        computed: {
            otherReasonCharactersRemaining : {
                get() {
                    const newLimit = this.characterLimit - this.otherReason.length
                    return ((newLimit) < 0) ? 0 : newLimit;
                },
                set(value) {
                    return value
                }
            }
        },
        methods: {
            onCancel() {
                this.$emit("onReportCancel")
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Import Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import axios from "axios";
import VueCompositionAPI from "@vue/composition-api";
import "./config/websocket";
import moment from "./config/moment";
import { CoolSelectPlugin } from "vue-cool-select";
import "vue-cool-select/dist/themes/bootstrap.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "./config/focusTrap";
import metaPixelProvider from "./mixins/pixel/index";
import currencyLocalization from "./plugins/currencyLocalization";
import appModal from "./plugins/appModal";

import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";

import "@/assets/css/root.css";
import "@/assets/css/main.css";
import "@/assets/css/buttons.css";
import "@/assets/css/custom-calendar.css";
import "@/assets/css/custom-tab-container.css";
import "@/assets/css/custom-table.css";
import "@/assets/css/dashboard-breadcrumbs.css";
import "@/assets/css/currency-localization.css";
import "@/assets/css/v-select.css";
import "./assets/scss/main.scss";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("en", en);

library.add(fas, far, fab);
Vue.use(PrimeVue);
Vue.component("Dropdown", Dropdown)
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(CoolSelectPlugin);
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);
Vue.use(moment);
Vue.use(currencyLocalization, { store });
Vue.use(appModal);

Vue.prototype.axios = axios;

Vue.mixin(metaPixelProvider);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

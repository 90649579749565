<template>
  <div v-if="this.ReviewCount">
    <!-- Tutor Reviews  -->
    <div id="tutor-reviews" class="bg-white py-4 px-4 mb-4 rounded shadow-sm">
      <div class="d-flex flex-row align-items-center pb-2" style="border-bottom: 2px solid var(--silver)">
        <b-icon icon="star-fill
" class="mr-1 orange"></b-icon>
        <h6 class="m-0">Ratings & Reviews</h6>
      </div>
      <!-- Reviews Counter -->
      <div>
        <b-row class="p-3" align-h="center">
          <b-col class="text-center p-4 " style="background:rgba(255,189,89, .10);" sm="12" lg="5">
            <div v-if="isRatingLoading"><b-spinner type="grow" small style="color: #212529;"></b-spinner><span style="font-size: 11px; color: #212529;"> loading ratings and reviews ...</span></div>
            <div v-else>
              <div class="font-weight-bold">
                <div class="display-4 font-weight-bold">{{ this.AverageRating || 0 }}</div>
              </div>
              <div>
                <b-form-rating class="font-size-md" icon-empty="star-fill" :value="this.AverageRating || 0" variant="warning" no-border inline readonly></b-form-rating>
              </div>
              <small>Based on {{ this.ReviewCount || 0 }} review{{(this.ReviewCount || 0 > 1 ? 's' : '')}}</small>
            </div>
          </b-col>
          <b-col class="p-4">
            <div class="font-size-md font-weight-bold mb-1">
              Top Rated Subjects
            </div>
            <div>
              <b-table align-v="center" show-empty empty-text="No top rated subjects yet." align-h="center" :items="top_subjects" :fields="topsub_fields" :busy="isBusy">
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> loading top rated subjects...</strong>
                  </div>
                </template>
                <template #cell(subject)="data">
                  <div class="font-weight-bold">
                    <b-link @click="getSubjectReviews(data.item.subject)">{{ data.item.subject }}</b-link>
                  </div>
                </template>
                <template #cell(average_rating)="data">
                  <b-form-rating icon-empty="star-fill" :value="data.item.average_rating" variant="warning" no-border inline readonly></b-form-rating>
                </template>
              </b-table>

            </div>

          </b-col>
        </b-row>
      </div>

      <div v-if="isLoadingTopReviews" style="padding: 10px;">
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> loading top reviews...</strong>
        </div>
      </div>
      <div v-else>
        <div v-if="subject_reviews.length === 0"><span style="color: #C5C5C5; font-size: 12px; margin-left: 10px;"><i> No review for {{ selectedSubject }} subject.</i></span></div>
        <!-- Feedback Media container -->
        <b-media class="py-4" v-for="(item, index) in subject_reviews" :key="index">
          <template #aside>
            <b-img class="rounded-circle" width="70" height="70" alt="placeholder" :src="item.image || require('@/assets/placeholder/profile-placeholder.png')" style="object-fit: cover;"></b-img>
          </template>

          <div class="d-flex flex-row justify-content-between pb-2">
            <div class="w-100">
              <div class="d-flex align-items-center justify-content-between">
                <p class="fs-16 font-weight-bold m-0">{{ item.reviewer_name }}</p>
                <small class="dark-silver">{{ item.review_date ? toLongDateFormat(new Date(item.review_date.split(" ").slice(0, 1))) : '' }}</small>
              </div>
              <b-form-rating class="font-size-sm" style="margin-left: -12px;" icon-empty="star-fill" :value="item.review_rating || 0" variant="warning" no-border inline readonly></b-form-rating>
            </div>
          </div>
          <p class="mb-0 font-size-sm" style="word-break: break-word; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">
            {{ item.review }}
          </p>
        </b-media>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../../../mixins";
import review_mixins from "../../../mixins/review";

export default {
  props: ["teacherData"],
  name: "ProfileTeacherRatingsAndReviews",
  data: () => ({
    AverageRating: 0,
    ReviewCount: 0,
    isRatingLoading: true,
    isBusy: true,
    isLoadingTopReviews: false,
    top_subjects: [],
    topsub_fields: ["subject", "average_rating"],
    subject_reviews: [],
    teacherId: "",
    selectedSubject: '',
    items: []
  }),
  mounted() {},
  methods: {
    getReviewSummary () {
      this.getTeacherReviewSummary(this.items.user_id).then((response) => {
        const _data = response.data;
        this.AverageRating = _data.data[0].average_rating;
        this.ReviewCount = _data.data[0].rating_count;
      }).finally(() => { this.isRatingLoading = false })
    },
    getTopSubjects() {
      this.getTeacherTopSubjects(this.items.user_id, 3).then((data) => {
        const _data = data.data.data;
        this.top_subjects = _data;
        if (_data.length != 0) this.getSubjectReviews(_data[0].subject || "");
      }).finally(() => { this.isBusy = false })
    },
    getSubjectReviews(subject) {
      this.isLoadingTopReviews = true
      this.selectedSubject = subject
      this.getTeacherSubjectReviews(this.items.user_id, subject, 10).then((data) => {
          const _data = data.data.data;
          this.subject_reviews = 
            _data
              .filter(data => { return data.review != null })
              .map((item) => {
                return {
                  reviewer_id: item.reviewer_id,
                  review: item.review,
                  review_date: item.review_date,
                  reviewer_name: item.reviewer_name,
                  subject_name: subject,
                  image: item.image,
                  review_rating: item.rating || 0
                };
          });
        }).finally(() => { this.isLoadingTopReviews = false });
    },
  },
  watch: {
    teacherData(val) {
      this.items = val
      this.getReviewSummary()
      this.getTopSubjects()
    }
  },
  mixins: [mixins, review_mixins],
};
</script>

<style scoped>
.form-control,
.form-control:focus {
  background: transparent;
}
</style>
const crypto = require("crypto");

const secretKey = "178f60f64c2ab9e819619e340043be02";

export const frontapp = {
  signEmail(email) {
    return crypto
      .createHmac("sha256", secretKey)
      .update(email)
      .digest("hex");
  },

  removeCache() {
    document.cookie = "fcuid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "fccid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "fccaid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },

  removeFCPMUCCache() {
    document.cookie = "fcpmuc=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },

  routesToAvoidSpawningIn: [
    "dashboard",
    "profile",
    "inbox",
    "schedule",
    "calendar",
    "sessions",
    "requests",
    "session-history",
    // "history",
    "preferences",
    "parent-children",
    "messages",
    "scribook_learner",
    "scribook",
    // "search",
    // "booking",
    "video-session",
    "preview-session-room"
  ],

  loadScript(routeName) {
    if (!window.FrontChat) {
      const script = document.createElement("script");
      script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
      script.async = true;
      script.onload = () => frontapp.initializeAsGuest(routeName);
      document.body.appendChild(script);
    } else {
      frontapp.initializeAsGuest();
    }
  },

  initializeAsGuest(routeName) {
    frontapp.removeCache();
    frontapp.removeFCPMUCCache();
    window.FrontChat("init", {
      chatId: "ca6a97dd3e203be9a86f5c11d09b1c9d",
      useDefaultLauncher: true,
      name: "Guest"
    });
    frontapp.setWidgetVisibility(routeName);
  },

  initializeAsUser(routeName, email, name) {
    frontapp.removeCache();
    frontapp.removeFCPMUCCache();
    window.FrontChat("init", {
      chatId: "ca6a97dd3e203be9a86f5c11d09b1c9d",
      useDefaultLauncher: true,
      email: email,
      name: name,
      userHash: frontapp.signEmail(email)
    });
    frontapp.setWidgetVisibility(routeName);
  },

  setWidgetVisibility(routeName) {
    if (frontapp.routesToAvoidSpawningIn.includes(routeName)) {
      document.getElementById("front-chat-iframe").style.display = "none";
    } else {
      document.getElementById("front-chat-iframe").style.display = "initial";
    }
  }
};

<template>
    <div>
        <section class="section py-5">
 <div class="container px-5">
 <h1 class="text-center dark-grey mb-4">Online Tutorial House Rules</h1>
 <ul style="list-style: disc outside;margin:auto;width:80%;">
 <li> Look presentable to your learners. Dress comfortably but professionally.
 </li>
 <li> Make sure you have good lighting.
 </li>
 <li> Be on your best angle and maintain proper distance from the camera so your shoulders and your entire face are visible.
 </li>
 <li> Choose a fine and distraction-free teaching environment.
 </li>
 <li> Your video must be always ON for the entire session.
 </li>
 <li> Inform your learners if you need to step out of the camera.
 </li>
 <li> Speak clearly.
 </li>
 <li> Be an active listener.
 </li>
 <li> Use available resources such as props or visual aids for effective delivery of lessons.
 </li>
 <li> Be genuinely confident.
 </li>
 <li> Have an in-depth knowledge of the subject matter you are asked to teach and be able to present it in a fun and engaging manner.
 </li>
 <li> You are expected to be friendly, understanding and patient with your students.
 </li>
 <li> Prepare your topics in advance.
 </li>
 <li> Have a fast and reliable internet connection. Choosing a learning space that is well-lit and free from ambient noise.
 </li>
 <li> To be always on time for each tutorial session.
 </li>

 </ul>















 </div>
</section>
    </div>
</template>
<script>
export default {
  name: "online_tutorial_house_rules",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>

</style>
<template>
  <b-row class="pt-5 px-lg-5 px-2  cb-header" no-gutters align-v="center">
    <b-col md="6" lg="6" class="p-3">
      <div>
        <h3 class="dark-blue">
          Learn
          <span class="cb-change-text ">
            <span class="cb-change-text-slide orange">Math.</span>
            <span class="cb-change-text-slide orange">Coding.</span>
            <span class="cb-change-text-slide orange">Music.</span>
            <span class="cb-change-text-slide orange">Reading.</span>
          </span>
          <br />
          Anytime, anywhere.
        </h3>
        <p class="py-3" style="line-height: 1.5;">
          Personalized learning experience with our expert tutors.
          <br />
          Book your preferred classes now.
        </p>

        <!-- <b-form class="cb-search-tutor-form" id="registration-step1-form" @submit.stop.prevent="onSubmit">
          <input list="my-list-id" v-model="search" type="search" placeholder="Type subject, grade level or tutor's name" />
          <button type="submit" class="cb-search-button">Find A Tutor <b-icon icon="search" class="ml-1"></b-icon></button>
        </b-form> -->

        <div class="homepage-container">
          <div class="homepage-container__card-container">
            <b-link
              to="/search"
              class="homepage-container__card homepage-container__card--search-tutors"
            >
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.43857 4.70015C8.51643 -1.81443 34.4149 -1.31401 39.9991 4.70015C45.5834 10.7143 45.0779 37.2724 39.9991 40.7809C34.9204 44.2894 7.50229 45.7915 3.43857 40.7809C-0.625144 35.7703 -1.63928 11.2147 3.43857 4.70015Z"
                  fill="white"
                />
                <path
                  d="M16.5835 24.1668C16.5835 23.5685 17.0685 23.0835 17.6668 23.0835C18.2651 23.0835 18.7502 23.5685 18.7502 24.1668C18.7502 24.7652 18.2651 25.2502 17.6668 25.2502C17.0685 25.2502 16.5835 24.7652 16.5835 24.1668Z"
                  fill="#00BFE0"
                />
                <path
                  d="M17.6668 27.4165C17.0685 27.4165 16.5835 27.9015 16.5835 28.4998C16.5835 29.0982 17.0685 29.5832 17.6668 29.5832C18.2651 29.5832 18.7502 29.0982 18.7502 28.4998C18.7502 27.9015 18.2651 27.4165 17.6668 27.4165Z"
                  fill="#00BFE0"
                />
                <path
                  d="M20.9165 24.1668C20.9165 23.5685 21.4015 23.0835 21.9998 23.0835C22.5982 23.0835 23.0832 23.5685 23.0832 24.1668C23.0832 24.7652 22.5982 25.2502 21.9998 25.2502C21.4015 25.2502 20.9165 24.7652 20.9165 24.1668Z"
                  fill="#00BFE0"
                />
                <path
                  d="M21.9998 27.4165C21.4015 27.4165 20.9165 27.9015 20.9165 28.4998C20.9165 29.0982 21.4015 29.5832 21.9998 29.5832C22.5982 29.5832 23.0832 29.0982 23.0832 28.4998C23.0832 27.9015 22.5982 27.4165 21.9998 27.4165Z"
                  fill="#00BFE0"
                />
                <path
                  d="M25.25 24.1668C25.25 23.5685 25.735 23.0835 26.3333 23.0835C26.9317 23.0835 27.4167 23.5685 27.4167 24.1668C27.4167 24.7652 26.9317 25.2502 26.3333 25.2502C25.735 25.2502 25.25 24.7652 25.25 24.1668Z"
                  fill="#00BFE0"
                />
                <path
                  d="M26.3333 27.4165C25.735 27.4165 25.25 27.9015 25.25 28.4998C25.25 29.0982 25.735 29.5832 26.3333 29.5832C26.9317 29.5832 27.4167 29.0982 27.4167 28.4998C27.4167 27.9015 26.9317 27.4165 26.3333 27.4165Z"
                  fill="#00BFE0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.4998 11.1668C15.4998 10.5685 15.9849 10.0835 16.5832 10.0835C17.1815 10.0835 17.6665 10.5685 17.6665 11.1668V12.2502H26.3332V11.1668C26.3332 10.5685 26.8182 10.0835 27.4165 10.0835C28.0148 10.0835 28.4998 10.5685 28.4998 11.1668V12.2502H29.5832C31.3781 12.2502 32.8332 13.7052 32.8332 15.5002V30.6668C32.8332 32.4618 31.3781 33.9168 29.5832 33.9168H14.4165C12.6216 33.9168 11.1665 32.4618 11.1665 30.6668V15.5002C11.1665 13.7052 12.6216 12.2502 14.4165 12.2502H15.4998V11.1668ZM26.3332 14.4168V15.5002C26.3332 16.0985 26.8182 16.5835 27.4165 16.5835C28.0148 16.5835 28.4998 16.0985 28.4998 15.5002V14.4168H29.5832C30.1815 14.4168 30.6665 14.9019 30.6665 15.5002V18.7502H13.3332V15.5002C13.3332 14.9019 13.8182 14.4168 14.4165 14.4168H15.4998V15.5002C15.4998 16.0985 15.9849 16.5835 16.5832 16.5835C17.1815 16.5835 17.6665 16.0985 17.6665 15.5002V14.4168H26.3332ZM13.3332 20.9168V30.6668C13.3332 31.2652 13.8182 31.7502 14.4165 31.7502H29.5832C30.1815 31.7502 30.6665 31.2652 30.6665 30.6668V20.9168H13.3332Z"
                  fill="#00BFE0"
                />
              </svg>

              <div class="homepage-container__card-text">
                <h4>Book a session</h4>
                <span>Browse our roster of expert tutors</span>
              </div>

              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="ml-auto"
              />
            </b-link>

            <a
              href="https://forms.gle/TehFUZdpYBHpeVUY8"
              target="_blank"
              class="homepage-container__card"
            >
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1316_397)">
                  <path
                    opacity="0.15"
                    d="M10.0428 10.7842C10.7661 9.85619 12.3082 9.10691 14.407 8.60306C16.4782 8.10586 18.9888 7.87154 21.5227 7.89184C24.0559 7.91213 26.5907 8.18675 28.7086 8.69546C30.8515 9.21018 32.477 9.94365 33.2879 10.817C34.1028 11.6946 34.7495 13.4032 35.1792 15.6158C35.6039 17.802 35.8003 20.3896 35.7798 22.9351C35.7592 25.4813 35.5218 27.9644 35.0883 29.9423C34.8715 30.9319 34.6086 31.7808 34.3071 32.4429C34.0007 33.1156 33.6777 33.5437 33.3701 33.7562C33.0192 33.9986 32.434 34.248 31.6332 34.4844C30.8421 34.7179 29.876 34.9285 28.7898 35.1088C26.618 35.4694 23.9954 35.7042 21.3897 35.7653C18.7821 35.8265 16.2096 35.7135 14.1341 35.3856C13.0957 35.2215 12.1965 35.0059 11.4851 34.7386C10.7631 34.4673 10.2878 34.1622 10.0367 33.8526C9.75571 33.5061 9.47647 32.9562 9.21758 32.2155C8.96109 31.4817 8.73487 30.5908 8.54519 29.5866C8.16586 27.5786 7.93868 25.1499 7.89744 22.6841C7.85619 20.218 8.00121 17.7305 8.35964 15.6035C8.72151 13.4561 9.28928 11.7508 10.0428 10.7842Z"
                    stroke="#00BFE0"
                  />
                  <path
                    d="M28.8611 27.1034L26.0482 24.2905C25.9213 24.1636 25.7491 24.093 25.5686 24.093H25.1087C25.8874 23.0971 26.3501 21.8444 26.3501 20.4817C26.3501 17.24 23.7234 14.6133 20.4817 14.6133C17.24 14.6133 14.6133 17.24 14.6133 20.4817C14.6133 23.7234 17.24 26.3501 20.4817 26.3501C21.8444 26.3501 23.0971 25.8874 24.093 25.1087V25.5686C24.093 25.7491 24.1636 25.9213 24.2905 26.0482L27.1034 28.8611C27.3686 29.1263 27.7974 29.1263 28.0598 28.8611L28.8583 28.0627C29.1235 27.7974 29.1235 27.3686 28.8611 27.1034ZM20.4817 24.093C18.487 24.093 16.8704 22.4792 16.8704 20.4817C16.8704 18.487 18.4842 16.8704 20.4817 16.8704C22.4764 16.8704 24.093 18.4842 24.093 20.4817C24.093 22.4764 22.4792 24.093 20.4817 24.093Z"
                    fill="#00BFE0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1316_397">
                    <rect
                      width="28.8906"
                      height="28.8906"
                      fill="white"
                      transform="translate(7.39062 7.39062)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <div class="homepage-container__card-text">
                <h4>Find me a tutor</h4>
                <span>Our team can help you find a match</span>
              </div>

              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="ml-auto"
              />
            </a>
          </div>
        </div>

        <datalist id="my-list-id">
          <option
            v-for="(item, index) in subject_keywords"
            :key="'keyword-' + index"
          >
            {{ item }}
          </option>
        </datalist>
      </div>
    </b-col>
    <b-col md="6" lg="6">
      <img
        class="w-100 py-5"
        :src="require('@/assets/imgs/home/Header - Homepage.png')"
        alt=""
      />
    </b-col>
  </b-row>
</template>

<script>
import commonmixins from "../../../mixins";

export default {
  name: "HeaderBanner",

  data: () => ({
    search: "",
    subject_keywords: []
  }),

  mounted() {
    let cbheader = document.querySelector(".cb-header");
    let promobanner = document.querySelectorAll(".promotional-banner");
    if (promobanner.length >= 1) {
      cbheader.style.marginTop = "130px";
    } else {
      cbheader.style.marginTop = "75px";
    }

    var slideIndex = 0;
    showSlides();

    function showSlides() {
      let i;
      let slides = document.getElementsByClassName("cb-change-text-slide");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      if (slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "inline-block";
      }
      setTimeout(showSlides, 2500);
    }
  },
  methods: {
    onSubmit() {
      const payload = {
        eventName: "Search",
        ...(this.search && { data: { search_string: this.search } })
      };
      this.metaPixelTrack(payload);
      this.$router.push("/search?search_result=" + this.search + "&lvlcat=");
    },
    softSearch() {}
  },
  mixins: [commonmixins],
  watch: {
    search: function(val, old_val) {
      if (val != old_val) {
        const ApiURL =
          process.env.VUE_APP_BACKEND_URL +
          "/api/web/maintenance/get-subject-keywords?query=" +
          val;

        const options = {
          headers: this.getGetRequestHeaders(),
          mode: "cors"
        };

        this.axios.get(ApiURL, options).then((data) => {
          const _data = data.data;
          this.subject_keywords = _data.data;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.homepage-container {
  &__card-container {
    display: flex;
    gap: 1.35rem;
    margin-bottom: 2em;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__card {
    border-radius: 0.8rem;
    border: 1px solid transparent;
    padding: 1rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    border-color: var(--light-blue);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &--search-tutors {
      background-color: var(--light-blue);
      box-shadow: 0px 0px 20px rgba(0, 191, 224, 0.2);

      svg {
        color: white !important;
      }
    }

    svg {
      color: var(--light-blue);
    }

    &:first-child:hover {
      background-color: #02a5c1;
    }

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 191, 224, 0.2);
    }
  }

  &__card--search-tutors &__card-text {
    color: white;
  }

  &__card-text {
    color: var(--light-blue);
    display: inline-flex;
    flex-direction: column;

    h4 {
      font-size: calc(var(--fs-one) + 2px);
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    span {
      font-size: 0.8rem;
    }
  }
}

.cb-header {
  margin-top: 130px;
  background: var(--light-blue-10);
}
.cb-change-text-slide {
  font-size: 52px;
}

@media screen and (max-width: 300px) {
  .cb-header {
    margin-top: 25vh !important;
  }
  .cb-header h3,
  .cb-header .cb-change-text-slide {
    font-size: var(--fs-two) !important;
  }
}

@media screen and (max-width: 800px) {
  /*hide for now .cb-header {
    margin-top: 185px !important ;
  }*/
  .cb-change-text-slide {
    font-size: var(--fs-three);
  }
}

@media screen and (max-width: 1025px) and (min-width: 767px) {
  .cb-search-tutor-form input[type="search"] {
    width: 55% !important;
  }
}

@media screen and (max-width: 1440px) {
  .cb-header h3,
  .cb-header .cb-change-text-slide {
    font-size: var(--fs-two-six);
  }

  .cb-search-tutor-form button[type="submit"],
  .cb-search-tutor-form input[type="search"] {
    font-size: calc(var(--fs-one) - 4px) !important;
  }
}
</style>

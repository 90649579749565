import moment from 'moment-timezone';

export default {
  install: (Vue) => {
    Vue.prototype.moment = moment;
    Vue.prototype.guestTz = moment.tz.guess();

    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s:  '1s',
        ss: '%ss',
        m:  '1m',
        mm: '%dm',
        h:  '1h',
        hh: '%dh',
        d:  '1d',
        dd: '%dd',
        M:  '1M',
        MM: '%dM',
        y:  '1Y',
        yy: '%dY'
      }
    })
    
  }
}


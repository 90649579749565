<template>
  <div class="position-relative">
    <b-container class="py-5 ">
      <div id="tutor_registration_container" class="py-3">
        <div class="font-size-xl text-center mb-3"><strong>Tutor Registration</strong></div>
        <ul id="progressbar">
          <li :class="{ active: isActiveClass(1) }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'user']" />
            </div>
            <strong>Profile</strong>
          </li>
          <li :class="{ active: isActiveClass(2) }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'search']" />
            </div>
            <strong>Screening</strong>
          </li>
          <li :class="{ active: isActiveClass(3) }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'user-check']" />
            </div>
            <strong>Verification</strong>
          </li>
        </ul>
      </div>
      
      <registration-teacher-step-1 v-if="isStep1"/>
      <registration-teacher-step-2 v-if="isStep2" :userItems="userData"/>
      <registration-teacher-step-3 v-if="isStep3" />

    </b-container>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TSTAT from '../../../../mixins/tutor-status'
  import RegistrationTeacherStep1 from "./multistep/RegistrationTeacherStep1.vue";
  import RegistrationTeacherStep2 from "./multistep/RegistrationTeacherStep2.vue";
  import RegistrationTeacherStep3 from "./multistep/RegistrationTeacherStep3.vue";
  export default {
    name: 'TutorRegistration',
    components: { RegistrationTeacherStep1, RegistrationTeacherStep2, RegistrationTeacherStep3 },
    computed: {
      ...mapState(['userData']),
      isStep1() {
        return [TSTAT.DRAFT, TSTAT.RETURN_APPLICATION].includes(this.userData.status) && this.userData.account_step === 1
      },
      isStep2() {
        return ([TSTAT.DRAFT, TSTAT.RETURN_APPLICATION].includes(this.userData.status) && this.userData.account_step === 2) || [TSTAT.NEW, TSTAT.RESUBMIT, TSTAT.ORIENTATION, TSTAT.REJECTED, TSTAT.FOR_SCREENING].includes(this.userData.status)
      },
      isStep3() {
        return [TSTAT.ACTIVE].includes(this.userData.status)
      }
    },
    methods: {
      isActiveClass(step) {
        return  this.isStep1 && step === 1 ? true :
                this.isStep2 && step <= 2 ? true :
                this.isStep3 && step <= 3 ? true : false
      }
    },
    mounted() {
      this.$nextTick(() => {
        document.getElementById("navbar-logo").removeAttribute("href")
      })
    }
  }
</script>

<style scoped>
.step-icon-container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--silver);
  display: flex;
  align-items: center;
  justify-content: center;
}
#progressbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding-left: unset;
  overflow: hidden;
  color: lightgrey;
  width: 100%;
}

#progressbar .active .step-icon-container {
  color: #fff;
  background: var(--light-blue);
}

#progressbar .active {
  color: var(--dark-grey);
}

#progressbar li {
  color: var(--dark-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  width: 33.33%;
  position: relative;
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 3px;
  background: var(--dark-silver);
  position: absolute;
  right: 50%;
  top: 25px;
  z-index: -1;
}
#progressbar li:first-child:after {
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--light-blue);
}
</style>
<template>
  <div>
    <div class="who-we-are image-parallax"></div>
    <b-container class="py-3 font-weight-bold">
      <h1 class="light-blue mb-6">Who We Are</h1>
      <p class="">
        Chalkboard is an online marketplace for education that connects students
        to teachers. We aim to be the preferred venue for Filipino K-12 learners
        and college students in their quest for learning.
      </p>
      <p class="">
        Chalkboard is the Philippines&#39; first end-to-end online learning
        solution that gives you access to expert-level tutors at the comfort of
        your home. You can enjoy quality mentoring based on your schedule and
        budget preference.
      </p>

      <p class="">
        You can access our easy-to-use platform complete with reliable digital
        tools. Each of which are designed to facilitate a smooth and easy search
        for resources that best matches students’ learning styles and academic
        goals.
      </p>
    </b-container>

    <!-- Second -->

    <div class=" who-we-are-2 image-parallax">
      <div class="" style="max-width: 550px;
    margin-left: auto;
    margin-right: 5rem;">
        <p class=" py-2">
          It is our passion to provide the best learning experience to our
          <span class="light-blue">learners</span>, by service above and beyond.
        </p>
      </div>
    </div>

    <!-- Mission / Vission -->
    <section class="light-blue-bg">
      <div class="container py-5 ">
        <div
          class="mission"
          data-aos="fade-right"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          <h3 class="mb-4 text-white ">
            Our Mission
          </h3>
          <p class="content ">
            We at Chalkboard aim to create an online platform to help parents
            and learners connect to the best tutors and to serve as the
            preferred venue for quality learning experience.
          </p>
        </div>
      </div>
    </section>

    <section class="orange-bg">
      <div class="container  py-5">
        <div
          class="vision"
          data-aos="fade-left"
          data-aos-delay="20"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          <h3 class="mb-4 text-white text-right">
            Our Vision
          </h3>
          <p class="content text-right">
            Our vision is to provide every student access to excellent education
            and educators.
          </p>
        </div>
      </div>
    </section>

    <!-- Our Story 1 -->
    <div class=" our-story-1 image-parallax"></div>

    <section class="">
      <div
        class="container px-4 py-5 text-center"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <h1 class="light-blue mb-4">Our Story</h1>
        <p class="font-weight-bold">
          Chalkboard aims to improve access to high quality of education by
          enabling the country&#39;s best tutors to reach more learners.
        </p>
        <p class="font-weight-bold">
          The internet has revolutionized so many parts of our lives, shopping,
          entertainment, and communication. However, how we access education has
          remained largely unchanged for centuries. The health crisis of 2020
          exposed the limitations of this and requires swift evolution in order
          to ensure that our learners aren&#39;t left behind.
        </p>
      </div>
    </section>

    <!-- Our Story 2 -->
    <div class=" our-story-2 image-parallax"></div>
    <section class="our-story-2-content ">
      <div class="container">
        <h1
          class="light-blue py-4 px-4"
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          In April 2020
        </h1>
        <p
          class="font-weight-bold px-4 pb-5"
          data-aos="fade-up"
          data-aos-delay="40"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          Kristine Encio-Brown, Albert Tablan and members of their high school
          class organized COVID-relief efforts for the benefit of the hospitals,
          children, and teachers in their hometown. As it became clear that
          COVID was only expanding and the impact on our lives accelerating,
          they realized the significant impact this would have on our
          country&#39;s learners.
        </p>
      </div>
    </section>

    <!-- Our Story 3 -->
    <div class=" our-story-3 image-parallax"></div>
    <section class="our-story-3-content ">
      <div class="container">
        <h1
          class="light-blue py-4 px-4"
        
        >
          In May 2020
        </h1>
        <p
          class="font-weight-bold text-right px-4 pb-5"
          data-aos="fade-up"
          data-aos-delay="40"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
        >
          Chalkboard was born from an urgency and passion to bring high quality
          education to every single student. Chalkboard provides a digital
          platform to expand the reach of our country&#39;s best tutors and
          builds a foundation for education in the twenty-first century.
        </p>
      </div>
    </section>

    <!-- Our Story 4 -->
    <section class="teal-bg py-5">
      <b-container>
          <div class="w-100">
          <div class="">
            <figure class="image">
              <img class="w-100" src="../assets/global/our_story_4.jpg" alt="Our Story" />
            </figure>
          </div>
        </div>
        <b-row align-v="center">
          <b-col> <h3 class="text-white">
              Get Started<br />
              with Chalkboard
            </h3></b-col>
          <b-col>  <a
                class="font-weight-bold cb-cta-fullwidth"
                href="/signup"
                >Sign Up Now</a
              ></b-col>
        </b-row>
      </b-container>

    </section>
  </div>
</template>

<script>
export default {
  name: "About",
  metaInfo: {
    title: "Chalkboard - About Chalkboard",
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-parallax {
    background: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    min-height: 525px;
    height: 100vh;
    width: 100%;
}
.who-we-are {
  background-image: url(../assets/global/who_we_are.jpg);
  background-size: cover;
  height: 110vh;
}

.who-we-are-2 {
  position: relative;
  height: 110vh;
  background-position: top center;
  background-image: url(../assets/global/who_we_are_2.jpg);
}


.who-we-are-2 div {
  font-weight: bold;
  position: absolute;
  top: 30%;
  right: 5%;
 background: var(--white);
    border-radius: var(--border-radius-5);
    padding: 2rem;
}

.our-story-1 {
  background-image: url(../assets/global/our_story_1.jpg);
}

.our-story-2 {
  background-image: url(../assets/global/our_story_2.jpg);
  background-position: center;
}
.our-story-3 {
  background-image: url(../assets/global/our_story_3.jpg);
  background-position: bottom;
}
.our-story-2-content,
.our-story-3-content {
  margin-top: -90px;
}
.our-story-2-content h1,
.our-story-3-content h1 {
  display: table;
  background: white;
  border-radius: var(--border-radius-5);
}
.our-story-3-content h1 {
  margin-left: auto;
}

.mission,
.vision {
  color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  line-height: 1.2;
}
.mission::after,
.vision::after {
  position: absolute;
  top: 0;
  content: "";
  height: 100%;
  border-left: 5px solid white;
  border-radius: var(--border-radius-5);
}
.mission::after {
  left: 0;
}
.vision::after {
  right: 0;
}
.cb-cta-fullwidth {
  font-weight: bold;
  padding: 1rem 3rem;
  color: var(--dark-grey);
  background: var(--orange);
  border-radius: var(--border-radius-5);

    text-align: center;
    display: block;
    width: 100%;
}
</style>
import mixins from '.';

export default {
    data: () => ({
        // For development purposes only. [enableMultiStep, multiStep]
        enableMultiStep: true,
        multiStep: {
            1: false,
            2: false,
            3: false,
            4: false
        },
        isDataShowed: false,
        mainProps: { blank: true, blankColor: '#777', width: 128, height: 128, class: 'm1' },
        imageUrl: require('@/assets/placeholder/profile-placeholder.png'),
        resumeFileName: '',
        governmentIDFileName: '',
        gwaCertFileName: '',
        TORDiplomaFileName: '',
        coeFileName: '',
        certificateFileName: '',
        nbiFileName: '',
        UserToken: window.localStorage.getItem("userToken"),
        form: {
            basicInformation: {
                image: null,
                email: '',
                birthday: '',
                firstname: '',
                lastname: '',
                mobile_no: '',
                street: '',
                brgy: '',
                city: '',
                province: '',
                zip_code: '',
                legal_firstname: '',
                legal_lastname: '',
                pn_country_code: '',
                whatsapp: '',
                wa_country_code: '',
                address1: '',
                address2: '',
                country: '',
                formattedPhoneNumber: "",
                formattedWhatsAppNumber: "",
                pn_area_code: "",
                wa_area_code: ""
            },
            background: {
                tagline: '',
                about_me: '',
                education: '',
                academic_affiliation: '',
                language: '',
                academic_degree: null,
                college_university: '',
                other_specializations: '',
                my_teaching_style: '',
                facebook: '',
                youtube: '',
                tiktok: '',
                linkedin: ''
            },
            resume: null,
            government_id: null,
            gwa_cert: null,
            tor_diploma: null,
            coe: null,
            certificate: null,
            nbi: null,
            primary_bank: null,
            pb_account_name: '',
            pb_account_type: null,
            pb_account_no: '',
            confirm_pb_account_no: '',
            secondary_bank: null,
            sb_account_name: '',
            sb_account_type: null,
            sb_account_no: '',
            confirm_sb_account_no: ''
        },
        allSelected: false,
        selected: [],
        specialization: [],
        options: [
            { value: null, text: '-- select --', disabled: true },
            { value: '1', text: 'Grade 1', disabled: false },
            { value: '2', text: 'Grade 2', disabled: false },
            { value: '3', text: 'Grade 3', disabled: false },
            { value: '4', text: 'Grade 4', disabled: false },
            { value: '5', text: 'Grade 5', disabled: false },
            { value: '6', text: 'Grade 6', disabled: false },
            { value: '7', text: 'Grade 7', disabled: false },
            { value: '8', text: 'Grade 8', disabled: false },
            { value: '9', text: 'Grade 9', disabled: false },
            { value: '10', text: 'Grade 10', disabled: false },
            { value: '11', text: 'Grade 11', disabled: false },
            { value: '12', text: 'Grade 12', disabled: false },
            { value: '13', text: "Preschool", disabled: false },
            { value: '14', text: "Tech", disabled: false },
            { value: '15', text: "Music", disabled: false },
            { value: '16', text: "Languages", disabled: false },
            { value: '17', text: "Art", disabled: false },
            { value: '18', text: "Others", disabled: false }
        ],
        tagsOptions: [],
        availability: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        AvailabilitySchedule: [],
        timeSlots: [],
        accept_bundle: true,
        is_discount: false,
        isLoading: false,
        imageFileName: '',
        selectedItemIndex: [],
        isPeerSelected: false,
        isPeerTutor: 0,
        accountStep: 0,
        tempSubjPerGradeLevel: [],
        guestTZ: null,
        regionTzName: null,
        eventPNData: {},
        eventWAData: {}
    }),
    mounted() {
        const vm = this
        vm.$watch('$store.state.userData', (data) => {
            vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone
            vm.regionTzName = data.timezone == null ? vm.getTzRegion(vm.guestTz) : data.region_timezone
            this.$store.dispatch("getTimeSlots", vm.guestTZ)
        }, { immediate: true })
    },
    computed: {},
    mixins: [mixins],
    methods: {
        onPeerTutorSelect(value) {
            this.isPeerTutor = value
            this.isPeerSelected = true
            document.getElementById("tutor_registration_container").style.display = "block"
            this.updatePeerStatus(value)
        },
        getSubjectPerGradeLevel (gradeLevel) {
            return this.axios.get(process.env.VUE_APP_BACKEND_URL + `/api/web/maintenance/get-subject-categories?gradelevel=${gradeLevel}`)
        },
        loadUserData() {
            this.isLoading = true
            this.$store.dispatch("getUserData", window.localStorage.getItem("userToken")).then(response => {
                const _data = response.data.data
                if (_data.role === 'teacher') {
                    this.isPeerTutor = _data.is_peer
                    this.imageUrl = (_data.image === null) ? require('@/assets/placeholder/profile-placeholder.png') : _data.image;
                    this.form = {
                        basicInformation: {
                            email: _data.email,
                            birthday: _data.birthday,
                            firstname: _data.firstname,
                            lastname: _data.lastname,
                            mobile_no: _data.mobile_no,
                            street: _data.street,
                            brgy: _data.brgy,
                            city: _data.city,
                            province: _data.province,
                            zip_code: _data.zip_code,
                            legal_firstname: _data.legal_firstname,
                            legal_lastname: _data.legal_lastname,
                            pn_country_code: _data.pn_country_code || 'US',
                            pn_area_code: _data.pn_area_code || '',
                            whatsapp: _data.whatsapp,
                            wa_country_code: _data.wa_country_code || 'US',
                            wa_area_code: _data.wa_area_code || '',
                            address1: _data.address1,
                            address2: _data.address2,
                            country: _data.country
                        },
                        background: {
                            tagline: _data.tagline,
                            about_me: _data.about_me,
                            education: _data.education,
                            academic_affiliation: _data.academic_affiliation || "",
                            language: _data.language,
                            academic_degree: _data.academic_degree,
                            college_university: _data.college_university,
                            other_specializations: _data.other_specializations,
                            my_teaching_style: _data.my_teaching_style,
                            facebook: _data.facebook,
                            youtube: _data.youtube,
                            tiktok: _data.tiktok,
                            linkedin: _data.linkedin
                        },
                        price_tier_id: _data.price_tier_id
                    }
    
                    this.form.primary_bank = _data.primary_bank
                    this.form.pb_account_name = _data.pb_account_name
                    this.form.pb_account_type = _data.pb_account_type
                    this.form.pb_account_no = _data.pb_account_no
                    this.form.secondary_bank = _data.secondary_bank
                    this.form.sb_account_name = _data.sb_account_name
                    this.form.sb_account_type = _data.sb_account_type
                    this.form.sb_account_no = _data.sb_account_no
    
                    this.imageFileName = (_data.image == null) ? "" : _data.image.substr(_data.image.lastIndexOf("/") + 1, _data.image.length - _data.image.lastIndexOf("/"))
    
                    let lastIndex = -1;
    
                    const subjects = [...new Set(_data.subject.map(item => item.level))]
    
                    subjects.forEach(x => {
                        if (this.specialization.length > 0) {
                            lastIndex = this.specialization[this.specialization.length - 1].index;
                        }
                        const selectedSubjects = _data.subject.filter(e => { return e.level == x }).map(item => item.name)
                        const sendGetRequest = async () => {
                            try {
                                const response = await this.getSubjectPerGradeLevel(x);
                                const _data = response.data.data
                                const result = [...new Set(_data.map(item => item.category))]
                                this.specialization.push({ index: lastIndex + 1, grade_level: x, subjects: selectedSubjects, subjectsPerGradeLevel: result });
                                lastIndex = lastIndex + 1;
                                this.$nextTick(() => this.specialization.sort((a, b) => a.grade_level - b.grade_level))
                            } catch (err) {
                                console.error(err);
                            }
                        }
                        sendGetRequest()
                    })
    
                    subjects.forEach(e => {
                        this.options.find(x => {
                            return x.value == e
                        }).disabled = true
                    })
    
                    const newScheduleList = []
    
                    this.availability.forEach(x => {
                        _data.schedule.filter(item => { return item.day == x }).map(item => {
                            const currDate = this.moment().tz(this.guestTZ).day(x)
                            let UTCDateStart = this.moment.tz(`${currDate.format('YYYY-MM-DD')} ${item.start_time}`, 'UTC').tz(this.guestTZ)
                            let UTCDateEnd = this.moment.tz(`${currDate.format('YYYY-MM-DD')} ${item.end_time}`, 'UTC').tz(this.guestTZ)
                            newScheduleList.push({
                                day: UTCDateStart.format('dddd'),
                                start_time: UTCDateStart.format('HH:mm:ss'),
                                end_time: UTCDateEnd.format('HH:mm:ss')
                            })
                        })
                    })
    
                    const schedules = [...new Set(newScheduleList.map(item => item.day))]
    
                    this.availability.forEach(x => {
                        let timeSlots = []
                        if (schedules.filter(s => { return s == x }).length > 0) {
                            timeSlots = newScheduleList.filter(item => { return item.day == x }).map(item => {
                                const currDate = this.moment().tz(this.guestTZ).day(x)
                                const convertedDateStart = currDate.format('YYYY-MM-DD') + ' ' + item.start_time
                                return {
                                    time: item.start_time + '-' + item.end_time,
                                    morningSlot: this.numRange(0, 12).includes(parseInt(this.moment(convertedDateStart).format('H'))) ? true : false,
                                    afternoonSlot: this.numRange(12, 18).includes(parseInt(this.moment(convertedDateStart).format('H'))) ? true : false,
                                    eveningSlot: this.numRange(18, 24).includes(parseInt(this.moment(convertedDateStart).format('H'))) ? true : false
                                }
                            })
                        }
                        this.AvailabilitySchedule.push({
                            day: x,
                            items: {
                                morning: timeSlots.filter(item => { return item.morningSlot }).map(item => item.time), 
                                afternoon: timeSlots.filter(item => { return item.afternoonSlot }).map(item => item.time), 
                                evening: timeSlots.filter(item => { return item.eveningSlot }).map(item => item.time)
                            }
                        })
                    })
                }
            }).finally(() => { this.isLoading = false })
        }
    }
}
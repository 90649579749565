<template>
  <div>
    <div class="scribook-entry-grid-container" :class="`${setDisplayView}`">
      <b-card class="scribook-card-container" v-for="(item, index) in data" :key="index" v-b-toggle.scribook-entry-sidebar>
        <div class="">
          <div @click="emitSelectedItem(item, index)">
            <div class="scribook-card-pill-container">
              <div class="d-inline-flex">
                <b-badge pill :key="index" class="scribook-card-pill" v-for="(pill, index) in item.subjectCardPills" :class="pill.isOpGrade ? getAssessmentClass(item.overall_assessment) : ''">{{ pill.title }}</b-badge>
               <!-- Show if Entry is Private or Locked -->
              <div v-if="item.entryType !== 'PUBLIC'" v-b-tooltip.hover :title="`${ item.entryType === 'PUBLIC' ? 'Public' : 'Private' }`">
                <font-awesome-icon class="ml-1 grey" style="font-size:10px;"  :icon="['fas', `${ item.entryType === 'PUBLIC' ? 'lock-open' : 'lock' }`]" />
              </div>
              </div>
              <img v-if="item.overall_assessment == 'SCRBBG'" v-b-tooltip.hover title="The learner has limited understanding; prerequisite and fundamental knowledge and/or skills still have to be developed adequately to aid understanding." class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_1_Beginning.svg" alt="">
          <img v-if="item.overall_assessment == 'SCRBDV'" v-b-tooltip.hover title="The learner possesses the minimum knowledge, skills and core understandings, but needs help throughout the performance of authentic task." class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_2_Developing.svg" alt="">
          <img v-if="item.overall_assessment == 'SCRBAP'" v-b-tooltip.hover title="The learner has developed fundamental knowledge, skills and core understanding, and with little guidance from the tutor and/or some assistance, can transfer these understandings throughout authentic performance task or written output." class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_3_Approaching_Proficiency.svg" alt="">
          <img v-if="item.overall_assessment == 'SCRBPR'" v-b-tooltip.hover title="The learner has developed fundamental knowledge, skills and core understanding, and can transfer them independently through authentic performance task or written output." class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_4_Proficient.svg" alt="">
          <img v-if="item.overall_assessment == 'SCRBAD'" v-b-tooltip.hover title="The learner exceeds the core requirements in terms of knowledge, skills and understanding and can transfer them automatically and flexibly through authentic performance task, written works and outputs." class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_5_Advanced.svg" alt="">  
             
            </div>

            <!-- Session Date & Time-->
            <div class="scribook-card-time-container  dark-silver">{{ `${moment.utc(item.bookings.start_date).tz(userData.timezone).format('LL h:mm A')} (${userData.region_timezone})` }}</div>

            <!-- Topic -->
            <div class="scribook-topic">{{item.topic}}</div>

            <!-- Answer to What did the learner learn today -->
            <p class="scribook-desc">{{item.questionnaire[0].answer}}</p>

            <!-- Created Date & Time-->

          </div>
        </div>
        <template #footer>
          <div class="scribook-card-time-container created-date dark-silver">Created {{ `${moment.utc(item.createdAt).tz(userData.timezone).format('LL')} (${userData.region_timezone})` }}</div>

          <hr>
          <!-- Attachments -->
          <div class="scribook-attachment-container">
            <!-- Scribook Summary -->
            <div class="scribook-summary">
              <div class="d-flex align-items-center">
                <b-avatar :src="item.tutor.image" size="2em" class="mr-1" v-b-tooltip.hover :title="item.tutor.name" button @click="onViewProfile(item.tutor.id)"></b-avatar>
                <b-avatar :src="item.learner.image" size="2em" class="mr-1" v-b-tooltip.hover :title="item.learner.name"></b-avatar>
              </div>
            </div>

            <!-- tooltip Number of attachments -->
            <div class="scribook-attachment" v-b-tooltip.hover @click="$emit('showFileStorageSidebar')" :title="`${item.attachments.length} ${ item.attachments.length > 1 ? 'attachments' : 'attachment' }`" v-if="item.attachments.length >= 1">
              <font-awesome-icon class="" :icon="['fas', 'paperclip']" />
            </div>
          </div>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AOPT from "../../store/modules/assessment_options";
export default {
  props: {
    data: Array,
    displayView: String,
  },
  name: "ScribookSubjectTabContent",
  data: () => ({}),
  computed: {
    ...mapState(["userData"]),
    setDisplayView() {
      return this.displayView === "Grid" ? "se-grid" : "";
    },
  },
  mounted() {},
  methods: {
    emitSelectedItem(item, index) {
      this.$emit("selectedItem", { item: item, index: index });
    },
    getAssessmentClass(assessment) {
      switch (assessment) {
        case AOPT.BEGINNING:
          return "scribook-op-grade b";
        case AOPT.DEVELOPING:
          return "scribook-op-grade d";
        case AOPT.APPROACHING_PROFICIENCY:
          return "scribook-op-grade ap";
        case AOPT.PROFICIENT:
          return "scribook-op-grade p";
        case AOPT.ADVANCED:
          return "scribook-op-grade a";
      }
    },
    onViewProfile(tutorId) {
      if (this.userData.role !== "teacher") {
        window.localStorage.setItem("tutorId", tutorId);
        let routeData = this.$router.resolve({
          name: "search_teacher_profile",
        });
        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.created-date {
  font-size: calc(var(--fs-one) - 6px);
  display: none;
}
.assessment-img {
  width: 2.4rem;
  display: none;
}
.scribook-entry-grid-container.se-grid .assessment-img  {
  display: block;
}
</style>

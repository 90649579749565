<template>
  <div>
    <div class="d-lg-flex  align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold ">
        Your Scribook
      </div>
    </div>

    <!-- Scribook Children -->
    <div class="children-avatar-container">
      <div class="child-card">
        <b-avatar size="4em" class="mb-2"></b-avatar>
        <div class="child-card-name">Learner Name</div>
      </div>
      <div class="child-card active">
        <b-avatar size="4em" class="mb-2"></b-avatar>
        <div class="child-card-name">Learner Name</div>
      </div>
    </div>
    <b-row>

      <b-col md="12" lg="12">
        <b-card no-body class="p-4  mb-4" style="border:none; box-shadow: 0px 0px 14px 0px rgba(233, 233, 233, 0.5);">
          <!-- Search & Filter -->
          <b-row class="scribook-search-filter">
            <b-col md="5" lg="5" class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <!-- Search Input -->
                <b-input-group class="w-100">
                  <b-form-input type="search" placeholder="Search any keywords"></b-form-input>
                  <b-input-group-append is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>
            <b-col md="7" lg="7" class="mb-4">
              <!-- Date Added & List/Grid View -->
              <div class="d-flex align-items-center justify-content-between w-100">
                <!-- Date Added -->
                <div class="d-flex align-items-center">
                  <div class=" font-weight-normal dark-silver pr-2" for="date-added" style="white-space: nowrap;">Date Added:</div>
                  <b-form-select class="w-100" style="min-width:10em;" id="date-added">
                    <b-form-select-option>Latest</b-form-select-option>
                  </b-form-select>
                </div>
                <!-- & List/Grid View buttons -->
                <div class="d-flex">
                  <b-button class="grid-list-view-btn mx-2">
                    <font-awesome-icon class="" :icon="['fas', 'th-large']" />
                  </b-button>
                  <b-button class="grid-list-view-btn">
                    <font-awesome-icon class="" :icon="['fas', 'th-list']" />
                  </b-button>
                </div>

                <b-button class="file-storage-btn" v-b-toggle.file-storage-sidebar>
                  <font-awesome-icon class="mr-2" :icon="['fas', 'cloud']" />
                  File Storage
                </b-button>
              </div>

            </b-col>

          </b-row>
          <b-tabs class="custom-tab-container scribook-subject-tabs">
            <b-tab title="All" lazy>
              <!-- Add .se-grid class -->
              <div class="scribook-entry-grid-container">
                <b-card class="scribook-card-container">
                  <div class="">
                    <div class="scribook-card-pill-container">
                      <b-badge pill class="scribook-card-pill">Math</b-badge>
                      <b-badge pill class="scribook-card-pill">Calculus</b-badge>
                      <b-badge pill class="scribook-card-pill scribook-op-grade b">Beginning</b-badge>
                    </div>
                    <!-- Date & Time-->
                    <div class="scribook-card-time-container dark-silver">
                      July 31, 2020, 7:00 AM(Manila)
                    </div>

                    <!-- Topic -->
                    <div class="scribook-topic" v-b-toggle.scribook-entry-sidebar>How to get an example of the number around the dishes let's say booya How to get an example of the number around the bush How to get an example of the number...</div>

                    <hr>

                    <!-- Attachments -->
                    <div class="scribook-attachment-container">
                      <!-- Scribook Summary -->
                      <div class="scribook-summary">
                        <div class="d-flex align-items-center">
                          <b-avatar size="2.5em" class="mr-1" v-b-tooltip.hover title="{Tutor Name}"></b-avatar>
                          <b-avatar size="2.5em" class="mr-1" v-b-tooltip.hover title="{Learner Name}"></b-avatar>
                        </div>
                      </div>
                      <div class="scribook-attachment">
                        <font-awesome-icon class="" :icon="['fas', 'paperclip']" />
                      </div>
                    </div>
                  </div>
                </b-card>

              </div>

            </b-tab>
            <b-tab title="Science" lazy>
            </b-tab>
            <b-tab title="Araling Panlipunan" lazy>
            </b-tab>

          </b-tabs>
        </b-card>
      </b-col>

    </b-row>

    <!-- Scribook Entry Sidebar -->
    <b-sidebar id="scribook-entry-sidebar" right shadow backdrop no-header width="30em" bg-variant="">
      <template #default="{ hide }">

        <div class="scribook-summary p-4">
          <!-- Scribook Overall Performance & Print button -->
          <div class="d-flex align-items-center justify-content-between mb-4">
            <div>
              <b-badge pill class="scribook-card-pill">Math</b-badge>
              <b-badge pill class="scribook-card-pill">Calculus</b-badge>
            </div>

            <!-- Print Button -->
            <div>
              <b-button class="scribook-btn scribook-lock-btn mr-1" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Make this entry private">
                <font-awesome-icon class="font-size-sm" :icon="['fas', 'lock']" />
              </b-button>
              <b-button class="scribook-btn scribook-print-btn mr-1" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Print">
                <font-awesome-icon class="font-size-sm" :icon="['fas', 'print']" />
              </b-button>
              <b-button @click="hide" class="scribook-btn scribook-close-btn" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Close">
                <font-awesome-icon class="font-size-sm" :icon="['fas', 'times']" />
              </b-button>
            </div>
          </div>

          <!-- What did the learner learn today? -->
          <div class="pb-2">
            <div class="scribook-summary-heading">
              What did Grace learn today?
            </div>
            <p>Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system. </p>
          </div>

          <!-- What went well? -->
          <div class="pb-2">
            <div class="scribook-summary-heading">
              What went well?
            </div>
            <p>Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system. </p>
          </div>

          <!-- What will we continue to work on? -->
          <div class="pb-2">
            <div class="scribook-summary-heading">
              What will we continue to work on?
            </div>
            <p>Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system. </p>
          </div>

          <!-- Other Comments -->
          <div class="pb-2">
            <div class="scribook-summary-heading">
              Other Comments:
            </div>
            <p>Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system. </p>
          </div>

          <!-- Overall Assessment -->
          <div class="pb-2">
            <div class="scribook-summary-heading">
              Overall Assesment:
            </div>
            <b-badge pill class="scribook-card-pill scribook-op-grade b">Beginning</b-badge>
            <p class="pt-3 mb-0">Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system.Grace learned about polynomials and counting the
              planets in the solar system. </p>
          </div>

        </div>
      </template>
      <template #footer="{}">
        <div class="dark-grey-bg p-4">
          <div class="pb-3 silver">
            July 31, 2020, 7:00 AM(Manila)
          </div>

          <div class="pb-3">
            <div class="d-flex align-items-center justify-content-between pb-2 scribook-rebook-btn-container">
              <div class="silver">
                <b-avatar size="2.5em" class="mr-1"></b-avatar>
                Tutor Name
              </div>
              <b-button class="scribook-rebook-btn">
                <font-awesome-icon class="mr-1" :icon="['fas', 'calendar']" />
                Book Again
              </b-button>
            </div>
            <div class="pb-2 silver">
              <b-avatar size="2.5em" class="mr-1" v-b-tooltip.hover title="{Learner Name}"></b-avatar>
              Learner Name
            </div>
          </div>

          <div>
            <div class="pb-3 silver">
              Attachments
            </div>
            <div class="scribook-attachment-sidebar">
              <font-awesome-icon class="mr-1" :icon="['fas', 'paperclip']" />
              image.pdf
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
    <!-- Scribook content -->

    <!-- File Storage Sidebar -->
    <b-sidebar id="file-storage-sidebar" right shadow backdrop no-header width="32em" bg-variant="">
      <template #default="{ hide }">

        <div class="p-4">
          <!-- Scribook Overall Performance & Print button -->
          <div class="d-flex align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center">
              <div class="pr-1">
                <div class="scribook-storage-icon ">
                  <font-awesome-icon class="" :icon="['fas', 'folder']" />
                </div>
              </div>
              <b-form-select v-model="selected" :options="options" class="scribook-storage-select">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" default>Math Folder</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option value="C">Option C</b-form-select-option>
                <b-form-select-option value="D">Option D</b-form-select-option>
              </b-form-select>
            </div>

            <!-- Close Button -->

            <b-button @click="hide" class="scribook-btn scribook-close-btn" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Close">
              <font-awesome-icon class="font-size-sm" :icon="['fas', 'times']" />
            </b-button>
          </div>

          <!-- Search Input -->
          <div class="pb-2 d-flex align-items-center justify-content-between">
            <b-input-group class="w-100">
              <b-form-input type="search" placeholder="Search Files"></b-form-input>
              <b-input-group-append is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-append>
            </b-input-group>

            <div class="d-flex">
              <b-button class="grid-list-view-btn mx-2">
                <font-awesome-icon class="" :icon="['fas', 'th-large']" />
              </b-button>
              <b-button class="grid-list-view-btn">
                <font-awesome-icon class="" :icon="['fas', 'th-list']" />
              </b-button>
            </div>

          </div>

          <div class="py-4">
            <!-- Download/Upload Area -->
            <!-- Add .sf-grid class -->
            <b-form class="scribook-storage-form-container ">
              <!-- Uploaded Files -->
              <b-card no-body class="scribook-file-card">
                <div class="">
                  <div class="scribook-file-icon">
                    <div class="scribook-storage-file-icon red">
                      <font-awesome-icon class=" " :icon="['fas', 'file-pdf']" />
                    </div>
                    <!-- File Name and Space -->
                    <div class="scribook-file-name">
                      <div class=" font-weight-medium">collegealgebra.pdf</div>
                      <div class=" dark-silver">1.1 mb</div>
                    </div>
                  </div>
                  <!-- File Actions -->

                  <div>
                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" class="dropdown-action-btn" right no-caret>
                      <template #button-content>
                        <font-awesome-icon class="font-size-sm grey" :icon="['fas', 'ellipsis-h']" />

                      </template>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'file-download']" />Download File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'copy']" />Copy File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'folder-plus']" />Move To
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'trash-alt']" />Delete File
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>

                </div>
              </b-card>

              <b-card no-body class="scribook-file-card">
                <div class="">
                  <div class="scribook-file-icon">
                    <div class="scribook-storage-file-icon light-blue ">
                      <font-awesome-icon class="" :icon="['fas', 'file-word']" />
                    </div>
                    <!-- File Name and Space -->
                    <div class="">
                      <div class="font-weight-medium">collegealgebra.doc</div>
                      <div class="dark-silver">1.1 mb</div>
                    </div>
                  </div>
                  <!-- File Actions -->

                  <div>
                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" class="dropdown-action-btn" right no-caret>
                      <template #button-content>
                        <font-awesome-icon class="font-size-sm grey" :icon="['fas', 'ellipsis-h']" />

                      </template>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'file-download']" />Download File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'copy']" />Copy File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'folder-plus']" />Move To
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'trash-alt']" />Delete File
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>

                </div>
              </b-card>
              <b-card no-body class="scribook-file-card">
                <div class="">
                  <div class="scribook-file-icon">
                    <div class="scribook-storage-file-icon light-blue ">
                      <font-awesome-icon class="" :icon="['fas', 'file-word']" />
                    </div>
                    <!-- File Name and Space -->
                    <div class="">
                      <div class="font-weight-medium">collegealgebra.doc</div>
                      <div class="dark-silver">1.1 mb</div>
                    </div>
                  </div>
                  <!-- File Actions -->

                  <div>
                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" class="dropdown-action-btn" right no-caret>
                      <template #button-content>
                        <font-awesome-icon class="font-size-sm grey" :icon="['fas', 'ellipsis-h']" />

                      </template>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'file-download']" />Download File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'copy']" />Copy File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'folder-plus']" />Move To
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'trash-alt']" />Delete File
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>

                </div>
              </b-card>
              <b-card no-body class="scribook-file-card">
                <div class="">
                  <div class="scribook-file-icon">
                    <div class="scribook-storage-file-icon light-blue ">
                      <font-awesome-icon class="" :icon="['fas', 'file-word']" />
                    </div>
                    <!-- File Name and Space -->
                    <div class="">
                      <div class="font-weight-medium">collegealgebra.doc</div>
                      <div class="dark-silver">1.1 mb</div>
                    </div>
                  </div>
                  <!-- File Actions -->

                  <div>
                    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" class="dropdown-action-btn" right no-caret>
                      <template #button-content>
                        <font-awesome-icon class="font-size-sm grey" :icon="['fas', 'ellipsis-h']" />

                      </template>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'file-download']" />Download File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'copy']" />Copy File
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'folder-plus']" />Move To
                      </b-dropdown-item>
                      <b-dropdown-item href="#">
                        <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'trash-alt']" />Delete File
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>

                </div>
              </b-card>

              <!-- Browse File / Drag and drop label/icon Container -->
              <div class="scribook-file-upload-container">
                <div class="scribook-file-upload-placeholder text-center">
                  <font-awesome-icon class="" style="font-size:1.5rem;" :icon="['fas', 'upload']" />
                  <div class="font-size-xs">
                    <span class="font-weight-medium">Browse files</span>
                    or drag and drop here
                  </div>
                </div>
                <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>

              </div>

            </b-form>

          </div>

        </div>
      </template>

      <template #footer="{}">
        <div class="dark-grey-bg p-4">
          <div class="silver pb-3">
            <font-awesome-icon class="mr-2 fs-24" :icon="['fas', 'cloud']" />
            Storage (47% Full)
          </div>
          <div class="pb-1 silver">
            <b-progress class="scribook-storage-progress-bar" height=".3rem" :value="47" :max="max"></b-progress>
          </div>
          <small class="silver">469 mb of 1 GB used</small>
        </div>
      </template>
    </b-sidebar>

    <!-- Start Scribook Modal for Tutors -->
    <b-modal ref="my-modal" hide-footer centered header-class="orange-bg text-center d-block">
      <template #modal-header>
        <!-- Learner Image -->
        <b-avatar :src="imageUrl" class="mx-auto" style="margin-top:-4rem;" size="4rem"></b-avatar>
        <div class="py-2 font-weight-medium text-white font-size-md">
          Scribook entry for Angelina Jo
        </div>
      </template>
      <b-form class="scribook-modal-form-container p-3">
        <transition-group tag="div" class="div-slider" name="slide">
          <!-- Scribook Topic -->
          <div class="scribook-modal-step-1" v-if="formStepSelected === 1" key="1">
            <div class="slider-content sc1">
              <b-form-group label="What's your topic for this session?" label-for="scribook-topic" size="sm">
                <b-form-input id="scribook-topic" size="sm" placeholder="Polynomial Functions"></b-form-input>
                <b-form-invalid-feedback>
                  Type at least 20 characters.
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block">
                  {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> 234 character</span> remaining
                </b-form-text>
              </b-form-group>
            </div>
          </div>

          <div class="scribook-modal-step-2" v-if="formStepSelected === 2" key="2">
            <div class="slider-content sc2">
              <!-- Scribook What Learner Learned Today -->
              <b-form-group label="What did {Learner name} learn today?" label-for="remarks" size="sm" class="pb-4">
                <b-form-textarea id="remarks" v-model="remarks" placeholder="" rows="4" size="sm" no-resize></b-form-textarea>
                <b-form-invalid-feedback>
                  Type at least 20 characters.
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block">
                  {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                </b-form-text>
              </b-form-group>

              <!-- Scribook What went well-->
              <b-form-group label="What went well?" label-for="remarks" size="sm" class="pb-4">
                <b-form-textarea id="remarks" v-model="remarks" placeholder="" rows="4" size="sm" no-resize></b-form-textarea>
                <b-form-invalid-feedback>
                  Type at least 20 characters.
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block">
                  {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                </b-form-text>
              </b-form-group>

              <!-- Scribook What will we continue to work on -->
              <b-form-group label="What will we continue to work on?" label-for="remarks" size="sm" class="pb-4">
                <b-form-textarea id="remarks" v-model="remarks" placeholder="" rows="4" size="sm" no-resize></b-form-textarea>
                <b-form-invalid-feedback>
                  Type at least 20 characters.
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block">
                  {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                </b-form-text>
              </b-form-group>

              <!-- Scribook Any other comments -->
              <b-form-group label="Any other comments?" label-for="remarks" size="sm" class="pb-4">
                <b-form-textarea id="remarks" v-model="remarks" placeholder="" rows="4" size="sm" no-resize></b-form-textarea>
                <b-form-invalid-feedback>
                  Type at least 20 characters.
                </b-form-invalid-feedback>
                <b-form-text id="password-help-block">
                  {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                </b-form-text>
              </b-form-group>

              <!-- Scribook File Attachments -->
              <b-form-group label="Attach some files" label-for="remarks" size="sm" class="pb-4">
                <div class="scribook-file-upload-container">
                  <div class="scribook-file-upload-placeholder p-4 text-center">
                    <font-awesome-icon class="fs-24" :icon="['fas', 'upload']" />
                    <div>
                      <span class="font-weight-medium">Browse files</span>
                      or drag and drop here
                    </div>
                  </div>
                  <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>

                </div>
              </b-form-group>
            </div>
          </div>

          <div class="scribook-modal-step-3" v-if="formStepSelected === 3" key="3">
            <div class="slider-content sc3">
              <b-form-group label="Overall Assessment to {Learner Name}'s Performance" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" value="A" class="selected" name="some-radios">
                  Beginning
                  <div class="font-weight-normal">The learner struggles with his/her understanding; prerequisite and fundamental knowledge and/or skills have not been acquired or developed adequately to aid understanding.
                  </div>
                </b-form-radio>
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" value="B" name="some-radios">
                  Developing
                  <div class="font-weight-normal">The learner possesses the minimum knowledge, skills and core understandings, but needs help throughout the performance of authentic task.
                  </div>
                </b-form-radio>
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" value="C" name="some-radios">
                  Approaching Proficiency
                  <div class="font-weight-normal">The learner has developed fundamental knowledge, skills and core understanding, and with little guidance from the tutor and/or some assistance, can transfer these understandings throughout authentic performance task or written output.
                  </div>
                </b-form-radio>
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" value="D" name="some-radios">
                  Proficient
                  <div class="font-weight-normal">The learner has developed fundamental knowledge, skills and core understanding, and can transfer them independently through authentic performance task or written output.
                  </div>
                </b-form-radio>
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" value="E" name="some-radios">
                  Advanced
                  <div class="font-weight-normal">The learner exceeds the core requirements in terms of knowledge, skills and understanding and can transfer them automatically and flexibly through authentic performance task, written works and outputs.
                  </div>
                </b-form-radio>

              </b-form-group>
            </div>
          </div>

          <div class="scribook-modal-step-4" v-if="formStepSelected === 4" key="4">
            <div class="slider-content sc3">
              <!-- Scribook File Attachments -->
              <b-form-group label="Upload Photo (Optional)" label-for="remarks" size="sm" class="pb-4">
                <span class="grey mt-n1">
                  Upload photo of your actual session or project that you want to send to your learner or learner's parent</span>
                <div class="scribook-file-upload-container py-4">
                  <div class="scribook-file-upload-placeholder p-4 text-center">
                    <font-awesome-icon class="fs-24" :icon="['fas', 'upload']" />
                    <div>
                      <span class="">Choose files</span>
                      or drag and drop here
                    </div>
                  </div>
                  <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>
                </div>

                <!-- File Preview Area -->
                <div class="scribook-file-preview-area text-center">
                  <b-img class="img-fluid" :src="require('@/assets/imgs/Header - Academic Tutoring.jpg')"></b-img>
                </div>
              </b-form-group>
            </div>
          </div>

          <div class="scribook-modal-step-5" v-if="formStepSelected === 5" key="5">
            <div class="slider-content sc3">
              <div class="text-center p-5">
                <div>
                  <b-img class="mb-4 p-3 img-fluid" :src="require('@/assets/imgs/scribook/scribook-success-step.png')"></b-img>
                  <div class="pyro">
                    <div class="before" style="top:0;"></div>
                    <div class="after" style="bottom:0;"></div>
                  </div>
                </div>
                <p>Hooray! Thank you for helping your learner grow.</p>
              </div>
            </div>
          </div>

        </transition-group>
        <!-- Scribook Modal Progress bar and Buttons -->
        <div class="d-flex align-items-center justify-content-between py-2">
          <!-- Scribook Modal Progress bar -->
          <div style="width:10em">
            <div class="font-size-xs dark-silver">0 of 3 answered</div>
            <b-progress class="scribook-modal-progress-bar" height=".3rem" :value="5" :max="max"></b-progress>
          </div>
          <!-- Scribook Modal Buttons -->
          <div class="text-center">
            <b-button class="scribook-modal-btn outline mr-1">
              Skip for now
            </b-button>
            <b-button class="scribook-modal-btn" v-if="formStepSelected < formSteps.length - 1 " @click="validateObserver">
              Next
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- End Scribook Modal for Tutors -->
  </div>
</template>

<script>
import mixins from "../mixins";
export default {
  name: "Scribook",
  data: () => ({
    characterLimit: 256,
    remarks: "",
    formStepSelected: 5,
    formSteps: [
      { step: 1, minHeight: "47rem" },
      { step: 2, minHeight: "40rem" },
      { step: 3, minHeight: "20rem" },
    ],
  }),
  created() {},
  computed: {
    othersCharactersRemaining: {
      get() {
        const newLimit = this.characterLimit - this.remarks.length;
        return newLimit < 0 ? 0 : newLimit;
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    this.showModal();
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    onChangeRecommendation(value) {
      this.$emit("changed", value);
    },
    onBack() {
      this.formStepSelected--;
      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          this.onNext();
        }
      });
    },
    onNext() {
      this.formStepSelected++;
      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    recommendation: {
      handler: function (value) {
        if (value == "OTH") {
          this.remarks = "";
          setTimeout(() => {
            document
              .getElementById("remarks")
              .setAttribute("maxlength", this.characterLimit);
          }, 1000);
        }
      },
    },
  },
  mixins: [mixins],
};
</script>

<style >
.scribook-search-filter input[type="search"],
#file-storage-sidebar input[type="search"],
.scribook-search-filter select {
  background: var(--light-silver);
  border: 0.5px solid var(--light-silver);
  color: var(--grey);
  border-top-left-radius: 15em !important;
  border-bottom-left-radius: 15em !important;
  padding: 0 1em;
}
.scribook-search-filter select {
  border-top-right-radius: 15em !important;
  border-bottom-right-radius: 15em !important;
}
.scribook-search-filter input[type="search"]:focus,
#file-storage-sidebar input[type="search"]:focus,
.scribook-search-filter select:focus {
  box-shadow: 0 0 0 0.05em var(--light-blue);
}
.scribook-search-filter .input-group-text,
#file-storage-sidebar .input-group-text {
  background: var(--light-silver);
  border: 0.5px solid var(--light-silver);
  color: var(--grey);
  font-size: 0.8rem;
  border-top-right-radius: 15em !important;
  border-bottom-right-radius: 15em !important;
  padding-right: 1em !important;
}
.scribook-search-filter select {
  background: var(--light-silver)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}
.scribook-subject-tabs ul {
  margin: 0 20px;
  font-size: 0.8rem;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: 0; /* Firefox */
  scroll-behavior: smooth;
}
/* Grid & List View Button */
.btn.grid-list-view-btn {
  background: var(--silver);
  background-color: var(--light-silver);
  border-radius: 15em;
  color: var(--grey);
  width: 2.415em;
}
.btn.file-storage-btn {
  background: var(--silver);
  background-color: var(--light-silver);
  border-radius: 15em;
  color: var(--grey);
}

.scribook-subject-tabs ul::-webkit-scrollbar {
  width: 0;
}
.scribook-button-container {
  display: flex;
}
.scribook-card-container.card {
  box-shadow: 0px 0px 14px 0px rgba(210, 210, 210, 0.7);
  border-radius: 4px;
  border: 0.5px solid transparent;
  cursor: pointer;
  transition: border 200ms ease-in-out;
  flex-direction: row;
}
.scribook-card-container.card:hover {
  border-left: 5px solid var(--light-blue);
}

.scribook-card-pill.badge {
  background: var(--silver);
  color: var(--dark-grey);
  font-weight: normal;
  font-size: calc(var(--fs-one) - 4px);
  margin-right: 0.3em;
  padding: 0.4em 0.8em;
}
.scribook-topic {
  font-size: var(--fs-one);
  font-weight: 500;
  color: var(--dark-grey);
  margin-left: 1.5em;
}

.scribook-desc {
  font-size: calc(var(--fs-one) - 2px);
  color: var(--grey);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  display: none;
}

.scribook-summary-heading {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: var(--fs-one);
  margin-bottom: 0.5em;
}
.scribook-summary p {
  color: var(--grey);
  font-weight: 400;
  font-size: calc(var(--fs-one) - 2px);
}
.scribook-show-more-link {
  font-weight: 500;
  font-size: 0.8rem;
  padding: 1em 0em;
  color: var(--light-blue);
  display: inline-block;
}
.scribook-attachment {
  background: var(--light-silver);
  border-radius: 15em;
  color: var(--grey);
  cursor: pointer;
  height: 1.8em;
  width: 1.8em;
  font-size: var(--fs-one);
  text-align: center;
  line-height: 1.9;
}
.scribook-attachment:hover {
  background: var(--silver);
  transition: all 200ms ease-in;
}

.scribook-attachment-sidebar {
  display: inline-flex;
  align-items: center;
  border: 0.3px solid var(--silver);
  color: var(--white);
  cursor: pointer;
  padding: 0.3em 0.8em;
  border-radius: 15em;
  position: relative;
  transition: all 300ms ease-in-out;
}

.scribook-attachment-sidebar span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

#scribook-entry-sidebar,
#file-storage-sidebar {
  background: var(--white);
}

#scribook-entry-sidebar .b-sidebar-body::-webkit-scrollbar,
#file-storage-sidebar .b-sidebar-body::-webkit-scrollbar {
  width: 10px;
}

#scribook-entry-sidebar .b-sidebar-body::-webkit-scrollbar-track,
#file-storage-sidebar .b-sidebar-body::-webkit-scrollbar-track {
  background: var(--light-silver);
}

#scribook-entry-sidebar .b-sidebar-body::-webkit-scrollbar-thumb,
#file-storage-sidebar .b-sidebar-body::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
  border-radius: 10px;
}

#scribook-entry-sidebar .b-sidebar-body::-webkit-scrollbar-thumb:hover,
#file-storage-sidebar .b-sidebar-body::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}
/* Scribook Buttons */

.btn.scribook-btn {
  width: 2em;
  height: 2em;
  padding: 0;
  text-align: center;
  color: var(--dark-silver);
  background: var(--white);
  background-color: var(--white);
  border: 0.5px var(--silver) solid;
  border-radius: 15em;
}

.btn.scribook-btn:hover,
.btn.scribook-btn:focus {
  outline: none;
  color: var(--grey) !important;
  background: var(--silver);
  background-color: var(--silver) !important;
  border: 0.5px var(--silver) solid;
}

.btn.scribook-lock-btn:active,
.btn.scribook-lock-btn.active {
  background: rgba(0, 191, 224, 0.2) !important;
  color: var(--light-blue) !important;
}

.btn.scribook-print-btn,
.btn.scribook-close-btn,
.btn.scribook-lock-btn {
  background: var(--silver);
  background-color: var(--silver);
  color: var(--grey);
}

.scribook-attachment-sidebar:hover {
  background: var(--light-silver);
  color: var(--dark-grey);
}

.sidebar-name-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  display: inline-block;
  vertical-align: middle;
}

.scribook-rebook-btn-container:hover > .btn.scribook-rebook-btn {
  opacity: 1;
}

.btn.scribook-rebook-btn,
.btn.scribook-rebook-btn:focus,
.btn.scribook-rebook-btn:active {
  background: transparent;
  border: 0.3px solid var(--silver);
  color: var(--white);
  border-radius: 15em;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.btn.scribook-rebook-btn:hover {
  background: var(--light-silver);
  color: var(--dark-grey);
}

.scribook-op-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.scribook-op-grade {
  border-radius: var(--border-radius-5);
  font-weight: 500 !important;
  text-align: center;
}
.scribook-op-grade.b,
.scribook-op-grade.d {
  color: var(--orange);
  background: rgba(255, 189, 89, 0.1);
}
.scribook-op-grade.ap {
  color: var(--light-blue);
  background: rgba(0, 191, 224, 0.1);
}
.scribook-op-grade.p,
.scribook-op-grade.a {
  color: var(--teal);
  background: rgba(0, 128, 128, 0.1);
}
.scribook-op-label {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--orange);
}

.scribook-storage-icon {
  width: 2em;
  font-size: 1.7rem;
  line-height: 2;
  background: var(--light-blue-10);
  border-radius: 15em;
  color: var(--light-blue);
  text-align: center;
}

.scribook-storage-select.custom-select {
  border: none;
  color: var(--grey);
  font-weight: 500;
  font-size: var(--fs-one);
  padding: 0.375rem 1.75rem 0.375rem 0rem;
}

.scribook-storage-select.custom-select:focus {
  box-shadow: none;
}

.scribook-storage-form-container {
  cursor: pointer;
  display: grid;
  gap: 0.6em;
}

.sf-grid {
  grid-template-columns: repeat(2, 1fr);
}

.scribook-file-card {
  border: 1px solid transparent !important;
  box-shadow: 0px 0px 14px 0px rgb(210 210 210 / 70%);
  padding: 1em 0.8em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  grid-column: span 2;
}
.scribook-file-card:hover {
  border: 1px solid var(--light-blue) !important;
  transition: all 200ms ease-out;
}

.scribook-file-card > div {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

/* Start Storage Grid */
.scribook-storage-form-container.sf-grid .scribook-file-icon {
  display: block;
}

.scribook-storage-form-container.sf-grid .scribook-storage-file-icon {
  margin-bottom: 0.5em;
}

.scribook-storage-form-container.sf-grid .scribook-file-card {
  grid-column: unset;
}

.scribook-storage-form-container.sf-grid .scribook-file-card > div {
  align-items: unset;
}

.scribook-storage-form-container.sf-grid .scribook-file-name {
  margin-left: unset;
}

.scribook-storage-form-container .scribook-file-upload-container {
  grid-column: span 2;
}

/* End Storage Grid */

.scribook-file-icon {
  display: flex;
  align-items: center;
  line-height: 1.3;
}

.scribook-storage-file-icon {
  height: 2em;
  width: 2em;
  background: var(--light-silver);
  border-radius: 15em;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2;
}

.scribook-file-name {
  margin-left: 0.8em;
}

.scribook-file-name > div {
  min-width: 100px;
  max-width: 165px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scribook-file-upload-container {
  cursor: pointer;
  position: relative;
  min-height: inherit;
}

.scribook-file-upload-input {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100% !important;
  width: 100%;
  opacity: 0;
}
.scribook-file-upload-input .custom-file-label::after {
  display: none;
}
.scribook-file-upload-input .custom-file-label {
  height: 100%;
}
.scribook-file-upload-placeholder {
  background: transparent !important;
  background-color: transparent !important;
  border: 0.5px dotted var(--dark-silver);
  border-radius: var(--border-radius-5);
  color: var(--dark-silver);
  font-size: 0.5rem;
  padding: 2em;
}

.scribook-file-upload-input .custom-file-label {
  cursor: pointer;
}

.scribook-modal-form-container .form-control-sm {
  font-size: calc(var(--fs-one) - 2px);
  padding: 0.625em;
}

.scribook-modal-form-container .form-control-sm::placeholder {
  color: var(--dark-silver);
}

.scribook-modal-form-container label,
.scribook-modal-form-container legend {
  font-size: var(--fs-one);
  margin-bottom: 1em;
}
.scribook-modal-form-container legend {
  margin-bottom: 0.5em;
}
.scribook-modal-form-container .form-control:focus {
  border: 1px solid var(--orange);
  box-shadow: none;
}
.scribook-modal-form-container .invalid-feedback,
.scribook-modal-form-container small,
.scribook-modal-form-container span {
  font-size: 14px;
}

.scribook-modal-progress-bar .progress-bar {
  background-color: var(--orange);
}

.scribook-storage-progress-bar .progress-bar {
  background-color: var(--light-blue);
}

.scribook-modal-step-3 .custom-radio {
  padding: 2em 1em 1em 4.8em;
  border: 1px solid var(--white);
  border-radius: var(--border-radius-5);
  margin-bottom: 0.5em;
}
.scribook-modal-step-3 .custom-radio .custom-control-label {
  cursor: pointer;
}
.scribook-modal-step-3 .custom-radio:hover {
  border: 1px solid var(--silver);
}

.scribook-modal-step-3 .custom-radio.selected {
  border: 1px solid var(--orange);
  border-radius: var(--border-radius-5);
}
.scribook-modal-step-3
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--orange) !important;
  background: var(--orange) !important;
  border-color: #adb5bd !important;
  display: none;
}

.scribook-modal-step-3
  .custom-radio
  .custom-control-input
  ~ .custom-control-label::before {
display: none;
  }

.scribook-modal-step-3
  .custom-radio
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(255 235 0 / 10%);
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
  opacity: 1;
}
.slide-enter {
  transform: translate(100%, 0);
  opacity: 0;
}
.slide-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.5s;
  opacity: 1;
}
.slideback-enter {
  transform: translate(-100%, 0);
  opacity: 0;
}
.slideback-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}

.div-slider {
  position: relative;
  width: 100%;
}

.div-slider .slider-content {
  width: 100%;
}

.scribook-entry-grid-container {
  --n: 4; /* The maximum number of columns */
  display: grid;
  gap: 20px;
}

.scribook-entry-grid-container .card-footer {
  align-self: center;
}

.scribook-card-container > .card-body > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scribook-card-container > .card-body > div > hr {
  display: none;
}

.scribook-card-container > .card-footer {
  background-color: unset;
  border-top: unset;
}

.scribook-card-container > .card-footer > hr {
  display: none;
}

.scribook-card-pill-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 1;
}

.scribook-card-pill-container .scribook-card-pill {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scribook-topic {
  order: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 420px;
}

.scribook-card-time-container {
  font-size: calc(var(--fs-one) - 4px);
  order: 3;
  margin-left: auto;
}

.scribook-attachment-container {
  display: flex;
  align-content: center;
  justify-content: center;
  order: 4;
}

/* Start Scribook Entry Grid */
.scribook-entry-grid-container.se-grid {
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(300px, 100% / var(--n)), 1fr)
  );
}
.scribook-entry-grid-container.se-grid > .scribook-card-container.card {
  flex-direction: column;
}

.scribook-entry-grid-container.se-grid
  > .scribook-card-container
  > .card-body
  > div
  > div {
  display: unset;
  align-items: unset;
  justify-content: unset;
}

.scribook-entry-grid-container.se-grid .scribook-card-pill-container {
  padding-bottom: 0.5em;
}

.scribook-entry-grid-container.se-grid .scribook-card-time-container {
  padding-bottom: 1em;
}
.scribook-entry-grid-container.se-grid .created-date {
  display: block;
}


.scribook-entry-grid-container.se-grid
  .scribook-card-container
  > .card-body
  > div
  > hr {
  display: block;
}

.scribook-entry-grid-container.se-grid .card-footer {
  align-self: unset;
}

.scribook-entry-grid-container.se-grid
  .scribook-card-container
  > .card-footer
  > hr {
  margin-top: 0;
  display: inherit;
}

.scribook-entry-grid-container.se-grid .scribook-topic {
  order: unset;
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  max-width: unset;
  margin-bottom: 0.2em;
  margin-left: unset;
}
.scribook-entry-grid-container.se-grid .scribook-attachment-container {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.scribook-entry-grid-container.se-grid .scribook-desc {
  display: -webkit-box;
}

.children-avatar-container {
  padding: 2em 0;
}

.child-card {
  display: inline-block;
  background: var(--white);
  padding: 1.5em;
  border-radius: 0.8em;
  border: 1px solid transparent;
  box-shadow: 0px 0px 14px 0px rgba(210, 210, 210, 0.7);
  text-align: center;
  cursor: pointer;
  user-select: none;
  min-width: 130px;
  max-height: 350px;
  margin-top: 1em;
}

.child-card:not(:last-child) {
  margin-right: 0.8em;
}

.child-card-name {
  color: var(--dark-silver);
  min-width: 100px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}

.child-card.active {
  border: 1px solid var(--light-blue);
}
.child-card.active > .child-card-name {
  font-weight: 500;
  color: var(--light-blue);
}

.scribook-modal-form-container {
  overflow: hidden;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@media (max-width: 768px) {
  .children-avatar-container {
    display: flex;
    overflow-x: scroll;
  }

  .child-card {
    margin-top: 0 !important;
  }

  .children-avatar-container {
    padding: 2em 0.5em;
  }

  .child-card-name {
    min-width: 0 !important;
  }

  .scribook-file-name > div {
    max-width: 120px;
  }
}
</style>
<template>
  <div>
    <div id="testimonials" class="testimonials">
      <div class="container-fluid">
        <p class="dark-blue">Let our parents and learners’ experiences give you reasons to trust Chalkboard. <br>Read what they have to say.</p>

        <VueSlickCarousel v-bind="settings">
          <div class="cb-testimonial-card card">
            <div class="card-body">
              <p>Chalkboard classes are fun and enjoyable. The teachers are very helpful and engaging. Chalkboard serves as an excellent venue of learning for my kids outside of the classroom.</p>
            </div>
            <div class="card-footer ">
              <div class="row align-items-end">
                <div class="col-lg-6 col-sm-12 rating">
                  <b-form-rating inline readonly value="5"></b-form-rating>
                </div>
                <div class="col-lg-6 col-sm-12 parent">
                  <p class="name">Flormelyn</p>
                  <p>Chalkboard parent</p>
                  <p class="country">Gaithersburg, Maryland</p>
                </div>
              </div>

            </div>
          </div>
          <div class="cb-testimonial-card card">
            <div class="card-body">
              <p>She is a very great tutor and is helpful and easy to communicate with. My son enjoys her lessons and her activities. He is always looking forward to his next art project which is great. My son really improved a lot and I'm really grateful that I found you on this platform. Thank you, Arleen.</p>
            </div>
            <div class="card-footer">
              <div class="row align-items-end">
                <div class="col-lg-6 rating">
                  <b-form-rating inline readonly value="5"></b-form-rating>
                </div>
                <div class="col-lg-6 parent">
                  <p class="name">Realyn</p>
                  <p>Chalkboard parent</p>
                  <p class="country">Singapore</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cb-testimonial-card card">
            <div class="card-body">
              <p>My son, Justin, has been having lessons with John for around 3 months now. His development has been terrific, and he really enjoys the lessons. Highly recommended piano teacher.</p>
            </div>
            <div class="card-footer">
              <div class="row align-items-end">
                <div class="col-lg-6 rating">
                  <b-form-rating inline readonly value="5"></b-form-rating>
                </div>
                <div class="col-lg-6 parent">
                  <p class="name">Karen</p>
                  <p>Chalkboard parent</p>
                  <p class="country">Los Angeles, California</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cb-testimonial-card card">
            <div class="card-body">
              <p>My son completed Levels 1 & 2 of the Python Coding Class with Tutor Jez. He learned a lot and enjoyed every activity. She’s a great tutor, explained perfectly and extremely patient.
                Thanks, Chalkboard! The platform is easy to use, and your customer support responds quickly.
              </p>
            </div>
            <div class="card-footer">
              <div class="row align-items-end">
                <div class="col-lg-5 rating">
                  <b-form-rating inline readonly value="5"></b-form-rating>
                </div>
                <div class="col-lg-7 parent">
                  <p class="name">Cherry Ann</p>
                  <p>Chalkboard parent</p>
                  <p class="country"> Parañaque City, Philippines
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="cb-testimonial-card card">
            <div class="card-body">
              <p>Tutor Jezreel is amazing! My son really had fun with his Scratch program class with her. He even requested to book another session. He's looking forward to their next class.
              </p>
            </div>
            <div class="card-footer">
              <div class="row align-items-end">
                <div class="col-lg-5 rating">
                  <b-form-rating inline readonly value="5"></b-form-rating>
                </div>
                <div class="col-lg-7 parent">
                  <p class="name">Hanna</p>
                  <p>Chalkboard parent</p>
                  <p class="country"> Mabalacat City, Philippines

                  </p>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Testimonials",
  components: { VueSlickCarousel },

  data() {
    return {
      settings: {
        infinite: true,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
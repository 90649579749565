<template>
  <div>
    <div class="p-loading" v-if="isLoading">
      <div class="p-loader"></div>
    </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form id="registration-step1-form" @submit.stop.prevent="handleSubmit(onSubmit)">
        <div class="accordion shadow-sm">
          <b-card no-body class="mb-1">
            <b-card-header id="basic-information-tab" v-b-toggle.basic-info header-tag="header" class="p-3">
              <h6 class="d-flex align-items-center">
                <font-awesome-icon class="font-size-md mr-2" style="height:25px;width:25px;" :icon="['fas', 'user-circle']" />
                Basic Information
              </h6>
              <div class="font-size-sm grey px-4 ml-2">Your personal information will help our team verify your identity. Please make sure that all the information
                entered below matches with the documents and other requirements that you have previously submitted.
                Your personal information will not be displayed on your profile except for your Public Name.</div>
            </b-card-header>
            <b-collapse id="basic-info" visible>
              <b-card-body class="p-5">
                <div v-show="isBasicInformation">
                  <b-form-row>
                    <b-col sm="12" lg="3">
                      <div class="d-flex flex-column align-items-center justify-content-center">

                        <b-avatar id="clickImage" :src="imageUrl" size="7rem" v-bind="mainProps" style="cursor:pointer;"></b-avatar>

                        <div class="px-2 text-center">
                          <div class="py-2"><strong>Profile Image *</strong></div>
                          <ValidationProvider name="image" rules="image" v-slot="validationContext">
                            <b-button @click="onProfileImageClick" variant="outline-info">Browse File</b-button>
                            <b-form-file id="fileUpload" style="display: none;" v-model="form.basicInformation.image" accept="image/jpeg, image/png" @change="onFileChange" size="md" :state="getValidationState(validationContext)"></b-form-file>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                            <p class="font-size-xs dark-silver mt-2">Max file size is 2MB <br>in jpeg, jpg and png format</p>

                          </ValidationProvider>
                        </div>

                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        <b-form-row class="mb-2">
                          <b-col>
                            <b-form-group id="input-group-1" label="Email Address *" label-for="email">
                              <ValidationProvider name="email" rules="required|email" v-slot="validationContext">
                                <b-form-input id="email" v-model="form.basicInformation.email" type="email" required placeholder="Enter email address" size="md" :state="getValidationState(validationContext)" disabled></b-form-input>
                                <b-form-invalid-feedback id="input-3-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                                <b-form-text v-if="!validationContext.errors[0]">
                                  All email notifications are sent here.
                                </b-form-text>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group id="input-group-2" label="Birthday *" label-for="birthday">
                              <ValidationProvider name="birthday" v-slot="validationContext">
                                <b-form-input id="birthday" v-model="form.basicInformation.birthday" type="date" required size="md" name="birthday" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-2-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                                <b-form-text v-if="!validationContext.errors[0]">
                                  You need to be at least 18 years old to sign up.
                                </b-form-text>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Legal First Name *" label-for="legal_firstname">
                              <ValidationProvider name="legal first name" rules="required|max:60" v-slot="validationContext">
                                <b-form-input id="legal_firstname" v-model="form.basicInformation.legal_firstname" type="text" max="10" required placeholder="Enter legal first name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                <b-form-text v-if="!validationContext.errors[0]">
                                  Enter your full legal name. This will only be visible to our team. It cannot be edited later.
                                </b-form-text>
                              </ValidationProvider>

                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Legal Last Name *" label-for="legal_lastname">
                              <ValidationProvider name="legal last name" rules="required|max:60" v-slot="validationContext">
                                <b-form-input id="legal_lastname" v-model="form.basicInformation.legal_lastname" type="text" max="10" required placeholder="Enter legal last name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Public First Name *" label-for="firstname">
                              <ValidationProvider name="first name" rules="required|max:60" v-slot="validationContext">
                                <b-form-input id="firstname" v-model="form.basicInformation.firstname" type="text" max="10" required placeholder="Enter first name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-4-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Public Last Name *" label-for="lastname">
                              <ValidationProvider name="last name" rules="required|max:60" v-slot="validationContext">
                                <b-form-input id="lastname" v-model="form.basicInformation.lastname" type="text" required placeholder="Enter last name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-5-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-form-group>
                            <b-form-text class="mt-n2 px-2">Your name will be displayed as “ {{form.basicInformation.firstname}} {{getFChar(form.basicInformation.lastname)}}.” on your tutor profile and on the parents’ and learners’ Dashboard. It may not be the same as your legal name and can be edited once submitted but you need to send us a request.</b-form-text>
                          </b-form-group>
                        </b-form-row>
                        <b-form-row class="mb-2">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Phone Number *" label-for="mobile_no">
                              <ValidationProvider name="phone number" :rules="`required|validatePhoneNumber:${JSON.stringify(eventPNData)}`" v-slot="validationContext">
                                <PhoneNumberInput
                                  required
                                  id="mobile_no"
                                  @update="onPNUpdate($event)"
                                  v-model="form.basicInformation.mobile_no"
                                  :default-country-code="`${form.basicInformation.pn_country_code || 'US'}`"
                                  :error="validationContext.errors[0] ? true : false"
                                />
                                <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                                <b-form-text v-if="!validationContext.errors[0]">
                                  For Customer Support. We will not share it with anyone else.
                                </b-form-text>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="WhatsApp" label-for="whatsapp">
                              <ValidationProvider name="whatsapp" :rules="`validatePhoneNumber:${JSON.stringify(eventWAData)}`" v-slot="validationContext">
                                <PhoneNumberInput
                                  id="whatsapp"
                                  @update="onWAUpdate($event)"
                                  v-model="form.basicInformation.whatsapp"
                                  :default-country-code="`${form.basicInformation.wa_country_code || 'US'}`"
                                  :error="validationContext.errors[0] ? true : false"
                                />
                                <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                                <b-form-text v-if="!validationContext.errors[0]">
                                  WhatsApp is one of Chalkboard’s primary communication channels. It is recommended that you install this app so our Customer Support may use it to contact you.
                                </b-form-text>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Address 1 *" label-for="street">
                              <ValidationProvider name="street" rules="required|max:100" v-slot="validationContext">
                                <b-form-input id="street" required v-model="form.basicInformation.street" type="text" placeholder="Blk, Lot, Street address" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-7-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Address 2" label-for="brgy">
                              <ValidationProvider name="barangay" rules="max:100" v-slot="validationContext">
                                <b-form-input id="brgy" v-model="form.basicInformation.brgy" type="text" placeholder="Apartment, suite, unit, building, floor etc." size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-8-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="City *" label-for="city">
                              <ValidationProvider name="city" rules="required|max:100" v-slot="validationContext">
                                <b-form-input id="city" v-model="form.basicInformation.city" type="text" required placeholder="Enter city name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-9-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="State/Province *" label-for="province">
                              <ValidationProvider name="province" rules="required|max:100" v-slot="validationContext">
                                <b-form-input id="province" v-model="form.basicInformation.province" type="text" required placeholder="Enter province name" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-10-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row class="pb-5 mb-5">
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Country *" label-for="country">
                              <ValidationProvider name="country" rules="required" v-slot="validationContext">
                                <CountrySelect v-model="form.basicInformation.country" :errorMessage="!form.basicInformation.country ? validationContext.errors[0] : ''" :successful="!!form.basicInformation.country" size="md" />
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <b-form-group id="input-group-1" label="Postal/Zip Code" label-for="zip_code">
                              <ValidationProvider name="zip code" rules="max:10" v-slot="validationContext">
                                <b-form-input id="zip_code" v-model="form.basicInformation.zip_code" type="text" placeholder="Enter zipcode" size="md" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </div>
                    </b-col>
                  </b-form-row>

                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header v-b-toggle.background header-tag="header" class="p-3">
              <h6 class="d-flex align-items-center ">
                <font-awesome-icon class="font-size-md mr-2" style="height:25px;width:25px;" :icon="['fas', 'user-graduate']" />
                Education and Professional Background
              </h6>
              <div class="font-size-sm grey px-4 ml-2">Chalkboard does not require you to have a degree program in Education. We do, however, believe that your education background and teaching credentials can help us assess your expertise during the interview and teaching demonstration. Most of the information below will also be displayed on your public profile.</div>
            </b-card-header>
            <b-collapse id="background" :visible="isEducationBackgroundVisible">
              <b-card-body class="p-5">
                <div v-show="isBackground">
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Academic Degree *" label-for="academic_degree">
                        <ValidationProvider name="academic degree" rules="required" v-slot="validationContext">
                          <b-form-select v-model="form.background.academic_degree" id="academic_degree" required :state="getValidationState(validationContext)" size="md">
                            <b-form-select-option value="null">-- select academic degree --</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in academicDegreeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Highest education degree completed or is currently studying from a college or university.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Degree Program *" label-for="education">
                        <ValidationProvider name="degree program" rules="required|max:100" v-slot="validationContext">
                          <b-form-input id="education" v-model="form.background.education" type="text" required placeholder="Enter degree program" size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-14-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Your course of study or program you are admitted to or has graduated from leading to your highest academic degree.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="College University *" label-for="college_university">
                        <ValidationProvider name="college university" rules="required" v-slot="validationContext">
                          <b-form-input id="college_university" v-model="form.background.college_university" required type="text" placeholder="Enter college university" size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Name of college or university where you completed or is currently talking your highest education degree.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group id="input-group-1" label="Academic Affiliation" label-for="academic_affiliation">
                        <ValidationProvider name="academic affiliation" rules="max:100" v-slot="validationContext">
                          <b-form-input id="academic_affiliation" v-model="form.background.academic_affiliation" type="text" placeholder="Enter academic affiliation" size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-15-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            List one academic institution where you are practicing teaching or tutoring.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Other Specializations" label-for="other_specializations">
                        <ValidationProvider name="other specializations" v-slot="validationContext">
                          <b-form-input id="other_specializations" v-model="form.background.other_specializations" type="text" placeholder="Enter other specializations" size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-12-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            List any specialization that you have completed
                            or have experience with. Examples are Special
                            Education (SPED), Early Childhood Education,
                            English as a Second Language, Visual and/or
                            Hearing Impairment, Education Administration,
                            etc </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Language/s Spoken *" label-for="language">
                        <ValidationProvider name="language spoken" rules="required|max:100" v-slot="validationContext">
                          <b-form-input id="language" v-model="form.background.language" type="text" placeholder="Enter language spoken" size="md" :state="getValidationState(validationContext)" required></b-form-input>
                          <b-form-invalid-feedback id="input-16-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            List all languages or dialects that you can speak
                            or write with high level of proficiency. High level
                            of proficiency means that you use the language
                            fluently and accurately on all levels pertinent to
                            professional needs. Use comma in case there’s
                            more than one.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header id="aboutSelf" v-b-toggle.aboutSelf header-tag="header" class="p-3">
              <h6 class="d-flex align-items-center">
                <font-awesome-icon class="font-size-md  mr-2" style="height:25px;width:25px;" :icon="['fas', 'book-reader']" />
                Tell us about yourself
              </h6>
              <div class="font-size-sm grey px-4 ml-2">Our tutors come from a variety of professional backgrounds - share anything you think we should know.
                Remember to put your best foot forward and make your application stand out!</div>

            </b-card-header>
            <b-collapse id="aboutSelf" :visible="isAboutYourselfVisible">
              <b-card-body class="p-5">
                <div v-show="isBackground">
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="Tagline *" label-for="tagline">
                        <ValidationProvider name="tagline" rules="required|max:60" v-slot="validationContext">
                          <b-form-input id="tagline" v-model="form.background.tagline" type="text" required placeholder="Enter tagline" size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-12-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                          <b-form-text v-if="!validationContext.errors[0]">
                            Create a catchy tagline that highlights your expertise, achievements, and teaching philosophy.
                          </b-form-text>

                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="About me *" label-for="about_me" class="custom-error-position">
                        <ValidationProvider name="about" rules="required|min:100|max:1000" v-slot="validationContext">
                          <b-form-textarea style="min-height: 138px;overflow: unset;" id="about_me" v-model="form.background.about_me" placeholder="Enter about..." required size="md" rows="3" max-rows="5" :state="getValidationState(validationContext)"></b-form-textarea>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            List teaching credentials, professional trainings, competitions won, or other relevant professional
                            certifications you have received in the last two years. You may also write something about your
                            hobbies, talents, and interests so parents and learners can relate to you more.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="About my teaching style *" label-for="my_teaching_style" class="custom-error-position">
                        <ValidationProvider name="teaching style" rules="required|min:100|max:1000" v-slot="validationContext">
                          <b-form-textarea style="min-height: 138px;overflow: unset;" id="my_teaching_style" v-model="form.background.my_teaching_style" placeholder="Enter your teaching style..." required size="md" rows="3" max-rows="5" :state="getValidationState(validationContext)"></b-form-textarea>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Share with us why you’re passionate about teaching. What’s your teaching approach and why
                            do you think it will be effective to your learners? List all the tools, techniques, and other related
                            technologies you may use on your class.

                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <div class="py-4">
                    <b-form-group>
                      <label for="">Online Profiles</label>
                      <div class="font-size-sm grey">Do you have a Facebook page, YouTube channel, TikTok profile, LinkedIn profile, or any
                        professional website that helps describe your experience and expertise? Share it with us here!</div>
                    </b-form-group>
                  </div>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="Facebook" label-for="facebook" class="custom-error-position">
                        <ValidationProvider name="facebook" v-slot="validationContext">
                          <b-form-input id="facebook" v-model="form.background.facebook" placeholder="Enter your facebook profile link..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="Youtube" label-for="youtube" class="custom-error-position">
                        <ValidationProvider name="youtube" v-slot="validationContext">
                          <b-form-input id="youtube" v-model="form.background.youtube" placeholder="Enter your youtube profile link..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="Tiktok" label-for="tiktok" class="custom-error-position">
                        <ValidationProvider name="tiktok" v-slot="validationContext">
                          <b-form-input id="tiktok" v-model="form.background.tiktok" placeholder="Enter your tiktok profile link..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="12">
                      <b-form-group id="input-group-1" label="LinkedIn" label-for="linkedin" class="custom-error-position">
                        <ValidationProvider name="linkedin" v-slot="validationContext">
                          <b-form-input id="linkedin" v-model="form.background.linkedin" placeholder="Enter your linkedin profile link..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </div>
              </b-card-body>
            </b-collapse>

          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header id="specialization" v-b-toggle.Specialization header-tag="header" class="p-3">
              <h6 class="font-size-lg d-flex align-items-center">
                <font-awesome-icon class="font-size-md  mr-2" style="height:25px;width:25px;" :icon="['fas', 'book']" />
                Specialization
              </h6>
              <div class="font-size-sm grey px-4 ml-2">Chalkboard does not limit you in choosing your specialization. However, we require that you only add
                grade levels, subjects, and lessons that you have expertise or experience in and that you are comfortable
                teaching even on short notice</div>
            </b-card-header>
            <b-collapse id="Specialization" :visible="isSpecializationVisible">
              <b-card-body class="p-5">
                <div v-show="isSpecialization">
                  <b-table-simple id="tblSpecialization">
                    <b-thead>
                      <b-th>
                        <b-form-checkbox v-model="allSelected" @change="onCheckAll" v-if="specialization.length > 0"></b-form-checkbox>
                      </b-th>
                      <b-th>Grade Level</b-th>
                      <b-th>Subjects</b-th>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, rIndex) in specialization" :key="rIndex">
                        <b-td style="vertical-align: top;">
                          <b-form-checkbox :value="item.index" v-model="selected" @change="select"></b-form-checkbox>
                        </b-td>
                        <b-td style="vertical-align: top;">
                          <b-form-select v-model="item.grade_level" @change="onSelectChange($event, rIndex)" size="md">
                            <b-form-select-option v-for="(option, index) in options" :disabled="option.disabled" :key="index" :value="option.value">{{ option.text }}</b-form-select-option>
                          </b-form-select>
                        </b-td>
                        <b-td style="vertical-align: top;">

                          <b-form-tags id="tags-component-select" v-model="item.subjects" size="lg" class="mb-2" add-on-change no-outer-focus>
                            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                  <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">{{ tag }}</b-form-tag>
                                </li>
                              </ul>
                              <b-form-select v-bind="inputAttrs" v-on="inputHandlers" :disabled="disabled || availableTagsOptions.length === 0" :options="item.subjectsPerGradeLevel">
                                <template #first>
                                  <!-- This is required to prevent bugs with Safari -->
                                  <option disabled value="">Choose a subject...</option>
                                </template>
                              </b-form-select>
                            </template>
                          </b-form-tags>

                          <!-- <b-tags v-model="item.subjects" input-id="tags-limit" placeholder="Add subject..." :input-attrs="{ list: 'tags-list' }" no-add-on-enter remove-on-delete size="md"></b-tags>
                          <b-datalist id="tags-list" :options="tagsOptions"></b-datalist> -->
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="d-flex justify-content-end">
                    <b-button variant="danger" @click="remove" class="mr-2" v-if="selected.length > 0">
                      <font-awesome-icon class="font-size-xs mr-1" style="margin-bottom: 3px;" :icon="['fas', 'trash-alt']" />Remove
                    </b-button>
                    <b-button variant="primary" @click="addnewrow" class="mr-2">
                      <font-awesome-icon class="font-size-xs mr-1" style="margin-bottom: 3px;" :icon="['fas', 'plus']" />Add Grade Level
                    </b-button>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header id="availability-tab" v-b-toggle.availability header-tag="header" class="p-3">
              <h6 class="font-size-lg d-flex align-items-center">
                <font-awesome-icon class="font-size-md  mr-2" style="height:25px;width:25px;" :icon="['fas', 'user-clock']" />
                Schedule
              </h6>
              <div class="font-size-sm grey px-4 ml-2">You are free to choose your schedule depending on your availability. To be considered an active tutor in
                the platform, you must declare at least 8 hours of availability per week. It is also important that you
                regularly update your availability because parents and learners use this information when booking</div>
            </b-card-header>
            <b-collapse id="availability" :visible="isScheduleVisible">
              <b-card-body class="p-5">
                <div v-show="isAvailability">
                  <div class="text-center">
                    <b-tabs class="availabilitytab" content-class="mt-3" align="center" justified>
                      <b-tab v-for="(item, index) in AvailabilitySchedule" :key="index" :title="item.day">
                        <div class="d-lg-flex align-items-center justify-content-between py-4 px-4 mb-4" style="border-bottom: 2px solid var(--light-silver)">
                          <div>
                            <strong>Select Timeslot for {{item.day}}</strong>
                          </div>
                          <div>
                            <b-button @click="onSelectAll(item.day)" class="selectall-btn mr-2" size="md">
                              Select All
                            </b-button>
                            <b-button @click="onClearAll(item.day)" class="clearall-btn mr-2" size="md">
                              Clear All
                            </b-button>
                          </div>
                        </div>
                        <b-row>
                          <b-col sm="12" lg="4">
                            <b-form-group label="Morning" class="text-center">
                              <b-form-checkbox-group v-model="item.items.morning" :options="timeSlots.morning" stacked buttons class="morning">
                              </b-form-checkbox-group>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="4">
                            <b-form-group label="Afternoon" class="text-center">
                              <b-form-checkbox-group v-model="item.items.afternoon" :options="timeSlots.afternoon" stacked buttons class="afternoon"></b-form-checkbox-group>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" lg="4">
                            <b-form-group label="Evening" class="text-center">
                              <b-form-checkbox-group v-model="item.items.evening" :options="timeSlots.evening" stacked buttons class="evening"></b-form-checkbox-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header v-b-toggle.rate header-tag="header" class="p-3">
              <h6 class="font-size-lg d-flex align-items-center">
                <font-awesome-icon class="font-size-md  mr-2" style="height:25px;width:25px;" :icon="['fas', 'wallet']" />
                Rate and Payout Details
              </h6>
              <div class="font-size-sm grey px-4 ml-2">You set your desired hourly rate. You can change this later, but we don’t recommend changing your rate
                frequently. If you’d like to adjust your rate, best practice is 1x-3x a year with PHP50-PHP100 adjustments.
                Make sure that you've entered your bank account information accurately before submitting this form.</div>
            </b-card-header>
            <b-collapse id="rate" :visible="isRatePayoutDetailsVisible">
              <b-card-body class="p-5">
                <div v-show="isRateAndPolicy">
                  <ValidationProvider name="rate" rules="required" v-slot="validationContext">
                    <b-row class="pb-3">
                      <b-col lg="5" sm="12">
                        <label for="rate">Rate *</label>
                        <Dropdown
                          class="w-100"
                          :class="validationContext.errors[0] ? 'p-invalid' : ''"
                          inputId="rate"
                          name="rate"
                          v-model="form.price_tier_id"
                          :options="priceTiers"
                          optionLabel="rate"
                          optionValue="id"
                          placeholder="Select a Price Tier"
                        >
                          <template #value="{ value, placeholder }">
                            <div v-if="value" class="p-dropdown-car-value">
                              <div class="d-flex align-items-center" :set="(priceTier = priceTiers.find(priceTier => priceTier.id == value))">
                                <div class="d-flex flex-column align-items-center mr-3">
                                  <small class="mb-1" style="font-weight: 500;">
                                    {{ priceTier.title }}
                                  </small>
                                  <span class="currency-localization currency-localization--xs">
                                    <span class="currency-localization__symbol" >&#8369;</span>
                                    <span class="currency-localization__whole">{{ priceTier.rate }}</span>
                                    <span class="currency-localization__decimal">.00</span>
                                    <span class="currency-localization__unit">/hr</span>
                                  </span>
                                </div>
                                <small style="text-overflow: wrap; white-space: wrap;">
                                  {{ priceTier.description }}
                                </small>
                              </div>
                            </div>
                            <span v-else>
                              {{ placeholder }}
                            </span>
                          </template>

                          <template #option="{ option: { title, rate, description } }">
                            <div class="d-flex align-items-center">
                              <div class="d-flex flex-column align-items-center mr-3">
                                <small class="mb-1" style="font-weight: 500;">
                                  {{ title }}
                                </small>
                                <span class="currency-localization currency-localization--xs">
                                  <span class="currency-localization__symbol" >&#8369;</span>
                                  <span class="currency-localization__whole">{{ rate }}</span>
                                  <span class="currency-localization__decimal">.00</span>
                                  <span class="currency-localization__unit">/hr</span>
                                </span>
                              </div>
                              <small style="text-overflow: wrap; white-space: wrap;">
                                {{ description }}
                              </small>
                            </div>
                          </template>
                        </Dropdown>

                        <b-form-invalid-feedback class="d-block" id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                  </ValidationProvider>
                  <div class="py-3">
                    <b-form-group>
                      <label for="">Primary Bank Account
                      </label>
                      <div class="font-size-sm grey">Your Primary Bank Account is your preferred payout channel where Chalkboard will deposit your earnings
                        from the platform.
                      </div>
                    </b-form-group>
                  </div>
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Primary Bank *" label-for="primary_bank">
                        <ValidationProvider name="primary bank" rules="required" v-slot="validationContext">
                          <b-form-select v-model="form.primary_bank" id="primary_bank" required :state="getValidationState(validationContext)" size="md">
                            <b-form-select-option value="null">-- select bank/wallet --</b-form-select-option>
                            <b-form-select-option-group v-for="(groupItem, groupIndex) in bankAccountOptions" :key="groupIndex" :label="`${groupIndex === 'CBLBNK' ? 'Local Banks' : groupIndex === 'EWLLET' ? 'E-Wallets' : ''}`">
                              <b-form-select-option v-for="(item, index) in groupItem" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                            </b-form-select-option-group>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Account Name *" label-for="pb_account_name" class="custom-error-position">
                        <ValidationProvider name="bank account name" rules="required" v-slot="validationContext">
                          <b-form-input id="pb_account_name" v-model="form.pb_account_name" placeholder="Enter account name..." required size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Account Type *" label-for="pb_account_type">
                        <ValidationProvider name="bank account type" rules="required" v-slot="validationContext">
                          <b-form-select id="pb_account_type" v-model="form.pb_account_type" required :state="getValidationState(validationContext)" size="md">
                            <b-form-select-option value="null">-- select account type--</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in bankAccountTypeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row class="pb-5">
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Bank Account Number *" label-for="pb_account_no" class="custom-error-position">
                        <ValidationProvider name="bank account no" rules="required" v-slot="validationContext">
                          <b-form-input id="pb_account_no" v-model="form.pb_account_no" placeholder="Enter bank account number..." required size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Do not put spaces and dashes. Enter your 11-
                            digit mobile number if using e-Wallets.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Confirm Bank Account Number *" label-for="confirm_pb_account_no" class="custom-error-position">
                        <ValidationProvider name="confirm bank account no" rules="required|confirm_bank_account_no:@bank account no" v-slot="validationContext">
                          <b-form-input id="confirm_pb_account_no" v-model="form.confirm_pb_account_no" placeholder="confirm bank account number..." required size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <div class="py-4">
                    <b-form-group>
                      <label for="">Secondary Bank Account

                      </label>
                      <div class="font-size-sm grey">Chalkboard will use this as your backup payout channel in case the nominated Primary Bank Account is
                        not available.
                      </div>
                    </b-form-group>
                  </div>

                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Secondary Bank" label-for="secondary_bank">
                        <ValidationProvider name="secondary bank" v-slot="validationContext">
                          <b-form-select v-model="form.secondary_bank" id="secondary_bank" :state="getValidationState(validationContext)" size="md">
                            <b-form-select-option value="null">-- select bank/wallet --</b-form-select-option>
                            <b-form-select-option-group v-for="(groupItem, groupIndex) in bankAccountOptions" :key="groupIndex" :label="`${groupIndex === 'CBLBNK' ? 'Local Banks' : groupIndex === 'EWLLET' ? 'E-Wallets' : ''}`">
                              <b-form-select-option v-for="(item, index) in groupItem" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                            </b-form-select-option-group>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Account Name" label-for="sb_account_name" class="custom-error-position">
                        <ValidationProvider name="secondary bank account name" v-slot="validationContext">
                          <b-form-input id="sb_account_name" v-model="form.sb_account_name" placeholder="Enter account name..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-2" label="Account Type" label-for="sb_account_type">
                        <ValidationProvider name="second bank account type" v-slot="validationContext">
                          <b-form-select id="sb_account_type" v-model="form.sb_account_type" :state="getValidationState(validationContext)" size="md">
                            <b-form-select-option value="null" disabled>-- select account type--</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in bankAccountTypeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                          </b-form-select>
                          <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row class="pb-5">
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Bank Account Number" label-for="sb_account_no" class="custom-error-position">
                        <ValidationProvider name="second bank account no" v-slot="validationContext">
                          <b-form-input id="sb_account_no" v-model="form.sb_account_no" placeholder="Enter bank account number..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          <b-form-text v-if="!validationContext.errors[0]">
                            Do not put spaces and dashes. Enter your 11-
                            digit mobile number if using e-Wallets.
                          </b-form-text>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-group id="input-group-1" label="Confirm Bank Account Number" label-for="confirm_sb_account_no" class="custom-error-position">
                        <ValidationProvider name="confirm second bank account no" rules="confirm_bank_account_no:@second bank account no" v-slot="validationContext">
                          <b-form-input id="confirm_sb_account_no" v-model="form.confirm_sb_account_no" placeholder="confirm bank account number..." size="md" :state="getValidationState(validationContext)"></b-form-input>
                          <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </div>
                <!-- <b-checkbox v-model="accept_bundle">Accept Bundle Bookings</b-checkbox>
                <b-checkbox v-model="is_discount">Give Bundle Discount(10%)</b-checkbox> -->
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <b-button @click="validateObserver" class="blue-button btn-block font-weight-bold my-3">
          <b-spinner small v-if="isLoading"></b-spinner>
          <span v-if="isLoading"> loading...</span>
          <span v-if="!isLoading">NEXT</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import mixins from "../../../../../mixins";
import MessageBox from "../../../../../mixins/message-box";
import multistepMixin from "../../../../../mixins/multistep-mixin";
import { extend } from "vee-validate";
import PhoneNumberInput from "../../../../../utility/forms/PhoneNumberInput.vue";
import CountrySelect from "../../../../../utility/forms/CountrySelect.vue";
import { mapState } from "vuex";

extend("confirm_bank_account_no", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Bank account number confirmation does not match",
});

export default {
  name: "RegistrationTeacherStep1",
  components: { PhoneNumberInput, CountrySelect },
  data: () => ({
    isBasicInformation: true,
    isBackground: true,
    isSpecialization: true,
    isAvailability: true,
    isRateAndPolicy: true,
    newTagOptions: [],
    isEducationBackgroundVisible: false,
    isAboutYourselfVisible: false,
    isSpecializationVisible: false,
    isScheduleVisible: false,
    isRatePayoutDetailsVisible: false,
    loadingPriceTiers: false,
    priceTiers: []
  }),
  beforeMount() {
    this.$store.dispatch("storeTutorRegistrationFormDispatch");
  },
  created() {
    this.getPriceTiers();
  },
  mounted() {
    const vm = this;
    document
      .querySelector("label[for='fileUpload']")
      .setAttribute("data-browse", "Browse Here");
    setTimeout(() => {
      vm.tagsOptions = vm.$store.state.subjects;
    }, 1000);
    vm.imageUrl = vm.imagePlaceholder;
    this.loadUserData()
  },
  computed: {
    ...mapState([
      "userData",
      "bankAccountData",
      "academicDegreeData",
      "bankAccountTypeData",
    ]),
    availableTagsOptions() {
      return this.tagsOptions;
    },
    bankAccountOptions() {
      return this.arrayGroupBy(this.bankAccountData, "code_type");
    },
    academicDegreeOptions() {
      return this.academicDegreeData;
    },
    bankAccountTypeOptions() {
      return this.bankAccountTypeData;
    },
  },
  methods: {
    onPNUpdate(e) {
      this.form.basicInformation.pn_country_code = e.countryCode;
      this.form.basicInformation.formattedPhoneNumber = e.formattedNumber
      this.form.basicInformation.pn_area_code = e.countryCallingCode
      this.eventPNData = e
    },
    onWAUpdate(e) {
      this.form.basicInformation.wa_country_code = e.countryCode;
      this.form.basicInformation.formattedWhatsAppNumber = e.formattedNumber
      this.form.basicInformation.wa_area_code = e.countryCallingCode
      this.eventWAData = e
    },
    onSelectAll(day) {
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.morning = this.timeSlots.morning.map((i) => i.value);
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.afternoon = this.timeSlots.afternoon.map((i) => i.value);
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.evening = this.timeSlots.evening.map((i) => i.value);
    },
    onClearAll(day) {
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.morning = [];
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.afternoon = [];
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.evening = [];
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (file) {
        if (file.size / 1024 > this.validProfileFileSize) {
          this.ShowModal(
            "The image you are trying to upload is too large.",
            () => {
              this.form.basicInformation.image = null;
              this.imageFileName = "";
              this.imageUrl = this.imagePlaceholder;
            },
            `<strong>${this.convertByteToMegaByte(
              this.validProfileFileSize
            )}MB Uploading Limit</strong>`
          );
          return;
        } else if (!/\.(jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal(
            "The file must be a file of type: jpg, jpeg, png.",
            () => {
              this.form.basicInformation.image = null;
              this.imageFileName = "";
              this.imageUrl = this.imagePlaceholder;
            }
          );
          return;
        }

        const formData = new FormData();
        const params = {
          file: file,
          original_name: file.name,
          type: "photo",
          document_type: "photo",
        };

        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
          options = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };

        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.imageFileName = _data.data.server_name;
              this.imageUrl = _data.data.path;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.basicInformation.image = null;
                this.imageFileName = "";
                this.imageUrl = this.imagePlaceholder;
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    onCheckAll() {
      this.selected = [];
      if (this.allSelected) {
        for (let i in this.specialization) {
          this.selected.push(this.specialization[i].index);
        }
      }
    },
    select() {
      this.allSelected =
        this.selected.length === this.specialization.length ? true : false;
    },
    addnewrow() {
      let lastIndex = -1;

      if (this.specialization.length > 0) {
        lastIndex = this.specialization[this.specialization.length - 1].index;
      }

      if (
        this.specialization.length <
        this.options.filter((e) => {
          return e.value != null;
        }).length
      ) {
        this.specialization.push({
          index: lastIndex + 1,
          grade_level: "",
          subjects: [],
          subjectsPerGradeLevel: [],
        });
      }

      this.allSelected = false;
    },
    remove() {
      setTimeout(() => {
        this.selected.forEach((e) => {
          const gradeLevelToRemove = this.specialization.find(
            (x) => x.index == e
          ).grade_level;
          this.specialization.splice(
            this.specialization.indexOf(
              this.specialization.find((x) => x.index == e)
            ),
            1
          );
          this.options.find(
            (x) => x.value == gradeLevelToRemove
          ).disabled = false;
        });
        this.selected = [];
      }, 200);
    },
    onSelectChange(event) {
      this.options
        .filter((e) => {
          return e.value != null;
        })
        .forEach((e) => {
          return (e.disabled = false);
        });

      const result = this.options.find((e) => e.value == event);
      const indexResult = this.options.indexOf(result);
      this.options[indexResult].disabled = true;

      this.selectedItemIndex = [];

      document
        .querySelectorAll("#tblSpecialization tbody tr select")
        .forEach((element) => {
          this.selectedItemIndex.push(element.options.selectedIndex);
        });

      const SelectedIndexes = [...new Set(this.selectedItemIndex)];

      this.options.forEach((e, index) => {
        if (SelectedIndexes.includes(index)) {
          this.options[index].disabled = true;
        }
      });

      const sendGetRequest = async () => {
        try {
          const response = await this.getSubjectPerGradeLevel(event);
          const _data = response.data.data;
          const result = [...new Set(_data.map((item) => item.category))];
          this.specialization.filter(
            (item) => item.grade_level == event
          )[0].subjectsPerGradeLevel = result;
        } catch (err) {
          console.error(err);
        }
      };

      sendGetRequest();
    },
    onProfileImageClick() {
      document.getElementById("fileUpload").click();
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          const educationBackground = ['academic degree', 'degree program', 'college university', 'academic affiliation', 'language spoken']
          const aboutYourself = ['tagline', 'about', 'teaching style']
          const ratePayoutDetails = ['rate', 'primary bank', 'bank account name', 'bank account type', 'bank account no', 'confirm bank account no']
          this.isEducationBackgroundVisible = educationBackground.includes(errors[0]["key"]) ? true : false
          this.isAboutYourselfVisible = aboutYourself.includes(errors[0]["key"]) ? true : false
          this.isRatePayoutDetailsVisible = ratePayoutDetails.includes(errors[0]["key"]) ? true : false
          setTimeout(() => {
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
          
        } else {
          this.onSubmit();
        }
      });
    },
    onSubmit() {
      let specError = 0;
      this.specialization.forEach((item) => {
        if (item.grade_level == "" || item.subjects.length == 0) {
          specError++;
        }
      });
      if (specError > 0) {
        this.ShowModal("Please select grade level and subject.", () => {
          this.isSpecializationVisible = true
          setTimeout(() => {
            const position = window.scrollY + document.getElementById("specialization").getBoundingClientRect().top;
            window.scrollTo({ top: position - 110, behavior: "smooth" });
          }, 100);
        });
        return;
      }

      let levelArr = [];

      this.specialization.forEach((x) => {
        let subj = "";
        x.subjects.forEach((item) => {
          subj += item + "_";
        });
        levelArr.push([x.grade_level + "|" + subj.replace(/_+$/, "")]);
      });

      let scheduleArr = [];

      let newAvailabilityScheduleList = [];
      this.dayOfWeekAsString().forEach((day) => {
        newAvailabilityScheduleList.push({
          day: day,
          items: {
            morning: [],
            afternoon: [],
            evening: [],
          },
        });
      });

      this.AvailabilitySchedule.forEach((s) => {
        const currDate = this.moment().tz(this.guestTZ).day(s.day);
        if (s.items.morning.length > 0) {
          s.items.morning.map((time) => {
            let stime = time.split("-")[0], 
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(12, 18).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(18, 24).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          })
        }
        if (s.items.afternoon.length > 0) {
          s.items.afternoon.map((time) => {
            let stime = time.split("-")[0],
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(12, 18).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(18, 24).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          });
        }
        if (s.items.evening.length > 0) {
          s.items.evening.map((time) => {
            let stime = time.split("-")[0],
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(12, 18).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (this.numRange(18, 24).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList.filter((n) => {
                return n.day == UTCDateStartDOW;
              })[0].items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          });
        }
      });

      newAvailabilityScheduleList.filter((e) => {
        return (
          e.items.morning.length > 0 ||
          e.items.afternoon.length > 0 ||
          e.items.evening.length > 0
        );
      }).forEach((x) => {
        const selectedMS = [];
        const selectedAS = [];
        const selectedES = [];

        x.items.morning.forEach((m) => {
          selectedMS.push(m);
        });

        x.items.afternoon.forEach((m) => {
          selectedAS.push(m);
        });

        x.items.evening.forEach((m) => {
          selectedES.push(m);
        });

        scheduleArr.push(
          x.day +
            "|" +
            selectedMS.concat(selectedAS).concat(selectedES).toString()
        );
      });

      if (this.specialization.length == 0) {
        this.ShowModal("Please select subject.", () => {
          this.isSpecializationVisible = true
          setTimeout(() => {
            const position = window.scrollY + document.getElementById("specialization").getBoundingClientRect().top;
            window.scrollTo({ top: position - 85, behavior: "smooth" });
          }, 100);
        });
        return;
      }

      const selectedAvailability = this.AvailabilitySchedule.filter((e) => {
        return (
          e.items.morning.length == 0 &&
          e.items.afternoon.length == 0 &&
          e.items.evening.length == 0
        );
      }).length;

      if (this.availability.length == selectedAvailability) {
        this.ShowModal("Please select schedule.", () => {
          this.isScheduleVisible = true
          setTimeout(() => {
            const position = window.scrollY + document.getElementById("availability-tab").getBoundingClientRect().top;
            window.scrollTo({ top: position - 85, behavior: "smooth" });
          }, 100);
        });
        return;
      }

      if (this.imageFileName == "") {
        this.ShowModal("The image field is required.", () => {
          setTimeout(() => {
            const position = window.scrollY + document.getElementById("basic-information-tab").getBoundingClientRect().top;
            window.scrollTo({ top: position - 80, behavior: "smooth" });
          }, 250)
        });
        return;
      }

      const multiData = new FormData();
      multiData.append("image", this.imageFileName);
      multiData.append("firstname", this.form.basicInformation.firstname);
      multiData.append("lastname", this.form.basicInformation.lastname);
      multiData.append("mobile_no", this.form.basicInformation.formattedPhoneNumber);
      multiData.append("birthday", this.form.basicInformation.birthday);
      multiData.append("street", this.form.basicInformation.street || '');
      multiData.append("province", this.form.basicInformation.province);
      multiData.append("city", this.form.basicInformation.city);
      multiData.append("brgy", this.form.basicInformation.brgy || '');

      multiData.append("zip_code", this.form.basicInformation.zip_code || "");
      multiData.append("tagline", this.form.background.tagline);
      multiData.append("about_me", this.form.background.about_me);

      multiData.append("education", this.form.background.education);
      multiData.append("academic_affiliation", this.form.background.academic_affiliation || "");
      multiData.append("language", this.form.background.language || "");
      multiData.append("price_tier_id", this.form.price_tier_id);

      multiData.append("legal_firstname", this.form.basicInformation.legal_firstname);
      multiData.append("legal_lastname", this.form.basicInformation.legal_lastname);
      multiData.append("pn_country_code", this.form.basicInformation.pn_country_code || "");
      multiData.append("pn_area_code", this.form.basicInformation.pn_area_code || "");
      multiData.append("whatsapp", this.form.basicInformation.formattedWhatsAppNumber || "");
      multiData.append("wa_country_code", this.form.basicInformation.whatsapp ? this.form.basicInformation.wa_country_code : "");
      multiData.append("wa_area_code", this.form.basicInformation.whatsapp ? this.form.basicInformation.wa_area_code : "");
      multiData.append("country", this.form.basicInformation.country);

      multiData.append("academic_degree", this.form.background.academic_degree);
      multiData.append("college_university", this.form.background.college_university);
      multiData.append("other_specializations", this.form.background.other_specializations || "");
      multiData.append("my_teaching_style", this.form.background.my_teaching_style);
      multiData.append("facebook", this.form.background.facebook || "");
      multiData.append("youtube", this.form.background.youtube || "");
      multiData.append("tiktok", this.form.background.tiktok || "");
      multiData.append("linkedin", this.form.background.linkedin || "");

      multiData.append("primary_bank", this.form.primary_bank);
      multiData.append("pb_account_name", this.form.pb_account_name);
      multiData.append("pb_account_type", this.form.pb_account_type);
      multiData.append("pb_account_no", this.form.pb_account_no);
      multiData.append("secondary_bank", this.form.secondary_bank || "");
      multiData.append("sb_account_name", this.form.sb_account_name || "");
      multiData.append("sb_account_type", this.form.sb_account_type || "");
      multiData.append("sb_account_no", this.form.sb_account_no || "");

      for (let i = 0; i < scheduleArr.length; i++) {
        multiData.append(`schedule[${i}]`, scheduleArr[i]);
      }

      for (let i = 0; i < levelArr.length; i++) {
        multiData.append(`level[${i}]`, levelArr[i]);
      }

      multiData.append("accept_bundle", this.accept_bundle ? 1 : 0);
      multiData.append("is_discount", this.is_discount ? 1 : 0);

      this.isLoading = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/multi-signup/info";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
        mode: "cors",
      };

      this.axios
        .post(ApiURL, multiData, options)
        .then((response) => {
          if (response.data.status === 200) {
            window.location.reload();
          }  else {
            if(response.data.status === 422 && response.data.errors.price_tier_id) {
              this.$refs.observer.setErrors({
                "rate": response.data.errors.price_tier_id
              });
              setTimeout(() => {
                this.$refs.observer.refs['rate'].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            } else {
              const errorKeys = Object.keys(response.data.errors)
              if (errorKeys.length > 0) {
                let errors = ''
                errorKeys.forEach(e => {
                  errors += `<div>${response.data.errors[e][0]}</div>`
                })
                this.ShowModal(errors)
              }
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          const payload = {
          'eventName': 'SubmitApplication'
          };
          this.metaPixelTrack(payload);
          this.isLoading = false;
        });

    },

    async getPriceTiers() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/price-tiers";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      try {
        this.loadingPriceTiers = true;
        const response = await this.axios.get(ApiURL, options)
        if (response.data.success) {
          this.priceTiers = response.data.payload;
        } else {
          this.showDynamicModal(
            "Something went wrong while loading the price tiers. Please reload the page.",
            "danger"
          );
        }
      } catch (error) {
        this.showDynamicModal(
          "Something went wrong while loading the price tiers. Please reload the page.",
          "danger"
        );
      } finally {
        this.loadingPriceTiers = false;
      }
    }
  },

  watch: {
    "$store.state.timeSlots": function () {
      const vm = this;
      const timeSlots = this.$store.state.timeSlots
        .map((item) => {
          const new_start_time = vm.moment
            .utc(
              `${vm.moment().tz(vm.guestTZ).format("YYYY-MM-DD")} ${
                item.start_time
              }`
            )
            .tz(vm.guestTZ);
          const new_end_time = vm.moment
            .utc(
              `${vm.moment().tz(vm.guestTZ).format("YYYY-MM-DD")} ${
                item.end_time
              }`
            )
            .tz(vm.guestTZ);
          return {
            start_time: new_start_time.format("HH:mm:ss"),
            end_time: new_end_time.format("HH:mm:ss"),
            text: `${new_start_time.format("h:mm A")} - ${new_end_time.format(
              "h:mm A"
            )}`,
            value: `${item.start_time}-${item.end_time}`,
            day_part: vm.getDayPart(new_start_time.format("HH:mm:ss")),
            sortH: parseInt(new_start_time.format("H")),
          };
        })
        .sort((a, b) => a.sortH - b.sortH);
      this.timeSlots = {
        morning: timeSlots
          .filter((x) => x.day_part === "Morning")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
        afternoon: timeSlots
          .filter((x) => x.day_part === "Afternoon")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
        evening: timeSlots
          .filter((x) => x.day_part === "Evening")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
      };
    },
  },
  mixins: [mixins, multistepMixin, MessageBox],
};
</script>
<style >
.accordion > .card > .card-header {
  position: relative;
}
.accordion > .card > .card-header:focus {
  outline: none;
  border: none;
}
.accordion > .card > .card-header[aria-expanded="true"]::after {
  transform: translateY(-50%) rotate(0.5turn);
}
.accordion > .card > .card-header::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:222;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.4s;
}
</style>
<style>
.btn.btn-outline-info {
  color: var(--light-blue);
  border-color: var(--light-blue);
}
.btn.btn-outline-info:hover {
  border-color: var(--light-blue);
  background: var(--light-blue);
}
.b-form-tags .badge-secondary {
  background: var(--light-blue);
}

.availabilitytab .nav-tabs .nav-link {
  margin-right: 10px;
  color: var(--dark-silver);
  background: var(--light-silver);
}

.availabilitytab .nav-item:last-child .nav-link {
  margin-right: unset;
}

.availabilitytab .nav-tabs .nav-link.active {
  background: var(--light-blue);
  color: #fff;
}
label.btn.btn-secondary {
  border: none;
  color: var(--dark-silver);
  background: var(--light-silver);
}

.morning label.btn.btn-secondary.active,
.morning .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--orange);
}
.afternoon label.btn.btn-secondary.active,
.afternoon .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--teal);
}
.evening label.btn.btn-secondary.active,
.evening .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--dark-blue);
}
.selectall-btn.btn-secondary,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):active,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):focus,
.selectall-btn.btn-secondary:not(:disabled):not(.disabled):hover {
  color: var(--light-blue);
  background: white;
  border: 1px solid var(--light-blue);
}
.clearall-btn.btn-secondary,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):active,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):focus,
.clearall-btn.btn-secondary:not(:disabled):not(.disabled):hover {
  color: var(--red);
  background: white;
  border: 1px solid var(--red);
}
</style>
<style scoped>
.p-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0.5;
  z-index: 1000000;
}
.p-loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.p-loader,
.p-loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -5.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>

<style scoped>
.custom-error-position .was-validated textarea.form-control:invalid,
.custom-error-position textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.875rem);
}
.hasErrors {
  border-color: #dc3545 !important;
}
</style>
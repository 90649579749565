<template>
  <div style="padding: 10px;">
    No active bundle session yet.
  </div>
</template>

<script>
  export default {
    name: 'ScheduleLearnerActiveBundleSessions',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store";
// For Meta tags
import VueMeta from "vue-meta";

//Landing Page
import Maintenance from "../components/Maintenance.vue";
import Index from "../components/Index.vue";

//Marketing Pages
import WhyChalkboard from "../components/WhyChalkboard.vue";
import HowWeVet from "../components/HowWeVet.vue";
import BecomeATutor from "../components/BecomeATutor.vue";
import Offerings from "../components/Offerings.vue";
import Contact from "../components/Contact.vue";
import Course from "../components/Course.vue";
import Subject from "../components/Subject.vue";

import LearnCoding from "../components/LearnCoding.vue";
import PartnerWithUs from "../components/Partner.vue";
import GroupTutoring from "../components/GroupTutoring.vue";

import HomePage from "../components/Index";
import About from "../components/About.vue";
import AboutLearners from "../components/Learners.vue";
import AboutParents from "../components/Parents.vue";
import AboutTeachers from "../components/Teachers.vue";
import Pricing from "../components/Pricing.vue";
import Dashboard from "../components/dashboard/Dashboard.vue";
import Faq from "../components/Faq.vue";
import Privacy from "../components/Privacy.vue";
import UserTc from "../components/UserTc.vue";
import TutorTc from "../components/TutorTc.vue";
import OnlineTutorialHouseRules from "../components/OnlineTutorialHouseRules.vue";
import ServiceEtiquette from "../components/ServiceEtiquette.vue";

//Authentication
import Login from "../components/authentication/login/Login.vue";
import SignUp from "../components/authentication/signup/SignUp.vue";
import EmailVerificationRedirect from "../components/authentication/signup/EmailVerificationRedirect.vue";

//Registration
import LearnerRegistration from "../components/authentication/signup/learner/RegistrationLearner.vue";
import ParentRegistration from "../components/authentication/signup/parent/RegistrationParent.vue";
import TeacherRegistration from "../components/authentication/signup/teacher/RegistrationTeacher.vue";

// Profile
import Profile from "../components/profile/Profile.vue";
import LearnerProfile from "../components/profile/learner/ProfileLearner.vue";
import ParentProfile from "../components/profile/parent/ProfileParent.vue";
import TeacherProfile from "../components/profile/teacher/ProfileTeacher.vue";
import ParentChildren from "../components/ParentChildren.vue";

import TeacherProfileAccount from "../components/profile/teacher/ProfileTeacherAbout.vue";
import TeacherProfileAbout from "../components/profile/teacher/ProfileTeacherAccount.vue";
import TeacherProfileSpecialization from "../components/profile/teacher/ProfileTeacherAvailability.vue";
import TeacherProfileAvailability from "../components/profile/teacher/ProfileTeacherSpecialization.vue";
import TeacherProfileRate from "../components/profile/teacher/ProfileTeacherRate.vue";

// Dashboard
import TeacherDashboard from "../components/dashboard/teacher/DashboardTeacher.vue";

// Booking
import Booking from "../components/booking/Booking.vue";
import SearchTeacherProfile from "../components/TeacherProfile.vue";

//Inbox
import Inbox from "../components/inbox/Inbox.vue";

// See All Chat
import Messages from "../components/Messages.vue";

//Wallet
import Wallet from "../components/wallet/Wallet.vue";

//History
import History from "../components/history/History.vue";
import SessionHistoryLearner from "../components/history/learner/SessionHistoryLearner.vue";
import SessionHistoryParent from "../components/history/parent/SessionHistoryParent.vue";
import SessionHistoryTeacher from "../components/history/teacher/SessionHistoryTeacher.vue";

// Preference
import Preferences from "../components/preference/Preferences.vue";
import ForgotPassword from "../components/ForgotPassword.vue";

import Schedules from "../components/schedule/Schedules.vue";
import ScheduleLearnerAcceptedSessions from "../components/schedule/learner/ScheduleLearnerAcceptedSessions.vue";
import ScheduleLearnerDeclinedSessions from "../components/schedule/learner/ScheduleLearnerDeclinedSessions.vue";
import ScheduleLearnerPendingSessions from "../components/schedule/learner/ScheduleLearnerPendingSessions.vue";
import ScheduleLearnerUpcomingSessions from "../components/schedule/learner/ScheduleLearnerUpcomingSessions.vue";

import ScheduleParentAcceptedSessions from "../components/schedule/parent/ScheduleParentAcceptedSessions.vue";
import ScheduleParentDeclinedSessions from "../components/schedule/parent/ScheduleParentDeclinedSessions.vue";
import ScheduleParentPendingSessions from "../components/schedule/parent/ScheduleParentPendingSessions.vue";
import ScheduleParentUpcomingSessions from "../components/schedule/parent/ScheduleParentUpcomingSessions.vue";

import ScheduleTeacherPendingSessions from "../components/schedule/teacher/ScheduleTeacherPendingSessions.vue";
import ScheduleTeacherUpcomingSessions from "../components/schedule/teacher/ScheduleTeacherUpcomingSessions.vue";
import ScheduleTeacherAcceptedSessions from "../components/schedule/teacher/ScheduleTeacherAcceptedSessions.vue";

// Session
import VideoSession from "../components/session/SessionVideo.vue";
import PreviewVideoSession from "../components/session/SessionPreview.vue";

// Scribook
import Scribook from "../components/scribook/Scribook.vue";
import ScribookTemplate from "../components/ScribookTemplate.vue";
import ScribookLearner from "../components/scribook/learner/ScribookLearner.vue";

//Collection
import CollectionBookingPaymentCheckout from "../components/collection/CollectionBookingPaymentCheckout.vue";
import CollectionBookingPaymentConfirmation from "../components/collection/CollectionBookingPaymentConfirmation.vue";
import CollectionDocCancelRefundPolicy from "../components/collection/CollectionDocCancelRefundPolicy.vue";

//Common
import CommonTeacherSearch from "../components/CommonTeacherSearch.vue";

//Vouchers
import ShareLink from "../components/ShareLink.vue";

//SCHEDULE
import Calendar from "@/components/schedule/Calendar.vue";
import Sessions from "@/components/schedule/Sessions.vue";
import Requests from "@/components/schedule/Requests.vue";
import SessionHistory from "@/components/schedule/SessionHistory.vue";

const McDonald = () => import("../components/views/promos/McDonald");

Vue.use(VueRouter);
// For Meta tags
Vue.use(VueMeta);

const routes = [
  { path: "/", alias: ["/home"], name: "home_page", component: HomePage },
  { path: "/front-page", name: "index", component: Index },
  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
    meta: { requiresLogin: true }
  },
  { path: "/about", name: "about", component: About },
  { path: "/learners", name: "about_learners", component: AboutLearners },
  { path: "/parents", name: "about_parents", component: AboutParents },
  { path: "/teachers", name: "about_teachers", component: AboutTeachers },
  { path: "/pricing", name: "pricing", component: Pricing },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresLogin: true }
  },
  {
    path: "/inbox",
    name: "inbox",
    component: Inbox,
    meta: { requiresLogin: true }
  },
  // See All Chat
  {
    path: "/messages",
    name: "messages",
    component: Messages,
    meta: { requiresLogin: true }
  },

  {
    path: "/history",
    name: "history",
    component: History,
    meta: { requiresLogin: true }
  },
  {
    path: "/session-history-learner",
    name: "session_history_learner",
    component: SessionHistoryLearner,
    meta: { requiresLogin: true }
  },
  {
    path: "/session-history-parent",
    name: "session_history_parent",
    component: SessionHistoryParent,
    meta: { requiresLogin: true }
  },
  {
    path: "/session-history-teacher",
    name: "session_history_teacher",
    component: SessionHistoryTeacher,
    meta: { requiresLogin: true }
  },
  {
    path: "/preferences",
    name: "preferences",
    component: Preferences,
    meta: { requiresLogin: true }
  },
  { path: "/share", name: "share-link", component: ShareLink },

  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
    meta: { requiresLogin: true }
  },
  {
    path: "/student-registration",
    name: "student_registration",
    component: LearnerRegistration,
    meta: { requiresLogin: true }
  },
  {
    path: "/parent-registration",
    name: "parent_registration",
    component: ParentRegistration,
    meta: { requiresLogin: true }
  },
  {
    path: "/teacher-registration",
    name: "teacher_registration",
    component: TeacherRegistration,
    meta: { requiresLogin: true }
  },
  { path: "/login", name: "login", component: Login },
  {
    path: "/signup",
    name: "sign_up",
    component: SignUp,
    meta: { redirectHomeIfAuthenticated: true }
  },
  {
    path: "/verify-email/:token",
    name: "verify-email",
    component: EmailVerificationRedirect
  },
  { path: "/faq", name: "faq", component: Faq },
  { path: "/privacy-policy", name: "privacy", component: Privacy },
  { path: "/user-terms-and-conditions", name: "user_tc", component: UserTc },
  { path: "/tutor-terms-and-conditions", name: "tutor_tc", component: TutorTc },
  {
    path: "/online-tutorial-house-rules",
    name: "online_tutorial_house_rules",
    component: OnlineTutorialHouseRules
  },
  {
    path: "/service-etiquette",
    name: "service_eqtiquette",
    component: ServiceEtiquette
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: ForgotPassword
  },
  {
    path: "/parent-profile",
    name: "parent_profile",
    component: ParentProfile,
    meta: { requiresLogin: true }
  },
  {
    path: "/learner-profile",
    name: "learner_profile",
    component: LearnerProfile,
    meta: { requiresLogin: true }
  },
  {
    path: "/teacher-profile",
    name: "teacher_profile",
    component: TeacherProfile,
    meta: { requiresLogin: true }
  },
  {
    path: "/parent-children",
    name: "parent-children",
    component: ParentChildren,
    meta: { requiresLogin: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    children: [
      { path: "/teacher-account", component: TeacherProfileAccount },
      { path: "/teacher-about", component: TeacherProfileAbout },
      {
        path: "/teacher-specialization",
        component: TeacherProfileSpecialization
      },
      { path: "/teacher-availability", component: TeacherProfileAvailability },
      { path: "/teacher-rate", component: TeacherProfileRate }
    ],
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule",
    name: "schedule",
    component: Schedules,
    children: [
      { path: "calendar", name: "calendar", component: Calendar },
      { path: "sessions", name: "sessions", component: Sessions },
      { path: "requests", name: "requests", component: Requests },
      {
        path: "session-history",
        name: "session-history",
        component: SessionHistory
      }
    ],
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-parent-accepted-sessions",
    name: "schedule_parent_accepted_sessions",
    component: ScheduleParentAcceptedSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-parent-pending-sessions",
    name: "schedule_parent_pending_sessions",
    component: ScheduleParentPendingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-parent-declined-sessions",
    name: "schedule_parent_declined_sessions",
    component: ScheduleParentDeclinedSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-parent-upcoming-sessions",
    name: "schedule_parent_upcoming_sessions",
    component: ScheduleParentUpcomingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-learner-accepted-sessions",
    name: "schedule_learner_accepted_sessions",
    component: ScheduleLearnerAcceptedSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-learner-pending-sessions",
    name: "schedule_learner_pending_sessions",
    component: ScheduleLearnerPendingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-learner-declined-sessions",
    name: "schedule_learner_declined_sessions",
    component: ScheduleLearnerDeclinedSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-learner-upcoming-sessions",
    name: "schedule_learner_upcoming_sessions",
    component: ScheduleLearnerUpcomingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-teacher-pending-sessions",
    name: "schedule_teacher_pending_sessions",
    component: ScheduleTeacherPendingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-teacher-upcoming-sessions",
    name: "schedule_teacher_upcoming_sessions",
    component: ScheduleTeacherUpcomingSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/schedule-teacher-accepted-sessions",
    name: "schedule_teacher_accepted_sessions",
    component: ScheduleTeacherAcceptedSessions,
    meta: { requiresLogin: true }
  },
  {
    path: "/profile-search",
    name: "search_teacher_profile",
    component: SearchTeacherProfile,
    meta: { requiresLogin: true }
  },
  {
    path: "/booking",
    name: "booking",
    component: Booking,
    meta: { requiresLogin: true }
  },
  {
    path: "/teacher-dashboard",
    name: "teacher_dashboard",
    component: TeacherDashboard,
    meta: { requiresLogin: true }
  },
  { path: "/search", name: "search", component: CommonTeacherSearch },
  {
    path: "/booking-payment/:tran_id",
    name: "booking-payment",
    component: CollectionBookingPaymentCheckout,
    meta: { requiresLogin: true }
  },
  {
    path: "/payment-confirmation",
    query: {
      txnid: "txnid",
      refno: "refno",
      status: "status",
      message: "message",
      digest: "digest"
    },
    name: "payment-confirmation",
    component: CollectionBookingPaymentConfirmation
  },
  {
    path: "/video-session",
    name: "video-session",
    component: VideoSession,
    meta: { requiresLogin: true }
  },
  {
    path: "/preview-room",
    name: "preview-session-room",
    component: PreviewVideoSession,
    meta: { requiresLogin: true }
  },
  {
    path: "/cancel-refund-policy",
    name: "cancel-refund-policy",
    component: CollectionDocCancelRefundPolicy
  },

  // Scribook Path
  { path: "/scribook", name: "scribook", component: Scribook },
  {
    path: "/scribook-template",
    name: "scribook_template",
    component: ScribookTemplate
  },
  {
    path: "/scribook-learner",
    name: "scribook_learner",
    component: ScribookLearner,
    meta: { requiresLogin: true }
  },

  //Marketing pages
  { path: "/course/:course_name", name: "course", component: Course },
  { path: "/subject/:subject_name", name: "subject", component: Subject },
  { path: "/offerings", name: "offerings", component: Offerings },
  { path: "/contact-chalkboard", name: "contact", component: Contact },
  { path: "/why-chalkboard", name: "why_chalkboard", component: WhyChalkboard },
  { path: "/how-we-vet", name: "how_we_vet", component: HowWeVet },
  { path: "/become-a-tutor", name: "become_a_tutor", component: BecomeATutor },
  { path: "/learn-coding", name: "learn_coding", component: LearnCoding },
  {
    path: "/partner-with-us",
    name: "partner_with_us",
    component: PartnerWithUs
  },
  { path: "/group-tutoring", name: "group_tutoring", component: GroupTutoring },

  { path: "/McDohappymeal", name: "McDonald", component: McDonald }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  }
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach((to, from, next) => {
  const isBringALearner =
    window.location.search.includes("?bring-a-learner") ||
    window.location.search.includes("redirect=%2Fdashboard%3Fbring-a-learner");
  if (
    to.name == "dashboard" &&
    (store.state.userData.role == "parent" ||
      store.state.userData.role == "student")
  ) {
    router.push("/profile" + (isBringALearner ? "?bring-a-learner" : ""));
  }
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (localStorage.getItem("userToken")) {
      next();
    } else {
      router.push({
        name: "login",
        query: {
          redirect: to.fullPath + (isBringALearner ? "?bring-a-learner" : "")
        }
      });
    }
  } else if (
    to.matched.some((record) => record.meta.redirectHomeIfAuthenticated)
  ) {
    if (localStorage.getItem("userToken")) {
      next({ name: "home_page" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

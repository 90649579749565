<template>
  <div>
    <b-table class="default-table-css font-size-sm" :fields="fields" :busy="isLoading" :items="declinedSessions.data" empty-text="No declined sessions yet." show-empty responsive>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> please wait while data is loading...</strong>
        </div>
      </template>

      <template #cell(LevelCategory)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.grade_level }}</div>
        </div>
      </template>

      <template #cell(SubjectLesson)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.subject }}</div>
        </div>
      </template>

      <template #cell(learner_name)="data">
        <div class="d-flex align-items-center">
          <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
          <div class="d-flex  flex-column">
            <div>{{data.item.learner_name}}</div>
            <small>{{`(${data.item.learner_age} y.o)`}}</small>
          </div>
        </div>
      </template>

      <template #cell(Payout)="data">
        <div class="d-flex align-items-center">
          <div>{{ `₱ ${formatPrice(data.item.total_price)}` }}</div>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button class="table-view-details-btn mx-1 my-sm-1" size="sm" v-b-tooltip.hover v-b-modal.view-details-modal title="View Details" @click="selectedData = data.item">
          <font-awesome-icon class="" :icon="['fas', 'external-link-alt']" />
        </b-button>
      </template>
    </b-table>

    <b-pagination v-if="declinedSessions.data.length > 0" v-model="currentPage" :total-rows="declinedSessions.total" :per-page="perPage" align="right" class="pt-4" size="sm"></b-pagination>

    <b-modal id="view-details-modal" size="xl" title="Set Schedule" hide-footer body-class="p-0" scrollable>
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center">
          <div>
            <div class="font-weight-semibold">Booking schedule with Learner {{ selectedData.learner_name }}</div>
          </div>
          <b-button type="button" class="close" size="sm" @click="close()">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>

      </template>

      <div class="p-3 ">

        <b-row class="pb-4">
          <b-col lg="5" sm="12">
            <!-- Transaction ID -->
            <div class="font-size-sm d-flex align-items-center ">
              <div class="relative-div px-2">
                Transaction ID: <span class="ml-2 font-weight-semibold"> {{ selectedData.transaction_id }}
                </span>
                <b-button @click="onCopy(selectedData.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>

            </div>
          </b-col>
          <b-col lg="7" sm="12">
            <!-- Request Date & Time -->
            <div class="font-size-sm">
              Request Date and Time:<span class="font-weight-semibold ml-2">{{ moment.utc(selectedData.created_at).tz(userData.timezone).format('dddd, LL') }}</span>
              <!-- Time --> <span class="ml-1 font-weight-semibold">{{ `${moment.utc(selectedData.created_at).tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</span>
            </div>
          </b-col>
        </b-row>

        <!-- Table Content -->
        <b-table class="default-table-css font-size-sm" :fields="modalFields" :items="selectedData.bookings" empty-text="No details found." show-empty responsive>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> please wait while data is loading...</strong>
            </div>
          </template>

          <template #cell(BookingID)="data">
            <div class="d-flex align-items-center relative-div mr-2">
                {{ data.item.booking_id }}
              <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-1 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
              </b-button>
            </div>
          </template>

          <template #cell(LevelCategory)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.grade_level }}</div>
            </div>
          </template>

          <template #cell(SubjectLesson)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.subject }}</div>
            </div>
          </template>

          <template #cell(Learner)>
            <div class="d-flex align-items-center">
              <b-avatar size="2em" class="mr-1" :src="selectedData.learner_image"></b-avatar>
              <div>{{selectedData.learner_name}}</div>
            </div>
          </template>

          <template #cell(Date)="data">
            <div class="d-flex align-items-center">
              <div>{{ moment.utc(data.item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
            </div>
          </template>

          <template #cell(Time)="data">
            <div class="d-flex align-items-center">
              <div>{{ `${moment.tz(data.item.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
            </div>
          </template>

        </b-table>

      </div>

      <!-- Custom Modal Footer -->
      <div class="light-white-bg w-100 p-3">
        <div class="font-weight-semibold pb-2">Notes:</div>
        <p class="font-weight-normal font-size-sm pb-4">{{ selectedData.note }}</p>

        <!-- Modal Buttons -->
        <div class="d-flex align-items-center justify-content-between pb-3">
          <!-- Download Attachment -->
          <div>
          </div>
          <div>
            <b-button class="table-close-back-btn mr-2" @click="$bvModal.hide('view-details-modal')">Back</b-button>
          </div>
        </div>

      </div>

    </b-modal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../mixins";
export default {
  props: ["isLoadTriggered"],
  name: "TeacherDeclinedSessions",
  data: () => ({
    isLoading: false,
    perPage: 10,
    currentPage: 1,
    noSessionsToday: "",
    fields: [
      "LevelCategory",
      "SubjectLesson",
      { label: "Learner", key: 'learner_name', sortable: true },
      "Payout",
      // "Reason",
      "Action",
    ],
    selectedData: [],
    modalFields: [
      "BookingID",
      "LevelCategory",
      "SubjectLesson",
      "Learner",
      "Date",
      "Time",
    ],
  }),
  computed: {
    ...mapState(["userData", "declinedSessions"]),
  },
  beforeMount() {
    if (this.isLoadTriggered) this.$emit("triggeredWatchSessions");
    this.loadSessions();
  },
  methods: {
    loadSessions() {
      const vm = this;
      vm.isLoading = true;
      vm.$store.state.declinedSessions.data = [];
      vm.$store
        .dispatch("getTutorDeclinedSessions", {
          perPage: vm.perPage,
          currentPage: vm.currentPage,
        })
        .then((response) => {
          vm.noSessionsToday =
            response.data.data.data.length === 0 ? true : false;
          vm.fields.filter(x => x['sortable'] !== undefined).map(x => x.sortable = response.data.data.data.length > 1 ? true : false)
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    onCopy(text) {
      this.$emit('copyToClipboard', text)
    },
  },
  watch: {
    currentPage() {
      this.loadSessions();
    },
    isLoadTriggered(val) {
      if (val) this.loadSessions();
      this.$emit("triggeredWatchSessions");
    },
  },
  mixins: [mixins],
};
</script>

<style lang="scss" scoped>
</style>
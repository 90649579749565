<style scoped>
.ampm-container {
  position: absolute;
  margin-top: 30px;
  top: 0;
  right: 0;
}
.ampm-container .btn.active {
  background: var(--light-blue) !important;
}
#time-zone.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.675rem;
}

@media (min-width: 300px) and (max-width: 576px) {
  .ampm-container {
    position: relative;
    margin-top: 30px;
    top: 0;
    right: 0;
  }
}
</style>
<template>
  <div>
    <div id="tutor-schedule" class="bg-white py-4 px-4 mb-4 rounded shadow-sm">
      <div class="d-flex flex-row align-items-center pb-2" style="border-bottom: 2px solid var(--silver)">
        <font-awesome-icon class="mr-1" :icon="['far', 'calendar-alt']" />
        <h6 class="m-0">Schedule</h6>
      </div>
      <br />
      <b-row class="rounded-top silver-bg p-2" align-v="center">
        <b-col sm="12" lg="6">
          <div class="d-flex align-items-center py-2">
            <font-awesome-icon class="font-size-xs mr-1 grey" :icon="['fas', 'info-circle']" />
            <label for="time-zone" class="mb-0 mr-2 grey font-weight-normal font-size-xs d-block" v-b-tooltip.hover title="Please note that changing time zone affects the displayed date and time schedules."> All schedules displayed are in your local timezone. </label>
          </div>
        </b-col>
        <b-col sm="12" lg="6">

          <b-form-select size="sm" id="time-zone" v-model="timeZone" @change="onTimeZoneChange">
            <b-form-select-option value="">-- select --</b-form-select-option>
            <b-form-select-option v-for="(item, index) in timeZonesOptions" :key="index" :value="item.timezone">{{ `${item.name} (${getTzOffSet('', item.timezone)})` }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <div class="py-1 position-relative">
        <b-form-radio-group v-model="midDay" class="ampm-container" buttons>
          <b-form-radio name="some-radios" value="AM">
            <font-awesome-icon class="" :icon="['fas', 'sun']" />
            A.M.
          </b-form-radio>
          <b-form-radio name="some-radios" value="PM">
            <font-awesome-icon class="" :icon="['fas', 'moon']" />
            P.M
          </b-form-radio>
        </b-form-radio-group>

        <div class="py-1">
          <FullCalendar
            v-if="isShowFC"
            :teacherData="items"
            :selectedTz="timeZone"
            class="profile-teacher-calendar"
            :meridiem="midDay"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../../../mixins";
import FullCalendar from "../../FullCalendar.vue";
export default {
  props: ["teacherData"],
  name: "ProfileTeacherAvailability",
  components: {
    FullCalendar,
  },
  data: () => ({
    items: [],
    timeZone: null,
    isShowFC: true,
    midDay: 'AM',
  }),
  beforeMount() {
    this.$store.dispatch("getRegionTimeZoneList");
  },
  computed: {
    timeZonesOptions() {
      return this.$store.state.regionTimeZones;
    },
  },
  mounted() {
    let vm = this;
    vm.$watch("teacherData", (val) => {
      this.items = val;
    });
    vm.$watch(
      (_) => [_.$store.state.userData.timezone, _.$store.state.regionTimeZones],
      (values) => {
        vm.timeZone = values[0] != null ? values[0] : "";
        vm.$store.dispatch("getTimeSlots", vm.timeZone);
      },
      { immediate: true }
    );
  },
  methods: {
    async onTimeZoneChange() {
      if (this.timeZone != "") {
        this.isShowFC = false;
        const updateResponse = await this.updateTimeZone(this.timeZone)
        if (updateResponse.status === 200) {
          this.ShowModal("Time zone has been updated.", async () => {
            const response = await this.$store.dispatch("getUserData", this.UserToken)
            if (response.status === 200) {
              this.isShowFC = true;
            }
          });
        }
      }
    },
  },
  watch: {
    midDay: {
      handler: function () {
        this.isShowFC = false;
        this.isShowFC = true;
      }
    }
  },
  mixins: [mixins],
};
</script>

<style>
.col-form-label {
  padding-top: 4px !important;
}
</style>
<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-1 orange-bg">

      </b-card-header>

      <b-card-body class="text-center">
        <div class="orange font-size-lg"><strong>Today's Sessions</strong></div>
        <hr>
        <div v-if="items.length == 0">No sessions today.</div>

        <!-- START : Three columns if different children have the same today session and when viewed on Schedules.vue -->

        <b-row v-if="layout == 0">
          
          <b-col lg="4" v-for="(item, index) in items" class="borderx-right"  :key="index">
          
            <b-avatar :src="item.image" size="4rem"></b-avatar>

            <div class="py-3">
          
              <div class="d-flex align-items-center justify-content-between">
                <div class="grey">Tutor Name</div>
                <div><strong>{{ item.name }}</strong></div>
              </div>
            
              <div class="d-flex align-items-center justify-content-between">
                <div class="grey">Learner Name</div>
                <div><strong>{{ item.child_name }}</strong></div>
              </div>
           
              <div class="d-flex align-items-center justify-content-between">
                <div>Time</div>
                <div><strong>{{ `${moment.utc(item.start_date).tz(guestTZ).format("hh:mm A")} (${regionTzName})` }}</strong></div>
              </div>
            
              <div class="d-flex align-items-center justify-content-between">
                <div>Grade Level</div>
                <div><strong>{{ item.grade_level }}</strong></div>
              </div>
          
              <div class="d-flex align-items-center justify-content-between">
                <div>Subject</div>
                <div><strong>{{ item.subject }}</strong></div>
              </div>
            </div>

            <b-button @click="onLaunchSession(item.booking_id)" :disabled="isAfterDate(item.start_date)" class="orange-bg white w-100">Launch Session</b-button>
          </b-col>

        </b-row>

        <!-- END : Three columns if different children have the same today session and when viewed on Schedules.vue -->

        <b-row v-else-if="layout == 1" v-for="(item, index) in items" :key="index">
          <b-col class="borderx-right">
            <!-- Parent Profile Image -->
            <b-avatar :src="item.image" size="4rem"></b-avatar>

            <div class="py-3">
              <!-- Tutor Name -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="grey">Tutor Name</div>
                <div><strong>{{ item.name }}</strong></div>
              </div>
              <!-- Learner Name -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="grey">Learner Name</div>
                <div><strong>{{ item.child_name }}</strong></div>
              </div>
              <!-- Time -->
              <div class="d-flex align-items-center justify-content-between">
                <div>Time</div>
                <div><strong>{{ `${moment.tz(item.start_date, 'UTC').tz(guestTZ).format('hh:mm A')} (${regionTzName})` }}</strong></div>
              </div>
              <!-- Grade Level -->
              <div class="d-flex align-items-center justify-content-between">
                <div>Grade Level</div>
                <div><strong>{{ item.grade_level }}</strong></div>
              </div>
              <!-- Subject -->
              <div class="d-flex align-items-center justify-content-between">
                <div>Subject</div>
                <div><strong>{{ item.subject }}</strong></div>
              </div>
            </div>

            <b-button @click="onLaunchSession(item.booking_id)" :disabled="isAfterDate(item.start_date)" class="orange-bg white w-100">Launch Session</b-button>
          </b-col>

        </b-row>

      </b-card-body>
      <b-pagination v-if="items.length > 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" class="p-3" size="sm"></b-pagination>
    </b-card>
  </div>
</template>

<script>
import mixins from "../../../mixins";
export default {
  props: ['layout'],
  name: "ScheduleParentTodaySessions",
  data: () => ({
    items: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 1,
    guestTZ: null,
    regionTzName: null
  }),
  computed: {},
  mounted() {
    const vm = this
    vm.$watch('$store.state.userData', (data) => {
      vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone
      vm.regionTzName = data.timezone == null ? vm.getTzRegion(vm.guestTz) : data.region_timezone
    }, { immediate: true })
    vm.perPage = (vm.layout == 0) ? 3 : 1
    vm.fetchTodaySessions();
  },
  methods: {
    async fetchTodaySessions() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        `/api/web/booking/today-session-user?count=${this.perPage}&page=${this.currentPage}`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };
      await this.axios.get(ApiURL, options).then((data) => {
        const _data = data.data;
        this.items = _data.data.data;
        this.totalRows = _data.data.total;
      });
    },
    onLaunchSession(bookingId) {
      window.localStorage.setItem("bookingId", bookingId);
      let routeData = this.$router.resolve({ name: "video-session" });
      window.open(routeData.href, "_blank");
    },
    isAfterDate(startDate) {
      return this.moment.utc(startDate).tz(this.guestTZ).subtract(10, 'minutes') > this.moment.tz(this.guestTZ)
    }
  },
  mixins: [mixins],
  watch: {
    currentPage: {
      handler: function () {
        this.fetchTodaySessions().catch((error) => {
          console.error(error);
        });
      },
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.borderx-right {
  border-right: 1px solid #dddddd;
}
.borderx-right:last-child {
  border-right: none;
}
</style>
<template>
  <div>
   

      <!-- Calendar and timeslots container Modal -->
      <div class="p-3">
        <!-- Calendar and timeslots Modal -->
        <b-row>
          <b-col sm="12" lg="6">
            <div class="font-weight-semibold pb-2 font-size-sm">Select Date:</div>
            <div class="rounded-top light-silver-bg  p-2">
              <div class="d-flex align-items-center justify-content-end">
                <font-awesome-icon class="font-size-xs mr-1 grey" :icon="['fas', 'info-circle']" />
                <label for="time-zone" class="mb-0 mr-2 grey font-weight-normal font-size-xs" v-b-tooltip.hover title="Please note that changing time zone affects the displayed date and time on schedules."> Time zone: </label>
                <b-form-select class="w-50" size="sm" v-bind:class="{ 'is-invalid': selectedTimeZone == '' }" id="time-zone" v-model="selectedTimeZone">
                  <b-form-select-option value="">-- select --</b-form-select-option>
                  <b-form-select-option v-for="(item, index) in timeZoneOptions" :key="index" :value="item.timezone">{{ `${item.name} (${getTzOffSet(calendar, item.timezone)})` }}</b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <b-calendar ref="calendar" hide-header class="cb-calendar cb-modal-calendar border rounded p-2" :style="
                  calendarError != ''
                    ? 'border: 1px solid #dc3545!important'
                    : ''
                " v-model="calendar" @selected="onSelectDay" :min="minDate" :max="maxDate" block locale="en-US"></b-calendar>
            <div v-if="calendarError != ''" style="display: block !important" class="invalid-feedback">
              {{ calendarError }}
            </div>
          </b-col>
          <b-col>
            <div class="font-weight-semibold pb-2 font-size-sm">Select Time:</div>
            <!-- Time Table -->
            <b-card no-body>
              <!-- Current selected Date -->
              <div class="light-silver-bg grey p-2 text-center font-weight-semibold">
                {{ moment(calendar).format('dddd, LL') }}
              </div>
              <b-row no-gutters>
                <!-- Morning, Afternoon, Evening time slot -->
                <b-col v-if="isTimeSlotsLoading">
                  <div class="text-center text-cb-color m-3">
                    <b-spinner class="align-middle" small></b-spinner>
                    <strong><small> loading time slots...</small></strong>
                  </div>
                </b-col>
                <b-col v-else v-for="(item, index) in xAvailabilityOption" :key="index" sm="12" lg="4">
                  <div class="text-center p-2 border-bottom mb-3">{{index}}</div>
                  <div class="my-3 text-center">
                    <b-form-checkbox-group v-model="xAvailabilityOption[index]" stacked buttons class="time-slot-btns">
                      <b-form-checkbox :key="sIndex" :value="xItem.value" v-for="(xItem, sIndex) in xTimeSlots.filter(_ => _.day_part === index)" :disabled="isScheduleNotAvailable(xItem.value, index)" :class="{ active: false }">{{ xItem.text }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-col>
              </b-row>

              <b-card-footer class="w-100">
                <div class="w-100 text-center">
                  <!-- Add Schedule button initial state -->
                  <b-button type="button" class="btn d-block add-schedule-btn font-size-sm" @click="addSchedule" :disabled="selectedTimeSlotsCountPerDate === 0">
                    {{ `${scheduleButtonLabel} Schedule` }}
                  </b-button>
                  <small class="font-size-xs">
                    You have selected <span class="font-weight-semibold">{{ selectedTimeSlotsCountPerDate }}</span> {{ `timeslot${selectedTimeSlotsCountPerDate > 1 ? 's' : '' }` }} for <span class="font-weight-semibold">{{ moment(calendar).format('LL') }}</span>
                  </small>
                </div>
              </b-card-footer>

            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- Custom Modal Footer -->
      <div class="dark-blue-bg text-white w-100 d-lg-flex text-sm-center align-items-center justify-content-lg-between p-3">
        <div class="font-size-sm d-flex align-items-center">
          
         
            <b-button v-b-toggle.schedule-summary-accordion-content class="font-size-sm view-schedule-summary-btn btn-reset"><div class="d-inline-block" >View Schedule Summary</div>  <span class="font-weight-normal" style="font-size: 1rem">( You have added <span class="font-weight-semibold"> {{ totalSelectedTimeSlots }} </span> {{ `Session${ totalSelectedTimeSlots > 1 ? 's' : '' }` }} )          </span>
</b-button>
        </div>
        <!-- View Schedule Summary Accordion -->
        <!-- Just Close modal -->
      <div class="d-flex align-items-center justify-content-end-lg py-2">
          <!-- Clear added Summary -->
          <b-button @click="onClearAll" size="sm" class="mx-2" style="background: transparent;" :disabled="this.schedules.length === 0">
            Clear All
          </b-button>

          <!-- Just Close modal -->
          <b-button @click="onProceed" size="sm" class="proceed-btn" :disabled="this.schedules.length === 0">
            Proceed
          </b-button>
        </div>

      </div>
      <b-collapse id="schedule-summary-accordion-content" class="dark-blue-bg p-3">
        <div class="schedule-summary-accordion-content-container bg-white">
          <div class="text-center text-cb-color m-3" v-if="isScheduleUpdatedLoading">
            <b-spinner class="align-middle" small></b-spinner>
            <strong><small> loading schedule...</small></strong>
          </div>
          <ul class="list-unstyled p-3" v-if="!isScheduleUpdatedLoading">
            <li v-for="(item, index) in schedules" :key="index" class="grey font-weight-semibold font-size-sm pb-4">
              <div class="mb-2">{{ moment(item.date_schedule).format('dddd, LL') }}</div>
              <ul class="list-unstyled">
                <li v-for="(item2, index2) in item.items" :key="index2" class="shadow-sm p-2 mb-2 rounded">
                  <div class="font-size-sm d-flex align-items-center justify-content-between">
                    <div class="d-flex">
                      <div class="font-size-xs list-counter mr-2">
                        {{ index2 + 1 }}
                      </div>
                      <div class="light-blue">
                        {{ formatDateTimeSchedule(item.date_schedule, item2) }}
                      </div>
                    </div>
                    <button @click="onScheduleSummaryRemove(item, item2)" type="button" class="light-blue close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

        </div>
        
      </b-collapse>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixins from '../../mixins'
import { getters, methods } from '../../store/booking'
export default {
  name: 'BookingCalendarModal',
  data: () => ({
    calendarError: '',
    isScheduleUpdatedLoading: false,
    bookingLimit: 20,
    scheduleButtonFlag: ''
  }),
  async mounted() {
    document.querySelectorAll('button[title="Previous month"], button[title="Current month"], button[title="Next month"]').forEach(btn => {
      btn.addEventListener('click', () => {
        setTimeout(() => { this.setActiveDateFromTz(this.selectedTimeZone) }, 100)
      })
    })
    this.isTimeSlotsLoading = true
    this.xTimeSlots = await this._getTimeSlots()
    this.isTimeSlotsLoading = false
  },
  computed: {
    ...mapState(["userData", "teacherData"]),
    ...getters,
    xAvailabilityOption() {
      return this.xAvailability.filter(_ => { return _.date_schedule === this.calendar }).map(x => x.items)[0];
    },
    selectedTimeSlotsCountPerDate() {
      let result = 0;
      const availability = this.xAvailability
        .filter((_) => _.date_schedule === this.calendar)
        .map((item) => item.items);
      if (availability.length > 0) {
        result =
          availability[0].Morning.length +
          availability[0].Afternoon.length +
          availability[0].Evening.length;
      }
      return result;
    },
    totalSelectedTimeSlots() {
      const result = this.schedules.reduce((acc, curr) => {
        return acc + curr.items.length;
      }, 0);
      return result;
    },
    scheduleButtonLabel() {
      const vm = this;
      var btnLabel = "Select";
      var availability = vm.xAvailability.find(
        (x) => x.date_schedule === vm.calendar
      );
      if (availability !== undefined) {
        availability =
          availability.items.Morning.length +
          availability.items.Afternoon.length +
          availability.items.Evening.length;
        if (availability > 0) {
          btnLabel = "Add";
        }
        if (
          vm.schedules.filter((s) => s.date_schedule === vm.calendar).length > 0
        ) {
          btnLabel = "Update";
        }
      }
      vm.scheduleButtonFlag = btnLabel;
      return btnLabel;
    },
  },
  methods: {
    ...methods,
    onSelectDay() {
      const vm = this;
      const payload = {
        schedule: vm.teacherData.schedule,
      };
      vm.onCalendarSelect(payload);
      vm.setActiveDateFromTz(vm.selectedTimeZone)
    },
    isScheduleNotAvailable(time, dayPart) {
      return this.availableSchedule.filter(item =>
                    item.date_schedule === this.calendar &&
                    item.time === time &&
                    item.day_part === dayPart).length === 0
    },
    addSchedule() {
      const vm = this;

      const currentSchedCount = vm.schedules.reduce((acc, curr) => {
        return acc + curr.items.length;
      }, 0);

      const newSchedCount = vm.xAvailability
        .filter((x) => x.date_schedule === vm.calendar)
        .reduce((acc, curr) => {
          let dayArray = [].concat.apply(
            [],
            [curr.items.Morning, curr.items.Afternoon, curr.items.Evening]
          );
          const isSchedExist = vm.schedules.find(
            (x) => x.date_schedule === vm.calendar
          );
          if (isSchedExist) {
            dayArray = dayArray.filter(
              (e) =>
                !vm.schedules.filter((s) => s.date_schedule === vm.calendar)[0]["items"].includes(e)
            );
          }
          return acc + dayArray.length;
        }, 0);

      const availabilityList = [];

      vm.xAvailability.forEach((x) => {
        const items = [].concat.apply(
          [],
          [x.items.Morning, x.items.Afternoon, x.items.Evening]
        );
        items.forEach((time) => {
          const start_time = vm.moment.tz(`${x.date_schedule} ${time.split('-')[0]}`, vm.selectedTimeZone)
          availabilityList.push({
            date_schedule: x.date_schedule,
            time: time,
            day_part: vm.getDayPart(start_time.format("HH:mm:ss")),
          });
        });
      });

      if (currentSchedCount + newSchedCount > vm.bookingLimit) {
        this.ShowModal(
          `You are only limited to a maximum of ${vm.bookingLimit} schedules per booking.`,
          () => {
            const excess = currentSchedCount + newSchedCount - vm.bookingLimit;
            if (excess > 0) {
              for (var i = vm.bookingLimit; i < vm.bookingLimit + excess; i++) {
                const a_item = vm.xAvailability.find(
                  (x) => x.date_schedule === availabilityList[i].date_schedule
                );
                if (a_item) {
                  const result = a_item.items[availabilityList[i].day_part].find((item) => item === availabilityList[i].time);
                  const index = a_item.items[availabilityList[i].day_part].indexOf(result);
                  a_item.items[availabilityList[i].day_part].splice(index, 1);
                }
              }
            }
          }
        );
        return;
      }

      vm.isScheduleUpdatedLoading = true;
      const isNewItem = vm.schedules.find(
        (x) => x.date_schedule === vm.calendar
      );
      vm.xAvailability
        .filter((x) => x.date_schedule === vm.calendar)
        .forEach((item) => {
          const DayOfWeek = this.moment(item.date_schedule).format("dddd");
          const NewItem = {
            date_schedule: item.date_schedule,
            day: DayOfWeek,
            items: [].concat.apply(
              [],
              [item.items.Morning, item.items.Afternoon, item.items.Evening]
            ),
            sortD: this.moment(item.date_schedule),
          };
          if (isNewItem === undefined) {
            vm.schedules.push(NewItem);
          } else {
            vm.schedules[vm.schedules.indexOf(isNewItem)] = NewItem;
          }
          vm.schedules.forEach((element, index) => {
            vm.schedules[index].items = element.items
              .map((x) => {
                return {
                  time: x,
                  sortT: this.moment.tz(`${item.date_schedule} ${x.split("-")[0]}`, this.selectedTimeZone).format("H"),
                };
              })
              .sort((a, b) => a.sortT - b.sortT)
              .map((item) => {
                return item.time;
              });
          });
        });
      vm.schedules.sort((a, b) => a.sortD - b.sortD);
      vm.isScheduleUpdatedLoading = false;
      const message = `The schedule has been ${
        vm.scheduleButtonFlag === "Add" ? "added" : "updated"
      } successfully.`;
      this.$bvToast.toast(message, {
        title: "System Message",
        variant: "info",
        solid: true,
        toaster: "b-toaster-top-right",
      });
    },
    onScheduleSummaryRemove(item, removeItem) {
      const vm = this;
      const scheduleItem = vm.schedules[vm.schedules.indexOf(item)].items;
      const index = scheduleItem.indexOf(removeItem);
      scheduleItem.splice(index, 1);
      if (scheduleItem.length === 0) {
        vm.schedules.splice(vm.schedules.indexOf(item), 1);
      }
      const availabilityItem = vm.xAvailability.filter(
        (x) => x.date_schedule === item.date_schedule
      )[0];
      const availItemArray = [
        availabilityItem.items.Morning,
        availabilityItem.items.Afternoon,
        availabilityItem.items.Evening,
      ];
      availItemArray.forEach((x) => {
        if (x.filter((item) => item === removeItem).length > 0) {
          const index = x.indexOf(removeItem);
          x.splice(index, 1);
        }
      });
    },
    onClearAll() {
      this.schedules = [];
      this.xAvailability.forEach((item) => {
        item.items.Morning = [];
        item.items.Afternoon = [];
        item.items.Evening = [];
      });
    },
    onProceed() {
      this.$bvModal.hide("calendar-modal");
    }
  },
  mixins: [mixins],
};
</script>

<style scoped>
/* Custom Button Group */
.btn-group-btn-outline {
  background: white;
  border: 0.5px solid var(--light-blue) !important;
  border-radius: var(--border-radius-5);
  color: var(--light-blue);
  font-weight: 600;
  outline: none;
  transition: all 300ms ease-in-out;
}
.btn-group-btn-outline-hover {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
  color: white;
}
.btn-group-btn-outline:active,
.btn-group-btn-outline:focus {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
}
.btn-group-btn-outline:hover {
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--border-radius-5);
}
.btn-secondary.disabled.btn-group-btn-outline.disabled,
.btn-secondary.disabled.btn-group-btn-outline:disabled {
  background: white !important;
  border: 0.5px solid var(--silver) !important;
  border-radius: var(--border-radius-5);
  color: var(--silver);
  font-weight: 600;
  outline: none;
  transition: all 300ms ease-in-out;
}

/* Session request steps */

ol.session-request-steps-list {
  list-style-type: none;
  counter-reset: session-request-steps;
}
ol.session-request-steps-list > li {
  counter-increment: session-request-steps;
  position: relative;
}
ol.session-request-steps-list > li::after {
  height: 100%;
  content: "";
  position: absolute;
  top: 30px;
  left: -26px;
  border-left: 2px solid var(--light-silver);
}

ol.session-request-steps-list > li::before {
  text-align: center;
  content: counter(session-request-steps);
  background: var(--dark-silver);
  color: #fff;
  position: absolute;
  left: -40px;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
}
ol.session-request-steps-list > li.active-session-request-step::before {
  background: var(--light-blue);
}

/* Session Summary CSS */
.schedule-summary-container {
  min-height: 450px;
  max-height: 550px;
  overflow-y: auto;
}

/* Session Summary list Counter */
.list-counter {
  background: var(--light-blue);
  color: #fff;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75;
}

/* Calendar Modal CSS */
.time-slot-btns .btn {
  background: white;
   color: var(--dark-grey);
  border: 0.5px solid var(--dark-grey);
  font-size: 0.6em;
  font-weight: 500;
  padding: 0.5em 0.8em;
  margin-bottom: 0.5em;
}
.time-slot-btns .btn.disabled {
  border: 0.5px solid var(--silver);
}
.time-slot-btns .btn.active {
  background-color: #d9f5fa !important;
   border: 0.5px solid #d9f5fa !important;
  color: var(--light-blue) !important;
  font-weight: 500;
  
}
.time-slot-btns .btn:hover, .time-slot-btns .btn.active:hover{
  border: 0.5px solid var(--light-blue) !important;
  color: var(--light-blue) !important;
}
.time-slot-btns .btn.disabled:hover{
  border: 0.5px solid #d0d0d0 !important;
    color: #848383 !important;
} 
.add-schedule-btn {
  background: var(--light-blue);
  color: white;
  font-weight: 600;
  width: 80%;
  transition: background 200ms ease-in-out;
  margin: auto;
}
.add-schedule-btn:hover {
  background: var(--hover-light-blue);
  transition: background 200ms ease-in-out;
}
.schedule-summary-accordion-content-container {
  min-height: 350px;
  max-height: 450px;
  overflow-y: auto;
}

.view-schedule-summary-btn {
  position: relative;
  font-weight: 500;
}

.view-schedule-summary-btn:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}

.view-schedule-summary-btn:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 300ms ease-in-out;
}
.view-schedule-summary-btn[aria-expanded="true"]:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0.5turn);
  right: -5px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:white;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 300ms ease-in-out;
}
.proceed-btn {
  background: white;
  color: var(--dark-blue);
  font-weight: 600;
}
.proceed-btn:active,
.proceed-btn:focus,
.proceed-btn:hover {
  background: white;
  color: var(--dark-blue);
  font-weight: 600;
}
/*Custom Override */

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  font-size: 0.575rem;
}
.text-cb-color {
  color: var(--light-blue) !important;
}
.sticky-top {
  top: 100px;
}
</style>
<template>
  <div>
 <div class="tutor-proposition">
            <div class="container">
                <div class="tutor-prop-inside">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="steps">
                                <div class="bg-icons build">
                                    <img :src="require('@/assets/imgs/become-a-tutor/build-your-profile.png')" alt="Build Your Profile" class="img-fluid icons">
                                </div>
                                <h5>Build Your Profile</h5>
                                <p>Build your reputation by showcasing all your expertise, competency advantages, teaching certifications, affiliations, and proof of education.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="steps">
                                <div class="bg-icons market">
                                    <img :src="require('@/assets/imgs/become-a-tutor/market-your-expertise.png')" alt="Market Your Expertise" class="img-fluid icons">
                                </div>
                                <h5>Market Your Expertise</h5>
                                <p>We provide you with a platform to market your expertise to a community of learners.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="steps">
                                <div class="bg-icons manage">
                                    <img :src="require('@/assets/imgs/become-a-tutor/manage-your-schedule.png')" alt="Manage Your Schedule" class="img-fluid icons">
                                </div>
                                <h5>Manage Your Schedule</h5>
                                <p>Tutor base on your own schedule and work anytime you want.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="steps">
                                <div class="bg-icons set">
                                    <img :src="require('@/assets/imgs/become-a-tutor/set-your-own-rate.png')" alt="Set Your Own Rate" class="img-fluid icons">
                                </div>
                                <h5>Set Your Own Rate</h5>
                                <p>You decide how much is your hourly rate based on your qualifications and experiences. Then we will handle billings and payouts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
  export default {
    name: 'Benefits',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
    <div>
        <div class="text-center mt-4">
            <b-form-rating class="font-size-xl" v-model="payload.rating" icon-empty="star-fill" variant="warning" no-border inline readonly></b-form-rating>
        </div>
        <div class="text-center mb-4 font-size-md">
            <div>
                <p class="mt-2 font-weight-bold"> You have given Tutor {{ payload.tutorName }} {{ payload.rating || 0 }} stars</p>
                <p class="mt-2 font-size-sm">This will help us improve our platform</p>
            </div>
        </div>
        <div class="text-center my-5">
            <b-button class="blue-button font-weight-bold mx-1" style="min-width: 250px;" @click="goToDashboard">Go Back to Dashboard</b-button>
            <p class="mt-2 font-size-xs">Will automatically redirect in {{ pageRedirectCountDown }}s...</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['payload'],
    data: () => ({
        redirectTimeOut: 5,
        pageRedirectCountDown: 0
    }),
    mounted() {
        if (this.payload.isRedirected) this.pageRedirectCountDown = this.redirectTimeOut
    },
    methods: {
        goToDashboard() {
            window.location.href = '/schedule'
        }
    },
    watch: {
        rating : function(val){
            this.$emit('update:ratingValue', val)
        },
        pageRedirectCountDown: {
            handler: function (value) {
                if ((value) > 0) {
                    setTimeout(() => {
                        this.pageRedirectCountDown--;
                    }, 1000);
                } else window.location.href = '/schedule'
            }
        }
    }
}
</script>
<template>

  <div>
<div :class="'course-outline ' + this.SubjectData.course_code + '-border'">
            <h6>Course Outline:</h6>
            <ol v-if="this.SubjectData.course_code != 'reading'">
                <li v-for="(item, index) in this.SubjectData.course_outline" :key="index">
                  
                        {{item.outline_item}}
                        <ol type="a" style="list-style-position: inside">
                          <li v-for="(subitem, subindex) in item.item_breakdown" :key="subindex" >{{subitem}}</li>
                        </ol>
                   

                </li>
            </ol>
            <ul v-if="this.SubjectData.course_code == 'reading'">
                <li v-for="(item, index) in this.SubjectData.course_outline" :key="index" class="mb-2">
                  
                        {{item.outline_item}}
                        <ol type="a" style="list-style-position: inside">
                          <li v-for="(subitem, subindex) in item.item_breakdown" :key="subindex" >{{subitem}}</li>
                        </ol>
                   

                </li>
            </ul>
            <p v-if="this.SubjectData.prepared_by != ''" class="prepared">Prepared by {{this.SubjectData.prepared_by}}</p>
        </div>
  </div>
</template>

<script>
  export default {
    name: 'CourseOutline',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    props:['SubjectData']
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
 <b-modal id="modal-scrollable" size="lg" scrollable title="User Terms and Conditions" ok-only>
    <div class="content">
    <strong>TheChalkboard Inc.</strong>, operating as Chalkboard
    (&quot;Chalkboard&quot;, &quot;our&quot;, &quot;us&quot; or
    &quot;we&quot;) provides an online platform known as CHALKBOARD that
    connects tutors with individuals seeking tutoring services (each a
    “User”) and other services related thereto (collectively, the
    &quot;Services&quot;) through our website, accessible at
    www.chalkboard.com.ph (the &quot;Site&quot;). The Services include the
    ability for Users and Tutors (each as defined below) to interact via
    the Chalkboard platform&#39;s interface or other methods as may be
    made available by Chalkboard from time to time. <br /><br />
    These User Terms &amp; Conditions govern your access to and use of the
    Site, the Services and the Collective Content (as hereinafter
    defined).

    <h3 class="is-size-5 my-4 dark-grey">Definitions</h3>
    <strong>"Account"</strong> means the user profile you create by
    registering as a User on the Site in accoradance with these User Terms
    <br /><br />
    <strong>&quot;Chalkboard Content&quot;</strong> means Content that
    Chalkboard makes available through the Site or Services, including any
    Content licensed from a third party, but excluding User Content.<br /><br />
    <strong>&quot;Collective Content&quot;</strong> means, collectively,
    Chalkboard Content and User Content.<br /><br />
    <strong>&quot;Content&quot;</strong> means all text, graphics, images,
    software, audio, video, information or other files or materials.<br /><br />
    <strong>“Tutor”</strong> means a tutoring or educational services
    provider accessible to Users via the Services.<br /><br />
    <strong>“Tutorial Fee”</strong> means the amount deducted from a
    User’s Monthly Credit on a proportionate basis in accordance with the
    length of a Tutor Session.<br /><br />
    <strong>“Tutor Services”</strong> mean any tutoring or educational
    services that a tutor may provide to a User that is related to a
    User&#39;s Account or the Services.<br /><br />
    <strong>&quot;Tutorial Session&quot;</strong> means the period of time
    during which Tutor Services are provided by a Tutor to a User
    calculated on an hour basis.<br /><br />
    <strong>“User”</strong> means you, a person who seeks and/or schedules
    Tutor Services for or on behalf of a student or yourself and includes
    a parent or guardian of a student if such student is a minor.<br /><br />
    <strong>&quot;User Content&quot;</strong> means Content that a tutor
    or User posts, uploads, publishes, submits or transmits to be made
    available through the Site or Services in connection with the Tutor
    Services or otherwise, including, but not limited to, Questions and
    related answers and other learning materials posted by Users and/or
    Tutors via the Services.<br /><br />
    As a User, your use of the Services is governed by the terms and
    conditions set forth herein together with Chalkboard’s applicable
    policies. THE SITE AND SERVICES COMPRISE AN ONLINE MARKETPLACE WHICH
    FACILITATES THE CONNECTION BETWEEN USERS AND TUTORS FEATURED ON THE
    SITE AND VIA THE SERVICES. CHALKBOARD CHARGES A FEE BASED ON A
    PERCENTAGE OF THE FEES EARNED BY A TUTOR WHEN HE OR SHE PROVIDES TUTOR
    SERVICES TO A USER(S). YOU UNDERSTAND AND AGREE THAT CHALKBOARD IS
    NEITHER A PARTY TO ANY AGREEMENT ENTERED INTO BETWEEN A USER AND A
    TUTOR NOR DOES CHALKBOARD HAVE ANY CONTROL OVER THE TUTOR SERVICES OR
    THE CONDUCT OF TUTORS AND OTHER USERS OF THE SITE AND SERVICES, AND
    DISCLAIMS ALL LIABILITY IN THIS REGARD. ANY MEETINGS (VIRTUAL OR
    OTHERWISE) BETWEEN USERS, TUTORS OR ANY OTHER THIRD PARTIES AND ANY
    PURCHASES OF TUTOR SERVICES PROVIDED BY TUTORS ARE DONE AT THE
    PARTIES&#39; SOLE RISK.
    <ol type="1">
      <li class="my-4 is-size-3 dark-grey">
        Eligibility; Acceptance of Terms
      </li>
      The Services made available through the Site are available only to,
      and may only be used by, individuals who are 18 years and older who
      can form legally binding contracts under applicable law. By creating
      an Account, you represent and warrant that you are at least 18 years
      old and that all registration information you submit is accurate and
      truthful. Chalkboard may, in its sole discretion, refuse to offer
      access to or use of the Site to any person or entity and change its
      eligibility criteria at any time. This provision is void where
      prohibited by law and the right to access the Site is revoked in
      such jurisdictions. Users under the age of 18 must at all times use
      the Services only in conjunction with and under the supervision of a
      teacher, parent or legal guardian who is at least 18 years of age.
      In all such cases, such teacher, parent or guardian is the User, and
      is responsible for any and all Chalkboard Account activities.
      <br /><br />
      YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE OR
      SERVICES, OR YOUR CHALKBOARD ACCOUNT, OR BY POSTING ANY CONTENT ON
      THE SITE OR THROUGH THE SERVICES IN CONNECTION WITH YOUR TUTOR
      SERVICES OR OTHERWISE, YOU ARE INDICATING THAT YOU HAVE READ, AND
      THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY, THESE USER TERMS &amp;
      CONDITIONS. IF YOU DO NOT AGREE TO THESE USER TERMS &amp;
      CONDITIONS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE OR
      SERVICES. If you accept or agree to these User Terms &amp;
      Conditions on behalf of a corporation or other legal entity, you
      represent and warrant that you have the authority to bind that
      corporation or other legal entity to these User Terms &amp;
      Conditions and, in such event, &quot;you&quot; and &quot;your&quot;
      will refer and apply to that corporation or other legal entity.
      <li class="my-4 is-size-3 dark-grey">Site and Service Operation</li>
      You acknowledge and agree that:

      <ol type="a">
        <li>
          that the Site and Services are intended only to facilitate the
          provision of Tutor Services via our online platform and its
          video and text chat and document collaboration interface;
        </li>
        <li>
          Chalkboard&#39;s role is solely to facilitate the availability
          of the Site and Services to Tutors and Users through the
          marketplace created by the Site and Services; and
        </li>
        <li>
          Chalkboard is not a contracting agent or representative of any
          Tutor. Tutors are independent contractors and are not employees
          or agents of Chalkboard.
        </li>
      </ol>

      <li class="my-4 is-size-3 dark-grey">
        Account Registration and Usage
      </li>
      <ol type="a">
        <li>
          You can register to join the Service as a User by logging into
          your Account using certain third-party social networking sites
          (“SNS”) (including, but not limited to Facebook) via our Site.
          When you register by logging into an SNS via our Site, we will
          obtain the Personal Information you have provided to that SNS
          (including, but not limited to your “real” name, profile
          picture, email address, list of SNS friends and any other
          information that you make available via the applicable SNS) from
          the account you have with such SNS and use that information to
          create your account and you will become a User; the information
          we obtain may depend on the information you provide to the
          applicable SNS and/or the privacy settings you have set or
          applied with the applicable SNS.
        </li>
        <li>
          When you register with Chalkboard and set up your Account, you:
          (i) agree to provide Chalkboard with accurate and complete
          information; (ii) agree to promptly update your Account
          information with any new information that may affect the
          operation of your Account; and (iii) authorize Chalkboard,
          directly or through third parties, to make any inquiries we
          consider necessary or appropriate to verify your Account
          information or the information you provide to us related to any
          transactions you initiate via the Site and Services. You will
          not use false identities or impersonate any other person or use
          a username or password that you are not authorized to use.
        </li>
        <li>
          You are responsible for safeguarding and maintaining the
          confidentiality of your username, password and corresponding
          Account information. You agree not to disclose your password to
          any third party and that you are entirely and solely responsible
          for any and all activities or actions that occur pursuant to the
          use of your Account, whether or not you have authorized such
          activities or actions. You will immediately notify Chalkboard of
          any unauthorized use of your username, password or Account.
        </li>

        <li>
          You agree that you will not permit, enable, introduce or
          facilitate persons who do not have an Account to have access to
          the features of the Site and Services only made available to
          registered Users.
        </li>
      </ol>
      <li class="my-4 is-size-3 dark-grey">Payment for Tutor Services</li>
      Payment is required prior to booking a Tutorial Session. The minimum
      and subsequent charge for any Tutorial Session is equivalent to a
      one-hour basis. Purchases are not eligible for refund.
      <br /><br />
      The payment of Tutorial Fees is subject to our Penalties policy (see
      Tutor Terms &amp; Conditions).
      <li class="my-4 is-size-3 dark-grey">Privacy</li>
      You acknowledge and agree that you have reviewed, acknowledge and
      agree to the Privacy Policy, which governs Chalkboard&#39;s
      collection and use of your “personal information” as defined in the
      Privacy Policy and/or Data Privacy Act of 2012.

      <li class="my-4 is-size-3 dark-grey">
        Interactions with Users and Provision of Tutor Services
      </li>
      As a User you acknowledge and agree to the following:
      <ol type="a">
        <li>
          Chalkboard acts as a technology service that facilitates the
          provision of Tutor Services by Tutors to Users through an online
          marketplace. Chalkboard does not make editorial or managerial
          decisions concerning, or otherwise exercise control or
          supervision over, a Tutor&#39;s Tutor Services, and Chalkboard
          will not be held responsible for any Tutor&#39;s failure to
          comply with applicable laws or regulations. Without limiting the
          terms of the “Limitation of Liability” section below, Chalkboard
          is not responsible for the use or exchange of any information,
          files or goods between Tutors and Users. Further, Chalkboard
          does not control, nor is it responsible for, the truth,
          accuracy, completeness, safety, timeliness, quality,
          appropriateness, legality or applicability of anything said or
          written by Tutors or Users, including, without limitation, the
          Tutor Services provided or other information made available
          through the Site and Services.
        </li>
        <li>
          Users are solely responsible for their interactions with Tutors.
          The User understands that while Chalkboard professionally
          screens all Tutors for the purposes of confirming
          identification, one (1) academic credential, teaching licensure
          (the “Screening”) and endeavors to provide Users with a
          reliable, safe and secure online environment, Chalkboard cannot
          guarantee the accuracy of the Screening and the use of the Site
          by Users and the receipt of Tutor Services is at the risk and
          discretion of each User and the User agrees to take reasonable
          precautions in all interactions with Tutors by way of the
          exercise of caution, discretion, common sense and judgment in,
          using the Site or receiving Tutor Services and disclosing
          information, including any personal information to Tutors, the
          provision of which is not required by the Site or the Tutor
          Services.
        </li>
        <li>
          Chalkboard reserves the right to contact Users, in compliance
          with applicable law, in order to evaluate compliance with
          Chalkboard’s rules and policies and these User Terms &amp;
          Conditions. If you believe that a Tutor or another User has
          violated the law or is defrauding, threatening or otherwise
          endangering anyone, Chalkboard urges you to immediately contact
          the police directly for assistance.
        </li>
        <li>
          You will comply with all applicable local, provincial, national
          and foreign laws, treatises and regulations in connection with
          your use of the Site and Services.
        </li>
        <li>
          You are solely responsible for all equipment necessary to access
          and use the Site and Services and to receive Tutor Services.
        </li>
        <li>
          You will not use the Site or Services in any manner that
          harasses a Tutor or another User or could otherwise interfere
          with any other party&#39;s use or enjoyment of the Site,
          Services or Tutor Services. You will respect the privacy of
          other Users and will not use the Site, Services or Tutor
          Services for unwelcome, rude or abusive communications or in any
          other disrespectful or detrimental manner, as determined by
          Chalkboard in its sole discretion.
        </li>
        <li>
          You will neither use the Site nor the Services to invite a Tutor
          to meet in person nor may you receive Tutor Services from a
          Tutor outside of the Site or Services.
        </li>
      </ol>

      <li class="my-4 is-size-3 dark-grey">No Endorsement</li>
      Chalkboard does not endorse any Tutor and you are responsible for
      determining and confirming the identity and suitability of the
      Tutors with whom you interact as a result of your use, or use by any
      third party, of the Site and Services. Chalkboard is not your agent
      or the agent of any Tutor and will not be responsible for any
      damages or harm which results from your interactions, or those of
      anyone else, with Tutors or other Users. By using the Site or
      Services, you, and any third party that may use the Site and
      Services under your Account, agree as follows: (i) any legal remedy
      or liability that you or such third party seek to obtain for the
      actions or omissions of a Tutor, User or other third party will be
      limited to a claim against such Tutor, User or third party who
      caused you, or such third party, harm; and (ii) you will not attempt
      to impose liability on, or seek any legal remedy from Chalkboard
      with respect to such actions or omissions. Accordingly, we encourage
      you to communicate directly with others you may come into contact
      with as a result of your use of the Site and Services.
      <li class="my-4 is-size-3 dark-grey">Ownership</li>
      The Site, Services and Collective Content are protected by
      copyright, trademark, and other laws of Philippines and foreign
      countries. You acknowledge and agree that the Site, Services and
      Collective Content, including all associated intellectual property
      rights, are the exclusive property of Chalkboard and its licensors.
      You will not remove, alter or obscure any copyright, trademark,
      service mark or other proprietary rights notices incorporated in or
      accompanying the Site, Services or Collective Content.
      <li class="my-4 is-size-3 dark-grey">Chalkboard Licenses</li>
      Subject to your compliance with the terms and conditions of these
      User Terms &amp; Conditions, Chalkboard grants you a limited,
      non-exclusive, non-transferable license: (i) to view, download and
      print any Chalkboard Content solely for your personal and
      non-commercial purposes; and (ii) to access, modify, edit and
      download any User Content to which you are permitted access solely
      for your personal and non-commercial purposes. You have no right to
      sublicense the license rights granted in this section.
      <br />
      <br />
      You will not use, copy, adapt, modify, prepare derivative works
      based upon, distribute, license, sell, transfer, publicly display,
      publicly perform, transmit, stream, broadcast or otherwise exploit
      the Site, Services or Collective Content, except as expressly
      permitted in these User Terms &amp; Conditions. No licenses or
      rights are granted to you by implication or otherwise under any
      intellectual property rights owned or controlled by Chalkboard or
      its licensors, except for the licenses and rights expressly granted
      in these User Terms &amp; Conditions.

      <li class="my-4 is-size-3 dark-grey">License Granted by User</li>
      By making any User Content available on or through the Site or
      Services, you hereby grant the following license to Chalkboard,
      which Chalkboard may exercise solely in connection with the
      Services, including its promotion of the Services, directly or
      directly: a worldwide, irrevocable, perpetual, non-exclusive,
      transferable, royalty-free license, with the right to sublicense to
      other users of the Services (including Users), to use, view, copy,
      adapt, modify, distribute, license, sell, transfer, publicly
      display, publicly perform, transmit, stream, broadcast and otherwise
      exploit such User Content. Chalkboard does not claim any ownership
      rights in any User Content and nothing in these User Terms &amp;
      Conditions will be deemed to restrict any rights that you may have
      to use and exploit any User Content.
      <br /><br />
      You acknowledge and agree that you are solely responsible for all
      User Content that you make available through the Site or Services.
      Accordingly, you represent and warrant that: (i) you either are the
      sole and exclusive owner of all User Content that you make available
      through the Site or Services or you have all rights, licenses,
      <br /><br />
      consents and releases that are necessary to grant to Chalkboard and
      to the rights in such User Content, as contemplated under these User
      Terms &amp; Conditions; and (ii) neither the User Content nor your
      posting, uploading, publication, submission or transmittal of the
      User Content or Chalkboard&#39;s use of the User Content (or any
      portion thereof) on, through or by means of the Site or Services
      will infringe, misappropriate or violate a third party&#39;s patent,
      copyright, trademark, trade secret, moral rights or other
      intellectual property rights, or rights of publicity or privacy, or
      result in the violation of any applicable law or regulation.
      <li class="my-4 is-size-3 dark-grey">Third Party Services</li>
      You understand and agree that receiving Tutor Services may require
      use of a third party website or service (each a &quot;<strong>Third Party Service</strong>&quot;). Your use of any Third-Party Service is voluntary. You
      shall be solely responsible for acquiring and maintaining all
      telecommunications and Internet services and other hardware and
      software you may decide to use to receive any Tutor Services,
      including, without limitation, any and all costs, fees, expenses,
      and taxes of any kind related to use of any Third-Party Service.
      Chalkboard will not be responsible for any loss or corruption of
      data, lost communications, or any other loss or damage of any kind
      arising from any such Third- Party Service.

      <li class="my-4 is-size-3 dark-grey">Feedback</li>
      If you decide to provide feedback, comments and suggestions for
      improvements to the Site or Services
      (&quot;<strong>Feedback</strong>&quot;), you may do so by emailing
      us at
      <a href="mailto:support@chalkboard.com.ph" class="light-blue">support@chalkboard.com.ph</a>
      . You acknowledge and agree that if you submit any Feedback to us,
      you hereby grant to us a non-exclusive, worldwide, perpetual,
      irrevocable, fully-paid, royalty-free, sub-licensable and
      transferable license under any and all intellectual property rights
      that you own or control to use, copy, modify, create derivative
      works based upon and otherwise exploit the Feedback for any purpose.

      <li class="my-4 is-size-3 dark-grey">General Prohibitions</li>
      If you decide to provide feedback, comments and suggestions for
      improvements to the Site or Services
      (&quot;<strong>Feedback</strong>&quot;), you may do so by emailing
      us at
      <a href="mailto:support@chalkboard.com.ph" class="light-blue">support@chalkboard.com.ph</a>
      . You acknowledge and agree that if you submit any Feedback to us,
      you hereby grant to us a non-exclusive, worldwide, perpetual,
      irrevocable, fully-paid, royalty-free, sub-licensable and
      transferable license under any and all intellectual property rights
      that you own or control to use, copy, modify, create derivative
      works based upon and otherwise exploit the Feedback for any purpose.
      <br /><br />
      You agree not to:

      <ol type="a">
        <li>
          Post, upload, publish, submit or transmit any Content that: (i)
          infringes, misappropriates or violates a third party&#39;s
          copyright, trademark, patent, trade secret, moral rights or
          other intellectual property rights, or rights of publicity or
          privacy; (ii) violates, or encourages any conduct that would
          violate, any applicable law or regulation or would give rise to
          civil liability; (iii) is fraudulent, false, misleading or
          deceptive; (iv) is defamatory, obscene, pornographic, vulgar or
          offensive; (v) promotes discrimination, bigotry, racism, hatred,
          harassment or harm against any individual or group; (vi) is
          violent or threatening or promotes violence or actions that are
          threatening to any person or entity; or (vii) promotes illegal
          or harmful activities or substances.
        </li>
        <li>
          Use, display, mirror or frame the Site, or any individual
          element within the Site or Services, Chalkboard&#39;s name, any
          Chalkboard trademark, logo or other proprietary information, or
          the layout and design of any page or form contained on a page,
          without Chalkboard&#39;s express written consent;
        </li>
        <li>
          Access, tamper with, or use non-public areas of the Site,
          Chalkboard&#39;s computer systems, or the technical delivery
          systems of Chalkboard&#39;s providers;
        </li>
        <li>
          Attempt to probe, scan, or test the vulnerability of any
          Chalkboard system or network or breach any security or
          authentication measures;
        </li>
        <li>
          Avoid, bypass, remove, deactivate, impair, descramble or
          otherwise circumvent any technological measure implemented by
          Chalkboard or any of Chalkboard&#39;s providers or any other
          third party (including another user) to protect the Site,
          Services or Collective Content;
        </li>
        <li>
          Attempt to access or search the Site, Services or Collective
          Content or download Collective Content from the Site or Services
          through the use of any engine, software, tool, agent, device or
          mechanism (including spiders, robots, crawlers, data mining
          tools or the like) other than the software and/or search agents
          provided by Chalkboard or other generally available third party
          web browsers;
        </li>
        <li>
          Send any unsolicited or unauthorized advertising, promotional
          materials, email, junk mail, spam, chain letters or other form
          of solicitation; h. Use any meta tags or other hidden text or
          metadata utilizing a Chalkboard trademark, logo URL or product
          name without Chalkboard&#39;s express written consent;
        </li>
        <li>
          Use the Site, Services or Collective Content for any commercial
          purpose or the benefit of any third party or in any manner not
          permitted by these User Terms &amp; Conditions;
        </li>
        <li>
          Forge any TCP/IP packet header or any part of the header
          information in any email or newsgroup posting, or in any way use
          the Site, Services or Collective Content to send altered,
          deceptive or false source-identifying information;
        </li>
        <li>
          Attempt to decipher, decompile, disassemble or reverse engineer
          any of the software used to provide the Site, Services or
          Collective Content;
        </li>
        <li>
          Interfere with, or attempt to interfere with, the access of any
          user, host or network, including, without limitation, sending a
          virus, overloading, flooding, spamming, or mail-bombing the Site
          or Services;
        </li>
        <li>
          Collect or store any personally identifiable information from
          the Site or Services from other users of the Site or Services
          without their express permission;
        </li>
        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity;
        </li>
        <li>Violate any applicable law or regulation; or</li>
        <li>
          Encourage or enable any other individual to do any of the
          foregoing.
        </li>
      </ol>
      <br /><br />
      Chalkboard will have the right to investigate and prosecute
      violations of any of the above to the fullest extent permitted by
      applicable law. Chalkboard may involve and cooperate with law
      enforcement authorities in prosecuting Users who violate these User
      Terms &amp; Conditions. You acknowledge that Chalkboard has no
      obligation to monitor your access to or use of the Site, Services or
      Collective Content or to review or edit any Collective Content, but
      has the right to do so for the purpose of operating the Site and
      Services, to ensure your compliance with these User Terms &amp;
      Conditions, or to comply with applicable law or the order or
      requirement of a court, administrative agency or other governmental
      body. Chalkboard reserves the right, at any time and without prior
      notice, to remove or disable access to any Collective Content that
      Chalkboard, at its sole discretion, considers to be in violation of
      these User Terms &amp; Conditions or otherwise harmful to the Site
      or Services.

      <li class="my-4 is-size-3 dark-grey">Links</li>
      The Site or Services may contain links to third-party websites or
      resources. You acknowledge and agree that Chalkboard is not
      responsible or liable for: (i) the availability or accuracy of such
      websites or resources; or (ii) the content, products, or services on
      or available from such websites or resources. Links to such websites
      or resources do not imply any endorsement by Chalkboard of such
      websites or resources or the content, products, or services
      available from such websites or resources. You acknowledge sole
      responsibility for and assume all risk arising from your use of any
      such websites or resources or the Content, products, or services on
      or available from such websites or resources.
      <li class="my-4 is-size-3 dark-grey">Modification</li>
      Chalkboard reserves the right, at its sole discretion, to modify,
      discontinue or terminate the Site or Services or to modify these
      User Terms &amp; Conditions, at any time and without prior notice.
      If we modify these User Terms &amp; Conditions, we will post the
      modification on the Site or provide you with notice of the
      modification. By continuing to access or use the Site or Services
      after we have posted a modification on the Site or have provided you
      with notice of a modification, you are indicating that you agree to
      be bound by the modified User Terms &amp; Conditions. If the
      modified User Terms &amp; Conditions are not acceptable to you, your
      only recourse is to cease using the Site and Services.
      <li class="my-4 is-size-3 dark-grey">
        Termination and Account Cancellation
      </li>
      If you breach any of these User Terms &amp; Conditions, Chalkboard
      will have the right to suspend or disable your Account or terminate
      these User Terms &amp; Conditions, at its sole discretion and
      without prior notice to you. Chalkboard reserves the right to revoke
      your access to and use of the Site, Services and Collective Content
      at any time, with or without cause. In the event Chalkboard
      terminates these User Terms &amp; Conditions for your breach you
      will remain liable for all amounts due hereunder. You may cancel
      your Account at any time by sending an email to
      <a class="light-blue" href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a>.
      <li class="my-4 is-size-3 dark-grey">Indemnity</li>
      You agree to defend, indemnify, and hold Chalkboard, its officers,
      directors, employees and agents, harmless from and against any
      claims, liabilities, damages, losses, and expenses, including,
      without limitation, reasonable legal and accounting fees, arising
      out of or in any way connected with your violation of these User
      Terms &amp; Conditions, your access to or use of the Site, Services
      or Collective Content, your receipt of any Tutor Services, and any
      claim made by a third party as a result of your use of the Site or
      Services or your provision of any Tutor Services, including any
      claim alleging your infringement upon the copyrights, trademarks,
      trade secrets, patents or other intellectual property rights of such
      third party or in respect of your liability for Taxes, as outlined
      above under “Taxes”.
      <li class="my-4 is-size-3 dark-grey">Limitation of Liability</li>
      YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
      LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
      SITE, SERVICES AND COLLECTIVE CONTENT REMAINS WITH YOU. NEITHER
      CHALKBOARD NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING,
      PRODUCING, OR DELIVERING THE SITE, SERVICES OR COLLECTIVE CONTENT
      WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
      CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS
      OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE
      OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES
      FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF
      OR IN CONNECTION WITH THESE USER TERMS &amp; CONDITIONS OR FROM THE
      USE OF OR INABILITY TO USE THE SITE, SERVICES OR COLLECTIVE CONTENT,
      OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH TUTORS,
      USERS OR OTHER USERS OF THE SITE OR SERVICES OR OTHER PERSONS WITH
      WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE
      OR SERVICES WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
      NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
      WHETHER OR NOT CHALKBOARD HAS BEEN INFORMED OF THE POSSIBILITY OF
      SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
      HAVE FAILED OF ITS ESSENTIAL PURPOSE.
      <br /><br />
      IN NO EVENT WILL CHALKBOARD&#39;S AGGREGATE LIABILITY ARISING OUT OF
      OR IN CONNECTION WITH THESE USER TERMS &amp; CONDITIONS OR FROM THE
      USE OF OR INABILITY TO USE THE SITE, SERVICES OR COLLECTIVE CONTENT
      EXCEED THE AMOUNT OF ALL AMOUNTS YOU HAVE PAID IN CONNECTION WITH
      YOUR RECEIPT OF TUTOR SERVICES VIA THE SITE AND SERVICES DURING THE
      IMMEDIATELY PRECEDING 12-MONTH PERIOD, OR TEN THOUSAND PESOS
      (PHP10,000.00) (WHICHEVER IS LESS). THE LIMITATIONS OF DAMAGES SET
      FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
      BETWEEN CHALKBOARD AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE
      EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
      DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.

      <br /><br />
      THE PARTIES ALSO AGREED THAT CHALKBOARD SHALL NOT, IN WHATEVER
      MANNER, BE HELD LIABLE FOR ANY AND ALL KINDS OF ACTS OR OMISSIONS
      BY, USER AND/OR ANYONE WHO HAS ACCESS TO CHALKBOARD’S ONLINE
      PLATFORM THAT CAUSES ANY FORM OF DAMAGE OR LIABILITY TO ANY PERSON.
      IT IS FURTHER AGREED THAT ANY DAMAGE, LIABILITY OR CLAIM IN RELATION
      TO THE SAID ACTS/OMISSIONS SHALL BE THE SOLE RESPONSIBILITY OF THE
      PERSON/S WHO CAUSES THE SAME. THE RESPONSIBLE PERSON/S ALSO
      UNDERTAKES TO COMPENSATE CHALKBOARD FOR ANY DAMAGE OR LOSS THAT IT
      MAY SUSTAIN AS A RESULT OF THE SAME ACTS/OMISSIONS.
      <li class="my-4 is-size-3 dark-grey">Proprietary Rights Notices</li>
      All trademarks, service marks, logos, trade names and any other
      proprietary designations of Chalkboard used herein are trademarks or
      registered trademarks of Chalkboard. Any other trademarks, service
      marks, logos, trade names and any other proprietary designations are
      the trademarks or registered trademarks of their respective parties.
      <li class="my-4 is-size-3 dark-grey">
        Controlling Law and Jurisdiction
      </li>
      These User Terms &amp; Conditions and any action related thereto
      will be governed by the laws of the Republic of the Philippines,
      without regard to conflicts of law provisions. The exclusive
      jurisdiction and venue of any action to prevent the actual or
      threatened infringement, misappropriation or violation of a
      party&#39;s copyrights, trademarks, trade secrets, patents or other
      intellectual property rights will be in accordance with Philippine
      Law. Venue of arbitration shall be in Subic Bay Freeport Zone to the
      exclusion of other venues.
      <li class="my-4 is-size-3 dark-grey">Disclaimers</li>
      THE SITE AND SERVICES ARE PROVIDED ON AN &quot;AS IS,&quot;
      &quot;WHERE IS,&quot; &quot;AS AVAILABLE&quot; AND &quot;WITH ALL
      FAULTS&quot; BASIS AND, TO THE FULLEST EXTENT PERMITTED BY LAW, ARE
      PROVIDED WITHOUT WARRANTY OF ANY KIND. CHALKBOARD AND ITS LICENSORS
      DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SITE AND SERVICES,
      INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE, AND ANY WARRANTIES
      REGARDING QUIET ENJOYMENT, QUALITY OF INFORMATION, SECURITY,
      RELIABILITY, TIMELINESS, AVAILABILITY OF BACKED-UP DATA AND
      PERFORMANCE OF THE SITE AND SERVICES. CHALKBOARD DOES NOT WARRANT
      THAT THE SITE AND SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE
      OPERATION OF THE SITE AND SERVICES WILL BE UNINTERRUPTED OR
      ERROR-FREE, OR THAT DEFECTS IN THE SITE AND SERVICES WILL BE
      CORRECTED IN A TIMELY MANNER OR AT ALL, OR THAT ENCRYPTION
      ALGORITHMS, ASSOCIATED KEYS AND OTHER SECURITY MEASURES WILL BE
      SECURE OR EFFECTIVE.
      <br /><br />
      YOU ACKNOWLEDGE AND AGREE THAT: (A) CHALKBOARD DOES NOT OPERATE OR
      CONTROL THE INTERNET AND THAT (I) VIRUSES, WORMS, TROJAN HORSES, AND
      OTHER UNDESIRABLE DATA OR SOFTWARE, OR (II) UNAUTHORIZED USERS
      (E.G., HACKERS) MAY ATTEMPT TO OBTAIN ACCESS TO AND DAMAGE YOUR
      DATA, WEBSITES, COMPUTERS, OR NETWORKS; (B) CHALKBOARD WILL NOT BE
      RESPONSIBLE FOR ANY OF THE FOREGOING ACTIVITIES; (C) YOU ARE SOLELY
      RESPONSIBLE FOR THE SECURITY AND INTEGRITY OF YOUR DATA AND SYSTEMS;
      AND (D) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
      FROM CHALKBOARD OR THROUGH THE SITE, SERVICES OR COLLECTIVE CONTENT,
      WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
      <br /><br />
      YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
      INTERACTIONS WITH TUTORS AND OTHER USERS OF THE SITE AND SERVICES
      AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A
      RESULT OF YOUR USE OF THE SITE OR SERVICES, INCLUDING, WITHOUT
      LIMITATION, ANY INTERACTION WITH TUTORS OR USERS VIA THE SERVICES OR
      OTHERWISE. YOU UNDERSTAND THAT CHALKBOARD DOES NOT MAKE ANY ATTEMPT
      TO VERIFY THE STATEMENTS OF TUTORS AND OTHER USERS OF THE SITE OR
      SERVICES. CHALKBOARD MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
      THE CONDUCT OF TUTORS OR OTHER USERS OF THE SITE OR SERVICES OR
      THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE SITE OR
      SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL
      COMMUNICATIONS AND INTERACTIONS WITH TUTORS AND OTHER USERS OF THE
      SITE AND SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE
      OR INTERACT AS A RESULT OF YOUR USE OF THE SITE OR SERVICES.
      <li class="my-4 is-size-3 dark-grey">Entire Agreement</li>
      These User Terms &amp; Conditions constitute the entire and
      exclusive understanding and agreement between Chalkboard and you
      regarding the Site, Services and
      <br /><br />
      Collective Content, and these User Terms &amp; Conditions supersede
      and replace any and all prior oral or written understandings or
      agreements between Chalkboard and you regarding the Site, Services
      and Collective Content.
      <li class="my-4 is-size-3 dark-grey">Assignment</li>
      You may not assign or transfer these User Terms &amp; Conditions, by
      operation of law or otherwise, without Chalkboard&#39;s prior
      written consent, which consent may be delayed or withheld by
      Chalkboard in its sole discretion. Any attempt by you to assign or
      transfer these User Terms &amp; Conditions, without such consent,
      will be null and of no effect. Chalkboard may assign or transfer
      these User Terms &amp; Conditions, in its sole discretion, without
      restriction. Subject to the foregoing, these User Terms &amp;
      Conditions will bind and inure to the benefit of the parties, their
      successors and permitted assigns.
      <li class="my-4 is-size-3 dark-grey">Notices</li>
      Any notices or other communications permitted or required hereunder,
      including those regarding modifications to these User Terms &amp;
      Conditions, will be in writing and given: (i) by Chalkboard via
      email (in each case to the e-mail address that you provide to us in
      establishing an Account, as you may amend from time to time); or
      (ii) by posting to the Site. For notices made by e-mail, the date of
      receipt will be deemed the date on which such notice is transmitted.
      <li class="my-4 is-size-3 dark-grey">Dispute Resolution</li>
      You and Chalkboard agree that any dispute, claim or controversy
      between you and Chalkboard, or otherwise arising out of or relating
      to these User Terms &amp; Conditions or the breach, termination,
      enforcement, interpretation or validity thereof or the use of the
      Site or Services (collectively, &quot;Disputes&quot;) will be
      settled by binding arbitration, except that each party retains the
      right to seek injunctive or other equitable relief in a court of
      competent jurisdiction to prevent the actual or threatened
      infringement, misappropriation or violation of a party&#39;s
      copyrights, trademarks, trade secrets, patents or other intellectual
      property rights. You acknowledge and agree that you and Chalkboard
      are each waiving the right to a trial by jury or to participate as a
      plaintiff or class member in any purported class action or
      representative proceeding. Further, unless both you and Chalkboard
      otherwise agree in writing, the arbitrator may not consolidate more
      than one person&#39;s claims, and may not otherwise preside over any
      form of any class or representative proceeding. Notwithstanding the
      “General” section, below, if this specific “Dispute Resolution”
      section is held to be unenforceable, then the entirety of this
      section will be deemed void, but without affecting the validity of
      the remainder of these User Terms &amp; Conditions, which will
      remain in full force and effect. Except as provided in the preceding
      sentence, this section will survive any termination of these User
      Terms &amp; Conditions. Any such arbitration contemplated by this
      section will be administered in accordance with the Republic Act
      9285 otherwise known as the Alternative Dispute Resolution Act of
      2004 (Alternative Dispute Resolution Act of 2004). The place of such
      arbitration shall be in Subic Bay Freeport Zone to the exclusion of
      other venues. The language of the arbitration will be English.
      <li class="my-4 is-size-3 dark-grey">Changes</li>
      Notwithstanding the provisions of the &quot;Modification&quot;
      section above, if Chalkboard changes this &quot;Dispute
      Resolution&quot; section after the date you first accepted these
      User Terms &amp; Conditions (or accepted any subsequent changes to
      these User Terms &amp; Conditions), you may reject any such change
      by sending us written notice (including by email to
      <a class="light-blue" href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a>) within 30 days of the date such change became effective, as
      indicated in the &quot;Last Updated Date&quot; above or in the date
      of Chalkboard&#39;s email to you notifying you of such change. By
      rejecting any change, you are agreeing that you will arbitrate any
      Dispute between you and Chalkboard in accordance with the provisions
      of this &quot;Dispute Resolution&quot; section as of the date you
      first accepted these User Terms &amp; Conditions (or accepted any
      subsequent changes to these User Terms &amp; Conditions).
      <li class="my-4 is-size-3 dark-grey">General</li>
      The failure of Chalkboard to enforce any right or provision of these
      User Terms &amp; Conditions will not constitute a waiver of future
      enforcement of that right or provision. The waiver of any such right
      or provision will be effective only if in writing and signed by a
      duly authorized representative of Chalkboard. Except as expressly
      set forth in these User Terms &amp; Conditions, the exercise by
      either party of any of its remedies under these User Terms &amp;
      Conditions will be without prejudice to its other remedies under
      these User Terms &amp; Conditions or otherwise. If for any reason a
      court of competent jurisdiction finds any provision of these User
      Terms &amp; Conditions invalid or unenforceable, that provision will
      be enforced to the maximum extent permissible and the other
      provisions of these User Terms &amp; Conditions will remain in full
      force and effect.
    </ol>
    <br /><br />
    As User, I have read, understand, and agree to abide by the foregoing
    terms and conditions of the Chalkboard including any subsequent
    amendment or revision thereof. Should I commit any violation, I
    understand and agree that my access shall be revoked, and Chalkboard
    may file appropriate action.
  </div>
  </b-modal>

 
</template>

<script>
export default {
  name: "CollectionDocCancelRefundPolicy",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
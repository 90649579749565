<template>
  <div class="voucher-dialog">
    <div class="voucher-dialog__header">
      <div class="voucher-dialog__close">
        <b-button size="sm" @click="closeAppModal()"><xmark /></b-button>
      </div>
      <div class="voucher-dialog__img-wrapper-sent"><mcdovouchersent /></div>
      <div class="voucher-dialog__title">
        CONGRATULATIONS!
      </div>
    </div>
    <div class="voucher-dialog__body">
      You have successfully claimed your voucher from Chalkboard and McDonald’s!
      Please visit your email inbox to view your voucher and complete your sign
      up.
    </div>
  </div>
</template>

<script>
import mcdovouchersent from "../../assets/imgs/mcdo-voucher-sent.svg?inline";
import xmark from "../../assets/imgs/x-mark.svg?inline";

export default {
  name: "McDoVoucherClaimed",

  components: {
    mcdovouchersent,xmark
  }
};
</script>

<template>
  <div class="chat-bubble-head-wrapper">
    <div class="position-relative" ref="bc-container">
        <div class="chat-bubble-box-container" ref="container">
        </div>
        <ChatBubbleHead />
    </div>
  </div>
</template>

<script>
import ChatBubbleHead from './ChatBubbleHead.vue'
import ChatBubbleBox from './ChatBubbleBox.vue'
import mixins from '../../mixins'
import Vue from 'vue';
import { store } from '../../store'
export default {
  name: 'ChatMain',
  data: () => ({
  }),
  components: { ChatBubbleHead },
  mounted() {
    store.state.chatOpenSelectedThreads = []
    this.$watch('$store.state.selectedInboxChatThread', (val) => {
      if (val.length !== 0) {
        var ComponentClass = Vue.extend(ChatBubbleBox)
        var instance = new ComponentClass({
            propsData: { data: val, userData: store.state.userData },
        })
        instance.$mount()
        this.$refs.container.appendChild(instance.$el)
      }
    })
  },
  computed: {},
  methods: {},
  mixins: [mixins]
}
</script>

<style scoped>
.chat-bubble-head-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1.2rem;
  z-index: 10;
}

/* Chat Bubble Box */

.chat-bubble-box-container {
  display: flex;
  direction: ltr;
  position: relative;
  right: 60px;
}

@media screen and (max-width:40em) {
  .chat-bubble-head-container, .chat-bubble-head-wrapper  {
    display: none;
  }
}
</style>
<template>
  <div>
    <!-- <DashboardLearner v-if="isUserLearner"/> -->
    <!-- <DashboardParent v-if="isUserParent"/> -->
    <DashboardTeacher v-if="isUserTeacher" />
  </div>
</template>

<script>
import mixins from "../../mixins";
// import DashboardLearner from '../../components/dashboard/learner/DashboardLearner.vue'
// import DashboardParent from '../../components/dashboard/parent/DashboardParent.vue'
import DashboardTeacher from "../../components/dashboard/teacher/DashboardTeacher.vue";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Chalkboard - Dashboard",
  },
  components: {
    // DashboardLearner,
    // DashboardParent,
    DashboardTeacher,
  },
  computed: {
    isUserParent() {
      return this.$store.state.userData.role == "parent" ? true : false;
    },
    isUserLearner() {
      return this.$store.state.userData.role == "student" ? true : false;
    },
    isUserTeacher() {
      return this.$store.state.userData.role == "teacher" ? true : false;
    },
  },
  mounted() {
    this.$store.dispatch("getUserData", window.localStorage.getItem("userToken"));
  },
  mixins: [mixins],
};
</script>

<style>
</style>
<template>
  <div>
    <b-form-group label="How would you assess your learner's performance for this session?" class="scribook-modal-step-3" size="sm" v-slot="{ ariaDescribedby }">
      <b-form-radio-group v-model="selectedOverAllAssessment" name="some-radios">
        <b-form-radio :class="{ 'selected': selectedOverAllAssessment === assessment.value }" v-for="(assessment, index) in assessmentOptions" :aria-describedby="ariaDescribedby" :value="assessment.value" name="some-radios" :key="index" size="sm">{{assessment.text}}
          <img v-if="assessment.value == 'SCRBBG'" class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_1_Beginning.svg" alt="">
          <img v-if="assessment.value == 'SCRBDV'" class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_2_Developing.svg" alt="">
          <img v-if="assessment.value == 'SCRBAP'" class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_3_Approaching_Proficiency.svg" alt="">
          <img v-if="assessment.value == 'SCRBPR'" class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_4_Proficient.svg" alt="">
          <img v-if="assessment.value == 'SCRBAD'" class="img-fluid assessment-img" src="https://developers.thetutormasters.com/img/scribook/Phase_5_Advanced.svg" alt="">

          <div class="font-weight-normal pt-2" style="font-size:14px;">{{assessment.description}}</div>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>
<script>
import mixins from "../../mixins";
export default {
  props: {
    data: Object,
    assessmentOptions: Array,
  },
  data: () => ({
    isLoadingAssessmentOptions: false,
    selectedOverAllAssessment: "",
  }),
  mounted() {
    this.selectedOverAllAssessment = this.data.assessment;
  },
  methods: {},
  watch: {
    selectedOverAllAssessment: function () {
      this.$emit("selectedOverAllAssessment", this.selectedOverAllAssessment);
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.assessment-img {
  position: absolute;
  top: -0.45rem;
  left: -3.3rem;
  width: 2.8rem;
}
</style>
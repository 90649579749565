export default {
    data: () => ({
      UserToken: window.localStorage.getItem("userToken")
    }),
    methods: {
      getTeacherReviewSummary(tutor_id) {
        const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/review/profile-review-summary'
        const options = {
          params:{
            tutor_id : tutor_id
          },
          headers: this.getGetRequestHeaders()
        }
        return this.axios.get(ApiURL, options)
      },
      getTeacherTopSubjects(tutor_id, top_count) {
        const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/review/review-teacher-top-subjects'
        const options = {
          params:{
            tutor_id : tutor_id,
            top_count: top_count,
          },
          headers: this.getGetRequestHeaders()
        }
        return this.axios.get(ApiURL, options)
      },          
      getTeacherSubjectReviews(tutor_id, subject, top_count) {
        const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/review/teacher-review-subjects'
        const options = {
          params:{
            tutor_id : tutor_id,
            subject: subject,
            top_count: top_count
          },
          headers: this.getGetRequestHeaders()
        }
        return this.axios.get(ApiURL, options)
      },                    
    }

}



import { render, staticRenderFns } from "./ScheduleTeacherAcceptedSessions.vue?vue&type=template&id=72b3a83d&scoped=true&"
import script from "./ScheduleTeacherAcceptedSessions.vue?vue&type=script&lang=js&"
export * from "./ScheduleTeacherAcceptedSessions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b3a83d",
  null
  
)

export default component.exports
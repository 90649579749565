<template>
  <div>
    
  </div>
</template>

<script>

  import mixins from '../../../../../mixins'
  import multistepMixin from '../../../../../mixins/multistep-mixin'

  export default {
    name: 'RegistrationTeacherStep4',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    mixins: [mixins, multistepMixin]
  }
</script>

<style>

</style>
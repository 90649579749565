<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit()">
        <!-- Scribook Entry Sidebar -->
        <b-sidebar ref="sidebar-modal" id="scribook-entry-sidebar" right no-close-on-backdrop shadow backdrop no-header width="30em" bg-variant="" v-if="data">
          <template #default="{ hide }">
            <div class="scribook-summary p-4">
              <!-- Scribook Overall Performance & Print button -->
              <div class="d-flex align-items-center justify-content-between mb-4">
                <div v-if="data.subjectCardPills">
                  <div v-for="(subject, index) in data.subjectCardPills" :key="index" class="d-inline">
                    <b-badge pill v-if="!subject.isOpGrade" class="scribook-card-pill" :class="`scribook-op-grade ${getAssessmentClass(subject.value)}`">{{ subject.title }}</b-badge>
                  </div>
                </div>

                <!-- Print Button -->
                <div>
                  <b-button v-if="!isViewScribook" class="scribook-btn scribook-lock-btn mr-1" :class="{ 'active': entryType === 'PRVATE' }" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" :title="setEntryTypeTitle" @click="setEntryType(data, entryType)" :disabled="isSavingChanges || isPrintScribookDownloading">
                    <font-awesome-icon v-if="entryType === 'PUBLIC'" class="font-size-sm" :icon="['fas', 'lock-open']" />
                    <font-awesome-icon  v-if="entryType === 'PRVATE'" class="font-size-sm" :icon="['fas', 'lock']" />
                  </b-button>
                  <b-button class="scribook-btn scribook-print-btn mr-1" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Print" @click="onScribookPrint()" :disabled="isSavingChanges || isPrintScribookDownloading">
                    <b-spinner class="align-middle" small v-if="isPrintScribookDownloading"></b-spinner>
                    <font-awesome-icon v-else class="font-size-sm" :icon="['fas', 'print']" />
                  </b-button>

                  <!-- Edit Button for Tutor -->
                  <b-button v-if="isViewScribook && !isEditable" class="scribook-btn scribook-print-btn mr-1" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Edit entry" @click="onEditMode()" :disabled="isSavingChanges || isPrintScribookDownloading || !isScribookAllowEdit">
                    <font-awesome-icon class="font-size-sm" :icon="['fas', 'pen']" />
                  </b-button>
                  <b-button @click="validateObserver" v-if="isViewScribook && isEditable" class="scribook-btn scribook-print-btn teal-bg mr-1" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Save changes" :disabled="isSavingChanges || isPrintScribookDownloading">
                    <font-awesome-icon class="font-size-sm" :icon="['fas', 'check']" />
                  </b-button>

                  <b-button @click="hide(); closeOnBackDrop();" class="scribook-btn scribook-close-btn" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Close" :disabled="isSavingChanges || isPrintScribookDownloading">
                    <font-awesome-icon class="font-size-sm" :icon="['fas', 'times']" />
                  </b-button>
                </div>
              </div>

              <div class="text-cb-color" style="text-align: left;margin-bottom: 10px;" v-if="isQuestionnaireLoading">
                <b-spinner class="align-middle" small></b-spinner>
                <strong><small> loading ...</small></strong>
              </div>

              <div class="pb-2" v-for="(item, index) in questionnaire" :key="index">
                <div class="scribook-summary-heading" v-if="item.question != 'Overall Assessment'">{{item.question}}</div>
                <div v-if="data.overall_assessment && item.question === 'Overall Assessment' && !isEditable">
                  <div class="scribook-summary-heading">Overall Assessment:<b-badge pill class="scribook-card-pill scribook-op-grade ml-2 mb-3" style="cursor: pointer;" :class="getAssessmentClass(data.overall_assessment)" v-b-tooltip.hover :title="getAssessmentOptionDescription">{{data.overall_assessment_title}}</b-badge>
                  </div>
                </div>
                <p v-if="item.question !== 'Overall Assessment' && !isEditable">{{item.answer}}</p>
                <ValidationProvider :name="item.name" :rules="generateRules(item.rules)" v-slot="validationContext" v-if="item.question !== 'Overall Assessment'">
                  <b-form-textarea v-if="isEditable" v-model="item['answer']" rows="4" size="sm" no-resize :state="getValidationState(validationContext)"></b-form-textarea>
                  <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>

                <div class="text-cb-color" style="text-align: left;margin-bottom: 10px;" v-if="data.overall_assessment && item.question === 'Overall Assessment' && isLoadingAssessmentOptions">
                  <b-spinner class="align-middle" small></b-spinner>
                  <strong><small> loading assessment options...</small></strong>
                </div>

                <div v-if="item.question === 'Overall Assessment' && isEditable && !isLoadingAssessmentOptions">
                  <div class="slider-content sc3" :class="{ 'overall-assessment-error': overallAssessmentError }">
                    <b-form-group class="scribook-modal-step-3" size="sm" ref="overall-assessment" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group v-model="selectedOverAllAssessment" name="some-radios">
                        <b-form-radio v-for="(assessment, index) in assessmentOptions" :key="index" :value="assessment.value" size="sm" :aria-describedby="ariaDescribedby" :class="{ 'selected': selectedOverAllAssessment === assessment.value }" name="some-radios">
                          {{assessment.text}}
                          <div class="font-weight-normal">{{assessment.description}}</div>
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                  <div style="color: #dc3545; margin-bottom: 5px;">{{overallAssessmentError}}</div>
                </div>

              </div>

              <!-- If Learner account -->
              <div class="scribook-summary-heading" v-if="userData.role === 'student'">Your moments with Tutor {{data.tutor.name}}</div>
              <!-- If Parent account -->
              <div class="scribook-summary-heading" v-if="userData.role === 'parent'">Learner {{data.learner.firstname}}'s moments with Tutor {{data.tutor.name}}</div>
              <!-- If Tutor account -->
              <div class="scribook-summary-heading" v-if="userData.role === 'teacher'">Your moments with Learner {{data.learner.name}}</div>
              <!-- Render img for uploaded photo from scribook creation Step 4 -->
              <b-img :src="data.image_server_name" fluid thumbnail v-if="['student', 'parent','teacher'].includes(userData.role)"></b-img>

            </div>
          </template>
          <template #footer="{}">
            <div class="dark-grey-bg p-4">
              <a v-b-toggle href="#sidebar-collapse" @click.prevent>
                <div class="d-flex justify-content-between">
                  <div class="pb-3 silver">
                    {{ `${moment.utc(data.bookings.start_date).tz(userData.timezone).format('LL h:mm A')} (${userData.region_timezone})` }}
                  </div>
                  <font-awesome-icon class="mr-1 silver" :icon="['fas', 'chevron-down']" />
                </div>

              </a>
              <div class="d-flex scribook-rebook-btn-container">
                <div class="silver mr-3" v-if="data.tutor" @click="onViewProfile(data.tutor.id)" :style="`${userData.role !== 'teacher' ? 'cursor: pointer' : ''}`">
                  <b-avatar :src="data.tutor.image" size="2.5em" class="mr-1" v-b-tooltip.hover :title="data.tutor.name"></b-avatar>
                  <span class="sidebar-name-span">{{ data.tutor.name }}</span>
                </div>
                <b-button v-if="!isViewScribook" class="scribook-rebook-btn" @click="onRebook(data)">
                  <font-awesome-icon class="mr-1" :icon="['fas', 'calendar']" />
                  Book Again
                </b-button>
              </div>
              <b-collapse id="sidebar-collapse">

                <div class="py-2 silver" v-if="data.learner">
                  <b-avatar :src="data.learner.image" size="2.5em" class="mr-1" v-b-tooltip.hover :title="data.learner.name"></b-avatar>
                  <span class="sidebar-name-span">{{ data.learner.name }}</span>
                </div>

                <div v-if="data.attachments">
                  <div class="py-3 silver">
                    Attachments
                  </div>

                  <div class="scribook-attachment-sidebar mr-2" v-for="(item, index) in data.attachments" :key="index" @click="onDownloadAttachmentFile(`${item.file_name}`, `${item.server_name}`)">
                    <font-awesome-icon class="mr-1" :icon="['fas', 'paperclip']" />
                    <span>{{item.file_name}}</span>
                  </div>

                  <!-- Browse File / Drag and drop label/icon Container -->
                  <div class="scribook-attachment-sidebar mt-2">
                    <div style="text-align: left;" v-if="isUploading">
                      <b-spinner class="align-middle" small></b-spinner>
                      <strong> uploading ...</strong>
                    </div>
                    <font-awesome-icon v-else class=" mr-2" style="font-size:1rem;" :icon="['fas', 'upload']" /> <span v-if="!isUploading">Upload File</span>
                    <b-form-file :disabled="isUploading" v-model="attachFiles" @change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>
                  </div>

                  <b-modal id="replace-attachment" hide-header no-close-on-backdrop centered>
                    <div class="py-2 grey">
                      <div class="pb-3">
                        <strong class="dark-grey">{{existingOrigFileName}}</strong> file is already uploaded.
                      </div>
                      <div>
                        Do you want to replace it with:
                        <div class="dark-grey"> <strong>{{attachOrigFileName}}</strong></div>
                      </div>
                    </div>
                    <template #modal-footer>
                      <b-button class="scribook-modal-btn outline mr-1" @click="onCancelUpload()" :disabled="isReplacingFile">
                        Cancel upload
                      </b-button>
                      <b-button class="scribook-modal-btn" :disabled="isReplacingFile" @click="onReplaceFile()">
                        <div style="text-align: left;" v-if="isReplacingFile">
                          <b-spinner class="align-middle" small></b-spinner>
                          <strong> replacing file...</strong>
                        </div>
                        <div v-else>Yes, replace file</div>
                      </b-button>
                    </template>
                  </b-modal>
                </div>
              </b-collapse>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </validation-observer>
    <!-- Scribook content -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../mixins";
import AOPT from "../../store/modules/assessment_options";
export default {
  props: {
    data: Object,
    scribookData: Object,
    isViewScribook: Boolean,
    isViewScribookSidebar: Boolean
  },
  name: "ScribookSidebarQuestionnaire",
  data: () => ({
    overall_assessment: "",
    isLoadingOverAllAssessment: false,
    entryType: "PUBLIC",
    isEditable: false,
    questionnaire: [],
    isQuestionnaireLoading: false,
    isSavingChanges: false,
    maxlength: 256,
    assessmentOptions: [],
    isLoadingAssessmentOptions: false,
    selectedOverAllAssessment: "",
    overallAssessmentError: "",
    attachFiles: [],
    attachFileName: "",
    attachOrigFileName: "",
    isUploading: false,
    existingOrigFileName: "",
    isReplacingFile: false,
    isPrintScribookDownloading: false
  }),
  computed: {
    ...mapState(["userData"]),
    setEntryTypeTitle() {
      return `Make this ${(this.getScribookTypeByCodeType(this.entryType) === "Public"? "Private" : "Public").toLowerCase()}`;
    },
    getAssessmentOptionDescription() {
      return this.assessmentOptions.find((x) => {
        return x.value === this.data.overall_assessment;
      })?.description;
    },
    isScribookAllowEdit() {
      return (this.isViewScribook ? Boolean(this.scribookData.scribook.scribook_allow_edit) : false)
    }
  },
  async mounted() {
    this.entryType = this.data.entryType;
    this.selectedOverAllAssessment = this.data.overall_assessment || "";
    this.getQuestionnaire();
    if (this.isViewScribookSidebar) this.$root.$emit('bv::toggle::collapse', 'scribook-entry-sidebar')
  },
  methods: {
    closeOnBackDrop() {
      this.$emit("onModalClose");
      this.$destroy();
    },
    onViewProfile(tutorId) {
      if (this.userData.role !== "teacher") {
        window.localStorage.setItem("tutorId", tutorId);
        let routeData = this.$router.resolve({
          name: "search_teacher_profile",
        });
        window.open(routeData.href, "_blank");
      }
    },
    getAssessmentClass(assessment) {
      switch (assessment) {
        case AOPT.BEGINNING:
          return "b";
        case AOPT.DEVELOPING:
          return "d";
        case AOPT.APPROACHING_PROFICIENCY:
          return "ap";
        case AOPT.PROFICIENT:
          return "p";
        case AOPT.ADVANCED:
          return "a";
      }
    },
    onRebook(data) {
      localStorage.setItem("tutorId", data.tutor.id);
      localStorage.setItem("rebookData", JSON.stringify(data.bookings));
      let routeData = this.$router.resolve({ name: "booking" });
      window.open(routeData.href, "_blank");
    },
    setEntryType(data, type) {
      this.entryType = type === "PRVATE" ? "PUBLIC" : "PRVATE";
      this.$store.dispatch("setScribookVisibility", {
        scribook_id: data.scribook_id,
        scribook_type: this.entryType,
      });
      this.$emit("updateScribookDataEntryType", this.entryType);
    },
    getScribookTypeByCodeType(type) {
      return type === "PRVATE" ? "Private" : "Public";
    },
    onEditMode() {
      this.isEditable = !this.isEditable;
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          this.onSaveChanges();
        }
      });
    },
    onSaveChanges() {
      if (!this.validateOverAllAssessment()) return;

      const assessmentDescription = this.assessmentOptions.find(
        (x) => x.value === this.data.overall_assessment
      )?.description;

      const assessment = [
        { question_item: 1, answer: this.questionnaire[0].answer },
        { question_item: 2, answer: this.questionnaire[1].answer },
        { question_item: 3, answer: this.questionnaire[2].answer },
        { question_item: 4, answer: this.questionnaire[3].answer },
        { question_item: 5, answer: assessmentDescription || "" },
      ];

      const assessmentResponsePayload = {
        scribook_id: this.data.scribook_id,
        assessment: assessment,
      };

      this.isSavingChanges = true;
      setTimeout(async () => {
        const response = await this.$store.dispatch("updateScribookAssessment", assessmentResponsePayload);
        if (response.data.status === 200) {
          this.isEditable = false;
        }
        this.isSavingChanges = false;
      }, 1);

      const overallAssessmentPayload = {
        scribook_id: this.data.scribook_id,
        overall_assessment: this.selectedOverAllAssessment,
      };

      this.isSavingChanges = true;
      setTimeout(async () => {
        const updateAssessmentResponse = await this.$store.dispatch("updateScribookOverallAssessment", overallAssessmentPayload);
        if (updateAssessmentResponse.data.status === 204) {
          this.isEditable = false;
        }
        this.isSavingChanges = false;
        this.$emit('reloadScribook', this.data)
      }, 1);
    },
    async getQuestionnaire() {
      this.isQuestionnaireLoading = true;
      const assessmentResponse = await this.$store.dispatch(
        "getAssessment",
        this.data.scribook_id
      );

      if (assessmentResponse.data.status === 200) {
        const _data = assessmentResponse.data.data;
        this.questionnaire = _data
          .map((x, index) => {
            return {
              id: 0,
              name: `text${this.renderWhiteSpace(index + 1)}`,
              question: x.question,
              answer: x.answer || "-",
              type: "textarea",
              rules: {
                isRequired: index === 0,
                maxLength: this.maxlength,
              },
            };
          })
          .sort((a, b) => a.sort_order - b.sort_order);
        this.getAssessmentOptions();
      }
      this.isQuestionnaireLoading = false;
    },
    async getAssessmentOptions() {
      this.isLoadingAssessmentOptions = true;
      const response = await this.$store.dispatch(
        "getAssessmentOptions",
        this.data.scribook_id
      );
      if (response.data.status === 200) {
        this.assessmentOptions = response.data.data.map((x) => {
          return {
            text: x.code_description,
            value: x.code_type_value,
            description: x.description,
          };
        });
      }
      this.isLoadingAssessmentOptions = false;
    },
    validateOverAllAssessment() {
      let isValid = true;
      if (this.selectedOverAllAssessment === "") {
        this.overallAssessmentError =
          "The overall assessment field is required.";
        this.$nextTick(() => {
          this.$refs["overall-assessment"][0].$el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
        isValid = false;
      }
      return isValid;
    },
    async onFileChange(e) {
      const shouldUpload = this.onFileDropChange(e);

      if (shouldUpload) {
        const params = {
          data: {
            scribook_id: this.data.scribook_id,
            attachment_file: this.getUploadFile(e),
          },
          upload_key: "upload",
        };

        this.isUploading = true;

        const response = await this.$store.dispatch(
          "uploadScribookFile",
          params
        );

        const _data = response.data.data;

        if (response.data.status === 200) {
          this.attachFileName = _data.server_attachment;
          this.attachOrigFileName = _data.original_name;

          const validateResponse = await this.$store.dispatch(
            "validateScribookFile",
            this.data.scribook_id
          );

          if (validateResponse.data.status === 200) {
            const uploadParams = {
              data: {
                scribook_id: this.data.scribook_id,
                attachment_file: this.attachOrigFileName,
                server_attachment: this.attachFileName,
              },
              upload_key: "save",
            };

            const uploadResponse = await this.$store.dispatch(
              "uploadScribookFile",
              uploadParams
            );

            if (uploadResponse.data.status === 200) {
              this.attachFileName = "";
              this.attachOrigFileName = "";
              this.$emit("reloadScribook");
            }
          } else {
            this.existingOrigFileName =
              this.data.attachments[
                this.data.attachments.length - 1
              ]?.file_name;
            this.$bvModal.show("replace-attachment");
          }
        } else {
          this.ShowModal(response.data.message);
        }

        this.isUploading = false;
      }
    },
    onCancelUpload() {
      this.$bvModal.hide("replace-attachment");
    },
    async onReplaceFile() {
      this.isReplacingFile = true;

      const uploadParams = {
        data: {
          scribook_id: this.data.scribook_id,
          attachment_file: this.attachOrigFileName,
          server_attachment: this.attachFileName,
        },
        upload_key: "save",
      };

      const uploadResponse = await this.$store.dispatch(
        "uploadScribookFile",
        uploadParams
      );

      if (uploadResponse.data.status === 200) {
        this.$bvModal.hide("replace-attachment");
        this.$emit("reloadScribook");
      }
      this.isReplacingFile = false;
    },
    async onScribookPrint() {
      this.isPrintScribookDownloading = true;
      const response = await this.$store.dispatch("downloadScribookPrintOut", {
        scribook_id: this.data.scribook_id,
      });
      if (response.data.size > 0) {
        const objectURL = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = objectURL;
        link.download = `scribook-${this.data.bookings.booking_id}`;
        link.click();
      } else {
        this.ShowModal("No file to download.");
      }
      this.isPrintScribookDownloading = false;
    },
  },
  watch: {
    selectedOverAllAssessment: function () {
      if (this.selectedOverAllAssessment !== "") {
        this.overallAssessmentError = "";
      }
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>

[data-icon="chevron-down"]{
  transition: transform 500ms ease-in-out;
}
.not-collapsed [data-icon="chevron-down"]{
  transform: rotate(180deg);
}
.scribook-modal-step-3 .custom-radio {
  cursor: pointer;
  padding-left: 2rem;
}
.overall-assessment-error {
  border: 1px solid #dc3545;
}
</style>

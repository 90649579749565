<template>
  <div>
    <div class="text-cb-color" style="text-align: center" v-if="isMessageLoading">
      <b-spinner class="align-middle" small></b-spinner>
      <strong><small> loading messages...</small></strong>
    </div>
    <div v-for="(item, index) in data" :key="index">
      <ChatBubbleMessageReceived :dataSource="dataSource" :data="{ message: item }" v-if="item.user_id !== userData.user_id"/>
      <ChatBubbleMessageSent :userData="userData" :data="{ message: item, last_seen: last_seen, isLastSentMessage: index === data.length - 1 }" v-if="item.user_id === userData.user_id"/>
    </div>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  import ChatBubbleMessageReceived from './ChatBubbleMessageReceived.vue'
  import ChatBubbleMessageSent from './ChatBubbleMessageSent.vue'
  export default {
    props: ['data', 'last_seen', 'isMessageLoading', 'userData', 'dataSource'],
    data: () => ({
    }),
    components: {
      ChatBubbleMessageReceived,
      ChatBubbleMessageSent
    },
    computed: {},
    mounted() {},
    mixins: [mixins]
  }
</script>

<style scoped>
.text-cb-color {
  color: var(--light-blue) !important;
}
</style>
<template>
  <div>
    <b-media>
      <template #aside>
        <b-avatar size="2em" :src="dataSource.data.image"></b-avatar>
      </template>
      <p class="chat-bubble-box-message" v-if="data.message.attachment_name !== null && data.message.attachment_url !== null"><font-awesome-icon class="mr-2 font-size-xs" :icon="['fas', 'paperclip']" /><a target="_blank" @click="onDownloadAttachmentFile(data.message.attachment_name, `${data.message.attachment_url}`)">{{ data.message.message }}</a></p>
      <p class="chat-bubble-box-message" v-else>{{ data.message.message }}</p>
    </b-media>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    props: {
      data: null,
      dataSource: null
    },
    data: () => ({
      attachmentPath: process.env.VUE_APP_BACKEND_URL
    }),
    mounted() {},
    methods: {},
    mixins: [mixins]
  }
</script>

<style scoped>
.chat-bubble-box-message {
  display: inline-block;
  background: #f6f6f6;
  color: var(--dark-grey);
  font-size: calc(var(--fs-one)- 2px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
  word-break: break-word;
}
.chat-bubble-box-message a {
  color: var(--dark-grey);
}
.chat-bubble-box-message a:hover {
  text-decoration: underline!important;
  cursor: pointer;
}
</style>
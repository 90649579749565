<template>
  <div>
    <b-table class="default-table-css font-size-sm" empty-text="No pending sessions yet." :fields="fields" :items="items" :busy="isLoading" show-empty responsive>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> please wait while data is loading...</strong>
        </div>
      </template>

      <template #cell(LevelCategory)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.LevelCategory }}</div>
        </div>
      </template>

      <template #cell(SubjectLesson)="data">
        <div class="d-flex align-items-center">
          <div>{{ data.item.SubjectLesson }}</div>
        </div>
      </template>

      <template #cell(Learner)="data">
        <div class="d-flex align-items-center">
          <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
          <div class="d-flex  flex-column">
            <div>{{ data.item.Learner }}</div>
            <small>{{`(${data.item.learner_age} y.o)`}}</small>
          </div>
        </div>
      </template>

      <template #cell(Payout)="data">
        <div class="d-flex align-items-center">
          <div>{{ `₱ ${formatPrice(data.item.Amount)}` }}</div>
        </div>
      </template>

      <template #cell(Expiry)="data">
        <div class="d-flex align-items-center">
          <div>{{ formatExpiry(data.item.Expiry) }}</div>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button class="table-reject-btn mr-1" size="sm" @click="onRejectRequest(data.item.transaction_id)" v-b-tooltip.hover title="Reject request">
          <font-awesome-icon class="" :icon="['fas', 'times']" />
        </b-button>
        <b-button :disabled="isDisabledAcceptButton" class="table-accept-btn mr-1" size="sm" @click="onAcceptRequest(data.item.transaction_id)" v-b-tooltip.hover title="Accept request">
          <b-spinner small v-if="isCurrentAcceptButtonDisabled(data.item.transaction_id)"></b-spinner>
          <font-awesome-icon v-else class="" :icon="['fas', 'check']" />
        </b-button>
        <b-button class="table-view-details-btn mr-1" size="sm" v-b-tooltip.hover v-b-modal.view-details-modal title="View Details" @click="selectedData = data.item.data">
          <font-awesome-icon class="" :icon="['fas', 'external-link-alt']" />
        </b-button>
      </template>
    </b-table>

    <b-pagination v-if="pendingSessions.data.length > 0" v-model="currentPage" :total-rows="pendingSessions.total" :per-page="perPage" align="right" class="pt-4" size="sm"></b-pagination>

    <b-modal id="view-details-modal" size="xl" title="Set Schedule" hide-footer body-class="p-0" scrollable>
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center">
          <div>
            <div class="font-weight-semibold">Booking schedule with Learner {{ selectedData.name }}</div>
          </div>
          <b-button type="button" class="close" size="sm" @click="close()">
            <span aria-hidden="true">&times;</span>
          </b-button>
        </div>

      </template>

      <div class="p-3 ">

        <b-row class="pb-4">
          <b-col lg="5" sm="12">
            <!-- Transaction ID -->
            <div class="font-size-sm d-flex align-items-center ">
              <div class="relative-div px-2">
                Transaction ID: <span class="ml-2 font-weight-semibold"> {{ selectedData.transaction_id }}
                </span>
                <b-button @click="onCopy(selectedData.transaction_id)" class="p-1 m-0 ml-2 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                  <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
                </b-button>
              </div>

            </div>
          </b-col>
          <b-col lg="7" sm="12">
            <!-- Request Date & Time -->
            <div class="font-size-sm">
              Request Date and Time:<span class="font-weight-semibold ml-2">{{ moment.utc(selectedData.created_at).tz(userData.timezone).format('dddd, LL') }}</span>
              <!-- Time --> <span class="ml-1 font-weight-semibold">{{ `${moment.utc(selectedData.created_at).tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</span>
            </div>
          </b-col>
        </b-row>

        <!-- Table Content -->
        <b-table class="default-table-css font-size-sm" :fields="modalFields" :items="selectedData.bookings" empty-text="No details found." show-empty responsive>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> please wait while data is loading...</strong>
            </div>
          </template>

          <template #cell(BookingID)="data">
            <div class="d-flex align-items-center relative-div mr-2">
              {{ data.item.booking_id }}
              <b-button @click="onCopy(data.item.booking_id)" class="p-1 m-0 ml-1 silver-bg copy-trans" style="line-height:0;" v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Copy">
                <font-awesome-icon class="font-size-xs grey" :icon="['far', 'clone']" />
              </b-button>
            </div>
          </template>

          <template #cell(LevelCategory)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.grade_level }}</div>
            </div>
          </template>

          <template #cell(SubjectLesson)="data">
            <div class="d-flex align-items-center">
              <div>{{ data.item.subject }}</div>
            </div>
          </template>

          <template #cell(Learner)="data">
            <div class="d-flex align-items-center">
              <b-avatar size="2em" class="mr-1" :src="data.item.learner_image"></b-avatar>
              <div>{{ data.item.name }}</div>
            </div>
          </template>

          <template #cell(Date)="data">
            <div class="d-flex align-items-center">
              <div>{{ moment.utc(data.item.start_date).tz(userData.timezone).format('dddd, LL') }}</div>
            </div>
          </template>

          <template #cell(Time)="data">
            <div class="d-flex align-items-center">
              <div>{{ `${moment.tz(data.item.start_date, 'UTC').tz(userData.timezone).format('hh:mm A')} (${userData.region_timezone})` }}</div>
            </div>
          </template>

        </b-table>

      </div>

      <!-- Custom Modal Footer -->
      <div class="light-white-bg w-100 p-3">
        <div class="font-weight-semibold pb-2">Notes:</div>
        <p class="font-weight-normal font-size-sm pb-4">{{ selectedData.note }}</p>

        <div class="d-flex align-items-center justify-content-between pb-3">
          <!-- Download Attachment -->
          <div>
            <b-button class="table-download-btn" size="sm" @click="onDownload({ file: selectedData.download_file,
                                   role: selectedData.account_type,
                                user_id: selectedData.user_id }, selectedData.original_file)">
              <font-awesome-icon class=" mr-1" :icon="['fas', 'download']" />Download Attachment
            </b-button>
          </div>
          <div>
            <b-button class="table-close-back-btn mr-2" @click="$bvModal.hide('view-details-modal')">Back</b-button>
            <b-button class="table-reject-btn mr-2 " size="sm" @click="onRejectRequest(selectedData.transaction_id)">
              <font-awesome-icon class=" mr-1" :icon="['fas', 'times']" />Reject
            </b-button>
            <b-button :disabled="isDisabledAcceptButton" class="table-accept-btn" size="sm" @click="onAcceptRequest(selectedData.transaction_id)">
              <b-spinner small v-if="isCurrentAcceptButtonDisabled(selectedData.transaction_id)"></b-spinner>
              <font-awesome-icon v-else class=" mr-1" :icon="['fas', 'check']" />Accept
            </b-button>
          </div>
        </div>

      </div>

    </b-modal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../mixins";
export default {
  props: ["isLoadTriggered"],
  name: "PendingTeacherSessions",
  data: () => ({
    isLoading: false,
    perPage: 10,
    currentPage: 1,
    noSessionsToday: "",
    fields: [
      { key: "LevelCategory", label: "Level/Category" },
      { key: "SubjectLesson", label: "Subject/Lesson" },
      { key: "Learner", sortable: true },
      "Payout",
      { key: "Expiry", sortable: true },
      "Action",
    ],
    selectedData: [],
    modalFields: [
      "BookingID",
      { key: "LevelCategory", label: "Level/Category" },
      { key: "SubjectLesson", label: "Subject/Lesson" },
      "Learner",
      "Date",
      "Time",
    ],
    items: [],
    isDisabledAcceptButton: false,
    currentSelectedButton: ''
  }),
  beforeMount() {
    if (this.isLoadTriggered) this.$emit("triggeredWatchSessions");
    this.loadSessions();
  },
  computed: {
    ...mapState(["userData", "pendingSessions"])
  },
  methods: {
    loadSessions() {
      const vm = this;
      vm.isLoading = true;
      vm.$store.state.pendingSessions.data = [];
      vm.$store
        .dispatch("getPendingSessions", {
          params: { count: vm.perPage, page: vm.currentPage, is_accepted: 0 },
          scope: "tutor-request-list",
        })
        .then((response) => {
          vm.noSessionsToday =
            response.data.data.data.length === 0 ? true : false;
          vm.items = response.data.data.data.map((item) => {
            return {
              LevelCategory: item.grade_level,
              SubjectLesson: item.subject,
              Learner: item.name,
              SessionType: item.session_type,
              Amount: item.total,
              Expiry: item.expiry,
              learner_image: item.learner_image,
              learner_age: item.learner_age,
              transaction_id: item.transaction_id,
              data: item,
            };
          });
          vm.fields.filter(x => x['sortable'] !== undefined).map(x => x.sortable = response.data.data.data.length > 1 ? true : false)
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    async onRejectRequest(transactionId) {
      this.showConfirmModal(
        "Are you sure you want to reject this booking?",
        () => {
          const formData = new FormData();
          formData.append("is_accepted", 0);
          formData.append("transaction_id", transactionId);
          const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/tutor-reject`;
          const options = {
            headers: {
              Accept: "application/json, text/plain, */*",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };
          this.axios.post(ApiURL, formData, options).then((data) => {
            if (data.status == 422)
              this.ShowModal(data.message, null, "Booking Rejection Error");
            else
              this.ShowModal(
                "You have rejected the selected booking!",
                null,
                "Booking Reject Request"
              );
            this.loadSessions();
            this.$bvModal.hide("view-details-modal");
          });
        }
      );
    },
    async onAcceptRequest(transactionId) {
      this.isDisabledAcceptButton = true
      this.currentSelectedButton = `accepted-button-${transactionId}`
      
      const formData = new FormData();
      formData.append("is_accepted", 0);
      formData.append("transaction_id", transactionId);
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/tutor-accept`;
      const options = {
        headers: {
          "Accept": "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        },
      };

      const response = await this.axios.post(ApiURL, formData, options)

      if ([200, 204].includes(response.data.status)) {
        let message = '<div>Thank you for accepting the request. You will receive an email and SMS notification once the sessions are paid.</div>'
        message += '<br /><div>You can still review this request under the Accepted Requests tab.</div>'
        this.ShowModal(message, () => { this.loadSessions() }, "Booking Request")
      } else {
        this.ShowModal(response.data.message, null, "Booking Accept Request Error");
      }

      this.isDisabledAcceptButton = false
      this.currentSelectedButton = ''
      this.$bvModal.hide("view-details-modal");
    },
    onDownload(params, original_file) {
      if (params.file == null) {
        this.ShowModal("No file to download.");
        return;
      }
      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/booking/download-file`,
        formData = new FormData();
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      this.onDownloadFile(ApiURL, formData, original_file);
    },
    onCopy(text) {
      this.$emit('copyToClipboard', text)
    },
    isCurrentAcceptButtonDisabled(transactionId) {
      return this.currentSelectedButton === `accepted-button-${transactionId}`
    }
  },
  watch: {
    currentPage() {
      this.loadSessions();
    },
    isLoadTriggered(val) {
      if (val) this.loadSessions();
      this.$emit("triggeredWatchSessions");
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
* >>> .modal-footer > .btn-primary.btn-sm {
  padding: .29rem 0.7rem;
}
</style>
<template>
  <div>

    <div class="d-lg-flex  align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold ">
        {{selectedLearnersName}} Scribook
      </div>
    </div>

    <!-- Scribook Children -->
    <div class="text-cb-color" style="text-align: left;margin-bottom: 10px;" v-if="isLoadingChildren">
      <b-spinner class="align-middle" small></b-spinner>
      <strong><small> loading children...</small></strong>
    </div>
    <div class="children-avatar-container" v-if="userData.role === 'parent' && !isLoadingChildren">
      <div class="child-card mt-lg-0" :class="{ 'active': selectedChild === child.id }" v-for="(child, index) in children" :key="index" @click="onChildSelected(child)">
        <b-avatar size="4em" class="mb-2" :src="child.filepath"></b-avatar>
        <div class="child-card-name">{{`${child.firstname}`}}</div>
      </div>
    </div>

    <b-row>
      <b-col md="12" lg="12">
        <b-card no-body class="p-4  mb-4" style="border:none; box-shadow: 0px 0px 14px 0px rgba(233, 233, 233, 0.5); min-height: 560px;">
          <!-- Search & Filter -->
          <b-row class="scribook-search-filter" align-v="center">
            <b-col md="12" lg="12" xl="6" class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <!-- Search Input -->
                <b-input-group class="w-100">
                  <b-form-input type="search" ref="searchScribookSubject" v-model="searchScribookSubject" :placeholder="searchPlaceHolder" :disabled="isLoadingScribook" v-on:keyup.enter="onSearchEnter"></b-form-input>
                  <b-input-group-append is-text @click="onSearchEnter" style="cursor: pointer;" :disabled="isLoadingScribook">
                    <b-icon icon="search"></b-icon>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>
            <b-col md="12" lg="12" xl="6" class="mb-4">
              <!-- Date Added & List/Grid View -->
              <div class="d-flex flex-lg-row flex-column align-items-lg-center justify-content-between w-100">
                <!-- Date Added -->
                <div class="d-flex align-items-center">
                  <div class=" font-weight-normal dark-silver pr-2" for="date-added" style="white-space: nowrap;">Session Date:</div>

                  <b-dropdown v-model="dateFilter" id="dropdown-1" :text="selectedDateFilterText" class="m-md-2 dropdownContainer" :disabled="isLoadingScribook">
                    <b-dropdown-item @click="onDateFilterChange('Today')">Today</b-dropdown-item>
                    <b-dropdown-item @click="onDateFilterChange('Yesterday')">Yesterday</b-dropdown-item>
                    <b-dropdown-item @click="onDateFilterChange('Last 7 days')">Last 7 days</b-dropdown-item>
                    <b-dropdown-item @click="onDateFilterChange('Last 30 days')">Last 30 days</b-dropdown-item>
                    <b-dropdown-item @click="onDateFilterChange('Custom Date')" v-b-modal.customDateDropdown> Custom Date</b-dropdown-item>
                    <b-modal id="customDateDropdown" title="Search scribook entry within a date range" centered no-close-on-backdrop>
                      <template #modal-header="{ close }">
                        <div class="font-weight-bold">Seach Scribook entry within a date range</div>
                        <button type="button" aria-label="Close" class="close" @click="close()">×</button>
                      </template>
                      <div class="p-2">
                        <div class="d-flex align-items-center ">
                          <div class="w-100 mr-1">
                            <label for="datepicker-dateformat1" class="datepickerLabel font-weight-medium">Start Date</label>
                            <b-form-datepicker v-model="dateFilterStartDate" id="datepicker-dateformat1" class="datepickerC" :max="getDateFilterMaxStartDate" placeholder="Choose a date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en"></b-form-datepicker>
                          </div>
                          <div class="pt-2 mt-4"> - </div>
                          <div class="w-100 ml-1">
                            <label for="datepicker-dateformat2" class="datepickerLabel font-weight-medium">End Date</label>
                            <b-form-datepicker v-model="dateFilterEndDate" id="datepicker-dateformat2" class="datepickerC" :min="getDateFilterMinStartDate" placeholder="Choose a date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en"></b-form-datepicker>
                          </div>
                        </div>
                      </div>

                      <template #modal-footer="{ cancel }">
                        <b-button class="datepickerBtn cancel" @click="cancel()">
                          Cancel
                        </b-button>
                        <b-button class="datepickerBtn apply" @click="onApplyDateFilterCustomRange">
                          Apply
                        </b-button>
                      </template>

                    </b-modal>
                  </b-dropdown>
                </div>
                <!-- & List/Grid View buttons -->
                <div class="d-flex mt-lg-0 mt-4">
                  <b-button class="grid-list-view-btn mr-2" @click="updateView('Grid', subjectTabIndex)">
                    <font-awesome-icon class="" :icon="['fas', 'th-large']" />
                  </b-button>
                  <b-button class="grid-list-view-btn mr-2" @click="updateView('List', subjectTabIndex)">
                    <font-awesome-icon class="" :icon="['fas', 'th-list']" />
                  </b-button>
                  <b-button class="file-storage-btn mr-2" :disabled="isLoadingScribook" @click="onShowFileStorage">
                    <font-awesome-icon class="mr-2" :icon="['fas', 'cloud']" />
                    File Storage
                  </b-button>
                </div>

              </div>

            </b-col>

          </b-row>
        
          <b-tabs v-model="subjectTabIndex" class="custom-tab-container scribook-subject-tabs position-relative">
              <b-button class="buttonTabs" id="prevTab" >
                   <font-awesome-icon class="" :icon="['fas', 'chevron-left']" />        
          </b-button>
          <b-button class="buttonTabs" id="nextTab" >
                        <font-awesome-icon class="" :icon="['fas', 'chevron-right']" />        
          </b-button>
            <b-row v-if="subjectListCards.length === 0 && !isLoadingScribook"><b-col>No record found.</b-col></b-row>
            <div class="text-cb-color" style="text-align: left" v-if="isLoadingScribook">
              <b-spinner class="align-middle" small></b-spinner>
              <strong><small> loading scribook...</small></strong>
            </div>
            <ScribookSubjectTab :data="{ subjects: subjectList, subjectCards: subjectListCards }" v-if="setDisplayView" :displayView="displayView" @emitSelectedSubjectTab="onRenderSubjectTabContent" @selectedItem="onSelectedItem" @showFileStorageSidebar="onShowFileStorage"/>
          </b-tabs>

          <b-pagination v-if="!isLoadingScribook && subjectListCards.length > 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" :align="`left`" class="pt-4" size="sm"></b-pagination>

        </b-card>
      </b-col>
    </b-row>
    
    <ScribookSidebarQuestionnaire :data="selectedScribook" :scribookData="scribookData" :isViewScribook="isViewScribook" v-if="isShowQuestionnaireModal" @onModalClose="onModalClose" @reloadScribook="onReloadScribook" @updateScribookDataEntryType="updateScribookDataEntryType"/>
    <ScribookSidebarFileStorage :subjects="subjectList" :data="selectedScribook" :dateFilterParams="dateFilterParams" v-if="isShowFileStorage" @onFileStorageClose="onFileStorageClose" :scribookData="scribookData" :isViewScribook="isViewScribook"/>
    <ScribookCreationModal />

  </div>
</template>

<script>
import ScribookSubjectTab from "../scribook/ScribookSubjectTab.vue";
import ScribookSidebarQuestionnaire from "../scribook/ScribookSidebarQuestionnaire.vue";
import ScribookSidebarFileStorage from "../scribook/ScribookSidebarFileStorage.vue";
import ScribookCreationModal from "../scribook/ScribookCreationModal.vue";
import mixins from "../../mixins";
import { mapState } from "vuex";

export default {
  name: "Scribook",
  props: {
    isViewScribook: Boolean,
    scribookData: Object,
    data: Object,
  },
  components: {
    ScribookSubjectTab,
    ScribookSidebarQuestionnaire,
    ScribookSidebarFileStorage,
    ScribookCreationModal,
  },
  data: () => ({
    displayView: "Grid",
    setDisplayView: true,
    subjectTabIndex: 0,
    selectedTabIndex: 0,
    subjects: [],
    isLoadingScribook: false,
    selectedScribookItem: 0,
    isShowQuestionnaireModal: false,
    searchScribookSubject: "",
    children: [],
    isLoadingChildren: false,
    selectedChild: "",
    clonedSubjects: [],
    isShowFileStorage: false,
    selectedLearnersName: '',
    searchPlaceHolder: 'Search Subject',
    dateFilter: '',
    selectedDateFilterText: '',
    dateFilterStartDate: '',
    dateFilterEndDate: '',
    dateFilterParams: {},
    subjectList: [],
    subjectListCards: [],
    currentPage: 1,
    totalRows: 0,
    perPage: 9,
    isEnableSearch: false,
    selectedSubjectTab: ''
  }),
  computed: {
    ...mapState(["userData"]),
    selectedScribook() {
      return this.subjectListCards[this.selectedScribookItem]
    },
    getDateFilterMinStartDate() {
      return this.dateFilterStartDate !== '' ? this.moment(this.dateFilterStartDate).tz(this.userData.timezone).format() : ''
    },
    getDateFilterMaxStartDate() {
      return this.dateFilterEndDate !== '' ? this.moment(this.dateFilterEndDate).tz(this.userData.timezone).format() : ''
    }
  },
  mounted() {
    this.scribookInit();
    this.getChildren();
    this.setScribookLearnersName();
    const nextTab = document.getElementById('nextTab');
    const prevTab = document.getElementById('prevTab');
    let tabsUl = document.querySelector('.scribook-subject-tabs ul')

    // Check if subject tabs element is scrollable
    let scrollable = tabsUl.scrollWidth > tabsUl.clientWidth;
    if(scrollable) {
      nextTab.style.display="block"
      prevTab.style.display="block"
      tabsUl.style.margin ="0 20px"
    } else {
      nextTab.style.display="none"
      prevTab.style.display="none"
      tabsUl.style.margin ="0"
    }


    nextTab.onclick = () => {
      tabsUl.scrollLeft += 200;
    };
    prevTab.onclick = () => {
      tabsUl.scrollLeft -= 200;
    };
    
  },
  methods: {
    async scribookInit() {
      const currentTabIndex = this.subjectTabIndex
      this.subjectList = []
      this.subjectListCards = []
      this.getScribookSubjectList();
      this.$nextTick(() => {
        this.$refs.searchScribookSubject?.$el.focus();
        this.subjectTabIndex = currentTabIndex;
      });
    },
    async getScribookSubjectList() {
      this.isLoadingScribook = true
      const subject = this.isEnableSearch ? this.searchScribookSubject : ''
      const scribookParams = this.getScribookParams(subject, this.isViewScribook, true)
      const response = await this.$store.dispatch('getScribook', scribookParams)
      this.renderSubjectList(response.data.data?.subject_list)
      this.isLoadingScribook = false
      this.onRenderSubjectTabContent('All')
    },
    updateView(param, selectedTabIndex) {
      this.displayView = param;
      this.setDisplayView = false;
      this.$nextTick(() => {
        this.setDisplayView = true;
        this.$nextTick(() => {
          this.subjectTabIndex = selectedTabIndex;
        });
      });
    },
    filterObjByType(obj) {
      return this.isViewScribook ? obj.filter((x) => x.scribook_type === "PUBLIC") : obj;
    },
    getScribookParams(subject, isViewScribook, isGetAll = false) {
      const params = {
        subject: subject
      }
      this.mergeObject(params, this.setDateFilter())

      const parentUserChildID = this.userData.role === "parent" ? this.selectedChild : ""
      const viewParentUserChildID = this.scribookData && this.scribookData.users.role === "parent" ? this.scribookData.learner.child_id : ""

      params.child_id = parentUserChildID || viewParentUserChildID
      params.view_user_id = isViewScribook && this.scribookData ? this.scribookData.users.id : ""

      if (!isGetAll) {
        params.count = this.perPage
        params.page = this.currentPage
      }
      this.dateFilterParams = params
      return params
    },
    setDateFilter() {
      let result = {}, DateToday = this.moment.tz(this.userData.timezone).format('YYYY-MM-DD')
      switch (this.selectedDateFilterText) {
        case 'Today':
          result = {
            date_from: this.moment(`${DateToday} 00:00:00`).utc(),
            date_to: this.moment(`${DateToday} 23:59:59`).utc()
          }
          break
        case 'Yesterday':
          result = {
            date_from: this.moment(`${DateToday} 00:00:00`).subtract(1, 'days').utc(),
            date_to: this.moment(`${DateToday} 23:59:59`).subtract(1, 'days').utc()
          }
          break
        case 'Last 7 days':
          result = {
            date_from: this.moment(`${DateToday} 00:00:00`).subtract(7, 'days').utc(),
            date_to: this.moment(`${DateToday} 23:59:59`).utc()
          }
          break
        case 'Last 30 days':
          result = {
            date_from: this.moment(`${DateToday} 00:00:00`).subtract(30, 'days').utc(),
            date_to: this.moment(`${DateToday} 23:59:59`).utc()
          }
          break
        case 'Custom Date':
          result = {
            date_from: this.moment(`${this.dateFilterStartDate} 00:00:00`).tz(this.userData.timezone).utc(),
            date_to: this.moment(`${this.dateFilterEndDate} 23:59:59`).tz(this.userData.timezone).utc()
          }
          break
      }
      Object.keys(result).forEach(function(key) { result[key] = result[key].format('YYYY-MM-DD HH:mm:ss') })
      return result
    },
    onSelectedItem(payload) {
      this.selectedScribookItem = payload.index;
      if (this.scribookData) {
        this.scribookData.scribook.scribook_id = payload.item.scribook_id
        this.scribookData.scribook.scribook_allow_edit = payload.item.scribook_allow_edit
        this.scribookData.scribook.scribook_status = payload.item.scribook_status
      }
      this.isShowQuestionnaireModal = true;
    },
    onModalClose() {
      this.isShowQuestionnaireModal = false;
    },
    getChildren() {
      if (this.userData.role !== "parent") return;
      this.isLoadingChildren = true;
      const childApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/user/child-list`;
      const childOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      this.axios
        .get(childApiURL, childOptions)
        .then((response) => {
          this.children = response.data.data.data;
        })
        .finally(() => {
          this.isLoadingChildren = false;
        });
    },
    onChildSelected(data) {
      this.selectedChild = data.id;
      this.onReloadScribook();
      this.selectedLearnersName = `${data.firstname}'s`
    },
    onShowFileStorage() {
      this.isShowFileStorage = true;
    },
    onFileStorageClose() {
      this.isShowFileStorage = false;
    },
    onReloadScribook() {
      this.scribookInit()
    },
    setScribookLearnersName() {
      if (this.isViewScribook && this.scribookData.users.role === 'student') {
        this.selectedLearnersName = `${this.scribookData.learner.firstname}'s`
      } else {
        const userData = this.$watch('userData', () => {
          if (this.userData.role === 'student') {
            this.selectedLearnersName = `${this.userData.firstname}'s`
          }
          userData()
        })
      }
    },
    onSearchEnter () {
      this.isEnableSearch = true;
      this.scribookInit();
      this.selectedChild = "";
    },
    onDateFilterChange(val) {
      this.selectedDateFilterText = val
      if (val === 'Custom Date') return
      this.onReloadScribook()
    },
    onApplyDateFilterCustomRange() {
      if (this.dateFilterStartDate === '' || this.dateFilterEndDate === '') {
        this.ShowModal('Please specify start date and end date')
        return
      }
      this.onReloadScribook()
      this.$bvModal.hide('customDateDropdown')
    },
    async onRenderSubjectTabContent(subject, isCurrentSelection = false) {
      this.selectedSubjectTab = subject
      this.currentPage = !isCurrentSelection ? 1 : this.currentPage
      this.isLoadingScribook = true
      this.subjectListCards = []
      const enableSearchCondition = this.isEnableSearch ? this.searchScribookSubject : ''
      subject = subject === 'All' ? enableSearchCondition : subject
      const scribookParams = this.getScribookParams(subject, this.isViewScribook)
      const response = await this.$store.dispatch('getScribook', scribookParams)
      const _data = response.data.data[0]
      this.mapResponseData(this.filterObjByType(_data.data))
      this.totalRows = _data.total
      this.isLoadingScribook = false
    },
    renderSubjectList(obj) {
      const subjects = []
      subjects.push('All')
      Object.values(obj).forEach(x => subjects.push(x))
      this.subjectList = subjects
    },
    mapResponseData(response) {
      const obj = response.map(item => {
        return {
          scribook_id: item.scribook_id,
          title: item.subject,
          learner: {
            name: item.learner_name,
            firstname: item.learner_firstname,
            lastname: item.learner_lastname,
            image: item.learner_image
          },
          tutor: {
            id: item.tutor_id,
            name: item.teacher_name,
            firstname: item.tutor_firstname,
            lastname: item.tutor_lastname,
            image: item.teacher_image
          },
          parent: {
            child_id: item.child_id,
          },
          bookings: {
            booking_id: item.booking_id,
            note: item.note,
            account_type: item.account_type,
            grade_level: item.grade_level,
            subject: item.subject,
            child_id: item.child_id,
            start_date: item.start_date
          },
          createdAt: item.created_at,
          topic: item.topic,
          entryType: item.scribook_type,
          subjectCardPills: [
            { title: item.subject, value: "", isOpGrade: false },
            {
              title: item.assessment_title,
              value: item.overall_assessment,
              isOpGrade: true,
            },
          ],
          questionnaire: item.questionnaire.map((q) => {
            return {
              answer: q.answer,
              question: q.question,
            };
          }),
          attachments: item.attachments,
          overall_assessment: item.overall_assessment,
          overall_assessment_title: item.assessment_title,
          image_file_name: item.image_file_name,
          image_server_name: `${process.env.VUE_APP_BACKEND_URL}/${item.image_server_name}`,
          scribook_allow_create: item.scribook_allow_create,
          scribook_allow_edit: item.scribook_allow_edit,
          scribook_status: item.scribook_status
        }
      })
      this.subjectListCards = obj
    },
    updateScribookDataEntryType(entryType) {
      this.subjectListCards[this.selectedScribookItem].entryType = entryType
    }
  },
  mixins: [mixins],
  watch: {
    currentPage: {
      handler: function () {
        this.onRenderSubjectTabContent(this.selectedSubjectTab, true)
      }
    }
  }
};
</script>

<style>
#customDateDropdown .b-calendar .b-calendar-grid-body .col[data-date] .btn {
  margin: unset !important;
}
</style>

<style >
.dropdownContainer .btn,.dropdownContainer .btn:active, .dropdownContainer .btn:focus  {
  background: var(--light-silver) !important;
  border: 0.5px solid var(--light-silver) !important;
  color: var(--grey) !important;
  border-radius: 15em;
  min-width: 150px;
  min-height: 2.3em;
  text-align: left;
  padding: 0.375rem 2.1rem 0.375rem 0.95rem;
}
.dropdownContainer .btn:hover,  .dropdownContainer .btn:focus, .dropdownContainer.show > .btn-secondary.dropdown-toggle {
  box-shadow: 0 0 0 0.05em var(--light-blue);
}

.dropdownContainer .dropdown-toggle::after{
  position: absolute;
  top: 15px;
  right: 15px;
}

.dropdownContainer .dropdown-item.active, .dropdownContainer .dropdown-item:active {
  background-color: #f8f9fa;
  color: var(--grey);
}

.dropdownContainer .dropdown-menu .dropdown-item {
  font-size: calc(var(--fs-one) - 2px);
}

.datepickerLabel {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--grey);
}

.b-form-datepicker.datepickerC {
  border: 0.8px solid var(--dark-silver);
  color: var(--grey);
  border-radius: 15em;
  padding: 0 1em;
}
.b-form-datepicker.datepickerC:focus,
.b-form-datepicker.datepickerC.form-control.focus {
  box-shadow: none;
  border-color: var(--dark-silver);
}

.datepickerC label {
  padding: unset;
  padding-top: 0.5em;
  font-weight: 500;
  color: var(--dark-grey);
}

.datepickerC .btn.h-auto,
.datepickerC .btn.h-auto:hover {
  color: #16bdda;
}

.datepickerBtn.btn.btn-secondary {
  border-radius: 15em;
}

.datepickerBtn.apply.btn,
.datepickerBtn.apply.btn:focus,
.datepickerBtn.apply.btn:active,
.datepickerBtn.apply.btn-secondary:not(:disabled):not(.disabled):active {
  background: var(--light-blue);
  color: var(--white);
  padding-left: 1.6em;
  padding-right: 1.6em;
}
.datepickerBtn.apply.btn:hover {
  background: var(--hover-light-blue);
}
.datepickerBtn.cancel.btn,
.datepickerBtn.cancel.btn:focus,
.datepickerBtn.cancel.btn:active {
  background: none !important;
  color: var(--grey) !important;
}
</style>


<style scoped>
.buttonTabs,.buttonTabs:hover, .buttonTabs:active, .buttonTabs:focus {
  position: absolute;
  top: 0;
  color: var(--dark-silver) !important;
  padding: 0.175rem 0.75rem;
}
#nextTab.buttonTabs {
  right: 0;
  padding-right: 0;
  background: linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.503428397726278) 70%, rgba(0,0,0,0) 100%) !important;
}
#prevTab.buttonTabs {
  left: 0;
  padding-left: 0;
  background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.503428397726278) 70%, rgba(0,0,0,0) 100%)  !important;
}
</style>
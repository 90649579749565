import axios from "axios";

const currencyLocalizationModule = {
  namespaced: true,

  state: () => ({
    countryCode: "PH",
    currencyCode: null,
    symbol: null,
    rateFrom: null,
    rateTo: null
  }),

  getters: {
    isUserFromPh: state => state.countryCode == "PH",
    showConvertedAmount: (state, getters, rootState) => {
      const role = rootState.userData.role,
        isUserOverseas = !getters.isUserFromPh;
      if (role) {
        const isUserAParentOrLearner = role == "parent" || role == "student";
        return isUserOverseas && isUserAParentOrLearner;
      } else {
        return isUserOverseas;
      }
    }
  },

  mutations: {
    setState(state, { countryCode, currencyCode, symbol, rate_from, rate_to }) {
      state.countryCode = countryCode || "PH";
      state.currencyCode = currencyCode;
      state.symbol = symbol;
      state.rateFrom = rate_from;
      state.rateTo = rate_to;
    }
  },

  actions: {
    getIpInfo({ commit }) {
      return axios
        .get(
          process.env.VUE_APP_BACKEND_URL + "/api/currencylocalization/ipinfo",
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("userToken")
            }
          }
        )
        .then(response => {
          if (response.status == 200) {
            commit("setState", response.data);
          }
          return response;
        })
        .catch(error => {
          throw error;
        });
    }
  }
};

export default {
  install(Vue, options) {
    options.store.registerModule(
      "currencyLocalization",
      currencyLocalizationModule
    );

    Vue.mixin({
      computed: {
        isUserFromPh() {
          return this.$store.getters["currencyLocalization/isUserFromPh"];
        },

        showConvertedAmount() {
          return this.$store.getters[
            "currencyLocalization/showConvertedAmount"
          ];
        },

        currencyLocalization() {
          return this.$store.state.currencyLocalization;
        }
      },

      methods: {
        convertAmount(
          amount,
          settings = {
            parts: false,
            ceil: false,
            floor: false,
            fDigits: 2,
            spaceAfterSign: true
          }
        ) {
          amount = amount || 0;

          const { currencyCode, symbol, rateFrom } = this.currencyLocalization,
            scale = settings.fDigits == null ? 2 : settings.fDigits,
            spaceAfterSign =
              settings.spaceAfterSign === null ? true : settings.spaceAfterSign;

          let result = amount * rateFrom;

          if (settings.ceil) {
            result = Math.ceil(result);
          }

          if (settings.floor) {
            result = Math.floor(result);
          }

          if (settings.parts) {
            const splitResult = result.toFixed(scale).split(".");

            return {
              symbol: symbol,
              whole: splitResult[0],
              decimal: splitResult[1],
              code: currencyCode || "PHP"
            };
          } else {
            let fResult = new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: currencyCode || "PHP",
              minimumFractionDigits: scale,
              maximumFractionDigits: scale
            }).format(result);

            if (spaceAfterSign) {
              fResult = fResult.replace(/^(\D+)/, "$1 ").replace(/\s+/, " ");
            }

            return {
              result: fResult,
              rawResult: result.toFixed(scale),
              code: currencyCode || "PHP",
              symbol: symbol
            };
          }
        }
      }
    });
  }
};

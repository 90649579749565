<template>
  <div>
    <b-container class="my-5">
      <b-row align-h="center">
        <b-col sm="12" lg="7">
          <div class="px-4 py-5 shadow" style="white-space: normal;">
            <div class="text-center ">
              <!-- SUCCESSFUL PAYMENT -->
              <div v-if="tranStatus == 'S'">
                <b-avatar
                  class="light-blue-bg"
                  icon="check"
                  size="5em"
                ></b-avatar>
                <div class="light-blue font-weight-bold font-size-lg py-2">
                  Payment Successful
                </div>
                <div class="text-center font-size-sm pb-2 mb-4">
                  This is to confirm that your payment has been completed.
                </div>
                <b-button
                  pill
                  variant="secondary"
                  class="px-3 py-2"
                  size="sm"
                  @click.stop="redirectToSchedule()"
                >
                  Go To My Dashboard
                </b-button>
                <div
                  class="mt-4 text-secondary d-flex align-items-center justify-content-center"
                >
                  <b-spinner small></b-spinner>
                  <small class="ml-3">
                    Redirecting to your dashboard in 5 seconds...
                  </small>
                </div>
              </div>

              <!-- WAITING FOR PAYMENT -->
              <div v-else-if="tranStatus == 'P'">
                <b-avatar
                  class="orange-bg"
                  icon="hourglass-split"
                  size="5em"
                ></b-avatar>
                <div class=" font-weight-bold font-size-lg py-2">
                  Waiting For Payment
                </div>
                <div class="text-center font-size-sm pb-2">
                  Please wait while your payment is being processed.
                </div>
              </div>

              <!-- UNSUCCESSFUL PAYMENT -->
              <div v-else-if="tranStatus != 'S'">
                <b-avatar
                  class="red-bg"
                  icon="exclamation"
                  size="5em"
                ></b-avatar>
                <div class="red font-weight-bold font-size-lg py-2">
                  Payment Unsuccessful
                </div>
                <div class="text-center font-size-sm pb-2">
                  There was a problem processing your request. Please contact
                  <a class="dark-grey" href="mailto:support@chalkboard.com.ph">
                    <u>support@chalkboard.com.ph.</u>
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-4 mb-2 ">
              <dl class="row">
                <dt class="col-sm-4 ">
                  <div class="font-weight-normal grey">Reference No:</div>
                </dt>
                <dd class="col-sm-8 font-weight-bold">{{ this.refNo }}</dd>

                <dt class="col-sm-4 ">
                  <div class="font-weight-normal grey">Transaction No:</div>
                </dt>
                <dd class="col-sm-8 font-weight-bold">{{ this.transNo }}</dd>
              </dl>
            </div>

            <div>
              <dl class="row">
                <dt class="col-sm-4">
                  <div class="font-weight-normal grey">System Message:</div>
                </dt>
                <dd
                  class="col-sm-8 font-weight-bold"
                  style="word-break: break-word;"
                >
                  {{ this.systemMsg }}
                </dd>
              </dl>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import mixins from "../../mixins";

export default {
  name: "CollectionBookingPaymentConfirmation",
  data: () => ({
    transMsg: "",
    refNo: "",
    transNo: "",
    systemMsg: "",
    note: "",
    tranStatus: "",
    redirectingToDashboard: false
  }),
  computed: {},
  beforeMount() {
    const paramData = new FormData();

    if (
      this.$route.query.refno == undefined ||
      this.$route.query.refno == "-"
    ) {
      this.transMsg =
        this.$route.query.status != null ? this.$route.query.status : "-";
      this.tranStatus =
        this.$route.query.status != null ? this.$route.query.status : "-";
      this.refNo =
        this.$route.query.txnid != null ? this.$route.query.txnid : "-";
      this.transNo =
        this.$route.query.txnid != null ? this.$route.query.txnid : "-";
      this.systemMsg =
        this.$route.query.status != null ? this.$route.query.message : "-";
      this.note =
        this.$route.query.message != null ? this.$route.query.message : "-";
    } else {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/booking/return-url";

      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`
        },
        mode: "cors"
      };

      this.tranStatus = this.$route.query.status;
      this.refNo = this.$route.query.refno;
      this.transNo = this.$route.query.txnid;

      paramData.append("digest", this.$route.query.digest);
      paramData.append("txnid", this.transNo);
      paramData.append("status", this.tranStatus);
      paramData.append("refno", this.refNo);
      paramData.append("message", "message=" + this.$route.query.message);

      this.axios
        .post(ApiURL, paramData, options)
        .then((data) => {
          const _data = data.data.data;

          this.transMsg =
            _data.transaction_message != null
              ? _data.transaction_message
              : "-`";
          this.refNo = _data.refno != null ? _data.refno : "-";
          this.transNo = _data.txnid != null ? _data.txnid : "-";
          this.systemMsg = _data.message != null ? _data.message : "-";
          this.note = _data.note != null ? _data.note : "-";
        })
        .catch((err) => console.error(err));
    }
  },
  mounted() {
    document.getElementById("navbar-fp-main").style.display = "none";
    document.querySelector("footer").style.display = "none";
    if (this.tranStatus == "S") {
      this.redirectingToDashboard = true;
      setTimeout(this.redirectToSchedule, 5000);
    }
  },
  methods: {
    redirectToSchedule() {
      window.location.href = "/schedule/sessions";
    }
  },
  mixins: mixins
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

<template>
  <div>
    <Scribook />
  </div>
</template>

<script>
import Scribook from "../../../components/ScribookTemplate.vue";
import mixins from "../../../mixins";

export default {
  components: { Scribook },
  name: "ScribookLearner",
  data: () => ({}),
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  mixins: [mixins],
};
</script>

<style scoped>
</style>
<template>
  <b-alert :show="!isCookieAccepted" dismissible fade class="cookie-container bg-white position-fixed dark-grey shadow">
    <div class="d-flex align-items-center flex-column-sm flex">
      <!-- Cookie Image -->
      <div class="text-center p-2">
        <img width="60" height="70" class="cookie-image" src="../assets/global/cookie.svg" alt="" />
        <div class="mt-2 cookie-shadow"></div>
      </div>
      <!-- Cookie Content -->
      <div class="p-2">
        <div class="font-weight-semibold pb-2 font-size-sm">I'm a Cookie :)</div>
        <p class="font-size-xs">This site uses cookies to personalize and enhance your experience on our website.
          <br> By clicking "accept", you agree to our use of cookies.
        </p>
        <!-- Buttons -->
        <div class="d-inline-block">
          <b-button class="bg-dark text-center text-white rounded font-size-xs" @click="onCookieAccepted">Accept</b-button>
          <a href="https://chalkboard.com.ph/pages/cookie-policy/" class="p-2 dark-grey font-size-xs text-underline"><u>Read More</u></a>

        </div>
      </div>

    </div>
  </b-alert>
</template>

<script>
  export default {
    data: () => ({
      props: ['DashboardRoutes'],
      consentPropertyName: 'cb_cookie_consent',
      isCookieAccepted: true,
    }),
    mounted() {
      this.displayConsentPopup()
      this.$watch('$route', () => {
        if (!this.isDashboardRoute) this.displayConsentPopup()
      })
    },
    computed: {
      isDashboardRoute() {
        return (this.DashboardRoutes) ? this.DashboardRoutes.filter(e => e === this.$route.name).length > 0 ? true : false : false
      }
    },
    methods: {
      displayConsentPopup() {
        const vm = this
        vm.isCookieAccepted = (!localStorage.getItem(vm.consentPropertyName)) ? false : true
      },
      onCookieAccepted() {
        const vm = this
        localStorage.setItem(vm.consentPropertyName, true)
        vm.isCookieAccepted = true
        vm.metaPixelInit();
      }
    }
  }
</script>

<style scoped>
.cookie-container {
  bottom: 2%;
  left: 1rem;
  margin-right: 1rem;
  max-width: 500px;
  border: none;
  z-index: 250;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1126%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(255%2c 189%2c 89%2c 0.05)'%3e%3c/rect%3e%3cpath d='M 0%2c150 C 36%2c124 108%2c27 180%2c20 C 252%2c13 288%2c103.2 360%2c115 C 432%2c126.8 468%2c79.4 540%2c79 C 612%2c78.6 648%2c125.4 720%2c113 C 792%2c100.6 828%2c5.8 900%2c17 C 972%2c28.2 1008%2c154.8 1080%2c169 C 1152%2c183.2 1188%2c95.4 1260%2c88 C 1332%2c80.6 1404%2c123.2 1440%2c132L1440 560L0 560z' fill='rgba(183%2c 136%2c 65%2c 0.05)'%3e%3c/path%3e%3cpath d='M 0%2c319 C 57.6%2c309.8 172.8%2c274 288%2c273 C 403.2%2c272 460.8%2c327.4 576%2c314 C 691.2%2c300.6 748.8%2c207 864%2c206 C 979.2%2c205 1036.8%2c299.8 1152%2c309 C 1267.2%2c318.2 1382.4%2c263.4 1440%2c252L1440 560L0 560z' fill='rgba(249%2c 174%2c 62%2c 0.05)'%3e%3c/path%3e%3cpath d='M 0%2c537 C 41.2%2c514.8 123.6%2c430.6 206%2c426 C 288.4%2c421.4 329.6%2c518.6 412%2c514 C 494.4%2c509.4 535.6%2c402.6 618%2c403 C 700.4%2c403.4 741.6%2c508.8 824%2c516 C 906.4%2c523.2 947.6%2c440.4 1030%2c439 C 1112.4%2c437.6 1154%2c515.4 1236%2c509 C 1318%2c502.6 1399.2%2c427.4 1440%2c407L1440 560L0 560z' fill='rgba(204%2c 143%2c 50%2c 0.05)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1126'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}
.cookie-image {
   animation: bouncing-cookie 2.5s infinite ease-in;
}
.cookie-shadow {
  background: var(--dark-silver);
  height: 10px;
  width: 60px;
  border-radius: 50%;
   animation: bouncing-cookie-shadow 2.5s infinite ease-in;
}
@keyframes bouncing-cookie {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20%) ;
    animation-timing-function: ease-out;
  }
  
}

@keyframes bouncing-cookie-shadow {
  0%, 100% {
     transform: scale(.8);
  }
  50% {
     transform: scale(1);
      animation-timing-function: ease-out;
  }
}
</style>
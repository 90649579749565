export default {
    data: () => ({
        UserToken: window.localStorage.getItem("userToken")
    }),    
    methods: {
        paySessionRequest(tran_id){
            //Redirects the  
            const paramData = new FormData()
            paramData.append('transaction_id', tran_id) 
  
            const ApiURL = process.env.VUE_APP_BACKEND_URL + '/api/web/booking/payment'
            const options = {
              headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${this.UserToken}`
              }
            }
  
            this.axios.post(ApiURL, paramData, options).then(data => {
                const _data = data.data
                
                if (_data.data == 'S') {
                    this.ShowModal("Payment for this booking is already COMPLETED. If you have any concerns, please contact support.", null, 'Payment Checkout Error');

                }
                else if (_data.status == 422) {
                    this.ShowModal(_data.message, null, 'Payment Checkout Error'); 
                }
                else{   
                    this.$router.push({ path: `/booking-payment/${tran_id}` });
                    
                }
 
            })
              
          
        },
    }
}
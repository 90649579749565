<!-- TODO - this component is currenlty unused -->
<template>
  <div class="user-vouchers" ref="userVouchers">
    <div class="user-vouchers__header">
      <h5>My Vouchers</h5>
      <b-button size="sm" @click="closeAppModal()"><xmark /></b-button>
    </div>

    <b-overlay :show="isLoading">
      <b-tabs
        nav-class="user-vouchers__tabs"
        content-class="p-4 light-silver-bg"
        justified
      >
        <b-tab title="Active Vouchers" active>
          <div
            v-if="vouchers.includes('MCDO23')"
            class="voucher-list__card"
            @click="showMcDoVoucherDetails"
          >
            <div class="voucher-list__image-wrapper">
              <div class="voucher-list__image">
                <b-img :src="require('@/assets/imgs/cb-voucher.png')"></b-img>
              </div>
            </div>
            <div class="voucher-list__content">
              <div class="voucher-list__top-content">
                <div class="voucher-list__header">Free 3 Sessions</div>
                <div class="voucher-list__description">
                  Applicable for math, reading or coding session only
                </div>
              </div>
              <div class="voucher-list__bottom-content">
                <div class="voucher-list__expiration">Expires in 5 days</div>
                <b-button
                  v-if="this.showAction"
                  class="voucher-list__button"
                  @click.stop="useVoucher('MCDO23')"
                >
                  Use voucher
                </b-button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Past Vouchers" disabled>
          <p>Past Vouchers</p>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
/**
 * TODO - build for modularity once functional
 */
// import UserVouchersList from "./UserVouchersList.vue";
import McDoVoucherDetails from "./McDoVoucherDetails.vue";
import xmark from "../../assets/imgs/x-mark.svg?inline";

export default {
  name: "UserVouchers",

  components: {
    // UserVouchersList
    xmark
  },

  props: {
    showAction: {
      type: Boolean,
      default: true
    }
  },

  created() {
    this.getUserVouchers();
  },

  data() {
    return {
      isLoading: true,
      vouchers: [],
      MCDO23: {}
    };
  },

  methods: {
    getUserVouchers() {
      this.isLoading = true;

      const headers = {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`
      };

      /**
       * This route was cleaned up as the ticket for this no longer requires
       * a user to be able to view their voucher
       */
      const url = process.env.VUE_APP_BACKEND_URL + "/api/voucher/claimed";

      /**
       * TODO
       * - this route was not meant for modularity
       * - corners were cut to make sure this was delivered on time
       * - delete everything related to CBW-1011 after the MCDO23 promo concludes
       * - or revamp for modularity
       */
      this.axios
        .get(url, { headers: headers })
        .then((response) => {
          this.vouchers = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showMcDoVoucherDetails() {
      this.openAppModal(
        McDoVoucherDetails,
        {
          "show-action": this.showAction
        },
        {
          id: "voucher-details-modal",
          maxWidth: "550px"
        },
        {
          "use-voucher": this.useVoucher
        }
      );
    },

    useVoucher(voucher) {
      this.$emit("use-voucher", voucher);
      this.closeAppModal();
    }
  }
};
</script>

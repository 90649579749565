<template>
  <div>
    <!-- breadcrumbs -->
    <div class="pb-4">
      <div class="text-uppercase font-weight-semibold ">
        View Messages
      </div>
    </div>

    <b-card no-body class="shadow mb-4" style="border:none;">
      <div class="d-flex">
        <!-- Chat User List -->
        <div v-if="allChatList" class="all-chat-list-wrapper">
          <!-- Chat Search -->
          <div class="mb-2">
            <b-form-input v-model="searchMessages" @keyup="onSearchMessages" autocomplete="off" class="all-chat-search-input" placeholder="Search messages.."></b-form-input>
            <b-form-select v-model="allChatSelect" class="all-chat-select" @change="onChatSelectChange">
              <b-form-select-option value="All">All Messages</b-form-select-option>
              <b-form-select-option value="Favorites">Favorites</b-form-select-option>
            </b-form-select>
          </div>
          <!-- Chat Users -->
          <div class="all-chat-messages-list-container">
            <div class="all-chat-message-no-chat-messages" v-if="items.length === 0">
              <font-awesome-icon class="grey" :icon="['far', 'comments']" />
              <div>No messages yet</div>
              <span>Don't worry, a notification will appear here when someone sends you a message</span>
            </div>
            <div class="text-cb-color" style="text-align: center" v-if="isLoadingSearchMessages">
              <b-spinner class="align-middle" small></b-spinner>
              <strong><small> loading messages...</small></strong>
            </div>
            <ul class="list-unstyled">
              <li class="all-chat-messages-list" v-for="(item, index) in items" :key="index" @click="onThreadClick(item)">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="chat-bubble-box-avatar">
                    <b-avatar size="2.7em" :src="item.data.image"></b-avatar>
                    <span class="chat-bubble-box-avatar-status" :class="{ online: (item.data.chat_status || 'offline') === 'online', offline: (item.data.chat_status || 'offline') === 'offline' }"></span>
                  </div>
                  <div class="ml-2">
                    <div>
                      <div class="all-chat-name" :class="{ 'chat-unread': item.is_read === false || false }">
                        {{ `${item.data.firstname} ${getFChar(item.data.lastname)}.` }}
                      </div>
                      <div class="all-chat-message" :class="{ 'chat-unread': item.is_read === false || false }">
                        {{ item.last_message }}
                        <!-- Tutor Message, Okay, Let's Discuss Later Tutor Message, Okay, Let's Discuss Later Tutor Message, Okay, Let's Discuss Later Tutor Message, Okay, Let's Discuss Later -->
                      </div>
                    </div>
                  </div>
                  <div class="ml-auto">
                    <div class="text-center">
                      <div class="chat-nav-time" :class="{ 'chat-unread': item.is_read === false || false }">{{ moment.utc(item.last_active_message).tz(userData.timezone).fromNow() }}</div>
                      <!-- Display only if message is unread -->
                      <span class="chat-dot-notif" :class="{ 'chat-unread': item.is_read === false || false }"></span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Start Chat Initial State -->

        <!-- <div class="all-chat-message-no-chat-messages">
          <font-awesome-icon class="grey" :icon="['far', 'comments']" />
          <div>No messages yet</div>
          <span>Don't worry, a notification will appear here when someone sends you a message</span>
        </div> -->

        <!-- End Chat Initial State -->

        <!-- Chat Box -->
        <div v-if="allChatBox" class="w-100 all-chat-box-wrappper">
          <div class="all-chat-box-container" style="width: 100%; height: 100%;">
            <div class="all-chat-box-header">
              <div class="d-flex align-items-center justify-content-between">

                <b-button class="all-chat-box-back-button p-0 mr-3 fs-24" v-if="selectedThread" @click="onBackButton">
                  <font-awesome-icon class="dark-silver " :icon="['fas', 'arrow-left']" />
                </b-button>

                <div class="all-chat-bubble-box-avatar" v-if="selectedThread">
                  <b-avatar size="2.5em" :src="selectedThread.data.image"></b-avatar>
                  <span class="all-chat-bubble-box-avatar-status" :class="{ online: (selectedThread.data.chat_status || 'offline') === 'online', offline: (selectedThread.data.chat_status || 'offline') === 'offline' }"></span>
                </div>

                <div class="ml-2" v-if="selectedThread">
                  <div v-b-tooltip.hover :title="`${ userData.role !== 'teacher' ? 'View Tutor Profile' : '' }`" @click="onViewProfile(selectedThread.data.user_id)">
                    <div class="mb-0 all-chat-bubble-box-tutor-name">{{ `${selectedThread.data.firstname} ${getFChar(selectedThread.data.lastname)}.` }}</div>
                    <div class="all-chat-bubble-box-status">{{ (selectedThread.data.chat_status || 'offline') === 'online' ? 'Online' : 'Offline' }}</div>
                  </div>
                </div>

                <div class="ml-auto" style="padding:15px;" v-if="!selectedThread"></div>
                <div class="ml-auto" v-if="selectedThread">
                  <span class="all-chat-bubble-box-control" :class="{ active: selectedThread.bookmarked }" v-b-tooltip.hover title="Mark as Favorite">
                    <font-awesome-icon class="" :icon="['fas', 'star']" @click="onMarkAsFavorites()" />
                  </span>
                  <span class="all-chat-bubble-box-control" :class="{ active: !selectedThread.is_read }" v-b-tooltip.hover :title="`Mark as ${selectedThread.is_read ? 'Unread' : 'Read'}`">
                    <font-awesome-icon class="" :icon="['fas', 'envelope-open-text']" @click="onMarkAsUnread()" />
                  </span>
                  <!-- <span class="all-chat-bubble-box-control" :class="{ active: selectedThread.is_muted }" v-b-tooltip.hover title="Mute Conversation">
                    <font-awesome-icon class="" :icon="['fas', 'bell']" @click="onMuteConversation()" />
                  </span> -->
                  <!-- <span class="all-chat-bubble-box-control cs disabled" v-b-tooltip.hover title="Add Customer Support">
                    <font-awesome-icon class="" :icon="['fas', 'user-plus']" />
                  </span> -->
                  <span class="all-chat-bubble-box-control" v-b-tooltip.hover title="Message Information" @click="ShowAllChatInfo">
                    <font-awesome-icon class="" :icon="['fas', 'info-circle']" />
                  </span>

                  <!-- Info on Mobile -->
                  <b-dropdown size="" variant="link" toggle-class="text-decoration-none" no-caret right class="mobile-control">
                    <template #button-content>
                      <font-awesome-icon class="dark-silver" :icon="['fas', 'info-circle']" />
                    </template>

                    <b-dropdown-item-button href="" v-b-modal.chat-shared-attachments-mobile>
                      <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                        <font-awesome-icon class="dark-silver" :icon="['fas', 'info-circle']" />
                      </div>
                      <span class="">
                        View Attachments
                      </span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button href="">
                      <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                        <font-awesome-icon class="dark-silver" :icon="['fas', 'bell']" @click="onMuteConversation()" />
                      </div>
                      <span>
                        Mute Conversation
                      </span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                      <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                        <font-awesome-icon class="dark-silver" :icon="['fas', 'envelope-open-text']"/>
                      </div>
                      <span  @click="onMarkAsUnread()" >
                        Mark as unread
                      </span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button href="">
                      <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                        <font-awesome-icon class="dark-silver" :icon="['fas', 'exclamation-triangle']" />
                      </div>
                      <span>
                        Inappropriate content
                      </span>
                    </b-dropdown-item-button>
                  </b-dropdown>

                </div>

                 <!-- Mobile Shared Attachments -->

            <b-modal id="chat-shared-attachments-mobile" title="Shared Attachments" title-tag="div" size="sm" hide-footer centered scrollable>
              <div class="text-cb-color" style="text-align: center" v-if="isAttachmentLoading">
                <b-spinner class="align-middle" small></b-spinner>
                <strong><small> loading attachments...</small></strong>
              </div>
              <b-collapse visible id="chat-attachments" v-if="!isAttachmentLoading">
                <ul class="list-unstyled">
                  <li v-for="(item, index) in selectedThread.attachments" :key="index">
                    <b-link :href="`${attachmentPath}/${item.attachment_url}`" target="_blank" class="all-chat-info-attachment">
                      <font-awesome-icon class="mr-1" :icon="['fas', 'paperclip']" />
                      <span>{{item.attachment_name}}</span>
                    </b-link>
                  </li>
                </ul>
              </b-collapse>
            </b-modal>


              </div>
            </div>
            <div class="all-chat-box-messages" style="height: 100%;">
              <!-- Initial State New Chat -->
              <div class="text-center py-5">
                <!-- Tutor / Learner Avatar -->
                <b-avatar size="5em" :src="selectedThread.data.image"></b-avatar>
                <div class="pt-3 dark-silver">
                  You are now connected with
                  <div>{{ this.getRoleName(selectedThread.data.role) }} {{ `${selectedThread.data.firstname} ${getFChar(selectedThread.data.lastname)}.` }} Say Hello!</div>
                </div>
              </div>
              <div class="text-cb-color" style="text-align: center" v-if="isMessageLoading">
                <b-spinner class="align-middle" small></b-spinner>
                <strong><small> loading thread conversation...</small></strong>
              </div>
              <b-media v-if="selectedThread">
                <ChatBubbleMessage :dataSource="selectedThread" :data="messageItems" :last_seen="last_seen" :userData="userData"/>
              </b-media>
            </div>
            <div class="text-cb-color" style="text-align: left; margin-left: 10px;" v-if="isSendingFileAttachment">
              <b-spinner class="align-middle" small></b-spinner>
              <strong><small> sending file attachment...</small></strong>
            </div>
            <div class="d-flex p-3" v-if="selectedThread" style="border-top: 1px solid #E9E9E9;">
              <div class="position-relative w-100">
                <div id="emoji-mart-list-messages" class="row emoji-selector-list" style="display: none">
                  <picker set="emojione" @select="showEmoji" title="Chalkboard Emoji" />
                </div>
                <b-textarea v-model="chatMessage" @focus="onChatMessageFocus" :disabled="isSendingFileAttachment || selectedThread.is_active === 0" @keydown.enter.exact.prevent @keyup.enter="sendMessage" class="all-chat-bubble-box-type-message" placeholder="Type a message.." no-resize></b-textarea>
                <span class="all-chat-bubble-box-type-message-emoji" v-b-tooltip.hover title="Emoji">
                  <font-awesome-icon class="" :icon="['fas', 'smile']" @click="onEmojiClick" />
                </span>
              </div>
              <div class="d-flex align-items-end">
                <div class="all-chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || selectedThread.is_active === 0 }" v-b-tooltip.hover title="Add attachment">
                  <font-awesome-icon class="" v-if="selectedThread.is_active === 0" :icon="['fas', 'file']" />
                  <font-awesome-icon class="" v-else :icon="['fas', 'file']" @click="onUploadAttachment" />
                  <b-form-file style="display: none" id="chatFileUpload" v-model="attachFiles" @change="onFileUploadChange($event, selectedThread.thread_id)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".jpg, .jpeg, .png, .docx, .doc, .ppt, .xls, .xlsx, .pdf, .zip, .rar"></b-form-file>
                </div>
                <div class="all-chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || selectedThread.is_active === 0 }" v-b-tooltip.hover title="Send message">
                  <font-awesome-icon class="ml-n1" v-if="selectedThread.is_active === 0" :icon="['fas', 'paper-plane']"/>
                  <font-awesome-icon class="ml-n1" v-else :icon="['fas', 'paper-plane']" @click="sendMessage"/>
                </div>
              </div>
            </div>

          </div>
          <!-- Chat box mor Info -->
          <div class="all-chat-info-container p-3" v-if="selectedThread && allChatInfo">
            <div class="text-center">
              <div class="position-relative">
                <b-avatar size="4.5em" :src="selectedThread.data.image"></b-avatar>
                <div class="all-chat-info-tutor-name">{{ `${selectedThread.data.firstname} ${getFChar(selectedThread.data.lastname)}.` }}</div>
              </div>
              <div class="d-inline-block">
                <b-icon icon="star-fill" class="font-size-xs orange mr-1"></b-icon>
                <span class="">{{selectedThread.data.average_rating || 0}}</span>
              </div>
            </div>

            <div class="">
              <div class="all-shared-documents pt-5 pb-2" v-b-toggle.chat-attachments>
                Shared Attachments
              </div>
              <div class="text-cb-color" style="text-align: center" v-if="isAttachmentLoading">
                <b-spinner class="align-middle" small></b-spinner>
                <strong><small> loading attachments...</small></strong>
              </div>
              <b-collapse visible id="chat-attachments" v-if="!isAttachmentLoading">
                <ul class="list-unstyled">
                  <li v-for="(item, index) in selectedThread.attachments" :key="index" class="mb-2">
                    <a @click="onDownloadAttachmentFile(item.attachment_name, `${item.attachment_url}`)" target="_blank" class="all-chat-info-attachment">
                      <font-awesome-icon class="mr-1" :icon="['fas', 'paperclip']" />
                      <span>{{item.attachment_name}}</span>
                    </a>
                  </li>
                </ul>
              </b-collapse>
            </div>

           
          </div>
        </div>

      </div>

    </b-card>
  </div>
</template>

<script>
import mixins from "../mixins";
import { mapState } from "vuex";
import { Picker } from "emoji-mart-vue";
import ChatBubbleMessage from "../components/chat/ChatBubbleMessage.vue";
import chatMixins from '../mixins/chat'
import review_mixins from "../mixins/review";
export default {
  name: "Messages",
  components: {
    Picker,
    ChatBubbleMessage,
  },
  data: () => ({
    items: [],
    messageItems: [],
    selectedThread: null,
    chatMessage: "",
    last_seen: null,
    isMarkUnread: false,
    isMessageLoading: false,
    attachFiles: [],
    attachFileName: "",
    attachOrigFileName: "",
    searchMessages: "",
    allChatSelect: "All",
    isAttachmentLoading: false,
    attachmentPath: process.env.VUE_APP_BACKEND_URL,
    allChatList: true,
    allChatInfo: false,
    allChatBox: false,
    isSendingFileAttachment: false,
    WatchTyping: null,
    isLoadingSearchMessages: false
  }),
  computed: {
    ...mapState(["userData", "chatThreads"]),
  },
  beforeMount() {
    this.$store.dispatch("loadChatThreads", {
      favorites_only: "",
      search_keyword: this.searchMessages || "",
    });
  },
  mounted() {
    this.$watch("$store.state.chatThreads", (val) => {
      this.items = val.map((x) => {
        const is_muted = x.chat_participants.filter((x) => { return x.user_id !== this.userData.user_id })[0].is_muted;
        return {
          thread_id: x.id,
          created_at: x.created_at,
          data: x.chat_participants.filter((x) => { return x.user_id !== this.userData.user_id })[0],
          is_read: x.chat_participants.filter((x) => { return x.user_id === this.userData.user_id })[0]?.last_read !== null ? true : false,
          is_muted: is_muted === 0 ? false : is_muted !== null || is_muted === 1 ? 1 : 0,
          last_message: x.messages[0]?.message || "You are now connected with",
          last_active_message: x.messages[0]?.created_at || x.created_at,
          sortH: this.moment(x.messages[0]?.created_at || x.created_at).format("MM-DD-YYYY HH:mm:ss"),
          bookmarked: x.bookmarked,
          attachments: [],
          is_active: x.is_active
        }
      }).sort((a, b) => new Date(b.sortH) - new Date(a.sortH));
      this.items.forEach((x) => {
        this.onListenIncomingMessage(x.thread_id, x.data.user_id);
      });
    }, { immediate: true });

    this.$watch("$store.getters.chatMessages", (val) => {
      this.messageItems = val;
      this.scrollToBottom();
    }, { immediate: true });

    window.Echo.private(`chat-notifications.${this.userData.user_id}`)
      .listen(".Modules\\Message\\Events\\Web\\CreateChatThread", (e) => {
        if (e.thread_id !== 0) {
          this.items.unshift(e);
        }
      }
    );

    this.$watch("selectedThread", (val) => {

      if (val !== null) {

        window.Echo.private(`chat-user-status.${this.selectedThread.data.user_id}`)
          .listen(".Modules\\Message\\Events\\Web\\ChatUserStatus", (e) => {
            this.selectedThread.data.chat_status = e.status;
          });

        window.Echo.private(`read-thread-message.${this.selectedThread.thread_id}.${this.selectedThread.data.user_id}`)
          .listen(".Modules\\Message\\Events\\Web\\MarkMessageRead", (e) => {
            if (this.selectedThread.thread_id === parseInt(e.thread_id) && this.selectedThread.data.user_id === e.user_id) {
              this.last_seen = this.moment
                .tz(e.last_seen, "UTC")
                .tz(this.userData.timezone)
                .format("hh:mm A ll");
              this.scrollToBottom();
            }
          });
      }
      
    });

    window.addEventListener("mouseup", function (event) {
      var emoji = document.getElementById("emoji-mart-list-messages");
      if (emoji) {
        if (
          event.target.parentNode != emoji &&
          !document
            .getElementById("emoji-mart-list-messages")
            ?.contains(event.target)
        ) {
          emoji.style.display = "none";
        }
      }
    });

    this.$store.dispatch('setUserChatStatus', { 'user_id': this.userData.user_id, 'status': 'online' })
    
  },
  methods: {
    // Start Mobile
    onBackButton() {
      this.allChatBox = !this.allChatBox;
      this.allChatList = !this.allChatList;
    },
    // End Mobile

    ShowAllChatInfo() {
      this.allChatInfo = !this.allChatInfo;
    },

    onChatSelectChange() {
      this.$store.dispatch("loadChatThreads", {
        favorites_only: this.allChatSelect === "Favorites" ? 1 : "",
        search_keyword: this.searchMessages,
      });
    },
    onListenIncomingMessage(thread_id, user_id) {
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${user_id}`).stopListening(".Modules\\Message\\Events\\Web\\SendChatMessage")
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${user_id}`)
        .listen(".Modules\\Message\\Events\\Web\\SendChatMessage", (e) => {
          const result = this.items.find((x) => x.thread_id === thread_id);
          if (result !== "undefined") {
            const index = this.items.indexOf(result);
            this.items[index].last_active_message = e.created_at;
            this.items[index].last_message = e.message;
            this.items[index].is_read = false;
          }
          if (parseInt(e.sender) === this.selectedThread?.data.user_id) {
            this.chatNotifSound()
            this.messageItems.push(e);
            this.scrollToBottom();
            this.isAttachmentLoading = true
            this.$store.dispatch("loadChatAttachments", { thread_id: this.selectedThread.thread_id }).then((response) => {
              this.selectedThread.attachments = response.data.data;
            }).finally(() => { this.isAttachmentLoading = false });
          }
        }
      );
    },
    onSearchMessages() {
      clearTimeout(this.WatchTyping);
      setTimeout(() => { this.isLoadingSearchMessages = true }, 500)
      this.WatchTyping = setTimeout(() => {
        this.$store.dispatch("loadChatThreads", { favorites_only: this.allChatSelect === "Favorites" ? 1 : "", search_keyword: this.searchMessages }).finally(() => { this.isLoadingSearchMessages = false });
      }, 1000);
    },
    removeAttachment() {
      this.attachFiles = [];
      this.attachFileName = "";
      this.attachOrigFileName = "";
    },
    onUploadAttachment() {
      document.getElementById("chatFileUpload").click();
    },
    onFileUploadChange(e, thread_id) {
      let file = e.dataTransfer || e.target;
      file = file.files[0];
      if (file) {
        const validSize = [
          { type: "file", size: "5000" },
          { type: "photo", size: "5000" },
        ];
        let allowedExt = [
          {
            ext: [
              "txt",
              "docx",
              "doc",
              "ppt",
              "pptx",
              "xls",
              "xlsx",
              "pdf",
              "zip",
              "rar",
            ],
            type: "file",
          },
          { ext: ["jpg", "jpeg", "png"], type: "photo" },
        ];
        const filename = file.name.toLowerCase();
        const ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        const fileType = allowedExt
          .filter((x) => {
            return x.ext.includes(ext);
          })
          .map((x) => x.type)[0];
        const allowedSize = parseInt(
          validSize
            .filter((x) => {
              return x.type === fileType;
            })
            .map((x) => x.size)[0]
        );
        allowedExt = [].concat
          .apply(
            [],
            allowedExt.map((x) => x.ext)
          )
          .join("|");
        const pattern = new RegExp(`(${allowedExt})$`);
        if (file.size / 1024 > allowedSize) {
          this.ShowModal(
            `The ${fileType} that you are trying to upload is too large.<br /><br /><b>Upload Details:</b><br />
              Name: ${file.name}<br />
              Size: ${this.formatBytes(file.size)}<br />
              Type: ${
                ext === "rar" ? "application/x-rar-compressed" : file.type
              }`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            },
            `<strong>${this.convertByteToMegaByte(
              allowedSize
            )}MB Uploading Limit</strong>`
          );
          return;
        } else if (!pattern.test(filename)) {
          this.ShowModal(
            `The file must be a file of type: ${allowedExt.replaceAll(
              "|",
              ", "
            )}.`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            }
          );
          return;
        }
        const formData = new FormData();
        const params = {
          attachment_file: file,
          thread_id: thread_id,
        };
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/upload-chat-attachment`,
          options = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };
        this.isSendingFileAttachment = true
        this.axios
          .post(ApiURL, formData, options)
          .then((response) => {
            if (response.data.status === 200) {
              this.attachFileName = response.data.data.server_attachment;
              this.attachOrigFileName = response.data.data.original_name;
              this.chatMessage = this.attachOrigFileName;
              this.sendMessage();
            } else {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            }
          })
          .catch(() => {
            this.attachFiles = [];
            this.attachFileName = "";
            this.attachOrigFileName = "";
          })
          .finally(() => {});
      }
    },
    onViewProfile(tutorId) {
      if (this.userData.role !== "teacher") {
        window.localStorage.setItem("tutorId", tutorId);
        let routeData = this.$router.resolve({
          name: "search_teacher_profile",
        });
        window.open(routeData.href, "_blank");
      }
    },
    onMarkAsFavorites() {
      this.$store.dispatch("markChatAsFavorites", {
        thread_id: this.selectedThread.thread_id,
        is_favorite: this.selectedThread.bookmarked,
      });
      this.selectedThread.bookmarked = !this.selectedThread.bookmarked;
    },
    onMuteConversation() {
      this.selectedThread.is_muted = !this.selectedThread.is_muted;
      const payload = {
        thread_id: this.selectedThread.thread_id,
        user_id: this.selectedThread.data.user_id,
        is_muted: this.selectedThread.is_muted ? 1 : 0,
      };
      this.$store.dispatch("toggleMuteParticipantThread", payload);
    },
    onMarkAsUnread() {
      this.selectedThread.is_read = !this.selectedThread.is_read;
      const payload = {
        thread_id: this.selectedThread.thread_id,
        is_read: this.selectedThread.is_read ? 0 : 1,
      };
      this.$store.dispatch("toggleReadMessage", payload);
    },
    onEmojiClick() {
      const emojis = document.getElementById("emoji-mart-list-messages");
      emojis.style.display = "block";
      emojis.style.position = "absolute";
      emojis.style.top = "-420px";
    },
    showEmoji(emoji) {
      this.chatMessage = this.chatMessage + emoji.native;
    },
    sendMessage() {
      if (this.chatMessage === "") return;
      const attachment = this.attachOrigFileName === '' ? null : this.attachOrigFileName
      if (attachment === null) {
        this.messageItems.push({
          thread_id: this.selectedThread.thread_id,
          user_id: this.userData.user_id,
          message: this.chatMessage,
          created_at: this.moment.tz(this.userData.timezone).utc(),
          attachment_name: null,
          attachment_url: null
        });
      }
      const param = {
        thread_id: this.selectedThread.thread_id,
        message: this.chatMessage,
        server_attachment: this.attachFileName,
        attachment_file: this.attachOrigFileName,
        recipient: this.selectedThread.data.user_id
      };
      const formData = new FormData();
      Object.keys(param).forEach((key) => {
        formData.append(key, param[key]);
      });

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/send-chat`;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      this.axios.post(ApiURL, formData, options).then(response => {
        if (attachment !== null) {
          this.messageItems.push({
            thread_id: this.selectedThread.thread_id,
            user_id: this.userData.user_id,
            message: this.chatMessage,
            created_at: this.moment.tz(this.userData.timezone).utc(),
            attachment_name: attachment,
            attachment_url: response.data.data.attachment_url
          })
        }
      }).finally(() => {
        if (attachment !== null) {
          this.chatMessage = ''
          this.last_seen = null
          this.scrollToBottom()
          this.isSendingFileAttachment = false
          this.attachFiles = [];
          this.attachFileName = ""
          this.attachOrigFileName = ""
        }
      })
      if (attachment === null) {
        this.chatMessage = ''
        this.last_seen = null
        this.scrollToBottom()
      }
    },
    onThreadClick(data) {
      // Start Mobile //
      if (this.isMobile() || window.orientation !== undefined || window.innerWidth < 798) {
        this.allChatList = false;
        this.allChatBox = true;
      } else {
        this.allChatBox = true;
      }
      // End Mobile //
      this.messageItems = [];
      this.selectedThread = data;
      this.last_seen = data.data.last_read !== null ? this.moment.tz(data.data.last_read, 'UTC').tz(this.userData.timezone).format('hh:mm A ll') : null
      this.$store.state.chatSelectedThread = data;
      this.isMessageLoading = true;
      this.getTeacherReviewSummary(this.selectedThread.data.user_id)
        .then((response) => {
          const _data = response.data.data;
          this.selectedThread.data.average_rating = _data[0].average_rating;
          this.selectedThread.data.review_count = _data[0].rating_count;
        });
      this.$store.dispatch("loadChatMessages", { thread_id: data.thread_id, search_keyword: this.searchMessages }).finally(() => { this.isMessageLoading = false; });
      const payload = {
        thread_id: data.thread_id,
        is_read: 0,
      };
      this.$store.dispatch("toggleReadMessage", payload);
      this.items.find(
        (x) => x.thread_id === parseInt(data.thread_id)
      ).is_read = 1;
      this.$store.dispatch("loadUnreadMessages");
      this.isAttachmentLoading = true;
      this.$store
        .dispatch("loadChatAttachments", {
          thread_id: this.selectedThread.thread_id,
        })
        .then((response) => {
          this.selectedThread.attachments = response.data.data;
        })
        .finally(() => {
          this.isAttachmentLoading = false;
        });
    },
    scrollToBottom() {
      const chatBubbleBoxBody = document.querySelector(`.all-chat-box-messages`);
      if (chatBubbleBoxBody)
        setTimeout(() => {
          chatBubbleBoxBody.scrollTo({ top: chatBubbleBoxBody.scrollHeight, behavior: "smooth" });
        }, 100);
    },
    onChatMessageFocus() {
      if (this.last_seen !== null) {
        const result = this.items.find((x) => x.thread_id === this.selectedThread.thread_id)
        this.items[this.items.indexOf(result)].is_read = result !== "undefined" ? true : false
        this.readMessage()
      }
    },
    readMessage() {
      const payload = {
        thread_id: this.selectedThread.thread_id,
        is_read: 0,
      };
      this.$store.dispatch("toggleReadMessage", payload);
    }
  },
  mixins: [mixins, chatMixins, review_mixins],
};
</script>

<style scoped>
.all-chat-list-wrapper {
  min-height: 30em;
  min-width: 22em;
  padding: 1.2em 0.875em 0 1em;
}
.all-chat-search-input {
  background: #f0f2f5;
  border-radius: 25px;
  border: none;
  font-size: calc(var(--fs-one) - 2px);
}
.all-chat-search-input:focus,
.all-chat-search-input:active {
  background-color: #f0f2f5;
  outline: none;
  box-shadow: none;
}
.all-chat-select,
.all-chat-select:focus {
  border: unset;
  box-shadow: unset;
  font-weight: 500;
  padding: unset;
}
.all-chat-messages-list-container {
  min-height: 34em;
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 21em;
   scrollbar-width: thin;
}

.all-chat-messages-list-container::-webkit-scrollbar, .all-chat-box-messages::-webkit-scrollbar {
  width: 10px;
}

.all-chat-messages-list-container::-webkit-scrollbar-track, .all-chat-box-messages::-webkit-scrollbar-track {
  background: var(--light-silver);
}

.all-chat-messages-list-container::-webkit-scrollbar-thumb, .all-chat-box-messages::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
    border-radius: 10px;
}

.all-chat-messages-list-container::-webkit-scrollbar-thumb:hover, .all-chat-box-messages::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
  
}

.all-chat-messages-list {
  cursor: pointer;
  padding: 0.875em;
  border-radius: 10px;
}
.all-chat-messages-list.active,
.all-chat-messages-list.active:hover {
  background: rgba(0, 191, 224, 5%);
}
.all-chat-messages-list:hover {
  background: var(--silver);
}
.all-chat-name {
  color: var(--grey);
  font-size: var(--fs-one);
}
.all-chat-name.chat-unread {
  color: var(--dark-grey);
  font-weight: 500;
}
.all-chat-message {
  color: #9b9b9b;
  min-width: 12em;
  max-width: 13em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-chat-message.chat-unread {
  color: var(--light-blue);
}
.chat-dot-notif {
  display: inline-block;
  background: transparent;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}
.chat-dot-notif.chat-unread {
  display: inline-block;
  background: var(--light-blue);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}

.chat-messages-list {
  min-height: 20em;
}
.all-chat-box-wrappper {
  display: flex;
}
.all-chat-message-no-chat-messages {
  padding: 5em;
  text-align: center;
  /* width: 35em; */
  margin: auto;
  color: var(--grey);
}

.all-chat-message-no-chat-messages > [data-icon="comments"] {
  font-size: var(--fs-three);
}
.all-chat-message-no-chat-messages > div {
  font-size: var(--fs-one-six);
}
.all-chat-message-no-chat-messages > span {
  font-size: var(--fs-one);
  color: var(--dark-silver);
}

.all-chat-box-container {
  border: 1px solid var(--silver);
}

.all-chat-box-header {
  border-bottom: 1px solid var(--silver);
  padding: 1em;
}

.all-chat-box-back-button,
.all-chat-box-back-button:hover,
.all-chat-box-back-button:active,
.all-chat-box-back-button:focus {
  background: none !important;
  display: none;
}

.all-chat-box-messages {
  min-height: 30em;
  max-height: 30em;
  overflow-y: scroll;
  padding: 1em;
  scrollbar-width: thin;
}

.all-chat-bubble-box-avatar {
  position: relative;
}

.all-chat-bubble-box-avatar-status {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 0.525em;
  height: 0.525em;
}

.all-chat-bubble-box-avatar-status.online {
  background: var(--teal);
}

.all-chat-bubble-box-avatar-status.offline {
  background: var(--dark-silver);
}

.all-chat-bubble-box-message {
  display: inline-block;
  background: #f6f6f6;
  color: var(--dark-grey);
  font-size: calc(var(--fs-one)- 2px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
}

.all-chat-bubble-box-tutor-name {
  font-weight: 500;
  color: var(--dark-grey);
  cursor: pointer;
}

.all-chat-bubble-box-control {
  color: var(--dark-silver);
  text-align: center;
  font-size: calc(var(--fs-one) - 2px);
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.875rem;
}
.all-chat-bubble-box-control.disabled,
.all-chat-bubble-box-control.disabled:hover {
  cursor: not-allowed;
  color: var(--dark-silver);
}
.all-chat-bubble-box-control:hover {
  color: var(--light-blue);
}
.all-chat-bubble-box-control.active {
  color: var(--light-blue);
}
.all-chat-bubble-box-message-status {
  display: inline-block;
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
}
.all-chat-bubble-box-message-status.me {
  float: right;
}

.all-chat-bubble-box-message.me {
  background: var(--light-blue);
  color: var(--white);
  float: right;
}

.all-chat-bubble-box-type-message:focus,
.all-chat-bubble-box-type-message:active {
  background-color: #f0f2f5;
  outline: none;
  box-shadow: none;
}
.all-chat-bubble-box-type-message::placeholder {
  color: var(--dark-silver);
}
.all-chat-bubble-box-type-message {
  background: #f0f2f5;
  border-radius: 25px;
  color: var(--dark-grey);
  line-height: 1.2;
  overflow-y: unset !important;
  padding-top: 0.82em;
  padding-left: 0.82em;
  padding-right: 2.725em;
  word-break: break-all;
  height: 45px;
  max-height: 75px;
  border: none;
  font-size: calc(var(--fs-one) - 2px);
}
.all-chat-bubble-box-type-message-emoji {
  position: absolute;
  right: 25px;
  bottom: 0;
  font-size: 20px;
  line-height: 2.2;
  color: var(--dark-silver);
  cursor: pointer;
}
.all-chat-bubble-box-type-message-buttons {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f0f2f5;
  text-align: center;
  line-height: 2.2;
  margin-left: 0.425rem;
  color: var(--dark-silver);
  font-size: 20px;
  cursor: pointer;
}
.all-chat-bubble-box-type-message-buttons:hover {
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  transition: all ease 500ms;
}

.all-chat-bubble-box-type-message-buttons.disabled,
.all-chat-bubble-box-type-message-buttons.disabled:hover {
  cursor: not-allowed;
  color: var(--dark-silver);
}

#chat-attachments {
    overflow-y: scroll;
    max-height: 20em;
    scrollbar-width: thin;
    padding: 0.2em;
}

 #chat-attachments::-webkit-scrollbar {
  width: 10px;
}

 #chat-attachments::-webkit-scrollbar-track {
  background: var(--light-silver);
}

#chat-attachments::-webkit-scrollbar-thumb {
  background: var(--dark-silver);
    border-radius: 10px;
}

#chat-attachments::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
  
}

/* Info box */
.all-chat-info-container {
  min-width: 15em;
  max-width: 25em;
}
.all-chat-info-tutor-name {
  margin-top: 0.855rem;
  font-size: calc(var(--fs-one-six) - 4px);
  font-weight: 500;
}
.all-shared-documents {
  color: var(--dark-grey);
  font-size: var(--fs-one);
  font-weight: 500;
}

.all-chat-info-attachment {
  color: var(--grey);
  font-size: calc(var(--fs-one) - 6px);
  cursor: pointer;
}
.all-chat-info-attachment span {
  font-size: calc(var(--fs-one) - 4px);
}
.all-chat-info-attachment:hover {
  text-decoration: underline;
}
.mobile-control {
  display: none;
}
@media screen and (max-width: 40em) {
  .all-chat-box-back-button,
  .all-chat-box-back-button:hover,
  .all-chat-box-back-button:active,
  .all-chat-box-back-button:focus {
    display: block;
  }
  .all-chat-list-wrapper {
    width: 100%;
    min-width: unset;
  }
  .all-chat-messages-list-container {
    min-width: unset;
    min-height: unset;
    max-height: unset;
    overflow-y: unset;
    overflow-x: unset;
  }
  .all-chat-message {
    min-width: unset;
  }
  .all-chat-info-container {
    display: none;
  }
  .all-chat-bubble-box-control {
    display: none;
  }
  .all-chat-bubble-box-control.cs {
    display: inline-block;
  }
  .mobile-control {
    display: inline-block;
  }
  .mobile-control > .btn-link,
  .mobile-control > .btn:focus,
  .mobile-control > .btn.focus {
    padding: 0 !important;
    box-shadow: none !important;
    outline: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .all-chat-message {
    max-width: 10em;
  }
}
.text-cb-color {
  color: var(--light-blue) !important;
}
.chat-bubble-box-control {
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  font-size: calc(var(--fs-one) - 2px);
  font-weight: normal;
  line-height: 1.4;
  cursor: pointer;
}
</style>
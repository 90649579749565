<template>
  <div>
    <b-link v-if="showMcDonaldsBanner" to="/mcdohappymeal">
      <b-img
        class="mcdo-banner"
        :src="require('@/assets/imgs/mcdo-banner.png')"
      ></b-img>
      <b-img
        class="mcdo-banner-mobile"
        :src="require('@/assets/imgs/mcdo-banner-mobile.png')"
      ></b-img>
    </b-link>
    <svg
      v-else
      id="wave"
      style="background: #003060; transform:rotate(180deg); transition: 0.3s"
      height="60"
      width="100%"
      viewBox="0 0 1440 100"
      preserveAspectRatio="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(244, 253, 255, 1)" offset="0%"></stop>
          <stop stop-color="rgba(244, 253, 255, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style="transform:translate(0, 0px); opacity:1"
        fill="url(#sw-gradient-0)"
        d="M0,60L48,53.3C96,47,192,33,288,36.7C384,40,480,60,576,63.3C672,67,768,53,864,40C960,27,1056,13,1152,16.7C1248,20,1344,40,1440,48.3C1536,57,1632,53,1728,55C1824,57,1920,63,2016,56.7C2112,50,2208,30,2304,20C2400,10,2496,10,2592,15C2688,20,2784,30,2880,40C2976,50,3072,60,3168,65C3264,70,3360,70,3456,63.3C3552,57,3648,43,3744,43.3C3840,43,3936,57,4032,61.7C4128,67,4224,63,4320,63.3C4416,63,4512,67,4608,70C4704,73,4800,77,4896,70C4992,63,5088,47,5184,35C5280,23,5376,17,5472,16.7C5568,17,5664,23,5760,28.3C5856,33,5952,37,6048,43.3C6144,50,6240,60,6336,65C6432,70,6528,70,6624,65C6720,60,6816,50,6864,45L6912,40L6912,100L6864,100C6816,100,6720,100,6624,100C6528,100,6432,100,6336,100C6240,100,6144,100,6048,100C5952,100,5856,100,5760,100C5664,100,5568,100,5472,100C5376,100,5280,100,5184,100C5088,100,4992,100,4896,100C4800,100,4704,100,4608,100C4512,100,4416,100,4320,100C4224,100,4128,100,4032,100C3936,100,3840,100,3744,100C3648,100,3552,100,3456,100C3360,100,3264,100,3168,100C3072,100,2976,100,2880,100C2784,100,2688,100,2592,100C2496,100,2400,100,2304,100C2208,100,2112,100,2016,100C1920,100,1824,100,1728,100C1632,100,1536,100,1440,100C1344,100,1248,100,1152,100C1056,100,960,100,864,100C768,100,672,100,576,100C480,100,384,100,288,100C192,100,96,100,48,100L0,100Z"
      ></path>
    </svg>
    <section class="container-fluid dark-blue-bg">
      <div class="cb-as-seen-container">
        <h5 class="text-center font-weight-bold text-white">As seen in</h5>
        <div class="row align-items-center justify-content-center">
          <div class="as-seen flex-lg-row flex-md-column" style="gap: 2em;">
            <figure class="figure p-2">
              <img
                class="figure-img cnn"
                :src="require('@/assets/imgs/home/cnn.svg')"
                alt=""
              />
            </figure>
            <figure class="figure p-2">
              <img
                class="figure-img bmtm"
                :src="require('@/assets/imgs/home/BusinessMirror.svg')"
                alt=""
              />
            </figure>
            <figure class="figure p-2">
              <img
                class="figure-img bmtm"
                :src="require('@/assets/imgs/home/ManilaTimes.svg')"
                alt=""
              />
            </figure>

            <figure class="figure p-2 ">
              <img
                class="figure-img qbo"
                :src="require('@/assets/imgs/home/QBO.svg')"
                alt=""
              />
            </figure>
            <figure class="figure p-2">
              <img
                class="figure-img phsw"
                :src="require('@/assets/imgs/home/PHSW.png')"
                alt=""
              />
            </figure>
            <figure class="figure p-2">
              <img
                class="figure-img "
                :src="require('@/assets/imgs/home/PhDailyInquirer.svg')"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <svg
      width="100%"
      height="39"
      viewBox="0 0 1440 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M1440 26.6545L1320 29.9863C1200 33.3181 960 39.9817 720 38.8781C480 37.6911 240 28.9451 120 24.4263L0 19.9909V0L120 0C240 0 480 0 720 0C960 0 1200 0 1320 0L1440 0V26.6545Z"
        fill="#003060"
      />
    </svg>

    <section class="py-875">
      <b-container fluid>
        <h6 class="text-center dark-blue">
          Trusted by thousands of parents and learners globally
        </h6>
        <b-row align-v="center" no-gutters>
          <b-col sm="12" md="5" lg="5">
            <figure class="text-center p-2">
              <img
                class="img-fluid"
                style="max-width: 450px;width:100%;"
                :src="require('@/assets/imgs/home/testimonial-image.png')"
                alt=""
              />
            </figure>
          </b-col>
          <b-col sm="12" md="7" lg="7">
            <div class="testi-slider pr-sm-3">
              <VueSlickCarousel v-bind="settings">
                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>I love the flexibility and reasonable rate!</div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    My two daughters had fun during their first session and they
                    requested me to book again!
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Charisse, Colorado
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>
                      They always have a very good interaction every session.
                    </div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    My daughter loves Teacher Donna. Teacher Donna is an
                    effective Filipino teacher for non-native speakers. I would
                    definitely recommend her at any time.
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Michelle, Qatar
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>Perfect fit for our need.</div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    Tutor Mark can teach well and converse with my son. My son
                    likes him and is learning a lot from him.
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Leilani, Illinois
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>I'm really happy with my son's progress.</div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    Tutor Toni is very patient with my son. I can't imagine that
                    our little Yannis Raphael could make it. Thank you
                    Chalkboard and Tutor Toni.
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Janneth, Olongapo City
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>My daughter is improving her writing skills.</div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    My daughter was happy with Tutor Ronald. I can see that my
                    daughter is improving her writing skills through the help of
                    his coaching.
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Kristina, Negros Occidental
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>She made it to the Director’s List again.</div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    Thank you for helping my daughter! I appreciate your help,
                    effort and patience. Thanks, Tutor Iana of Chalkboard!
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Brenda, Bulacan
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>

                <div class="testi-card">
                  <div class="testi-quote-h">
                    <img
                      class=""
                      :src="require('@/assets/imgs/home/testi-quote-h.png')"
                      alt=""
                    />
                    <div>
                      To see your child enjoy school and learning is heavenly.
                    </div>
                  </div>
                  <p class="testi-quote-p font-italic">
                    Learning is fun for him since the tutors are patient and
                    professional. It results in good grades and a happy home.
                    It's the best thing that money can buy.
                  </p>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span
                      class="testi-quote-name text-center font-weight-medium"
                    >
                      Hope, Delaware
                    </span>
                    <b-form-rating
                      class="text-center testi-rating"
                      size="sm"
                      icon-empty="star-fill"
                      value="5"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "StatsSummary",

  components: { VueSlickCarousel },

  created() {
    this.checkMcDonaldsVoucherAvailability();
  },

  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        initialSlide: 1,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 11000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false
            }
          }
        ]
      },
      showMcDonaldsBanner: false
    };
  },

  methods: {
    checkMcDonaldsVoucherAvailability() {
      const url = process.env.VUE_APP_BACKEND_URL + "/api/voucher/availability";
      const options = {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        params: { voucher_code: "MCDO23" }
      };

      axios.get(url, options).then((response) => {
        this.showMcDonaldsBanner = response.data.inEffect;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mcdo-banner {
  width: 100%;
  background-color: var(--dark-blue);
}
@media screen and (max-width: 769px) {
  .mcdo-banner {
    display: none;
  }
}
.mcdo-banner-mobile {
  width: 100%;
  background-color: var(--dark-blue);
  display: none;
}
@media screen and (max-width: 768px) {
  .mcdo-banner-mobile {
    display: block;
  }
}
.as-seen {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.py-875 {
  padding: 8.75em 0;
}

.pb-875 {
  padding-bottom: 8.75em 0;
}

@media screen and (max-width: 768px) {
  .as-seen {
    padding-top: 2em;
    flex-direction: column;
  }
}

.testi-card {
  background: var(--white);
  border-radius: 1em;
  padding: 1.5em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 15px 0px;
  height: 300px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (max-width: 480px) {
  .testi-card {
    height: 400px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .testi-card {
    height: 100%;
  }
}

.testi-rating,
.testi-rating:focus {
  background: none;
  background-color: none;
  padding: 0;
}

.testi-quote-h {
  color: var(--teal);
  display: inline-flex;
  align-items: baseline;
}

.testi-quote-h div {
  font-size: calc(var(--fs-one-six) - 4px);
  font-weight: 600;
  width: 75%;
  margin-bottom: 0.8em;
}

.testi-quote-h img {
  width: 100%;
  max-width: 26px;
  object-fit: contain;
  margin-right: 1em;
}

.testi-quote-p {
  font-size: calc(var(--fs-one) - 2px);
  color: var(--grey);
}

.testi-quote-name {
  font-size: calc(var(--fs-one) - 4px);
}

.testi-slider {
  padding: 5em 0;
}

.testi-slider >>> .slick-slide > div {
  margin: 1em;
}

.testi-slider >>> .slick-dots {
  position: unset;
}
</style>

<template>
  <div>
    <b-modal ref="promo-modal" centered hide-header hide-footer body-class="p-0">
      <b-card class="p-2" no-body>
        <span v-on:click="hidePromoModal()" class="position-absolute text-center font-weight-bold text-white" style="right:-15px;top:-15px;background:var(--red);height:30px;width:30px;border-radius:50%; font-size: 30px;line-height:1;cursor:pointer;">&times;</span>
        <a href="https://chalkboard.com.ph/pages/free-trial-campaign-terms-and-conditions" target="_blank">
          <img style="border-radius: 15px;" class="w-100 " src="../../assets/imgs/promotions/promotion-modal.jpg" alt="">
        </a>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'PromoPopup',
    data: () => ({
      cookiePropName: 'promo-banner-click-once',
      isPromoClickOnce: false,
      isShowPromoModal: false
    }),
    computed: {
      ...mapState(['voucherPromoData']),
    },
    mounted() {
      if (this.$route.name === 'home_page') this.showPromoModal()
    },
    methods: {
      showPromoModal() {
        const vm = this
        vm.isShowPromoModal = (!localStorage.getItem(vm.cookiePropName)) ? false : true
        if (!vm.isShowPromoModal && this.voucherPromoData.length > 0) {
          this.$refs["promo-modal"].show();
        }
      },
      hidePromoModal() {
        this.$refs["promo-modal"].hide();
        this.onPromoClickOnce()
      },
      onPromoClickOnce() {
        const vm = this
        localStorage.setItem(vm.cookiePropName, true)
        vm.isPromoClickOnce = true
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
  <div class="mt-n5">
    <TopSpacer />
    <section class="container-fluid cb-our-tutors-section px-5em">
      <div class="container pb-5">
        <h3 class="text-center">Our Tutors</h3>
      </div>
      <div class="cb-our-tutor-nav pb-4">
        <b-nav small>
          <b-nav-item
            @click="loadFeaturedTutors()"
            :active="activeTab == 'Popular'"
          >
            Popular Tutors
          </b-nav-item>
          <b-nav-item
            @click="loadFeaturedTutors('Math')"
            :active="activeTab == 'Math'"
          >
            Math
          </b-nav-item>
          <b-nav-item
            @click="loadFeaturedTutors('Coding')"
            :active="activeTab == 'Coding'"
          >
            Coding
          </b-nav-item>
          <b-nav-item
            @click="loadFeaturedTutors('Music')"
            :active="activeTab == 'Music'"
          >
            Music
          </b-nav-item>
          <b-nav-item
            @click="loadFeaturedTutors('Arts')"
            :active="activeTab == 'Arts'"
          >
            Arts
          </b-nav-item>
          <b-nav-item
            @click="loadFeaturedTutors('Reading')"
            :active="activeTab == 'Reading'"
          >
            Reading
          </b-nav-item>
        </b-nav>
      </div>

      <div class="cb-our-tutors-container">
        <div class="" v-if="isBusy">
          <div class="text-center dark-blue">
            <b-spinner class="align-middle mr-3"></b-spinner>
            <strong>Loading {{ activeTab }} Tutors...</strong>
          </div>
        </div>
        <div v-if="popularTutors.length == 0 && !isBusy">
          <div class="text-center dark-blue">
            <strong>
              There are no active {{ activeTab }} tutors at this moment.
            </strong>
          </div>
        </div>
        <VueSlickCarousel v-bind="settings" v-if="popularTutors.length > 0">
          <div class="pb-3" v-for="(item, index) in popularTutors" :key="index">
            <div
              class="cb-our-tutors-body"
              :style="'border: 1px solid ' + item.color_id + ' !important;'"
            >
              <b-link :to="item.book_tutor_path" class="dark-grey">
                <div class="cb-tutor-image">
                  <img :src="item.image_url" />
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p class="cb-our-tutors-name text-overflow-ellipsis">
                    {{ item.name }}
                  </p>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      class="orange mr-1 tutor-star"
                      :icon="['fas', 'star']"
                    />
                    <div
                      class="font-weight-medium dark-grey"
                      :style="'font-size:18px;'"
                    >
                      {{ item.average_rating }}
                    </div>
                  </div>
                </div>
                <div class="cb-session-content pb-2">
                  <div
                    class="d-flex align-items-center font-size-xs dark-grey pb-3"
                  >
                    <div class="d-inline-block mr-2">
                      <div class="count-text">
                        <span>{{ item.session_count }}</span>
                        session{{ item.session_count > 1 ? "s" : "" }}
                        •
                      </div>
                    </div>
                    <div class="d-inline-block">
                      <div class="count-text">
                        <span>{{ item.learner_count }}</span>
                        learner{{ item.learner_count > 1 ? "s" : "" }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <p class="tutor-about__tagline">
                      {{ item.tagline }}
                    </p>
                  </div>
                </div>

                <div class="cb-our-tutor-content">
                  <div class="subject-list pb-4">
                    <span style="font-weight:500;">Can Teach</span>
                    <div v-subject-list-pills>
                      <b-badge
                        v-for="subject in item.subject_names"
                        :key="subject.name + subject"
                        pill
                      >
                        {{ subject }}
                      </b-badge>
                    </div>
                  </div>

                  <div v-if="false" class=" pb-2">
                    <span style="font-weight:500;">Availability</span>
                    <div class="availability-list">
                      <div
                        class="availability-list-item"
                        v-bind:class="item.mornings ? 'available' : ''"
                      >
                        <MorningIcon />
                        <span>Morning</span>
                      </div>
                      <div
                        class="availability-list-item "
                        v-bind:class="item.afternoons ? 'available' : ''"
                      >
                        <AfternoonIcon />
                        <span>Afternoon</span>
                      </div>
                      <div
                        class="availability-list-item"
                        v-bind:class="item.evenings ? 'available' : ''"
                      >
                        <EveningIcon />
                        <span>Evening</span>
                      </div>
                      <div
                        class="availability-list-item"
                        v-bind:class="item.weekends ? 'available' : ''"
                      >
                        <WeekendIcon />
                        <span>Weekends</span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-link>

              <div class="d-flex align-items-center justify-content-between">
                <span
                  v-if="showConvertedAmount"
                  class="currency-localization currency-localization--sm"
                  :set="
                    (amount = convertAmount(item.hourly_rate, { parts: true }))
                  "
                >
                  <span class="dark-grey currency-localization__symbol">
                    {{ amount.symbol }}
                  </span>
                  <span class="currency-localization__whole">
                    {{ amount.whole }}
                  </span>
                  <span
                    class="currency-localization__decimal"
                    style="font-weight: 500;"
                  >
                    {{ amount.decimal }}
                  </span>
                  <span class="currency-localization__unit">/hr</span>
                </span>

                <span
                  v-else
                  class="currency-localization currency-localization--sm"
                >
                  <span class="dark-grey currency-localization__symbol">₱</span>
                  <span class="currency-localization__whole">
                    {{ item.hourly_rate }}
                  </span>
                  <span class="currency-localization__unit">/hr</span>
                </span>

                <b-link
                  @click="onViewProfile(item.user_id)"
                  class="card-link cb-primary-link book-now"
                >
                  View Tutor
                </b-link>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
    <BottomSpacer />
  </div>
</template>

<script>
import commonmixins from "../../../mixins";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import MorningIcon from "../../../assets/imgs/home/morning.svg?inline";
import AfternoonIcon from "../../../assets/imgs/home/afternoon.svg?inline";
import EveningIcon from "../../../assets/imgs/home/evening.svg?inline";
import WeekendIcon from "../../../assets/imgs/home/weekend.svg?inline";
import TopSpacer from "../../../assets/imgs/home/our-tutors-top-spacer.svg?inline";
import BottomSpacer from "../../../assets/imgs/home/our-tutors-bottom-spacer.svg?inline";

export default {
  name: "TutorList",
  components: {
    VueSlickCarousel,
    MorningIcon,
    AfternoonIcon,
    EveningIcon,
    WeekendIcon,
    TopSpacer,
    BottomSpacer
  },

  data() {
    return {
      popularTutors: [],
      activeTab: "Popular",
      isBusy: false,
      settings: {
        centerMode: true,
        infinite: true,
        initialSlide: 5,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1540,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerMode: false,
              slidesToScroll: 2,
              infinite: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      colorHexCodes: [
        "FFA928",
        "00BFE0",
        "0295af",
        "7BECFF",
        "003060",
        "283D52",
        "008080",
        "005656",
        "FFBD59",
        "FFB84D",
        "FFBE5C",
        "FFB547",
        "FDD285",
        "36B2BA",
        "36B2BA",
        "3B5F83",
        "179F9F",
        "F8CB87",
        "03BFE2",
        "6CD4D5",
        "005CB4",
        "1B9998",
        "ADE5F9",
        "3DCAE2",
        "FCC461",
        "2269AF",
        "154C83",
        "5F9EDC",
        "238CF5",
        "20DCE8",
        "1AA6AF",
        "308C92"
      ]
    };
  },

  directives: {
    subjectListPills: {
      inserted: function(el) {
        if (el.clientHeight > 50) {
          while (el.clientHeight > 50) {
            el.lastChild.remove();
          }
          const lastChild = el.lastChild,
            newLastChild = document.createElement("small");

          newLastChild.classList = "text-secondary";
          newLastChild.innerText = "+more";

          el.replaceChild(newLastChild, lastChild);
        }
      }
    }
  },

  created() {
    this.loadFeaturedTutors();
  },

  methods: {
    loadFeaturedTutors(tab) {
      this.activeTab = tab || "Popular";
      this.isBusy = true;
      this.popularTutors = [];

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.axios
        .get(
          process.env.VUE_APP_BACKEND_URL + "/api/statistic/featured-tutors",
          {
            headers: {
              Accept: "application/json"
            },
            params: { subjectName: tab, timeZone: timeZone }
          }
        )
        .then(({ data, status }) => {
          if (status == 200) {
            data = data.map((tutor) => {
              tutor.subject_names = tutor.subject_names.split(",");
              tutor["color_id"] = this.getRandomColor();
              return tutor;
            });
            this.popularTutors = data;
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    getRandomColor() {
      return this.colorHexCodes[
        Math.floor(Math.random() * this.colorHexCodes.length)
      ];
    },

    onViewProfile(tutorId) {
      window.localStorage.setItem("tutorId", tutorId);
      if (this.UserToken) {
        this.$router.push("/profile-search");
      } else {
        this.ShowModal("You need to login to view this profile.", () => {
          this.$router.push({
            name: "login",
            query: { redirect: "/profile-search" }
          });
        });
      }
    }
  },
  mixins: [commonmixins]
};
</script>

<style scope>
.cb-our-tutors-body .tutor-about__tagline {
  font-weight: 500;
  font-size: calc(var(--fs-one) - 2px);
  color: var(--dark-grey);
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}

.tutor-star {
  margin-bottom: 0.15rem;
}
</style>

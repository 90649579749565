<template>
  <div>
    <section class="section faq-wrapper py-5">
         <div class="container">
        <h1 class="text-center light-blue mb-5">Frequently Asked Questions</h1>

        <b-row>
            <b-col>
                <a class="card-link" href="#general_faq">
                     <div class="card shadow-sm p-3 " >
  <img style="width:128px;" class="mx-auto card-img-top" src="../assets/global/FAQ_General.svg">
  <div class="pt-3 text-center">
    <div class="card-title">General</div>
  </div>
</div>
                </a>
            </b-col>
            <b-col>
             <a href="#parentslearners_faq">
                    <div class="card shadow-sm p-3" >
  <img style="width:128px;" class="mx-auto card-img-top" src="../assets/global/FAQ_ParentsLearners.svg">
  <div class="pt-3 text-center">
    <div class="card-title">   Parents 	&#38; Learners</div>
  </div>
                 
                    </div>
                </a>
            </b-col>
            <b-col>
                              
                 <a href="#tutors_faq">
                     <div class="card shadow-sm p-3" >
  <img style="width:128px;" class="mx-auto card-img-top" src="../assets/global/FAQ_Tutor.svg">
  <div class="pt-3 text-center">
    <div class="card-title"> Tutors</div>
  </div>
                 
                    </div>
                   
                </a>
            </b-col>
            <b-col>
                <a href="#paymentsbilling_faq">
                       <div class="card shadow-sm p-3" >
  <img style="width:128px;" class="mx-auto card-img-top" src="../assets/global/FAQ_PaymentsBilling.svg">
  <div class="pt-3 text-center">
    <div class="card-title">   Payments 	&#38; Billing</div>
  </div>
                 
                    </div>
                   
                </a>
            </b-col>
        </b-row>



        <!-- General FAQ -->
        <div id="general_faq" class="py-4">
            <div class="divider font-weight-bold font-size-md text-center dark-grey">General</div>
        </div>

        <div class="accordion">
            <div id="how-is-chalkboard-different-from-other-tutoring-services?">
                <input name="general" type="radio" id="general_1" class="accordion-input">
                <label for="general_1" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How is Chalkboard different from other tutoring services?
                    </h6>
                </label>
                <div class="accordion-content content ">
                    <p>
                        We are the first end-to-end online tutoring platform for learners and tutors. We are a marketplace for advanced and fun learning experiences. We do this by finding high quality tutors, matching them with the learners, and building a firm connection that will flourish through advanced education.
                        <br><br>
                        Aside from being the first to create an end-to-end tutoring service in the Philippines, we make sure we have a high caliber of expert tutors that would assist each learners to their educational growth. We also have our own advanced digital learning tools which will be used by both tutors and learners in our online platform.
                    </p>
                </div>
            </div>

            <div id="how-can-chalkboard-help-me?">
                <input name="general" type="radio" id="general_2" class="accordion-input">
                <label for="general_2" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How can Chalkboard help me?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        <strong>Benefits for parents/learners</strong>
                        <br><br>

                        Here at Chalkboard, we understand that parents need all the help they can get, especially in ensuring their child gets a good education. We understand that every learner has their own ways in grasping ideas. With this, we see to it to create fun and active tutorial sessions with our expert tutors and by using top quality online tools.
                        <br><br>

                        <strong>Benefits for tutors</strong>
                        <br><br>

                        At Chalkboard, we value our tutors by giving them an online platform where they can practice their passion in teaching and at the same time earn at their own pace. We commit to our tutors the flexibility and limitless earnings while at home or anywhere where there’s a stable internet connection. Aside from those, we make sure that you are heard with your suggestions and advocacy in putting out a great quality learning service.

                    </p>
                </div>
            </div>

            <div id="how-do-i-create-an-account?">
                <input name="general" type="radio" id="general_3" class="accordion-input">
                <label for="general_3" class="accordion-label px-3 py-2">
                    <h6 id="create-an-account" class="is-size-5">
                        How do I create an account?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        All you have to do is to follow these easy steps and you are good to go!

                        <ul class="">
                            <li>
                                Go to chalkboard.com.ph
                            </li>
                            <li>
                                Click the Sign Up button
                            </li>
                            <li>
                                Select whether you are a Parent, Learner or Tutor
                            </li>
                            <li>
                                Register using a Facebook account, or via an email address and password (for
                                parents and learners 18 years old and above)

                            </li>
                            <li>
                                Complete your profile information.
                            </li>

                        </ul>
                    </p>
                </div>
            </div>

            <div id="what-are-the-technical-requirements-for-chalkboard?">
                <input name="general" type="radio" id="general_4" class="accordion-input">
                <label for="general_4" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What are the technical requirements for Chalkboard?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        At Chalkboard, we require both learners and tutors with a laptop or a desktop computer, stable internet connection, speakers/audio, and a microphone. We also highly recommend having a webcam and headphones for the live online tutoring session. There’s no need for you to download any outside applications because the online tutorial will happen inside the platform.
                        <br><br>
                        We also recommend that the learner wears headphones for their online learning sessions. Please ensure also that learners are free from any physical distractions such as toys or other gadgets to ensure an optimized learning experience.

                    </p>
                </div>
            </div>

            <div id="what-subjects-are-offered-on-chalkboard?">
                <input name="general" type="radio" id="general_5" class="accordion-input">
                <label for="general_5" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What subjects are offered on Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        As of the moment, we offer K-12 subjects to our learners. But as we progress so does the adding of new subjects, curriculum, courses or level. If you’re interested in a course or level but did not see it as one of our service offerings, please contact us at <a href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a>

                    </p>
                </div>
            </div>

            <div id="do-i-need-to-download-and-install-an-app-to-use-chalkboard?">
                <input name="general" type="radio" id="general_6" class="accordion-input">
                <label for="general_6" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do I need to download and install an app to use Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        There’s no need for you to download anything. You just go to our website <a href="https://chalkboard.com.ph/">https://chalkboard.com.ph/</a> and you can start using Chalkboard.
                    </p>
                </div>
            </div>

            <div id="is-there-a-separate-app-for-the-video-conferencing-tool?-do-i-need-to-have-a-separate-account-for-it?">
                <input name="general" type="radio" id="general_8" class="accordion-input">
                <label for="general_8" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Is there a separate app for the video conferencing tool? Do I need to have a separate account for it?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        There’s no need to install a separate app for the video conferencing tool. It is built-in to the Chalkboard platform and ready for your tutorial sessions.
                    </p>
                </div>
            </div>

            <div id="does-chalkboard-provide-learning-materials?">
                <input name="general" type="radio" id="general_9" class="accordion-input">
                <label for="general_9" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Does Chalkboard provide learning materials?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        The learning materials will be provided by either the tutor or learner. Although, it is our hope to provide valuable educational content for all our users in the near future.
                    </p>
                </div>
            </div>

        </div>

        <!-- Parents Learners FAQ -->
        <div id="parentslearners_faq" class="py-4">
            <div class="divider font-weight-bold font-size-md text-center dark-grey">Parents 	&#38; Learners</div>
        </div>

        <div class="accordion">
            <div id="how-safe-is-chalkboard?-or-is-the-website-child-safe?">
                <input name="parentslearners" type="radio" id="parentslearners_1" class="accordion-input">
                <label for="parentslearners_1" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How safe is Chalkboard? Or Is the website child-safe?
                    </h6>
                </label>
                <div class="accordion-content content ">
                    <p>
                        Here at Chalkboard, we make sure to properly and strictly screen and verify every tutors we get on board. We also have a strong data privacy policy and a firm website security. As an added feature, parents can link their accounts to their child/children 18 years old and below. This way they can be assured each session is secured.
                        <br><br>
                        You may know more about our <a class="light-blue has-text-weight-bold" href="./privacy-policy">Privacy Policy</a> here
                    </p>
                </div>
            </div>

            <div id="what-can-i-do-with-my-parent-account?">
                <input name="parentslearners" type="radio" id="parentslearners_2" class="accordion-input">
                <label for="parentslearners_2" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What can I do with my Parent Account?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        As a Parent, you may sign up for a free parent account any time.
                        <br><br>
                        Once you sign up, you can:
                        <ul>
                            <li>
                                Link your account to your child’s account so you can find a tutor suitable to his/her learning style.
                            </li>
                            <li>
                                Track your sessions and manage your future sessions with the calendar tab on your dashboard.
                            </li>
                            <li>
                                Monitor your child’s progress and performance through special badges given by their Tutors.
                            </li>

                        </ul>
                    </p>
                </div>
            </div>

            <div id="how-do-i-link-my-child’s-account-to-my-account?">
                <input name="parentslearners" type="radio" id="parentslearners_3" class="accordion-input">
                <label for="parentslearners_3" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I link my child’s account to my account?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        On the Parent's dashboard, there is an option to add your children’s profile. This is to ensure that every need of each learner will be properly and separately addressed. You can link up to 4 learner’s accounts.
                    </p>
                </div>
            </div>

            <div id="is-there-an-orientation-given-to-the-parents-and-learners-before-the-start-of-the-tutorial-session?">
                <input name="parentslearners" type="radio" id="Is there an orientation given to the parents and learners before the start of the tutorial session?" class="accordion-input">
                <label for="Is there an orientation given to the parents and learners before the start of the tutorial session?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Is there an orientation given to the parents and learners before the start of the tutorial session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        There is no orientation given to the parents and learners. Video instructions on how to search and
                        book a tutor as well as how to join the session from the dashboard are available on our <a class="light-blue has-text-weight-bold" href="https://www.youtube.com/channel/UCrKCzAeaHwHqaMOTWuBrODQ/">YouTube
                            channel. </a> </p>
                </div>
            </div>

            <div id="can-parents-join-the-tutee-during-the-tutorial-session?">
                <input name="parentslearners" type="radio" id="Can parents join the tutee during the tutorial session?" class="accordion-input">
                <label for="Can parents join the tutee during the tutorial session?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can parents join the tutee during the tutorial session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. There may be situations where parents can also join the session to provide support or
                        assistance to their children. </p>
                </div>
            </div>

            <div id="how-do-you-ensure-quality?">
                <input name="parentslearners" type="radio" id="parentslearners_4" class="accordion-input">
                <label for="parentslearners_4" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do you ensure quality?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard is all about giving great quality online education, focusing on passion and hard work yet enjoying academics. All of our expert tutors have strong credentials, integrity, credibility, and understanding. They are strictly screened by our highly Talent Acquisition Team. They are not just tutors but also mentors who could also help every learner’s interaction and reaction to the real world.
                        <br><br>
                        In addition to this, you may read feedback from parents who used Chalkboard to help you make your decision. <a class="light-blue has-text-weight-bold" href="./parents">Click here to see what moms are saying about Chalkboard.</a>

                    </p>
                </div>
            </div>

            <div id="how-are-tutors-screened?">
                <input name="parentslearners" type="radio" id="parentslearners_5" class="accordion-input">
                <label for="parentslearners_5" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How are tutors screened?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We have a strict observance of our recruitment process; we make sure to set personal interviews
                        and require candidates to secure valid documents. We also do a background check to validate
                        information given by our Tutors.
                    </p>
                </div>
            </div>

            <div id="what-if-the-subject-i-want-is-not-listed?">
                <input name="parentslearners" type="radio" id="parentslearners_6" class="accordion-input">
                <label for="parentslearners_6" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What if the subject I want is not listed?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        At Chalkboard, we love to hear about your suggestions. You may email us at <a class="light-blue has-text-weight-bold" href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a> and let us know if there are subjects that you would love to have in our platform. We hope to have more subjects for your child in the future.
                    </p>
                </div>
            </div>

            <div id="what-is-the-difference-between-hourly-and-bundle-sessions?">
                <input name="parentslearners" type="radio" id="parentslearners_7" class="accordion-input">
                <label for="parentslearners_7" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What is the difference between Hourly and Bundle sessions (coming soon)?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        For Hourly Sessions, you can book at least 1 hour and 3 hours maximum. And as for the Bundle
                        Sessions, you can choose from at least 12 sessions to a maximum of 100 sessions, with big
                        discounts available for you. Find out here which <a class="light-blue has-text-weight-bold" href="./pricing">plan</a> is best for you and your child.
                        <br><br>
                        For Grades 1 to 6, a bundle session may be booked with the same tutor for all the sessions. You
                        may also book different subject/s. <br><br>
                        For Grades 7 to 12, a bundle is for the same tutor, and SUBJECT, for all sessions.
                    </p>
                </div>
            </div>

            <div id="can-i-have-2-or-more-subjects-in-a-bundle?">
                <input name="parentslearners" type="radio" id="parentslearners_8" class="accordion-input">
                <label for="parentslearners_8" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I have 2 or more subjects in a Bundle?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You may refer to our <a class="light-blue has-text-weight-bold" href="./pricing">Hourly and Bundle sessions</a>
                    </p>
                </div>
            </div>

            <div id="how-do-i-get-a-tutor?">
                <input name="parentslearners" type="radio" id="parentslearners_9" class="accordion-input">
                <label for="parentslearners_9" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I get a tutor?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Get a Tutor at Chalkboard with these easy steps. First, log in to your Chalkboard account. If you don’t have an account yet please refer to <a class="light-blue has-text-weight-bold" href="#create-an-account">How do I create an account?</a>
                        Go to the search bar, type in keywords for your search such as grade level or subject matter, filter the list of tutors on the results page, and send a session request.
                        <br><br>
                        After that you may schedule your session. You can also choose recommended Tutors based on your search.

                    </p>
                </div>
            </div>

            <div id="can-i-work-with-my-favorite-tutor-again?">
                <input name="parentslearners" type="radio" id="parentslearners_10" class="accordion-input">
                <label for="parentslearners_10" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I work with my favorite tutor again?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. You can mark your tutor as “My Favorite Tutor” and keep a list of your most favorite tutors.

                    </p>
                </div>
            </div>

            <div id="what-if-my-tutor-is-not-able-to-help-me?">
                <input name="parentslearners" type="radio" id="What if my tutor is not able to help me?" class="accordion-input">
                <label for="What if my tutor is not able to help me?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What if my tutor is not able to help me?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Here at Chalkboard, we make sure to give you the best online learning experience you deserve.
                        However, if ever there are circumstances that you may not like, or you feel that your tutor is not
                        fit to your liking you may contact our <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?"> Customer Service</a>.
                    </p>
                </div>
            </div>

            <div id="my-tutor-did-not-show-up-on-our-scheduled-session-what-do-i-do?">
                <input name="parentslearners" type="radio" id="parentslearners_11" class="accordion-input">
                <label for="parentslearners_11" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        My tutor did not show up on our scheduled session. What do I do?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We take the professionalism of our tutors seriously, but we understand that sometimes, unexpected situations can arise. If you are having any problems with your tutor, please contact us at <a class="light-blue has-text-weight-bold" href="mailto: support@chalkboard.com.ph">support@chalkboard.com.ph</a>.

                    </p>
                    <p>
                        You may reach us through the following channels:
                        <ul>
                            <li>
                                Our Customer Support hours are between 7:00 AM to 11:00 PM, Monday to Sunday.
                            </li>
                            <li>
                                Globe: 0927 454 5406 (SMS and Whatsapp)
                            </li>
                            <li>
                                Smart: 0908-810-7090 (Text and Viber)
                            </li>
                            <li>
                                Email: support@chalkboard.com.ph
                            </li>
                            <li>
                                Facebook Page: https://www.facebook.com/thechalkboardinc/
                            </li>
                            <li>
                                Phone call Support - Monday - Friday, 8:00 AM - 5:00 PM
                            </li>
                            <li>
                                Email, Text, Messaging Apps, Facebook Chat Support - Monday - Sunday, 7:00 AM - 11:00 PM
                            </li>
                        </ul>
                    </p>
                </div>
            </div>

            <div id="my-tutor-is-late-what-do-i-do?">
                <input name="parentslearners" type="radio" id="parentslearners_12" class="accordion-input">
                <label for="parentslearners_12" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        My tutor is late. What do I do?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        If the tutor is late for 15 minutes, please contact our <a class="light-blue has-text-weight-bold" href="tel:+63 945-5525997">Customer Service</a> if you need an urgent replacement or reschedule the session.
                    </p>
                </div>
            </div>

            <div id="what-happens-if-a-tutor-cancels-a-session?">
                <input name="parentslearners" type="radio" id="parentslearners_13" class="accordion-input">
                <label for="parentslearners_13" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What happens if a tutor cancels a session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We understand that sometimes, unexpected events can arise. In such cases, you will be informed immediately and be given the opportunity to reschedule with the same tutor or choose a different tutor if you need an urgent replacement.
                    </p>
                </div>
            </div>

            <div id="can-i-leave-a-review-for-a-tutor?">
                <input name="parentslearners" type="radio" id="parentslearners_14" class="accordion-input">
                <label for="parentslearners_14" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I leave a review for a tutor?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes, you can choose to provide ratings and comments. Here at Chalkboard, we encourage giving feedback to our Tutors so we can help other parents in finding the best tutors for their child. Furthermore, feedback also gives our Tutors opportunities for improvement. </p>
                </div>
            </div>

            <div id="what-if-my-child-is-already-late-for-the-scheduled-session?">
                <input name="parentslearners" type="radio" id="parentslearners_15" class="accordion-input">
                <label for="parentslearners_15" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What if my child is already late for the scheduled session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        If you are late, the Tutor will still wait for you in the online session. If you do join late, please be aware the session will still conclude at the scheduled time and you will still be charged in full.
                </p>
                </div>
            </div>

            <div id="what-should-i-do-if-i-won’t-be-able-to-take-the-planned-lesson?">
                <input name="parentslearners" type="radio" id="parentslearners_16" class="accordion-input">
                <label for="parentslearners_16" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What should I do if I won’t be able to take the planned lesson?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You can reschedule your upcoming session at least 48 hours before the schedule through
                        the platform. Otherwise, you can contact <a class="light-blue has-text-weight-bold" href="tel: +63 945-5525997">Customer Service</a> to help you reschedule the
                        sessions.
                    </p>
                </div>
            </div>

            <div id="how-long-is-the-session-can-we-extend-a-session?">
                <input name="parentslearners" type="radio" id="parentslearners_17" class="accordion-input">
                <label for="parentslearners_17" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How long is the session? Can we extend a session?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        A session is a 50 to 55-minute 1-to-1 online tutoring. If the learner needs more time completing
                        the lesson, he/she can book for another hour. The platform is still being developed to
                        accommodate extension requests so a learner can focus more on the tutorial instead of spending
                        time booking another session.
                    </p>
                </div>
            </div>

            <div id="can-you-choose-a-tutor-for-me?">
                <input name="parentslearners" type="radio" id="parentslearners_18" class="accordion-input">
                <label for="parentslearners_18" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can you choose a tutor for me?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We made the platform easy for you to choose and book a tutor. However, if you’re still
                        finding booking a tutor difficult, please contact our <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?">Customer Service</a>.
</p>
                </div>
            </div>

            <div id="how-can-i-find-tutors-who-conduct-in-person-lessons?">
                <input name="parentslearners" type="radio" id="parentslearners_19" class="accordion-input">
                <label for="parentslearners_19" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How can I find tutors who conduct in-person lessons?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Currently, we only offer 1-on-1 online tutoring.
                    </p>

                </div>
            </div>

            <div id="how-quickly-does-the-tutor-respond?">
                <input name="parentslearners" type="radio" id="parentslearners_20" class="accordion-input">
                <label for="parentslearners_20" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How quickly does the tutor respond?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Typical response time of a tutor may vary and is reflected on the tutor’s profile. Each session request expires after 24 hours without acknowledgement from the tutor. You can send another session request to a different tutor. </p>
                </div>
            </div>

            <div id="can-two-students-share-a-tutor?">
                <input name="parentslearners" type="radio" id="parentslearners_21" class="accordion-input">
                <label for="parentslearners_21" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can two students share a tutor?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We hope to have this feature included in the platform in the near future. For now we offer one-on-one online tutorial sessions.
                    </p>
                </div>
            </div>

            <div id="can-i-switch-to-a-different-tutor-if-i-get-bundle-sessions?-">
                <input name="parentslearners" type="radio" id="parentslearners_22" class="accordion-input">
                <label for="parentslearners_22" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I switch to a different tutor if I get Bundle sessions?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Our system only allows one Tutor for each Bundle Session. If you need two tutors, we suggest you book sessions with them separately. Should you have any issues or concerns with your Tutor, please contact our <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?">Customer Service. </a>
                    </p>
                </div>
            </div>

            <div id="do-you-have-tutors-that-specialize-in-helping-children-with-learning-disabilities?">
                <input name="parentslearners" type="radio" id="parentslearners_23" class="accordion-input">
                <label for="parentslearners_23" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do you have tutors that specialize in helping children with learning disabilities?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        It is our goal that no child will be left uneducated, whether they have disabilities or not. It is our commitment and our hope to make this service available in our platform in the future.
                    </p>
                </div>
            </div>

            <div id="can-we-pay-bundle-sessions-in-installments?">
                <input name="parentslearners" type="radio" id="Can we pay bundle sessions in installments" class="accordion-input">
                <label for="Can we pay bundle sessions in installments" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can we pay bundle sessions in installments?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        At the moment, installment payments are not supported by the platform. We are making
                        improvements to our product so we can serve you better. We will look into this with our third-
                        party payment processor so we can include this payment option in the future. </p>
                </div>
            </div>

            
            <div id="how-do-i-contact-customer-support?">
                <input name="general" type="radio" id="general_10" class="accordion-input">
                <label for="general_10" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I contact Customer Support?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We are always happy to serve. For any suggestions, clarifications, or complaints you may reach us through the following channels:
                        <ul>
                            <li>
                                Our Customer Support hours are between 7:00 AM to 11:00 PM, Monday to Sunday.
                            </li>
                            <li>
                                Globe: 0927 454 5406 (SMS and Whatsapp)
                            </li>
                            <li>
                                Smart: 0908-810-7090 (Text and Viber)
                            </li>
                            <li>
                                Email: support@chalkboard.com.ph
                            </li>
                            <li>
                                Facebook Page: https://www.facebook.com/thechalkboardinc/
                            </li>
                            <li>
                                Phone call Support - Monday - Friday, 8:00 AM - 5:00 PM
                            </li>
                            <li>
                                Email, Text, Messaging Apps, Facebook Chat Support - Monday - Sunday, 7:00 AM - 11:00 PM
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>


        <!-- Tutors FAQ -->
        <div id="tutors_faq" class="py-4">
            <div class="divider font-weight-bold font-size-md text-center dark-grey">Tutors</div>
        </div>

        <div class="accordion">
            <div id="what-is-expected-from-me-in-the-session?">
                <input name="tutors" type="radio" id="What is expected from me in the session?" class="accordion-input">
                <label for="What is expected from me in the session?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What is expected from me in the session?
                    </h6>
                </label>
                <div class="accordion-content content ">
                    
                        Here at Chalkboard, we want each online session to be as close, if not better than in-person
                        tutorials. Here are our guidelines on Service Etiquette and Online Tutorial House Rules

                        <div class="has-text-centered"><strong>Service Etiquette</strong></div>
                        <ul>
                            <li>
                                I understand that my role as a tutor is to assist tutees in developing learner independence
                                by helping them develop the skills necessary to achieve their most effective and efficient
                                learning.
                            </li>
                            <li>
                                I undertake never to do a tutee’s work for him or her.
                            </li>
                            <li>
                                I understand that my relationship to the student is professional rather than personal, and I
                                will avoid establishing inappropriate relationships with tutees.
                            </li>

                            <li>
                                I will respect every tutee’s uniqueness and personal dignity at all times by accepting him or
                                her without judgment.
                            </li>
                            <li>
                                I will respect, and will work to improve my sensitivity to, tutees’ cultural, individual and
                                role differences, including those based on age, gender, sexual orientation, race, ethnicity,
                                culture, national origin, religion, disability, language and socioeconomic status.
                            </li>
                            <li>
                                I recognize the existence of individual learning styles and will remain flexible in my
                                approach to student learning.
                            </li>
                            <li>
                                Recognizing that I may not be able to meet all of the needs of tutees, I will be willing to seek
                                assistance from more qualified employees and/or to direct tutees to appropriate resources
                                when tutees’ needs exceed the boundaries of my competence and expertise.
                            </li>
                            <li>
                                I will fulfill all my commitments to tutees - including those relating to punctuality and
                                attendance - out of courtesy and so as to be a good example for my tutees.
                            </li>
                            <li>
                                I will establish a safe environment where students feel comfortable to ask questions and
                                admit weaknesses.
                            </li>
                            <li>
                                I will not tutor any form of tests, including take-home tests/essays
                                I will keep all information about tutees confidential.
                            </li>
                            <li>
                                When on duty as a tutor, I will conduct myself in a professional manner.
                            </li>
                            <li>
                                I will act professionally about my colleagues by never commenting negatively on other
                                tutors, whether on his/her teaching style and strategies.
                            </li>
                            <li>
                                I will keep current in both my subject area(s) and learning methodologies.
                            </li>
                        </ul>
                        <div class="has-text-centered my-4"><strong>Online Tutorial House Rules</strong></div>
                        <ul>
                            <li>
                                Look presentable to your learners. Dress comfortably but professionally.
                            </li>
                            <li>
                                Make sure you have good lighting.
                            </li>
                            <li>
                                Be on your best angle and maintain proper distance from the camera so your shoulders
                                and your entire face are visible.
                            </li>
                            <li>
                                Choose a fine and distraction-free teaching environment.
                            </li>
                            <li>
                                Your video must be always ON for the entire session.
                            </li>
                            <li>
                                Inform your learners if you need to step out of the camera.
                            </li>
                            <li>
                                Speak clearly.
                            </li>
                            <li>
                                Be an active listener.
                            </li>
                            <li>
                                Use available resources such as props or visual aids for effective delivery of lessons.
                            </li>
                            <li>
                                Be genuinely confident.
                            </li>
                            <li>
                                Have an in-depth knowledge of the subject matter you are asked to teach and be able to
                                present it in a fun and engaging manner.
                            </li>
                            <li>
                                You are expected to be friendly, understanding and patient with your students.
                            </li>
                            <li>
                                Prepare your topics in advance.
                            </li>
                            <li>
                                Have a fast and reliable internet connection. Choosing a learning space that is well-lit and free from ambient noise.
                            </li>
                            <li>
                                To be always on time for each tutorial session.
                            </li>
                        </ul>
                    
                </div>
            </div>

            <div id="how-many-subjects-can-we-teach?">
                <input name="tutors" type="radio" id="How many subjects can we teach" class="accordion-input">
                <label for="How many subjects can we teach" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How many subjects can we teach?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You can teach as many subjects as you can. However, it is recommended to just choose the
                        subjects that you are very much comfortable teaching so you and your learner can have the best
                        tutorial experience. </p>
                </div>
            </div>

            <div id="do-we-set-our-own-preferred-schedule-are-we-allowed-to-change-our-schedule?">
                <input name="tutors" type="radio" id="Do we set our own preferred schedule? Are we allowed to change our schedule?" class="accordion-input">
                <label for="Do we set our own preferred schedule? Are we allowed to change our schedule?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do we set our own preferred schedule? Are we allowed to change our schedule? </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. When you register on the platform and create your profile, you need to set your availability.
                        You can choose to set your availability between 7:00am to 10:00pm. This will be reflected on your
                        calendar and will be seen by the parents and learners. You can change your availability any time in
                        the platform, but those sessions booked prior to your change will not be affected.
                    </p>
                </div>
            </div>

            <div id="can-filipino-teachers-abroad-sign-up-with-chalkboard?">
                <input name="tutors" type="radio" id="Can Filipino teachers abroad sign up with Chalkboard?" class="accordion-input">
                <label for="Can Filipino teachers abroad sign up with Chalkboard?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can Filipino teachers abroad sign up with Chalkboard?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard will initially offer its platform to our local teachers and learners. We are still reviewing
                        possible legal implications of opening the platform outside the Philippines based on our laws and
                        regulations. </p>
                </div>
            </div>

            <div id="is-it-required-to-complete-our-profile-before-we-can-accept-session-requests?">
                <input name="tutors" type="radio" id="Is it required to complete our profile before we can accept session requests?" class="accordion-input">
                <label for="Is it required to complete our profile before we can accept session requests?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Is it required to complete our profile before we can accept session requests?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. You need to sign up using a valid email address and complete your profile before you can be
                        scheduled for an interview/teaching demo by our Talent Acquisition Team. Tutors who pre-
                        applied (before the website is launched) and already passed the screening also need to sign up and
                        build their profile before they can be searched on the platform.
                    </p>
                </div>
            </div>

            <div id="how-much-does-a-tutor-earn-at-chalkboard?">
                <input name="tutors" type="radio" id="How much does a Tutor earn at Chalkboard?" class="accordion-input">
                <label for="How much does a Tutor earn at Chalkboard?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How much does a Tutor earn at Chalkboard?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Tutor earnings depend on how much time they devote to tutoring. Hard work also plays a major
                        part in determining how much a Tutor can earn.
                    </p>
                </div>
            </div>

            <div id="can-we-set-different-hourly-rates-depending-on-the-difficulty-of-the-subjects-and-grade-levels?">
                <input name="tutors" type="radio" id="Can we set different hourly rates depending on the difficulty of the subjects and grade levels?" class="accordion-input">
                <label for="Can we set different hourly rates depending on the difficulty of the subjects and grade levels?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can we set different hourly rates depending on the difficulty of the subjects and grade levels? </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Currently, the hourly rate that you will declare during your registration on the website will apply
                        to all grade levels and subjects that you will choose to teach.
                    </p>
                </div>
            </div>

            <div id="are-there-any-required-banks-for-the-payout?">
                <input name="tutors" type="radio" id="Are there any required banks for the payout?" class="accordion-input">
                <label for="Are there any required banks for the payout?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Are there any required banks for the payout? </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You can nominate your bank account/s from any major local bank. Chalkboard can also send
                        payouts through e-wallets and some money remittance centers. The complete list will be available
                        on the “Bank Account Nomination” form that we will be sending you. Please fill this out and make
                        sure that you send this back to us before your first cut off schedule.
                        <br><br>
                        For a list of payout channels under Dragonpay, you may visit this <a class="light-blue has-text-weight-bold" href="https://www.dragonpay.ph/mass-payout/">page</a>.
                    </p>
                </div>
            </div>


            <div id="how-is-money-credited-to-the-tutor’s-account?">
                <input name="tutors" type="radio" id="tutors_2" class="accordion-input">
                <label for="tutors_2" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How is money credited to the tutor’s account?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        For every tutorial session, payment is guaranteed. The payment will be credited to your account once the session is completed. You can access your wallet and monitor your earnings through your dashboard
                    </p>
                </div>
            </div>

            <div id="how-do-i-request-my-payout?">
                <input name="tutors" type="radio" id="tutors_3" class="accordion-input">
                <label for="tutors_3" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I request my payout?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        There’s no need for you to request your payout. Payouts are automatically credited to your chosen bank account every 15th and 30th of the month. If you have any questions on your payment disbursement, please contact our <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?"> Customer Service</a>.
                    </p>
                </div>
            </div>

            <div id="at-what-times-should-i-make-myself-available-for-tutoring?">
                <input name="tutors" type="radio" id="tutors_4" class="accordion-input">
                <label for="tutors_4" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        At what times should I make myself available for tutoring?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        We expect that a significant number of our learners will be requesting tutors once a week before exams. The advantage on your part, is that you have the flexibility to choose the exact time and days you would like to tutor at Chalkboard. The more bookings you have, the more earnings you get. Please make sure that you are available for the schedule that you set.

                    </p>
                </div>
            </div>

            <div id="will-the-tutees-see-our-schedule-of-sessions?">
                <input name="tutors" type="radio" id="Will the tutees see our schedule of sessions?" class="accordion-input">
                <label for="Will the tutees see our schedule of sessions?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Will the tutees see our schedule of sessions?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. The learners as well as the tutors can see their session schedule on their respective calendar
                        available in the platform.
                    </p>
                </div>
            </div>

            <div id="is-there-an-expiration-for-session-requests?">
                <input name="tutors" type="radio" id="Is there an expiration for session requests?" class="accordion-input">
                <label for="Is there an expiration for session requests?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Is there an expiration for session requests?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. All unacknowledged session requests sent to the Tutors will expire after 24 hours.

                    </p>
                </div>
            </div>

            <div id="how-do-we-know-if-we-have-a-session-request?">
                <input name="tutors" type="radio" id="How do we know if we have a session request?" class="accordion-input">
                <label for="How do we know if we have a session request?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do we know if we have a session request?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Tutors will be notified immediately via e-mail for any session request made by the parents and
                        learners. An SMS will also be sent to your mobile phones for these session requests. You will also
                        see this information on your dashboard.
                    </p>
                </div>
            </div>

            <div id="are-we-going-to-receive-information-such-as-learner’s-grade-level-and-the-topics-to-be-discussed-before-we-conduct-the-tutorial-session?">
                <input name="tutors" type="radio" id="Are we going to receive information such as learner’s grade level and the topics to be discussed before we conduct the tutorial session?" class="accordion-input">
                <label for="Are we going to receive information such as learner’s grade level and the topics to be discussed before we conduct the tutorial session?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Are we going to receive information such as learner’s grade level and the topics to be discussed
                        before we conduct the tutorial session?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. Learner’s information such as grade level will be available on the session request. Also,
                        parents and learners can send a message to the tutor before confirming the session to give the
                        tutor an idea on the topics that the learners need help with.
                    </p>
                </div>
            </div>

            <div id="are-we-going-to-provide-learning-resources-or-references-to-use?">
                <input name="tutors" type="radio" id="Are we going to provide learning resources or references to use?" class="accordion-input">
                <label for="Are we going to provide learning resources or references to use?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Are we going to provide learning resources or references to use?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You can use your own learning materials and props if you think that would help you with your
                        lesson delivery and make the online tutoring more effective. Chalkboard will not be providing any
                        learning materials and the tutors will have to create them on their own.
                    </p>
                </div>
            </div>

            <div id="what-do-i-need-to-confirm-before-i-start-a-session-with-students?">
                <input name="tutors" type="radio" id="tutors_5" class="accordion-input">
                <label for="tutors_5" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What do I need to confirm before I start a session with students?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        When a learner sends a session request, you will see the learner’s:
                        <ul>
                            <li>
                                Grade Level
                            </li>
                            <li>
                                Subject Matter
                            </li>
                            <li>
                                Specific Topic
                            </li>
                            <li>
                                Additional description or request from the Parent or Learner regarding the tutorial session.
                            </li>
                        </ul>

                    </p>
                </div>
            </div>
            <div id="is-there-any-requirement-regarding-the-teaching-environment?">
                <input name="tutors" type="radio" id="tutors_6" class="accordion-input">
                <label for="tutors_6" class="accordion-label px-3 py-2">
                     <h6 class="is-size-5">
                         Is there any requirement regarding the teaching environment?
                         
                    </h6>
                </label>
                <div class="accordion-content content">
                    <p>
                        Teaching environment is particularly important in an online tutorial session. There is no
                        requirement, but we do have recommendations written on our <a class="light-blue has-text-weight-bold" href="./online-tutorial-house-rules">Online Tutorial House Rules</a>. These are discussed during the tutors’ onboarding and will be available on the website. You may also refer to our <a class="light-blue has-text-weight-bold" href="./service-etiquette">Service Etiquette</a> for guidance on your online tutorial lessons.
                    </p>
                </div>
            </div>
            <div id="do-we-need-to-submit-feedback-or-assessment-to-the-learners-about-his/her-progress?">
                <input name="tutors" type="radio" id="Do we need to submit feedback or assessment to the learners about his/her progress?" class="accordion-input">
                <label for="Do we need to submit feedback or assessment to the learners about his/her progress?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do we need to submit feedback or assessment to the learners about his/her progress?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard is currently developing Scribook – an e-notebook for learners that is accessible on
                        their dashboard. Tutors can use this tool to log all the lessons covered during their tutorial session.
                        Tutors can also write their assessment of the learner’s progress on this e-notebook.
                    </p>
                </div>
            </div>

            <div id="can-we-show-our-profile-on-our-social-media-accounts?">
                <input name="tutors" type="radio" id="Can we show our profile on our social media accounts?" class="accordion-input">
                <label for="Can we show our profile on our social media accounts?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can we show our profile on our social media accounts?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        No. Your profile will only be available inside the Chalkboard platform. However, we may feature
                        some tutors on the website and on our social media channels.
                    </p>
                </div>
            </div>

            <div id="can-we-post-screenshots-of-actual-classes-in-social-media-to-promote-and-get-more-learners-providing-that-we-keep-the-identity-of-the-student?">
                <input name="tutors" type="radio" id="Can we post screenshots of actual classes in social media to promote and get more learners
                        providing that we keep the identity of the student?" class="accordion-input">
                <label for="Can we post screenshots of actual classes in social media to promote and get more learners
                        providing that we keep the identity of the student?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can we post screenshots of actual classes in social media to promote and get more learners
                        providing that we keep the identity of the student?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You can post screenshots of the actual session to your social media to help you market your profile
                        more. However, you need to make sure that the identity of your learner is kept hidden for privacy
                        purposes.
                    </p>
                </div>
            </div>

            <div id="what-if-the-booked-session-would-not-be-enough-to-cover-all-the-lessons-requested-by-the-parent-or-learner?">
                <input name="tutors" type="radio" id="What if the booked session would not be enough to cover all the lessons requested by the Parent or Learner?" class="accordion-input">
                <label for="What if the booked session would not be enough to cover all the lessons requested by the Parent or Learner?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What if the booked session would not be enough to cover all the lessons requested by the
                        Parent or Learner?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        The Tutor has the option to either accept or decline a session request. Alternatively, the Tutor can
                        accept the session request and inform the Parent or Learner at the onset that given the scope and
                        complexity of the lessons, one session would not be enough and it is best that some of the topics
                        are tackled on a separate tutoring session/s.
                    </p>
                </div>
            </div>

            <div id="if-the-session-is-temporarily-cut-due-to-power-interruptions-and-internet-connection-issues,-does-the-platform-allow-the-tutor-to-join-the-session-again?">
                <input name="tutors" type="radio" id="If the session is temporarily cut due to power interruptions and internet connection issues, does the platform allow the tutor to join the session again?" class="accordion-input">
                <label for="If the session is temporarily cut due to power interruptions and internet connection issues, does the platform allow the tutor to join the session again?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        If the session is temporarily cut due to power interruptions and internet connection issues, does
                        the platform allow the tutor to join the session again?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        It is strongly advised that tutors report these kinds of technical problems to our <a href="#how-do-i-contact-customer-support?" class="light-blue has-text-weight-bold">Customer
                            Support</a> so the parents and learners can be notified about the situation. Tutors can always join the
                        session again once these technical problems are resolved and the session is still in progress.
                    </p>
                </div>
            </div>

            <div id="what-if-the-learner-did-not-show-up-in-the-session-is-there-any-grace-period-given-to-the-learner-before-we-can-declare-him/her-absent?">
                <input name="tutors" type="radio" id="What if the learner did not show up in the session? Is there any grace period given to the learner before we can declare him/her absent?" class="accordion-input">
                <label for="What if the learner did not show up in the session? Is there any grace period given to the learner before we can declare him/her absent?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What if the learner did not show up in the session? Is there any grace period given to the learner
                        before we can declare him/her absent?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard does not penalize learners for being late or absent. The learner can join the video
                        conferencing any time within the session schedule so you should always be in the video
                        conferencing until the session completes.
                    </p>
                </div>
            </div>

            <div id="will-i-receive-technical-support-if-i-face-any-issues-during-my-sessions?">
                <input name="tutors" type="radio" id="tutors_8" class="accordion-input">
                <label for="tutors_8" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Will I receive technical support if I face any issues during my sessions?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. If you face any technical issues, you can get help by contacting <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?">Customer Service</a>.
                    </p>
                </div>
            </div>

            <div id="do-we-set-our-own-hourly-rate?-can-we-change-it-in-the-future?">
                <input name="tutors" type="radio" id="tutors_9" class="accordion-input">
                <label for="tutors_9" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do we set our own hourly rate? Can we change it in the future?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes. As a tutor, you can set your hourly rate. You can also change this anytime by editing your
                        profile found on your dashboard. However, the new hourly rate will only apply on sessions booked
                        after the change.
                    </p>
                </div>
            </div>

            <div id="how-do-i-begin-a-session?">
                <input name="tutors" type="radio" id="tutors_10" class="accordion-input">
                <label for="tutors_10" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I begin a session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You just have to log into your Tutor account and you will see your upcoming sessions on your dashboard. Simply click the link to the session and you may start your online tutorial session.

                    </p>
                </div>
            </div>

            <div id="is-there-a-sign-up-fee-at-chalkboard?">
                <input name="tutors" type="radio" id="tutors_11" class="accordion-input">
                <label for="tutors_11" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Is there a sign-up fee at Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Here at Chalkboard we do not charge any sign-up fees.

                    </p>
                </div>
            </div>

            <div id="can-i-tutor-students-outside-chalkboard?">
                <input name="tutors" type="radio" id="tutors_12" class="accordion-input">
                <label for="tutors_12" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I tutor students outside Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes, you can have other tutorial sessions outside Chalkboard. Any tutoring you do through Chalkboard is independent of tutorial sessions you do outside of Chalkboard. However, all tutorial sessions that you provide to learners you find through Chalkboard must always be done within the platform.
                    </p>
                </div>
            </div>

            <div id="can-we-chat-with-the-parent-or-learner-outside-the-platform?">
                <input name="tutors" type="radio" id="Can we chat with the parent or learner outside the platform?" class="accordion-input">
                <label for="Can we chat with the parent or learner outside the platform?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can we chat with the parent or learner outside the platform?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes, you can have other tutorial sessions outside Chalkboard. Any tutoring you do through Chalkboard is independent of tutorial sessions you do outside of Chalkboard. However, all tutorial sessions that you provide to learners you find through Chalkboard must always be done within the platform.
                    </p>
                </div>
            </div>

            <div id="a-student-is-asking-for-contact-information-or-suggesting-to-deal-outside-the-platform.-what-should-i-do?">
                <input name="tutors" type="radio" id="A student is asking for contact information or suggesting to deal outside the platform. What should I do?" class="accordion-input">
                <label for="A student is asking for contact information or suggesting to deal outside the platform. What should I do?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        At Chalkboard, we uphold the value of honesty and integrity. We advise that Tutors not only teach
                        but also instill these values to our young learners.

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Yes, you can have other tutorial sessions outside Chalkboard. Any tutoring you do through Chalkboard is independent of tutorial sessions you do outside of Chalkboard. However, all tutorial sessions that you provide to learners you find through Chalkboard must always be done within the platform.
                    </p>
                </div>
            </div>


            <div id="what-should-i-do-if-i-will-not-be-able-to-show-up-for-the-scheduled-session?">
                <input name="tutors" type="radio" id="What should I do if I will not be able to show up for the scheduled session?" class="accordion-input">
                <label for="What should I do if I will not be able to show up for the scheduled session?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What should I do if I will not be able to show up for the scheduled session?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You may contact our <a href="#how-do-i-contact-support" class="light-blue has-text-weight-bold">Customer Support</a> at least 4 hours before the scheduled session. </p>
                </div>
            </div>

            <div id="am-i-allowed-to-make-changes-on-my-profile?">
                <input name="tutors" type="radio" id="tutors_15" class="accordion-input">
                <label for="tutors_15" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Am I allowed to make changes on my profile?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Our Talent Acquisition Team manually screens and verifies each new tutor. During registration, you will see the level of completeness of your profile. It is important for us that you provide information in your profile with consistency and credibility. This is why all important changes on your profile will have an approval from our side. Please contact <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?">Customer Service</a> if you wish to make changes on your profile.
                    </p>
                </div>
            </div>

            <div id="how-can-i-close-my-account?">
                <input name="tutors" type="radio" id="tutors_16" class="accordion-input">
                <label for="tutors_16" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How can I close my account?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You may delist yourself by deactivating your account on your Tutor dashboard.
                    </p>
                </div>
            </div>

            <div id="if-we-encounter-a-learner-showing-uncalled-for-behavior-during-the-tutorial-session,-what-should-i-do?">
                <input name="tutors" type="radio" id="If we encounter a learner showing uncalled for behavior during the tutorial session, what should I do?" class="accordion-input">
                <label for="If we encounter a learner showing uncalled for behavior during the tutorial session, what should I do?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        If we encounter a learner showing uncalled for behavior during the tutorial session, what should
                        I do?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        As a Chalkboard Tutor, we encourage you to adapt to different types of learners and adjust
                        according to their learning style. Here at Chalkboard, we understand that each learner is different
                        from the next, so it is essential that Tutors also understand this.
                    </p>
                </div>
            </div>

            <div id="does-chalkboard-have-an-employment-contract-with-the-tutors?">
                <input name="tutors" type="radio" id="Does Chalkboard have an employment contract with the tutors?" class="accordion-input">
                <label for="Does Chalkboard have an employment contract with the tutors?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Does Chalkboard have an employment contract with the tutors?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Tutors do not have an employment contract with Chalkboard. Tutors are treated as independent
                        contractors in the platform.
                    </p>
                </div>
            </div>

            <div id="do-i-need-to-sign-any-contract-or-bonds?">
                <input name="tutors" type="radio" id="tutors_19" class="accordion-input">
                <label for="tutors_19" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do I need to sign any contract or bonds?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        You must agree with Chalkboard’s Terms and Conditions upon signing up.
                    </p>

                </div>
            </div>

            <div class="font-weight-bold font-size-md text-center bg-white py-4"><span class="dark-grey">TUTOR APPLICATION PROCESS</span>
            </div>


            <div id="how-do-i-become-a-chalkboard-tutor?">
                <input name="tutors" type="radio" id="tutors_20" class="accordion-input">
                <label for="tutors_20" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How do I become a Chalkboard tutor?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        To become a Tutor at Chalkboard, it takes only a few steps and you’re ready to earn.
                        <ul type="1">
                            <li>
                                Sign-up. Create your profile. Fill in the necessary information and always remember to be sincere in creating your profile. You will also be asked to upload the necessary documents to proceed.
                            </li>
                            <li>
                                Get Screened. After successfully creating your profile and uploading your documents, you will be scheduled for an interview with our Screening Specialists. You will be asked to prepare a 5-10 minute teaching demo.
                            </li>
                            <li>
                                Get Verified. You may also submit additional security documents to get your account Verified.
                            </li>
                            <li>
                                Start Earning. Once you’ve passed the Tutor Application Process, your profile may now be searched by parents and learners looking for Tutors.
                            </li>

                        </ul>
                    </p>

                </div>
            </div>


            <div id="what-are-the-requirements-to-apply-as-a-tutor?">
                <input name="tutors" type="radio" id="What are the requirements to apply as a tutor?" class="accordion-input">
                <label for="What are the requirements to apply as a tutor?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What are the requirements to apply as a tutor?
                    </h6>

                </label>
                <div class="accordion-content content ">

                    <ol class="my-4" type="A">
                        <li>For Bachelor’s Degree:
                            <ol type="1">
                                <li>
                                    Resume or CV </li>
                                <li>
                                    Any government issued ID (SSS, UMID, BIR, GSIS, LTO, Postal ID) </li>
                                <li>
                                    Any of the following (Transcript of Records, Board Rating, Diploma) </li>
                                <li>
                                    Certificate of Employment (if applicable)
                                </li>
                                <li>
                                    Training Certificates (if available)
                                </li>

                            </ol>
                        </li>
                        <li>For Practice Teacher/Peer Tutor
                            <ol type="1">
                                <li>
                                    Resume or CV
                                </li>
                                <li>
                                    Dean&#39;s Recommendation or GWA Certificate
                                </li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </div>

            <div id="are-there-any-hardware-and-system-requirements?">
                <input name="tutors" type="radio" id=" Are there any hardware and system requirements?" class="accordion-input">
                <label for=" Are there any hardware and system requirements?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Are there any hardware and system requirements?
                    </h6>

                </label>
                <div class="accordion-content content ">

                    <ol class="my-4" type="A">
                        <li>Hardware
                            <ul>
                                <li>
                                    Personal Computer or laptop (Intel Dual Core processor, 4GB RAM)
                                </li>
                                <li>
                                    Peripherals (Headset, Webcam 480p)
                                </li>

                            </ul>
                        </li>
                        <li>Software
                            <ul>
                                <li>
                                    Windows 7 or higher
                                </li>
                                <li>
                                    MAC OS
                                </li>
                            </ul>
                        </li>
                        <li>Connectivity
                            <ul>
                                <li>
                                    DSL/Broadband/Fiber with at least 3 MBPS internet speed
                                </li>
                            </ul>
                        </li>
                    </ol>

                </div>
            </div>

            <div id="what-qualifications-should-the-chalkboard-tutors-have?">
                <input name="tutors" type="radio" id="tutors_21" class="accordion-input">
                <label for="tutors_21" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What qualifications should the Chalkboard tutors have?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard always shows preference to the friendly, professional, supportive teachers from top tier colleges, who can understand the learners. Here, qualifications and experience vary between tutors, but you can view individual qualifications in each tutor’s profile.
                    </p>
                </div>
            </div>

            <div id="how-are-chalkboard-tutors-verified?">
                <input name="tutors" type="radio" id="tutors_22" class="accordion-input">
                <label for="tutors_22" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How are Chalkboard tutors verified?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        For Tutors to be Verified, they must submit additional security documents such as NBI Clearance or Passport.
                    </p>
                </div>
            </div>

            <div id="do-i-have-to-be-located-in-the-philippines-to-be-a-chalkboard-tutor?">
                <input name="tutors" type="radio" id="tutors_23" class="accordion-input">
                <label for="tutors_23" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Do I have to be located in the Philippines to be a Chalkboard tutor?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Since Chalkboard is an online platform that connects students to teachers, and our goal is to be the preferred venue where they learn and teach, all tutorial lessons will happen online, inside our platform, using our reliable and easy-to-use digital tools. So you can work from anywhere in the Philippines. For Filipinos abroad, we are working on our platform so that you will be able to join us.
                    </p>
                </div>
            </div>

            <div id="what-is-expected-from-me-in-an-interview?">
                <input name="tutors" type="radio" id="tutors_24" class="accordion-input">
                <label for="tutors_24" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What is expected from me in an interview?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Interviews will be conducted live via video conferencing (computer + headphones are needed). You will be required to answer questions on your subject expertise, your experiences and your views on teaching. We will check your communication skills and teaching style. You will be asked to do a live teaching demo during the interview.
                    </p>
                </div>
            </div>

            <div id="how-will-i-know-if-i-have-been-approved-to-join-chalkboard?">
                <input name="tutors" type="radio" id="tutors_25" class="accordion-input">
                <label for="tutors_25" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How will I know if I have been approved to join Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        After the Screening process and the interview, our Screening Specialists will inform you of the result thru email or sms. If you have passed, our system will be able to let you proceed with the rest of the steps.
                    </p>
                </div>
            </div>

            <div id="how-long-does-it-take-for-the-process-to-be-completed-and-get-listed-on-chalkboard?">
                <input name="tutors" type="radio" id="tutors_26" class="accordion-input">
                <label for="tutors_26" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How long does it take for the process to be completed and get listed on Chalkboard?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        The process of getting listed on Chalkboard is extremely simple and quick. On an average, the process can be completed in a maximum of 5-7 working days.
                    </p>
                </div>
            </div>

        </div>



        <!-- Payments Billing  FAQ -->
        <div id="paymentsbilling_faq" class="py-4">
            <div class="divider font-weight-bold font-size-md text-center dark-grey">Payments 	&#38; Billing</div>
        </div>

        <div class="accordion">

            <div id="how-much-does-it-cost-to-connect-with-a-chalkboard-tutor?">
                <input name="paymentsbilling" type="radio" id="paymentsbilling_1" class="accordion-input">
                <label for="paymentsbilling_1" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How much does it cost to connect with a Chalkboard tutor?
                    </h6>
                </label>
                <div class="accordion-content content ">
                    <p>
                        Chalkboard offers several flexible plans to fit your study style and the need for tutoring from month to month. Full details are available on our <a class="light-blue has-text-weight-bold" href="./pricing">Pricing Page</a>. </p>
                </div>
            </div>

            <div id="which-forms-of-payment-are-accepted?">
                <input name="paymentsbilling" type="radio" id="paymentsbilling_2" class="accordion-input">
                <label for="paymentsbilling_2" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Which forms of payment are accepted?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Below are the supported payment methods:

                        <ul>
                            <li>
                                Online Banking
                            </li>
                            <li>
                                E-wallets
                            </li>
                            <li>
                                Over-the-counter (Coming Soon)
                            </li>
                            <li>
                                Credit Cards (Coming Soon)
                            </li>
                            <li>
                                Remittance Centers (Coming Soon)
                            </li>
                        </ul>

                    </p>
                </div>
            </div>

            <div id="how-soon-can-i-make-a-payment">
                <input name="paymentsbilling" type="radio" id="How soon can I make a payment?" class="accordion-input">
                <label for="How soon can I make a payment?" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        How soon can I make a payment?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                    You can pay as soon as your tutor accepts your request. If you fail to pay the accepted request in 24 hours, the session is automatically cancelled. Also, please note that payments less than 90 minutes before the session starts are no longer accepted by the system.
                    </p>
                </div>
            </div>

            <div id="how-do-you-secure-payments?">
                <input name="paymentsbilling" type="radio" id="paymentsbilling_3" class="accordion-input">
                <label for="paymentsbilling_3" class="accordion-label px-3 py-2">
                    <h6 id="create-an-account" class="is-size-5">
                        How do you secure payments?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        All card payments are made through a secure third-party payment provider. We assure you that your credit card information is never stored on our website.

                    </p>
                </div>
            </div>

            <div id="what-is-a-service-fee?">
                <input name="paymentsbilling" type="radio" id="paymentsbilling_4" class="accordion-input">
                <label for="paymentsbilling_4" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        What is a service fee?
                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        Service fee is a fee that a user (parent/learner or tutor) pays for use of the platform.

                    </p>
                </div>
            </div>

            <div id="can-i-cancel-upcoming-tutorials-after-i-have-made-payment?">
                <input name="paymentsbilling" type="radio" id="paymentsbilling_6" class="accordion-input">
                <label for="paymentsbilling_6" class="accordion-label px-3 py-2">
                    <h6 class="is-size-5">
                        Can I cancel upcoming tutorials after I have made payment?

                    </h6>

                </label>
                <div class="accordion-content content ">
                    <p>
                        It is unfortunate that you plan to cancel your upcoming sessions, please allow us to help and understand your situation. Kindly contact our <a class="light-blue has-text-weight-bold" href="#how-do-i-contact-customer-support?">Customer Service</a> regarding the matter.

                    </p>
                </div>
            </div>

        </div>


    </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Faq",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
label {
    margin-bottom: 0;
}
.faq-wrapper h6 {
  font-family: var(--lato);
  color: var(--dark-grey);
  margin-bottom: 0;
}
.faq-wrapper a {
    color: var(--dark-grey);
    font-weight: 500;
}
.accordion {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius--5);
  background-color: #8fcbfe;
  overflow: hidden;
}

.accordion-label {
  display: block;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  border-bottom: 1px solid var(--light-silver);
}

.accordion-label::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:222;" /></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.4s;
}

.accordion-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.accordion-content {
  background-color: var(--light-silver);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
.accordion-content p {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.accordion-input {
  display: none;
}

.accordion-input:checked ~ .accordion-content {
  transition: max-height 0.5s;
  max-height: 1200px;
}
.accordion-input:checked ~ .accordion-label::after {
  transform: translateY(-50%) rotate(0.5turn);
}
</style>
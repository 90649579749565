<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">

      <b-form id="parent-profile-form" @submit.prevent="handleSubmit(onSubmit)">
        <b-card-body>
          <b-row class="py-3">
            <b-col lg="6">
              
              <b-form-group id="input-group-1" label-cols-lg="5" content-cols-sm label-for="current_password" label="Current Password:" label-size="sm">
                <ValidationProvider name="current password" rules="required" v-slot="validationContext">
                  <b-input-group size="sm">
                    <template #append>
                      <b-input-group-text @click="onPasswordMask('current_password')">
                        <b-icon icon="eye-slash" v-if="masked.current_password" style="cursor: pointer;"></b-icon>
                        <b-icon icon="eye" v-if="!masked.current_password" style="cursor: pointer;"></b-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input ref="current_password" id="current_password" v-model="form.currentPassword" type="password" required placeholder="Enter your current password" size="sm" :state="getValidationState(validationContext)"></b-form-input>
                  </b-input-group>
                  <div class="invalidFeedback">{{ validationContext.errors[0] }}</div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group id="input-group-2" label-cols-lg="5" content-cols-sm label-for="new_password" label="New Password:" label-size="sm">
                <ValidationProvider name="new password" rules="required" v-slot="validationContext">
                  <b-input-group size="sm">
                    <template #append>
                      <b-input-group-text @click="onPasswordMask('new_password')">
                        <b-icon icon="eye-slash" v-if="masked.new_password" style="cursor: pointer;"></b-icon>
                        <b-icon icon="eye" v-if="!masked.new_password" style="cursor: pointer;"></b-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input ref="new_password" id="new_password" v-model="form.newPassword" type="password" required placeholder="Enter your new password" size="sm" :state="getValidationState(validationContext)"></b-form-input>
                  </b-input-group>
                  <div class="invalidFeedback">{{ validationContext.errors[0] }}</div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group id="input-group-3" label-cols-lg="5" content-cols-sm label-for="retype_password" label="Re-type New Password:" label-size="sm">
                <ValidationProvider name="re-type new password" rules="required|password:@new password" v-slot="validationContext">
                  <b-input-group size="sm">
                    <template #append>
                      <b-input-group-text @click="onPasswordMask('retype_password')">
                        <b-icon icon="eye-slash" v-if="masked.retype_password" style="cursor: pointer;"></b-icon>
                        <b-icon icon="eye" v-if="!masked.retype_password" style="cursor: pointer;"></b-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input ref="retype_password" id="retype_password" v-model="form.reTypeNewPassword" type="password" required placeholder="Re-type your new password" size="sm" :state="getValidationState(validationContext)"></b-form-input>
                  </b-input-group>
                  <div class="invalidFeedback">{{ validationContext.errors[0] }}</div>
                </ValidationProvider>
              </b-form-group>

            </b-col>
            <b-col lg="4">
              <b-alert show class="orange " style="background:#FBF4EA;border: 1px solid #ffe1ab">
                <div class="pb-2 font-size-sm">
                  <strong>All fields are required, please make sure that the password is following the correct format:</strong>
                </div>
                <ul class="font-size-xs">
                  <li>Password must contain at least 1 number
                  </li>
                  <li>Password must contain at least 1 capital letter</li>
                  <li>Password must contain at least 1 special character</li>
                  <li>Password must be at least 8 characters long</li>

                </ul>
              </b-alert>
            </b-col>
          </b-row>

        </b-card-body>
        <b-card-footer  class="bg-white">
          <b-button type="submit" variant="primary" class="d-block ml-auto mt-4" style="font-weight: 500;">
            <b-spinner small v-if="isLoading"></b-spinner>
            <span v-if="isLoading"> loading...</span>
            <span v-if="!isLoading">Update Password</span>
          </b-button>
        </b-card-footer>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import mixins from "../../mixins";

export default {
  name: "PreferencesAccountChangePassword",
  data: () => ({
    form: {
      currentPassword: "",
      newPassword: "",
      reTypeNewPassword: "",
    },
    isLoading: false,
    masked: {
      current_password: false,
      new_password: false,
      retype_password: false
    }
  }),
  computed: {},
  mounted() {},
  methods: {
    onPasswordMask(value) {
      this.masked[value] = !this.masked[value]
      const x = document.getElementById(value)
      x.type = x.type === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      const fetchApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user";
      const fetchOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(fetchApiURL, fetchOptions)
        .then((data) => {
          const _data = data.data;

          const formData = new FormData();

          const params = {
            current_password: this.form.currentPassword,
            password: this.form.newPassword,
            password_confirmation: this.form.reTypeNewPassword,
            email: _data.data.email,
          };

          Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
          });

          const changePassApiURL =
            process.env.VUE_APP_BACKEND_URL +
            "/api/web/authenticate/change-password";
          const changePassOptions = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
          };

          this.axios
            .post(changePassApiURL, formData, changePassOptions)
            .then((data) => {
              const _data = data.data;
              if (_data.status == 200) {
                this.ShowModal(
                  "You have successfully updated your password.",
                  () => {
                    this.form.currentPassword = "";
                    this.form.newPassword = "";
                    this.form.reTypeNewPassword = "";
                    this.$refs.observer.reset();
                  }
                );
              } else {
                let errormsg =
                  "<p>Please resolve the following issue(s) before continuing.</p>";
                if (_data.message == "validation_error") {
                  if (_data.errors.current_password != undefined) {
                    errormsg += `<p>${_data.errors.current_password[0]}</p>`;
                  }

                  if (_data.errors.password != undefined) {
                    let has8CharError = false;
                    _data.errors.password.forEach((error) => {
                      errormsg += `<p>${error}</p>`;
                      if (
                        error == "The password must be at least 8 characters."
                      )
                        has8CharError = true;
                    });
                    errormsg += "<p>*Format:</p>";
                    errormsg += "<p>1. At least 1 number.</p>";
                    errormsg += "<p>2. At least 1 capital letter.</p>";
                    errormsg += "<p>3. At least 1 special character.</p>";
                    if (!has8CharError)
                      errormsg += "<p>4. Minimum of 8 characters.</p>";
                  }

                } else {
                  errormsg += _data.message;
                }

                this.ShowModal(errormsg);
              }
            })
            .catch((error) =>
              console.log(
                "fn method hook => onSubmit() -> Change Password: " +
                  error.response
              )
            );
        })
        .catch(() => {});

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
  mixins: [mixins],
};
</script>

<style>
  .invalidFeedback {
    color: #dc3545;
    margin-top: 0.25rem;
    font-size: 80%;
  }
</style>
<template>
  <div>
    <b-media right-align vertical-align="center">
      <p class="chat-bubble-box-message me" v-if="data.message.attachment_name !== null && data.message.attachment_url !== null"><font-awesome-icon class="mr-2 font-size-xs" :icon="['fas', 'paperclip']" /><a target="_blank" @click="onDownloadAttachmentFile(data.message.attachment_name, `${data.message.attachment_url}`)">{{ data.message.message }}</a></p>
      <p class="chat-bubble-box-message me" v-else>{{ data.message.message }}</p>
      <span class="chat-bubble-box-message-status" v-if="data.isLastSentMessage && data.last_seen">Seen &bull; {{ data.last_seen }}</span>
      <span class="chat-bubble-box-message-status me" v-if="data.isLastSentMessage && !data.last_seen">Delivered &bull; {{ moment.tz(data.message.created_at, 'UTC').tz(userData.timezone).format('hh:mm A ll') }}</span>
    </b-media>
  </div>
</template>

<script>
import mixins from '../../mixins'
  export default {
    props: ['data', 'userData'],
    data: () => ({
      attachmentPath: process.env.VUE_APP_BACKEND_URL
    }),
    computed: {},
    mixins: [mixins]
  }
</script>

<style scoped>
.chat-bubble-box-message {
  display: inline-block;
  background: #f6f6f6;
  color: var(--dark-grey);
  font-size: calc(var(--fs-one)- 2px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
  word-break: break-word;
}
.chat-bubble-box-message-status {
  display: inline-block;
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
}
.chat-bubble-box-message-status.me {
  float: right;
  clear: right;
}
.chat-bubble-box-message.me {
  background: var(--light-blue);
  color: var(--white);
  float: right;
  margin-left: 3rem;
}
.chat-bubble-box-message-status {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
  float: right;
  clear: right;
}
.chat-bubble-box-message a {
  color: white;
}
.chat-bubble-box-message a:hover {
  text-decoration: underline!important;
  cursor: pointer;
}
</style>

<template>
  <div>
    <b-alert v-model="showTop" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" fade variant="info">Copied!</b-alert>
    <!-- breadcrumbs -->
    <div class="d-flex align-items-center justify-content-between pb-4">
      <div class="text-uppercase font-weight-semibold">
        View Sessions
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center font-size-sm mr-3 grey font-weight-normal">
          <div v-b-tooltip.hover title="Date and time displayed are based on your selected time zone">
            <font-awesome-icon class="font-size-xs mr-1 " :icon="['fas', 'info-circle']" />
          </div>
          Time zone:
        </div>
        <div class="font-weight-semibold font-size-sm d-flex align-items-center">
          <span class=" mr-2">{{ userData.timezone }}</span>
          <b-link to="/preferences#timezone" v-b-tooltip.hover title="Change time zone in Preference settings.">
            <font-awesome-icon class="font-size-xs mr-1 light-blue" :icon="['fas', 'pen']" />
          </b-link>
        </div>
      </div>
    </div>

    <b-card no-body class="p-4 shadow-sm mb-4" style="border:none;">
      <b-tabs v-model="tabs" class="custom-tab-container">
        <b-tab title="Today's Sessions" v-on:click.prevent="setActiveTab" lazy>
          <LearnerTodaySessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" />
          <ParentTodaySessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" />
          <TeacherTodaySessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" />
        </b-tab>
        <b-tab title="Upcoming Sessions" v-on:click.prevent="setActiveTab" lazy>
          <LearnerUpcomingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('student')" @copyToClipboard="copyToClipboard"/>
          <ParentUpcomingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('parent')" @copyToClipboard="copyToClipboard"/>
          <TeacherUpcomingSessions :isLoadTriggered="isLoadTriggered" @triggeredWatchSessions="triggeredWatchSessions" v-if="currentUserRoleIs('teacher')" @copyToClipboard="copyToClipboard"/>
        </b-tab>
        <!-- <b-tab title="Active Bundle Sessions" disabled></b-tab> -->
      </b-tabs>
    </b-card>

  </div>
</template>

<script>
import LearnerUpcomingSessions from "../schedule/learner/ScheduleLearnerUpcomingSessions.vue";
import ParentUpcomingSessions from "../schedule/parent/ScheduleParentUpcomingSessions.vue";
import TeacherUpcomingSessions from "../schedule/teacher/ScheduleTeacherUpcomingSessions.vue";
import LearnerTodaySessions from "../schedule/learner/ScheduleLearnerTodaySessions.vue";
import ParentTodaySessions from "../schedule/parent/ScheduleParentTodaySessions.vue";
import TeacherTodaySessions from "../schedule/teacher/ScheduleTeacherTodaySessions.vue";
import { mapState } from "vuex";
export default {
  components: {
    LearnerUpcomingSessions,
    TeacherUpcomingSessions,
    ParentUpcomingSessions,
    LearnerTodaySessions,
    ParentTodaySessions,
    TeacherTodaySessions,
  },
  name: "Sessions",
  metaInfo: {
    title: "Chalkboard - Dashboard Sessions",
  },
  data: () => ({
    isLoadTriggered: false,
    tabs: 0,
    showTop: false
  }),
  mounted() {
    this.$store.dispatch('getTimeSlots', this.userData.timezone)
  },
  computed: {
    ...mapState(["userData"]),
  },
  methods: {
    setActiveTab() {
      this.isLoadTriggered = true;
    },
    triggeredWatchSessions() {
      this.isLoadTriggered = false;
    },
    currentUserRoleIs(role) {
      return this.userData.role === role;
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      this.showTop = true;
      setTimeout(() => {
        this.showTop = false;
      }, 1000);
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.transition-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
<template>
  <div>
    <div id="tutor-bio" class="bg-white py-4 px-4 mb-4 rounded shadow-sm">
      <div class="d-flex flex-row align-items-center pb-2" style="border-bottom: 2px solid var(--silver)">
        <font-awesome-icon class="mr-1" icon="user-circle" />
        <h6 class="m-0">About</h6>
      </div>
      <p class="py-4"><span style="white-space: pre-wrap;word-break: break-word;" v-html="items.about_me"></span></p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['teacherData'],
    name: 'ProfileTeacherAbout',
    data: () => ({
      items: []
    }),
    computed: {},
    mounted() {
      
    },
    methods: {},
    watch: {
      teacherData(val) {
        this.items = val
        console.log(JSON.stringify(this.items.about_me))
      }
    }
  }
</script>

<style scope>
#tutor-bio {
  word-break: break-all;
}
#tutor-bio p > span{
    font-size: calc(var(--fs-one) - 2px);
}
</style>
<template>
  <div>
    <div class="bg-white py-4 px-4 mb-4 rounded shadow-sm" style="
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
        z-index: 100;
      ">
      <div class="d-flex flex-column align-items-center justify-content-around">
        <div class="py-3">
          <span
            v-if="showConvertedAmount"
            class="currency-localization currency-localization--lg"
          >
            <span class="currency-localization__symbol" style="color:var(--dark-grey);">
              {{ convertedTutorRate.symbol }}
            </span>
            <span class="currency-localization__whole">
              {{ convertedTutorRate.whole }}
            </span>
            <span class="currency-localization__decimal" style="color:var(--dark-grey);font-weight:500;">
              {{ convertedTutorRate.decimal }}
            </span>
            <span class="currency-localization__unit" style="margin-top: 1.3rem;font-weight: normal;text-transform: lowercase;color: var(--grey);    font-size: 1.1rem;">/hr</span>
          </span>
          <span v-else  class="currency-localization currency-localization--lg">
              <span class="currency-localization__symbol" style="color:var(--dark-grey)">
               ₱
              </span>
              <span class="currency-localization__whole">
               {{ Math.round(items.rate || 0, 0) }}
              </span>
              <span class="font-size-lg grey" style="line-height: 1.1;">
                      <span>/hr</span>
                    </span>
            </span>
          <!-- <span v-else class="font-weight-bold font-size-xl">
            ₱ {{ items.rate }}
            <span>/hr</span>
          </span> -->

        </div>
        <b-button v-if="showBookingButton" to="/booking" variant="primary" class="light-blue-bg border-0 mt-3" style="padding: 0.8em 2em; border-radius: 10em;"><strong>Request Session</strong></b-button>
        <div class="pt-4">
          <ul class="font-size-sm list-unstyled">
            <li v-if="items.is_verified" v-b-tooltip.hover title="Tutor has a verified ID and that Chalkboard has confirmed that an account is associated with the identity of a real person.">
               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="9.5" fill="white" stroke="white" />
                        <path d="M19.2592 9.99998C19.2592 15.1137 15.1137 19.2592 9.99998 19.2592C4.88622 19.2592 0.740723 15.1137 0.740723 9.99998C0.740723 4.88622 4.88622 0.740723 9.99998 0.740723C15.1137 0.740723 19.2592 4.88622 19.2592 9.99998ZM8.92897 14.9027L15.7987 8.03291C16.032 7.79964 16.032 7.42139 15.7987 7.18812L14.9539 6.34332C14.7207 6.11001 14.3424 6.11001 14.1091 6.34332L8.50655 11.9458L5.89085 9.33014C5.65758 9.09687 5.27933 9.09687 5.04602 9.33014L4.20122 10.1749C3.96795 10.4082 3.96795 10.7865 4.20122 11.0197L8.08414 14.9026C8.31745 15.136 8.69566 15.136 8.92897 14.9027Z" fill="#00BFE0" />
                      </svg>
                       Verified
            </li>
            <li v-b-tooltip.hover title="Tutor was personally interviewed by Chalkboard. We only take tutor applications from candidates who fulfill our grade criteria and tutoring experience requirements. ">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10Z" fill="#FFBD59" stroke="white" />
                        <path d="M9.30014 8.02264L7.79992 5.52242C7.73824 5.41957 7.65097 5.33445 7.54661 5.27535C7.44225 5.21625 7.32436 5.18518 7.20443 5.18518H4.79232C4.51128 5.18518 4.34679 5.50137 4.50781 5.73162L6.92253 9.18127C7.5675 8.57863 8.38781 8.165 9.30014 8.02264ZM15.2077 5.18518H12.7956C12.5517 5.18518 12.3255 5.31322 12.2001 5.52242L10.6999 8.02264C11.6122 8.165 12.4325 8.57863 13.0775 9.18106L15.4922 5.73162C15.6532 5.50137 15.4887 5.18518 15.2077 5.18518ZM10 8.6574C7.89063 8.6574 6.18056 10.3675 6.18056 12.4768C6.18056 14.5862 7.89063 16.2963 10 16.2963C12.1094 16.2963 13.8195 14.5862 13.8195 12.4768C13.8195 10.3675 12.1094 8.6574 10 8.6574ZM12.0078 12.0702L11.1847 12.8722L11.3794 14.0055C11.4141 14.2086 11.2001 14.3638 11.018 14.2679L10 13.7329L8.98222 14.2679C8.79992 14.3644 8.58616 14.2084 8.62089 14.0055L8.81555 12.8722L7.99241 12.0702C7.84441 11.9261 7.92622 11.6745 8.13022 11.645L9.26802 11.4792L9.77649 10.448C9.82228 10.3551 9.91082 10.3093 9.99958 10.3093C10.0888 10.3093 10.178 10.3558 10.2238 10.448L10.7322 11.4792L11.87 11.645C12.074 11.6745 12.1558 11.9261 12.0078 12.0702Z" fill="white" />
                      </svg> Screened
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['teacherData'],
    name: 'ProfileTeacherRate',
    data: () => ({
      items: []
    }),
    computed: {
      showBookingButton () {
        return (this.$route.name == 'search_teacher_profile') ? true : false
      },

      convertedTutorRate() {
        return this.showConvertedAmount
          ? this.convertAmount(this.items.rate, { parts: true })
          : null;
      }
    },
    mounted() {},
    methods: {},
    watch: {
      teacherData(val) {
        this.items = val
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
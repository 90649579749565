<template>
  <div>
    <b-form class="w-100">
      <b-form-group :label="`${$attrs.label}${ $attrs.required ? '*' : '' }`">
        <Input
          v-bind="$attrs"
          v-if="$attrs.f_type === 'input'"
          :childClass="childClass"
          @input="$emit('input', $event)"
        />
        <Select
          v-bind="$attrs"
          v-if="$attrs.f_type === 'select'"
          :childClass="childClass"
          @input="$emit('input', $event)"
        />
        <CountrySelect
          v-bind="$attrs"
          v-if="$attrs.f_type === 'country-select'"
          @input="onCountryEmitInput"
        />
        <PhoneNumberInput
          v-bind="$attrs"
          v-if="$attrs.f_type === 'phone-number-input'"
          @input="$emit('input', $event)"
        />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
  import Input from './FormInput.vue';
  import Select from './FormSelect.vue';
  import CountrySelect from './CountrySelect.vue';
  import PhoneNumberInput from './PhoneNumberInput.vue';
  export default {
    name: 'Form',
    components: { Input, Select, CountrySelect, PhoneNumberInput },
    props: ['childClass'],
    methods: {
      onCountryEmitInput(val) { this.$emit('input', val) }
    }
  }
</script>

<style scoped>

</style>
<template>
  <div>
    <SessionHistoryLearner v-if="isUserLearner"/>
    <SessionHistoryParent v-if="isUserParent" />
    <SessionHistoryTeacher v-if="isUserTeacher"/>
  </div>

</template>

<script>
import mixins from "../../mixins";
import SessionHistoryLearner from "../history/learner/SessionHistoryLearner.vue";
import SessionHistoryParent from "../history/parent/SessionHistoryParent.vue";
import SessionHistoryTeacher from "../history/teacher/SessionHistoryTeacher.vue";

export default {
  components: {
    SessionHistoryLearner,
    SessionHistoryParent,
    SessionHistoryTeacher,
  },
  name: "History",
  data() {
    return {
      fields: [
        "booking_id",
        "date",
        "time",
        "reviewed_by",
        "grade_level",
        "subject",
        "status",
        "action",
      ],
      items: [
        {
          booking_id: "book-2020-2345234",
          date: "Jan. 6, 2021",
          time: "10:00 AM - 11:00 AM",
          reviewed_by: "Regine C.",
          grade_level: "Grade 2",
          subject: "English",
          status: "Completed",
        },
      ],
    };
  },
  computed: {
    isUserParent() {
      return this.$store.state.userData.role == "parent" ? true : false;
    },
    isUserLearner() {
      return this.$store.state.userData.role == "student" ? true : false;
    },
    isUserTeacher() {
      return this.$store.state.userData.role == "teacher" ? true : false;
    },
  },
  mounted() {
    this.$store.dispatch(
      "getUserData",
      window.localStorage.getItem("userToken")
    );
  },
  methods: {},
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link {
  font-size: 2rem;
  font-weight: 700;
}
.nav.nav-tabs {
  background: var(--silver);
}
.b-rating.form-control:focus {
  box-shadow: none;
}
</style>
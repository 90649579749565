<template>
  <div>
    <HeaderBanner />
    <ChalkboardBenefits />
    <Testimonials />
    <PlaftformsComparison />
    <CustomerFeedback />

  </div>
</template>

<script>
import HeaderBanner from "./marketing/why-chalkboard/HeaderBanner.vue";
import ChalkboardBenefits from "./marketing/why-chalkboard/ChalkboardBenefits.vue";
import CustomerFeedback from "./marketing/why-chalkboard/CustomerFeedback.vue";
import PlaftformsComparison from "./marketing/why-chalkboard/PlaftformsComparison.vue";
import Testimonials from "./marketing/why-chalkboard/Testimonials.vue";

export default {
  name: "WhyChalkboard",
  metaInfo: {
    title: "Why Chalkboard - A Trusted Online Learning Platform | Chalkboard",
    htmlAttrs: { lang: "en-US" },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Chalkboard is a recognized online learning platform consisting of excellent, professional tutors with unmatched teaching skills.",
      },
      {
        name: "keywords",
        content:
          "online learning, online tutor/online tutorial, online learning platform, online math tutor, english tutors, online tutoring services, online coding tutor, python coding tutor, programming tutor, online marketplace, tutoring services, ukulele tutor, distance learning, virtual learning",
      },
    ],
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  components: {
    HeaderBanner,
    ChalkboardBenefits,
    CustomerFeedback,
    PlaftformsComparison,
    Testimonials,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/why-chalkboard.css");
</style>
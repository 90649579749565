<template>
  <div>
    <section class="container py-5">
      <div class="row align-items-center justify-content-between">
        <div class="col-sm-12 col-lg-5">
          <figure class="figure p-2">
            <img class="figure-img img-fluid cb-course-testimonial-img" :src="require('@/assets/imgs/courses/'+ this.CourseData.testimonial.featured_image)" alt="">
          </figure>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="cb-course-testimonial-content">
            <figure class="cb-course-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
            </figure>
             <blockquote>{{this.CourseData.testimonial.quote}}</blockquote>
            <div class="cb-course-testimonial-name">
              <p>{{this.CourseData.testimonial.reviewer_name}}</p>
              <p class="font-italic">{{this.CourseData.testimonial.reviewer_location}}</p>
              <b-form-rating inline readonly value="5"></b-form-rating>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="light-blue-5-bg">
      <section class="container py-5">
        <div class="py-5">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-12 col-lg-9">
              <div class="text-center">
                <h5 class="mb-4 dark-blue" style="font-weight: 900;">{{this.CourseData.post_scripts.header}}</h5>
                <p class="fs-24 mb-5"> {{this.CourseData.post_scripts.body}}</p>
                <b-link class="cb-book-now-link" to="/search?search_result=&lvlcat=">Book Now</b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "Summary",
  data() {
    return {
    };
  },
  computed: {},
  mounted() {},
  methods: {},
  props:['CourseData']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
import { extend } from 'vee-validate';
import TSTAT from '../mixins/tutor-status'
import gtmProvider from "../mixins/google-tag-manager/index";

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend('date_not_today', {
    validate: (value) => {
        const d = ((new Date()).toLocaleString("en-US", {
            timeZone: `${'Asia/Manila'}`
        }));
        const dateSelectedValue = (new Date(value).getMonth() + 1) + '/' + new Date(value).getDate() + '/' + new Date(value).getFullYear()
        const dateTodayValue = (new Date(d).getMonth() + 1) + '/' + new Date(d).getDate() + '/' + new Date(d).getFullYear()
        return dateTodayValue != dateSelectedValue
    },
    message: 'The {_field_} field is not allowed to select current date.'
})

extend('atleast_one_number', {
    validate: value => value.match(/^(?=.*[0-9]).*$/),
    message: 'The {_field_} field must have at least 1 number'
})

extend('atleast_one_capital_letter', {
    validate: value => value.match(/^(?=.*[A-Z]).*$/),
    message: 'The {_field_} field must have at least 1 capital letter'
})

extend('atleast_one_special_character', {
    validate: value => value.match(/^(?=.*[\W_]).*$/),
    message: 'The {_field_} field must have at least 1 special character'
})

extend('max_eight_characters', {
    validate: value => value.match(/^(?=.{8,}).*$/),
    message: 'The {_field_} field must have a minimum of 8 characters in length'
})

extend('validate_age', {
    validate: (value) => {
        const d = ((new Date()).toLocaleString("en-US", {
            timeZone: `${'Asia/Manila'}`
        }));

        const date = new Date(d.replace(/-/g, "/"))
        date.setFullYear(date.getFullYear() - 18);
        
        const today = date.toISOString().split('T')[0]

        return value <= today;
    },
    message: 'The {_field_} field must be at least 18 years old'
})

extend("validatePhoneNumber", {
    params: ['target'],
    validate: (_value, { target }) => {
        const targetValue = JSON.parse(target)
        return targetValue.isValid !== undefined ? targetValue.isValid : false
    },
    message:  `Please specify a valid phone number`
});

export default {
    data: () => ({
        UserToken: window.localStorage.getItem("userToken"),
        validProfileFileSize: 2000,
        validDocumentFileSize: 2000, //temporary
        parentChildren: [],
        imagePlaceholder: require('@/assets/placeholder/profile-placeholder.png'),
        defaultStandardLoginRoute: '/schedule/sessions'
    }),
    filters: { 
        truncate: function(data,num){
            const outputStr = 
              data.split("").slice(0, num).join("");
            return outputStr;
        }
    },
    methods: {
        ShowModal(MessageBody, CallBack, Title) {
            const titleNodes = this.$createElement('div', { domProps: { innerHTML: (Title == undefined) ? '<b>System Message</b>' : Title } })
            const messageNodes = this.$createElement('div', { domProps: { innerHTML: MessageBody } })
            this.$bvModal.msgBoxOk([messageNodes], { title: [titleNodes], buttonSize: 'sm' }).then((CallBack) ? CallBack : null)
        },
        showConfirmModal(MessageBody, CallBack, Title, cancelTitle, okTitle) {
            const titleNodes = this.$createElement('div', { domProps: { innerHTML: (Title == undefined) ? '<b>System Confirmation Message</b>' : Title } })
            const messageNodes = this.$createElement('div', { domProps: { innerHTML: MessageBody } })
            const options = { title: [titleNodes], buttonSize: 'sm', size: '', okTitle: (okTitle == undefined) ? 'YES' : okTitle, cancelTitle: (cancelTitle == undefined) ? 'NO' : cancelTitle }
            this.$bvModal.msgBoxConfirm([messageNodes], options ).then(value => {
                if(value){ 
                    CallBack.call()
                }
              })
              .catch(err => {
                console.log( err); 
              })
        },   
        showCustomConfirmModal(MessageBody, CallBack, Title, cancelTitle, okTitle) {
            const titleNodes = this.$createElement('div', { domProps: { innerHTML: (Title == undefined) ? '<b>System Confirmation Message</b>' : Title } })
            const messageNodes = this.$createElement('div', { domProps: { innerHTML: MessageBody } })
            const options = {
                title: [titleNodes],
                buttonSize: 'sm',
                size: 'md', 
                okTitle: (okTitle == undefined) ? 'YES' : okTitle,
                cancelTitle: (cancelTitle == undefined) ? 'NO' : cancelTitle,
                headerClass: 'light-blue-bg text-white', 
                footerClass:'custom-modal', 
                centered: true
            }
            this.$bvModal.msgBoxConfirm([messageNodes], options ).then(value => {
                if(value){ 
                    CallBack.call()
                }
            }).catch(err => { console.log( err); })
        },       
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        timeTo12Hours(time) {
            var d = new Date('1970/01/01 ' + time);
            var hh = d.getHours();
            var m = d.getMinutes();
            var s = d.getSeconds();
            var dd = "AM";
            var h = hh;
            if (h >= 12) {
              h = hh - 12;
              dd = "PM";
            }
            if (h == 0) {
              h = 12;
            }
            m = m < 10 ? "0" + m : m;
          
            s = s < 10 ? "0" + s : s;
          
            var replacement = h + ":" + m + ":" + s;
            replacement += " " + dd;
          
            return replacement;
        },
        timeTo12HoursWOSec(time) {
            var d = new Date('1970/01/01 ' + time);
            var hh = d.getHours();
            var m = d.getMinutes();
            var dd = "AM";
            var h = hh;
            if (h >= 12) {
              h = hh - 12;
              dd = "PM";
            }
            if (h == 0) {
              h = 12;
            }
            m = m < 10 ? "0" + m : m;
          
            var replacement = h + ":" + m;
            replacement += " " + dd;
          
            return replacement;
        },
        toLongDateFormat(myDate) {
            let weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
            let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
            return weekDays[myDate.getDay()] + ", " + month[myDate.getMonth()] + " " + myDate.getDate() + ", " + myDate.getFullYear();
        },
        lastWord(words) {
            let n = words.replace(/\[\]?.,\/#!$%\^&\*;{}=\\|_~()]/g, "").split(" ");
            return n[n.length - 1];
        },
        onDownloadFile(ApiURL, formData, filename) {
            
            const options = {
                headers: {
                    'Accept': 'application/octet-stream',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${this.UserToken}`
                },
                responseType: 'blob'
            }

            this.axios.post(ApiURL, formData, options).then(response => {

                const data = response.data;

                if (data.type == 'text/html') {
                    this.ShowModal("no record found.")
                } else {
                    if (data.size != 0) {
                        var objectURL = URL.createObjectURL(data);
                        var link = document.createElement('a');
                        link.href = objectURL;
                        link.download = filename;
                        link.click();
                    } else {
                        this.ShowModal("no file to download.")
                    }
                }

            })

        },
        formatPrice(num_val){
            let result = 0
            if (typeof(num_val) !== 'undefined') {
                let val = (num_val/1).toFixed(2)//.replace('.', ',')
                result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            return result
        },
        getPostRequestHeaders(){
            return {
                Accept: "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${this.UserToken}`,
              }
        },
        getGetRequestHeaders(){
            return {
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${this.UserToken}`
            }            
        },
        getSubjectPerGradeLevel (gradeLevel) {
            return this.axios.get(process.env.VUE_APP_BACKEND_URL + `/api/web/maintenance/get-subject-categories?gradelevel=${gradeLevel}`)
        },
        convertByteToMegaByte(bytes) {
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return (bytes / Math.pow(1024, i)).toFixed(0)
        },
        isCurrentRoute(src) {
            return this.$route.matched.some(route => route.path === src)
        },
        dayOfWeekAsString () {
            return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        },
        convertTimeZone(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
        },
        autoRedirectIfLogged() {
            if (window.localStorage.getItem('userToken') !== null) {
                this.LoginRedirection(this.$store.state.userData);
            }
        },
        scrollIntoView(event) {
            event.preventDefault();
            if (this.$route.name === 'session-history') {
                const el = document.getElementById('tutorProfileModal')
                const y = el.scrollTop + el.querySelectorAll("#tutor-reviews")[0].getBoundingClientRect().top - 60
                el.scrollTo({ top: y, behavior: "smooth" })
                return
            }
            const href = event.target.getAttribute("href");
            let el = href ? document.querySelector(href) : null;
            const yOffset = -60;
            if (el) {
              const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({ top: y, behavior: "smooth" });
            }
        },
        getTeacherData(teacherId) {
            const teacherData = new FormData();
            teacherData.append("id", teacherId);
            const ApiUrl = process.env.VUE_APP_BACKEND_URL + "/api/web/booking/tutor-details"
            const options = {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${this.UserToken}`,
                }
            }
            return this.axios.post(ApiUrl, teacherData, options)
        },
        getTeacherCounter(teacherId) {
            const counterApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/user/tutor-counter";
            const counterOptions = {
                params: {
                    tutor_id: teacherId
                },
                headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${this.UserToken}`,
                },
            };
            return this.axios.get(counterApiURL, counterOptions)
        },
        getTutorHighestRate() {
            const searchData = new FormData();
            searchData.append("keyword", "");
            searchData.append("sort_by", "highest");

            const paramData = new URLSearchParams(searchData);

            const searchApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/user/teacher-list?" + paramData;
            const searchOptions = {
                headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${this.UserToken}`,
                },
            };
            
            return this.axios.get(searchApiURL, searchOptions)
        },
        getTzRegion(timezone) {
            const tzr = timezone.split('/')
            return tzr[1] || tzr[0]
        },
        updateTimeZone(timezone) {
            const formData = new FormData();
            formData.append("timezone", timezone)
            return this.axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/web/user/update-timezone`, formData, {
                    headers: {
                    "Accept": "application/json, text/plain, */*",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${this.UserToken}`,
                    }
                })
        },
        numRange (start, end) {
            return new Array(end - start).fill().map((d, i) => i + start);
        },
        updatePeerStatus(value) {
            const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/update-peer-status`;
            const options = {
                headers: {
                    "Accept": "application/json, multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${this.UserToken}`,
                }
            }

            const formData = new FormData();
            formData.append('is_peer', value)

            this.axios.post(ApiURL, formData, options).then(() => {
                this.$store.dispatch('getUserData', window.localStorage.getItem("userToken"))
            })
        },
        formatExpiry(time) {
            if (time != 0) {
                const Time = time.split(':')
                let H = parseInt(Time[0])
                let M = parseInt(Time[1])
                return `${H} hr${parseInt(H) > 1 ? 's' : ''} ${M} min${parseInt(M) > 1 ? 's' : ''}`
            } else return `${0} hr ${0} min`
        },
        urlSearchParams(obj) {
            const urlParams = new URLSearchParams()
            Object.keys(obj).forEach(key => {
                urlParams.append(key, obj[key])
            })
            return urlParams
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
            const result = `${isNaN(value) ? 0 : value} ${sizes[i] || 'Byte'}`
            return result
        },
        getDayPart(time) {
            return time >= '00:00:00' && time <= '11:59:59' ? 'Morning' : time >= '12:00:00' && time <= '17:59:59' ? 'Afternoon' : 'Evening'
        },
        capitalizeFirstChar(str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        redirectIfUnauthenticated () {
            this.$store.dispatch("getUserData", localStorage.getItem("userToken")).catch(error => {
                const response = error.response.data
                if (response.status === 401 && response.message === 'Unauthenticated') {
                    if (this.isUserDashboard && this.$route.query.isUnauthenticated !== 1) {
                        this.ShowModal('You have been logged out from the system. Please log in again.', () => {
                        localStorage.removeItem('userToken')
                        localStorage.removeItem('tutorId')
                        localStorage.removeItem('rebookData')
                        this.$store.state.userIsAuthorized = false;
                        this.$store.state.userRole = null;
                        sessionStorage.clear();
                        this.$router.replace('/login?isUnauthenticated=1')
                        })
                    }
                }
            })
        },
        getFChar(str) {
            return (str === null || str === '') ? '' : str.charAt(0).substr(0, 1)
        },
        isMobile() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
            
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
        socialAccountType() {
            return ['FBACNT', 'GGACNT']
        },
        getTzOffSet(date, tz) {
            return date === '' ? this.moment.tz(tz).format('Z') : this.moment(date).tz(tz).format('Z')
        },
        convertTz(date, time, tzFrom, tzTo, format) {
            return this.moment.tz(`${date} ${time}`, tzFrom).tz(tzTo).format((format || '') === '' ? 'YYYY-MM-DD HH:mm:ss' : format)
        },
        convertDSTTime(dt, tz, format) {
            const data = this.$store.state.timeSlots.filter(x => {
                return x.start_time === this.moment(dt).format('HH:mm:ss')
            }).map(_ => {
                const UTCDate = this.moment.tz(`${this.moment.tz(dt, 'UTC').tz(tz).format('YYYY-MM-DD')} ${_.start_time}`, 'UTC').tz(tz)
                return this.moment(`${this.moment.tz(dt, 'UTC').tz(tz).format('YYYY-MM-DD')} ${UTCDate.format('HH:mm:ss')}`).format(format)
            })
            return data
        },
        setActiveDateFromTz(tz) {
            const vm = this
            const xSelectedDate = vm.moment.tz(tz).format('YYYY-MM-DD')
            const cls = ['cb-modal-calendar', 'cb-booking-calendar']
            vm.$nextTick(() => {
                cls.forEach(calCls => {
                document.querySelectorAll(`.${calCls} [data-date] span`).forEach(x => {
                    const removeItem = ['btn-outline-primary', 'focus']
                    x.classList.remove(...removeItem)
                })
                })
                cls.forEach(calCls => {
                const activeDate = document.querySelector(`.${calCls} [data-date="${xSelectedDate}"] span`)
                const classToAdd    = ['btn-outline-primary'],
                        classToRemove = ['text-dark', 'btn-outline-light']
                if (activeDate !== null) {
                    activeDate.classList.add(...classToAdd)
                    activeDate.classList.remove(...classToRemove)
                }
                })
            })
        },
        fbAsyncInit() {
            const vm = this
            return new Promise(() => {
                window.fbAsyncInit = function() {
                    window.FB.init({
                    appId : '420992469454118',
                    cookie : true,
                    xfbml : true,
                    version : 'v2.8'
                    });
                    vm.$store.state.isInitFbSdk = true
                };
                (function(d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = "//connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            })
        },
        dashboardRoutes() {
            return [
                "dashboard",
                "profile",
                "inbox",
                "schedule",
                "calendar",
                "sessions",
                "requests",
                "session-history",
                "history",
                "preferences",
                "parent-children",
                "my-vouchers",
              ]
        },
        arrayGroupBy(data, key) {
            return data.reduce(function(list, x) {
                (list[x[key]] = list[x[key]] || []).push(x);
                return list;
            }, {});
        },
        arrayGroupByMultipleKey(data, key) {
            data.forEach(d => {
                var result = '';
                key.forEach(k => result += `_${d[k]}`)
                Object.assign(d, {[key.join('_')]: result});
            })
            const obj = data.reduce(function(list, x) {
                (list[x[key.join('_')]] = list[x[key.join('_')]] || []).push(x);
                return list;
            }, []);
            var result = [];
            var keys = Object.keys(obj);
            var values = Object.values(obj)
            for (var i = 0; i < keys.length; i++) {
                result.push(values[i])
            }
            return result
        },
        setCss(element, style) {
            for (const property in style)
                element.style[property] = style[property];
        },
        onDownloadAttachmentFile(filename, url) {
            const options = {
                headers: {
                    'Accept': 'application/octet-stream',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${this.UserToken}`
                },
                responseType: 'blob'
            }
            const formData = new FormData()
            formData.append('file_attachment', url)
            this.axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/web/message/download-attachment`, formData, options).then(response => {
                const data = response.data;
                if (data.size != 0) {
                    var objectURL = URL.createObjectURL(data);
                    var link = document.createElement('a');
                    link.href = objectURL;
                    link.download = filename;
                    link.click();
                } else this.ShowModal("no file to download.")
            }).catch((error) => {
                this.ShowModal(`${error.response.status} ${error.response.statusText}`)
            })
        },
        LoginRedirection(userData) {
            const   role                        = userData.role,
                    status                      = userData.status
            switch (role) {
                case "student":
                case "parent":
                    this.$router.push(status !== 'CSACTV' ? `/${role}-registration` : this.defaultStandardLoginRoute)
                    break;
                case "teacher":
                    this.$router.push(![TSTAT.ACTIVE, TSTAT.VERIFIED].includes(status) ? '/teacher-registration' : this.defaultStandardLoginRoute)
                    break;
            }
        },
        getRoleName(role) {
            let roleName = ''
            switch (role) {
                case "student":
                    roleName = 'Learner'
                    break;
                case "parent":
                    roleName = 'Parent'
                    break;
                case "teacher":
                    roleName = 'Tutor'
                    break;
                default:
                    roleName = 'Learner'
                    break;
            }
            return roleName
        },
        siteLogOut() {
            localStorage.removeItem("userToken");
            localStorage.removeItem("tutorId");
            localStorage.removeItem("rebookData");
            this.$store.state.userIsAuthorized = false;
            this.$store.state.userRole = null;
            this.$store.state.userData = []
            sessionStorage.clear();
            window.location = "/login";
        },
        getUploadFile(e) {
            return (e.dataTransfer || e.target).files[0];
        },
        onFileDropChange(e) {

            let result = true;

            let file = this.getUploadFile(e);

            if (file) {

                const validSize = [
                    { type: "file", size: "5000" },
                    { type: "photo", size: "5000" },
                ];

                let allowedExt = [
                    {
                        ext: ["txt", "docx", "doc", "ppt", "pptx", "xls", "xlsx", "pdf", "zip", "rar"],
                        type: "file",
                    },
                    {
                        ext: ["jpg", "jpeg", "png"],
                        type: "photo"
                    },
                ];

                const filename = file.name.toLowerCase();
                const ext = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
                const fileType = allowedExt.filter((x) => { return x.ext.includes(ext); }).map((x) => x.type)[0];
                const allowedSize = parseInt(validSize.filter((x) => { return x.type === fileType; }).map((x) => x.size)[0]);

                allowedExt = [].concat.apply([], allowedExt.map((x) => x.ext)).join("|");
                const pattern = new RegExp(`(${allowedExt})$`);

                if (file.size / 1024 > allowedSize) {
                    this.ShowModal(
                        `The ${fileType} that you are trying to upload is too large.<br /><br /><b>Upload Details:</b><br />
                        Name: ${file.name}<br />
                        Size: ${this.formatBytes(file.size)}<br />
                        Type: ${ext === "rar" ? "application/x-rar-compressed" : file.type}`, () => {
                            this.attachFiles = [];
                            this.attachFileName = "";
                            this.attachOrigFileName ="";
                        }, `<strong>${this.convertByteToMegaByte(allowedSize)}MB Uploading Limit</strong>`);
                    result = false;
                } else if (!pattern.test(filename)) {
                    this.ShowModal(
                        `The file must be a file of type: ${allowedExt.replaceAll("|", ", ")}.`, () => {
                            this.attachFiles = [];
                            this.attachFileName = "";
                            this.attachOrigFileName = "";
                        });
                    result = false;
                }

            } else {
                result = false
            }

            return result
        },
        debounce (fn, delay = 1000) {
            var timeoutID = null
            return function () {
                clearTimeout(timeoutID)
                var args = arguments
                var that = this
                timeoutID = setTimeout(function () {
                    fn.apply(that, args)
                }, delay)
            }
        },
        getFileTypeIcon(filename) {
            let result = ''
            if (filename) {
                const ext = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
                const documentFiles = [
                    { type: 'txt', name: 'text', 'fa-icon': 'file', 'icon-color': '' },
                    { type: 'doc', name: 'word', 'fa-icon': 'file-word', 'icon-color': '#00A2ED' },
                    { type: 'docx', name: 'word', 'fa-icon': 'file-word', 'icon-color': '#00A2ED' },
                    { type: 'ppt', name: 'powerpoint', 'fa-icon': 'file-powerpoint', 'icon-color': '#D04423' },
                    { type: 'pptx', name: 'powerpoint', 'fa-icon': 'file-powerpoint', 'icon-color': '#D04423' },
                    { type: 'xls', name: 'excel', 'fa-icon': 'file-excel', 'icon-color': '#1D6F42' },
                    { type: 'xlsx', name: 'excel', 'fa-icon': 'file-excel', 'icon-color': '#1D6F42' },
                    { type: 'pdf', name: 'pdf', 'fa-icon': 'file-pdf', 'icon-color': 'red' },
                    { type: 'zip', name: 'zip', 'fa-icon': 'file-zipper', 'icon-color': 'yellow' },
                    { type: 'rar', name: 'rar', 'fa-icon': 'file-zipper', 'icon-color': 'yellow' },
                    { type: 'jpg', name: 'jpg', 'fa-icon': 'file-image', 'icon-color': '' },
                    { type: 'jpeg', name: 'jpeg', 'fa-icon': 'file-image', 'icon-color': '' },
                    { type: 'png', name: 'png', 'fa-icon': 'file-image', 'icon-color': '' },
                ]
                const filterResult = documentFiles.filter(x => { return x.type === ext.toLowerCase() })
                if (filterResult.length > 0) {
                    result = filterResult[0]
                }
            }
            return result || 'file'
        },
        generateRules(rules) {
            let result = `${ rules.isRequired ? 'required' : '' }`
            if (rules.maxLength) {
                result += `|max:${rules.maxLength}`
            }
            return result
        },
        renderWhiteSpace(count) {
            let spaces = '';
            for (let i = 0; i < count; i++) { spaces += ' ' }
            return spaces
        },
        IsNullOrEmpty(str) {
            return !str
        },
        mergeObject(obj, src) {
            Object.keys(src).forEach(function(key) { obj[key] = src[key]; });
            return obj;
        },
        googleTagInit: gtmProvider.methods.googleTagInit
    },
    computed: {
    },
}
<style scoped>
h3 {
  font-family: var(--proxima);
}
.cb-login-background-image {
  background: url("~@/assets/global/login-signup-img.png");
  background-color: #f4fdff;
  background-size: cover;
  background-position:top center;
  height: 100vh;
}
.cb-back-link {
  font-size: 0.8rem;
  top: 15px;
  left: 25px;
}
</style>
<template>
  <b-container fluid class="h-100">
    <b-row align-v="center" align-h="center" style="height: 100vh;">
      <b-col class="cb-login-background-image d-none d-lg-flex d-xl-flex">

      </b-col>

      <b-col lg="6" md="12" sm="12" class="h-100" style="overflow-y: scroll;">

        <b-form id="cb-login-form" class="cb-login-form" @submit.prevent="onSubmit">

          <b-navbar-brand id="navbar-logo" to="/home">
            <b-img class="ml-n2 pb-4 mb-4 cb-logo" :src="require('@/assets/global/cb_logo.png')"></b-img>
          </b-navbar-brand>

          <div class="cb-login-text">
            <h6 class="cb-login-text__heading">Welcome</h6>
            <p class="cb-login-text__subheading">Log in to your Chalkboard Account</p>
          </div>
          <!-- Email Field -->
          <b-form-group class="pb-2">
            <label for="email" class="cb-login-form__label">Email</label>
            <b-form-input class="cb-login-form__input" id="email" v-model="form.email" type="email" placeholder="Type your email address" :state="formEmailState"></b-form-input>
            <b-form-invalid-feedback id="input-3-live-feedback">{{ formEmailValidationError }}</b-form-invalid-feedback>
          </b-form-group>

          <!-- Password Field -->
          <b-form-group class="">
            <label for="password" class="cb-login-form__label">Password</label>
            <b-input-group>
              <b-form-input class="cb-login-form__input" id="password" v-model="form.password" type="password" placeholder="Enter your password" :state="formPasswordState" autocomplete="on"></b-form-input>
              <b-input-group-append>
                <b-button class="cb-login-form__eye" @click="togglePassword">
                  <b-icon v-if="!showPassword" icon="eye-fill"></b-icon>
                  <b-icon v-if="showPassword" icon="eye-slash-fill"></b-icon>
                </b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-3-live-feedback">{{ formPasswordValidationError }}</b-form-invalid-feedback>
            </b-input-group>
            <!-- Password Reset Link -->
            <router-link class="pt-3 cb-login-form__link cb-login-form__link--right" to="/forgot-password">Forgot Password</router-link>
          </b-form-group>

          <button class="cb-login-form__submit" type="submit" :disabled="isLoading">
            <b-spinner small v-if="isLoading"></b-spinner>
            <span v-if="isLoading"> loading...</span>
            <span v-if="!isLoading">Login</span>
          </button>&nbsp;

          <!-- Or Login With -->
          <div class="cb-login-form__divider"> or login with </div>

          <!-- Social Media Login -->
          <div class="cb-login-form__socmed-btns ">
            <b-button @click="onFacebookLoginClick" :disabled="!$store.state.isInitFbSdk" variant="default">
              <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_155_1534)">
                  <path d="M24.4999 0C38.0316 0 49 10.9699 49 24.5001C49 38.0318 38.0316 49 24.4999 49C10.9682 49 0 38.0316 0 24.5001C0 10.9699 10.9684 0 24.4999 0Z" fill="#3B5998" />
                  <path d="M30.8591 19.4247H36V12H29.9567V12.0268C22.6343 12.2803 21.1335 16.3046 21.0012 20.5313H20.9862V24.2388H16V31.5098H20.9862V51H28.5006V31.5098H34.6562L35.8453 24.2388H28.5031V21.9988C28.5031 20.5704 29.4753 19.4247 30.8591 19.4247Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_155_1534">
                    <rect width="49" height="49" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </b-button>
            <b-button @click="onGoogleLoginClick" :disabled="!isInit">
              <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7519 19.9359C12.6559 14.1664 18.0779 10.0227 24.5001 10.0227C27.9524 10.0227 31.0705 11.2477 33.5205 13.2523L40.6478 6.125C36.3046 2.33864 30.7365 0 24.5001 0C14.8431 0 6.52883 5.50902 2.53174 13.5771L10.7519 19.9359Z" fill="#EA4335" />
                <path d="M32.7497 36.7757C30.5247 38.2124 27.6973 38.9773 24.4999 38.9773C18.1023 38.9773 12.6973 34.8653 10.7737 29.1302L2.52637 35.3923C6.5185 43.4745 14.8326 49 24.4999 49C30.4878 49 36.2095 46.8713 40.4947 42.8741L32.7497 36.7757Z" fill="#34A853" />
                <path d="M40.4948 42.8741C44.9762 38.6938 47.8864 32.4699 47.8864 24.5C47.8864 23.0522 47.6636 21.4931 47.3295 20.0454H24.5V29.5113H37.6409C36.9925 32.6944 35.252 35.1599 32.7498 36.7757L40.4948 42.8741Z" fill="#4A90E2" />
                <path d="M10.7738 29.1303C10.2866 27.6775 10.0227 26.1206 10.0227 24.5C10.0227 22.904 10.2786 21.3698 10.7518 19.9359L2.53165 13.5771C0.891365 16.8651 0 20.5706 0 24.5C0 28.4191 0.908094 32.1158 2.52649 35.3923L10.7738 29.1303Z" fill="#FBBC05" />
              </svg>

            </b-button>
          </div>

          <!-- Sign up redirect -->
          <p class="text-center mt-2 pb-4 grey">
            New to Chalkboard?
            <b-link to="/signup" class="cb-login-form__link">Sign up here</b-link>
          </p>
        </b-form>
      </b-col>
    </b-row>

    <b-modal id="resend-email-verification-modal" title="System Message">
      <div>{{ resendEmailErrorMessage }}</div>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="mr-1" variant="info" @click="onReSendEmailVerificationClick">re-send email verification link</b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../mixins";
import google from "../../../mixins/social/google.js";
import facebook from "../../../mixins/social/facebook.js";
import TSTAT from "../../../mixins/tutor-status";

export default {
  name: "Login",
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    UserToken: "",
    isLoading: false,
    resendEmailErrorMessage: "",
    formEmailState: null,
    formEmailValidationError: "",
    formPasswordState: null,
    formPasswordValidationError: "",
    loginErr: false,
    showPassword: false,
  }),
  computed: {
    ...mapState(["userData"]),
  },
  mounted() {
    this.autoRedirectIfLogged();
  },
  methods: {
    onFormValidate() {
      this.loginErr = false;

      if (this.form.email == "") {
        this.formEmailState = false;
        this.formEmailValidationError = "The email field is required.";
        this.loginErr = true;
      }

      if (this.form.password == "") {
        this.formPasswordState = false;
        this.formPasswordValidationError = "The password field is required.";
        this.loginErr = true;
      }
    },
    async onSubmit() {
      this.onFormValidate();

      if (this.loginErr) return;

      const fd = new FormData();
      fd.append("email", this.form.email);
      fd.append("password", this.form.password);

      const ApiURL =
          process.env.VUE_APP_BACKEND_URL + "/api/web/authenticate/login",
        options = {
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        };

      this.isLoading = true;

      const loginResponse = await this.axios.post(ApiURL, fd, options);

      const _data = loginResponse.data;

      if (_data.status == 422) {
        if (_data.message == "Account is not yet verified on email.") {
          this.resendEmailErrorMessage = _data.message;
          this.$bvModal.show("resend-email-verification-modal");
        } else {
          this.ShowModal(`<div>${_data.message}</div>`);
        }
      } else {
        const userToken = _data.data.api_token;

        window.localStorage.setItem("userToken", userToken);
        this.$store.state.userToken = userToken;
        this.UserToken = userToken;
        this.$store.state.userIsAuthorized = true;

        const ApiURL =
            process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user",
          options = {
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `Bearer ${userToken}`,
            },
          };

        const userDataResponse = await this.axios.get(ApiURL, options);

        const _user = userDataResponse.data;

        if (_user.status === 200) {
          const _data = _user.data;

          const userRole = _data.role;
          const isLearnerParent =
            (userRole === "student" || userRole === "parent") &&
            _data.status === "CSACTV";
          const isTeacher =
            userRole === "teacher" &&
            [TSTAT.ACTIVE, TSTAT.VERIFIED].includes(_data.status);
          const isValidAccountStep = isTeacher || isLearnerParent;


          if ((!_data.price_tier_id || _data.inactive_price_tier_id) && isTeacher) {
            this.$router.push("/profile#rate");
            return;
          }

          //Return to URL when login is required before visiting a page
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect || "/");
            return;
          }

          if (!_data.price_tier_id && isValidAccountStep && _data.timezone === null) {
            this.$router.push("/preferences?setTimezone");
            return;
          }

          this.LoginRedirection(_data);
        }
      }

      this.isLoading = false;
    },
    onReSendEmailVerificationClick() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/authenticate/sendValidationEmail";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      };

      const formData = new FormData();

      formData.append("email", this.form.email);

      this.axios
        .post(ApiURL, formData, options)
        .then(() => {
          this.ShowModal(
            `<div>Verification link was successfully sent to ` +
              this.form.email +
              `.</div>`,
            () => {
              this.$bvModal.hide("resend-email-verification-modal");
            }
          );
        })
        .catch((error) => console.log(error.response));
    },
    fetchUser() {
      const ApiURL =
          process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user",
        options = {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${this.UserToken}`,
          },
        };
      this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data;
          const userRole = _data.data.role;
          const isLearnerParent =
            (userRole === "student" || userRole === "parent") &&
            _data.data.status === "CSACTV";
          const isTeacher =
            userRole === "teacher" &&
            [TSTAT.ACTIVE, TSTAT.VERIFIED].includes(_data.data.status);
          const isValidAccountStep = isTeacher || isLearnerParent;

          //Return to URL when login is required before visiting a page
          if (this.$route.query.redirect) {
            window.location.href = this.$route.query.redirect || "/";
            return;
          }

          if (isValidAccountStep && _data.data.timezone === null) {
            window.location.href = "/preferences?setTimezone";
            return;
          }

          this.LoginRedirection(_data.data);
        })
        .catch(() => {});
    },
    /* Toggle Show password */
    togglePassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        document.querySelector('.cb-login-form__input[type="password"]').type =
          "text";
      } else {
        document.querySelector('.cb-login-form__input[type="text"]').type =
          "password";
      }
    },
  },
  watch: {
    "form.email": {
      handler: function (value) {
        if (this.loginErr && value != "") {
          this.formEmailState = null;
        }
      },
    },
    "form.password": {
      handler: function (value) {
        if (this.loginErr && value != "") {
          this.formPasswordState = null;
        }
      },
    },
  },
  mixins: [mixins, google, facebook],
};
</script>

<style lang="scss" scoped>
.cb-login-form {
  max-width: 480px;
  margin: 0 auto;
  padding: 1em;

  .cb-login-text {
    padding-bottom: 1em;
    &__heading {
      font-weight: 500;
      color: var(--dark-grey);
      margin: 0;
    }
    &__subheading {
      color: var(--dark-silver);
      font-weight: 500;
    }
  }

  &__label {
    font-weight: 500;
    font-size: calc(var(--fs-one) - 2px);
    color: var(--grey);
  }

  &__input {
    border-radius: 15em;
    border-color: var(--dark-silver);
    padding: 1.375rem 0.825rem;

    &::placeholder {
      color: var(--dark-silver);
    }

    &:focus {
      box-shadow: unset;
      border-color: var(--light-blue);
    }

    &[type="password"],
    &[type="text"] {
      border-right-style: none;
    }
  }

  &__eye.btn {
    color: var(--dark-silver);
    background: none;
    border: 1px solid;
    border-left-style: none;
    border-color: var(--dark-silver);
    border-top-right-radius: 15em !important;
    border-bottom-right-radius: 15em !important;

    &:active {
      border: 1px solid !important;
      border-left-style: none !important;
      background-color: unset !important;
      color: var(--dark-silver) !important;
    }
  }

  &__link {
    font-weight: 500;
    color: var(--light-blue);

    &--right {
      float: right;
    }
  }

  &__submit {
    border-radius: 15em;
    border: none;
    background: var(--light-blue);
    width: 100%;
    padding: 0.625em 0.75em;

    font-weight: 600;
    color: var(--white);

    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  &__divider {
    padding: 3em 0 1.3em;
    color: var(--dark-silver);
    overflow: hidden;
    text-align: center;

    &::before,
    &::after {
      content: "";
      background-color: var(--silver);
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &::before {
      right: 0.5em;
      margin-left: -50%;
    }
    &::after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  &__socmed-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4em;

    button {
      border: 1px solid var(--dark-silver);
      border-radius: 50%;
      width: 69px;
      height: 69px;
      padding: 0;

      svg {
        margin: 0 auto;
      }
    }
  }
}
</style>

<style>
.modal-title {
  font-weight: bold !important;
}
.social-media-buttons {
  margin: 0.5rem 0;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--dark-silver);
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--dark-silver);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
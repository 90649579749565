<template>
  <div>
    <div class="why-header">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-6 col-sm-12 col-xs-12">
            <div class="text-header">
              <h1>4.9</h1>
              <p>out of 5 rating based on over 300 customer reviews</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBanner",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
<template>
    <div>
        
        <!-- File Storage Sidebar -->
        <b-sidebar :visible="showSideBar" id="file-storage-sidebar" right shadow backdrop no-header width="32em" bg-variant="" @hidden="onFileStorageClose()">
            <template #default="{}">
            <div class="p-4">
            <!-- Scribook Overall Performance & Print button -->
            <div class="d-flex align-items-center justify-content-between mb-4">
                <div class="d-flex align-items-center">
                <div class="pr-1">
                    <div class="scribook-storage-icon ">
                    <font-awesome-icon class="" :icon="['fas', 'folder']" />
                    </div>
                </div>
                <b-form-select v-model="subject" class="scribook-storage-select" :options="getSubjects()">
                    <!-- This slot appears above the options from 'options' prop
                    <template #first>
                        <b-form-select-option :value="null" default>Math Folder</b-form-select-option>
                    </template>

                    These options will appear after the ones from 'options' prop
                    <b-form-select-option value="C">Option C</b-form-select-option>
                    <b-form-select-option value="D">Option D</b-form-select-option> -->
                </b-form-select>
                </div>

                <!-- Close Button -->

                <b-button @click="onFileStorageClose()" class="scribook-btn scribook-close-btn" v-b-toggle.scribook-show-more-container v-b-tooltip.hover="{ customClass: 'font-size-xs' }" title="Close">
                <font-awesome-icon class="font-size-sm" :icon="['fas', 'times']" />
                </b-button>
            </div>

            <!-- Search Input -->
            <div class="pb-2 d-flex align-items-center justify-content-between">
                <b-input-group class="w-100">
                <b-form-input type="search" ref="searchScribookFile" v-model="searchScribookFile" placeholder="Search Files" :disabled="isLoadingFiles" v-on:keyup.enter="onSearchEnter"></b-form-input>
                <b-input-group-append is-text>
                    <b-icon icon="search"></b-icon>
                </b-input-group-append>
                </b-input-group>

                <div class="d-flex">
                <b-button class="grid-list-view-btn mx-2" @click="updateView('Grid')">
                    <font-awesome-icon class="" :icon="['fas', 'th-large']" />
                </b-button>
                <b-button class="grid-list-view-btn" @click="updateView('List')">
                    <font-awesome-icon class="" :icon="['fas', 'th-list']" />
                </b-button>
                </div>

            </div>

            <div class="text-cb-color" style="text-align: center; margin-top: 15px;" v-if="isLoadingFiles">
                <b-spinner class="align-middle" small></b-spinner>
                <strong><small> loading files...</small></strong>
            </div>

            <div class="py-4">
                <!-- Download/Upload Area -->
                <!-- Add .sf-grid class -->
                <b-row v-if="!isLoadingFiles && files.length === 0" style="text-align: center; margin-bottom: 10px;"><b-col>No file uploaded.</b-col></b-row>
                <b-form class="scribook-storage-form-container" :class="`${setDisplayView}`">
                    <!-- Uploaded Files -->
                    <b-card no-body class="scribook-file-card" v-for="(file, index) in files" :key="index">
                        <div>
                            <b-container :ref="`file-download-${file.id}`" hidden="true">
                                <div class="text-cb-color" style="text-align: center;">
                                    <b-spinner class="align-middle" small></b-spinner>
                                    <strong><small> downloading file...</small></strong>
                                </div>
                            </b-container>
                        </div>
                        <div class="">
                            <div class="scribook-file-icon">
                                <div class='scribook-storage-file-icon' :style="`color: ${getFileTypeIcon(file.file_name)['icon-color']}`">
                                <font-awesome-icon class=" " :icon="['fas', getFileTypeIcon(file.file_name)['fa-icon']]" />
                                </div>
                                <!-- File Name and Space -->
                                <div class="scribook-file-name">
                                <div class=" font-weight-medium" style="word-break: break-word;">{{file.file_name}}</div>
                                <div class=" dark-silver">{{formatBytes(file.file_size)}}</div>
                                </div>
                            </div>
                            <!-- File Actions -->
                            <div>
                                <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" class="dropdown-action-btn" right no-caret>
                                <template #button-content>
                                    <font-awesome-icon class="font-size-sm grey" :icon="['fas', 'ellipsis-h']" />
                                </template>
                                <b-dropdown-item href="#" @click="onDownloadFile(file)">
                                    <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'file-download']" />Download File
                                </b-dropdown-item>
                                <b-dropdown-item href="#" :disabled="true">
                                    <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'copy']" />Copy File
                                </b-dropdown-item>
                                <b-dropdown-item href="#" :disabled="true">
                                    <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'folder-plus']" />Move To
                                </b-dropdown-item>
                                <b-dropdown-item href="#" @click="onDeleteScribookFile(file)">
                                    <font-awesome-icon class="font-size-sm mr-2" :icon="['fas', 'trash-alt']" />Delete File
                                </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </b-card>

                </b-form>

            </div>

            </div>
        </template>

        <template #footer="{}">
            <div class="dark-grey-bg p-4">
            <div class="silver pb-3">
                <font-awesome-icon class="mr-2 fs-24" :icon="['fas', 'cloud']" />
                Storage ({{`${getFileSizePercentage()}%${getFileSizePercentage() === 100 ? ' Full' : ''}`}})
            </div>
            <div class="pb-1 silver">
                <b-progress class="scribook-storage-progress-bar" height=".3rem" :value="getFileSizePercentage()" ></b-progress>
            </div>
            <small class="silver">{{formatBytes(totalFileSize)}} of 1 GB used</small>
            </div>
        </template>
        </b-sidebar>
    </div>
</template>

<script>
import mixins from '../../mixins'

export default {
    props: {
        subjects: Array,
        data: Object,
        isViewScribook: Boolean,
        scribookData: Object,
        dateFilterParams: Object
    },
    data: () => ({
        subject: 'All',
        displayView: 'Grid',
        files: [],
        isLoadingFiles: false,
        searchScribookFile: '',
        attachFiles: [],
        attachFileName: "",
        attachOrigFileName: "",
        isUploading: false,
        isDeletingFile: false,
        totalFileSize: 0,
        maxGigaByteAllowed: 1e+9,
        showSideBar: false
    }),
    computed: {
        setDisplayView() {
            return this.displayView === 'Grid' ? 'sf-grid' : ''
        }
    },
    mounted() {
        this.showSideBar = true
        this.getScribookFiles()
    },
    methods: {
        getSubjects() {
            return this.subjects
        },
        updateView(param) {
            this.displayView = param
        },
        onFileStorageClose() {
            this.$emit('onFileStorageClose')
            this.showSideBar = false
            this.$destroy()
        },
        async getScribookFiles(scribook_id = '', subject = '', keyword = '') {
            this.isLoadingFiles = true
            const subjectResults = this.subjects.filter(x => Object.keys(x).toString() !== 'All')
            if (this.isViewScribook) subject = this.scribookData.bookings.subject
            const params = { scribook_id: scribook_id, subject: subject, keyword: keyword }
            delete this.dateFilterParams['subject']
            this.mergeObject(params, this.dateFilterParams)
            let response = await this.$store.dispatch('getScribookFiles', params)
            if (response.data.status === 200) {
                response = this.filterObjByType(response.data.data)
                this.files = []
                if (subjectResults.length !== 0) {
                    this.files = response
                    this.totalFileSize = this.getTotalFileSize()
                }
            } else {
                this.files = []
                this.totalFileSize = 0
            }
            this.isLoadingFiles = false
            this.$nextTick(() => {
                if (this.$refs.searchScribookFile)
                    this.$refs.searchScribookFile?.$el.focus()
            })
        },
        async onDeleteScribookFile(data) {
            this.isDeletingFile = true
            await this.$store.dispatch('deleteScribookFile', { scribook_file_id: data.id })
            this.isDeletingFile = false
            this.isLoadingFiles = true
            await this.getScribookFiles('', '', '')
            this.isLoadingFiles = false
        },
        async onFileChange(e) {

            const shouldUpload = this.onFileDropChange(e);

            if (shouldUpload) {
                
                const params = {
                    data: {
                        scribook_id: 1,
                        attachment_file: this.getUploadFile(e)
                    },
                    upload_key: 'upload'
                };

                this.isUploading = true;
                const response = await this.$store.dispatch('uploadScribookFile', params)
                const _data = response.data.data

                if (response.data.status === 200) {
                    this.attachFileName = _data.server_attachment
                    this.attachOrigFileName = _data.original_name
                } else {
                    this.attachFiles = []
                    this.attachFileName = _data.server_attachment
                    this.attachOrigFileName = _data.original_name
                }

                this.isUploading = false;

            }
        },
        getTotalFileSize() {
            return this.files.reduce((acc, curr) => { return acc + curr.file_size }, 0)
        },
        getFileSizePercentage() {
            return Math.floor((this.totalFileSize / this.maxGigaByteAllowed) * 100)
        },
        onDownloadFile(file) {
            this.$refs[`file-download-${file.id}`][0].hidden = false;
            setTimeout(() => {
                this.onDownloadAttachmentFile(file.file_name, file.server_name);
                this.$refs[`file-download-${file.id}`][0].hidden = true;
            }, 250);
        },
        filterObjByType(obj) {
            return this.isViewScribook ? obj.filter(x => x.scribook_type === 'PUBLIC') : obj
        },
        onSearchEnter () {
            const subject = this.subject === 'All' ? '' : this.subject
            this.getScribookFiles('', subject, this.searchScribookFile)
        }
    },
    watch: {
        subject: {
            handler: function (newVal) {
                const keyword = this.searchScribookFile
                const subject = newVal === 'All' ? '' : newVal
                this.getScribookFiles('', subject, keyword)
            }
        }
    },
    mixins: [mixins]
}
</script>
<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form id="registration-step3-form" @submit.stop.prevent="handleSubmit(onSubmit)">

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-3">
          <b-container class="py-3">
            <b-row align-h="center" align-v="center">
              <b-col>
                <div class="w-100">
                  <div class="text-center">
                    <img class="d-lg-block ml-lg-auto" width="170px" height="170px" src="~@/assets/global/Chalkboard_Badge_Verified.png" alt="">
                  </div>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="font-size-lg mb-2"><strong>Get Verified!</strong></div>
                <p>Upload any of the security documents below to get verified.<br>
                  Getting the "Verified" badge can boost your profile because<br>
                  it increases your credibility and trustworthiness.</p>
              </b-col>
            </b-row>
          </b-container>
        </b-card-header>

        <b-card-body class="py-5">
          <b-row align-v="center" align-h="center">
            <b-col lg="8" sm="12">

              <b-alert show class="light-blue " style="background:#F6FDFE;">
                <div class="pb-2 font-size-sm">
                  <strong>Guidelines for Uploading files</strong>
                </div>
                <ul class="font-size-xs">
                  <li>Make sure that you upload a valid security document
                  </li>
                  <li>Your complete name on the security document should match your name on your profile as
                    well as with all the other submitted requirements</li>
                  <li>Files accepted: .jpg, .jpeg, .pdf, .doc, .docx</li>

                  <li>File size cannot exceed {{ convertByteToMegaByte(validDocumentFileSize) }}MB</li>
                </ul>
              </b-alert>
              <div class="p-2">
                <div class="mb-2"><strong>NBI Clearance/Passport</strong></div>

                <ValidationProvider name="nbi" v-slot="validationContext">
                  <b-form-file placeholder="Upload nbi or passport here" v-model="form.nbi" accept=".docx,.pdf,.jpeg,.jpg,.png" size="md" :state="getValidationState(validationContext)" @change="onNBIFileChange"></b-form-file>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  <p class="font-size-xs dark-silver mt-1">Files must be in docx, pdf, jpeg/jpg or png format and under {{ convertByteToMegaByte(validDocumentFileSize) }}MB.</p>
                </ValidationProvider>

              </div>
            </b-col>
          </b-row>

        </b-card-body>

      </b-card>
      <p class="font-size-sm font-weight-normal text-center mb-3 mt-4">By clicking on the Next button, you certify that all the information entered is true and <br>
        correct to the best of your knowledge and that you understand that submitting false and<br>
        malicious documents and photos may disqualify you from being a tutor on the platform.</p>
      <b-button type="submit" :disabled="isLoading || isValidatingFileUpload" class="blue-button btn-block font-weight-bold w-25 mx-auto">
        <b-spinner small v-if="isLoading || isValidatingFileUpload"></b-spinner>
        <span v-if="isLoading"> loading...</span>
        <span v-else-if="isValidatingFileUpload"> validating file upload...</span>
        <span v-if="!isLoading && !isValidatingFileUpload">NEXT</span>
        <!-- <span v-if="!isLoading" v-b-modal.ty-msg>NEXT</span> -->
      </b-button>

      <!-- Thank you message -->
      <b-modal id="ty-msg" centered no-close-on-backdrop no-close-on-esc hide-header>
        <div class="p-2">
          <img class="d-block mx-auto" src="~@/assets/global/ty-msg-img.png" alt="">
          <div class=" text-center">
            <div class="font-size-md font-weight-bold pb-2">
              Congratulations! Well done!
            </div>
            <p class="font-size-sm">Your profile will now be visible to parents and learners looking for tutors.
              We hope that you will have the best teaching experience at Chalkboard.
              You can go to your dashboard to view and edit your profile and check for any pending session requests.
              <br><br>
              Good luck!
            </p>
          </div>
        </div>

        <template #modal-footer>
          <!-- Go To Dashboard Link -->
          <b-button type="submit" class="blue-button btn-block font-weight-bold w-50 mx-auto" @click="goToDashboard()">
            <span v-if="!isLoading" v-b-modal.ty-msg>Go to Dashboard</span>
          </b-button>
        </template>
      </b-modal>
    </b-form>
    </validation-observer>

  </div>
</template>

<script>
import mixins from "../../../../../mixins";
import multistepMixin from "../../../../../mixins/multistep-mixin";

export default {
  name: "RegistrationTeacherStep3",
  data: () => ({
    isValidatingFileUpload: false
  }),
  computed: {},
  mounted() {},
  methods: {
    goToDashboard() {
      window.location.href = '/dashboard'
    },
    onSubmit() {

      const formData = new FormData();
      formData.append("nbi_file", this.nbiFileName);

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/nbi-clearance`,
            options = {
              headers: {
                "Accept": "application/json, multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${this.UserToken}`,
              },
            };

      this.isLoading = true
      this.axios
        .post(ApiURL, formData, options)
        .then((data) => {
          const _data = data.data;
          if (_data.status !== 200) {
            this.ShowModal(_data.message);
          } else {
            this.$bvModal.show('ty-msg')
          }
        })
        .catch((error) => {
          console.log(error.response);
        }).finally(() => { this.isLoading = false })
        
    },
    onNBIFileChange(e) {

      const file = e.target.files[0];

      if (file) {

        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(`The ${(file.type.indexOf("image") !== -1) ? "image" : "document" } you are trying to upload is too large.`,
                          () => {
                            this.form.nbi = null
                            this.nbiFileName = ""
                          },
                          `<strong>${this.convertByteToMegaByte(this.validDocumentFileSize)}MB Uploading Limit</strong>`);
          return
        } else if (! /\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal('The file must be a file of type: docx, pdf, jpeg/jpg or png.', () => {
            this.form.nbi = null
            this.nbiFileName = ""
          })
          return
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "nbi");
        formData.append("type", (file.type.indexOf("image") !== -1) ? "photo" : "file");

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
              options = {
                headers: {
                  "Accept": "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  "Authorization": `Bearer ${this.UserToken}`,
                },
                mode: "cors",
              };

        this.isValidatingFileUpload = true
        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.nbiFileName = _data.data.server_name;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.nbi = null
                this.nbiFileName = ""
              })
            }
          })
          .catch((error) => {
            console.log(error.response);
          }).finally(() => { this.isValidatingFileUpload = false });

      }

    }
  },
  mixins: [mixins, multistepMixin],
};
</script>

<style>
</style>
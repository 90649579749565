<template>
  <div>
    <div class="subject-header">
      <div class="container-fluid">
        <div class="subjects-content">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <b-link to="/offerings">Offerings</b-link>
              </li>
              <li class="breadcrumb-item ">
                <b-link v-if="this.SubjectData.course_code != 'coding'" :to="'/course/' + this.SubjectData.course_code ">{{this.SubjectData.course_name}}</b-link>
                <b-link v-if="this.SubjectData.course_code == 'coding'" to="/learn-coding">{{this.SubjectData.course_name}}</b-link>
              </li>
              <li class="breadcrumb-item active">
                <b-link :to="'/subject/' + this.SubjectData.subject_code">{{this.SubjectData.subject_name | stripHTML}}</b-link>
              </li>
            </ol>
          </nav>
          <h1 v-html="this.SubjectData.subject_name"></h1>
          <h3> {{this.SubjectData.description}}</h3>
          <div v-if="this.SubjectData.subject_name == 'ScratchJr'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj1.png')" alt="">

                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj5.png')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj1.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj2.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj3.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj4.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchjrproj5.gif')" alt="">
              </div>
            </b-modal>
          </div>
          <div v-if="this.SubjectData.subject_name == 'Scratch Level 1'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj1.png')" alt="">

                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj5.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-6>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl1proj6.png')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/615622583/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/615629393/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/615634611/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/615642290/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625404928/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-6" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625411040/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
          </div>
          <div v-if="this.SubjectData.subject_name == 'Scratch Level 2'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl2proj1.png')" alt="">

                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl2proj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl2proj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl2proj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/scratchlvl2proj5.png')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625892792/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625935774/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/629112111/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625687644/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://scratch.mit.edu/projects/625857105/embed" allowtransparency="true" width="100%" height="600" frameborder="0" scrolling="no" allowfullscreen></iframe>
              </div>
            </b-modal>
          </div>

          <div v-if="this.SubjectData.subject_code == 'python_level_1' " class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/pythonproj1.gif')" alt="">
                  </div>
                </div>
              </div>
               <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/pythonproj2.gif')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/pythonproj3.gif')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/pythonproj4.gif')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://trinket.io/embed/python/139f813fa4" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://trinket.io/embed/python/1b833910dc" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://trinket.io/embed/python/67d128defe" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <iframe src="https://trinket.io/embed/python/044726f3e6" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>
              </div>
            </b-modal>
           
          </div>

          <div v-if="this.SubjectData.subject_code == 'python_level_2'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj1.gif')" alt="">

                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj2.gif')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj3.gif')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj4.gif')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj1.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj2.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj3.gif')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/python2proj4.gif')" alt="">
              </div>
            </b-modal>

          </div>

          <div v-if="this.SubjectData.subject_code == 'roblox_level_1'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj1.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj5.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj6.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj7.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/robloxproj8.png')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox1vid.gif')" alt="">
              </div>
            </b-modal>
          </div>

          <div v-if="this.SubjectData.subject_code == 'roblox_level_2'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj1.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj5.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-6>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj6.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj7.png')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj1.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj2.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj3.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj4.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj5.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-6" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/roblox2proj6.png')" alt="">
              </div>
            </b-modal>
          </div>

          <div v-if="this.SubjectData.subject_code == 'minecraft_1'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj1.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj5.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-6>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj6.gif')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj1.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj2.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj3.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj4.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj5.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-6" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraftproj6.gif')" alt="">
              </div>
            </b-modal>
          </div>

          <div v-if="this.SubjectData.subject_code == 'minecraft_2'" class="cb-subject-projects-carousel">
            <VueSlickCarousel v-bind="settings">
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-1>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj1.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-2>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj2.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-3>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj3.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-4>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj4.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-5>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj5.png')" alt="">
                  </div>
                </div>
              </div>
              <div>
                <div class="card">
                  <div class="card-body  iframe-body" v-b-modal.zoom-6>
                    <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj6.gif')" alt="">
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <b-modal id="zoom-1" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj1.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-2" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj2.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-3" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj3.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-4" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj4.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-5" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj5.png')" alt="">
              </div>
            </b-modal>
            <b-modal id="zoom-6" hide-header hide-footer size="lg" centered>
              <div>
                <img class="card-img-top" :src="require('@/assets/imgs/offerings/coding/minecraft2proj6.gif')" alt="">
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "HeaderBanner",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: true,
        infinite: true,
        initialSlide: 3,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 6,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: "50px",
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  filters: {
    stripHTML: function (value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      const text = div.textContent || div.innerText || "";
      return text;
    },
  },
  computed: {},
  mounted() {
    this.reRenderImageSource();
    this.bindCarouselObjectClickEvent();
  },
  methods: {
    reRenderImageSource() {
      const imgs = document.querySelectorAll("img.card-img-top");
      if (imgs)
        imgs.forEach((img) => {
          img.src = require("@/" + img.getAttribute("src"));
        });
    },
    bindCarouselObjectClickEvent() {
      const vm = this;
      const iFrameOverlay = document.querySelectorAll(".iframe-overlay");
      if (iFrameOverlay)
        iFrameOverlay.forEach((obj) => {
          obj.addEventListener("click", function () {
            vm.$bvModal.show("modal-2");
            // this.parentNode.childNodes.forEach(cNode => {
            //   if (cNode.tagName === 'IFRAME')
            // })
          });
        });

      const cObj = this.$refs["thisObj"].querySelectorAll(".c-obj");
      if (cObj)
        cObj.forEach((obj) => {
          obj.addEventListener("click", function () {
            vm.$bvModal.show("modal-2");
          });
        });
    },
  },
  props: ["SubjectData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cb-subject-projects-carousel {
  max-width: 1080px;
  margin: 0 auto -5em;
}

.cb-subject-projects-carousel >>> .slick-slide > div {
  margin: 0.5rem;
}

.cb-subject-projects-carousel >>> .slick-prev,
.cb-subject-projects-carousel >>> .slick-next {
  width: 35px;
  height: 35px;
  z-index: 1;
  /* top: -50px; */
  box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.1);
}
.cb-subject-projects-carousel >>> .slick-prev:before,
.cb-subject-projects-carousel >>> .slick-next:before {
  display: none;
}
.cb-subject-projects-carousel >>> .slick-prev,
.cb-subject-projects-carousel >>> .slick-next {
  background: var(--light-blue);
  border-radius: 50%;
}
/* .cb-subject-projects-carousel >>> .slick-prev {
right: 115px;
left: unset;
}
.cb-subject-projects-carousel >>>.slick-next {
    right: 65px;
} */
.cb-subject-projects-carousel >>> .slick-prev::after,
.cb-subject-projects-carousel >>> .slick-next::after {
  content: "";
  border: solid white;
  border-width: 0 4px 4px 0;
  border-radius: 4px;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.cb-subject-projects-carousel >>> .slick-prev::after {
  margin-left: 4px;
}
.cb-subject-projects-carousel >>> .slick-next::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 4px;
}
.cb-subject-projects-carousel .card {
  width: 100%;
  height: 100%;
  margin: auto;
  background: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  transition: box-shadow 200ms ease-in;
}

.cb-subject-projects-carousel .card:hover {
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.cb-subject-projects-carousel .iframe-body {
  position: relative;
  overflow: hidden;
  padding-top: 70% !important; /* 16:9 Aspect Ratio */
  border-radius: 5px;
}
.cb-subject-projects-carousel .iframe-body > iframe,
.cb-subject-projects-carousel .iframe-body > img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.cb-subject-projects-carousel .cb-primary-link,
.cb-subject-projects-carousel .cb-primary-link:hover {
  font-size: calc(var(--fs-one) - 4px);
}
</style>
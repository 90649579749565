<template>
  <div>
    <div id="why-choose-us" class="value-proposition">
      <div class="container py-5">
        <h5 class="dark-blue">Why choose Chalkboard?</h5>
        <div class="value-prop-container">
          <div class="value-prop-content">
            <div class="one-prop">
              <img :src="require('@/assets/imgs/why-chalkboard/four-steps.png')" class="img-fluid icons" alt="Steps">
              <h6 class="vp-header">4 Step Process</h6>
              <p>Find-Match-Book-Learn, an easy way to connect with expert tutors specific to your needs.</p>
            </div>
          </div>
          <div class="value-prop-content">
            <div class="one-prop">
              <img :src="require('@/assets/imgs/why-chalkboard/digital-tools.png')" class="img-fluid icons" alt="DIgital Tools">
              <h6 class="vp-header">Digital Tools</h6>
              <p>Easy-to-use online learning tools such as video conferencing, whiteboard (Coming Soon) and Scribook, Chalkboard's very own online notebook (Coming Soon).</p>
            </div>
          </div>
          <div class="value-prop-content">
            <div class="one-prop">
              <img :src="require('@/assets/imgs/why-chalkboard/personalized.png')" class="img-fluid icons" alt="Personalized">
              <h6 class="vp-header">Personalized</h6>
              <p>We ensure to provide experts who will fit your child’s learning styles.</p>
            </div>
          </div>
          <div class="value-prop-content">
            <div class="one-prop">
              <img :src="require('@/assets/imgs/why-chalkboard/expert-tutors.png')" class="img-fluid icons" alt="Expert Tutors">
              <h6 class="vp-header">Expert Tutors</h6>
              <p>Quality tutors screened and verified by our highly skilled Talent Acquisition Team.</p>
            </div>
          </div>
          <div class="value-prop-content">
            <div class="one-prop">
              <img :src="require('@/assets/imgs/why-chalkboard/flexibility.png')" class="img-fluid icons" alt="Flexibility">
              <h6 class="vp-header">Flexibility</h6>
              <p>Manage your schedule and choose from different tutorial rates.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChalkboardBenefits",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
export default {
  namespace: true,

  state: () => ({
    modalHistory: [],
    modalInstances: []
  }),

  actions: {
    openAppModal(
      { state },
      {
        contentComponent,
        contentComponentBinds,
        modalSettings,
        contentComponentEvents
      }
    ) {
      const scrollTop = window.pageYOffset;
      const scrollLeft = window.pageXOffset;
      document.documentElement.style.scrollBehavior = "initial";
      window.onscroll = () => {
        window.scrollTo(scrollTop, scrollLeft);
      };

      state.modalInstances.push({
        contentComponent,
        contentComponentBinds,
        contentComponentEvents,
        ...modalSettings,
        visible: true,
        zIndex: 1032 + state.modalInstances.length
      });

      state.modalHistory.push(modalSettings.id);
    },

    closeAppModal({ state }, id) {
      if (!state.modalInstances.length) {
        return;
      }
      if (!id) {
        id = state.modalHistory.pop();
      } else {
        state.modalHistory = state.modalHistory.filter(
          (modalHistoryId) => modalHistoryId != id
        );
      }
      state.modalInstances.some((modalInstance) => {
        if (modalInstance.id == id) {
          modalInstance.visible = false;
          return true;
        }
      });

      /**
       * TODO
       * - possible bug if modals have the same id
       */
      document.getElementById(id).onanimationend = () => {
        state.modalInstances = state.modalInstances.filter(
          (modalInstance) => modalInstance.id != id
        );

        if (state.modalHistory.length) {
          id = state.modalHistory.at(-1);
          document.getElementById(id).focus();
        } else {
          document.documentElement.style.scrollBehavior = "smooth";
          window.onscroll = null;
        }
      };
    }
  }
};

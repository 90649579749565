import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

if(window.Pusher !== 'undefined'){
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
        wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
        wssHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPTED,
        forceTLS: process.env.VUE_APP_WEBSOCKETS_FORCEDTLS,
        disableStats: process.env.VUE_APP_WEBSOCKETS_DISABLESTATS,
        authEndpoint: `${process.env.VUE_APP_BACKEND_URL}/api/broadcasting/auth`,
        namespace: 'Modules.Message.Events.Web',
        auth:
        {
            headers:
            {
                'Authorization': 'Bearer ' + localStorage.getItem('userToken'),
                'Accept': "application/json",
            }
        }
    });
}
<template>
  <div id="app">
    <NavMain />
    <PromoSlider v-if="1 == 0" />
    <b-container v-if="isUserDashboard" fluid>
      <b-row style="height:100vh;">
        <NavDashboard />
        <b-col sm="12" md="9" lg="10" class="ml-sm-auto px-md-4 light-white-bg">
          <div class="cb-dashboard-container">
            <router-view></router-view>
          </div>
        </b-col>
      </b-row>
      <ChatBox v-if="$route.name !== 'messages'" />
    </b-container>
    <div v-else>
      <router-view></router-view>
    </div>
    <Footer />
    <!-- Cookie Container -->
    <CookieConsent v-if="!isUserDashboard" :DashboardRoutes="DashboardRoutes" />
    <div class="app-modal-instances">
      <AppModal
        v-for="modalInstance in $store.state.appModal.modalInstances"
        :key="modalInstance.id"
        v-bind="modalInstance"
      />
    </div>
  </div>
</template>

<script>
import NavMain from "./components/NavigationMain.vue";
import NavDashboard from "./components/NavigationDashboard.vue";
import Footer from "./components/Footer.vue";
import CookieConsent from "./components/CookieConsent.vue";

//Promo
import PromoSlider from "./components/promo/PromoSlider.vue";
import mixins from "./mixins";

import { frontapp } from "./frontapp";

import ChatBox from "./components/chat/ChatMain.vue";
import TSTAT from "./mixins/tutor-status";

import MessageBox from "./mixins/message-box";

import AppModal from "./components/AppModal.vue";

export default {
  name: "App",

  beforeCreate() {
    document.title = process.env.VUE_APP_TITLE;
  },

  data() {
    return {
      DashboardRoutes: [
        "dashboard",
        "profile",
        "inbox",
        "schedule",
        "calendar",
        "sessions",
        "requests",
        "session-history",
        "history",
        "preferences",
        "parent-children",
        "my-vouchers",
        "messages",
        "scribook_learner",
        "scribook"
      ]
    };
  },

  created() {
    this.setupCurrencyLocalization();
    this.$store.state.appModal.modalInstances = [];
    this.$store.state.appModal.modalHistory = [];
  },

  beforeMount() {
    if (window.localStorage.getItem("userToken")) {
      this.$store.dispatch(
        "getUserData",
        window.localStorage.getItem("userToken")
      );
      this.isOnGoingRegistration();
    }
    this.$store.dispatch("getAllSubjects");
    this.$store.dispatch("getReviewRecommendations");
  },

  mounted() {
    this.forceTzSetting();
    this.fbAsyncInit();
    this.metaPixelInit();
    this.googleTagInit();
    this.onChatInit();
    this.$watch("$route", () => {
      this.redirectIfUnauthenticated();
    });

    const payload = {
      eventName: "ViewContent",
      data: {
        content_name: "landing_page"
      }
    };

    this.metaPixelTrack(payload);

    frontapp.loadScript(this.$route.name);
  },

  components: {
    NavMain,
    NavDashboard,
    Footer,
    PromoSlider,
    CookieConsent,
    ChatBox,
    AppModal
  },

  computed: {
    isUserDashboard() {
      return this.DashboardRoutes.find((e) => e === this.$route.name)
        ? true
        : false;
    }
  },

  methods: {
    onChatInit() {
      const msgTextarea = document.querySelector(
        ".chat-bubble-box-type-message"
      );
      if (msgTextarea) {
        msgTextarea.addEventListener("keyup", () => {
          msgTextarea.style.height = "45px";
          msgTextarea.style.height = msgTextarea.scrollHeight + "px";
        });
      }

      window.Echo.join("chat")
        .joining((user) => {
          this.$store.dispatch("setUserChatStatus", {
            user_id: user.id,
            status: "online"
          });
        })
        .leaving((user) => {
          this.$store.dispatch("setUserChatStatus", {
            user_id: user.id,
            status: "offline"
          });
        });
    },

    isOnGoingRegistration() {
      this.$watch(
        (vm) => vm.$store.state.userData,
        function() {
          const status = this.$store.state.userData.status;
          const role = this.$store.state.userData.role;
          const isTeacher = role === "teacher";
          const isValidTeacher =
            isTeacher && ![TSTAT.ACTIVE, TSTAT.VERIFIED].includes(status);
          const isLearnerParent = role === "student" || role === "parent";
          const isValidLearnerParent = isLearnerParent && status !== "CSACTV";
          if (
            (isLearnerParent && status === "CSINAC") ||
            (isTeacher && status === TSTAT.DEACTIVATED)
          ) {
            this.ShowModal(
              "Account is currently deactivated. Please contact support.",
              () => {
                this.siteLogOut();
              }
            );
            return;
          }
          if (
            (isValidTeacher || isValidLearnerParent) &&
            this.$route.name != `${role}_registration`
          ) {
            this.$router.push(`/${role}-registration`).catch(() => {});
          }
        }
      );
    },

    forceTzSetting() {
      this.$watch(
        (_) => [_.$route, _.$store.state.userData],
        (val) => {
          if (!window.localStorage.getItem("userToken") || !val[1].id) {
            return;
          }

          const isActiveOrVerifiedTeacher =
            val[1].role === "teacher" &&
            [TSTAT.ACTIVE, TSTAT.VERIFIED].includes(val[1].status);

          if (
            isActiveOrVerifiedTeacher &&
            (!val[1].price_tier_id || val[1].inactive_price_tier_id)
          ) {
            if (this.$route.name !== "profile") {
              this.$router.push("/profile#rate");
            }
            return;
          }

          const registrationRouteNames = [
            "learner_registration",
            "parent_registration",
            "teacher_registration"
          ];
          if (isActiveOrVerifiedTeacher && !val[1].timezone) {
            if (
              !registrationRouteNames.includes(this.$route.name) &&
              this.$route.name !== "preferences"
            ) {
              this.$router.push("/preferences?setTimezone");
            }
            return;
          }
        },
        { immediate: true }
      );
    },

    setupCurrencyLocalization() {
      this.$store
        .dispatch("currencyLocalization/getIpInfo")
        .then((response) => {
          if (response.status != 200) {
            this.showDynamicModal(
              "Unknown response when fetching IP info. Try reloading the page.",
              "danger"
            );
          }
        })
        .catch((error) => {
          this.showDynamicModal(
            [
              "Error when fetching IP info! Try reloading the page.",
              error.response?.message || error.message
            ],
            "danger"
          );
        });
    }
  },

  mixins: [mixins, MessageBox],

  watch: {
    $route(to) {
      if (window.FrontChat) {
        frontapp.setWidgetVisibility(to.name);
      }
    },
    "$store.state.userData": function(user) {
      if (window.FrontChat) {
        const name = user.firstname + " " + this.getFChar(user.lastname) + ".";
        const email = user.email;
        frontapp.initializeAsUser(this.$route.name, email, name);
      }
    }
  }
};
</script>

<style scoped>
.chat-bubble-head-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1.2rem;
}

/* Chat Bubble Box */

.chat-bubble-box-container {
  display: flex;
  direction: ltr;
  position: relative;
  right: 60px;
}
.chat-bubble-box {
  margin-left: 0.525rem;
  position: relative;
  bottom: 0;
}
.chat-bubble-box .card {
  border-radius: 1em 1em 0 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}
.chat-bubble-box .card .card-header {
  border-radius: 1em 1em 0 0;
}
.chat-bubble-box-head >>> .btn:focus.dropdown-toggle.btn-link {
  box-shadow: none;
}
.chat-bubble-box-head
  >>> [role="presentation"]
  [type="button"].dropdown-item.active,
.chat-bubble-box-head
  >>> [role="presentation"]
  [type="button"].dropdown-item:active {
  background-color: var(--light-silver);
  color: inherit;
  outline: none;
}

.chat-bubble-box-avatar {
  position: relative;
}

.chat-bubble-box-avatar-status {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 0.525em;
  height: 0.525em;
}
.chat-bubble-box-dropdown-icon {
  border-top: 0.3em var(--light-blue) solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-top: 1.3em;
  margin-left: 1em;
}

.chat-bubble-box-avatar-status.online {
  background: var(--teal);
}

.chat-bubble-box-avatar-status.offline {
  background: var(--dark-silver);
}

.chat-bubble-box-body {
  max-height: 20em;
  overflow-y: scroll;
  padding: 1.5em;
}
.chat-bubble-box-body .media-aside {
  margin-right: 0.625rem;
}

.chat-bubble-box-message {
  display: inline-block;
  background: #f6f6f6;
  color: var(--dark-grey);
  font-size: calc(var(--fs-one)- 2px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
}
.chat-bubble-box-message-status {
  display: inline-block;
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
}
.chat-bubble-box-message-status.me {
  float: right;
}

.chat-bubble-box-message.me {
  background: var(--light-blue);
  color: var(--white);
  float: right;
}

.chat-bubble-box-type-message:focus,
.chat-bubble-box-type-message:active {
  background-color: #f0f2f5;
  outline: none;
  box-shadow: none;
}
.chat-bubble-box-type-message::placeholder {
  color: var(--dark-silver);
}
.chat-bubble-box-type-message {
  background: #f0f2f5;
  border-radius: 25px;
  color: var(--dark-grey);
  line-height: 1.2;
  overflow-y: unset !important;
  padding: 0.82em 2.725em 0.82em 0.82em;
  height: 45px;
  max-height: 75px;
  border: none;
  font-size: calc(var(--fs-one) - 2px);
}
.chat-bubble-box-type-message-emoji {
  position: absolute;
  right: 25px;
  bottom: 0;
  font-size: 20px;
  line-height: 2.2;
  color: var(--dark-silver);
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f0f2f5;
  text-align: center;
  line-height: 2.2;
  margin-left: 0.425rem;
  color: var(--dark-silver);
  font-size: 20px;
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons:hover {
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  transition: all ease 500ms;
}

.chat-bubble-box-tutor-name {
  font-weight: 500;
  color: var(--dark-grey);
  cursor: pointer;
  max-width: 10em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-bubble-box-status {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
}
.chat-bubble-box-control {
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  font-size: calc(var(--fs-one) - 2px);
  font-weight: normal;
  line-height: 1.4;
  cursor: pointer;
}

.chat-bubble-head-container {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.chat-bubble-head {
  border-radius: 50%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  position: relative;
}
.chat-bubble-head-red-notif {
  position: absolute;
  top: 0;
  right: -2px;
  background: #f95656;
  border-radius: 50%;
  width: 0.825em;
  height: 0.825em;
  text-align: center;
}
.chat-bubble-head-close {
  position: absolute;
  top: 0;
  right: -3px;
  display: none;
  background: var(--white);
  border-radius: 50%;
  color: var(--dark-grey);
  width: 1.225em;
  height: 1.225em;
  text-align: center;
  line-height: 1.4;
  font-size: calc(var(--fs-one) - 4px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}
.chat-bubble-head:hover > .chat-bubble-head-close {
  display: block;
}
.cb-dashboard-container {
  padding-top: 6.5em;
}
@media screen and (max-width: 576px) {
  * >>> .modal {
    top: 25%;
  }
  * >>> .modal-dialog {
    margin: 2rem;
  }
  * >>> .modal-body {
    font-size: 85%;
  }
}
* >>> .emoji-menu {
  top: -260px !important;
}
</style>

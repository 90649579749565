var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appModal",staticClass:"app-modal",class:_vm.visible ? 'app-modal--open' : 'app-modal--close',attrs:{"id":_vm.id,"tabindex":"0"},on:{"keyup":_vm.onKeyup,"mouseup":_vm.onMouseup}},[_c('div',{ref:"appModalMain",staticClass:"app-modal__main",style:({
      minWidth: _vm.minWidth,
      minHeight: _vm.minHeight,
      width: _vm.width,
      height: _vm.height,
      maxWidth: _vm.maxWidth,
      maxHeight: _vm.maxHeight
    })},[_c(_vm.contentComponent,_vm._g(_vm._b({tag:"component",on:{"close-modal":function($event){return _vm.closeAppModal(_vm.id)}}},'component',_vm.contentComponentBinds,false),_vm.contentComponentEvents))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-container fluid class="cb-pricing-hero px-lg-5 py-lg-5">

      <div class="cb-pricing-hero-overlay">
        <h1 class="text-white font-size-xl">
          Best education, best prices
        </h1>
        <p class="mb-lg-5 text-white font-size-lg">
          Find out which pricing plan best suits your tutoring needs.<br> A range of budget-friendly
          rates you can choose from.
        </p>
        <a class="font-weight-bold cb-cta" href="./signup">Sign Up Now</a>

      </div>
    </b-container>

    <section class="section py-5">
      <div class="container">
        <h3 class="text-center mb-6">
         No Signup Fees, No Monthly Fees, No Subscriptions
        </h3>
        <!-- <div
          class=" text-center-touch has-text-weight-semibold d-flex"
        >
          Bundles&nbsp;<span class="tag is-warning is-light">Soon!</span>
        </div>
        <div
          class="columns text-center-touch is-gapless border-top-bottom is-vcentered mb-6"
        >
          <div class="column">
            <div class="py-4 border-bottom">
              <div class="light-blue has-text-weight-semibold">
                <img
                  class="image is-inline-block mr-2"
                  style="height: 20px"
                  src="../assets/global/Bundle_Starter.png"
                  alt=""
                />Starter
              </div>
              <div class="is-italic is-size-4">Includes 12 hours</div>
            </div>
            <div class="py-4">
              <div class="light-blue has-text-weight-semibold">
                <img
                  class="image is-inline-block mr-2"
                  style="height: 20px"
                  src="../assets/global/Bundle_Boost.png"
                  alt=""
                />Booster
              </div>
              <div class="is-italic is-size-4">Includes 20 hours</div>
            </div>
          </div>
          <div class="column">
            <div class="text-center">
              <div class="cb-pricing-regular-container">
                <div class="is-size-7">Starts at</div>
                <div
                  class="has-text-weight-bold light-blue mb-4 cb-pricing-regular-price"
                >
                  ₱219<span class="is-size-6 light-blue">/hr</span>
                </div>
                <a
                  class="is-size-6 has-text-weight-bold px-6 py-4 cb-pricing-regular-button"
                  href="./search"
                  >View Tutors</a
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="columns text-center-touch is-gapless border-bottom is-vcentered mb-6"
        >
          <div class="column">
            <div class="py-4 border-bottom">
              <div class="light-blue has-text-weight-semibold">
                <img
                  class="image is-inline-block mr-2"
                  style="height: 20px"
                  src="../assets/global/Bundle_Super.png"
                  alt=""
                />Super
              </div>
              <div class="is-italic is-size-4">Includes 40 hours</div>
            </div>
            <div class="py-4 border-bottom">
              <div class="light-blue has-text-weight-semibold">
                <img
                  class="image is-inline-block mr-2"
                  style="height: 20px"
                  src="../assets/global/Bundle_Genius.png"
                  alt=""
                />Genius
              </div>
              <div class="is-italic is-size-4">Includes 60 hours</div>
            </div>
            <div class="py-4">
              <div class="light-blue has-text-weight-semibold">
                <img
                  class="image is-inline-block mr-2"
                  style="height: 20px"
                  src="../assets/global/Bundle_Prodigy.png"
                  alt=""
                />Prodigy
              </div>
              <div class="is-italic is-size-4">Includes 100 hours</div>
            </div>
          </div>
          <div class="column">
            <div class="text-center">
              <div class="is-size-4 light-blue has-text-weight-bold mb-4">
                10% Tutor Discount
              </div>
              <div class="light-blue-bg cb-pricing-promo-container">
                <div class="is-size-7 text-white">Starts at</div>

                <div
                  class="has-text-weight-bold text-white cb-pricing-promo-price"
                >
                  ₱197<span class="is-size-6">/hr</span>
                </div>
                <p class="is-size-6 is-italic text-white mb-4">from P219/hr</p>

                <a
                  class="is-size-6 has-text-weight-bold px-6 py-4 cb-pricing-promo-button"
                  href="./search"
                  >View Tutors</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="text-center is-size-4 is-italic pt-4 mb-4">
          Or if you just want to try it out, you can start with hourly
        </div> -->

        <b-container>
          <b-row align-v="center" class="py-5" style="border-bottom: 2px solid var(--light-silver)">
            <b-col>
              <div class="font-size-md light-blue font-weight-bold">
                <img class="mr-2" style="height: 20px" src="../assets/global/Hourly.png" alt="" />Hourly
              </div>
              <div class="font-italic">Book from 1 up to 20 hours</div>
            </b-col>
            <b-col>
              <div class="text-center cb-pricing-regular-container">
                <div class="font-size-sm mb-1">Starts at</div>
                <div class="font-weight-bold light-blue mb-3 cb-pricing-regular-price">
                  ₱223<span class="light-blue">/hr</span>
                </div>
                <a class="font-weight-bold px-2 py-2 cb-pricing-regular-button" href="./search?search_result=&lvlcat=">View Tutors</a>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>

    <!-- Certifications -->

    <section class="section">
      <b-container class="py-4">
        <div class="text-center">
          <div class="font-size-lg text-center font-weight-bold  light-blue">
            Secure Payments
          </div>
          <p class="font-size-sm text-center">
            Guaranteed payments brought to you by the these partners
          </p>
          <img class="image is-block mb-6" style="width: 50%; margin-left: auto; margin-right: auto" src="../assets/global/cb_payments.png" alt="" />
        </div>

        <b-row align-v="center" align-h="center" class="py-2">
          <b-col lg="2" sm="12" class="text-center">
            <img width="150" src="../assets/global/dragonpay.png" />
          </b-col>
          <b-col lg="2" sm="12" class="text-center">
            <img width="100" src="../assets/global/ssl.png" />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "Pricing",
   metaInfo: {
    title: "Chalkboard - Pricing",
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cb-cta {
  background: white;
  color: var(--light-blue);
}
.font-size-xl {
  font-size: 3.5rem;
  line-height: 1;
}
.cb-pricing-hero {
  min-height: 780px;
  background:  url(../assets/global/Pricing_Banner_2000x777.png);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cb-pricing-hero-overlay {
  width: 100%;
}

.border-top-bottom {
  border-top: 2px solid var(--light-silver);
  border-bottom: 2px solid var(--light-silver);
}
.border-bottom {
  border-bottom: 2px solid var(--light-silver);
}
.cb-pricing-regular-container {
  font-family: var(--montserrat);
}
.cb-pricing-regular-price {
  font-size: 2.5rem;
  line-height: 1;
}
.cb-pricing-regular-button {
  color: var(--light-blue);
  border: 2px solid var(--light-blue);
  border-radius: var(--border-radius-3);
}

.cb-pricing-promo-container {
  padding: 2rem;
  max-width: 400px;
  margin: 0 6rem;
  margin-left: auto;
  margin-right: auto;
}

.cb-pricing-promo-price {
  line-height: 1;
  font-family: var(--montserrat);
  font-size: 4rem;
}
.cb-pricing-promo-button {
  color: var(--light-blue);
  background-color: var(--white);
  border-radius: var(--border-radius-3);
}

@media screen and (max-width: 1224px) {
  h1 {
    font-size: 2rem;
  }
  .font-size-md {
    font-size: 1rem;
  }
  .cb-pricing-hero {
    min-height: 398px;
    background:  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/global/Pricing_Banner_1024x398.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.1rem;
  }
  .font-size-md {
    font-size: 0.8rem;
  }
  .cb-pricing-hero {
    min-height: 199px;
    background:  linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/global/Pricing_Banner_768x299.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cb-cta {
    padding: 0.5rem 1rem;
  }
  .cb-pricing-promo-container {
    padding: 2rem;
    max-width: 400px;
    margin: 0 1rem;
  }
  .cb-pricing-promo-button.px-6 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
</style>
<template>
    <b-container>
        <div class="px-5">
            <b-form-group label="What could have been done better?" label-for="feedback" size="sm">
                <b-form-select id="reason" v-model="recommendation" :options="recommendationOptions" size="sm" @change="onChangeRecommendation"></b-form-select>
            </b-form-group>
        </div>
        <div class="px-5">
            <div class="py-2" v-if="recommendation == 'OTH'">
                <b-form-group label="Others:" label-for="remarks" size="sm">
                    <b-form-textarea
                        id="remarks" 
                        v-model="remarks" 
                        placeholder="Please state other feedback here." 
                        rows="8" 
                        size="sm" 
                        no-resize aria-describedby="password-help-block"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>
                        Type at least 20 characters.
                    </b-form-invalid-feedback>
                    <b-form-text id="password-help-block" style="float:right;">
                        {{ othersCharactersRemaining }} <span v-if="othersCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                    </b-form-text>
                </b-form-group>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'RecommendationSection',
    data: () => ({
        recommendation: '',
        recommendationOptions: [
            { value: "", text: "-- please select an option --" },
            { value: "AAT", text: "Attendance and Timeliness" },
            { value: "TRS", text: "Tutoring skills" },
            { value: "PFR", text: "Professionalism" },
            { value: 'OTH', text: "Others" }
        ],
        characterLimit: 500,
        remarks: ''
    }),
    mounted() {
    },
    computed: {
        othersCharactersRemaining : {
            get() {
                const newLimit = this.characterLimit - this.remarks.length
                return ((newLimit) < 0) ? 0 : newLimit;
            },
            set(value) {
                return value
            }
        }
    },
    methods: {
        onChangeRecommendation(value) {
            this.$emit('changed', value)
        }
    },
    watch: {
        recommendation: {
            handler: function (value) {
                if (value == 'OTH') {
                    this.remarks = ''
                    setTimeout(() => {
                        document.getElementById('remarks').setAttribute("maxlength", this.characterLimit)
                    }, 1000);
                }
            }
        }
    }
}
</script>
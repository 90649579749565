<template>
  <main>
    <section class="cb-first-section">
      <div class="cb-first-section-content">
        <div class="container">
          <div class="row py-5">
            <div class="col-lg-6">
              <h3 class="dark-blue">
                Contact Us
              </h3>
              <p class="py-1 pr-5">Whether you have a question about your sessions, payments, tutors, or anything else, our team is ready to help. Here's how you can reach us.
              </p>
              <div class="cb-contact-channels">
                <div class="">
                  <a target="_blank" href="https://m.me/thechalkboardinc">

                    <figure class="figure d-block text-center ">
                      <img class="img-fluid " :src="require('@/assets/imgs/contact/Facebook Messenger.png')" alt="">
                      <figcaption>
                        Messenger
                      </figcaption>

                    </figure>
                  </a>
                </div>

                <div class="">
                  <a href="https://wa.me/17816615480">

                    <figure class="figure d-block text-center ">
                      <img class="img-fluid " :src="require('@/assets/imgs/contact/WhatsApp.png')" alt="">
                      <figcaption>
                        WhatsApp<br>
                        (Text Only)
                      </figcaption>
                    </figure>
                  </a>

                </div>

                <div class="">
                  <a href="mailto:support@chalkboard.com.ph">
                    <figure class="figure d-block text-center ">
                      <img class="img-fluid " :src="require('@/assets/imgs/contact/Mail.png')" alt="">
                      <figcaption>
                        Support Email
                      </figcaption>
                    </figure>
                  </a>
                </div>

                <div class="">
                  <a v-b-modal.how-it-works-modal>
                    <figure class="figure d-block text-center ">
                      <img class="img-fluid " :src="require('@/assets/imgs/contact/Play Button Circled.png')" alt="">
                      <figcaption>
                        How it works
                      </figcaption>
                    </figure>
                  </a>

                  <b-modal id="how-it-works-modal" hide-footer hide-header centered size="lg">
                    <div class="video-container">
                      <iframe src="https://www.youtube.com/embed/Pc2oWqY_wAc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </b-modal>

                </div>
                <div class="">
                  <a href="https://chalkboard.tawk.help/">
                    <figure class="figure d-block text-center ">
                      <img class="img-fluid " :src="require('@/assets/imgs/contact/Help.png')" alt="">
                      <figcaption>
                        Help Center
                      </figcaption>
                    </figure>
                  </a>
                </div>

              </div>

            </div>
          </div>
        </div>
        <img class="header-img" :src="require('@/assets/imgs/contact/Header - Contact Us.png')" alt="" style="width:55%;">
      </div>
    </section>
    <section class="container py-5">
      <div class="row py-5 justify-content-between">
        <div class="col-lg-6 p">
          <div class="pr-5">
            <h5 class="mb-4">
              We'd love to hear from you.
            </h5>

            <p class="pr-3">Feel free to send us a message for any questions or inquiries. If you want to give us any feedback, please don’t hesitate to leave us a message too! We are more than happy to help you with anything. Just fill in the form and submit so that we can keep in touch with you.</p>
          </div>
        </div>
        <div class="col-lg-6">
          <form @submit.stop.prevent="onSubmit">
            <div class="row pt-sm-4 pt-lg-0">
              <div class="col">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" v-model="sender_name" class="form-control" id="name" placeholder="John Doe i.e">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input type="email" v-model="sender_mail" class="form-control" id="email" placeholder="email@emailaddress.com">
                </div>
              </div>
            </div>
            <div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea class="form-control"  v-model="sender_message" id="message" rows="3" placeholder="Write a message"></textarea>
              </div>
              <button type="submit" class="btn btn-primary cb-contact-form-submit-btn mb-2">Submit</button>

            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<!-- Fontawesome -->  

<script src="https://kit.fontawesome.com/e06763c731.js" crossorigin="anonymous"></script>

<script>
import mixins from "../mixins";

export default {
  name: "Contact",
  metaInfo: {
    title: "Chalkboard Contact us",
    htmlAttrs: { lang: "en-US" },
    meta: [],
  },
  data() {
    return {
      sender_name: '',
      sender_mail: '',
      sender_message: ''

    }
  },
  methods:{
    onSubmit() {
       const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/message/send-contactus-message";


      const options = {
        headers: this.getPostRequestHeaders(),
        mode: "cors",
      };

      let paramData = new FormData();

      paramData.append("name", this.sender_name);
      paramData.append("email", this.sender_mail);
      paramData.append("message", this.sender_message);

      this.axios
        .post(ApiURL, paramData, options)
        .then((response) => {
          if(response.data.status == 200){
            this.resetForm();
            this.ShowModal("Thank you for writing to us! We are reviewing your request and we'll get in touch as soon as possibe. Meanwhile, take a look at our blog and newsroom for more information about Chalkboard.")
          }else{
            this.ShowModal("Ooops, something wasn't right. If you need immediate assistance, please contact us at support@chalkboard.com.ph")
          }

      });     
    },
    resetForm(){
      this.sender_name = ''
      this.sender_mail = ''
      this.sender_message = ''
    }
  },
  mixins:[mixins]
};
</script>
<style>
@import url("../assets/css/contact.css");
</style>
<template>
  <div>
    <b-alert v-model="showTop" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" fade variant="info">Copied!</b-alert>
    <SessionHistoryLearner v-if="isUserLearner" @copyToClipboard="copyToClipboard"/>
    <SessionHistoryParent v-if="isUserParent" @copyToClipboard="copyToClipboard"/>
    <SessionHistoryTeacher v-if="isUserTeacher" @copyToClipboard="copyToClipboard"/>
  </div>

</template>

<script>
import mixins from "../../mixins";
import SessionHistoryLearner from "../history/learner/SessionHistoryLearner.vue";
import SessionHistoryParent from "../history/parent/SessionHistoryParent.vue";
import SessionHistoryTeacher from "../history/teacher/SessionHistoryTeacher.vue";

export default {
  components: {
    SessionHistoryLearner,
    SessionHistoryParent,
    SessionHistoryTeacher,
  },
  name: "History",
  metaInfo: {
    title: "Chalkboard - Dashboard Session History",
  },
  data: () => ({
    showTop: false
  }),
  computed: {
    isUserParent() {
      return this.$store.state.userData.role == "parent" ? true : false;
    },
    isUserLearner() {
      return this.$store.state.userData.role == "student" ? true : false;
    },
    isUserTeacher() {
      return this.$store.state.userData.role == "teacher" ? true : false;
    },
  },
  mounted() {
    this.$store.dispatch(
      "getUserData",
      window.localStorage.getItem("userToken")
    );
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text);
      this.showTop = true;
      setTimeout(() => {
        this.showTop = false;
      }, 1000);
    }
  },
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link {
  font-size: 2rem;
  font-weight: 700;
}
.nav.nav-tabs {
  background: var(--silver);
}
.b-rating.form-control:focus {
  box-shadow: none;
}
</style>
<style>
.trans-section .dropdown-menu.show .dropdown-item {
  padding-right: 2.5rem;
}
</style>
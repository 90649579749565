
<template>
  <b-container class="px-4 py-5">
    <div class="py-5 my-5">
      <h6 class="text-center pb-5">We've already created your account!<br>
        Fill out this form to finish your registration as a Parent.</h6>

      <div class="cb-registration-form">

        <ul id="progressbar" class="px-4 pt-4">
          <li :class="{ active: 1 <= formStepSelected }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'user']" />
            </div>
            <div>Personal Info</div>
          </li>
          <li :class="{ active: 2 <= formStepSelected }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'map-marker-alt']" />
            </div>
            <div>Address</div>
          </li>
          <li :class="{ active: 3 <= formStepSelected }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'user-edit']" />
            </div>
            <div>Other Info</div>
          </li>
          <li :class="{ active: 4 <= formStepSelected }">
            <div class="step-icon-container">
              <font-awesome-icon class="" :icon="['fas', 'user-check']" />
            </div>
            <div>Finish</div>
          </li>
        </ul>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit()">

            <div class="p-4">
              <transition-group tag="div" class="div-slider" name="slide">
                <!-- Start Personal Info -->
                <div v-if="formStepSelected === 1" key="1">
                  <div class="slider-content sc1">

                    <b-form-group label="Birthday *" label-for="birthday" class="pb-2">
                      <ValidationProvider name="birthday" v-slot="validationContext">
                        <b-form-input required type="date" id="birthday" v-model="birthday" placeholder="Birthday" onfocus="this.type='date'" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        <b-form-text id="birthday-caption" v-if="!validationContext.errors[0]">
                          You need to be at least 18 years old to sign up.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="Primary Email *" label-for="Primary Email" class="pb-2">
                      <ValidationProvider name="primary email" rules="required|email" v-slot="validationContext">
                        <b-form-input disabled required type="text" v-model="email" id="primary-email" placeholder="email@email.com" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        <b-form-text id="primary-email-caption" v-if="!validationContext.errors[0]">
                          All email notifications are sent here.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="First Name *" label-for="first name" class="pb-2">
                      <ValidationProvider name="first name" rules="required" v-slot="validationContext">
                        <b-form-input required type="text" id="firstname" placeholder="John" v-model="firstname" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="Last Name *" label-for="last name" class="pb-2">
                      <ValidationProvider name="last name" rules="required" v-slot="validationContext">
                        <b-form-input required type="text" id="lastname" placeholder="Dela Cruz" v-model="lastname" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        <b-form-text id="lastname-caption" v-if="!validationContext.errors[0]">
                          Other parents and learners including our tutors will see your name as “ {{firstname}} {{getFChar(lastname)}}. “.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="Phone Number *" label-for="Phone Number" class="pb-2">
                      <ValidationProvider name="phone number" :rules="`required|validatePhoneNumber:${JSON.stringify(eventPNData)}`" v-slot="validationContext">
                        <PhoneNumberInput
                          required
                          id="mobile_no"
                          ref="mobile_no"
                          v-model="mobile_no"
                          class="form-number-override"
                          placeholder="Enter Phone Number"
                          @update="onPNValidate($event)"
                          :default-country-code="`${pn_country_code || 'US'}`"
                          :error="validationContext.errors[0] ? true : false"/>
                        <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                        <b-form-text id="phone-number-caption" v-if="!validationContext.errors[0]">
                          For customer support. We will not share it with anyone else.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label-for="whatsapp" class="pb-2">
                      <ValidationProvider name="whatsapp" :rules="`validatePhoneNumber:${JSON.stringify(eventWAData)}`" v-slot="validationContext">
                        <label>
                          <b-img class="img-fluid" :src="require('@/assets/imgs/forms/whatsapp.png')"></b-img>
                          WhatsApp
                        </label>
                        <PhoneNumberInput
                          id="whatsapp"
                          v-model="whatsapp"
                          class="form-number-override"
                          placeholder="Enter Whatsapp"
                          @update="onWAValidate($event)"
                          :default-country-code="`${wa_country_code || 'US'}`"
                          :error="validationContext.errors[0] ? true : false" 
                        />
                        <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                        <b-form-text id="whatsapp-caption" v-if="!validationContext.errors[0]">
                          For parents outside the Philippines, our customer support may use this to contact you.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                </div>
                <!-- End Personal Info -->

                <!-- Start Address -->
                <div v-if="formStepSelected === 2" key="2">
                  <div class="slider-content sc2">
                    <b-form-group label="Address 1" label-for="Address 1" class="pb-1">
                      <ValidationProvider name="address 1" rules="max:100" v-slot="validationContext">
                        <b-form-input id="street" v-model="street" placeholder="Blk, Lot, Street address" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="Address 2" label-for="Address 2" class="pb-1">
                      <ValidationProvider name="address 2" rules="max:100" v-slot="validationContext">
                        <b-form-input id="brgy" v-model="brgy" placeholder="Apartment, suite, unit, building, floor etc." :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="City *" label-for="city" class="pb-1">
                      <ValidationProvider name="city" rules="required" v-slot="validationContext">
                        <b-form-input id="city" v-model="city" required :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="State/Province *" label-for="province" class="pb-1">
                      <ValidationProvider name="province" rules="required" v-slot="validationContext">
                        <b-form-input required v-model="province" id="state-province" :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="form-country-override pb-1" label="Country *" label-for="country">
                      <ValidationProvider name="country" rules="required" v-slot="validationContext">
                        <CountrySelect v-model="country" :errorMessage="!country ? validationContext.errors[0] : ''" :successful="!!country" size="sm" />
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group label="Zip Code" label-for="Zip Code" class="pb-1">
                      <b-form-input id="Zip Code" v-model="zip_code"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Start Address -->

                <!-- Start Other Info -->
                <div v-if="formStepSelected === 3" key="3">
                  <div class="slider-content sc3">
                    <b-form-group label="What is your education approach?" label-for="education-approach" class="pb-2">
                      <b-form-select id="education-approach" v-model="education_approach">
                        <b-form-select-option v-for="(item, index) in educationalApproachData.map(x => { return { name: x.code_description, code: x.code_type_value }})" :key="index" :value="item.code">{{item.name}}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group label="How did you hear about us?" label-for="How did you hear about us?" class="pb-2">
                      <b-form-select id="How did you hear about us?" v-model="referral_source" @change="onReferralSourceChange">
                        <b-form-select-option v-for="(item, index) in howDidYouHearAboutUsData.map(x => { return { name: x.code_description, code: x.code_type_value }})" :key="index" :value="item.code">{{item.name}}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>

                    <!-- Should only appear if "From A friend" or "Schools, groups, and organizations" is selected -->
                    <b-form-group label="Referral Code" label-for="Referral Code" class="pb-2" v-show="isEnableReferralCode">
                      <b-form-input id="Referral Code" v-model="referral_code"></b-form-input>
                      <b-form-text id="referral-code">
                        Add the code of the user that referred you.
                      </b-form-text>
                    </b-form-group>
                  </div>
                </div>
                <!-- End Other Info -->

                <!-- Start Last Step -->
                <div v-if="formStepSelected === 4" key="4">
                  <div class="slider-content sc4">
                    <div class="text-center pb-5">
                      <b-img class="img-fluid" width="250px" :src="require('@/assets/imgs/forms/last-step.svg')">
                      </b-img>

                      <p class="mt-4 font-weight-medium">Yey! You have successfully registered.<br>
                        You’ll be automatically redirected to your dashboard in {{ pageRedirectCountDown }}s.</p>

                      <b-link class="light-blue font-weight-semibold fs-24" to="/preferences?setTimezone">Go To Dashboard</b-link>
                    </div>
                  </div>
                </div>
                <!-- End Last Step -->
              </transition-group>

              <!-- Buttons -->
              <div class="text-center">
                <b-button class="btn back" v-if="formStepSelected > 1 && formStepSelected <= formSteps.length - 1" @click="onBack" :disabled="isSubmitLoading">Back</b-button>
                <b-button type="button" class="btn" v-if="formStepSelected < formSteps.length - 1" @click="validateObserver">Next</b-button>
                <b-button class="btn" v-if="formStepSelected === formSteps.length - 1" @click="onSubmit" :disabled="isSubmitLoading">
                  <b-spinner small v-if="isSubmitLoading"></b-spinner>
                  <span v-if="isSubmitLoading"> saving data...</span>
                  <span v-if="!isSubmitLoading">Submit</span>
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-container>
</template>

<script>
import mixins from "../../../../mixins";
import { mapState } from "vuex";
import PhoneNumberInput from "../../../../utility/forms/PhoneNumberInput.vue";
import CountrySelect from "../../../../utility/forms/CountrySelect.vue";

export default {
  name: "RegistrationParent",
  components: { PhoneNumberInput, CountrySelect },

  data: () => ({
    firstname: "",
    lastname: "",
    birthday: "",
    email: "",
    mobile_no: "",
    street: "",
    brgy: "",
    province: "",
    city: "",
    zip_code: "",
    formStepSelected: 1,
    formSteps: [
      { step: 1, minHeight: "47rem" },
      { step: 2, minHeight: "40rem" },
      { step: 3, minHeight: "20rem" },
      { step: 4, minHeight: "30rem" },
    ],
    divSlider: "",
    pn_country_code: "",
    whatsapp: "",
    wa_country_code: "",
    country: "",
    education_approach: [],
    referral_source: [],
    referral_code: "",
    redirectTimeOut: 5,
    pageRedirectCountDown: 0,
    formattedPhoneNumber: "",
    formattedWhatsAppNumber: "",
    pn_area_code: "",
    wa_area_code: "",
    eventPNData: {},
    eventWAData: {},
    isSubmitLoading: false
  }),
  beforeMount() {
    this.$store.dispatch("getEducationalApproach");
    this.$store.dispatch("getHowDidYouHearAboutUs");
  },
  computed: {
    ...mapState([
      "userData",
      "educationalApproachData",
      "howDidYouHearAboutUsData",
    ]),
    isEnableReferralCode() {
      const source = ["FMFRND", "SCGROR"];
      return source.includes(this.referral_source);
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("navbar-logo").removeAttribute("href");
    });
    this.fetchUserData();
    this.divSlider = document.querySelector(".div-slider");
    this.setCss(this.divSlider, {
      minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
        .minHeight,
    });
  },
  methods: {
    onReferralSourceChange() {
      if (!this.isEnableReferralCode) {
        this.referral_code = "";
      }
    },
    onPNValidate(e) {
      this.pn_country_code = e.countryCode;
      this.formattedPhoneNumber = e.formattedNumber
      this.pn_area_code = e.countryCallingCode
      this.eventPNData = e
    },
    onWAValidate(e) {
      this.wa_country_code = e.countryCode;
      this.formattedWhatsAppNumber = e.formattedNumber
      this.wa_area_code = e.countryCallingCode
      this.eventWAData = e
    },
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          this.onNext();
        }
      });
    },
    onBack() {
      this.formStepSelected--;
      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onNext() {
      this.formStepSelected++;
      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onSubmit() {
      const registerData = new FormData();
      registerData.append("birthday", this.birthday);
      registerData.append("firstname", this.firstname);
      registerData.append("lastname", this.lastname);
      registerData.append("mobile_no", this.formattedPhoneNumber);
      registerData.append("brgy", this.brgy || '');
      registerData.append("street", this.street || '');
      registerData.append("city", this.city);
      registerData.append("province", this.province);
      registerData.append("zip_code", this.zip_code);
      registerData.append("pn_country_code", this.pn_country_code);
      registerData.append("pn_area_code", this.pn_area_code);
      registerData.append("whatsapp", this.formattedWhatsAppNumber || "");
      registerData.append("wa_country_code", (this.whatsapp) ? this.wa_country_code : "");
      registerData.append("wa_area_code", (this.whatsapp) ? this.wa_area_code : "");
      registerData.append("country", this.country);
      registerData.append("education_approach", this.education_approach);
      registerData.append("referral_source", this.referral_source);
      registerData.append("referral_code", this.referral_code);

      this.isSubmitLoading = true
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/multi-signup/info";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios.post(ApiURL, registerData, options).then((data) => {
        const _data = data.data;
        if (_data.status == 422) {
          this.ShowModal(
            "Something went wrong. Please contact support.",
            null,
            "Registration Error"
          );
          return;
        }
        this.onNext();
        this.pageRedirectCountDown = this.redirectTimeOut;
        this.$store.dispatch(
          "getUserData",
          window.localStorage.getItem("userToken")
        );
      }).finally(() => {
        const payload = {
            'eventName': 'CompleteRegistration',
            'data': {
                'content_name': 'parent'
            }
        };
        this.metaPixelTrack(payload);
        this.isSubmitLoading = false
      });
    },
    fetchUserData() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data;
          if (_data.data.role === "parent") {
            if (_data.data.status === 'CSACTV') {
              this.$router.push("/dashboard");
            } else {
              this.firstname = _data.data.firstname || "";
              this.lastname = _data.data.lastname || "";
              this.birthday = _data.data.birthday || "";
              this.email = _data.data.email || "";
            }
          } else {
            this.ShowModal("You are not allowed to access this page.", () => {
              this.$router.push("/");
            });
          }
        })
        .catch(() => {});
    },
  },
  watch: {
    pageRedirectCountDown: {
      handler: function (value) {
        if (value > 0) {
          setTimeout(() => {
            this.pageRedirectCountDown--;
          }, 1000);
        } else window.location.href = "/preferences?setTimezone";
      },
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.step-icon-container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--silver);
  display: flex;
  align-items: center;
  justify-content: center;
}

#progressbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding-left: unset;
  overflow: hidden;
  color: lightgrey;
  width: 100%;
  position: relative;
  z-index: 1;
}

#progressbar .active .step-icon-container {
  color: #fff;
  background: var(--light-blue);
}

#progressbar .active {
  color: var(--dark-grey);
}

#progressbar li {
  color: var(--dark-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  width: 33.33%;
  position: relative;
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 3px;
  background: var(--dark-silver);
  position: absolute;
  right: 50%;
  top: 25px;
  z-index: -1;
}
#progressbar li:first-child:after {
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--light-blue);
}

.cb-registration-form {
  max-width: 500px;
  background: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1em;
  position: relative;
  margin: 0 auto;
}

.cb-registration-form::before,
.cb-registration-form::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.cb-registration-form::before {
  bottom: -4em;
  left: -5em;
  height: 233px;
  width: 233px;
  background: rgba(0, 191, 224, 0.2);
}
.cb-registration-form::after {
  top: -3em;
  right: -5em;
  height: 243px;
  width: 234px;
  background: rgba(255, 189, 89, 0.2);
}

.cb-registration-form button {
  background: var(--light-blue);
  border-radius: 2rem !important;
  border: none;
  padding: 0.5em 2.375em !important;
  font-size: var(--fs-one) !important;
  font-weight: 500;
  outline: none;
}
.cb-registration-form button.back {
  background: none;
  background-color: none;
  color: var(--dark-silver);
}

input.form-control,
select.custom-select {
  border-radius: 1.25rem;
  padding: 0.375rem 1rem;
}
.cb-registration-form >>> .form-text.text-muted {
  color: var(--dark-silver) !important;
}
.form-number-override >>> .input-tel.input-phone-number {
  margin-left: 1rem;
}
.form-number-override >>> input.country-selector__input {
  border-radius: 1.25rem !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: calc(var(--fs-one) - 2px) !important;
}
.form-number-override >>> input.input-tel__input {
  border-radius: 1.25rem !important;
  padding-left: 1rem;
  padding-top: unset !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: calc(var(--fs-one) - 2px) !important;
}
.form-country-override >>> .IZ-select__input,
.form-country-override >>> input {
  position: relative;
  border-radius: 1.25rem !important;
  padding: 0rem 0.5rem;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: calc(var(--fs-one) - 2px) !important;
}
.form-country-override >>> .IZ-select__input::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 10em;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 21l-12-18h24z'/%3E%3C/svg%3E");
  background-size: 0.6em;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
}
.custom-select {
    padding: 0.375rem 2.55em 0.375em 1rem !important;
  background: none;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 21l-12-18h24z'/%3E%3C/svg%3E");
   background-size: 0.6em;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
}

.header-text {
  margin-top: 5rem;
}
@media screen and (max-width: 576px) {
  .header-text {
    margin-top: 5rem;
    font-size: 1.8rem;
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
    opacity: 1;

}
.slide-enter {
  transform: translate(100%, 0);
    opacity: 0;

}
.slide-leave-to {
  transform: translate(-100%, 0);
    opacity: 0;

}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.5s;
  opacity: 1;
}
.slideback-enter {
  transform: translate(-100%, 0);
      opacity: 0;

}
.slideback-leave-to {
  transform: translate(100%, 0);
      opacity: 0;

}

.div-slider {
  position: relative;
  width: 100%;
}

.div-slider .slider-content {
  padding: 0 0.4em;
  width: 100%;
}
</style>
<template>
  <div>
    <div class="pr-container">
      <div>
        <div class="pr-body">
          <b-img class="m-auto py-3 cb-logo d-block" :src="require('@/assets/global/cb_logo.png')"></b-img>
          <p class="w-75 m-auto text-center" style="font-size:16px;">Before you join the class, please make sure your camera
            and microphone are working correctly.</p>
          <b-container class="pt-4">
            <b-row align-h="center" align-v="center">
              <b-col>
                <div id="pr-video-preview">
                  <video id="vs-video-preview" autoplay></video>
                  <audio id="vs-speaker-preview" autoplay></audio>
                  <div class="pr-btn-container">
                    <!-- Add inactive class -->
                    <b-button v-bind:class="['pr-btn active', { 'pr-btn inactive' : !this.isMicEnabled }]" @click="onMicMuteUnmuteClick">
                      <font-awesome-icon :icon="['fas', 'microphone']" />
                    </b-button>
                    <b-button v-bind:class="['pr-btn active', { 'pr-btn inactive' : !this.isCamEnabled }]" @click="onVideoOnOffClick">
                      <font-awesome-icon :icon="['fas', 'video']" />
                    </b-button>
                  </div>
                </div>
                <small class="d-block text-center">Video Preview</small>
              </b-col>
              <b-col>
                <div class="vs-settings-tabs">
                  <b-form-group label="Camera:">
                    <b-form-group label-for="dropdown-for-mic">
                      <b-form-select size="sm" v-model="videoCameraSelected" :options="videoCameraDevices" @change="onSelectVideoCamera"></b-form-select>
                    </b-form-group>
                  </b-form-group>
                  <b-form-group label="Microphone:">
                    <div class="d-flex" id="vs-settings-mic-preview">
                      <b-form-group label-for="dropdown-for-mic">
                        <b-form-select size="sm" v-model="audioInputSelected" :options="audioInputDevices" @change="onSelectAudioInput"></b-form-select>
                      </b-form-group>
                    </div>
                    <div class="volume-wrapper">
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                      <div class="volume"></div>
                    </div>
                  </b-form-group>
                  <b-form-group label="Speaker:">
                    <div class="d-flex" id="vs-settings-speaker-preview" v-if="this.browserType != 'Firefox' && this.browserType != 'Safari'">
                      <b-form-group label-for="dropdown-for-speaker " class="mr-2">
                        <b-form-select size="sm" v-model="audioOutputSelected" :options="audioOutputDevices" @change="onSelectAudioOutput"></b-form-select>
                      </b-form-group>
                    </div>
                    <b-button size="sm" class="vs-settings-btn w-100" @click="onSpeakerSoundTest">Test Speaker</b-button>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-container>

        </div>

        <div class="pr-buttons-container text-center">
          <b-button class="pr-btn-skip " @click="onJoinSessionClick">Join Session</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AlarmToneWav from '../../assets/wav/alarm_tone.wav';

export default {
  name: "SessionPreview",
  data() {
    return {
      audioInputDevices: [],
      audioOutputDevices: [],
      videoCameraDevices: [],
      audioInputSelected: "",
      audioOutputSelected: "",
      videoCameraSelected: "",
      isMicEnabled: true,
      isCamEnabled: true,
      audioContext: new AudioContext(),
      browserType: ""
    };
  },
  mounted() {
    let vm = this;
    vm.browserType = vm.detectBrowser();
    if(vm.browserType != "Safari"){
      navigator.permissions.query({ name: 'camera' }, { name: 'microphone' })
        .then(function(permissionStatus){
            permissionStatus.onchange = function(){
              if(this.state === "granted"){
                vm.$router.go(vm.$router.currentRoute);
              }
            }
        });
    }
    navigator.mediaDevices.enumerateDevices().then(vm.availableDevices).catch(vm.deviceErrorHandler);
    const audioSource = vm.audioInputSelected,
        videoSource = vm.videoCameraSelected,
        constraints = {
          audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
          video: { facingMode: "user", deviceId: videoSource ? { exact: videoSource } : undefined },
        };

    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    navigator.mediaDevices.getUserMedia(constraints).then(vm.onWindowStream).then(vm.onSystemCheckingElement).catch(vm.deviceErrorHandler);
  },
  methods: {
     onSelectVideoCamera(selectedVideo) {
      let vm = this;
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      vm.audioContext.close();
      const audioSource = vm.audioInputSelected,
          videoSource = selectedVideo,
          constraints = {
            audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
            video: { deviceId: videoSource ? { exact: videoSource } : undefined },
          };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(vm.onWindowStream)
        .catch(vm.deviceErrorHandler);
    },
    onSelectAudioInput(selectedAudio) {
      let vm = this;
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      vm.audioContext.close();
      const audioSource = selectedAudio,
          videoSource = vm.videoCameraSelected,
          constraints = {
            audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
            video: { deviceId: videoSource ? { exact: videoSource } : undefined },
          };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(vm.onWindowStream)
        .catch(vm.deviceErrorHandler);
    },
    onSelectAudioOutput(selectedAudio) {
      let vm = this,
        videoTestElement = document.querySelector("#vs-video-preview"),
        speakerTestElement = document.querySelector("#vs-speaker-preview");
      vm.audioOutputSelected = selectedAudio;
      speakerTestElement.setSinkId(vm.audioOutputSelected);
      vm.onTestAttachSinkId(videoTestElement, vm.audioOutputSelected);
    },
    onTestAttachSinkId(element, sinkId) {
      if (typeof element.sinkId !== "undefined") {
        element
          .setSinkId(sinkId)
          .catch((error) => {
            let errorMessage = error;
            if (error.name === "SecurityError") {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            alert(errorMessage);
          });
      } else {
        console.warn("Browser does not support output device selection.");
      }
    },
    onSpeakerSoundTest() {
        const speakerTestElement = document.querySelector("#vs-speaker-preview");
        speakerTestElement.controls = false;
        speakerTestElement.autoplay = true;
        speakerTestElement.src = AlarmToneWav;
    },
    onSystemCheckingElement(deviceInfos) {
      let vm = this;
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === "audioinput" && vm.audioInputSelected === "") {
          vm.audioInputSelected = deviceInfo.deviceId;
        } else if (deviceInfo.kind === "audiooutput" && vm.audioOutputSelected === "") {
          vm.audioOutputSelected = deviceInfo.deviceId;
        }
        else if (deviceInfo.kind === "videoinput" && vm.videoCameraSelected === "") {
          vm.videoCameraSelected = deviceInfo.deviceId;
        }
      }
    },
    onWindowStream(stream) {
      let vm = this,
        videoTestElement = document.querySelector("#vs-video-preview");
      window.stream = stream;
      videoTestElement.srcObject = stream;
      stream.getVideoTracks().forEach(function (track) {
        if(vm.videoCameraSelected === "" && vm.browserType == "Chrome")
          vm.videoCameraSelected = track.getCapabilities().deviceId;
        else
          vm.videoCameraSelected = track.getSettings().deviceId;
        track.enabled = vm.isCamEnabled;
      });
      stream.getAudioTracks().forEach(function (track) {
        track.enabled = vm.isMicEnabled;
      });
      if(vm.audioContext.state === "suspended")
      vm.audioContext.resume();
      vm.audioContext = (vm.audioContext.state === "closed") ? new AudioContext() : vm.audioContext;
      vm.onLoadSpectrum();
      return navigator.mediaDevices.enumerateDevices();
    },
    availableDevices(deviceInfos) {
      let vm = this; 
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        const option = document.createElement("option");
        option.value = deviceInfo.deviceId;
        if (deviceInfo.kind === "audioinput") {
          if (deviceInfo.deviceId == "default") {
            vm.audioInputSelected = deviceInfo.deviceId;
          }
          if(vm.audioInputDevices.filter(e => e.groupId === deviceInfo.groupId).length === 0){
            vm.audioInputDevices.push({
              groupId:deviceInfo.groupId,
              value: deviceInfo.deviceId,
              text: deviceInfo.label,
            });
          }
        } else if (deviceInfo.kind === "audiooutput") {
          if (deviceInfo.deviceId == "default") {
            vm.audioOutputSelected = deviceInfo.deviceId;
          }
          if(vm.audioOutputDevices.filter(e => e.groupId === deviceInfo.groupId).length === 0){
            vm.audioOutputDevices.push({
              groupId:deviceInfo.groupId,
              value: deviceInfo.deviceId,
              text: deviceInfo.label,
            });
          }
        } else if (deviceInfo.kind === "videoinput") {
          vm.videoCameraDevices.push({
            value: deviceInfo.deviceId,
            text: deviceInfo.label,
          });
        }
      }
    },
    deviceErrorHandler(error) {
      if (error.name == "NotAllowedError")
        alert(error.message + ": Please check your browser permission and allow the necessary devices");
      else
        alert("Please contact customer support: "+ error.message);
    },
    micVolumeIndicator(vol) {
      const volumes = [...document.querySelectorAll(".volume")],
        volumeBit = Math.round(vol / 10),
        volumeToColor = volumes.slice(0, volumeBit);
      for (const volColor of volumes) {
        volColor.style.backgroundColor = "#e6e7e8";
      }
      for (const volColor of volumeToColor) {
        volColor.style.backgroundColor = "#69ce2b";
      }
    },
    onMicMuteUnmuteClick() {
      let vm = this,
        tracks = window.stream.getAudioTracks();
      vm.isMicEnabled = !vm.isMicEnabled;
      tracks.forEach(function (track) {
        track.enabled = vm.isMicEnabled;
      });
    },
    onVideoOnOffClick() {
      let vm = this,
        tracks = window.stream.getVideoTracks();
      vm.isCamEnabled = !vm.isCamEnabled;
      tracks.forEach(function (track) {
        track.enabled = vm.isCamEnabled;
      });
    },
    onLoadSpectrum() {
      const vm = this,
        analyser = vm.audioContext.createAnalyser(),
        microphone = vm.audioContext.createMediaStreamSource(window.stream),
        scriptProcessor = vm.audioContext.createScriptProcessor(2048, 1, 1);

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;
      microphone.connect(analyser);
      analyser.connect(scriptProcessor);
      scriptProcessor.connect(vm.audioContext.destination);
      scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        vm.micVolumeIndicator(average);
      };
    },
    onJoinSessionClick() {
      let vm = this,
          record = {
            audioInputSelected: vm.audioInputSelected,
            audioOutputSelected: vm.audioOutputSelected,
            videoCameraSelected: vm.videoCameraSelected,
            isCamEnabled: vm.isCamEnabled,
            isMicEnabled: vm.isMicEnabled
          };
      if(vm.audioInputSelected === "" || vm.videoCameraSelected === ""){
        alert("Unable to join session, please check your browser permission and allow the necessary devices");
        return;
      }
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
      vm.$emit("join-session", record);
    },
    detectBrowser() { 
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return 'Opera';
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return 'Chrome';
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            return 'Firefox';
        } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
            return 'IE';
        } else {
            return 'Unknown';
        }
    }
  },
};
</script>

<style scoped>
.pr-btn-container {
  position: absolute;
  bottom: 15px;
  left: 10px;
  z-index: 99;
}

.pr-btn,
.pr-btn:focus,
.pr-btn:active {
  height: 2em;
  width: 2em;
  line-height: 1;
  text-align: center;
  margin-right: 0.2em;
  background: rgba(34, 34, 34, 50%);
  border-radius: 30px;
  position: relative;
  padding: 0;
}
.pr-btn.inactive::before {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    to right bottom,
    transparent,
    transparent 44%,
    #f44336 46%,
    #f44336 54%,
    transparent 56%,
    transparent
  ) !important;
}
.pr-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pr-body {
  padding: 1em;
}
#pr-video-preview {
  border-radius: 10px;
  position: relative;
  height: 80%;
  max-width: 450px;
  width: 100%;
  margin: auto;
}
.pr-buttons-container {
  padding: 2em;
}
.pr-btn-skip,
.pr-btn-skip:hover,
.pr-btn-skip:active,
.pr-btn-skip:focus,
.pr-btn-skip.btn-secondary:not(:disabled):not(.disabled):active {
  background: var(--light-blue);
  border-radius: 30px;
  font-size: var(--fs-one);
  font-weight: 600;
  padding: 0.5em 3em;
  text-transform: uppercase;
  margin-bottom: 1em;
}
.pr-btn-link {
  display: block;
  color: var(--light-blue);
  text-decoration: underline;
}

/* Settings Modal */
.vs-settings-tabs >>> .nav-link {
  color: var(--dark-grey);
}
.vs-settings-tabs >>> .nav-link.active {
  background: var(--light-blue);
  border-radius: 30px;
  font-weight: 700;
}
.vs-settings-tabs >>> .card-header {
  background: none;
  border-right: 1px solid var(--silver);
}
.vs-settings-tabs >>> .custom-select {
  border-radius: 35px;
}

.vs-settings-tabs
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--light-blue) !important;
  border-color: rgb(0 191 224 / 20%);
}

.vs-settings-tabs >>> .form-group {
  margin-bottom: .625rem;
}

.vs-settings-tabs >>> legend {
  font-size: calc(var(--fs-one) - 4px);
  font-weight: 600;
}

.vs-settings-btn,
.vs-settings-btn:active,
.vs-settings-btn:focus,
.vs-settings-btn.btn-secondary:not(:disabled):not(.disabled):active {
  border-radius: 30px;
  background: transparent;
  color: var(--dark-grey);
  border: 2px solid var(--light-blue);
  font-weight: 500;
}

.vs-settings-btn:hover {
  background: var(--light-blue);
  background-color: var(--light-blue);
  color: var(--white);
  border: 2px solid var(--light-blue);
  transition: all 200ms ease-in-out;
}

#vs-video-preview {
  border-radius: 10px;
  width: 100%;
}

/* Loading Style */
.vs-waiting-loader {
  --background: linear-gradient(
    135deg,
    var(--light-blue-20),
    var(--light-blue)
  );
  --shadow: rgba(39, 94, 254, 0.28);
  --text: #6c7486;
  --page: rgba(255, 255, 255, 0.36);
  --page-fold: rgba(255, 255, 255, 0.52);
  --duration: 3s;
  width: 200px;
  height: 140px;
  position: relative;
  margin: 0 auto;
}
.vs-waiting-loader :before,
.vs-waiting-loader :after {
  --r: -6deg;
  content: "";
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  transform: rotate(var(--r));
}
.vs-waiting-loader :before {
  left: 4px;
}
.vs-waiting-loader :after {
  --r: 6deg;
  right: 4px;
}
.vs-waiting-loader div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--shadow);
  background-image: var(--background);
}
.vs-waiting-loader div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.vs-waiting-loader div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--page);
  position: absolute;
  top: 10px;
  left: 10px;
  transform-origin: 100% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--duration) ease infinite;
  animation: var(--duration) ease infinite;
}
.vs-waiting-loader div ul li:nth-child(2) {
  --c: var(--page-fold);
  -webkit-animation-name: page-2;
  animation-name: page-2;
}
.vs-waiting-loader div ul li:nth-child(3) {
  --c: var(--page-fold);
  -webkit-animation-name: page-3;
  animation-name: page-3;
}
.vs-waiting-loader div ul li:nth-child(4) {
  --c: var(--page-fold);
  -webkit-animation-name: page-4;
  animation-name: page-4;
}
.vs-waiting-loader div ul li:nth-child(5) {
  --c: var(--page-fold);
  -webkit-animation-name: page-5;
  animation-name: page-5;
}
.vs-waiting-loader div ul li svg {
  width: 90px;
  height: 120px;
  display: block;
}
.vs-waiting-loader div ul li:first-child {
  --r: 0deg;
  --o: 1;
}
.vs-waiting-loader div ul li:last-child {
  --o: 1;
}
.vs-waiting-loader span {
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--text);
}

@-webkit-keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
  50%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%,
  100% {
    opacity: 0;
  }
  50%,
  100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
  65%,
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
  65%,
  100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%,
  100% {
    opacity: 0;
  }
  80%,
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%,
  100% {
    opacity: 0;
  }
  80%,
  100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
  95%,
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
  95%,
  100% {
    transform: rotateY(0deg);
  }
}
</style>

<style>
.vs-settings-modal-header > h5.modal-title {
  font-size: var(--fs-one);
}
.vs-settings-modal-body.modal-body {
  padding: 0;
}
.volume-wrapper {
  width: 100%;
}
.volume {
  width: calc(10% - 10px);
  height: 10px;
  display: inline-block;
  margin: 5px;
}
</style>

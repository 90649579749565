<template>
  <div>
    <div class="cb-parent-testimonial-container for-why-chalkboard">
      <div class="teal-bg py-4">
        <div class="container">
          <div class="cb-parent-testimonial-content cha">
            <figure class="cb-parent-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/open-quote.png')" alt="">
            </figure>
            <figure class="cb-parent-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
            </figure>
            <figure class="cb-parent-testimonial-img">
              <img :src="require('@/assets/imgs/why-chalkboard/cha-fajardo.jpg')" class="img-fluid hero-img" alt="Cha Fajardo">
            </figure>
            <blockquote> How I wish I found you sooner. Miss Wileth is such a good teacher. I remember posting in different groups just to look for a tutor for my daughter. Until a co-mama told me about Chalkboard, and she was right! You are a perfect fit for my daughter! <br>
              I realized that I should not be looking at where tutors earned their degree but rather focusing on their experiences and how they handle a child.
            </blockquote>
            <div class="cb-parent-testimonial-name">
              <p>Cha Fajardo</p>
              <p>Chalkboard parent</p>
              <p>Canada</p>
            </div>
          </div>
        </div>
      </div>
      <div class="orange-bg py-4">
        <div class="container">
          <div class="cb-parent-testimonial-content emily">
            <figure class="cb-parent-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/open-quote.png')" alt="">
            </figure>
            <figure class="cb-parent-testimonial-quotes">
              <img :src="require('@/assets/imgs/testimonials/close-quote.png')" alt="">
            </figure>
            <figure class="cb-parent-testimonial-img">
              <img :src="require('@/assets/imgs/why-chalkboard/emily-forgey.jpg')" class="img-fluid hero-img" alt="Emily Forgey">
            </figure>
            <blockquote>There are so many things I love about Chalkboard! As a mom of five very diverse children, I am impressed with the capability to find tutors to match the needs and personalities of each of my children. My daughter, Caroline, decided to take the ACT last year as a Freshman in high school. She learned about Chalkboard and signed up for twice weekly sessions with a tutor to cover courses she had not yet taken. When her scores came back, we were so thankful to have found Chalkboard!
            </blockquote>
            <div class="cb-parent-testimonial-name">
              <p>Emily Forgey</p>
              <p>Chalkboard parent</p>
              <p>Kentucky, USA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chalkboard-experience">
      <div class="container">
        <div class="experience text-center">
          <h5>Experience Chalkboard</h5>
          <p>Your best choices are with us. As our tutors were carefully screened and evaluated, we assure parents and learners that all of our tutors are high caliber.</p>
          <b-link to="/search" class="cb-book-now-link">Book Now</b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerFeedback",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cb-parent-testimonial-content.cha {
  background: var(--light-blue-20);
  margin-right: 20rem;
  margin-left: 5rem;
  margin-top: 8rem;
}
.cb-parent-testimonial-content.emily {
  background: var(--orange-20);
  margin-left: 20rem;
  margin-right: 5rem;
}

.cb-parent-testimonial-content.cha .cb-parent-testimonial-img img {
  object-position: 50% 20%;
  margin-right: -17rem;
  shape-outside: circle(66.79% at 81px 12px);
  float: right;
  padding: 0.625em;
  background: var(--light-blue-20);
}
.cb-parent-testimonial-content.emily .cb-parent-testimonial-img img {
  object-position: 50% 20%;
  margin-left: -17rem;
  margin-right: unset;
  shape-outside: ellipse(74px 75px at 4px 60px);
  float: left;
  padding: 0.625em;
  background: var(--orange-20);
}
.cb-parent-testimonial-content.emily
  .cb-parent-testimonial-quotes:nth-child(1) {
  left: 120px;
}

@media screen and (max-width: 38em) {
  .cb-parent-testimonial-content.cha,
  .cb-parent-testimonial-content.emily {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 20rem;
  }

  .cb-parent-testimonial-content.cha .cb-parent-testimonial-img img,
  .cb-parent-testimonial-content.emily .cb-parent-testimonial-img img {
    margin-right: unset;
    margin-left: unset;
    float: unset;
  }
  .cb-parent-testimonial-content.emily
    .cb-parent-testimonial-quotes:nth-child(1) {
    left: unset;
  }
}
</style>
<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12" lg="7">
        <b-card no-body>
          <b-card-header class="p-1 light-blue-bg">

          </b-card-header>

          <b-card-body>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form id="parent-profile-form" @submit.prevent="handleSubmit(onSubmit)">

                <!-- Profile Image -->
                <div class="text-center py-2">
                  <b-avatar
                    id="main-profile-img"
                    :src="imageUrl"
                    size="6rem"
                    v-bind="mainProps"
                    :class="{ 'cursor-pointer': formDisabled ? false : true }"
                  ></b-avatar>
                  <div class="font-size-lg pb-4"><strong>{{ ProfileName }}</strong></div>
                   <b-tooltip :disabled.sync="disabled" target="main-profile-img" placement="right" hover="true">
                    <small>Click to upload new image</small>
                  </b-tooltip>

                  <ValidationProvider name="image" rules="image|size:5000" v-slot="validationContext">
                    <b-form-file id="file-upload" v-model="form.image" accept="image/*" @change="onFileChange" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled" style="display:none;"></b-form-file>
                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </ValidationProvider>

                </div>

                <!-- Email Address and Birthday -->
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Email Address" label-for="email">
                      <ValidationProvider name="email" rules="required|email" v-slot="validationContext">
                        <b-form-input id="email" v-model="form.email" type="email" required placeholder="Enter email address" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                        <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-2" label="Birthday" label-for="birthday">
                      <ValidationProvider name="birthday" rules="required" v-slot="validationContext">
                        <b-form-input id="birthday" v-model="form.birthday" type="date" required size="sm" name="birthday" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Firstname , Lastname and Mobile number -->
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="First Name" label-for="firstname">
                      <ValidationProvider name="first name" rules="required" v-slot="validationContext">
                        <b-form-input id="firstname" v-model="form.firstname" type="text" required placeholder="Enter first name" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                        <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Last Name" label-for="lastname">
                      <ValidationProvider name="last name" rules="required" v-slot="validationContext">
                        <b-form-input id="lastname" v-model="form.lastname" type="text" required placeholder="Enter last name" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                        <b-form-invalid-feedback id="input-5-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                 
                </b-row>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Phone Number" label-for="mobile_no">
                    <ValidationProvider name="phone number" :rules="`required|validatePhoneNumber:${JSON.stringify(eventPNData)}`" v-slot="validationContext">
                      <PhoneNumberInput
                        required
                        id="mobile_no"
                        v-model="form.mobile_no"
                        :disabled="formDisabled"
                        @update="onPNUpdate($event)"
                        :default-country-code="`${form.pn_country_code || 'US'}`"
                        :error="validationContext.errors[0] ? true : false"
                      />
                      <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                    </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="WhatsApp" label-for="whatsapp">
                      <ValidationProvider name="whatsapp" :rules="`validatePhoneNumber:${JSON.stringify(eventWAData)}`" v-slot="validationContext">
                        <PhoneNumberInput
                          id="whatsapp"
                          v-model="form.whatsapp"
                          :disabled="formDisabled"
                          @update="onWAUpdate($event)"
                          :default-country-code="`${form.wa_country_code || 'US'}`"
                          :error="validationContext.errors[0] ? true : false"
                        />
                        <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Street and Barangay -->
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Address 1" label-for="street">
                      <ValidationProvider name="address 1" v-slot="validationContext">
                        <b-form-input id="street" v-model="form.street" type="text" placeholder="Enter address 1" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-7-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Address 2" label-for="brgy">
                      <ValidationProvider name="address 2" v-slot="validationContext">
                        <b-form-input id="brgy" v-model="form.brgy" type="text" placeholder="Enter address 2" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-8-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- City, Province and Zip code -->
                <b-row>
                  <b-col sm="12" lg="4">
                    <b-form-group id="input-group-1" label="City" label-for="city">
                      <ValidationProvider name="city" rules="required" v-slot="validationContext">
                        <b-form-input id="city" v-model="form.city" type="text" required placeholder="Enter city name" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-9-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>

                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-form-group id="input-group-1" label="Province" label-for="province">
                      <ValidationProvider name="province" rules="required" v-slot="validationContext">
                        <b-form-input id="province" v-model="form.province" type="text" required placeholder="Enter province name" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-10-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-form-group id="input-group-1" label="Zip Code" label-for="zip_code">
                      <ValidationProvider name="zip code" v-slot="validationContext">
                        <b-form-input id="zip_code" v-model="form.zip_code" type="text" placeholder="Enter zipcode" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Country" label-for="country">
                      <ValidationProvider name="country" rules="required" v-slot="validationContext">
                        <CountrySelect
                          required
                          size="md"
                          id="country"
                          v-model="form.country"
                          :disabled="formDisabled"
                          :errorMessage="!form.country ? validationContext.errors[0] : ''"
                          :successful="!!form.country"
                        />
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="What is your education approach?" label-for="education_approach">
                      <ValidationProvider name="education approach" rules="required" v-slot="validationContext">
                        <b-form-select v-model="form.education_approach" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                          <b-form-select-option v-for="(item, index) in educationalApproachData" :key="index" :value="item.code_type_value">{{ item.code_description }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="How did you hear about us?" label-for="referral_source">
                      <ValidationProvider name="referral source" rules="required" v-slot="validationContext">
                        <b-form-select v-model="form.referral_source" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled" @change="onReferralSourceChange">
                          <b-form-select-option v-for="(item, index) in howDidYouHearAboutUsData" :key="index" :value="item.code_type_value">{{ item.code_description }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group id="input-group-1" label="Referral Code" label-for="referral_code" v-show="isEnableReferralCode">
                      <ValidationProvider name="referral code" v-slot="validationContext">
                        <b-form-input id="referral_code" v-model="form.referral_code" type="text" placeholder="Enter referral code" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="py-3 d-flex justify-content-end">

                  <b-button id="btn-edit-profile" type="button" variant="primary" v-if="enableEditButton" v-on:click.prevent="enableEditButton = false; formDisabled = false;" @click="disabled = !disabled">
                    <span>Edit Profile</span>
                  </b-button>

                  <b-button id="btn-cancel" type="button" variant="primary" v-if="!enableEditButton" v-on:click.prevent="enableEditButton = true; formDisabled = true; loadUserData();" class="mr-1" @click="onCancel">
                    <span>Cancel</span>
                  </b-button>

                  <b-button id="btn-submit" type="submit" variant="primary" v-if="!enableEditButton" :disabled="isCheckingValidUpload" @click="disabled = !disabled">
                    <b-spinner small v-if="isLoading"></b-spinner>
                    <span v-if="isLoading"> saving data...</span>
                    <span v-if="!isLoading">Save</span>
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import mixins from "../../../mixins";
import { mapState } from "vuex";
import PhoneNumberInput from '../../../utility/forms/PhoneNumberInput.vue';
import CountrySelect from '../../../utility/forms/CountrySelect.vue';
export default {
  name: "ProfileLearner",
  components: { PhoneNumberInput, CountrySelect },
  data: () => ({
    disabled: true,
    mainProps: {
      blank: true,
      blankColor: "#777",
      width: 128,
      height: 128,
      class: "m1",
    },
    form: {
      image: null,
      birthday: "",
      email: "",
      firstname: "",
      lastname: "",
      mobile_no: "",
      street: "",
      brgy: "",
      city: "",
      province: "",
      zip_code: "",
      imageFileName: "",
      whatsapp: "",
      country: "",
      education_approach: "",
      referral_source: "",
      referral_code: "",
      pn_country_code: "",
      wa_country_code: "",
      formattedPhoneNumber: "",
      formattedWhatsAppNumber: "",
      pn_area_code: "",
      wa_area_code: ""
    },
    ProfileName: "",
    UserToken: window.localStorage.getItem("userToken"),
    isLoading: false,
    imageUrl: require("@/assets/placeholder/profile-placeholder.png"),
    enableEditButton: true,
    formDisabled: true,
    isCheckingValidUpload: false,
    eventPNData: {},
    eventWAData: {}
  }),
  beforeMount() {
    this.$store.dispatch('getEducationalApproach')
    this.$store.dispatch('getHowDidYouHearAboutUs')
  },
  created() {
    this.loadUserData();
  },
  computed: {
    ...mapState(['educationalApproachData', 'howDidYouHearAboutUsData']),
    isEnableReferralCode () {
      const source = ['FMFRND', 'SCGROR']
      return source.includes(this.form.referral_source)
    }
  },
  mounted() {
    document
      .getElementById("main-profile-img")
      .addEventListener("click", function () {
        document.getElementById("file-upload").click();
      });
  },
  methods: {
    onPNUpdate(e) {
      this.form.pn_country_code = e.countryCode
      this.form.formattedPhoneNumber = e.formattedNumber
      this.form.pn_area_code = e.countryCallingCode
      this.eventPNData = e
    },
    onWAUpdate(e) {
      this.form.wa_country_code = e.countryCode
      this.form.formattedWhatsAppNumber = e.formattedNumber
      this.form.wa_area_code = e.countryCallingCode
      this.eventWAData = e
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.imageUrl = URL.createObjectURL(file);

      if (file.size / 1024 > this.validProfileFileSize) {
        this.ShowModal(
          "The image you are trying to upload is too large.",
          null,
          `<strong>${this.convertByteToMegaByte(
            this.validProfileFileSize
          )}MB Uploading Limit</strong>`
        );
        this.loadUserData();
        return;
      }

      const formData = new FormData();

      const params = {
        file: file,
        original_name: file.name,
        type: "photo",
        document_type: "photo",
      };

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      const ImageApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/multi-signup/temp-upload";
      const ImageOptions = {
        headers: {
          Accept: "application/json, multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.isCheckingValidUpload = true;

      this.axios
        .post(ImageApiURL, formData, ImageOptions)
        .then((data) => {
          const _data = data.data;
          if (_data.status == 200) {
            this.form.imageFileName = _data.data.server_name;
            this.imageUrl = _data.data.path;
          } else if (_data.status == 422) {
            this.ShowModal(_data.errors.file);
            this.imageUrl = this.imagePlaceholder;
            this.form.imageFileName = "";
            this.form.image = null;
          }
        })
        .catch((error) => {
          console.log("fn method hook => onFileChange(): " + error.response);
        })
        .finally(() => (this.isCheckingValidUpload = false));
    },
    onSubmit() {
      const UserInfoApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/update-user-info";
      const UserInfoOptions = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      const formData = new FormData();

      const params = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        mobile_no: this.form.formattedPhoneNumber,
        birthday: this.form.birthday,
        street: this.form.street || '',
        province: this.form.province,
        city: this.form.city,
        brgy: this.form.brgy || '',
        zip_code: this.form.zip_code || '',
        type: "account",
        whatsapp: this.form.formattedWhatsAppNumber || '',
        country: this.form.country,
        education_approach: this.form.education_approach,
        referral_source: this.form.referral_source,
        referral_code: this.form.referral_code || '',
        pn_country_code: this.form.pn_country_code,
        wa_country_code: this.form.whatsapp ? this.form.wa_country_code : '',
        pn_area_code: this.form.pn_area_code,
        wa_area_code: this.form.whatsapp ? this.form.wa_area_code : ''
      };

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      if (this.form.imageFileName != null) {
        formData.append("image", this.form.imageFileName);
      }

      this.isLoading = true;

      this.axios
        .post(UserInfoApiURL, formData, UserInfoOptions)
        .then((data) => {
          const _data = data.data;
          if (_data.status == 422) {
            this.ShowModal(data.errors);
          } else {
            this.ShowModal("Learner profile successfully updated.");
            this.formDisabled = true;
            this.enableEditButton = true;
            this.isDisableReferralCode = true;
            setTimeout(() => {
              this.loadUserData();
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("fn method hook => onSubmit(): " + error.response);
        })
        .finally(() => (this.isLoading = false));
    },
    loadUserData() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;

          this.form.image = null;
          this.form.birthday = _data.birthday;
          this.form.email = _data.email;
          this.form.firstname = _data.firstname;
          this.form.lastname = _data.lastname;
          this.form.mobile_no = _data.mobile_no;
          this.form.street = _data.street;
          this.form.brgy = _data.brgy;
          this.form.city = _data.city;
          this.form.province = _data.province;
          this.form.zip_code = _data.zip_code || '';
          this.form.whatsapp = _data.whatsapp || '';
          this.form.country = _data.country;
          this.form.education_approach = _data.education_approach;
          this.form.referral_source = _data.referral_source;
          this.form.referral_code = _data.referral_code || '';
          this.form.pn_country_code = _data.pn_country_code;
          this.form.wa_country_code = _data.wa_country_code || '';
          this.form.pn_area_code = _data.pn_area_code || '';
          this.form.wa_area_code = _data.wa_area_code || '';

          this.ProfileName = this.form.firstname + " " + this.form.lastname;
          this.imageUrl =
            _data.image == null ? this.imagePlaceholder : _data.image;
          this.form.imageFileName =
            _data.image == null
              ? ""
              : _data.image.substr(
                  _data.image.lastIndexOf("/") + 1,
                  _data.image.length - _data.image.lastIndexOf("/")
                );

          this.$nextTick(() => {
            try {
              this.$refs.observer.reset();
            } catch {
              //
            }
          });
        }).catch(() => {});
    },
    onReferralSourceChange() {
      if (!this.isEnableReferralCode) {
        this.form.referral_code = ''
      }
    },
    onCancel() {
      this.disabled = !this.disabled
      this.isDisableReferralCode = true
    }
  },
  mixins: [mixins],
};
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
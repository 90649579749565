<template>
  <div>
    <div id="what-sets-us-apart" class="matrix">
      <div class="container">
        <div class="matrix-header">
          <h5>What sets us apart</h5>
          <h6>See how Chalkboard compares to other tutoring sources.</h6>
        </div>
        <div class="matrix-table">
          <div class="table-responsive">
            <table class="table">
              <thead class="matrix-table-header">
                <tr>
                  <th class="column-one"></th>
                  <th class="column-rest chalkboard-logo" scope="col">
                    <img :src="require('@/assets/imgs/why-chalkboard/logo-regular-stacked.png')" class="img-fluid">
                  </th>
                  <th class="column-rest">Traditional Tutors</th>
                  <th class="column-rest">Brick &amp; Mortar Tutorial Center</th>
                  <th class="column-rest">Other Tutoring Platforms</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="head" scope="row">Thorough vetting of tutors by a team of academic professionals</th>
                  <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td></td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <th class="head" scope="row">Personalized learning</th>
                  <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Instant and flexible scheduling</th>
                <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Rates are affordable and set by tutors</th>
                <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Approachable role models</th>
                  <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Long-term relationship</th>
                <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Guaranteed experts</th>
                 <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Access to expert substitute tutors</th>
                <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                </tr>
                <tr>
                  <th class="head" scope="row">Access to specialized programs</th>
                 <td>
                    <font-awesome-icon class="cb" :icon="['fas', 'check']" />
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    <font-awesome-icon class="others" :icon="['fas', 'check']" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaftformsComparison",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
import Vue from 'vue';
import axios from 'axios';

export const state = Vue.observable({
    isHistoryStateEnabled: false,
    isBusy: false,
    isSearched: false,
    search: '',
    items: [],
    sessionTypeOptions: [
        { value: "Hourly", text: "Hourly", disabled: false },
        { value: "Bundle", text: "Bundle", disabled: true },
    ],
    sessionTypeSelected: "Hourly",
    subjects: [],
    selectedSubjects: [],
    gradeLevels: [
        { value: 13, text: "Preschool", level_grouping: 1 },
        { value: 1, text: "Grade 1", level_grouping: 1 },
        { value: 2, text: "Grade 2", level_grouping: 1 },
        { value: 3, text: "Grade 3", level_grouping: 1 },
        { value: 4, text: "Grade 4", level_grouping: 1 },
        { value: 5, text: "Grade 5", level_grouping: 1 },
        { value: 6, text: "Grade 6", level_grouping: 1 },
        { value: 7, text: "Grade 7", level_grouping: 1 },
        { value: 8, text: "Grade 8", level_grouping: 1 },
        { value: 9, text: "Grade 9", level_grouping: 1 },
        { value: 10, text: "Grade 10", level_grouping: 1 },
        { value: 11, text: "Grade 11", level_grouping: 1 },
        { value: 12, text: "Grade 12", level_grouping: 1 },
        { value: 14, text: "Tech", level_grouping: 2 },
        { value: 15, text: "Music", level_grouping: 2 },
        { value: 16, text: "Languages", level_grouping: 2 },
        { value: 17, text: "Art", level_grouping: 2 },
        { value: 18, text: "Others", level_grouping: 3 }
    ],
    selectedGradeLevels: [],
    availability: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ],
    selectedAvailability: [],
    time: [],
    selectedTime: [],
    sortOptions: [
        { value: "lowest_rate", text: "Lowest Price" },
        { value: "highest_rate", text: "Highest Price" },
        { value: "lowest_rating", text: "Lowest Rating" },
        { value: "highest_rating", text: "Highest Rating", selected: true },
    ],
    sortSelected: "highest_rating",
    rows: 0,
    perPage: 20,
    currentPage: 1,
    isViewMoreSubjects: false,
    isViewMoreGradeLevels: false,
    isViewMoreAvailability: false,
    isViewMoreTime: false,
    selectedSearchItems: [],
    level_category: '',
    rateRangeMin: '',
    rateRangeMax: '',
    minRateValue: 330,
    maxRateValue: 1200,
    guestTZ: null
});

export const getters = {
    search: {
        get() { return state.search },
        set(val) { state.search = val }
    },
    items: () => state.items,
    isBusy: () => state.isBusy,
    isSearched: () => state.isSearched,
    isHistoryStateEnabled: () => state.isHistoryStateEnabled,
    sessionTypeOptions: () => state.sessionTypeOptions,
    sessionTypeSelected: () => state.sessionTypeSelected,
    subjects: () => state.subjects,
    selectedSubjects: {
        get() { return state.selectedSubjects },
        set(val) { state.selectedSubjects = val }
    },
    gradeLevels: () => state.gradeLevels,
    selectedGradeLevels: {
        get() { return state.selectedGradeLevels },
        set(val) { state.selectedGradeLevels = val }
    },
    availability: () => state.availability,
    selectedAvailability: {
        get() { return state.selectedAvailability },
        set(val) { state.selectedAvailability = val }
    },
    time: () => state.time,
    selectedTime: {
        get() { return state.selectedTime },
        set(val) { state.selectedTime = val }
    },
    sortOptions: () => state.sortOptions,
    sortSelected: {
        get() { return state.sortSelected },
        set(val) { state.sortSelected = val }
    },
    rows: () => state.rows,
    perPage: () => state.perPage,
    currentPage: {
        get() { return state.currentPage },
        set(val) { state.currentPage = val }
    },
    isViewMoreSubjects: {
        get() { return state.isViewMoreSubjects },
        set(val) { state.isViewMoreSubjects = val }
    },
    isViewMoreGradeLevels: {
        get() { return state.isViewMoreGradeLevels },
        set(val) { state.isViewMoreGradeLevels = val }
    },
    isViewMoreAvailability: {
        get() { return state.isViewMoreAvailability },
        set(val) { state.isViewMoreAvailability = val }
    },
    isViewMoreTime: {
        get() { return state.isViewMoreTime },
        set(val) { state.isViewMoreTime = val }
    },
    selectedSearchItems: () => state.selectedSearchItems,
    level_category: () => state.level_category,
    rateRangeMin: {
        get() { return state.rateRangeMin },
        set(val) { state.rateRangeMin = val }
    },
    rateRangeMax: {
        get() { return state.rateRangeMax },
        set(val) { state.rateRangeMax = val }
    },
    minRateValue: {
        get() { return state.minRateValue },
        set(val) { state.minRateValue = val }
    },
    maxRateValue: {
        get() { return state.maxRateValue },
        set(val) { state.maxRateValue = val }
    },
    guestTZ: {
        get() { return state.guestTZ },
        set(val) { state.guestTZ = val }
    }
}

export const mutations = {

}

export const actions = {

}

export const methods = {
    onSearch(currentPage) {

        state.currentPage = currentPage

        state.isSearched = true;

        const searchData = new FormData();
        searchData.append("keyword", state.search);
        searchData.append("sort_by", state.sortSelected);
        searchData.append("level_category", state.level_category);
        searchData.append("count", state.perPage);
        searchData.append("page", currentPage);
        searchData.append("guestTz", this.guestTZ);
        searchData.append("rate", state.rateRangeMin + '-' + state.rateRangeMax)
        searchData.append("tutor_id", state.tutor_id)

        if (this.selectedSubjects.length > 0) {
            searchData.append('subject', this.selectedSubjects.toString());
        }

        if (this.selectedGradeLevels.length > 0) {
            searchData.append('level', this.selectedGradeLevels.toString());
        }

        if (this.selectedAvailability.length > 0) {
            searchData.append('day', this.selectedAvailability.toString());
        }

        if (this.selectedTime.length > 0) {
            const UTCTimeSel = []
            const currDate = this.moment.tz(this.guestTZ)
            this.selectedTime.forEach(time => {
                const UTCDateStart = this.moment.tz(currDate.format('YYYY-MM-DD') + ' ' + time.split('-')[0], this.guestTZ).utc()
                const UTCDateEnd = this.moment.tz(currDate.format('YYYY-MM-DD') + ' ' + time.split('-')[1], this.guestTZ).utc()
                UTCTimeSel.push(UTCDateStart.format('HH:mm:ss') + '-' + UTCDateEnd.format('HH:mm:ss'))
            })
            searchData.append('time', UTCTimeSel.toString());
        }

        const paramData = new URLSearchParams(searchData);

        const searchApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/user/teacher-list?" + paramData;
        const searchOptions = {
            headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${this.UserToken}`,
            },
        };

        state.isBusy = true;

        axios
        .get(searchApiURL, searchOptions)
        .then((data) => {
            const _data = data.data.data;
            state.rows = _data.total
            state.items = _data.data;

            // SEARCH HISTORY API
            if (state.isHistoryStateEnabled) {

                const historyStates = {
                    keyword: state.search,
                    sort_by: state.sortSelected,
                    count: state.perPage,
                    page: state.currentPage,
                    rateMin: this.rateRangeMin,
                    rateMax: this.rateRangeMax,
                    subjects: state.selectedSubjects,
                    grade_level: state.selectedGradeLevels,
                    isViewMoreSubjects: state.isViewMoreSubjects,
                    isViewMoreGradeLevels: state.isViewMoreGradeLevels,
                    isViewMoreAvailability: state.isViewMoreAvailability,
                    isViewMoreTime: state.isViewMoreTime,
                    availability: state.selectedAvailability,
                    time: state.selectedTime,
                    level_category: state.level_category,
                    selectedSearchItems: state.selectedSearchItems
                };
                
                window.history.pushState(historyStates, null, window.location.pathname + window.location.search);
            }

        }).catch((error) => { console.log(error.response); })
        .finally(() => {
            try {
                const position = window.scrollY + document.getElementById('search-container').getBoundingClientRect().top;
                window.scrollTo({ top: position - 110, behavior: 'smooth' })
                state.isBusy = false;
            } catch {
                //
            }
        });

    },
    onSortChange(sort) {
        state.sortSelected = sort
        this.onSearch(1)
        state.isHistoryStateEnabled = true;
    },
    onPageClick(page) {
        this.onSearch(page)
        state.isHistoryStateEnabled = true;
    },
    onBookNow(tutorID) {
        window.localStorage.setItem("tutorId", tutorID);
        window.localStorage.removeItem('rebookData')
        this.$router.push('/booking')
    },
    onApplySearch(selected_category) {
        
        state.selectedSearchItems.filter(si => { return si.category === selected_category}).forEach(item => {
            const index = state.selectedSearchItems.indexOf(item)
            state.selectedSearchItems.splice(index, 1)
        })

        if (selected_category === 'subject') {
            state.selectedSubjects.forEach(item => {
                if (state.selectedSearchItems.filter(i => { return i.category === selected_category && i.value === item }).length === 0) {
                    state.selectedSearchItems.push({
                        category: selected_category,
                        value: item,
                        text: item
                    })
                }
            })
        }

        if (selected_category === 'grade_level') {
            state.selectedGradeLevels.forEach(item => {
                if (state.selectedSearchItems.filter(i => { return i.category === selected_category && i.value === item }).length === 0) {
                    state.selectedSearchItems.push({
                        category: selected_category,
                        value: item,
                        text: state.gradeLevels.filter(x => { return x.value === item})[0].text
                    })
                }
            })
        }

        if (selected_category === 'availability') {
            state.selectedAvailability.forEach(item => {
                if (state.selectedSearchItems.filter(i => { return i.category === selected_category && i.value === item }).length === 0) {
                    state.selectedSearchItems.push({
                        category: selected_category,
                        value: item,
                        text: item
                    })
                }
            })
        }

        if (selected_category === 'time') {
            state.selectedTime.forEach(item => {
                if (state.selectedSearchItems.filter(i => { return i.category === selected_category && i.value === item }).length === 0) {
                    state.selectedSearchItems.push({
                        category: selected_category,
                        value: item,
                        text: state.time.filter(x => { return x.value === item})[0].text
                    })
                }
            })
        }

        if (selected_category === 'rate') {
            if (this.onRateValidation()) return
        }

        this.onSearch(1);
        state.isHistoryStateEnabled = true;

    },
    onItemRemove (category, value) {

        let result = state.selectedSearchItems.find(item => { return item.category === category && item.value === value})
        let index = state.selectedSearchItems.indexOf(result)
        state.selectedSearchItems.splice(index, 1)

        if (category === 'subject') {
            state.selectedSubjects.splice(state.selectedSubjects.indexOf(value), 1)
        } else if (category === 'grade_level') {
            state.selectedGradeLevels.splice(state.selectedGradeLevels.indexOf(value), 1)
        } else if (category === 'availability') {
            state.selectedAvailability.splice(state.selectedAvailability.indexOf(value), 1)
        } else if (category === 'time') {
            state.selectedTime.splice(state.selectedTime.indexOf(value), 1)
        }

        this.onSearch(1);
        state.isHistoryStateEnabled = true;
    },
    onClearAll () {
        state.selectedSearchItems = []
        state.selectedSubjects = []
        state.selectedGradeLevels = []
        state.selectedAvailability = []
        state.selectedTime = []
        this.onSearch(1);
        state.isHistoryStateEnabled = true;
    },
    onRateValidation () {
        let result = false
        if (state.rateRangeMin < state.minRateValue) {
            this.ShowModal('Minimum rate must not be less than ' + state.minRateValue)
            result = true
        } else if (parseInt(state.rateRangeMin) > state.maxRateValue) {
            this.ShowModal(`Minimum rate must not be greater than maximum rate (${parseInt(state.maxRateValue).toFixed(2)}).`)
            result = true
        } else if (parseInt(state.rateRangeMax) < state.minRateValue) {
            this.ShowModal('Maximum rate must not be less than ' + state.minRateValue)
            result = true
        } else if (state.rateRangeMax == null || state.rateRangeMax == '') {
            this.ShowModal('Maximum rate must not be null or empty.')
            result = true
        }
        return result
    }
}
<template>
  <div :data-id="`thread_box_${data.thread_id}`" class="chat-bubble-box">
      <b-card no-body style="width: 25em;" header-class="bg-white" footer-class="bg-white">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="chat-bubble-box-avatar">
              <b-avatar size="2.5em" :src="data.data.image"></b-avatar>
              <span class="chat-bubble-box-avatar-status" :class="{ online: (data.data.chat_status || 'offline') === 'online', offline: (data.data.chat_status || 'offline') === 'offline' }"></span>
            </div>
             <b-dropdown size="" variant="link" toggle-class="text-decoration-none" left no-caret class="chat-bubble-box-head text-left">
              <template #button-content>
                <div class="text-left d-flex">
                  <div>
                    <div class="mb-0 chat-bubble-box-tutor-name" v-if="data">{{ `${data.data.firstname} ${getFChar(data.data.lastname)}.` }}</div>
                    <div class="chat-bubble-box-status">{{ (data.data.chat_status || 'offline') === 'online' ? 'Online' : 'Offline' }}</div>
                  </div>
                  <div class="chat-bubble-box-dropdown-icon"></div>
                </div>
              </template>
            <b-dropdown-item-button href="" v-if="userData.role !== 'teacher'">
                <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                  <font-awesome-icon class="ml-n1 dark-silver" :icon="['fas', 'user']" />
                </div>
                <span class="" style="font-size:1rem;" @click="onViewProfile(data.data.user_id)">
                  View Profile
                </span>
              </b-dropdown-item-button>
              <!-- <b-dropdown-item-button href="">
                <div class="mr-1" style="width: 1.2em;display: inline-block;text-align: center;">
                  <font-awesome-icon class="dark-silver" :icon="['fas', 'user-plus']" />
                </div>
                <span style="font-size:1rem;">
                  Add Customer Support
                </span>
              </b-dropdown-item-button> -->
            </b-dropdown>
            <div class="ml-auto">
              <span class="chat-bubble-box-control minimize mr-2" v-b-tooltip.hover title="Minimize">
                <font-awesome-icon class="" :icon="['fas', 'minus']" @click="onMinimizeChatBox(data)"/>
              </span>
              <span class="chat-bubble-box-control remove" v-b-tooltip.hover title="Close">
                <font-awesome-icon class="" :icon="['fas', 'times']" @click="onChatBoxClose(data)"/>
              </span>
            </div>
          </div>
        </template>
        <div class="chat-bubble-box-body">
          <b-media>
            <div class="text-center py-5">
              <b-avatar size="5em" :src="data.data.image"></b-avatar>
              <div class="pt-3 dark-silver">
                You are now connected with
                <div>{{ this.getRoleName(data.data.role) }} {{ `${data.data.firstname} ${getFChar(data.data.lastname)}.` }} Say Hello!</div>
              </div>
            </div>
            <ChatBubbleMessage :data="messages" :dataSource="data" :last_seen="last_seen" :userData="userData" :isMessageLoading="isMessageLoading"/>
          </b-media>
        </div>
        <div class="text-cb-color" style="text-align: left; margin-left: 10px;" v-if="isSendingFileAttachment">
          <b-spinner class="align-middle" small></b-spinner>
          <strong><small> sending file attachment...</small></strong>
        </div>
        <template #footer>
          <div class="d-flex">
            <div class="position-relative w-100">
              <div id="emoji-mart-list" class="row emoji-selector-list" style="display: none">
                <picker set="emojione" @select="showEmoji" title="Chalkboard Emoji"/>
              </div>
              <b-textarea v-model="chatMessage" @focus="onChatMessageFocus" :disabled="isSendingFileAttachment || data.is_active === 0" @keydown.enter.exact.prevent @keyup.enter="sendMessage" class="chat-bubble-box-type-message" placeholder="Type a message.." no-resize></b-textarea>
              <span class="chat-bubble-box-type-message-emoji" v-b-tooltip.hover title="Emoji">
                <font-awesome-icon class="" :icon="['fas', 'smile']" id="show-emoji-icon" @click="onEmojiClick"/>
              </span>
            </div>
            <div class="d-flex align-items-end">
              <div class="chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || data.is_active === 0 }" v-b-tooltip.hover title="Add attachment">
                <font-awesome-icon class="" v-if="data.is_active === 0" :icon="['fas', 'file']" />
                <font-awesome-icon class="" v-else :icon="['fas', 'file']" @click="onUploadAttachment"/>
                <b-form-file style="display: none" id="chatFileUpload" v-model="attachFiles" @change="onFileUploadChange($event, data.thread_id)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".jpg, .jpeg, .png, .docx, .doc, .ppt, .xls, .xlsx, .pdf, .zip, .rar"></b-form-file>
              </div>
              <div class="chat-bubble-box-type-message-buttons" :class="{ disabled: isSendingFileAttachment || data.is_active === 0 }" v-b-tooltip.hover title="Send message" @click="sendMessage">
                <font-awesome-icon class="ml-n1" :icon="['fas', 'paper-plane']"/>
              </div>
            </div>
          </div>
        </template>
      </b-card>
    </div>
</template>

<script>
import mixins from '../../mixins'
import { Picker } from 'emoji-mart-vue'
import ChatBubbleMessage from './ChatBubbleMessage.vue'
import { store } from '../../store';
import router from '../../router'
import chatMixins from '../../mixins/chat'
export default {
  props: ['data', 'userData'],
  components: {
    Picker,
    ChatBubbleMessage
  },
  data: () => ({
    chatMessage: '',
    selectedThread: null,
    items: [],
    messages: null,
    last_seen: null,
    attachFiles: [],
    attachFileName: '',
    attachOrigFileName: '',
    isMessageLoading: false,
    isSendingFileAttachment: false
  }),
  computed: {},
  mounted() {

    this.items = this.data
    this.last_seen = this.items.data.last_read !== null ? this.moment.tz(this.items.data.last_read, 'UTC').tz(this.userData.timezone).format('hh:mm A ll') : null
    this.isMessageLoading = true
    store.dispatch('loadChatMessages', { thread_id: this.items.thread_id, search_keyword: '' }).then(response => {
      this.messages = response.data.data
      this.scrollToBottom();
    }).finally(() => { this.isMessageLoading = false })
    this.scrollToBottom();

    window.Echo.private(`chat-user-status.${this.data.data.user_id}`)
      .listen('.Modules\\Message\\Events\\Web\\ChatUserStatus', (e) => {
        this.items.data.chat_status = e.status
      })
      
    window.Echo.private(`read-thread-message.${this.data?.thread_id}.${this.data.data.user_id}`)
      .listen('.Modules\\Message\\Events\\Web\\MarkMessageRead', (e) => {
        this.last_seen = this.moment.tz(e.last_seen, 'UTC').tz(this.userData.timezone).format('hh:mm A ll')
        this.scrollToBottom()
      })

    window.addEventListener('mouseup',function(event){
        var emoji = document.getElementById('emoji-mart-list');
        if (emoji) {
          if(event.target.parentNode != emoji && !document.getElementById('emoji-mart-list')?.contains(event.target)) {
            emoji.style.display = 'none'
          }
        }
    });

    store.dispatch('setUserChatStatus', { 'user_id': this.userData.user_id, 'status': 'online' })

    this.ListenOnChatMessage();

  },
  methods: {
    ListenOnChatMessage() {
      const chatUserID = this.items.data.user_id
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${chatUserID}`)
        .listen('.Modules\\Message\\Events\\Web\\SendChatMessage', (e) => {
          if (parseInt(e.sender) === chatUserID) {
            this.chatNotifSound()
            this.messages.push(e)
            this.scrollToBottom();
          }
        })
    },
    removeAttachment() {
      this.attachFiles = []
      this.attachFileName = ''
      this.attachOrigFileName = ''
    },
    onUploadAttachment() {
      document.getElementById('chatFileUpload').click()
    },
    onFileUploadChange(e, thread_id) {
      let file = e.dataTransfer || e.target;
      file = file.files[0];
      if (file) {
        const validSize = [
          { type: "file", size: "5000" },
          { type: "photo", size: "5000" },
        ];
        let allowedExt = [
          {
            ext: ["txt", "docx", "doc", "ppt", "pptx", "xls", "xlsx", "pdf", "zip", "rar"],
            type: "file",
          },
          { ext: ["jpg", "jpeg", "png"], type: "photo" },
        ];
        const filename = file.name.toLowerCase();
        const ext = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
        const fileType = allowedExt.filter((x) => { return x.ext.includes(ext); }).map((x) => x.type)[0];
        const allowedSize = parseInt(validSize.filter((x) => { return x.type === fileType; }).map((x) => x.size)[0]);
        allowedExt = [].concat.apply([], allowedExt.map((x) => x.ext)).join("|");
        const pattern = new RegExp(`(${allowedExt})$`);
        if (file.size / 1024 > allowedSize) {
          this.ShowModal(
            `The ${fileType} that you are trying to upload is too large.<br /><br /><b>Upload Details:</b><br />
              Name: ${file.name}<br />
              Size: ${this.formatBytes(file.size)}<br />
              Type: ${
                ext === "rar" ? "application/x-rar-compressed" : file.type
              }`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName ="";
            }, `<strong>${this.convertByteToMegaByte(allowedSize)}MB Uploading Limit</strong>`);
          return;
        } else if (!pattern.test(filename)) {
          this.ShowModal(
            `The file must be a file of type: ${allowedExt.replaceAll(
              "|",
              ", "
            )}.`,
            () => {
              this.attachFiles = [];
              this.attachFileName = "";
              this.attachOrigFileName = "";
            }
          );
          return;
        }
        const formData = new FormData();
        const params = {
          attachment_file: file,
          thread_id: thread_id,
        };
        Object.keys(params).forEach((key) => { formData.append(key, params[key]); });
        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/upload-chat-attachment`,
              options = {
                headers: {
                  Accept: "application/json, multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: `Bearer ${this.UserToken}`,
                },
              };
        this.isSendingFileAttachment = true
        this.axios
          .post(ApiURL, formData, options)
          .then(response => {
            if (response.status === 200) {
              this.attachFileName = response.data.data.server_attachment;
              this.attachOrigFileName = response.data.data.original_name;
              this.chatMessage = this.attachOrigFileName
              this.sendMessage()
            } else {
              this.attachFiles = [];
              this.attachFileName = ""
              this.attachOrigFileName = ""
            }
          }).catch(() => {
            this.attachFiles = [];
            this.attachFileName = ""
            this.attachOrigFileName = ""
          }).finally(() => {});
      }
    },
    onViewProfile(tutorId) {
      if (this.userData.role !== 'teacher') {
        window.localStorage.setItem("tutorId", tutorId);
        let routeData = router.resolve({name: 'search_teacher_profile'});
        window.open(routeData.href, '_blank')
      }
    },
    onEmojiClick() {
      const emojis = document.getElementById('emoji-mart-list')
      emojis.style.display = 'block';
      emojis.style.position = 'absolute';
      emojis.style.top = '-420px';
    },
    showEmoji(emoji) {
      this.chatMessage = this.chatMessage + emoji.native
    },
    sendMessage() {
      if (this.chatMessage === '') return
      const attachment = this.attachOrigFileName === '' ? null : this.attachOrigFileName
      if (attachment === null) {
        this.messages.push({
          thread_id: this.data.thread_id,
          user_id: this.userData.user_id,
          message: this.chatMessage,
          created_at: this.moment.tz(this.userData.timezone).utc(),
          attachment_name: null,
          attachment_url: null
        })
      }
      const param = {
        'thread_id': this.items.thread_id,
        'message': this.chatMessage,
        'server_attachment': this.attachFileName,
        'attachment_file': this.attachOrigFileName,
        'recipient': this.items.data.user_id
      }
      const formData = new FormData();
      Object.keys(param).forEach(key => { formData.append(key, param[key]) })

      const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/message/send-chat`
      const options = {
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
      }
      this.axios.post(ApiURL, formData, options).then(response => {
        if (attachment !== null) {
          this.messages.push({
            thread_id: this.data.thread_id,
            user_id: this.userData.user_id,
            message: this.chatMessage,
            created_at: this.moment.tz(this.userData.timezone).utc(),
            attachment_name: attachment,
            attachment_url: response.data.data.attachment_url
          })
        }
      }).finally(() => {
        if (attachment !== null) {
          this.chatMessage = ''
          this.last_seen = null
          this.scrollToBottom()
          this.isSendingFileAttachment = false
          this.attachFiles = [];
          this.attachFileName = ""
          this.attachOrigFileName = ""
        }
      })
      if (attachment === null) {
        this.chatMessage = ''
        this.last_seen = null
        this.scrollToBottom()
      }
    },
    onMinimizeChatBox(data) {
      const SelectedChatBubbleHead = document.querySelectorAll(`[data-id="bubble_head_${data.thread_id}"]`)
      const ActiveChatBubbleHead = document.querySelectorAll('[data-id*="bubble_head_"]').length
      if (SelectedChatBubbleHead.length === 0 && ActiveChatBubbleHead <= 3) {
        if (ActiveChatBubbleHead === 3) document.querySelectorAll('[data-id*="bubble_head_"]')[0].remove()
        store.state.selectedInboxChatThread = []
        data.is_read = true
        store.state.selectedBubbleChatHead = data
      }
      this.removeThreadBox(data.thread_id)
    },
    onChatBoxClose(data) {
      store.state.selectedInboxChatThread = []
      data.is_read = true
      store.state.selectedBubbleChatHead = data
      const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${data.thread_id}']`)
      if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()
      this.removeThreadBox(data.thread_id)
    },
    removeThreadBox(thread_id) {
      const ChatBubbleThreadBox = document.querySelector(`[data-id='thread_box_${thread_id}']`)
      if (ChatBubbleThreadBox) ChatBubbleThreadBox.remove()
    },
    scrollToBottom() {
      const chatBubbleBoxBody = document.querySelector(`[data-id=thread_box_${this.items.thread_id}] .chat-bubble-box-body`)
      if (chatBubbleBoxBody)
        setTimeout(() => {
          chatBubbleBoxBody.scrollTo({ top: chatBubbleBoxBody.scrollHeight, behavior: "smooth" });
        }, 100)
    },
    onChatMessageFocus() {
      if (this.last_seen !== null) {
        this.readMessage()
      }
    },
    readMessage() {
      const payload = {
        thread_id: this.data.thread_id,
        is_read: 0,
      };
      store.dispatch("toggleReadMessage", payload);
    }
  },
  mixins: [mixins, chatMixins]
}
</script>

<style scoped>
.chat-bubble-box {
  margin-left: 0.525rem;
  position: relative;
  bottom: 0;
  height: auto;
}
.chat-bubble-box .card {
  border-radius: 1em 1em 0 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  height: 100%;
}
.chat-bubble-box .card .card-header {
  border-radius: 1em 1em 0 0;
}
.chat-bubble-box-head >>> .btn:focus.dropdown-toggle.btn-link {
  box-shadow: none;
}
.chat-bubble-box-head
  >>> [role="presentation"]
  [type="button"].dropdown-item.active,
.chat-bubble-box-head
  >>> [role="presentation"]
  [type="button"].dropdown-item:active {
  background-color: var(--light-silver);
  color: inherit;
  outline: none;
}

.chat-bubble-box-avatar {
  position: relative;
}

.chat-bubble-box-avatar-status {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 0.525em;
  height: 0.525em;
}
.chat-bubble-box-dropdown-icon {
  border-top: 0.3em var(--light-blue) solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-top: 1.3em;
  margin-left: 1em;
}

.chat-bubble-box-avatar-status.online {
  background: var(--teal);
}

.chat-bubble-box-avatar-status.offline {
  background: var(--dark-silver);
}

.chat-bubble-box-body {
  max-height: 20em;
  min-height: 20em;
  overflow-y: scroll;
  padding: 1.5em;
  height: 100%;
}
.chat-bubble-box-body .media-aside {
  margin-right: 0.625rem;
}

.chat-bubble-box-message {
  display: inline-block;
  background: #f6f6f6;
  color: var(--dark-grey);
  font-size: calc(var(--fs-one)- 2px);
  border-radius: 10px;
  padding: 0.875em;
  margin-bottom: 0.825rem;
}
.chat-bubble-box-message-status {
  display: inline-block;
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
  margin-bottom: 1rem;
}
.chat-bubble-box-message-status.me {
  float: right;
}

.chat-bubble-box-message.me {
  background: var(--light-blue);
  color: var(--white);
  float: right;
}

.chat-bubble-box-type-message:focus,
.chat-bubble-box-type-message:active {
  background-color: #f0f2f5;
  outline: none;
  box-shadow: none;
}
.chat-bubble-box-type-message::placeholder {
  color: var(--dark-silver);
}
.chat-bubble-box-type-message {
  background: #f0f2f5;
  border-radius: 25px;
  color: var(--dark-grey);
  line-height: 1.2;
  overflow-y: unset !important;
  padding-top: 0.82em;
  padding-left: 0.82em;
  padding-right:  2.725em;
  word-break: break-all;
  height: 45px;
  max-height: 75px;
  border: none;
  font-size: calc(var(--fs-one) - 2px);
}
.chat-bubble-box-type-message-emoji {
  position: absolute;
  right: 25px;
  bottom: 0;
  font-size: 20px;
  line-height: 2.2;
  color: var(--dark-silver);
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f0f2f5;
  text-align: center;
  line-height: 2.2;
  margin-left: 0.425rem;
  color: var(--dark-silver);
  font-size: 20px;
  cursor: pointer;
}
.chat-bubble-box-type-message-buttons:hover {
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  transition: all ease 500ms;
}

.chat-bubble-box-type-message-buttons.disabled,
.chat-bubble-box-type-message-buttons.disabled:hover {
  cursor: not-allowed;
  color: var(--dark-silver);
}

.chat-bubble-box-tutor-name {
  font-weight: 500;
  color: var(--dark-grey);
  cursor: pointer;
  max-width: 10em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-bubble-box-status {
  font-size: calc(var(--fs-one) - 4px);
  color: var(--dark-silver);
}
.chat-bubble-box-control {
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 191, 224, 20%);
  color: var(--light-blue);
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  font-size: calc(var(--fs-one) - 2px);
  font-weight: normal;
  line-height: 1.4;
  cursor: pointer;
}

.chat-bubble-head-container {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.chat-bubble-head {
  border-radius: 50%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  position: relative;
}
.chat-bubble-head-red-notif {
  position: absolute;
  top: 0;
  right: -2px;
  background: #f95656;
  border-radius: 50%;
  width: 0.825em;
  height: 0.825em;
  text-align: center;
}
.chat-bubble-head-close {
  position: absolute;
  top: 0;
  right: -3px;
  display: none;
  background: var(--white);
  border-radius: 50%;
  color: var(--dark-grey);
  width: 1.225em;
  height: 1.225em;
  text-align: center;
  line-height: 1.4;
  font-size: calc(var(--fs-one) - 4px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}
.chat-bubble-head:hover > .chat-bubble-head-close {
  display: block;
}
.cb-dashboard-container {
  padding-top: 6.5em;
}
.text-cb-color {
  color: var(--light-blue) !important;
}
</style>
<template>
    <div class="review-modal">
        <b-container>
            <b-form>
                <!-- Modal content -->
                <div class="review-modal-content">
                    <!-- Modal header -->
                    <header class="text-center light-blue-bg" style="border-radius: 10px 10px 0 0;">
                        <div>
                            <b-avatar style="margin-top:-4rem;" size="8rem" :src="imageUrl"></b-avatar>
                        </div>
                        <div class="py-4 font-weight-bold text-white font-size-md">{{ headerTitle }}</div>
                    </header>

                    <ReviewSection
                        v-if="isRatingsAndReviews"
                        :ratingsAndReviewsData="ratingsAndReviewsData"
                        :request="request"
                        :mode='mode'
                        @onSubmit="onSubmit"
                        @onNextTime="onNextTime"
                    />
                    <FeedbackSection :payload="payload" v-if="isShowFeedback"/>
                    <ReportSection :payload="payload" v-if="isShowReport" @onOkClick="onOkClick" @onGetHelp="onGetHelp"/>
                    <ReportGetHelp v-if="isShowGetHelp" @onReportCancel="onReportCancel"/>

                </div>
            </b-form>
        </b-container>
    </div>
</template>

<script>
import ReviewSection from './ReviewSection.vue'
import FeedbackSection from './FeedbackSection.vue'
import ReportSection from './ReportSection.vue'
import ReportGetHelp from './ReportGetHelp.vue'
import mixins from '../../mixins'
export default {
    props: ['modalToShow', 'mode', 'ratingsAndReviewsData', 'request'],
    name: 'RatingsAndReviews',
    components: {
        ReviewSection,
        FeedbackSection,
        ReportSection,
        ReportGetHelp
    },
    data: () => ({
        headerTitle: '',
        isRatingsAndReviews: false,
        isShowFeedback: false,
        isShowReport: false,
        payload: {
            rating: null,
            tutorName: '',
            isRedirected: false
        },
        isLoading: false,
        imageUrl: '',
        isShowGetHelp: false
    }),
    mounted() {
        if (this.modalToShow == 'RatingsAndReviews') {
            this.isRatingsAndReviews = true
            this.isShowFeedback = false
            this.isShowReport = false
            const tutorName = (this.ratingsAndReviewsData.tutor_name !== null) ? this.ratingsAndReviewsData.tutor_name : ''
            this.headerTitle = `How was your experience with Tutor ${tutorName} ?`
        } else if (this.modalToShow == 'Feedback') {
            this.isRatingsAndReviews = false
            this.isShowFeedback = true
            this.isShowReport = false
        } else if (this.modalToShow == 'Report') {
            this.payload = this.ratingsAndReviewsData
            this.headerTitle = `Rated by ${this.ratingsAndReviewsData.reviewed_by}`
            this.isRatingsAndReviews = false
            this.isShowFeedback = false
            this.isShowReport = true
        }
        this.imageUrl = this.ratingsAndReviewsData.image
    },
    methods: {
        onSubmit(payload) {
               
            let params = []

            const ApiURL = process.env.VUE_APP_BACKEND_URL + `/api/web/review/${this.mode.toLowerCase()}-review`
            const options = {
                headers: this.getGetRequestHeaders()
            }

            if (this.mode.toLowerCase() == 'update') {
                const item = this.ratingsAndReviewsData
                params = {
                    review_id: item.review_id,
                    booking_id: item.booking_id,
                    // tutor_id: item.tutor_id,
                    rating: payload.rating,
                    review: payload.review,
                    recommendation: payload.recommendation,
                    remarks: payload.remarks
                }
            } else {
                params = {
                    booking_id: this.ratingsAndReviewsData.booking_id,
                    // tutor_id: this.ratingsAndReviewsData.tutor_id,
                    rating: payload.rating,
                    review: payload.review,
                    recommendation: payload.recommendation,
                    remarks: payload.remarks
                }
            }

            const formData = new FormData();

            Object.keys(params).forEach(key => {
                formData.append(key, params[key])
            });

            var postApi = () => {

                this.axios.post(ApiURL, formData, options).then(data => {
                    const _data = data.data;
                    if (_data.status == 422) {
                        this.ShowModal(_data.message)
                    } else {
                        if (this.mode.toLowerCase() == 'update') {
                            this.$emit('onUpdateRequestSuccess', true)
                        } else {
                            this.headerTitle = 'Thank you for your feedback'
                            this.isRatingsAndReviews = false
                            this.isShowFeedback = true
                            this.payload = payload
                        }
                    }
                })

            }

            if (this.mode.toLowerCase() == 'update') {

                this.showCustomConfirmModal('<div class="text-center py-3"><div class="pb-3"><strong>Are you sure you want to submit these changes? </strong><div><br> <small>Note: You can only edit this rating or review once. Ratings and reviews become final seven days from session date.</small></div>', () => {
                    postApi()
                }, 'Confirm changes?', 'Cancel', 'Proceed')

            } else {
                postApi()
            }

        },
        onNextTime() {
            if (this.mode.toLowerCase() == 'update') {
                this.$emit('onUpdateRequestSuccess', true)
            } else {
                if (this.request == 'session-history') {
                    this.$emit('onUpdateRequestSuccess', true)
                } else if (this.request == 'video-session') {
                    window.location.href = '/schedule'
                }
            }
        },
        onOkClick() {
            this.$emit('onReportOkClicked')
        },
        onGetHelp() {
            this.isShowGetHelp = true
            this.isShowReport = false
        },
        onReportCancel() {
            this.isShowGetHelp = false
            this.isShowReport = true
        }
    },
    mixins: [mixins]
}
</script>

<style scope>

.modal-footer.custom-modal .btn-primary {
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--light-blue);
}
.modal-footer.custom-modal .btn-secondary {
    background-color: white;
    border: 1px solid var(--dark-silver);
    color: var(--dark-silver);
}
.b-rating-star-empty .text-warning {
  color: var(--dark-silver) !important;
}

.review-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    z-index: 1040;
}

/* Modal Content/Box */
.review-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

.btn-secondary.blue-button.disabled {
    border: 1px solid silver !important;
}
</style>
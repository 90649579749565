<template>
  <div>
<section class="section py-5">
    <div class="container px-5">
        <h1 class="is-size-1 has-text-centered has-text-weight-bold dark-grey mb-6">Privacy Policy</h1>

        <div class="content">
            <h3 class="is-size-5 my-4 dark-grey">
                Introduction
            </h3>
            Chalkboard (TheChalkboard Inc.) is an online marketplace designed to help parents and learners
            connect to the best tutors and to serve as a venue for quality learning experience. We are committed
            to give the best service to our customers above and beyond. <br><br>
            We respect the fundamental rights of all individuals to the privacy of their personal data, and we
            commit in the safeguarding and lawful treatment of all information provided. Further, we developed
            this Privacy Policy to observe appropriate standards and comply with the requirements and applicable
            privacy laws, particularly to Republic Act No. 10173, otherwise known as Data Privacy Act of 2012
            (“Data Privacy Act”) and its implementing rules and regulations.

            <h3 class="is-size-5 my-4 dark-grey">
                Information that we Collect
            </h3>
            We collect your personal information in various ways. You provide us your personal information by
            signing up on our website, and correspondences via email, phone, or social media platforms. Personal
            information includes those provided when you sign up to our online platform, contact information
            forms, and communications with our product and customer support team.<br><br>
            The personal information that you will provide us may contain your name, email address, home
            address, phone number, username, password and other shareable data. This personal information
            may come in various forms such as digital records, video recordings, and photographic images. Digital
            records and photographic images include company, school, training records and/or professional
            affiliation. <br><br>
            Similar to other online platforms, we receive technical information when clients use our Services. This
            information is used to evaluate and improve our services and platform, as well as saving your log-in
            information for future sessions.

            <h2 class="is-size-3 my-4 dark-grey">Tutors</h2>

            <h3 class="is-size-5 my-4 dark-grey">
                Registration to our Platform
            </h3>
            Personal information is collected during the sign-up and registration in our platform. These include
            details such as your name, address, email addresses, and contact numbers. We may also collect other
            information such as academic records, work experience, certifications, etc. We will also be collecting
            information needed to facilitate your payouts, such as your back account details, and tax identification
            number.

            <h3 class="is-size-5 my-4 dark-grey">
                Building your Profile
            </h3>
            As a freelancer, you need to create a profile that will be publicly available. These include your personal
            information, username, photographs, information from relevant previous work, hourly pay rates, and
            interests and preferences. In cases such as an unauthorized profile you believe has been created
            containing information about you, you can request for an immediate action by contacting us at
            <a class="light-blue" href="mailto: support@chalkboard.com.ph ">support@chalkboard.com.ph</a> or             <a class="light-blue" href="mailto: dpo@chalkboard.com.ph">dpo@chalkboard.com.ph</a>.

            <h3 class="is-size-5 my-4 dark-grey">
                Collaboration using the Platform
            </h3>
            Tutors, as freelancers, may interact with other freelance tutors inside the community through the
            platform. If you wish to collaborate on learning delivery techniques and teaching styles, you may use
            the platform to do so. Likewise, tutors and learners may communicate with each other through the
            platform service. You may discuss anything related to the tutoring session from lessons to learner
            assessment. Interaction may be in a form of one-on-one communication via chat feature, which may be
            recorded and stored in our servers for future reference.

            <h3 class="is-size-5 my-4 dark-grey">
                Records and Identity Verification
            </h3>
            As our guarantee to provide expert tutors, we will be verifying your identity and records. For you to
            be verified, copies of your records such as academic records, employment history, training certificates,
            government-issued IDs, and clearance certificate will be required. Online interview and demo
            teaching will be mandatory as well as part of our application process and identity verification.

            <h3 class="is-size-5 my-4 dark-grey">
                Use, Share and Disclosure of Information
            </h3>
            Personal information may be used and disclosed due to the following:
            1. Protecting the rights of Chalkboard, the management and its stakeholders;
            2. Legal purposes such as exercising legal rights or against legal claims, or
            3. As otherwise mandated by law.

            <h3 class="is-size-5 my-4 dark-grey">
                Use of Information
            </h3>
            We use your personal information to fulfill our agreement with our stakeholders, and to attain our
            genuine interests as an online tutorial platform. These include processing your registration in our
            platform, managing tutor profile and account, conducting investigation and disciplinary measures
            relative to tutor behavior, communicating official Chalkboard announcements, and delivering
            customer service. Likewise, your personal information will be used in processing payouts for the
            services rendered.<br><br>
            We also use your personal information to conduct maintenance activities to gauge the effectiveness
            and improve our services in terms of quality and safety. Activities include product and service
            enhancement, identity verification and protection against fraudulent and malicious activity, strict
            enforcement of Chalkboard’s Terms and Conditions and company policies and fulfilling our legal
            obligations for the interest of our stakeholders.

            Sharing and Disclosure of Information
            We share and disclose your personal information for service provision in using the platform, and legal
            purposes as mandated by law. We have partnered with third party service providers to perform
            service-related activities such as payouts processors, and information and IT-related services. They
            may be given access to your personal information, with your consent, to perform the task on our
            behalf. We may also share your personal information, again with your permission, for marketing
            activities and company promotion, and collaboration with our business partners. We do not share or
            disclose your personal information except for the provisions stated above.

            <h2 class="is-size-3 my-4 dark-grey">Parents &amp; Learners</h2>
            Personal information of the parents, legal guardians, or sponsors is collected during the sign-up and
            registration in our platform such as your name, address, email addresses, and contact numbers. We do
            not collect personal information of learners below 18 years old. Parents, legal guardians, or sponsors

            must provide their full consent for the collection, use, sharing, and disclosure of a learner’s personal
            information.
            In such instances, learner’s basic personal information is collected during sign-up and registration of
            Parents, legal guardians, or sponsors in our platform. This includes learner’s nominated username in
            the platform.

            <h3 class="is-size-5 my-4 dark-grey">
                Storage and Retention of Information
            </h3>
            Personal information is stored securely in our company server on AWS. We retain important personal
            information indefinitely for records purposes, unless otherwise prohibited by law. As such, personal
            information is disposed securely after a specified retention period.<br><br>
            Personal information collected through forms via website and social media platforms, as well as
            marketing activities is stored indefinitely. If for a period longer than six months a person does not
            communicate with us, all contact information will be securely removed from our database.

            <h3 id="cookies" class="is-size-5 my-4 dark-grey">
                Security of Personal Information in our platform
            </h3>
            Chalkboard’s online platform employs the following security measures for the protection of its
            stakeholders: passwords, resource access authentication, secured profiles, secured communication,
            secured e-commerce, secured storage, and secured servers. Personal passwords are encrypted with
            unique SALT values, and with the website using HTTPS. Regular security audits are being run
            periodically on our site for detection of security issues and information breach.

            <h3  class="is-size-5 my-4 dark-grey ">
                Cookies
            </h3>
            Our platform uses cookies, like many websites, when you access our services. These are vital
            as they allow us to collect information used to authenticate the login process, and evaluate the
            performance of our platform. For further information on what cookies we are using, please
            read our Cookie Policy.
            <h3 class="is-size-5 my-4 dark-grey">
                Safeguarding Personal Information
            </h3>
            We recognize your rights to your personal data, as such, safety of all personal information is our top
            priority. In accordance with the Data Privacy Act, Chalkboard put into effect policies and procedures
            to carry out applicable level of security for the personal information that we collect, process, store and
            dispose. Chalkboard also requires our partners, to whom we disclose sensitive personal information,
            to implement similar handling and processing of personal information.<br><br>
            In case of breach of data involving personal information you have provided us, or question regarding
            your rights as afforded by the Data Privacy Act, or concern in this Data Privacy Policy, you may reach
            our Data Protection Officer via the following channels:

            <ul>
                <li>
                    Email: info@chalkboard.com.ph
                </li>
                <li>
                    Contact Number: +63 977 824-0414
                </li>
                <li>
                    Postal Address: HQ: Unit 3, 2 nd Floor, Bldg., 607 Sampson Rd., Subic Bay Freeport Zone,
                    Philippines, 2222
                </li>
                <li>
                    Virtual Address: Penthouse 1, One Corporate Center, Meralco Ave., cor. Julia Vargas Ave.,
                    Ortigas Center, Pasig City, Philippines, 1800
                </li>
            </ul>

            <h3 class="is-size-5 my-4 dark-grey">
                Amendments to this Privacy Policy
            </h3>
            Periodically, we may make modifications to this Privacy Policy, without prior notice. On such
            instances, we will upload the revised policy on our website <a class="light-blue" href="https://chalkboard.com.ph/">(www.chalkboard.com.ph)</a>. Changes are
            acted upon posting on the website. If there are substantial changes that involves the collection and/or
            use of your Personal Information, we will notify you through our platform.<br><br>
            This Privacy Policy is effective October 15, 2020.
        </div>
    </div>
</section>

  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
</style>
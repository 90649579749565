<template>
  <div>
    <cool-select
      v-bind="$attrs"
      :items="items"
      item-value="code"
      item-text="name"
      @input="$emit('input', $event)"
    >
      <template slot="item" slot-scope="{ item: data }">
        <div style="display: flex; align-items: center;">
          <img :src="getFlag(data.code)" class="country-flag"/>
          <div>{{ data.name }}</div>
        </div>
      </template>
    </cool-select>
  </div>
</template>

<script>
  import { CoolSelect } from 'vue-cool-select'
  import countries from '../../assets/countries'
  export default {
    components: { CoolSelect },
    data: () => ({
      items: countries,
    }),
    methods: {
      getFlag(flagName) {
        try {
          return `${process.env.VUE_APP_BACKEND_URL}/country-flags/${flagName.toLowerCase()}.svg`;
        } catch (e) {
          return `${process.env.VUE_APP_BACKEND_URL}/country-flags/undefined.svg`;
        }
      }
    }
  }
</script>

<style scoped>
  .country-flag {
    max-width: 30px;
    margin-right: 10px;
    border: 1px solid #EAECF0;
  }
</style>
<!-- TODO - statically built to meet time constaints, rebuild for modularity later on or purge implementation after MCDO23 promo condludes -->
<!-- TODO - retain TBD -->
<template>
  <div class="voucher-details" ref="voucherDetails">
    <div class="voucher-details__header">
      <b-button size="sm" @click="closeAppModal()"><xmark /></b-button>

      <div class="voucher-details__title">
        <div class="voucher-details__image">
          <b-img :src="require('@/assets/imgs/cb-voucher.png')"></b-img>
        </div>
        <div class="voucher-details__main-text">
          Free 3 Sessions
        </div>
        <div class="voucher-details__sub-text">
          Expires in 5 days
        </div>
      </div>
    </div>

    <div class="voucher-details__body">
      <div class="voucher-details__card">
        <!-- TODO - MCDO23 voucher details are outdated -->
        <div>Terms & Conditions</div>
        <ul>
          <li>Free 3 hours session for math, reading or coding only</li>
          <li>Single use voucher (1 transaction)</li>
          <li>Tutor rate must be 500 and below</li>
          <li>Vouchers are valid from March 1-31</li>
          <li>Vouchers are non transferable</li>
          <li>Valid in the Philippines only</li>
          <li>1 redemption per user only</li>
        </ul>
        <b-button
          v-if="showAction"
          class="voucher-list__button voucher-list__button_details"
          @click="useVoucher()"
        >
          Use voucher
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import xmark from "../../assets/imgs/x-mark.svg?inline";

export default {
  name: "McDoVoucherDetailsModal",

  components: {
    xmark
  },

  props: {
    showAction: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    useVoucher() {
      this.$emit("use-voucher", "MCDO23");
      this.closeAppModal();
    }
  }
};
</script>

<template>
  <div>
    <li :data-id="`bubble_head_${data.thread_id}`" class="mb-2 position-relative chat-bubble-list" :title="`${data.data.firstname} ${getFChar(data.data.lastname)}.`">
      <div class="chat-bubble-head" @click="onChatBubbleHeadClick(data)">
        <b-avatar size="3em" :src="data.data.image"></b-avatar>
        <span class="chat-bubble-head-red-notif" v-if="!data.is_read"></span>
      </div>
      <span class="chat-bubble-head-close">
        <font-awesome-icon class="" :icon="['fas', 'times']" @click="onChatHeadClose(data)"/>
      </span>
    </li>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  import { store } from '../../store'
  export default {
    props: ['data', 'userData'],
    mounted() {
      window.Echo.private(`send-chat-message.${this.userData.user_id}.${this.data.data.user_id}`)
        .listen('.Modules\\Message\\Events\\Web\\SendChatMessage', (e) => {
          if (parseInt(e.thread_id) === this.data.thread_id && e.sender === this.data.data.user_id) {
            this.data.is_read = false
          }
        })
    },
    methods: {
      onChatBubbleHeadClick(data) {
        const SelectedChatBubbleBox = document.querySelectorAll(`[data-id="thread_box_${data.thread_id}"]`)
        const ActiveChatBubbleBox = document.querySelectorAll('[data-id*="thread_box_"]').length
        if (SelectedChatBubbleBox.length === 0 && ActiveChatBubbleBox <= 2) {
          if (ActiveChatBubbleBox === 2) document.querySelectorAll('[data-id*="thread_box_"]')[0].remove()
          store.state.selectedInboxChatThread = data;
        }
        const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${data.thread_id}']`)
        if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()
        store.state.selectedBubbleChatHead = []
        const payload = {
          thread_id: data.thread_id,
          is_read: 0,
        };
        store.dispatch("toggleReadMessage", payload);
      },
      onChatHeadClose(data) {
        const SelectedChatBubbleHead = document.querySelector(`[data-id='bubble_head_${data.thread_id}']`)
        if (SelectedChatBubbleHead) SelectedChatBubbleHead.remove()
      }
    },
    mixins: [mixins]
  }
</script>

<style scoped>
.chat-bubble-head {
  border-radius: 50%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1;
}
.chat-bubble-head-red-notif {
  position: absolute;
  top: 0;
  right: -2px;
  background: #f95656;
  border-radius: 50%;
  width: 0.825em;
  height: 0.825em;
  text-align: center;
  z-index: 2;
}
.chat-bubble-head-close {
  position: absolute;
  top: 0;
  right: -3px;
  display: none;
  background: var(--white);
  border-radius: 50%;
  color: var(--dark-grey);
  width: 1.225em;
  height: 1.225em;
  text-align: center;
  line-height: 1.4;
  font-size: calc(var(--fs-one) - 4px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  z-index: 3;
}
.chat-bubble-list:hover > .chat-bubble-head-close {
  display: block;
}
</style>
<template>
  <div>

  </div>
</template>

<script>
  export default {
    name: 'HeaderBanner',
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
<template>
  <input
    class="form-control"
    :class="state === false ? 'is-invalid' : ''"
    :disabled="disabled"
    @input="updateCardNumber"
    @keydown="handleKeydown"
    @keypress="handleKeypress"
    @mousedown="handleMousedown"
    @copy="handleCopy"
    @paste="handlePaste"
    ref="cardInput"
    maxlength="19"
  />
</template>

<script>
export default {
  name: "CardInputBox",

  props: {
    value: {
      type: [Number, String],
      default: ""
    },

    disabled: {
      type: Boolean
    },

    state: {
      type: Boolean
    }
  },

  methods: {
    updateCardNumber(e) {
      const input = e.target;
      let value = e.target.value.replace(/\D/g, "");
      let cursorPosition = input.selectionStart;
      const selectionEnd = input.selectionEnd;
      this.$emit("input", value);
      input.value = value.replace(/(\d{4})(?=\d)/g, "$1 ");

      if (
        this.value.length == 0 ||
        (this.value.length <= input.value.length &&
          input.value.length < selectionEnd)
      ) {
        input.setSelectionRange(input.value.length + 1, input.value.length + 1);
      } else {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }
    },

    handleKeydown(e) {
      const input = e.target;
      let cursorPosition = input.selectionStart;
      const value = input.value;
      const selectionEnd = input.selectionEnd;

      if (e.key === "Backspace" && cursorPosition === selectionEnd) {
        if (value[cursorPosition - 2] === " ") {
          if (cursorPosition >= value.length) {
            input.setSelectionRange(cursorPosition - 2, cursorPosition);
          }
        } else if (value[cursorPosition - 1] === " ") {
          input.setSelectionRange(cursorPosition - 2, cursorPosition);
        }
      } else if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        this.$nextTick(() => {
          this.moveCursorToNextGroup(e.key);
        });
      }
    },

    handleKeypress(e) {
      if (!/\d/.test(e.key)) {
        e.preventDefault();
      } else {
        const input = this.$refs.cardInput;
        const value = e.target.value;
        let cursorPosition = input.selectionStart;
        const selectionEnd = input.selectionEnd;
        const selectionRange = Math.abs(cursorPosition - selectionEnd);

        if (value.length < 19) {
          if (cursorPosition % 5 === 4 && value[cursorPosition] !== " ") {
            input.value =
              value.slice(0, cursorPosition) +
              " " +
              value.slice(cursorPosition);
            cursorPosition += 1;
            input.setSelectionRange(cursorPosition, cursorPosition);
          }
        } else if (
          cursorPosition != selectionEnd &&
          value.length == 19 &&
          selectionRange >= 1
        ) {
          return;
        } else {
          e.preventDefault();
        }
      }
    },

    moveCursorToNextGroup(arrowKey) {
      const input = this.$refs.cardInput;
      let cursorPosition = input.selectionStart;
      const value = input.value;
      const selectionEnd = input.selectionEnd;

      if (cursorPosition == selectionEnd) {
        if (
          arrowKey === "ArrowRight" &&
          (cursorPosition + 2) % 5 === 0 &&
          value[cursorPosition + 1] === " "
        ) {
          cursorPosition += 1;
        } else if (
          arrowKey === "ArrowLeft" &&
          cursorPosition % 5 === 0 &&
          value[cursorPosition - 1] === " "
        ) {
          cursorPosition -= 1;
        }

        input.setSelectionRange(cursorPosition, cursorPosition);
      }
    },

    handleMousedown() {
      const input = this.$refs.cardInput;
      const value = input.value;

      setTimeout(() => {
        let cursorPosition = input.selectionStart;

        if (value[cursorPosition] === " ") {
          cursorPosition -= 1;
          input.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
    },

    handleMousemove(e) {
      if (e.buttons === 1) {
        setTimeout(() => {
          const input = this.$refs.cardInput;
          let cursorPosition = input.selectionStart;
          const value = input.value;
          const selectionEnd = input.selectionEnd;
          if (value[cursorPosition] === " " && selectionEnd < cursorPosition) {
            cursorPosition -= 1;
            input.setSelectionRange(cursorPosition, cursorPosition);
          }
        }, 0);
      }
    },

    handleCopy(e) {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      const input = this.$refs.cardInput;
      let cursorPosition = input.selectionStart;
      const value = input.value;
      const selectionEnd = input.selectionEnd;
      const selection = value
        .slice(cursorPosition, selectionEnd)
        .replace(/\D/g, "");
      clipboardData.setData("text/plain", selection);
    },

    handlePaste(e) {
      e.preventDefault();
      const pastedData = e.clipboardData || window.clipboardData;
      const pastedText = pastedData.getData("Text").replace(/\D/g, "");

      const input = e.target;
      const originalText = input.value.replace(/\D/g, "");
      let cursorPosition = input.selectionStart;
      let endPosition = input.selectionEnd;

      // Calculate the actual start and end position in the original text (without spaces)
      let actualStartPosition = this.calculateActualPosition(cursorPosition);
      let actualEndPosition = this.calculateActualPosition(endPosition);

      // Replace the selected text with the pasted text
      let updatedText =
        originalText.slice(0, actualStartPosition) +
        pastedText +
        originalText.slice(actualEndPosition);

      // Trim the text if it's too long
      if (updatedText.length > 16) {
        updatedText = updatedText.slice(0, 16);
      }

      // Update the value
      this.$emit("input", updatedText);
      input.value = updatedText.replace(/(\d{4})(?=\d)/g, "$1 ");

      // Set the cursor position
      if (originalText.length === 0) {
        cursorPosition = input.value.length;
      } else {
        cursorPosition =
          actualStartPosition +
          pastedText.length +
          Math.floor(pastedText.length / 5);
        if (cursorPosition >= 19) {
          cursorPosition = 19;
        }
      }

      input.setSelectionRange(cursorPosition, cursorPosition);
    },

    calculateActualPosition(position) {
      return position - Math.floor(position / 5);
    }
  },

  mounted() {
    const input = this.$refs.cardInput;
    input.value = this.value.replace(/(\d{4}(?=.))/g, "$1 ");
    window.addEventListener("mousemove", this.handleMousemove);
  },

  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleMousemove);
  },

  watch: {
    value(newValue) {
      const input = this.$refs.cardInput;
      let value = newValue.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
      if (value != input.value) {
        input.value = value;
        input.setSelectionRange(value.length + 1, value.length + 1);
      }
    }
  }
};
</script>

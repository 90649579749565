<template>
  <div>
    <!-- Start Scribook Modal for Tutors -->
    <b-modal ref="my-modal" hide-footer centered header-class="orange-bg text-center d-block" @hidden="onModalClose">
      <template #modal-header>
        <!-- Learner Image -->
        <b-avatar :src="scribookData.learner.image" class="mx-auto" style="margin-top:-4rem;" size="4rem"></b-avatar>
        <div class="py-2 font-weight-medium text-white font-size-md">
          Scribook entry for {{ scribookData.learner.name }}
          <div>{{`${scribookData.bookings.grade_level} - ${scribookData.bookings.subject}`}}</div>
          <div>{{ `${moment.utc(scribookData.bookings.start_date).tz(userData.timezone).format('h:mm A')} (${userData.region_timezone})` }}</div>
        </div>
      </template>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form class="scribook-modal-form-container p-3" @submit.stop.prevent="handleSubmit()">
          <transition-group tag="div" class="div-slider" name="slide">
            <!-- Scribook Topic -->
            <div class="scribook-modal-step-1" v-if="formStepSelected === 1" key="1">
              <div class="slider-content sc1">
                <b-form-group label="What's your topic for this session?" label-for="scribook-topic" size="sm">
                  <ValidationProvider name="topic" rules="required|max:50" v-slot="validationContext">
                    <b-form-input id="scribookTopic" v-model="data.scribookTopic" :state="getValidationState(validationContext)" size="sm" placeholder="Polynomial Functions" :disabled="isLoadingButtons"></b-form-input>
                    <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    <b-form-text id="password-help-block">
                      {{ topicCharactersRemaining }} <span v-if="topicCharactersRemaining > 1">characters</span><span v-else> character</span> remaining
                    </b-form-text>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </div>

            <div class="scribook-modal-step-2" v-if="formStepSelected === 2" key="2">
              <div class="slider-content sc2">

                <div class="text-cb-color" style="text-align: left; margin-bottom: 10px;" v-if="isQuestionnaireLoading">
                  <b-spinner class="align-middle" small></b-spinner>
                  <strong><small> loading ...</small></strong>
                </div>

                <ScribookQuestionnaire v-if="isShowScribookQuestionnaire" :questionnaire="questionnaire" :maxLength="maxLength" />

                <!-- Scribook File Attachments -->
                <b-form-group label="Attachments (Optional)" label-for="remarks" size="sm" class="pb-4" v-if="isShowScribookQuestionnaire">
                  <span class="grey mt-n1">
                    Attach learning materials your learner may use.</span>
                  <div class="scribook-file-upload-container pt-3">
                    <div class="scribook-file-upload-placeholder p-4 text-center">
                      <font-awesome-icon class="fs-24" :icon="['fas', 'upload']" />
                      <div>
                        <span class="">Browse files or drag and drop here</span>
                        <span><br>Maximum file size: 2MB</span>
                      </div>
                    </div>
                    <b-form-file v-model="attachFiles" @change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>

                  </div>
                  <div class="text-cb-color" style="text-align: center; margin-top: 15px;" v-if="isTempUploadingLoading">
                    <b-spinner class="align-middle" small></b-spinner>
                    <strong><small> loading file...</small></strong>
                  </div>
                  <!-- File Preview Area -->
                  <div v-if="attachOrigFileName" class="my-2 p-4 grey d-flex align-items-center justify-content-center" style="border: 1px solid var(--orange);border-radius: 5px;">
                    <!-- Show File Name Here -->
                    <font-awesome-icon class="fs-24 mr-2" :style="`color: ${getFileTypeIcon(attachOrigFileName)['icon-color']}; `" :icon="['fas', getFileTypeIcon(attachOrigFileName)['fa-icon'] || 'file']" />
                    <span class="font-weight-medium">{{attachOrigFileName}}</span>
                  </div>

                </b-form-group>
              </div>
            </div>

            <div class="scribook-modal-step-3" v-if="formStepSelected === 3" key="3">
              <div class="slider-content sc3" :class="{ 'overall-assessment-error': overallAssessmentError }">
                <ScribookAssessment @selectedOverAllAssessment="onSelectedOverAllAssessment" :assessmentOptions="assessmentOptions" ref="overall-assessment" :data="data" />
              </div>
              <div style="color: #dc3545; margin-bottom: 5px;">{{overallAssessmentError}}</div>
            </div>

            <div class="scribook-modal-step-4" v-if="formStepSelected === 4" key="4">
              <div class="slider-content sc3">
                <!-- Scribook File Attachments -->
                <b-form-group label="Upload Photo (Optional)" label-for="remarks" size="sm" class="pb-4">
                  <span class="grey mt-n1">
                    Upload photo of your actual session or project that you want to send to your learner or learner's parent.</span>
                  <div class="scribook-file-upload-container py-4">
                    <div class="scribook-file-upload-placeholder p-4 text-center">
                      <font-awesome-icon class="fs-24" :icon="['fas', 'upload']" />
                      <div>
                        <span class="">Choose files or drag and drop here</span>
                        <span><br>Maximum file size: 2MB</span>
                      </div>
                    </div>
                    <b-form-file v-model="attachImageFile" @change="onImageFileUpload" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="scribook-file-upload-input"></b-form-file>
                  </div>

                  <div class="text-cb-color" style="text-align: center; margin-top: 15px;" v-if="isLoadingPreviewFile">
                    <b-spinner class="align-middle" small></b-spinner>
                    <strong><small> loading preview file...</small></strong>
                  </div>

                  <!-- File Preview Area -->
                  <div class="scribook-file-preview-area text-center" v-if="!isLoadingPreviewFile">
                    <b-img class="img-fluid" :src="imagePreviewFile"></b-img>
                  </div>
                </b-form-group>
              </div>
            </div>

            <div class="scribook-modal-step-5" v-if="formStepSelected === 5" key="5">
              <div class="slider-content sc3">
                <div class="text-center p-5">
                  <div>
                    <b-img class="mb-4 p-3 img-fluid" :src="require('@/assets/imgs/scribook/scribook-success-step.png')"></b-img>
                    <div class="pyro">
                      <div class="before" style="top:0;"></div>
                      <div class="after" style="bottom:0;"></div>
                    </div>
                  </div>
                  <p style="font-size: 14px;">Thank you for providing your feedback and your assessment for this session. <br>Your Scribook for Learner <span style="color:var(--light-blue);font-weight:500">{{scribookData.learner.name}}</span> was submitted.
                    <br><br>
                    You can edit this Scribook within 7 days.
                  </p>
                </div>
              </div>
            </div>

          </transition-group>
          <!-- Scribook Modal Progress bar and Buttons -->
          <div class="d-flex align-items-center justify-content-between py-2">
            <!-- Scribook Modal Progress bar -->
            <div>
              <div class="font-size-xs dark-silver">{{answeredProgress}} of {{formSteps.length - 2}} answered</div>
              <b-progress class="scribook-modal-progress-bar" height=".3rem" :value="modalProgress" style="width:10em"></b-progress>
              <div class="dark-silver" style="font-size: 14px; margin-top: .3em;">{{scribookData.bookings.booking_id}}</div>
            </div>

            <!-- Scribook Modal Buttons -->
            <div class="text-cb-color" style="text-align: left;margin-bottom: 10px;" v-if="isLoadingButtons">
              <b-spinner class="align-middle" small></b-spinner>
              <strong><small> loading...</small></strong>
            </div>
            <div class="text-center" v-if="!isLoadingButtons">
              <b-button class="scribook-modal-btn outline mr-1" v-if="formStepSelected >= 2 && formStepSelected <= formSteps.length - 2" @click="onPreviousClick" :disabled="isTempUploadingLoading">Previous</b-button>
              <b-button class="scribook-modal-btn" v-if="formStepSelected < formSteps.length - 2" @click="validateObserver" :disabled="isTempUploadingLoading">Next</b-button>
              <b-button class="scribook-modal-btn" v-if="formStepSelected === formSteps.length - 2" @click="onSubmit" :disabled="isSubmitLoading">
                <b-spinner small v-if="isSubmitLoading"></b-spinner>
                <span v-if="isSubmitLoading"> loading...</span>
                <span v-if="!isSubmitLoading">Submit</span>
              </b-button>
              <b-button class="btn" v-if="formStepSelected === formSteps.length - 1" @click="onModalClose">Close</b-button>
            </div>
          </div>
          <div v-if="!isLoadingButtons">
            <!-- required indicator -->
            <div class="text-center mt-4" style="color:var(--red);cursor:pointer;font-size:calc(var(--fs-one) - 4px);" title="You are required to submit lesson logs through Scribook every 3 completed sessions with your learner." v-b-tooltip.hover v-if="formStepSelected < formSteps.length - 1 && !showSkipButton">
              Scribook entry is required for this session.  <font-awesome-icon class="grey" :icon="['fas', 'info-circle']" />
            </div>
            <div class="text-center pt-4" style="text-decoration: underline;color:var(--orange);cursor:pointer;font-size:calc(var(--fs-one) - 4px);" @click="onSkipForNow" v-if="formStepSelected < formSteps.length - 1 && showSkipButton">
              Skip Scribook entry for now
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import mixins from "../../mixins";
import { mapState } from "vuex";
import ScribookQuestionnaire from "../scribook/ScribookQuestionnaire.vue";
import ScribookAssessment from "../scribook/ScribookAssessment.vue";

export default {
  props: {
    scribookData: Object,
    isAllowedFromSessionHistory: Boolean,
  },
  components: { ScribookQuestionnaire, ScribookAssessment },
  name: "ScribookCreationModal",
  data: () => ({
    formStepSelected: 1,
    formSteps: [
      { step: 1, minHeight: "8.5rem" },
      { step: 2, minHeight: "40rem" },
      { step: 3, minHeight: "20rem" },
      { step: 4, minHeight: "10rem" },
      { step: 5, minHeight: "10rem" },
      { step: 6, minHeight: "10rem" },
    ],
    divSlider: "",
    imageUrl: "",
    data: {
      scribook_id: "",
      booking_id: "",
      scribookTopic: "",
      learner: {
        name: "",
        image: "",
      },
      questionnaire: {
        q1: "",
        q2: "",
        q3: "",
        q4: "",
      },
      assessment: "",
    },
    topicCharacterLimit: 50,
    qCharacterLimit: 256,
    file1: null,
    isSubmitLoading: false,
    answeredProgress: 0,
    tempQuestionnaire: [
      {
        question_item: 1,
        question: "What did {learner_name} learn today?",
        field_type: "TEXTAREA",
      },
      {
        question_item: 2,
        question: "What will we continue to work on?",
        field_type: "TEXTAREA",
      },
      {
        question_item: 3,
        question: "What significant achievement has the learner demonstrated?",
        field_type: "TEXTAREA",
      },
      {
        question_item: 4,
        question: "Do you have any other comments?",
        field_type: "TEXTAREA",
      },
      {
        question_item: 5,
        question: "Overall Assessment",
        field_type: "DROPDOWN",
      },
    ],
    attachFiles: [],
    attachFileName: "",
    attachOrigFileName: "",
    isTempUploadingLoading: false,
    overallAssessmentError: "",
    showSkipButton: true,
    isLoadingButtons: false,
    assessmentOptions: [],
    isLoadingAssessmentOptions: false,
    questionnaire: [],
    isQuestionnaireLoading: false,
    questionnairePlaceholders: [
      "Specify the learning objective/s for this session.",
      "Identify area/s for learner’s improvement.",
      "Describe any milestone that the learner has shown.",
      "Mention relevant behaviors which you have observed.",
    ],
    maxLength: 256,
    attachImageFile: [],
    attachImageOrigFileName: "",
    attachImageFileName: "",
    isLoadingPreviewFile: false,
    imagePreviewFile: "",
    isShowScribookQuestionnaire: false
  }),
  computed: {
    ...mapState(["userData"]),
    topicCharactersRemaining: {
      get() {
        const newLimit =
          this.topicCharacterLimit - this.data.scribookTopic.length;
        return newLimit < 0 ? 0 : newLimit;
      },
      set(value) {
        return value;
      },
    },
    modalProgress() {
      return (this.answeredProgress / (this.formSteps.length - 2)) * 100;
    },
  },
  async mounted() {
    if (this.userData.role === "teacher" || this.isAllowedFromSessionHistory) {
      if (this.scribookData) {
        this.data.booking_id = this.scribookData.bookings.booking_id;
        this.$refs["my-modal"].show();
        this.loadScribookData();
        this.canSkipScribook();
        this.$nextTick(() => {
          this.divSlider = document.querySelector(".div-slider");
          this.setCss(this.divSlider, {
            minHeight: this.formSteps.find(
              (s) => s.step === this.formStepSelected
            ).minHeight,
          });
          document
            .getElementById("scribookTopic")
            .setAttribute("maxlength", this.topicCharacterLimit);
        });
      }
    }
  },
  methods: {
    validateObserver() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        } else {
          this.onNext();
        }
      });
    },
    onPreviousClick() {
      this.isLoadingButtons = true;
      setTimeout(() => {
        this.formStepSelected--;
        this.answeredProgress--;
        this.isLoadingButtons = false;
      }, 1000);
      let step = 2
      this.isShowScribookQuestionnaire = this.formStepSelected === step + 1
      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected - 1)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onNext() {
      if (this.formStepSelected === 3 && this.IsNullOrEmpty(this.data.assessment)) {
        this.overallAssessmentError = "The overall assessment field is required.";
        this.$nextTick(() => {
          this.$refs["overall-assessment"].$el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
        return;
      }

      this.setCss(this.divSlider, {
        minHeight: this.formSteps.find((s) => s.step === this.formStepSelected)
          .minHeight,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });

      switch (this.formStepSelected) {
        case 1:
          this.onCreateScribookSubmit();
          break;
        case 2:
          this.onUpdateScribookQuestionnaire();
          break;
        case 3:
          this.onUpdateScribookAssessment();
          break;
        case 4:
          this.onSubmit();
          break;
      }
    },
    async onSubmit() {
      this.isSubmitLoading = true;
      const uploadParams = {
        data: {
          scribook_id: this.data.scribook_id,
          attachment_file: this.attachImageOrigFileName,
          server_attachment: this.attachImageFileName,
        },
        upload_key: "save",
      };

      const response = await this.$store.dispatch(
        "uploadScribookImage",
        uploadParams
      );

      if (response.data.status === 200) {
        const params = { scribook_id: this.data.scribook_id };

        const submitResponse = await this.$store.dispatch(
          "completeScribook",
          params
        );

        if (submitResponse.data.status === 200) {
          this.answeredProgress++;
          this.formStepSelected++;
        } else {
          this.ShowModal(submitResponse.data.message);
        }
      } else {
        this.ShowModal(response.data.message);
      }

      this.isSubmitLoading = false;
    },
    async onCreateScribookSubmit() {
      this.isLoadingButtons = true;

      const payload = {
        booking_id: this.data.booking_id,
        topic: this.data.scribookTopic,
      };

      const response = await this.$store.dispatch("createScribook", payload);
      if (response.data.status === 200) {
        this.data.scribook_id = response.data.data.scribook_id;
        await this.getQuestionnaire();
        this.$nextTick(() => {
          this.answeredProgress++;
          this.formStepSelected++;
          this.toggleScribookQuestionnaire()
        });
      } else {
        this.ShowModal(response.data.message);
      }

      this.isLoadingButtons = false;
    },
    async onUpdateScribookQuestionnaire() {
      this.isLoadingButtons = true;

      const assessment = this.questionnaire.map((x, index) => {
        return {
          question_item: index + 1,
          answer: x.answer,
        };
      });

      const assessmentDescription = this.assessmentOptions.find(
        (x) => x.value === this.data.assessment
      )?.description;

      assessment.push({
        question_item: 5,
        answer: assessmentDescription || "",
      });

      const assessmentResponsePayload = {
        scribook_id: this.data.scribook_id,
        assessment: assessment,
      };

      const response = await this.$store.dispatch(
        "updateScribookAssessment",
        assessmentResponsePayload
      );
      if (response.data.status === 200) {
        if (this.attachFileName !== "") {
          const uploadParams = {
            data: {
              scribook_id: response.data.data.scribook_id,
              attachment_file: this.attachOrigFileName,
              server_attachment: this.attachFileName,
            },
            upload_key: "save",
          };

          const uploadResponse = await this.$store.dispatch(
            "uploadScribookFile",
            uploadParams
          );

          if (uploadResponse.data.status === 200) {
            await this.getAssessmentOptions();
            this.$nextTick(() => {
              this.answeredProgress++;
              this.formStepSelected++;
              this.toggleScribookQuestionnaire()
            });
          } else {
            this.ShowModal(uploadResponse.data.message);
            this.isLoadingButtons = false
            return;
          }
        } else {
          await this.getAssessmentOptions();
          this.$nextTick(() => {
            this.answeredProgress++;
            this.formStepSelected++;
            this.toggleScribookQuestionnaire()
          });
        }
      }

      this.isLoadingButtons = false;
    },
    toggleScribookQuestionnaire() { this.isShowScribookQuestionnaire = this.formStepSelected === 2 },
    async onUpdateScribookAssessment() {
      this.isLoadingButtons = true;

      const overallAssessmentPayload = {
        scribook_id: this.data.scribook_id,
        overall_assessment: this.data.assessment,
      };

      const response = await this.$store.dispatch(
        "updateScribookOverallAssessment",
        overallAssessmentPayload
      );

      if (response.data.status === 204) {
        this.answeredProgress++;
        this.formStepSelected++;
      }

      this.isLoadingButtons = false;
    },
    onSkipForNow() {
      this.$refs["my-modal"].hide();
      this.$emit("onScribookCreationModalClose");
    },
    onModalClose() {
      this.$refs["my-modal"].hide();
      this.$emit("onScribookCreationModalClose");
    },
    async onFileChange(e) {
      const shouldUpload = this.onFileDropChange(e);

      if (shouldUpload) {
        const params = {
          data: {
            scribook_id: this.data.scribook_id,
            attachment_file: this.getUploadFile(e),
          },
          upload_key: "upload",
        };

        this.isTempUploadingLoading = true;
        const response = await this.$store.dispatch(
          "uploadScribookFile",
          params
        );
        const _data = response.data.data;

        if (response.data.status === 200) {
          this.attachFileName = _data.server_attachment;
          this.attachOrigFileName = _data.original_name;
        } else {
          this.attachFiles = [];
          this.attachFileName = "";
          this.attachOrigFileName = "";
          this.ShowModal(response.data.message);
        }

        this.isTempUploadingLoading = false;
      }
    },
    async canSkipScribook() {
      this.isLoadingButtons = true;
      const response = await this.$store.dispatch("canSkipScribook", { booking_id: this.scribookData.bookings.booking_id });

      if (response.data.status === 200) {
          this.showSkipButton = response.data.data.scribook_required === 1 ? false : true
      }
      
      this.isLoadingButtons = false;
    },
    async loadScribookData() {
      this.isLoadingButtons = true;
      if (!this.IsNullOrEmpty(this.scribookData.scribook.scribook_id)) {
        const scriBookResponse = await this.$store.dispatch(
          "getScribookDetails",
          this.scribookData.scribook.scribook_id
        );
        if (scriBookResponse.data.status === 200) {
          const _data = scriBookResponse.data.data.data[0];
          this.data.scribook_id = _data.scribook_id;
          this.data.scribookTopic = _data.topic;
          this.data.assessment = _data.overall_assessment;
          this.attachOrigFileName =
            _data.attachments[_data.attachments.length - 1]?.file_name;
        }
      }
      if (!this.IsNullOrEmpty(this.data.scribook_id)) {
        const assessmentResponse = await this.$store.dispatch(
          "getAssessment",
          this.data.scribook_id
        );
        if (assessmentResponse.data.status === 200) {
          const _data = assessmentResponse.data.data;
          this.data.questionnaire.q1 = _data[0].answer;
          this.data.questionnaire.q2 = _data[1].answer;
          this.data.questionnaire.q3 = _data[2].answer;
          this.data.questionnaire.q4 = _data[3].answer;
        }
        this.getQuestionnaire();
        this.getAssessmentOptions();
      }
      this.isLoadingButtons = false;
    },
    onSelectedOverAllAssessment(value) {
      this.data.assessment = value;
    },
    async getAssessmentOptions() {
      this.isLoadingAssessmentOptions = true;
      const response = await this.$store.dispatch(
        "getAssessmentOptions",
        this.data.scribook_id
      );
      if (response.data.status === 200) {
        this.assessmentOptions = response.data.data.map((x) => {
          return {
            text: x.code_description,
            value: x.code_type_value,
            description: x.description,
          };
        });
      }
      this.isLoadingAssessmentOptions = false;
    },
    async getQuestionnaire() {
      this.isQuestionnaireLoading = true;
      if (!this.IsNullOrEmpty(this.data.scribook_id)) {
        const assessmentResponse = await this.$store.dispatch("getAssessment", this.data.scribook_id);
        if (assessmentResponse.data.status === 200) {
          const _data = assessmentResponse.data.data;
          this.questionnaire = _data
            .map((x, index) => {
              const cachedAnswer = this.questionnaire.find(c => c.id === index + 1)?.answer || ''
              return {
                id: index + 1,
                name: `text${this.renderWhiteSpace(index + 1)}`,
                question: x.question,
                answer: x.answer || cachedAnswer,
                type: "textarea",
                rules: {
                  isRequired: index === 0,
                  maxLength: this.maxLength,
                },
                placeholder: this.questionnairePlaceholders[index],
                characterLimit: this.maxLength,
              };
            })
            .sort((a, b) => a.sort_order - b.sort_order)
            .filter((x) => {
              return x.question !== "Overall Assessment";
            });
        }
        this.isQuestionnaireLoading = false;
      }
    },
    async onImageFileUpload(e) {
      const shouldUpload = this.onFileDropChange(e);

      if (shouldUpload) {
        const uploadParams = {
          data: {
            scribook_id: this.data.scribook_id,
            attachment_file: this.getUploadFile(e),
          },
          upload_key: "upload",
        };

        this.isLoadingPreviewFile = true;
        const response = await this.$store.dispatch(
          "uploadScribookImage",
          uploadParams
        );

        if (response.data.status === 200) {
          this.attachImageOrigFileName = response.data.data.original_name;
          this.attachImageFileName = response.data.data.server_attachment;
          this.imagePreviewFile = window.URL.createObjectURL(
            this.getUploadFile(e)
          );
        } else {
          this.ShowModal(response.data.message);
        }
        this.isLoadingPreviewFile = false;
      }
    },
  },
  watch: {
    "data.assessment": function () {
      if (this.data.assessment !== "") {
        this.overallAssessmentError = "";
      }
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
.overall-assessment-error {
  padding-left: 15px;
  padding-top: 15px;
  border: 1px solid #dc3545;
}
</style>

<template>
  <div>
    <b-table striped hover sort-icon-left show-empty empty-text="No upcoming class yet." :fields="fields" :items="items" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :busy="isBusy" responsive="sm">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>

      <template #cell(message)="data">
        <!-- <b-button variant="primary" size="sm">{{data.item.message}}</b-button> -->
        <b-button
          variant="primary"
          size="sm"
          v-b-modal.note-modal-message
          @click="showMessage(data.item)"
        >Message</b-button>        
      </template>

    </b-table>
    <b-pagination v-if="items.length > 0" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
       class="p-3"></b-pagination>

    <b-modal v-model="modalShow" :title="learnerName + `'s Message`" centered>
      {{ noteMessage }}
      <template #modal-footer>
        <div class="w-100">
          <b-button class="mt-3 mr-1" variant="warning" :disabled="selectedData.download_file == null" @click="onDownload">Download</b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import mixins from "../../../mixins";

export default {
  name: "ScheduleTeacherUpcomingSessions",
  props: ['isDataTriggerLoad'],
  data() {
    return {
      isBusy: false,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      items: [],
      UserToken: window.localStorage.getItem("userToken"),
      learnerName: '',
      noteMessage: '',
      fields: ['sessionDate', 'sessionTime', 'learnerName', 'gradeLevel', 'subject', 'message'],
      modalShow: false,
      selectedData: [],
      guestTZ: null,
      regionTzName: null
    };
  },
  computed: {},
  beforeMount() {
    if (this.isDataTriggerLoad) {
      this.$emit('triggeredWatchSessions')
    }
  },
  mounted() {
    const vm = this
    
    vm.$watch('$store.state.userData', (data) => {
      vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone
      vm.regionTzName = data.timezone == null ? vm.getTzRegion(vm.guestTz) : data.region_timezone
      vm.$store.dispatch('getTimeSlots', vm.guestTZ)
    }, { immediate: true })
    vm.fetchUpcomingSessions();
  },
  methods: { 
    onDownload() {

      const ApiURL = process.env.VUE_APP_BACKEND_URL + "/api/web/booking/download-file",
            formData = new FormData(),
            param = { 
                role: this.selectedData.account_type,
                user_id: this.selectedData.user_id,
                file: this.selectedData.download_file 
              }

      Object.keys(param).forEach(key => formData.append(key, param[key]))

      this.onDownloadFile(ApiURL, formData, this.selectedData.original_file)

    },
    showMessage(data) {
      this.modalShow = true;
      this.noteMessage = data.message;
      this.learnerName = data.learnerName;
      this.selectedData = data;
    },
    async fetchUpcomingSessions() {
      this.isBusy = true;

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL +
        "/api/web/booking/upcoming-session-tutor?count=" +
        this.perPage +
        "&page=" +
        this.currentPage;
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      await this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data.data;
          
          this.items = _data.data.map((item) => {
            return {
              sessionDate: this.moment.utc(item.start_date).tz(this.guestTZ).format('dddd, LL'),
              sessionTime: `${this.moment.tz(item.start_date, 'UTC').tz(this.guestTZ).format('hh:mm A')} (${this.regionTzName})`,
              learnerName: item.learner_name,
              gradeLevel: item.grade_level,
              subject: item.subject,
              message: item.note,
              download_file: item.download_file,
              account_type: item.account_type,
              user_id: item.user_id
            };
          });
          this.totalRows = _data.total;
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchUpcomingSessions().catch((error) => {
          console.error(error);
        });
      },
    },
    isDataTriggerLoad(val) {
      if (val) {
        this.fetchUpcomingSessions()
      }
      this.$emit('triggeredWatchSessions')
    }
  },
  mixins: [mixins],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
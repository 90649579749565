<template>
  <div class="maintenance-container">
    <section class="section py-3">
      <div class="container text-center">
        <b-img class="mb-4 img-fluid cb-logo" style="width:150px;" :src="require('@/assets/global/cb_logo.png')"></b-img>
        <h1 class="my-3">Chalkboard<br> is currently down for maintenance</h1>
        <div class="position-relative mb-5">
          <b-img class=" img-fluid maintenance-image" :src="require('@/assets/global/maintenance.png')"></b-img>
        </div>
        <p>We apologize for any inconvenience caused. We will be back soon!<br>For the mean time, you can visit our <a class="light-blue card-link" href="https://fb.com/thechalkboardinc" target="_blank">Facebook page</a> for updates.</p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style scoped>
.maintenance-image {
  width: 650px;
}
div.position-relative::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 500px;
  width: 550px;
  margin: auto;
  border-radius: 50%;
  background: rgb(0, 191, 224, 0.3);
  box-shadow: 0 0 0 0 rgb(0, 191, 224, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: -1;
}
.maintenance-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 9999;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(0, 191, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgb(0, 191, 224, 0.3);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(0, 191, 224, 0);
  }
}
</style>
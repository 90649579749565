<template>
  <b-container fluid>
    <b-alert :show="showPriceTierAlert" variant="warning">
      <strong>Please Read</strong>
      <hr/>
      We have introduced a new rate pricing model in an effort to standardize, centralize and limit the rates of tutors in our platform. Please update your rate into a price tier that is right for you.
    </b-alert>
    <b-alert :show="showInactivePriceTierAlert" variant="warning">
      <strong>Please Read</strong>
      <hr/>
      The rate price tier you have picked was disabled by system administrators. Please select a new price tier.
    </b-alert>
    <b-row>
      <b-col sm="12" lg="5">
        <b-card no-body>
          <b-card-header class="p-1 light-blue-bg">

          </b-card-header>

          <div class="text-center text-primary my-5" v-if="isFetchingData">
            <b-spinner variant="info" class="align-middle"></b-spinner>
            <strong class="text-info"> loading profile data...</strong>
          </div>

          <b-card-body class="text-center" v-if="!isFetchingData">

            <b-alert v-if="secProfileCompletion" show dismissible variant="warning">
              <div><strong>Get Verified!</strong></div>
              <span class="font-size-sm text-left">{{ progressNarrator }}</span>
              <b-progress :max="max" class="my-2">
                <b-progress-bar :value="value" class="orange-bg">
                  <span><strong>{{ value }}%</strong></span>
                </b-progress-bar>
              </b-progress>
              <div class="d-flex justify-content-end">
                <b-button class="mt-2 py-1" size="sm" v-if="hasSubmitButton" variant="warning" @click="onNBISubmitFile">Submit Now</b-button>
              </div>
              <b-form-file id="nbiFileInput" placeholder="Browse nbi file here" accept=".docx,.pdf,.jpeg,.jpg,.png" style="width: 300px; display: none;" v-model="nbi" size="sm" @change="onNBIFileChange"></b-form-file>
            </b-alert>

            <div class="light-blue font-size-lg"><strong>Profile Overview</strong></div>
            <div class="d-flex flex-column align-items-center justify-content-center py-3">
              <div @click="onFileUpload">
                <b-avatar :src="imageUrl" size="7rem" v-bind="mainProps" :class="{ 'cursor-pointer': formDisabled ? false : true }"></b-avatar>
                <b-tooltip :disabled.sync="disabled" target="clickImage" placement="right" hover="true">
                  <small>Click to upload new image</small>
                </b-tooltip>
              </div>
              <div><strong>{{ profileName }}</strong></div>
              <span style="word-break: break-word; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">{{ form.background.tagline }}</span>
              <b-form-file style="display: none;" id="file-upload" v-model="form.image" accept="image/*" @change="onFileChange" size="sm" :disabled="formDisabled"></b-form-file>
            </div>
            <hr>
            <b-row>
              <b-col>
                <div>
                  <div class="light-blue">{{ students }}</div>
                  <span class="font-size-sm">Students</span>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="light-blue">{{ tutoringHours }}</div>
                  <span class="font-size-sm">Tutoring Hours</span>
                </div>
              </b-col>
              <b-col>
                <div>
                  <div class="light-blue">{{ ratings }}</div>
                  <span class="font-size-sm">Ratings</span>
                </div>
              </b-col>
            </b-row>

          </b-card-body>
        </b-card>

      </b-col>

      <b-col>
        <div>

          <validation-observer ref="observer" v-slot="{ handleSubmit }">

            <b-form id="teacher-profile-form" @submit.prevent="handleSubmit(onSubmit)">

              <b-card no-body class="tutorprofiletabs">
                <b-tabs card justified v-model="tabIndex">
                  <b-tab title="Account" class="py-4">
                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Email Address" label-for="email">
                          <ValidationProvider name="email" rules="required|email" v-slot="validationContext">
                            <b-form-input id="email" v-model="form.email" type="email" required placeholder="Email Address" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                            <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              All email notifications are sent here.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>

                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Birthday" label-for="birthday">
                          <ValidationProvider name="birthday" rules="required" v-slot="validationContext">
                            <b-form-input id="birthday" v-model="form.birthday" type="text" required size="sm" name="birthday" placeholder="Birthday" :state="getValidationState(validationContext)" onfocus="this.type='date'" :disabled="true"></b-form-input>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              You need to be at least 18 years old to sign up.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Public First Name" label-for="firstname">
                          <ValidationProvider name="first name" rules="required" v-slot="validationContext">
                            <b-form-input id="firstname" v-model="form.firstname" type="text" required placeholder="First Name" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                            <b-form-invalid-feedback id="input-4-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>

                      </b-col>
                      <b-col sm="12" lg="6">

                        <b-form-group id="input-group-1" label="Public Last Name" label-for="lastname">
                          <ValidationProvider name="last name" rules="required" v-slot="validationContext">
                            <b-form-input id="lastname" v-model="form.lastname" type="text" required placeholder="Last Name" size="sm" :state="getValidationState(validationContext)" :disabled="true"></b-form-input>
                            <b-form-invalid-feedback id="input-5-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <b-form-group>
                      <b-form-text class="mt-n2">Your name will be displayed as “ {{form.firstname}} {{getFChar(form.lastname)}}. ” on your tutor profile and on the parents’ and learners’ Dashboard. It may not be the same as your legal name and can be edited once submitted but you need to send us a request.</b-form-text>
                    </b-form-group>
                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Phone Number" label-for="mobile_no">
                          <ValidationProvider name="phone number" :rules="`required|validatePhoneNumber:${JSON.stringify(eventPNData)}`" v-slot="validationContext">
                            <PhoneNumberInput
                              required
                              id="mobile_no"
                              v-model="form.mobile_no"
                              :disabled="formDisabled"
                              @update="onPNUpdate($event)"
                              :default-country-code="`${form.pn_country_code || 'US'}`"
                              :error="validationContext.errors[0] ? true : false"
                            />
                            <div class="invalid-feedback" style="display: block;">{{ validationContext.errors[0] }}</div>
                            <b-form-text v-if="!validationContext.errors[0]">
                              For Customer Support. We will not share it with anyone else.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="WhatsApp" label-for="whatsapp">
                          <ValidationProvider name="whatsapp" :rules="`validatePhoneNumber:${JSON.stringify(eventWAData)}`" v-slot="validationContext">
                            <PhoneNumberInput
                              id="whatsapp"
                              v-model="form.whatsapp"
                              :disabled="formDisabled"
                              @update="onWAUpdate($event)"
                              :default-country-code="`${form.wa_country_code || 'US'}`"
                              :error="validationContext.errors[0] ? true : false"
                            />
                            <div class="invalid-feedback" style="display: block;" v-if="validationContext.errors[0]">{{ validationContext.errors[0] }}</div>
                            <b-form-text v-if="!validationContext.errors[0]">
                              WhatsApp is one of Chalkboard’s primary communication channels. It is recommended that you install this app so our Customer Support may use it to contact you.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Address 1" label-for="street">
                          <ValidationProvider name="street" rules="required|max:100" v-slot="validationContext">
                            <b-form-input id="street" v-model="form.street" type="text" required placeholder="" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-7-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">

                        <b-form-group id="input-group-1" label="Address 2" label-for="brgy">
                          <ValidationProvider name="barangay" rules="max:100" v-slot="validationContext">
                            <b-form-input id="brgy" v-model="form.brgy" type="text" required placeholder="" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-8-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="City" label-for="city">
                          <ValidationProvider name="city" rules="required|max:100" v-slot="validationContext">
                            <b-form-input id="city" v-model="form.city" type="text" required placeholder="City" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-9-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>

                      </b-col>

                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="State/Province" label-for="province">
                          <ValidationProvider name="province" rules="required|max:100" v-slot="validationContext">
                            <b-form-input id="province" v-model="form.province" type="text" required placeholder="" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-10-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Country" label-for="country">
                          <ValidationProvider name="country" rules="required" v-slot="validationContext">
                            <CountrySelect
                              size="sm"
                              v-model="form.country"
                              :disabled="formDisabled"
                              :successful="!!form.country"
                              :errorMessage="!form.country ? validationContext.errors[0] : ''"
                            />
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Postal/Zip Code" label-for="zip_code">
                          <ValidationProvider name="zip code" rules="max:10" v-slot="validationContext">
                            <b-form-input id="zip_code" v-model="form.zip_code" type="text" placeholder="Zip Code" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="About" class="py-4">
                    <div class="py-3">
                      <b-form-group>
                        <label for="">Education and Professional Background</label>
                        <div class="font-size-sm grey">Chalkboard does not require you to have a degree program in Education. We do, however, believe that your education background and teaching credentials can help us assess your expertise during the interview and teaching demonstration. Most of the information below will also be displayed on your public profile.</div>
                      </b-form-group>
                    </div>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Academic Degree" label-for="academic_degree">
                          <ValidationProvider name="academic degree" rules="required" v-slot="validationContext">
                            <b-form-select v-model="form.background.academic_degree" id="academic_degree" required :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                              <b-form-select-option value="null">-- select academic degree --</b-form-select-option>
                              <b-form-select-option v-for="(item, index) in academicDegreeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Highest education degree completed or is currently studying from a college or university.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Degree Program" label-for="education">
                          <ValidationProvider name="degree program" rules="required|max:100" v-slot="validationContext">
                            <b-form-input id="education" v-model="form.background.education" type="text" required placeholder="Education" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-14-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Your course of study or program you are admitted to or has graduated from leading to your highest academic degree.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="College University" label-for="college_university">
                          <ValidationProvider name="college university" rules="required" v-slot="validationContext">
                            <b-form-input id="college_university" v-model="form.background.college_university" required type="text" placeholder="Enter college university" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-11-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Name of college or university where you completed or is currently talking your highest education degree.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Academic Affiliation" label-for="academic_affiliation">
                          <ValidationProvider name="academic affiliation" rules="max:100" v-slot="validationContext">
                            <b-form-input id="academic_affiliation" v-model="form.background.academic_affiliation" type="text" placeholder="Enter academic affiliation" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-15-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              List one academic institution where you are practicing teaching or tutoring.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Other Specializations" label-for="other_specializations">
                          <ValidationProvider name="other specializations" v-slot="validationContext">
                            <b-form-input id="other_specializations" v-model="form.background.other_specializations" type="text" placeholder="Enter other specializations" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-12-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              List any specialization that you have completed
                              or have experience with. Examples are Special
                              Education (SPED), Early Childhood Education,
                              English as a Second Language, Visual and/or
                              Hearing Impairment, Education Administration,
                              etc </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Language/s Spoken *" label-for="language">
                          <ValidationProvider name="language spoken" rules="required|max:100" v-slot="validationContext">
                            <b-form-input id="language" required v-model="form.background.language" type="text" placeholder="Language" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-16-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              List all languages or dialects that you can speak
                              or write with high level of proficiency. High level
                              of proficiency means that you use the language
                              fluently and accurately on all levels pertinent to
                              professional needs. Use comma in case there’s
                              more than one.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <div class="py-3">
                      <b-form-group>
                        <label for="">About yourself

                        </label>
                        <div class="font-size-sm grey">Our tutors come from a variety of professional backgrounds - share anything you think we should know.
                          Remember to put your best foot forward and make your application stand out!</div>
                      </b-form-group>
                    </div>
                    <b-form-group id="input-group-1" label="Tagline" label-for="tagline">
                      <ValidationProvider name="tagline" rules="required|max:60" v-slot="validationContext">
                        <b-form-input id="tagline" v-model="form.background.tagline" type="text" required placeholder="Tagline" size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                        <b-form-invalid-feedback id="input-12-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                        <b-form-text v-if="!validationContext.errors[0]">
                          Create a catchy tagline that highlights your expertise, achievements, and teaching philosophy.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="About me" label-for="about_me">
                      <ValidationProvider name="about me" rules="required|min:100|max:1000" v-slot="validationContext">
                        <b-form-textarea id="about_me" v-model="form.background.about_me" placeholder="About Me..." size="sm" rows="3" max-rows="6" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-textarea>
                        <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        <b-form-text v-if="!validationContext.errors[0]">
                          List teaching credentials, professional trainings, competitions won, or other relevant professional
                          certifications you have received in the last two years. You may also write something about your
                          hobbies, talents, and interests so parents and learners can relate to you more.
                        </b-form-text>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-row>
                      <b-col sm="12" lg="12">
                        <b-form-group id="input-group-1" label="About my teaching style" label-for="my_teaching_style" class="custom-error-position">
                          <ValidationProvider name="teaching style" rules="required|min:100|max:1000" v-slot="validationContext">
                            <b-form-textarea style="min-height: 138px;overflow: unset;" id="my_teaching_style" v-model="form.background.my_teaching_style" placeholder="Enter your teaching style..." required size="sm" rows="3" max-rows="6" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-textarea>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Share with us why you’re passionate about teaching. What’s your teaching approach and why
                              do you think it will be effective to your learners? List all the tools, techniques, and other related
                              technologies you may use on your class.

                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <div class="py-3">
                      <b-form-group>
                        <label for="">Online Profile

                        </label>
                        <div class="font-size-sm grey">Do you have a Facebook page, YouTube channel, TikTok profile, LinkedIn profile, or any
                          professional website that helps describe your experience and expertise? Share it with us here!</div>
                      </b-form-group>
                    </div>
                    <b-form-row>
                      <b-col sm="12" lg="12">
                        <b-form-group id="input-group-1" label="Facebook" label-for="facebook" class="custom-error-position">
                          <ValidationProvider name="facebook" v-slot="validationContext">
                            <b-form-input id="facebook" v-model="form.background.facebook" placeholder="Enter your facebook profile link..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="12">
                        <b-form-group id="input-group-1" label="Youtube" label-for="youtube" class="custom-error-position">
                          <ValidationProvider name="youtube" v-slot="validationContext">
                            <b-form-input id="youtube" v-model="form.background.youtube" placeholder="Enter your youtube profile link..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="12">
                        <b-form-group id="input-group-1" label="Tiktok" label-for="tiktok" class="custom-error-position">
                          <ValidationProvider name="tiktok" v-slot="validationContext">
                            <b-form-input id="tiktok" v-model="form.background.tiktok" placeholder="Enter your tiktok profile link..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="12">
                        <b-form-group id="input-group-1" label="LinkedIn" label-for="linkedin" class="custom-error-position">
                          <ValidationProvider name="linkedin" v-slot="validationContext">
                            <b-form-input id="linkedin" v-model="form.background.linkedin" placeholder="Enter your linkedin profile link..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-tab>
                  <b-tab title="Specialization">
                    <b-table-simple id="tblSpecialization" responsive>
                      <b-thead>
                        <b-th>
                          <b-form-checkbox v-model="allSelected" @change="onCheckAll" v-if="specialization.length > 0" :disabled="formDisabled"></b-form-checkbox>
                        </b-th>
                        <b-th>Grade Level</b-th>
                        <b-th>Subjects</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(item, rIndex) in specialization" :key="rIndex">
                          <b-td style="vertical-align: top;">
                            <b-form-checkbox :value="item.index" v-model="selected" @change="select" :disabled="formDisabled"></b-form-checkbox>
                          </b-td>
                          <b-td class="specialization-select" style="vertical-align: top;">
                            <b-form-select v-model="item.grade_level" @change="onGradeLevelSelectChange($event, rIndex)" size="sm" :disabled="formDisabled">
                              <b-form-select-option v-for="(option, index) in options" :disabled="option.disabled" :key="index" :value="option.value">{{ option.text }}</b-form-select-option>
                            </b-form-select>
                          </b-td>
                          <b-td style="vertical-align: top; width: 70%;">

                            <b-form-tags id="tags-component-select" v-model="item.subjects" size="lg" class="mb-2" add-on-change no-outer-focus :disabled="formDisabled">
                              <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                    <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">{{ tag }}</b-form-tag>
                                  </li>
                                </ul>
                                <b-form-select v-bind="inputAttrs" v-on="inputHandlers" :disabled="disabled || availableTagsOptions.length === 0" :options="item.subjectsPerGradeLevel">
                                  <template #first>
                                    <!-- This is required to prevent bugs with Safari -->
                                    <option disabled value="">Choose a subject...</option>
                                  </template>
                                </b-form-select>
                              </template>
                            </b-form-tags>

                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="d-flex justify-content-end">
                      <b-button variant="danger" @click="remove" class="mr-2" v-if="selected.length > 0" :disabled="formDisabled">
                        <font-awesome-icon class="font-size-xs mr-1" style="margin-bottom: 3px;" :icon="['fas', 'trash-alt']" />Remove
                      </b-button>
                      <b-button variant="primary" @click="addnewrow" class="mr-2" :disabled="(specialization.length == options.length - 1) || formDisabled">
                        <font-awesome-icon class="font-size-xs mr-1" style="margin-bottom: 3px;" :icon="['fas', 'plus']" />Add Grade Level
                      </b-button>
                    </div>
                  </b-tab>
                  <b-tab title="Availability">
                    <div class="text-center">
                      <b-tabs class="availabilitytab" content-class="mt-3" align="center" justified>
                        <b-tab v-for="(item, index) in AvailabilitySchedule" :key="index" :title="item.day">
                          <div class="d-lg-flex align-items-center justify-content-between py-4 px-4 mb-4" style="border-bottom: 2px solid var(--light-silver)">
                            <div>
                              <strong>Select Timeslot for {{item.day}}</strong>
                            </div>
                            <div>
                              <b-button @click="onSelectAll(item.day)" class="selectall-btn mr-2" size="sm" :disabled="formDisabled">
                                Select All
                              </b-button>
                              <b-button @click="onClearAll(item.day)" class="clearall-btn mr-2" size="sm" :disabled="formDisabled">
                                Clear All
                              </b-button>
                            </div>
                          </div>
                          <b-row>
                            <b-col sm="12" lg="4">
                              <b-form-group label="Morning" class="text-center">
                                <b-form-checkbox-group v-model="item.items.morning" :disabled="formDisabled" :options="timeSlots.morning" stacked buttons class="morning">
                                </b-form-checkbox-group>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="4">
                              <b-form-group label="Afternoon" class="text-center">
                                <b-form-checkbox-group v-model="item.items.afternoon" :disabled="formDisabled" :options="timeSlots.afternoon" stacked buttons class="afternoon"></b-form-checkbox-group>
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="4">
                              <b-form-group label="Evening" class="text-center">
                                <b-form-checkbox-group v-model="item.items.evening" :disabled="formDisabled" :options="timeSlots.evening" stacked buttons class="evening"></b-form-checkbox-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </b-tab>
                  <b-tab title="Rates" class="py-4">

                   <!-- TODO numeric|rate_validation -->
                    <ValidationProvider name="rate" rules="required" v-slot="validationContext">
                      <b-row class="pb-3">
                        <b-col lg="8" sm="12">
                          <label for="price_tier_id">Rate *</label>
                          <Dropdown
                            class="w-100"
                            :class="validationContext.errors[0] ? 'p-invalid' : ''"
                            inputId="price_tier_id"
                            v-model="form.price_tier_id"
                            :options="priceTiers"
                            optionLabel="rate"
                            optionValue="id"
                            placeholder="Select a Price Tier"
                            :disabled="formDisabled"
                          >
                            <template #value="{ value, placeholder }">
                              <div v-if="value" class="p-dropdown-car-value" :set="(priceTier = priceTiers.find(priceTier => priceTier.id == value))">
                                <div class="d-flex align-items-center">
                                  <div class="d-flex flex-column align-items-center mr-3">
                                    <small class="mb-1" style="font-weight: 500;">
                                      {{ priceTier.title }}
                                    </small>
                                    <span class="currency-localization currency-localization--xs">
                                      <span class="currency-localization__symbol" >&#8369;</span>
                                      <span class="currency-localization__whole">{{ priceTier.rate }}</span>
                                      <span class="currency-localization__decimal">.00</span>
                                      <span class="currency-localization__unit">/hr</span>
                                    </span>
                                  </div>
                                  <small style="text-overflow: wrap; white-space: wrap;">
                                    {{ priceTier.description }}
                                  </small>
                                </div>
                              </div>
                              <span v-else>
                                {{ placeholder }}
                              </span>
                            </template>

                            <template #option="{ option: { title, rate, description } }">
                              <div class="d-flex align-items-center">
                                <div class="d-flex flex-column align-items-center mr-3">
                                  <small class="mb-1" style="font-weight: 500;">
                                    {{ title }}
                                  </small>
                                  <span class="currency-localization currency-localization--xs">
                                    <span class="currency-localization__symbol" >&#8369;</span>
                                    <span class="currency-localization__whole">{{ rate }}</span>
                                    <span class="currency-localization__decimal">.00</span>
                                    <span class="currency-localization__unit">/hr</span>
                                  </span>
                                </div>
                                <small style="text-overflow: wrap; white-space: wrap;">
                                  {{ description }}
                                </small>
                              </div>
                            </template>
                          </Dropdown>

                          <b-form-invalid-feedback class="d-block" id="input-1-live-feedback">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>

                          <b-form-text>
                            You can only change your price tier once a month so be careful in choosing. Beyond once a month, you can contact our support to request an approval for a one-time-use price-tier change.
                            <span v-if="showPriceTierAlert" class="d-block" style="font-weight: 600; font-size: 0.8rem">NOTE: This first change does not count.</span>
                          </b-form-text>
                        </b-col>
                      </b-row>
                    </ValidationProvider>
                    <br>
                    <div class="py-3">
                      <b-form-group>
                        <label for="">Primary Bank Account
                        </label>
                        <div class="font-size-sm grey">Your Primary Bank Account is your preferred payout channel where Chalkboard will deposit your earnings
                          from the platform.
                        </div>
                      </b-form-group>
                    </div>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Primary Bank *" label-for="primary_bank">
                          <ValidationProvider name="primary bank" rules="required" v-slot="validationContext">
                            <b-form-select v-model="form.primary_bank" id="primary_bank" required :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                              <b-form-select-option value="null">-- select bank/wallet --</b-form-select-option>
                              <b-form-select-option-group v-for="(groupItem, groupIndex) in bankAccountOptions" :key="groupIndex" :label="`${groupIndex === 'CBLBNK' ? 'Local Banks' : groupIndex === 'EWLLET' ? 'E-Wallets' : ''}`">
                                <b-form-select-option v-for="(item, index) in groupItem" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                              </b-form-select-option-group>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Account Name *" label-for="pb_account_name" class="custom-error-position">
                          <ValidationProvider name="bank account name" rules="required" v-slot="validationContext">
                            <b-form-input id="pb_account_name" v-model="form.pb_account_name" placeholder="Enter account name..." required size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Account Type *" label-for="pb_account_type">
                          <ValidationProvider name="bank account type" rules="required" v-slot="validationContext">
                            <b-form-select id="pb_account_type" v-model="form.pb_account_type" required :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                              <b-form-select-option value="null">-- select account type--</b-form-select-option>
                              <b-form-select-option v-for="(item, index) in bankAccountTypeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row class="pb-5">
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Bank Account Number *" label-for="pb_account_no" class="custom-error-position">
                          <ValidationProvider name="bank account no" rules="required" v-slot="validationContext">
                            <b-form-input id="pb_account_no" v-model="form.pb_account_no" placeholder="Enter bank account number..." required size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Do not put spaces and dashes. Enter your 11-
                              digit mobile number if using e-Wallets.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                      </b-col>
                    </b-form-row>

                    <div class="py-4">
                      <b-form-group>
                        <label for="">Secondary Bank Account

                        </label>
                        <div class="font-size-sm grey">Chalkboard will use this as your backup payout channel in case the nominated Primary Bank Account is
                          not available.
                        </div>
                      </b-form-group>
                    </div>

                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Secondary Bank" label-for="secondary_bank">
                          <ValidationProvider name="secondary bank" v-slot="validationContext">
                            <b-form-select v-model="form.secondary_bank" id="secondary_bank" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                              <b-form-select-option value="null">-- select bank/wallet --</b-form-select-option>
                              <b-form-select-option-group v-for="(groupItem, groupIndex) in bankAccountOptions" :key="groupIndex" :label="`${groupIndex === 'CBLBNK' ? 'Local Banks' : groupIndex === 'EWLLET' ? 'E-Wallets' : ''}`">
                                <b-form-select-option v-for="(item, index) in groupItem" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                              </b-form-select-option-group>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Account Name" label-for="sb_account_name" class="custom-error-position">
                          <ValidationProvider name="secondary bank account name" v-slot="validationContext">
                            <b-form-input id="sb_account_name" v-model="form.sb_account_name" placeholder="Enter account name..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-2" label="Account Type" label-for="sb_account_type">
                          <ValidationProvider name="second bank account type" v-slot="validationContext">
                            <b-form-select id="sb_account_type" v-model="form.sb_account_type" :state="getValidationState(validationContext)" size="sm" :disabled="formDisabled">
                              <b-form-select-option value="null" disabled>-- select account type--</b-form-select-option>
                              <b-form-select-option v-for="(item, index) in bankAccountTypeOptions" :key="index" :value="item.code_type_value">{{item.code_description}}</b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row class="pb-5">
                      <b-col sm="12" lg="6">
                        <b-form-group id="input-group-1" label="Bank Account Number" label-for="sb_account_no" class="custom-error-position" :disabled="formDisabled">
                          <ValidationProvider name="second bank account no" v-slot="validationContext">
                            <b-form-input id="sb_account_no" v-model="form.sb_account_no" placeholder="Enter bank account number..." size="sm" :state="getValidationState(validationContext)" :disabled="formDisabled"></b-form-input>
                            <b-form-invalid-feedback id="input-13-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <b-form-text v-if="!validationContext.errors[0]">
                              Do not put spaces and dashes. Enter your 11-
                              digit mobile number if using e-Wallets.
                            </b-form-text>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" lg="6">
                      </b-col>
                    </b-form-row>
                  </b-tab>
                </b-tabs>
              </b-card>

              <b-button block type="button" variant="primary" v-if="enableEditButton" v-on:click.prevent="enableEditButton = false; formDisabled = false;" class="my-3" @click="disabled = !disabled" :disabled="isFetchingData">
                <span>Edit Profile</span>
              </b-button>

              <div style="text-align: center;">
                <b-button type="button" style="width: 49%;" variant="outline-primary" v-if="!enableEditButton" v-on:click.prevent="onCancel" :disabled="isCheckingValidUpload" class="mr-2 my-3" @click="disabled = !disabled">
                  <span>Cancel</span>
                </b-button>

                <b-button type="button" style="width: 49%;" class="blue-button font-weight-bold my-3" v-if="!enableEditButton" :disabled="isCheckingValidUpload" @click="onValidateSubmit">
                  <b-spinner small v-if="isLoading"></b-spinner>
                  <span v-if="isLoading"> saving data...</span>
                  <span v-if="!isLoading">Save</span>
                </b-button>
              </div>

            </b-form>

          </validation-observer>

        </div>

      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import mixins from "../../../mixins";
import { mapState } from 'vuex';
import PhoneNumberInput from '../../../utility/forms/PhoneNumberInput.vue';
import CountrySelect from '../../../utility/forms/CountrySelect.vue';
import TSTAT from '../../../mixins/tutor-status'
export default {
  name: "ProfileTeacher",
  components: { PhoneNumberInput, CountrySelect },
  data: () => ({
    disabled: true,
    isFetchingData: false,
    isLoading: false,
    mainProps: {
      blank: true,
      blankColor: "#777",
      width: 128,
      height: 128,
      class: "m1",
    },
    imageUrl: require("@/assets/placeholder/profile-placeholder.png"),
    imageFileName: "",
    secProfileCompletion: false,
    value: 75,
    max: 100,
    progressNarrator:
      "Complete your profile by submitting a copy of NBI document.",
    nbi: [],
    nbiFileName: "",
    students: "---",
    tutoringHours: "---",
    ratings: "---",
    form: {
      image: null,
      email: "",
      birthday: "",
      firstname: "",
      lastname: "",
      mobile_no: "",
      street: "",
      brgy: "",
      city: "",
      province: "",
      zip_code: "",
      background: {
        tagline: "",
        about_me: "",
        education: "",
        academic_affiliation: "",
        language: "",
        academic_degree: null,
        college_university: '',
        other_specializations: '',
        my_teaching_style: '',
        facebook: '',
        youtube: '',
        tiktok: '',
        linkedin: ''
      },
      legal_firstname: '',
      legal_lastname: '',
      pn_country_code: '',
      whatsapp: '',
      wa_country_code: '',
      country: '',
      primary_bank: null,
      pb_account_name: '',
      pb_account_type: null,
      pb_account_no: '',
      secondary_bank: null,
      sb_account_type: null,
      sb_account_name: '',
      sb_account_no: '',
      formattedPhoneNumber: "",
      formattedWhatsAppNumber: "",
      pn_area_code: "",
      wa_area_code: "",
      price_tier_id: null,
    },
    accept_bundle: false,
    is_discount: false,
    allSelected: false,
    selected: [],
    specialization: [],
    options: [
      { value: null, text: "-- select --", disabled: true },
      { value: "13", text: "Preschool", disabled: false },
      { value: "1", text: "Grade 1", disabled: false },
      { value: "2", text: "Grade 2", disabled: false },
      { value: "3", text: "Grade 3", disabled: false },
      { value: "4", text: "Grade 4", disabled: false },
      { value: "5", text: "Grade 5", disabled: false },
      { value: "6", text: "Grade 6", disabled: false },
      { value: "7", text: "Grade 7", disabled: false },
      { value: "8", text: "Grade 8", disabled: false },
      { value: "9", text: "Grade 9", disabled: false },
      { value: "10", text: "Grade 10", disabled: false },
      { value: "11", text: "Grade 11", disabled: false },
      { value: "12", text: "Grade 12", disabled: false },
      { value: "14", text: "Tech", disabled: false },
      { value: "15", text: "Music", disabled: false },
      { value: "16", text: "Languages", disabled: false },
      { value: "17", text: "Art", disabled: false },
      { value: "18", text: "Others", disabled: false },
    ],
    tagsOptions: [],
    selectedItemIndex: [],
    availability: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    AvailabilitySchedule: [],
    timeSlots: [],
    enableEditButton: true,
    formDisabled: true,
    isCheckingValidUpload: false,
    tabs: ["Account", "About", "Specialization", "Availability", "Rates"],
    tabData: [
      { tab: "Account", key: "phone number" },
      { tab: "Account", key: "whatsapp" },
      { tab: "Account", key: "address 1" },
      { tab: "Account", key: "address 2" },
      { tab: "Account", key: "city" },
      { tab: "Account", key: "province" },
      { tab: "Account", key: "country" },
      { tab: "About", key: "academic degree" },
      { tab: "About", key: "college university" },
      { tab: "About", key: "tagline" },
      { tab: "About", key: "about me" },
      { tab: "About", key: "education" },
      { tab: "About", key: "academic affiliation" },
      { tab: "About", key: "about my teaching style" },
      { tab: "Rates", key: "rate" },
      { tab: "Rates", key: "primary bank" },
      { tab: "Rates", key: "account name" },
      { tab: "Rates", key: "account type" },
      { tab: "Rates", key: "bank account no" },
      { tab: "Rates", key: "confirm bank account no" },
    ],
    tabIndex: 0,
    guestTZ: null,
    regionTzName: null,
    hasSubmitButton: false,
    eventPNData: {},
    eventWAData: {},
    loadingPriceTiers: false,
    priceTiers: [],
    showPriceTierAlert: false,
    showInactivePriceTierAlert: false
  }),
  beforeMount() {
    this.$store.dispatch("storeTutorRegistrationFormDispatch");
  },
  computed: {
    ...mapState(["bankAccountData", "academicDegreeData", "bankAccountTypeData"]),
    profileName() {
      const legal_name = `${this.form.legal_firstname || ''} ${this.form.legal_lastname || ''}`;
      const public_name = `${this.form.firstname || ''} ${this.form.lastname || ''}`;
      return (legal_name.trim() !== '') ? legal_name : public_name
    },
    availableTagsOptions() {
      return this.tagsOptions;
    },
    bankAccountOptions() {
      return this.arrayGroupBy(this.bankAccountData, "code_type");
    },
    academicDegreeOptions() {
      return this.academicDegreeData;
    },
    bankAccountTypeOptions() {
      return this.bankAccountTypeData;
    }
  },
  created() {
    this.getPriceTiers();
  },
  mounted() {
    const vm = this;
    vm.loadUserData();
    vm.tagsOptions = vm.$store.state.subjects;
    vm.$watch(
      "$store.state.userData",
      (data) => {
        vm.guestTZ = data.timezone == null ? vm.guestTz : data.timezone;
        vm.regionTzName =
          data.timezone == null
            ? vm.getTzRegion(vm.guestTz)
            : data.region_timezone;
        this.$store.dispatch("getTimeSlots", vm.guestTZ);
      },
      { immediate: true }
    );

    if (this.$route.hash.includes("rate")) {
      setTimeout(() => {
        this.enableEditButton = true;
        this.tabIndex = 4;
      }, 1);
    }
  },
  methods: {
    onPNUpdate(e) {
      this.form.pn_country_code = e.countryCode;
      this.form.formattedPhoneNumber = e.formattedNumber
      this.form.pn_area_code = e.countryCallingCode
      this.eventPNData = e
    },
    onWAUpdate(e) {
      this.form.wa_country_code = e.countryCode;
      this.form.formattedWhatsAppNumber = e.formattedNumber
      this.form.wa_area_code = e.countryCallingCode
      this.eventWAData = e
    },
    onCancel() {
      this.enableEditButton = true;
      this.formDisabled = true;
      this.allSelected = null;
      this.selected = [];
      this.loadUserData();
    },
    onValidateSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          const result = this.tabData.filter((data) => {
            return data.key == errors[0]["key"];
          });
          if (result.length != 0) {
            const index = this.tabs.indexOf(result[0].tab);
            this.tabIndex = index;
            setTimeout(() => {
              this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }, 100);
          }
        } else {
          this.onSubmit();
        }
      });
    },
    onFileUpload() {
      document.getElementById("file-upload").click();
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (file) {
        this.imageUrl = URL.createObjectURL(file);

        if (file.size / 1024 > this.validProfileFileSize) {
          this.ShowModal(
            "The image you are trying to upload is too large.",
            null,
            `<strong>${this.convertByteToMegaByte(
              this.validProfileFileSize
            )}MB Uploading Limit</strong>`
          );
          this.loadUserData();
          return;
        } else if (!/\.(jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal(
            "The file must be a file of type: jpg, jpeg, png.",
            () => {
              this.imageUrl = this.imagePlaceholder;
              this.imageFileName = "";
              this.form.image = null;
            }
          );
          return;
        }

        const formData = new FormData();

        const params = {
          file: file,
          original_name: file.name,
          type: "photo",
          document_type: "photo",
        };

        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });

        const ImageApiURL =
          process.env.VUE_APP_BACKEND_URL + "/api/web/multi-signup/temp-upload";

        const ImageOptions = {
          headers: {
            Accept: "application/json, multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${this.UserToken}`,
          },
        };

        this.isCheckingValidUpload = true;

        this.axios
          .post(ImageApiURL, formData, ImageOptions)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.imageFileName = _data.data.server_name;
              this.imageUrl = _data.data.path;
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file);
              this.imageUrl = this.imagePlaceholder;
              this.imageFileName = "";
              this.form.image = null;
            }
          })
          .catch((error) =>
            console.log("fn method hook => onFileChange():" + error.response)
          )
          .finally(() => (this.isCheckingValidUpload = false));
      }
    },
    onSelectAll(day) {
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.morning = this.timeSlots.morning.map((i) => i.value);
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.afternoon = this.timeSlots.afternoon.map((i) => i.value);
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.evening = this.timeSlots.evening.map((i) => i.value);
    },
    onClearAll(day) {
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.morning = [];
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.afternoon = [];
      this.AvailabilitySchedule.filter((x) => {
        return x.day === day;
      })[0].items.evening = [];
    },
    onCheckAll() {
      this.selected = [];
      if (this.allSelected) {
        for (let i in this.specialization) {
          this.selected.push(this.specialization[i].index);
        }
      }
    },
    select() {
      this.allSelected =
        this.selected.length === this.specialization.length ? true : false;
    },
    addnewrow() {
      let lastIndex = -1;

      if (this.specialization.length > 0) {
        lastIndex = this.specialization[this.specialization.length - 1].index;
      }

      if (
        this.specialization.length <
        this.options.filter((e) => {
          return e.value !== null;
        }).length
      ) {
        this.specialization.push({
          index: lastIndex + 1,
          grade_level: "",
          subjects: [],
        });
      }

      this.allSelected = false;
    },
    remove() {
      setTimeout(() => {
        this.selected.forEach((e) => {
          const gradeLevelToRemove = this.specialization.find(
            (x) => x.index == e
          ).grade_level;
          this.specialization.splice(
            this.specialization.indexOf(
              this.specialization.find((x) => x.index == e)
            ),
            1
          );
          if (gradeLevelToRemove !== "") {
            this.options.find(
              (x) => x.value == gradeLevelToRemove
            ).disabled = false;
          }
        });
        this.selected = [];
      }, 200);
    },

    onGradeLevelSelectChange(event, index) {
      this.options
        .filter((e) => {
          return e.value != null;
        })
        .forEach((e) => {
          return (e.disabled = false);
        });

      const result = this.options.find((e) => e.value == event);
      const indexResult = this.options.indexOf(result);
      let selectedGrade = this.specialization[index];
      this.options[indexResult].disabled = true;

      this.selectedItemIndex = [];
      selectedGrade.subjects = [];

      document
        .querySelectorAll("#tblSpecialization tbody tr select")
        .forEach((element) => {
          this.selectedItemIndex.push(element.options.selectedIndex);
        });

      const SelectedIndexes = [...new Set(this.selectedItemIndex)];

      this.options.forEach((e, index) => {
        if (SelectedIndexes.includes(index)) {
          this.options[index].disabled = true;
        }
      });

      const sendGetRequest = async () => {
        try {
          const response = await this.getSubjectPerGradeLevel(event);
          const _data = response.data.data;
          const result = [...new Set(_data.map((item) => item.category))];
          this.specialization.filter(
            (item) => item.grade_level == event
          )[0].subjectsPerGradeLevel = result;
        } catch (err) {
          console.error(err);
        }
      };

      sendGetRequest();
    },
    onSubmit() {
      let levelArr = [];

      this.specialization.forEach((x) => {
        let subj = "";
        x.subjects.forEach((item) => {
          subj += item + "_";
        });
        levelArr.push([x.grade_level + "|" + subj.replace(/_+$/, "")]);
      });

      let scheduleArr = [];

      let newAvailabilityScheduleList = [];
      this.dayOfWeekAsString().forEach((day) => {
        newAvailabilityScheduleList.push({
          day: day,
          items: {
            morning: [],
            afternoon: [],
            evening: [],
          },
        });
      });

      this.AvailabilitySchedule.forEach((s) => {
        const currDate = this.moment().tz(this.guestTZ).day(s.day);
        if (s.items.morning.length > 0) {
          s.items.morning.map((time) => {
            let stime = time.split("-")[0],
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(12, 18).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(18, 24).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          });
        }
        if (s.items.afternoon.length > 0) {
          s.items.afternoon.map((time) => {
            let stime = time.split("-")[0],
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(12, 18).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(18, 24).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          });
        }
        if (s.items.evening.length > 0) {
          s.items.evening.map((time) => {
            let stime = time.split("-")[0],
              etime = time.split("-")[1];
            const UTCDateStart = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateEnd = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + etime, this.guestTZ)
                .utc()
                .format("HH:mm:ss"),
              UTCDateStartDOW = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("dddd"),
              UTCDateStartHH = this.moment
                .tz(currDate.format("YYYY-MM-DD") + " " + stime, this.guestTZ)
                .utc()
                .format("H");
            if (this.numRange(0, 12).includes(parseInt(UTCDateStartHH))) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.morning.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(12, 18).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.afternoon.push(UTCDateStart + "-" + UTCDateEnd);
            } else if (
              this.numRange(18, 24).includes(parseInt(UTCDateStartHH))
            ) {
              newAvailabilityScheduleList
                .filter((n) => {
                  return n.day == UTCDateStartDOW;
                })[0]
                .items.evening.push(UTCDateStart + "-" + UTCDateEnd);
            }
          });
        }
      });

      newAvailabilityScheduleList
        .filter((e) => {
          return (
            e.items.morning.length > 0 ||
            e.items.afternoon.length > 0 ||
            e.items.evening.length > 0
          );
        })
        .forEach((x) => {
          const selectedMS = [];
          const selectedAS = [];
          const selectedES = [];

          x.items.morning.forEach((m) => {
            selectedMS.push(m);
          });

          x.items.afternoon.forEach((m) => {
            selectedAS.push(m);
          });

          x.items.evening.forEach((m) => {
            selectedES.push(m);
          });

          scheduleArr.push(
            x.day +
              "|" +
              selectedMS.concat(selectedAS).concat(selectedES).toString()
          );
        });

      if (this.specialization.length == 0) {
        this.ShowModal("Please select Grade Level and Subject", () => {
          this.tabIndex = 2;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
        return;
      }

      for (let index in this.specialization) {
        let specializationRecord = this.specialization[index];
        if (
          specializationRecord.grade_level == 0 &&
          specializationRecord.subjects.length == 0
        ) {
          this.ShowModal("Please select Grade Level and Subject");
          this.tabIndex = 2;
          return;
        } else if (specializationRecord.grade_level == 0) {
          this.ShowModal("Please select Grade Level");
          this.tabIndex = 2;
          return;
        } else if (specializationRecord.subjects.length == 0) {
          this.ShowModal("Please select Subject");
          this.tabIndex = 2;
          return;
        }
      }

      const selectedAvailability = this.AvailabilitySchedule.filter((e) => {
        return (
          e.items.morning.length == 0 &&
          e.items.afternoon.length == 0 &&
          e.items.evening.length == 0
        );
      }).length;

      if (this.availability.length == selectedAvailability) {
        this.ShowModal("please select schedule.");
        this.tabIndex = 3;
        return;
      }

      const multiData = new FormData();

      multiData.append("image", this.imageFileName);
      multiData.append("firstname", this.form.firstname);
      multiData.append("lastname", this.form.lastname);
      multiData.append("mobile_no", this.form.formattedPhoneNumber);
      multiData.append("birthday", this.form.birthday);
      multiData.append("street", this.form.street || '');
      multiData.append("province", this.form.province);
      multiData.append("city", this.form.city);
      multiData.append("brgy", this.form.brgy || '');
      multiData.append("education", this.form.background.education);
      multiData.append("academic_affiliation", this.form.background.academic_affiliation || "");
      multiData.append("price_tier_id", this.form.price_tier_id);
      multiData.append("zip_code", this.form.zip_code || "");
      multiData.append("about_me", this.form.background.about_me);
      multiData.append("tagline", this.form.background.tagline);
      multiData.append("language", this.form.background.language || "");

      multiData.append("pn_country_code", this.form.pn_country_code || "");
      multiData.append("pn_area_code", this.form.pn_area_code || "");
      multiData.append("whatsapp", this.form.formattedWhatsAppNumber || "");
      multiData.append("wa_country_code", this.form.whatsapp ? this.form.wa_country_code : "");
      multiData.append("wa_area_code", this.form.whatsapp ? this.form.wa_area_code : "");
      multiData.append("country", this.form.country);

      multiData.append("academic_degree", this.form.background.academic_degree);
      multiData.append("college_university", this.form.background.college_university);
      multiData.append("other_specializations", this.form.background.other_specializations || "");
      multiData.append("my_teaching_style", this.form.background.my_teaching_style);
      multiData.append("facebook", this.form.background.facebook || "");
      multiData.append("youtube", this.form.background.youtube || "");
      multiData.append("tiktok", this.form.background.tiktok || "");
      multiData.append("linkedin", this.form.background.linkedin || "");

      multiData.append("primary_bank", this.form.primary_bank);
      multiData.append("pb_account_name", this.form.pb_account_name);
      multiData.append("pb_account_type", this.form.pb_account_type);
      multiData.append("pb_account_no", this.form.pb_account_no);
      multiData.append("secondary_bank", this.form.secondary_bank || "");
      multiData.append("sb_account_name", this.form.sb_account_name || "");
      multiData.append("sb_account_type", this.form.sb_account_type || "");
      multiData.append("sb_account_no", this.form.sb_account_no || "");

      for (let i = 0; i < scheduleArr.length; i++) {
        multiData.append(`schedule[${i}]`, scheduleArr[i]);
      }

      for (let i = 0; i < levelArr.length; i++) {
        multiData.append(`level[${i}]`, levelArr[i]);
      }

      multiData.append("accept_bundle", this.accept_bundle ? 1 : 0);
      multiData.append("is_discount", this.is_discount ? 1 : 0);

      if (this.nbiFileName != "") {
        multiData.append("nbi_file", this.nbiFileName);
      }

      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/update-user-info";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.UserToken}`,
        },
        mode: "cors",
      };

      this.isLoading = true;

      this.axios
        .post(ApiURL, multiData, options)
        .then((data) => {
          const _data = data.data;

          if (_data.status == 422) {
            if (_data.message == "The price tier (rate) is no longer active, please pick another one.") {
              this.tabIndex = 4;
              this.$refs.observer.setErrors({
                "rate": _data.message
              });
              setTimeout(() => {
                this.$refs.observer.refs['rate'].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            } else {
              this.ShowModal(
                _data.message,
                "danger"
              );
            }
          } else {
            if (!this.$store.state.userData.timezone) {
              this.$store.state.userData.price_tier_id = this.form.price_tier_id
              this.$router.push("/preferences?setTimezone");
            } else {
              this.ShowModal("Profile successfully updated.", () => {
                this.enableEditButton = true;
                this.formDisabled = true;
                this.loadUserData();
              });
            }
          }
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
          this.isLoading = false;
        });
    },
    onNBIFileChange(e) {
      const file = e.target.files[0];

      if (file) {
        if (file.size / 1024 > this.validDocumentFileSize) {
          this.ShowModal(
            `The ${
              file.type.indexOf("image") !== -1 ? "image" : "document"
            } you are trying to upload is too large.`,
            () => {
              this.nbi = null;
              this.nbiFileName = "";
            },
            `<strong>${this.convertByteToMegaByte(
              this.validDocumentFileSize
            )}MB Uploading Limit</strong>`
          );
          return;
        } else if (!/\.(docx|pdf|jpg|jpeg|png)$/i.test(file.name)) {
          this.ShowModal(
            "The file must be a file of type: docx, pdf, jpeg/jpg or png.",
            () => {
              this.nbi = null;
              this.nbiFileName = "";
            }
          );
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("original_name", file.name);
        formData.append("document_type", "nbi");
        formData.append(
          "type",
          file.type.indexOf("image") !== -1 ? "photo" : "file"
        );

        const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/temp-upload`,
          options = {
            headers: {
              Accept: "application/json, multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${this.UserToken}`,
            },
            mode: "cors",
          };

        this.axios
          .post(ApiURL, formData, options)
          .then((data) => {
            const _data = data.data;
            if (_data.status == 200) {
              this.nbiFileName = _data.data.server_name;

              const formData = new FormData();
              formData.append("nbi_file", this.nbiFileName);

              const ApiURL = `${process.env.VUE_APP_BACKEND_URL}/api/web/multi-signup/nbi-clearance`,
                options = {
                  headers: {
                    Accept: "application/json, multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${this.UserToken}`,
                  },
                };

              this.axios
                .post(ApiURL, formData, options)
                .then((data) => {
                  const _data = data.data;
                  if (_data.status == 0) {
                    this.ShowModal(_data.message);
                  } else {
                    this.ShowModal(
                      "File has been successfully submitted.",
                      () => {
                        this.loadUserData();
                      }
                    );
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                });
            } else if (_data.status == 422) {
              this.ShowModal(_data.errors.file, () => {
                this.form.nbi = null;
                this.nbiFileName = "";
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    onNBISubmitFile() {
      document.getElementById("nbiFileInput").click();
    },
    loadUserData() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/web/user/fetch-user";
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      this.isFetchingData = true;

      this.axios
        .get(ApiURL, options)
        .then((data) => {
          const _data = data.data;

          if (_data.data.role == "teacher") {
            this.secProfileCompletion = _data.data.status === TSTAT.ACTIVE
            if (!_data.data.price_tier_id) {
              this.tabIndex = 4;
              this.showPriceTierAlert = true;
              this.$refs.observer.setErrors({
                "rate": ["Please select a price tier."]
              })
              this.enableEditButton = false;
              this.formDisabled = false;
            } else if (_data.data.inactive_price_tier_id) {
              this.tabIndex = 4;
              this.showInactivePriceTierAlert = true;
              this.$refs.observer.setErrors({
                "rate": ["Please change your price tier."]
              })
              this.enableEditButton = false;
              this.formDisabled = false;
              _data.data.price_tier_id = null;
            } else {
              this.showInactivePriceTierAlert = false;
              this.showPriceTierAlert = false;
            }
          }

          const hasNBIFile = _data.data.nbi_file ? true : false
          this.progressNarrator = hasNBIFile ? "You have submitted the requirement. Please wait while we verify your account." : "Complete your profile by submitting a copy of NBI document."
          this.hasSubmitButton = hasNBIFile ? false : true

          this.imageUrl =
            _data.data.image == null ? this.imagePlaceholder : _data.data.image;
          this.imageFileName =
            _data.data.image == null
              ? ""
              : _data.data.image.substr(
                  _data.data.image.lastIndexOf("/") + 1,
                  _data.data.image.length - _data.data.image.lastIndexOf("/")
                );

          this.form = {
            email: _data.data.email,
            birthday: _data.data.birthday,
            firstname: _data.data.firstname,
            lastname: _data.data.lastname,
            mobile_no: _data.data.mobile_no,
            street: _data.data.street,
            brgy: _data.data.brgy,
            city: _data.data.city,
            province: _data.data.province,
            zip_code: _data.data.zip_code || "",
            background: {
              tagline: _data.data.tagline,
              education: _data.data.education,
              academic_affiliation: _data.data.academic_affiliation || "",
              about_me: _data.data.about_me || "",
              language: _data.data.language,
              academic_degree: _data.data.academic_degree,
              college_university: _data.data.college_university,
              other_specializations: _data.data.other_specializations || "",
              my_teaching_style: _data.data.my_teaching_style,
              facebook: _data.data.facebook || "",
              youtube: _data.data.youtube || "",
              tiktok: _data.data.tiktok || "",
              linkedin: _data.data.linkedin || ""
            },
            legal_firstname: _data.data.legal_firstname,
            legal_lastname: _data.data.legal_lastname,
            pn_country_code: _data.data.pn_country_code,
            pn_area_code: _data.data.pn_area_code || "",
            whatsapp: _data.data.whatsapp || "",
            wa_country_code: _data.data.wa_country_code || "",
            wa_area_code: _data.data.wa_area_code || "",
            country: _data.data.country,
            primary_bank: _data.data.primary_bank,
            pb_account_name: _data.data.pb_account_name,
            pb_account_type: _data.data.pb_account_type,
            pb_account_no: _data.data.pb_account_no,
            secondary_bank: _data.data.secondary_bank,
            sb_account_name: _data.data.sb_account_name || "",
            sb_account_type: _data.data.sb_account_type,
            sb_account_no: _data.data.sb_account_no || "",
            price_tier_id: _data.data.price_tier_id
          };

          this.specialization = [];

          this.options.forEach(
            (opt) => (opt.disabled = opt.value === null ? opt.disabled : false)
          );

          // SPECIALIZATION
          let lastIndex = -1;

          const subjects = [
            ...new Set(_data.data.subject.map((item) => item.level)),
          ];

          subjects.forEach((x) => {
            if (this.specialization.length > 0) {
              lastIndex =
                this.specialization[this.specialization.length - 1].index;
            }
            const selectedSubjects = _data.data.subject
              .filter((e) => {
                return e.level == x;
              })
              .map((item) => item.name);
            this.specialization.push({
              index: lastIndex + 1,
              grade_level: x,
              subjects: selectedSubjects,
              subjectsPerGradeLevel: [],
            });
            this.specialization.sort((a, b) => a.grade_level - b.grade_level);
          });

          this.specialization.forEach((x) => {
            const gradeLevel = x.grade_level;
            this.getSubjectPerGradeLevel(gradeLevel).then((response) => {
              const _data = response.data.data;
              const result = [...new Set(_data.map((item) => item.category))];
              const res = this.specialization.filter((item) => {
                return item.grade_level == gradeLevel;
              });
              res[0]["subjectsPerGradeLevel"] = result;
            });
          });

          subjects.forEach((e) => {
            this.options.find((x) => {
              return x.value == e;
            }).disabled = true;
          });
          // END - SPECIALIZATION

          this.AvailabilitySchedule = [];

          // AVAILABILITY
          const newScheduleList = [];

          this.availability.forEach((x) => {
            _data.data.schedule
              .filter((item) => {
                return item.day == x;
              })
              .map((item) => {
                const currDate = this.moment().tz(this.guestTZ).day(x);
                let UTCDateStart = this.moment
                  .tz(
                    `${currDate.format("YYYY-MM-DD")} ${item.start_time}`,
                    "UTC"
                  )
                  .tz(this.guestTZ);
                let UTCDateEnd = this.moment
                  .tz(
                    `${currDate.format("YYYY-MM-DD")} ${item.end_time}`,
                    "UTC"
                  )
                  .tz(this.guestTZ);
                newScheduleList.push({
                  day: UTCDateStart.format("dddd"),
                  start_time: UTCDateStart.format("HH:mm:ss"),
                  end_time: UTCDateEnd.format("HH:mm:ss"),
                });
              });
          });

          const schedules = [
            ...new Set(newScheduleList.map((item) => item.day)),
          ];

          this.availability.forEach((x) => {
            let timeSlots = [];
            if (
              schedules.filter((s) => {
                return s == x;
              }).length > 0
            ) {
              timeSlots = newScheduleList
                .filter((item) => {
                  return item.day == x;
                })
                .map((item) => {
                  const currDate = this.moment().tz(this.guestTZ).day(x);
                  const convertedDateStart =
                    currDate.format("YYYY-MM-DD") + " " + item.start_time;
                  return {
                    time: item.start_time + "-" + item.end_time,
                    morningSlot: this.numRange(0, 12).includes(
                      parseInt(this.moment(convertedDateStart).format("H"))
                    )
                      ? true
                      : false,
                    afternoonSlot: this.numRange(12, 18).includes(
                      parseInt(this.moment(convertedDateStart).format("H"))
                    )
                      ? true
                      : false,
                    eveningSlot: this.numRange(18, 24).includes(
                      parseInt(this.moment(convertedDateStart).format("H"))
                    )
                      ? true
                      : false,
                  };
                });
            }
            this.AvailabilitySchedule.push({
              day: x,
              items: {
                morning: timeSlots
                  .filter((item) => {
                    return item.morningSlot;
                  })
                  .map((item) => item.time),
                afternoon: timeSlots
                  .filter((item) => {
                    return item.afternoonSlot;
                  })
                  .map((item) => item.time),
                evening: timeSlots
                  .filter((item) => {
                    return item.eveningSlot;
                  })
                  .map((item) => item.time),
              },
            });
          });

          // END - AVAILABILITY

          document.getElementById("birthday").type = "date";
          this.accept_bundle = _data.data.accept_bundle == 1 ? true : false;
          this.is_discount = _data.data.is_discount == 1 ? true : false;

          this.getTeacherCounter(_data.data.user_id).then((response) => {
            const _data = response.data;
            this.students = _data.data.students;
            this.tutoringHours = _data.data.tutoring_hours;
            this.ratings = _data.data.rating;
          });
        })
        .catch(() => {})
        .finally(() => (this.isFetchingData = false));
    },

    async getPriceTiers() {
      const ApiURL =
        process.env.VUE_APP_BACKEND_URL + "/api/price-tiers";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.UserToken}`,
        },
      };

      try {
        this.loadingPriceTiers = true;
        const response = await this.axios.get(ApiURL, options)
        if (response.data.success) {
          this.priceTiers = response.data.payload;
        } else {
          this.showDynamicModal(
            "Something went wrong while loading the price tiers. Please reload the page.",
            "danger"
          );
        }
      } catch (error) {
        this.showDynamicModal(
          "Something went wrong while loading the price tiers. Please reload the page.",
          "danger"
        );
      } finally {
        this.loadingPriceTiers = false;
      }
    }
  },
  watch: {
    "$store.state.timeSlots": function () {
      const vm = this;
      const timeSlots = this.$store.state.timeSlots
        .map((item) => {
          const new_start_time = vm.moment
            .utc(
              `${vm.moment().tz(vm.guestTZ).format("YYYY-MM-DD")} ${
                item.start_time
              }`
            )
            .tz(vm.guestTZ);
          const new_end_time = vm.moment
            .utc(
              `${vm.moment().tz(vm.guestTZ).format("YYYY-MM-DD")} ${
                item.end_time
              }`
            )
            .tz(vm.guestTZ);
          return {
            start_time: new_start_time.format("HH:mm:ss"),
            end_time: new_end_time.format("HH:mm:ss"),
            text: `${new_start_time.format("h:mm A")} - ${new_end_time.format(
              "h:mm A"
            )}`,
            value: `${item.start_time}-${item.end_time}`,
            day_part: vm.getDayPart(new_start_time.format("HH:mm:ss")),
            sortH: parseInt(new_start_time.format("H")),
          };
        })
        .sort((a, b) => a.sortH - b.sortH);
      this.timeSlots = {
        morning: timeSlots
          .filter((x) => x.day_part === "Morning")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
        afternoon: timeSlots
          .filter((x) => x.day_part === "Afternoon")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
        evening: timeSlots
          .filter((x) => x.day_part === "Evening")
          .map((item) => {
            return {
              value: item.start_time + "-" + item.end_time,
              text: item.text,
            };
          }),
      };
    },
  },
  mixins: [mixins],
};
</script>

<style scoped>
.alert-dismissible {
  padding-right: 1.25rem;
}

.tutorprofiletabs .card-header {
  padding: 0 0.55rem;
}
.tutorprofiletabs .card-header-tabs {
  margin-bottom: unset;
}
.tutorprofiletabs .nav-tabs .nav-item,
.tutorprofiletabs .nav-link {
  background: var(--light-silver);
  color: var(--dark-silver);
}
.tutorprofiletabs .nav-tabs .nav-link.active,
.tutorprofiletabs .nav-tabs .nav-item.show .nav-link {
  color: var(--light-blue);
  font-weight: 600;
  border-bottom: 1px solid var(--light-blue);
}
.specialization-select {
  width: 30%;
}

@media screen and (max-width: 40em) {
  .font-size-sm {
    font-size: 0.8rem;
  }
  .specialization-select {
    width: 100%;
  }
  .specialization-select select {
    width: 8em;
  }
}
</style>

<style>
label.btn.btn-secondary.disabled span {
  color: white;
}
.morning label.btn.btn-secondary.active,
.morning .btn-secondary:not(:disabled):not(.disabled):active {
  color: white !important;
  background-color: var(--orange) !important;
}
.afternoon label.btn.btn-secondary.active,
.morning .btn-secondary:not(:disabled):not(.disabled):active {
  color: white !important;
  background-color: var(--teal) !important;
}
.evening label.btn.btn-secondary.active,
.morning .btn-secondary:not(:disabled):not(.disabled):active {
  color: white !important;
  background-color: var(--dark-blue) !important;
}
</style>


<template>
  <section class="container px-5">
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-12 col-lg-6">
        <div class="cb-lessons-from-container">
          <h5 style="font-weight: 700;">
            Lessons from
            <span>
              <template v-if="showConvertedAmount">
                {{ convertedMinRate.result }}/hr
              </template>
              <template v-else>
                <span style="font-family:system-ui;margin:0;padding:0;"> ₱</span>{{ min_rate }}/hr
              </template>
            </span>
          </h5>
          <p>Get access to expert tutors for learners from Pre-Kindergarten to Grade 12 and choose from our various 1-on-1 and Small Group classes and lessons like Coding, Math, and Reading. Promote your child's capacity for learning with our top-notch tutors.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <figure class="figure p-2">
          <img class="figure-img img-fluid cnn" :src="require('@/assets/imgs/home/Lessons.png')" alt="">
        </figure>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LessonsFrom",
  data() {
    return {
      min_rate: 380
    };
  },
  computed: {
    convertedMinRate() {
      return this.showConvertedAmount
        ? this.convertAmount(this.min_rate, {
            fDigits: 0
          })
        : null;
    }
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@media screen and (max-width: 767px) {
  .cb-lessons-from-container {
    padding-top: 3em;
  }
}
</style>
export default {
    methods: {
        metaPixelInit() {
            try{
                if(this.isValidMetaPixelId(process.env.VUE_APP_PIXEL_ID)){
                    return new Promise(() => {
                        (function(f, b, e, v, n, t, s) {
                            if(f.fbq) return;
                            n = f.fbq = function() {
                                n.callMethod?
                                n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                            if(!f._fbq) f._fbq = n;
                            n.push = n;
                            n.loaded = !0;
                            n.version = '2.0';
                            n.queue = [];
                            t = b.createElement(e);
                            t.async =! 0;
                            t.src = v;
                            s = b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)
                        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js'));
        
                        window.fbq('init', process.env.VUE_APP_PIXEL_ID);
                        
                        if(localStorage.getItem('cb_cookie_consent')){
                        this.metaPixelTrack({'eventName': 'PageView'})
                        }
                    })
                }else{
                    console.log("[Initialization Error]: Pixel was not initialized.")
                }
            }catch(error){
                console.log(error)
            }
            
        },
        /** metaPixelTrack
         * @param {Object} payload
         * @param {Boolean} isCustom
         * 
         * Structure of payload:
         * {
         *      eventName: <String>
         *      data: <Object>
         * }
         * 
         * Example:
         * with data:
            * {
            *      'eventName' : 'CompleteRegistration'
            *      'data': { 'role': role }
            * }
         * no data:
            * {
            *       'eventName': 'PageView
            * }
        */
        metaPixelTrack({eventName, data}, isCustom = false){
            try {
                if ((!window.fbq) || (!localStorage.getItem('cb_cookie_consent'))) return;
                const trackingMethod = isCustom ? 'trackCustom' : 'track';
                data ? (window.fbq(trackingMethod, eventName, data)) : (window.fbq(trackingMethod, eventName))
            }
            catch(error) {
                console.log("metaPixelTrack Error: ", error);
            }
        },
        /** isValidMetaPixelId
         * @param {String} PixelId 
         * 
         * Returns a boolean value true if the string parameter is a fifteen digit number, else it will log an error and return false.
        */
        isValidMetaPixelId(PixelId){
            if(String(PixelId).match("^[0-9]{16}$")) {
                return true
            }
            console.log("[Error]: Pixel ID in environment file is not valid.")
            return false
        }
    },

}

/** Function usage:
 *  this.metaPixelTrack(<object>) * if event is not custom
 *  this.metaPixelTrack(<object>, true) * if event is custom
 */

// Standard Events - these are events pre-defined by Facebook Pixel. 
// Reference: https://developers.facebook.com/docs/meta-pixel/reference

/** Payload structures of Standard events currently used
 * Note: These parameters for optional, except for the Purchase standard event.
 * Note: All of these methods can have a custom parameter.
 * 
 * ViewContent:
 *      'content_name': <String>
 *      'content_ids': <Array of String>
 *      'content_category': <String>
 *      'content_type': <String>
 *      'contents': <Array of Objects>
 *      'currency': <String>
 *      'value': <Number>
 * 
 * CompleteRegistration:
 *      'content_name': <String>
 *      'currency': <String>
 *      'status': <Boolean>
 *      'value': <Integer/Float>
 * 
 * AddToCart:
 *      'content_ids': <Array of integers/strings>
 *      'content_name': <String>
 *      'content_type': <String>
 *      'contents': <Array of objects>
 *      'currency': <String>
 *      'value': <Integer/Float>
 * 
 * Purchase:
 *      'content_ids': <Array of integers/strings>
 *      'content_name': <String>
 *      'content_type': <String>
 *      'contents': <Array of objects>
 *      'num_items' <Integer>
 * 
 *      REQUIRED:
 *      'currency': <String>
 *      'value': <Integer/Float>
 * 
 * AddToCart:
 *      'content_ids': <Array of integers/strings>
 *      'content_name': <String>
 *      'content_type': <String>
 *      'contents': <Array of objects>
 *      'currency': <String>
 *      'value': <Integer/Float>
 * 
 * Search:
 *      'content_category': <String>
 *      'content_ids': <Array of integers/strings>
 *      'contents': <Array of objects>
 *      'currency': <String>
 *      'search_string': <String>
 *      'value': <Integer/Float>
 *      
 * Contact:
 *      no parameter / custom parameter     
 * 
 * InitiateCheckout:
 *      'content_category': <String>
 *      'content_ids': <Array of integers/strings>
 *      'contents': <Array of objects>
 *      'currency': <String>  
 *      'num_items' <Integer>
 *      'value': <Integer/Float>
 * 
 *  Submit Application:
 *      no parameter / custom parameter
 * 
 * 
 
 */

/** Parameter data types
 * 'content_category': <String>
 * 'content_ids': <Array of integers/strings>
 * 'content_name': <String>
 * 'content_type': <String>
 * 'contents': <Array of objects>
 * 'currency': <String>
 * 'num_items' <Integer>
 * 'predicted_ltv': <Integer/Float>
 * 'search_string': <String>
 * 'status': <Boolean>
 * 'value': <Integer/Float>
 * - custom - : <Any>
 */